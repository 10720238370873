import UserService from "api/users";
import VButton from "components/v-button";
import VInput from "components/v-input";
import VModal from "components/v-modal";
import Card from "components/v-teamplate/Card";
import { ROUTES } from "config/routes";
import useCountdown from "hooks/useCountdown";
import useTranslation from "hooks/useTranslation";
import { useState } from "react";
import { toast } from "react-toastify";
import { Spinner } from "reactstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { useHistory } from "react-router-dom";
import AuthAPI from "api/auth";

const View = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { time, isCountdown, loadingResend, resendCode } = useCountdown();
  const userProfile = useAppSelector(getUserProfile);

  const dataUser = props.dataUser || userProfile

  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [otp, setOtp] = useState("");

  const handleDelete = async () => {
    try {
      if (!loadingResend) {
        await AuthAPI.resendCode({ body: { email: dataUser?.email } });
      }
      setOpenModal(true);
    } catch (error) {}
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const submit = async () => {
    try {
      setLoading(true);
      const res = await UserService.userDeleteAccount({
        body: {
          otp: otp,
          user_id: dataUser?._id
        },
      });
      if (res) {
        toast.success(t("Account has been permanently deleted"));
        localStorage.removeItem("auth");
        history.push(ROUTES.LOGIN);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };
  return (
    <Card>
      <Card.Header>
        <h4>{t("You want to delete this account")}</h4>
      </Card.Header>
      <Card.Body>
        <div>
          <VButton
            loading={loading}
            height={"36px"}
            className="btn btn-primary me-2"
            onClick={handleDelete}
          >
            {t("Delete the account")}
          </VButton>
        </div>
      </Card.Body>
      <VModal
        title={t("You definitely want to delete this account")}
        isOpen={openModal}
        loading={loading}
        centered={true}
        disableConfirm={!true}
        confirmLabel={t("Delete")}
        onCancel={() => handleCloseModal()}
        onConfirm={submit}
      >
        <div className="form-group">
          <div className="mb-2">
            <span>
              {t(
                "We have sent a verification code to your email. Please check and enter the code here"
              )}
            </span>
          </div>
          <VInput
            label={t("Code")}
            type="text"
            id="otp"
            name="otp"
            suffix={
              <div className="send_mail_suffix">
                {loadingResend ? (
                  <Spinner animation="border" />
                ) : (
                  <span onClick={() => resendCode(dataUser?.email)}>
                    {isCountdown ? time : t("Resend")}
                  </span>
                )}
              </div>
            }
            onChange={(e) => setOtp(e.target.value)}
          />
        </div>
      </VModal>
    </Card>
  );
};

export default View;

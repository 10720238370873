/* eslint-disable jsx-a11y/alt-text */
import Button from "components/v-button";
import Card from "components/v-teamplate/Card";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
import queryString from "query-string";
import useQuery from "hooks/useQuery";

const currencies = [
  {
    key: "deposit_p2p",
    name: "p2p",
    icon: "/assets/images/coins/p2p.png",
    disable: false,
  },
  {
    key: "deposit_spot",
    name: "spot",
    icon: "/assets/images/coins/spot.png",
    disable: false,
  },
];

type IProps = {
  url: string;
  userId?: string;
  reloadBalance?: () => {};
};

const View = (props: IProps) => {
  const { url } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const [method, setMethod] = useState("");
  const query = useQuery();
  const stock = query.stock;

  const handleClick = () => {
    const _query = {
      ...query,
      method,
    };
    history.push(`${url}/?${queryString.stringify(_query)}`);
  };

  return (
    <Card isOpacity={true}>
      <div className="step-0">
        <h5>
          {String(stock || "").toUpperCase()} -{" "}
          {t("Please select the method you want to deposit")}{" "}
        </h5>
        {currencies.map((c, index) => (
          <Button
            key={index}
            className={`btn-currency  ${method === c.key ? "active" : ""}`}
            onClick={() => setMethod(c.key)}
            disabled={c.disable}
          >
            <img className="icon-currenct" src={c.icon} />
            {c.name.toUpperCase()}
          </Button>
        ))}
        <div className="submit-step">
          <Button onClick={handleClick} disabled={!method}>
            {t("Continue")}
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import FriendService from "api/friends";
import VAvatar from "components/v-avatar";
import VInput from "components/v-input";
import VModal from "components/v-modal";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import VNameVerify from "../v-name-verify";

const View = (props) => {
  const { dataUser, openModal, onLoad, setOpenModal } = props;

  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [nickName, setNickName] = useState("");

  const onChange = (value) => {
    setNickName(value);
  };
  const submit = async () => {
    try {
      setLoading(true);
      const res = await FriendService.updateNicknameFriend({
        body: {
          from_id: dataUser?._id,
          nickname: nickName,
        },
      });
      if (res) {
        toast.success(t("SUCCESS"));
        setOpenModal(false);
        setLoading(false);
        if (onLoad) {
          onLoad({
            dataUser
          });
        }
      }
    } catch (error) {
      toast.error(t(error.errors));
      setLoading(false);
    }
  };

  const getRelation = async () => {
    const res: any = await FriendService.getRelationWithToUser({
      params: {
        to_id: dataUser._id,
      },
    });
    if (res?.fromFriend) {
      setNickName(res?.fromFriend?.nickname || null);
    }
  };

  useEffect(() => {
    if (dataUser && dataUser?._id && openModal) {
      getRelation();
    }
  }, [dataUser, openModal]);

  return (
    <VModal
      title={t("Đặt tên gợi nhớ")}
      isOpen={openModal}
      loading={loading}
      centered={true}
      children={
        <div>
          <div className="profile-img">
            <div className="img-fluid d-flex justify-content-center">
              <VAvatar
                hideName={true}
                size={"70px"}
                userData={dataUser}
                // handleClickType={() => handleClick("choose_image", "avatar")}
                // handleUpload={handleUploadAvatar}
              />
            </div>
          </div>
          <div className="text-center w-100 fs-5 d-flex flex-wrap justify-content-center align-items-center mt-2">
            <span>{t("Hãy đặt một tên dễ nhớ cho")}</span>
            <div className="profile-header">
              <div className="user-detail position-relative mt-0 mb-0">
                <div className="profile-detail">
                  <h4
                    style={{
                      maxWidth:
                        dataUser?.full_name?.length > 20 ? "250px" : "100%",
                      margin: "auto",
                    }}
                  >
                    <VNameVerify
                      dataUser={dataUser}
                      fontSize={"16px"}
                      onLoad={getRelation}
                      className={"username"}
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <span className="text-center w-100 fs-5 d-block">
            {t("Lưu ý: tên sẽ chỉ hiển thị với riêng bạn")}
          </span>
          <VInput
            className={"mt-2"}
            // label={t("Nick name")}
            placeholder={dataUser?.full_name}
            value={nickName}
            onChange={(e) => onChange(e.target.value)}
          />
        </div>
      }
      onCancel={() => setOpenModal(false)}
      onConfirm={submit}
    />
  );
};

export default View;

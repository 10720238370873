/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
import { useEffect, useState } from "react";
import { formatNumber } from "utils/common";
import VTable from "components/v-table";
import InvestService from "api/invest";
import HistoryDetail from "../history-details";

const View = () => {
  useState<boolean>(false);
  const [histories, setHistories] = useState([]);
  const [detail, setDetail] = useState(null);

  const columns = [
    {
      key: "id",
      title: "ID",
      dataIndex: "_id",
      render: (item: any) => {
        return (
          <a href="#" onClick={(_) => setDetail(item)}>
            {item?._id.slice(0, 4) + "..." + item?._id.slice(-3)}
          </a>
        );
      },
    },
    {
      key: "Package",
      title: "Package",
      render: (item) => {
        return `${item.package_detail.name} (${item.package_detail.type})`;
      },
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${formatNumber(item?.investment.amount)} (${String(
          item.package_detail?.invest?.stock
        ).toUpperCase()})`;
      },
    },
  ];

  useEffect(() => {
    getHistories();
  }, []);

  const getHistories = async () => {
    try {
      const res: any = await InvestService.getHitories({
        query: {
          limit: 20,
          page: 1,
        },
      });
      if (res && res.docs) setHistories(res.docs);
    } catch (error) {}
  };

  return (
    <div className="package-history">
      {detail ? (
        <HistoryDetail data={detail} setDetail={setDetail} />
      ) : (
        <VTable columns={columns} dataSource={histories}></VTable>
      )}
    </div>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";
import { useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";

import WebModulesIframe from "components/iframe-web-module";

import { useTranslation } from "react-i18next";

import { WebModulesIframeStyled } from "components/iframe-web-module/styles";
import { WEB_MODULE_URL } from "config/env";

import qs from "query-string";

export default function View() {
  const { isOpenStoryPreview, curStory, userId, pagination, postType } =
    useSelector(getUISettingState) ?? {};

  const { i18n } = useTranslation();

  const [loading, setLoading] = useState<boolean>(false);

  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);

  const dispatch = useDispatch();

  const getIframeUrl = (key: string) => {
    const pathKey = {
      stories: `/stories/${curStory?._id}`,
    };

    const params = {
      origin: window.origin,
      vdb_token: localStorage.getItem("auth"),
      moduleSessionId,
      fromSessionUid,
      lang: i18n.language ? `${i18n.language}` : "",
      userId,
      limit: pagination?.limit,
      page: pagination?.page,
      postType,
    };

    if (pathKey[key] && moduleSessionId && fromSessionUid) {
      return `${WEB_MODULE_URL}${pathKey[key] || "/"}?${qs.stringify(params)}`;
    }
    return null;
  };

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  useEffect(() => {
    setLoading(true);
  }, [isOpenStoryPreview]);

  if (!isOpenStoryPreview) return;

  return (
    <>
      {isOpenStoryPreview && (
        <WebModulesIframeStyled
          className="web-modules-modal"
          style={{ zIndex: 2147483646 }}
        >
          <WebModulesIframe
            url={getIframeUrl("stories")}
            className="iframe-story-preview"
            title="web modules modal"
            loading={loading}
            onLoad={() => setLoading(false)}
          />
        </WebModulesIframeStyled>
      )}
    </>
  );
}

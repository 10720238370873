/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
// import { useAppSelector } from "redux/reducers/hook";
// import { getUserProfile } from "redux/reducers/users/users.selector";
import VChannel from "components/v-channel";
import PostService from "api/posts";
import useQuery from "hooks/useQuery";

const View = () => {
  // const dataUser = useAppSelector(getUserProfile);
  const query = useQuery();
  const type = query.type;

  const [postVideo, setPostVideo] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [filter, setFilter] = useState({
    type: "all",
    category_id: null,
    search: "",
  });

  const getFiles = async (type) => {
    try {
      if (type === "link") {
        const checkPostTypeLink = postVideo.find(
          (el) => el?.post_type === "video_ytb" || el?.post_type === "video_fb"
        );
        const postLinkYtb: any = await PostService.getPost({
          query: {
            ...filter,
            post_type: "video_ytb",
            limit: 10,
            page: checkPostTypeLink ? pageCurrent : 1,
          },
        });
        const postLinkFb: any = await PostService.getPost({
          query: {
            ...filter,
            post_type: "video_fb",
            limit: 10,
            page: checkPostTypeLink ? pageCurrent : 1,
          },
        });
        const postLink = [...postLinkYtb, ...postLinkFb];
        if (postLink && postLink.length > 0) {
          if (checkPostTypeLink) {
            if (filter?.search) {
              setPostVideo([...postLink?.sort(() => 0.5 - Math.random())]);
            } else {
              setPostVideo([
                ...postVideo?.filter((el) =>
                  filter.category_id
                    ? String(el?.category_id) === String(filter.category_id)
                    : el
                ),
                ...postLink?.sort(() => 0.5 - Math.random()),
              ]);
            }
          
            setPageCurrent(pageCurrent + 1);
          } else {
            setPostVideo([...postLink?.sort(() => 0.5 - Math.random())]);
            setPageCurrent(2);
          }
        }
      } else {
        const checkPostType = postVideo.find((el) => el?.post_type === type);

        const post: any = await PostService.getPost({
          query: {
            ...filter,
            post_type: type,
            limit: 10,
            page: checkPostType ? pageCurrent : 1,
          },
        });
        if (post) {
          if (checkPostType) {
            if (filter?.search) {
              setPostVideo([
                ...post
                  .filter((el) => el?.post_files[0]?.post_file_path)
                  ?.sort(() => 0.5 - Math.random()),
              ]);
            } else {
              setPostVideo([
                ...postVideo?.filter((el) =>
                  filter.category_id
                    ? String(el?.category_id) === String(filter.category_id)
                    : el
                ),
                ...post
                  .filter((el) => el?.post_files[0]?.post_file_path)
                  ?.sort(() => 0.5 - Math.random()),
              ]);
            }
            setPageCurrent(pageCurrent + 1);
          } else {
            setPostVideo([
              ...post
                .filter((el) => el?.post_files[0]?.post_file_path)
                ?.sort(() => 0.5 - Math.random()),
            ]);
            setPageCurrent(2);
          }
        }
      }
    } catch (error) {}
  };

  window.onscroll = function () {
    if (
      Number(window.innerHeight + window.pageYOffset) >=
        Number(document.body.offsetHeight - 10) &&
      postVideo.length > 0
    ) {
      getFiles(type);
    }
  };

  useEffect(() => {
    if (type) {
      setPageCurrent(1);
      getFiles(type);
    }
  }, [type, filter]);

  return (
    <>
      <Container>
        <Row>
          <Col lg={12} className={"px-1"}>
            <VChannel
              type={type}
              data={postVideo}
              filter={filter}
              setFilter={setFilter}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default View;

import Button from "components/v-button";
import useTranslation from "hooks/useTranslation";

type IProps = {
  step: number;
  endStep: number;
  loading?: boolean;
  onNext: any;
  onBack: any;
};

const View = ({ step, endStep, loading = false, onBack, onNext }: IProps) => {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        gap: "15px",
        justifyContent: "center",
      }}
    >
      {step > 0 && <Button onClick={onBack}>{t("Quay lại")}</Button>}
      {step < endStep - 1 && <Button onClick={onNext}>{t("Tiếp theo")}</Button>}
      {step === endStep - 1 && (
        <Button type="submit" loading={loading}>
          {t("Gửi")}
        </Button>
      )}
    </div>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import PackageItem from "./Item";
import InvestService from "api/invest";

const Packages = () => {
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    getPackages();
  }, []);

  const getPackages = async () => {
    try {
      const res : any = await InvestService.getPackages({
        query: {
          status: "opening",
        },
      });
      if (res) setPackages(res);
    } catch (error) {}
  };

  return (
    <div className="package-wrapper container">
      <div className="row">
        {packages && packages.map((p) => <PackageItem _package={p} />)}
      </div>
    </div>
  );
};

export default Packages;

import VCKEditor from "components/v-ckeditor";
import { Card } from "react-bootstrap";
import { getUrlImage } from "utils/common";

type IProps = {
  id: string;
  label?: string;
  initData?: any;
  required?: boolean;
  error?: string;
  field?: any;
  image?: string;
};

const View = ({
  id = "",
  label = "",
  initData = "",
  required = false,
  error,
  field,
  image,
}: IProps) => {
  const { onChange, value } = field;

  return (
    <Card border="success" className="mb-4 question_item_card" id={id}>
      {label && (
        <p className="text-sm mb-2">
          <b>{label}</b> {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div>
        {image && (
          <img
            style={{
              height: "200px",
              width: "auto",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
            src={getUrlImage(image, 1000)}
            alt="sub"
          />
        )}
      </div>
      <VCKEditor initData={value ?? initData} onChange={onChange} />
      {error && <p className="_error mb-0">{error}</p>}
    </Card>
  );
};

export default View;

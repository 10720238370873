import { Radio, Space } from "antd";
import { Card } from "react-bootstrap";
import { getUrlImage } from "utils/common";

type OptionType = {
  label: string;
  value: string;
};

type IProps = {
  id: string;
  title?: string;
  label?: string;
  required?: boolean;
  value?: string;
  options: OptionType[];
  error?: string;
  field?: any;
  image?: string;
};

const View = ({
  id = "",
  title = "",
  label = "",
  value = "",
  required = false,
  options = [],
  error,
  field,
  image,
}: IProps) => {
  return (
    <Card border="success" className="mb-4 question_item_card" id={id}>
      {label && (
        <p className="text-sm mb-2">
          <b>{label}</b> {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div>
        {image && (
          <img
            style={{
              height: "200px",
              width: "auto",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
            src={getUrlImage(image, 1000)}
            alt="sub"
          />
        )}
      </div>
      <Radio.Group value={value} {...field}>
        <Space direction="vertical">
          {options?.map((option) => (
            <Radio value={option.value}>{option.label}</Radio>
          ))}
        </Space>
      </Radio.Group>
      {error && <p className="_error mb-0">{error}</p>}
    </Card>
  );
};

export default View;

// @ts-nocheck
import { useContext, useEffect, useState } from "react";
import Dropdown from "../core/dropdown";
import { useTranslation } from "react-i18next";
import { getUrlImage } from "utils/common";
import { MapContext } from "./context";
import { Button } from "react-bootstrap";
import Container from 'react-bootstrap/Container';
import SelectRegions from "components/location";
import useQuery from "hooks/useQuery";
import { getQueryString } from "utils/common";
import { useHistory } from 'react-router-dom';
import { isMobile } from "react-device-detect";

const MapFilterSelector = () => {
  const { nations, religions, ages, careers, objects, categoryPosts } =
    useContext(MapContext);

  const history = useHistory();
  const params = useQuery();
  const { t } = useTranslation();  
  const [address, setAddress] = useState<any>({});
  const [searchValue, setSearchValue] = useState<any>({});

  useEffect(() => {
    const {country, district, province, ward} = params;
    setAddress({country, district, province, ward});
  }, [params]);

  const onSearch = () => {
    const queryData =  {...searchValue};

    Object.keys(address).forEach((k) => {
      if (address[k]) queryData[k] = address[k];
    })
    
    history.push(getQueryString(queryData));
  }
  
  return (
    <Container className="p-2 map-filter_container">
      <p className="mt-3">
        <h2>{t("Bộ lọc")}</h2>
      </p>
      <hr />
      <div className={`p-2 d-flex flex-wrap ${isMobile ? "overflow-auto smart-maps_filter" : ""}`}>
        <div className="w-full">
          <div className="mb-2 age-cate text-start">
            <Dropdown
              label={t("Độ tuổi ")}
              placeholder={t("Chọn độ tuổi")}
              options={
                [
                  {
                    label: t("Chọn độ tuổi"),
                    value: "none"
                  },
                  ...ages?.map((el: any) => {
                    return {
                      key: el?.key,
                      label: t(el?.name),
                      value: el?._id,
                    };
                  })
                ]
              }
              value={searchValue?.age || params?.age}
              onChange={(e) =>
                setSearchValue((oldState: any) => ({
                  ...oldState,
                  age: e,
                }))
              }
              className="!rounded-md !bg-white my"
            />
          </div>
        </div>
        <div className="my-2 w-full">
          <div className="object-cate text-start">
            <Dropdown
              label={t("Ngành nghề")}
              placeholder={t("Chọn ngành nghề của bạn")}
              options={
                [
                  {
                    label: t("Chọn ngành nghề của bạn"),
                    value: "none"
                  },
                  ...careers?.map((el) => {
                    return {
                      key: el?.key,
                      label: t(el?.name),
                      value: el?._id,
                      image: getUrlImage(el?.logo, 50),
                    };
                  })
                ]                
              }
              value={searchValue?.career || params?.career}
              onChange={(e) =>
                setSearchValue((object: any) => ({
                  ...object,
                  career: e,
                }))
              }
              className="!rounded-md !bg-white my"
            />
          </div>
        </div>
        <div className="my-2 w-full">
          <div className="nation text-start">
            <Dropdown
              label={t("Nation")}
              placeholder={t("Chọn dân tộc của bạn")}
              options={
                [
                  {
                    label: t("Chọn dân tộc của bạn"),
                    value: "none"
                  },
                  ...nations?.map((el) => {
                    return {
                      key: el?.key,
                      label: t(el?.name),
                      value: el?._id,
                      image: getUrlImage(el?.logo, 50),
                    };
                  })
                ]                
              }
              value={searchValue?.nation || params?.nation}
              onChange={(e) =>
                setSearchValue((oldState: any) => ({
                  ...oldState,
                  nation: e,
                }))
              }
              className="!rounded-md !bg-white my"
            />
          </div>
        </div>
        <div className="my-2 w-full">
          <div className="religions text-start">
            <Dropdown
              label={t("Religion")}
              placeholder={t("Chọn tôn giáo của bạn")}
              options={
                [
                  {
                    label: t("Chọn tôn giáo của bạn"),
                    value: "none"
                  },
                  ...religions?.map((el) => {
                    return {
                      key: el?.key,
                      label: t(el?.name),
                      value: el?._id,
                      image: getUrlImage(el?.logo, 50),
                    };
                  })
                ]
              }
              value={searchValue?.religion || params?.religion}
              onChange={(e) =>
                setSearchValue((oldState: any) => ({
                  ...oldState,
                  religion: e,
                }))
              }
              className="!rounded-md !bg-white my"
            />
          </div>
        </div>
        <div className="my-2 w-full">
          <div className="object text-start">
            <Dropdown
              label={t("Đối tượng")}
              placeholder={t("Chọn đối tượng")}
              options={
                [
                  {
                    label: t("Chọn đối tượng"),
                    value: "none"
                  },
                  ...objects?.map((el) => {
                    return {
                      key: el?.key,
                      label: t(el?.name),
                      value: el?.id,
                      image: getUrlImage(el?.logo_verify, 50),
                    };
                  })
                ]
              }
              value={searchValue?.object_type || params?.object_type}
              onChange={(e) =>
                setSearchValue((oldState: any) => ({
                  ...oldState,
                  object_type: e,
                }))
              }
              className="!rounded-md !bg-white my"
            />
          </div>
        </div>
        <div className="my-2 w-full text-start">
          <Dropdown
            className="!rounded-md !bg-white my"
            label={t("Danh mục")}
            placeholder={t("Chọn danh mục của bạn")}
            options={
              [
                {
                  label: t("Chọn danh mục của bạn"),
                  value: "none"
                },
                ...categoryPosts?.map((el: any) => {
                  return {
                    key: el?.key,
                    label: t(el?.name),
                    value: el?._id,
                  };
                })
              ]
            }
            value={searchValue?.category_id || params?.category_id}
            onChange={(e) =>
              setSearchValue((oldState: any) => ({
                ...oldState,
                category_id: e,
              }))
            }
            setRequiredValue={() => {}}
            showSearch={true}
          />
        </div>
        <div className={`my-2 mt-0 regions_custom text-start`}>
          <span className="text-xs">{t("Địa chỉ hiện tại")}</span>
          <SelectRegions address={address} setAddress={setAddress} colNum={6} />
        </div>
        <Button 
          className="btn btn-primary btn-md mt-3 btn btn-primary w-full map-filter_container__btn_search" 
          onClick={onSearch}>
          {t("Search")}
        </Button>   
      </div>    
    </Container>
  );
};

export default MapFilterSelector;

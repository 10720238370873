import styled from "styled-components";

export const Container = styled.div`
  width: 600px;
  padding: 40px;
  border: 1px solid #a0a0a0;
  margin: auto;
  font-size: 16px;
  position: relative;
  overflow: hidden;
  margin-top: 10px;
  z-index: 10;
  @media screen and (max-width: 600px) {
    width: 400px;
    padding: 10px;
  }
  @media screen and (max-width: 402px) {
    width: 350px;
    padding: 5px;
  }
`;


export const Header = styled.div``;

export const Logo = styled.div`
  height: 70px;
  img {
    height: 100%;
  }
`;
export const HeaderLeft = styled.div`
  margin-top: 50px;
  p {
    font-weight: 700;
    margin-bottom: 0px;
  }
  h5 {
    color: #00ac11a9;
    font-size: 15px;
    font-weight: 600;
  }
  .desc {
    font-size: 14px;
    font-weight: 400;
    max-width: 200px;
    span {
      color: #00ac11a9;
      font-weight: 600;
    }
  }
`;
export const HeaderRight = styled.div`
  margin-top: 30px;
  h3 {
    color: #00ac11a9;
    font-weight: 700;
    font-size: 24px;
  }
  p {
    font-weight: 600;
    margin-bottom: 5px;
  }
`;
export const Content = styled.div`
  margin: 50px 0;
  overflow: auto;
  td {
    color: black;
    font-size: 15px;
    font-weight: 500;
  }
  .logo {
    height: 50px;
  }
`;

export const PaymentInfo = styled.div`
  margin-top: 20px;
  h5 {
    font-weight: 700;
  }
`;



export const Value = styled.span`
  font-weight: 400;
  width: fit-content;
  font-size: 14px;
  color: #1f1f1f;
  line-height: 22px;
`;


export const Label = styled.span`
  font-weight: 700;
  min-width: 73px;
  font-size: 14px;
  color: #1f1f1f;
  line-height: 22px;
`;
export const Total = styled.div`
  margin-top: 20px;
  p {
    font-weight: 600;
    font-size: 15px;
    height: 25px;
    overflow: hidden;
    span {
      font-size: 14px;
    }
  }
  .total {
    background-color: #00ac11a9;
    color: white;
    padding: 5px;
    text-align: center;
    height: 30px;
  }
  .total-value {
    background-color: #172d4d;
    color: white;
    padding: 5px 0px;
    text-align: center;
    height: 30px;
  }
`;
export const Footer = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
  text-align: center;
  a {
    color: #00ac11a9 !important;
    font-weight: 600;
  }
  p {
    margin: 5px;
  }
`;


export const TopRight = styled.img`
  position: absolute;
  top: 12px;
  right: 26px;
  // width: 35%;
`;
export const BotttomLeft = styled.img`
  position: absolute;
  left: -1px;
  bottom: -1px;
  width: 35%;
  z-index: 100;
`;

export const Download = styled.div`
  margin-top: 50px;
  text-align: center;
  button {
    margin: 5px;
    padding: 8px 10px;
    background: #211ccaa9;
    color: white;
    font-weight: 600;
  }
`;
export const LogoVzonex = styled.div`
  position: absolute;
  right: 10px;
  bottom: 10px;
  img {
    height: 30px;
  }
`;



import Button from "components/v-button";
import { useRef } from "react";
import { copyText, formatCurrency } from "utils/common";
import { toast } from "react-toastify";

// import APIs from "api";
import Input from "components/input";
import useTranslation from "hooks/useTranslation";

const Step4 = ({
  setDataByKey,
  canclePayment,
  dataPayment,
  handleSubmit,
  loading,
  backStep,
  errUpload,
  file,
  setFile,
}) => {
  const { bank, currency, hash } = dataPayment;
  const { t } = useTranslation();
  // const [file, setFile] = useState();

  const fileRef = useRef<any>();
  const imgRef = useRef<any>();

  // const [errUpload, setErrorUpload] = useState<boolean>(false);

  const onUpload = async () => {
    const [f] = fileRef.current.files;
    setFile(f);
    imgRef.current.src = URL.createObjectURL(f);
    // try {
    //   const formData = new FormData();
    //   formData.append("files", f);
    //   const res: any = await APIs.UPLOAD.uploadFile({
    //     body: formData,
    //   });
    //   if (res) {
    //     setErrorUpload(false);
    //     setDataByKey("proof", res.filePath);
    //   } else {
    //     setErrorUpload(true);
    //   }
    // } catch (error) {
    //   // console.log(error);
    //   setErrorUpload(true);
    // }
  };

  const handleCopy = (tx: string) => () => {
    copyText(tx);
    toast.success(t("Copied to clipboard!"));
  };

  return (
    <div>
      <div className="bank-deposit border-bottom">
        <p>
          {t("Transfer to")} {currency === "vnd" ? t("account number") : t("address")}{" "}
          <span> {bank.account_number}</span>
          <div className="copy-btn" onClick={handleCopy(bank.account_number)}> [copy]</div>
        </p>
        <p>
          {currency === "vnd" ? t("Bank name") : t("Network")}:{" "}
          <span>{currency === "vnd" ? bank.bank_name : bank.network}</span>{" "}
        </p>
        <p>
          {currency === "vnd" ? t("Bank account name") : t("Wallet")} :{" "}
          <span>{bank.account_name}</span>{" "}
        </p>
        <p>
          {t("Deposit amount")}{" "}
          <span>
            {" "}
            {formatCurrency(dataPayment.amount)}{" "}
            {String(currency).toUpperCase()}
          </span>
          <div className="copy-btn" onClick={handleCopy(formatCurrency(dataPayment.amount))}>
            {" "}
            [copy]
          </div>
        </p>
        <p>***{t("The amount must be exact, including the odd part")}***</p>
        {currency === "vnd" && (
          <p>
            {t("Transfer content")} <span>{dataPayment.content}</span>
            <div className="copy-btn" onClick={handleCopy(dataPayment.content)}> [copy]</div>
          </p>
        )}
        <p>
          ***{t("The content must be exactly as instructed for the system to automatically match")}***{" "}
        </p>
      </div>

      <div className="bank-upload">
        <p>
          * {t("After you have send the payment, please confirm it was sent and update the payment proof so we can process.")}
        </p>

        {currency !== "vnd" && (
          <Input
            transparent={true}
            label={t("Trasaction hash")}
            placeholder="0x41f5919e4c007450d4eb26e773745f7d916809793dc66b35a9a9cff31e1d8cf6"
            onChange={(e) => setDataByKey("hash", e.target.value)}
          />
        )}

        {currency === "vnd" && (
          <label className={`upload-dragger ${errUpload ? "error-upload" : ""}`} htmlFor="proofFile">
            {!Boolean(file) ? "Upload" : ""}
            <img hidden={!Boolean(file)} ref={imgRef} alt="Proof" />
          </label>
        )}

        <input
          hidden={true}
          id="proofFile"
          ref={fileRef}
          type="file"
          className="custom-file-input"
          required
          onChange={onUpload}
        />

        <div className="submit-step d-flex flex-row">
          <Button loading={loading} onClick={canclePayment} color={"grey200"}>
            {t("Cancel")}
          </Button>
          &ensp;
          <Button
            disabled={
              currency === "vnd" ? !Boolean(file) || errUpload : !Boolean(hash)
            }
            loading={loading}
            onClick={handleSubmit}
          >
            {t("Submit")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Step4;

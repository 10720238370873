/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import VButton from "components/v-button";
import VInput from "components/v-input";
import VTextarea from "components/v-textarea";

import useTranslation from "hooks/useTranslation";
import APIsUser from "api/users";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/reducers/hook";

import {
  getUserAsync,
  getUserByIdAsync,
} from "redux/reducers/users/users.actions";

const FormFamilySetting = (props) => {
  const { dataUser, isMyProfile } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [familyData, setFamilyData] = useState({
    name: "",
    description: "",
  });

  useEffect(() => {
    if (dataUser?.profile?.family_data) {
      setFamilyData(dataUser.profile.family_data);
    }
  }, [dataUser.profile]);

  const handleCancel = () => {
    const search = new URLSearchParams(window.location.search);
    search.delete("isEdit")
    history.push(`?${search}`);
  };

  const handleChange = (value, name) => {
    setFamilyData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleEdit = async () => {
    try {
      setLoading(true);
      const newData = {
        family_data: familyData,
      };
      const res: any = await APIsUser.updateProfile({
        body: {
          ...newData,
        },
      });
      if (res) {
        if (isMyProfile) dispatch(getUserAsync());
        dispatch(
          getUserByIdAsync({
            params: {
              id: dataUser._id,
              user_id: dataUser._id,
            },
          })
        );
        handleCancel();
        toast.success(t(`Success`));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(`${error?.errors}`));
    }
  };

  return (
    <Form>
      <Form.Group className="form-group col-12 me-1">
        <VInput
          label={t("Name")}
          type="text"
          id="name"
          name="name"
          defaultValue={familyData.name}
          onChange={(e) => handleChange(e.target.value, "name")}
        />
      </Form.Group>
      <div className="mb-2">
        <VTextarea
          label={t("Descriptions")}
          id="description"
          name="description"
          defaultValue={familyData?.description}
          onChange={(e) => handleChange(e.target.value, "description")}
        />
      </div>
      <VButton
        loading={loading}
        height={"36px"}
        className="btn btn-primary me-2"
        onClick={handleEdit}
      >
        {t("save")}
      </VButton>
      <VButton
        loading={loading}
        onClick={handleCancel}
        height={"36px"}
        className="btn bg-danger border-danger"
      >
        {t("Cancel")}
      </VButton>
    </Form>
  );
};

export default FormFamilySetting;

import { Container } from "react-bootstrap";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";

import VButton from "components/v-button";
import { FRIEND_ACTION } from "config/enums";
import { getFollowingPages } from "redux/reducers/pages/pages.selector";
import { getChannelFollowingsAsync } from "redux/reducers/channels/channels.action";
import VPageCard from "components/v-objects/v-page-card";

const View = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const pages: any = useAppSelector(getFollowingPages);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = pages;

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getChannelFollowingsAsync({
        query: {
          page,
          limit,
        },
      })
    );
    setLoading(false);
  };

  const handleViewMore = () => {
    getData({
      page: page + 1,
    });
  };

  return (
    <>
      <div>
        <Container>
          <div className="row">
            {pages &&
              (pages.docs || []).map((page, index) => (
                <VPageCard
                  key={index}
                  col_lg={4}
                  col_md={4}
                  page={{
                    ...page,
                    ...page.to,
                  }}
                  type={FRIEND_ACTION.VIEW_DETAIL_PAGE}
                />
              ))}
          </div>
        </Container>

        <div className="col-12">
          {page < pages.totalPages && (
            <div className="text-center p-2">
              <VButton
                type="button"
                loading={loading}
                size={"small"}
                onClick={handleViewMore}
              >
                {" "}
                {t("View more")}
              </VButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default View;

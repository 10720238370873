/* eslint-disable indent */
import Card from "components/v-teamplate/Card";
import { useEffect, useState } from "react";
import QuotasService from "api/quotas";
import { getUrlImage } from "utils/common";

type IProp = {
  data: any;
};
const View = (props: IProp) => {
  const { data } = props;
  const [specialDate, setSpecialDate] = useState([]);

  const getSpecialDate = async () => {
    try {
      const res: any = await QuotasService.getSpecialDate();
      if (res) {
        setSpecialDate(res.special_dates);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSpecialDate();
  }, []);

  const renderTitle = (data) => {
    const specialDateTitle = specialDate.find(
      (el) =>
        (new Date(el.special_date_str).getDate() ===
          new Date(data?.title).getDate() &&
          new Date(el.special_date_str).getMonth() ===
            new Date(data?.title).getMonth()) ||
        String(data?.title).includes(el.special_date_str)
    );

    if (specialDateTitle) {
      return (
        <div
          className={`title-time px-2 py-1`}
          style={{
            backgroundColor: specialDateTitle?.background_color,
            color: specialDateTitle?.color,
          }}
        >
          {specialDateTitle?.special_date_str} - {specialDateTitle?.title}
        </div>
      );
    }
    return (
      <div
        className={`title-time px-2 py-1`}
        style={{
          backgroundColor: data?.bgColorTime,
          color: data?.colorTime,
        }}
      >
        {data?.title}
      </div>
    );
  };

  const renderIcon = (data) => {
    const specialDateTitle = specialDate.find(
      (el) =>
        new Date(el.special_date_str).getDate() ===
          new Date(data?.title).getDate() &&
        new Date(el.special_date_str).getMonth() ===
          new Date(data?.title).getMonth()
    );
    if (specialDateTitle) {
      return <img src={getUrlImage(specialDateTitle?.icon)} alt="" />;
    }
    return data.icon || <div className="timeline-dots"></div>;
  };

  return (
    <ul className="iq-timeline ms-1">
      {data &&
        data.length > 0 &&
        data.map((el, index) => (
          <li key={index} className="item-timeline-warrper">
            <div className="musty">{renderIcon(el)}</div>
            <div className="timeline-border"></div>
            <Card className="w-fit-content">{renderTitle(el)}</Card>
            {el.children}
          </li>
        ))}
    </ul>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import UserService from "api/users";
import VAvatar from "components/v-avatar";
// import VPanigation from "components/v-panigation";
import VTable from "components/v-table";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { formatNumber } from "utils/common";

const UserOnline = () => {
  const { t } = useTranslation();

  const [ranking, setRanking] = useState({
    docs: [],
    page: 1,
  });

  const columns = [
    {
      key: "ratings",
      title: t("Position"),
      render: (item: any, index) => {
        return <span>{(ranking.page - 1) * 10 + index + 1}</span>;
      },
    },
    {
      key: "User",
      title: t("User"),
      render: (item: any) => {
        return (
          <div className="d-flex justify-content-between cursor-pointer">
            <VAvatar
              className="m-1"
              isClick={true}
              size="25px"
              fontSizeIconVerify={12}
              userData={{
                ...item.profile,
                ...item.user,
              }}
            />
            {/* {index < 3 && (
              <img width={30} height={30} src={rankingIcon[index]} alt="" />
            )} */}
          </div>
        );
      },
    },
    {
      key: "total",
      title: t("Total hour"),
      render: (item) => {
        return formatNumber(item.total_milliseconds / 3600000);
      },
    },
  ];

  useEffect(() => {
    getRankings(1);
  }, []);

  const getRankings = async (page) => {
    const res: any = await UserService.getRankingsOnline({
      query: {
        limit: 10,
        page,
      },
    });
    if (res) setRanking(res);
  };

  return (
    <div className="forum-content">
      <div className="table-container-forum">
        <VTable columns={columns} dataSource={ranking["docs"]}></VTable>
      </div>
    </div>
  );
};

export default UserOnline;

import APIs from "api";
import Input from "components/v-input";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { securityAsync } from "redux/reducers/securities/securities.action";
import { getSecurity } from "redux/reducers/securities/securities.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";
import useTranslation from "hooks/useTranslation";
interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { t } = useTranslation();
  const { verify, setVerify } = props;
  const user = useAppSelector(getUserProfile);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { is_enable } = useAppSelector(getSecurity);
  const [isSend, setIsSend] = useState(false);

  const getCode = async () => {
    try {
      if(isSend) return;
      setLoading(true);
      await APIs.AUTH.resendCode({
        body: { email: user.email, title: "Verification Code - Withdraw" },
      });
      setIsSend(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t(error?.errors));
    }
  };

  useEffect(() => {
    dispatch(securityAsync());
  }, [dispatch]);

  return (
    <div className="p-3">
      <div className="content-rigth-view">
        <Input
          transparent={true}
          label={t("Email verification code")}
          onChange={(e) =>
            setVerify({
              ...verify,
              email_code: e.target.value,
            })
          }
          value={verify.email_code}
          suffix={
            <div
              className="cursor-pointer"
              onClick={getCode}
              hidden={loading}
            >
              {!isSend ? t("Get code") : t("Code Send")}
            </div>
          }
        />
        <span className="text-details">
          {t("Enter the 6 digit code send to")} {user.email}
        </span>
        {is_enable && (
          <>
            {" "}
            <Input
              transparent={true}
              label={t("Google verification code")}
              onChange={(e) =>
                setVerify({
                  ...verify,
                  google_code: e.target.value,
                })
              }
              value={verify.google_code}
            />
            <span className="text-details">
              {t(" Enter the 6 digit code from Google Authenticator")}
            </span>
          </>
        )}

        {user.pin_secure && (
          <>
            {" "}
            <Input
              transparent={true}
              label={t("Pin Code")}
              onChange={(e) =>
                setVerify({
                  ...verify,
                  pin_code: e.target.value,
                })
              }
              value={verify.pin_code}
            />
            <span className="text-details">{t("Enter your pin code")}</span>
          </>
        )}
      </div>
    </div>
  );
}

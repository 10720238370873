import Input, { Label, SuffixIcon, InputWrapper, CustomInputCurrency } from "./styles";
import { FormFeedback } from "reactstrap";

const View = ({
  transparent = false,
  color = "",
  isCurrency = false,
  label,
  hasError = false,
  suffixIcon = null,
  errMessage = "",
  ...props
}: any) => {
  
  return (
    <InputWrapper>
      {label && <Label hasError={hasError}>{label}</Label>}
      {
        isCurrency ? <CustomInputCurrency
          transparent={transparent}
          color={color}
          {...props}
        /> :
          <Input
            transparent={transparent}
            color={color}
            invalid={hasError}
            autocomplete="off"
            {...props}
          />
      }
      {hasError && <FormFeedback>{errMessage}</FormFeedback>}
      {suffixIcon && (
        <SuffixIcon height={props.height} hasError={hasError} color={color}>
          {suffixIcon}
        </SuffixIcon>
      )}
    </InputWrapper>
  );
};

export default View;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { useState, useEffect } from "react";
import Card from "components/v-teamplate/Card";
import { useHistory } from "react-router-dom";
import { Form } from "react-bootstrap";
import VInput from "components/v-input";
import { v4 as uuidv4 } from "uuid";
import VButton from "components/v-button";
import PostDirectoryService from "api/post-directory";
import useTranslation from "hooks/useTranslation";
import VIcon from "components/v-icon";
import { useAppDispatch } from "redux/reducers/hook";
import { getPostDirectoryByUserIdAsync } from "redux/reducers/post-directory/post-directory.actions";
import { toast } from "react-toastify";
import VSortable from "components/v-sortable";
import Dropdown from "components/v-dropdown";
import {
  DIRECTORY_STATUS,
  FRIEND_TYPE,
  PERMISSION_POTING_DIRECTORY,
} from "config/enums";
import { Label } from "reactstrap";
import UploadFile from "components/uploadFile";
import APIs from "api";
import { isUser, isBusiness, isGroup } from "utils/friends";
import FriendService from "api/friends";
import BusinessService from "api/businesses";
import GroupService from "api/groups";
import VNameVerify from "components/v-objects/v-name-verify";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  dataFolder?: any;
};

const listStatusPostDirectory = Object.keys(DIRECTORY_STATUS).map((k) => ({
  label: String(k).toLowerCase(),
  value: DIRECTORY_STATUS[k],
}));

const permissionPosting = Object.keys(PERMISSION_POTING_DIRECTORY).map((k) => ({
  label: String(k).toLowerCase(),
  value: PERMISSION_POTING_DIRECTORY[k],
}));

const displayType = [
  {
    label: "Menu ngang",
    value: "horizontal",
  },
  {
    label: "Menu dọc",
    value: "vertical",
  },
];

const View = (props: IProps) => {
  const { dataUser, dataFolder } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const [postDirectory, setPostDirectory] = useState([]);
  const [postDirectoryRender, setPostDirectoryRender] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [editPosition, setEditPosition] = useState(false);
  const [listFiles, setListFiles] = useState<any>({});
  const [infoDirectory, setInfoDirectory] = useState({
    name: "",
    color: {
      parent: "",
      child: "",
    },
    display_type: "vertical",
  });
  const [lazyParams, setLazyParams] = useState({
    limit: 50,
    page: 1,
  });
  const [members, setMembers] = useState([]);
  // const [totalDocs, setTotalDocs] = useState(0);

  const _isUser = isUser(dataUser.object_type);
  const _isBusiness = isBusiness(dataUser.object_type);
  const _isGroup = isGroup(dataUser.object_type);

  const getMemberProfile = async () => {
    try {
      let res;
      if (_isUser) {
        res = await FriendService.getFriends({
          params: {
            user_id: dataUser._id,
          },
          query: {
            ...lazyParams,
            type: FRIEND_TYPE.FRIEND,
          },
        });
      }
      if (_isBusiness) {
        res = await BusinessService.getMemberJoined({
          params: {
            id: dataUser._id,
          },
          query: {
            ...lazyParams,
          },
        });
      }

      if (_isGroup) {
        res = await GroupService.getMemberJoined({
          params: {
            id: dataUser._id,
          },
          query: {
            ...lazyParams,
          },
        });
      }

      if (res && res.docs) {
        if (res.page === 1) {
          setMembers(res.docs);
        } else {
          setMembers((prev) => prev.concat(res.docs));
        }
        setLazyParams((prev) => ({ ...prev, page: prev.page + 1 }));
        // setTotalDocs(res.totalDocs);
      }
    } catch (error) {}
  };

  const handlePositionItem = () => {
    setEditPosition(!editPosition);
  };

  const getItems = (mapList, parentId) => {
    let items = mapList.filter((e: any) => e.parent_id === parentId);
    items.forEach((el: any) => {
      const items = getItems(mapList, el.id);
      if (items && items != null && items.length > 0) {
        el.children = items;
      }
    });
    return items;
  };

  const custumValueRender = (data) => {
    if (data && data.length > 0) {
      const value = data?.map((el) => ({
        status: DIRECTORY_STATUS.PUBLIC,
        ...el,
      }));

      let tree = value.filter((e: any) => !e.parent_id || e?.parent_id == null);

      tree.forEach((el: any) => {
        const items = getItems(value, el.id);
        if (items && items != null && items.length > 0) {
          el.children = items;
        }
      });
      setPostDirectoryRender(tree);
    }
  };

  const handleTongle = (id: string) => {
    setPostDirectory((prev) =>
      prev.map((el) =>
        el.id === id
          ? {
              ...el,
              show: !Boolean(el?.show),
            }
          : el
      )
    );
  };

  useEffect(() => {
    if (postDirectory && postDirectory.length > 0) {
      custumValueRender(postDirectory);
      getMemberProfile();
    }
  }, [postDirectory]);

  useEffect(() => {
    if (dataFolder) {
      setIsEdit(true);
      custumValueRender(dataFolder.value);
      setPostDirectory(dataFolder.value);
      setInfoDirectory(dataFolder.infoDirectory);
    }
  }, [dataFolder]);

  const checkIsValid = (data) => {
    let isValid = true;
    data?.map((el) => {
      if (!el.name) {
        return (isValid = false);
      }
      el?.children?.map((item) => {
        if (!item.name) {
          return (isValid = false);
        }
      });
    });

    return isValid;
  };

  const handleAdd = (parent_id?: string) => {
    if (parent_id) {
      const parent = postDirectory.find((el) => el.id === parent_id);
      setPostDirectory((prev) => [
        ...prev,
        {
          id: uuidv4(),
          name: "",
          parent_id: parent_id,
          status: parent?.status,
        },
      ]);
    } else {
      setPostDirectory((prev) => [
        ...prev,
        {
          id: uuidv4(),
          name: "",
          parent_id: "",
          status: DIRECTORY_STATUS.PUBLIC,
        },
      ]);
    }
  };

  const handleRemove = (id: string) => {
    setPostDirectory((prev) => prev?.filter((el) => el.id !== id));
  };

  const setFile = (f: any, id: string) => {
    console.log("1111", f, id);
    
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const handleOnChangeInfo = (value, name, key?) => {
    if (name === "color") {
      setInfoDirectory((prev) => ({
        ...prev,
        [name]: {
          ...prev?.[name],
          [key]: value,
        },
      }));
    } else {
      setInfoDirectory((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleOnChange = (value, name, id) => {
    const child = postDirectory.find((el) => el.parent_id === id);
    if (name === "status" && value === DIRECTORY_STATUS.PRIVATE && child) {
      setPostDirectory((prev) =>
        prev?.map((el) =>
          el.parent_id === id || el.id === id
            ? {
                ...el,
                [name]: value,
              }
            : el
        )
      );
    }

    setPostDirectory((prev) =>
      prev?.map((el) =>
        el.id === id
          ? {
              ...el,
              [name]: value,
            }
          : el
      )
    );
  };

  const handleOnChangePermission = (value, name, id) => {
    if (value.includes("load_more")) {
      return getMemberProfile();
    }
    setPostDirectory((prev) =>
      prev?.map((el) =>
        el.id === id
          ? {
              ...el,
              permission: {
                ...(el?.permission || {}),
                [name]: value,
              },
            }
          : el
      )
    );
  };

  const sort = (data) => {
    const dataNotDelTop = postDirectory.filter(
      (el) =>
        el.type === "default_not_deleted" && (!el.position || el.position === 0)
    );
    const dataNotDelBottom = postDirectory.filter(
      (el) => el.type === "default_not_deleted" && el.position === 1
    );
    setPostDirectory([...dataNotDelTop, ...data, ...dataNotDelBottom]);
  };

  const handleSave = async () => {
    try {
      if (!checkIsValid(postDirectory))
        return toast.warning(
          `${t("Folder name")} ${t("message.cant-be-empty")}`
        );
      setLoading(true);
      let listImg;
      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${dataUser?._id}`);

        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
      }
      let data = postDirectory;
      console.log("listImg", listImg, listFiles);
      
      if (listImg && listImg?.length > 0) {
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              for (let index = 0; index < data.length + 1; index++) {
                const element = data[index];
                if (String(element?.id) === String(item)) {
                  data = data?.map((d: any, ind: number) =>
                    ind === index ? { ...d, icon: el?.filePath } : d
                  );
                  // data[index] = {
                  //   ...data[index],
                  //   icon: el?.filePath,
                  // };
                }

                if (element?.children?.length > 0) {
                  data = data?.map((d: any, ind: number) =>
                    ind === index
                      ? {
                          ...d,
                          children: d?.children.map((chil) =>
                            String(chil.id) === String(item)
                              ? {
                                  ...chil,
                                  icon: el?.filePath,
                                }
                              : chil
                          ),
                        }
                      : d
                  );

                  // data[index] = {
                  //   ...data[index],
                  //   children: data[index]?.children.map((chil) =>
                  //     String(chil.id) === String(item)
                  //       ? {
                  //           ...chil,
                  //           icon: el?.filePath,
                  //         }
                  //       : chil
                  //   ),
                  // };
                }
              }
            }
          });
        });
      }

      const checkItemsNotParent = (id: string) => {
        if (!id) return true;
        return data.find((el) => el.id === id);
      };
      if (!isEdit) {
        const res = await PostDirectoryService.createPostDirectory({
          body: {
            user_id: dataUser._id,
            value: data.filter(
              (el) =>
                el.type !== "default_not_deleted" &&
                checkItemsNotParent(el.parent_id)
            ),
          },
        });
        if (res) {
          dispatch(
            getPostDirectoryByUserIdAsync({
              params: {
                userId: dataUser._id,
              },
            })
          );
          history.goBack();
        }
      } else {
        const res = await PostDirectoryService.editPostDirectory({
          params: {
            id: dataFolder._id,
          },
          body: {
            user_id: dataUser._id,
            infoDirectory: infoDirectory,
            value: data.filter(
              (el) =>
                el.type !== "default_not_deleted" &&
                checkItemsNotParent(el.parent_id)
            ),
          },
        });
        if (res) {
          dispatch(
            getPostDirectoryByUserIdAsync({
              params: {
                userId: dataUser._id,
              },
            })
          );
          history.goBack();
        }
      }

      setLoading(false);
    } catch (error) {
      console.log("error", error);

      setLoading(false);
    }
  };

  const handleCancel = () => {
    if (editPosition) {
      return setEditPosition(false);
    }
    history.goBack();
  };

  const renderPostDirectory = (el) => {
    const parent = postDirectory?.find((pa: any) => pa.id === el.parent_id);
    return (
      <>
        <h5 className="fw-">{el.name}</h5>
        <div className="d-flex mt-2">
          <VIcon width={16} height={32} name="list-six-dot.svg" />
          <div className="w-100 ms-2">
            <div>
              <div className="setting-profile-post-directory">
                <div className="d-flex justify-content-between w-100">
                  <Form.Group className="form-group">
                    <VInput
                      id="name"
                      name="name"
                      defaultValue={el.name}
                      onChange={(e) =>
                        handleOnChange(e.target.value, "name", el.id)
                      }
                    />
                  </Form.Group>
                  <div className="_option-status">
                    <Dropdown
                      id="status"
                      options={listStatusPostDirectory?.map((el: any) => {
                        return {
                          icon: el.logo,
                          label: t(el.label),
                          value: el.value,
                          isDisabled:
                            el?.value === DIRECTORY_STATUS.VIP ||
                            el?.value === DIRECTORY_STATUS.FRIEND,
                        };
                      })}
                      disabled={parent?.status === DIRECTORY_STATUS.PRIVATE}
                      value={el.status}
                      onChange={(e) =>
                        handleOnChange(e.target.value, "status", el.id)
                      }
                    />
                  </div>
                </div>
                <VIcon onClick={() => handleRemove(el.id)} name="remove.svg" />
              </div>
              <div className="d-flex mb-3">
                <div className="upload-icon-post-directory">
                  <Label>{t("Icon")}</Label>
                  <div className="item-upload">
                    <UploadFile
                      id={el.id}
                      src={el.icon}
                      ratio="340x340"
                      file={listFiles?.[el.id]}
                      // isCrop={true}
                      setFile={setFile}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <Label>{t("Posting rights")}</Label>
              <div className="d-flex flex-wrap flex-md-nowrap">
                <div className="col-12 col-md-6 me-md-1">
                  <Dropdown
                    id="status"
                    options={permissionPosting?.map((el: any) => {
                      return {
                        label: t(el.label),
                        value: el.value,
                      };
                    })}
                    value={el.permission?.type}
                    onChange={(e) =>
                      handleOnChangePermission(e.target.value, "type", el.id)
                    }
                  />
                </div>
                {el.permission?.type !==
                  PERMISSION_POTING_DIRECTORY.ALL_MEMBER_OR_FRIENDS &&
                  el.permission?.type && (
                    <div className="col-12 col-md-6 ms-md-1">
                      <Dropdown
                        id="status"
                        isMulti={true}
                        options={[
                          ...members?.map((el: any) => {
                            return {
                              label: (
                                <VNameVerify
                                  className={
                                    "mb-0 d-flex align-items-center justify-content-center cursor-pointer"
                                  }
                                  dataUser={el.to}
                                />
                              ),
                              value: el.to?._id,
                            };
                          }),
                          {
                            label: "Load more",
                            value: "load_more",
                          },
                        ]}
                        value={el.permission?.list}
                        onChange={(e) =>
                          handleOnChangePermission(e, "list", el.id)
                        }
                      />
                    </div>
                  )}
              </div>
            </div>
            {el.show && (
              <div className="ms-5">
                {el?.children?.length > 0 &&
                  el?.children.map((item) => renderPostDirectory(item))}
                {!parent?.parent_id && (
                  <div
                    className="add-post-directory"
                    onClick={() => handleAdd(el.id)}
                  >
                    +
                  </div>
                )}
              </div>
            )}
            {!parent?.parent_id && (
              <div
                className="cursor-pointer text-primary"
                onClick={() => handleTongle(el.id)}
              >
                {t(el.show ? "Collapse" : "See more")}
              </div>
            )}
          </div>
        </div>
      </>
    );
  };

  return (
    <Card>
      <Card.Header>
        <h1 className="fs-4">{t("Category")}</h1>
      </Card.Header>
      <Card.Body>
        {!editPosition && (
          <div className="d-flex justify-content-between align-items-start mb-2">
            <div className="w-50">
              <Dropdown
                id="style"
                label={"Kiểu hiển thị"}
                options={displayType?.map((el: any) => {
                  return {
                    label: t(el.label),
                    value: el.value,
                  };
                })}
                value={infoDirectory?.display_type}
                onChange={(e) =>
                  handleOnChangeInfo(e.target.value, "display_type")
                }
              />
            </div>
            <VButton
              loading={loading}
              height={"30px"}
              className="btn btn-primary py-0"
              onClick={() => handlePositionItem()}
            >
              {t(editPosition ? "Back" : "Edit location")}
            </VButton>
          </div>
        )}
        {editPosition ? (
          <>
            {postDirectory
              ?.filter(
                (el) =>
                  el.type === "default_not_deleted" &&
                  !el.parent_id &&
                  (!el.position || el.position === 0)
              )
              ?.map((item) => (
                <div key={item.id} className={`opacity-50`}>
                  <div className="d-flex align-item-center">
                    <VIcon width={16} name="list-six-dot.svg" />
                    <h5 className="ms-2">{item.name}</h5>
                  </div>
                </div>
              ))}
            <VSortable
              arrayDefault={postDirectoryRender}
              listSort={postDirectoryRender
                ?.filter((el) => el.type !== "default_not_deleted")
                ?.map((el) => ({
                  oldId: el.id,
                  item: (
                    <div key={el.id} className={`cursor-pointer`}>
                      <div className="d-flex align-item-center">
                        <VIcon width={16} name="list-six-dot.svg" />
                        <h5 className="ms-2">{el.name}</h5>
                      </div>
                    </div>
                  ),
                }))}
              handleEndSort={(e) => sort(e)}
            />
            {postDirectory
              ?.filter(
                (el) =>
                  el.type === "default_not_deleted" &&
                  !el.parent_id &&
                  el.position === 1
              )
              ?.map((item) => (
                <div key={item.id} className={`opacity-50`}>
                  <div className="d-flex align-item-center">
                    <VIcon width={16} name="list-six-dot.svg" />
                    <h5 className="ms-2">{item.name}</h5>
                  </div>
                </div>
              ))}
          </>
        ) : (
          <>
            <div className="">
              <Label>{t("Name of the diary")}</Label>
              <div>
                <VInput
                  id="name"
                  name="name"
                  type="text"
                  value={infoDirectory?.name || t("Table of Contents Diary")}
                  onChange={(e) => handleOnChangeInfo(e.target.value, "name")}
                />
              </div>
            </div>
            <div className="d-flex mt-2">
              <Label>{t("Color Level 1")}</Label>
              <div className="setting-color-post-directory">
                <VInput
                  id="colorfield"
                  name="color"
                  type="color"
                  defaultValue={infoDirectory?.color?.parent}
                  onChange={(e) =>
                    handleOnChangeInfo(e.target.value, "color", "parent")
                  }
                />
              </div>
            </div>
            <div className="d-flex">
              <Label>{t("Color Level 2")}</Label>
              <div className="setting-color-post-directory">
                <VInput
                  id="colorfield"
                  name="color"
                  type="color"
                  defaultValue={infoDirectory?.color?.child}
                  onChange={(e) =>
                    handleOnChangeInfo(e.target.value, "color", "child")
                  }
                />
              </div>
            </div>
            {postDirectoryRender?.length > 0 &&
              postDirectoryRender?.map((el) => (
                <div
                  key={el.id}
                  className={
                    el.type === "default_not_deleted"
                      ? "pe-none opacity-50"
                      : ""
                  }
                >
                  {renderPostDirectory(el)}
                </div>
              ))}
            <div className="add-post-directory" onClick={() => handleAdd()}>
              +
            </div>
          </>
        )}

        <div className="mt-4 d-flex justify-content-center">
          <VButton
            loading={loading}
            onClick={handleCancel}
            height={"36px"}
            color={"text-primary"}
            className="bg-secondary border-secondary mx-1"
          >
            {t(editPosition ? "Back" : "Cancel")}
          </VButton>
          <VButton
            loading={loading}
            height={"36px"}
            className="btn btn-primary mx-1"
            onClick={handleSave}
          >
            {t("save")}
          </VButton>
        </div>
      </Card.Body>
    </Card>
  );
};

export default View;

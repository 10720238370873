import { createAsyncThunk } from "@reduxjs/toolkit";
import FriendService from "api/friends";
import { IRequestData } from "api/request";

export const getFriendRequestsAsync = createAsyncThunk(
  "friends/requests",
  async (req: IRequestData) => {
    try {
      const response: any = await FriendService.getRequest(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getFriendSuggestsAsync = createAsyncThunk(
  "friends/suggest",
  async (req: IRequestData) => {
    try {
      const response: any = await FriendService.getSuggests(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getFollowSuggestsAsync = createAsyncThunk(
  "friends/suggest/follow",
  async (req: IRequestData) => {
    try {
      const response: any = await FriendService.getSuggests(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

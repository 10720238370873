import { Container } from "react-bootstrap";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";

import VButton from "components/v-button";
import { getBusinessRequestAsync } from "redux/reducers/businesses/businesses.action";
import { getRequestJoinBusinesses } from "redux/reducers/businesses/businesses.selector";
import VObjectCard from "components/v-objects/v-business-card";
import { BUSINESS_ACTION } from "config/enums";
import { removeBusinessRequestJoin } from "redux/reducers/businesses/businesses.reducer";

const View = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const businesses: any = useAppSelector(getRequestJoinBusinesses);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = businesses;

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getBusinessRequestAsync({
        query: {
          page,
          limit,
        },
      })
    );

    setLoading(false);
  };

  const handleViewMore = () => {
    getData({
      page: page + 1,
    });
  };

  const reload = ({ to_id }) => {
    dispatch(removeBusinessRequestJoin(to_id));
  };

  return (
    <>
      <div>
        <Container>
          <div className="row">
            {businesses &&
              (businesses.docs || []).map((business, index) => (
                <VObjectCard
                  key={index}
                  col_lg={3}
                  col_md={4}
                  business={{
                    ...business,
                    ...business.to,
                  }}
                  type={BUSINESS_ACTION.CANCEL_REQUEST}
                  reload={reload}
                />
              ))}
          </div>
        </Container>

        <div className="col-12">
          {page < businesses.totalPages && (
            <div className="text-center p-2">
              <VButton
                type="button"
                loading={loading}
                size={"small"}
                onClick={handleViewMore}
              >
                {" "}
                {t("View more")}
              </VButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import VTab from "components/v-tab";
import useTranslation from "hooks/useTranslation";
// import VButtonCircleIcon from "components/v-button-circle-icon";
import VAvatar from "components/v-avatar";
import { ROUTES } from "config/routes";
import { useHistory } from "react-router-dom";
import VIcon from "components/v-icon";
import { checkDateOfBirthCurrent } from "utils/times";
import SearchService from "api/search";
import VNameVerify from "components/v-objects/v-name-verify";
import { renderOnline } from "utils/render";
import { useAppSelector } from "redux/reducers/hook";
import { getUserOnline } from "redux/reducers/users/users.selector";
// import { getUrlProfile } from "utils/common";
import VPageBackgrounds from "components/v-page-background";
import { useDispatch } from "react-redux";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import VRightView from "components/v-right-view";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import VWebView from "components/v-web-view";
// import Card from "components/v-teamplate/Card";
import useQuery from "hooks/useQuery";

const RightSidebar = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const menus = useAppSelector(getMenusByKey("tab_right_sidebar"));
  const query = useQuery();

  const [birthDayToday, setBirthDayToday] = useState([]);
  const [loadMenu, setLoadMenu] = useState(false);

  const [tab, setTab] = useState(menus.find((menu) => menu.order === 0));
  // const [lazyParams] = useState({
  //   limit: 50,
  //   page: 1,
  // });
  // const [friends, setFriends] = useState([]);
  const usersOnline = useAppSelector(getUserOnline);
  const minirightsidebar = () => {
    document.getElementById("rightSidebar").classList.toggle("right-sidebar");
    document.body.classList.toggle("right-sidebar-close");
  };

  // const handleClick = (data) => {
  //   history.push(getUrlProfile(data));
  // };

  // const getData = async () => {
  //   try {
  //     const userOnlines: any = await UserService.getUserOnline({
  //       query: lazyParams,
  //     });
  //     if (userOnlines) setFriends(userOnlines.docs);
  //   } catch (error) {}
  // };
  const getDataBirthday = async () => {
    try {
      const res: any = await SearchService.getBirthday();
      if (res) {
        setBirthDayToday(res.today || []);
      }
    } catch (error) {}
  };

  const onOpenMiniChat = (el: any) => {
    // history.push(`${ROUTES.CONVERSATION.replace(":id", el.user_id)}`);
    dispatch(
      setOpenWebModulesPopupAction({
        openWebModuleKey: "mini_chat_detail",
        payload: {
          userIdMiniChat: el.user_id,
        },
      })
    );
  };

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  useEffect(() => {
    // getData();
    getDataBirthday();
  }, []);

  const renderTab = () => {
    if (tab && tab.type_view === "internal") {
      if (tab.key === "advertisement") {
        return (
          <div className="media-height" data-scrollbar="init">
            <VRightView />
          </div>
        );
      }
      if (tab.key === "chat")
        return (
          <div className="media-height p-3" data-scrollbar="init">
            {usersOnline?.length > 0 &&
              usersOnline.map((el, index) => (
                <div
                  key={index}
                  className="d-flex align-items-center pb-2 mb-2 border-bottom"
                  onClick={onOpenMiniChat.bind(null, el)}
                >
                  <VAvatar
                    className="m-0"
                    size={"40px"}
                    rezise={50}
                    fontSizeIconVerify={12}
                    additionalData={renderOnline(
                      el.user_id,
                      el.status,
                      el.last_time_online,
                      t,
                      history
                    )}
                    statusOnline={el.status}
                    // onClickAvatar={(_) => handleClick(el.user)}
                    // onClickName={(_) => handleClick(el.user)}
                    userData={{
                      ...el.user,
                      profile: el.profile,
                    }}
                  />
                  {checkDateOfBirthCurrent(el.profile?.birthday) && (
                    <div className="ms-2 mb-2">
                      <VIcon name={"birthday-cake.svg"} />
                    </div>
                  )}
                </div>
              ))}
          </div>
        );
    }
    if (tab && tab.type_view === "webview") {
      return (
        <VWebView
          url={tab.url}
          widthScrollbar={tab?.width_scrollbar}
          height="700px"
        />
      );
    }
    return <></>;
  };

  return (
    <>
      <div className="right-sidebar-mini" id="rightSidebar">
        <VPageBackgrounds
          page={"page_bg_right_sidebar"}
          width="100%"
          height="100%"
          position={"absolute"}
        />
        <div className="right-sidebar-panel p-0">
          <div className="shadow-none">
            <div className="p-0">
              <div>
                {birthDayToday?.length > 0 && (
                  <div
                    className="mx-3 pt-3 cursor-pointer"
                    onClick={() => history.push(ROUTES.BIRTHDAY)}
                  >
                    <span className="fw-bold lh-lg fs-4">{t("birthday")}</span>
                    <div className="d-flex">
                      <div className="me-2">
                        <VIcon name={"birthday-cake.svg"} />
                      </div>
                      <span>
                        {t("Today is 's birthday")}{" "}
                        <div className="position-relative d-inline-block">
                          <VNameVerify
                            className={"mb-0 d-flex align-items-center"}
                            dataUser={birthDayToday[0]}
                            fontSizeIconVerify={12}
                          />
                        </div>{" "}
                        {birthDayToday?.length > 1 && (
                          <>
                            {t("and")}{" "}
                            <strong>{birthDayToday?.length - 1}</strong>{" "}
                            {t("others")}
                          </>
                        )}
                      </span>
                    </div>
                  </div>
                )}
                <div className="d-flex justify-content-between">
                  {/* <span className="fw-bold lh-lg fs-4">{t("chatting")}</span> */}
                  {/* <div className="d-flex gap-2">
                    <VButtonCircleIcon icon="setting.svg" onClick={(e) => { }} />
                    <VButtonCircleIcon icon="make_friend_with_color.svg" onClick={(e) => { }} />
                  </div> */}
                </div>
                <div className="">
                  <VTab
                    tab={tab}
                    setTab={setTab}
                    menus={menus}
                    activeBorder="bottom"
                    noBackground={true}
                  />
                </div>
              </div>
              {/* <MiniChat minusHeight={birthDayToday?.length > 0} /> */}
              {renderTab()}

              <div
                className="right-sidebar-toggle bg-primary text-white mt-3 d-flex"
                onClick={minirightsidebar}
              >
                <span className="material-symbols-outlined">chat</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RightSidebar;

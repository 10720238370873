/* eslint-disable indent */
import { Link, useLocation } from "react-router-dom";

import Card from "components/v-teamplate/Card";
// import { ROUTES } from "config/routes";
import useTranslation from "hooks/useTranslation";
import { getUrlImage } from "utils/common";
import useQuery from "hooks/useQuery";
import { useAppSelector } from "redux/reducers/hook";
import {
  getMenus,
} from "redux/reducers/settings/settings.selector";

const View = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const query = useQuery();
  const searchMenus = useAppSelector(getMenus)?.filter(
    (e) => e?.position === "search_option"
  );

  return (
    <Card>
      <div className="sidebar_settings">
        <Card.Body>
          {searchMenus.map((menu) => (
            <div className="sidebar-item">
              <Link
                className={`${
                  location.pathname === menu?.url ? "active" : ""
                } nav-link `}
                aria-current="page"
                to={`${menu?.url}?search=${query.search || ""}`}
              >
                <img
                  width={20}
                  height={20}
                  src={
                    (menu?.img_icon && getUrlImage(menu?.img_icon, 50)) ||
                    "/assets/icons/default/search/people.svg"
                  }
                  alt=""
                />
                <span className="item-name">{t(menu?.name)}</span>
              </Link>
            </div>
          ))}
        </Card.Body>
      </div>
    </Card>
  );
};
export default View;

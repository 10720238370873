/* eslint-disable react-hooks/exhaustive-deps */
import VWebView from "components/v-web-view";
import useQuery from "hooks/useQuery";
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import qs from "query-string";

const View = () => {
  const { id } = useParams();
  const { i18n } = useTranslation();
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const dispatch = useDispatch();
  const query = useQuery();

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col className="col-12">
          <VWebView
            key={`pay/${id}`}
            url={`${
              process.env.REACT_APP_URL_SHOP
            }/pay/${id}?token=${localStorage.getItem(
              "auth"
            )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&lang=${
              i18n.language ? `${i18n.language}` : ""
            }&${qs.stringify(query)}`}
            className="iframe-pay"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import VButton from "components/v-button";
import SelectRegions from "components/v-location";
import VInput from "components/v-input";
import VTextarea from "components/v-textarea";

import useTranslation from "hooks/useTranslation";
import APIsUser from "api/users";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/reducers/hook";
import {
  getUserAsync,
  getUserByIdAsync,
} from "redux/reducers/users/users.actions";

import FromTemplateService from "api/form-templates";
import VForm from "components/v-form";
import APIs from "api";
import { CHANNEL_TYPE, OBJECT_TYPE } from "config/enums";
import VDropdown from "components/v-dropdown";
import WebsiteSetting from "../utils/Website-settings";
import WorkTimeSetting from "../utils/Work-time-settings";

const group_type = Object.keys(CHANNEL_TYPE).map((k) => ({
  name: String(k).toLowerCase(),
  value: CHANNEL_TYPE[k],
}));

const FormSettingChannel = (props) => {
  const { isCreate, data, validateCreate, setDataCreate, isMyProfile } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(data?.profile);
  const [address, setAddress] = useState<any>({});

  const [isValid, setIsValid] = useState(false);
  const [fieldAdditionalData, setFieldAdditionalData] = useState(null);
  const [additionalData, setAdditionalData] = useState({});
  const [listFiles, setListFiles] = useState<any>({});
  const [validate, setValidate] = useState<any>(null);
  const [businessName, setBusinessName] = useState("");

  useEffect(() => {
    if (isCreate) {
      setDataCreate({
        ...state,
        full_name: businessName,
        email: state?.email,
        phone: state?.phone,
        object_type: OBJECT_TYPE.CHANNEL,
        location: address,
      });
    }
  }, [isCreate, state, address, businessName]);

  useEffect(() => {
    if (state?.location) {
      setAddress(state.location);
    }
    if (state?.additionalData) {
      setAdditionalData(state.additionalData);
    }
  }, [state]);

  useEffect(() => {
    if (!fieldAdditionalData || fieldAdditionalData?.fields?.length === 0) {
      setIsValid(true);
    }
  }, [fieldAdditionalData]);

  useEffect(() => {
    // if (!isCreate) {
    getFormTemplateProfile();
    // }
  }, [data.object_type]);

  const handleCancel = () => {
    const search = new URLSearchParams(window.location.search);
    search.delete("isEdit");
    history.push(`?${search}`);
  };

  const handleChange = (value, name) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleChangeBusinessInfo = (value, name) => {
    setState((prev) => ({
      ...prev,
      business_info: {
        ...prev?.business_info,
        [name]: value || null,
      },
    }));
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const handleEdit = async () => {
    try {
      if (!isValid) return;
      setLoading(true);

      let listImg;
      let newDataAddtional = { ...additionalData };
      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${data?._id}`);
        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
      }

      if (listImg && listImg?.length > 0) {
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              newDataAddtional = {
                ...newDataAddtional,
                [item]: el?.filePath,
              };
            }
          });
        });
      }

      const newData = {
        location: address,
        birthday: state.birthday,
        gender: state.gender,
        description: state.description,
        slogan: state.slogan,
        position: state.position,
        hobbies: state.hobbies,
        career_id: state.career_id,
        category_field_id: state.category_field_id,
        nation_id: state.nation_id,
        religion_id: state.religion_id,
        additionalData: newDataAddtional,
        business_info: state.business_info,
        work_time: state.work_time,
        secondary_site: state.secondary_site,
      };
      const res: any = await APIsUser.updateProfile({
        body: {
          ...newData,
          user_id: data._id,
        },
      });
      if (businessName) {
        await APIsUser.editUser({
          body: {
            full_name: businessName,
            user_id: data._id,
          },
        });
      }
      if (res) {
        if (isMyProfile) dispatch(getUserAsync());
        dispatch(
          getUserByIdAsync({
            params: {
              id: data._id,
              user_id: data._id,
            },
          })
        );
        handleCancel();
        toast.success(t(`${res}`));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(`${error?.errors}`));
    }
  };

  const getFormTemplateProfile = async () => {
    try {
      const res: any = await FromTemplateService.getForm({
        query: {
          type: "profile_setting",
          object_type: data?.object_type,
        },
      });
      if (res) {
        setFieldAdditionalData(res);
      }
    } catch (error) {
      setFieldAdditionalData([]);
    }
  };

  return (
    <Form>
      <Form.Group className="form-group col-12">
        <VInput
          label={t("Channel name")}
          type="text"
          id="channel_name"
          name="channel_name"
          defaultValue={data.full_name}
          errMessage={validateCreate?.full_name}
          onChange={(e) => setBusinessName(e.target.value)}
        />
      </Form.Group>
      {isCreate && (
        <Form.Group className="form-group col-12">
          <VInput
            label={t("Phone")}
            type="text"
            id="phone"
            name="phone"
            errMessage={validateCreate?.phone}
            onChange={(e) => handleChange(e.target.value, "phone")}
          />
        </Form.Group>
      )}
      {/* <Form.Group className="form-group col-12">
        <VInput
          label={t("Founding")}
          type="date"
          id="birthday"
          name="birthday"
          defaultValue={
            state?.birthday
              ? new Date(state?.birthday)?.toISOString()?.slice(0, 10)
              : ""
          }
          onChange={(e) => handleChange(e.target.value, "birthday")}
        />
      </Form.Group> */}
      <Form.Group className="form-group col-md-12">
        <Form.Label>{t("Choose privacy")}</Form.Label>
        <VDropdown
          key={"group_type"}
          placeholder={t("Choose privacy")}
          options={group_type?.map((el: any) => {
            return {
              label: t(el.name),
              value: String(el.value),
              isDisabled: false,
            };
          })}
          value={state?.group_type}
          onChange={(e) => handleChange(e.target.value, "group_type")}
        />
      </Form.Group>
      {state?.group_type === "vip" && (
        <Form.Group className="form-group col-12">
          <VInput
            label={t("Price")}
            type="number"
            id="price"
            name="price"
            errMessage={validateCreate?.price}
            onChange={(e) => handleChange(e.target.value, "price")}
          />
        </Form.Group>
      )}

      {/* setting work time */}
      <WorkTimeSetting state={state} setState={setState} />

      <div className="mb-2">
        <Form.Label>{t("Address")}</Form.Label>
        <SelectRegions
          hideLabel={true}
          hideDetail={false}
          isDisabled={false}
          address={address}
          isShowMap={true}
          setAddress={setAddress}
        />
      </div>
      <Form.Group className="form-group col-12">
        <VInput
          label={t("Website")}
          type="text"
          id="website"
          name="website"
          defaultValue={state?.business_info?.website}
          onChange={(e) => handleChangeBusinessInfo(e.target.value, "website")}
        />
      </Form.Group>
      {/* setting web site */}
      <WebsiteSetting state={state} setState={setState} />
      <div className="mb-2">
        <VTextarea
          label={t("Introduce")}
          id="description"
          name="description"
          defaultValue={state?.description}
          onChange={(e) => handleChange(e.target.value, "description")}
        />
      </div>
      {fieldAdditionalData?.fields?.length > 0 && (
        <div className="row">
          <VForm
            // colItem={"col-6"}
            fields={fieldAdditionalData?.fields}
            additionalData={additionalData}
            listFiles={listFiles}
            validate={validate}
            setListFiles={setListFiles}
            setValidate={setValidate}
            setIsValid={setIsValid}
            setFile={setFile}
            setAdditionalData={setAdditionalData}
          />
        </div>
      )}
      {!isCreate && (
        <>
          <VButton
            loading={loading}
            height={"36px"}
            className="btn btn-primary me-2"
            disabled={!isValid}
            onClick={handleEdit}
          >
            {t("Save")}
          </VButton>
          <VButton
            loading={loading}
            onClick={handleCancel}
            height={"36px"}
            className="btn bg-danger border-danger"
          >
            {t("Cancel")}
          </VButton>
        </>
      )}
    </Form>
  );
};

export default FormSettingChannel;

/* eslint-disable indent */
import Button from "components/v-button";
import useTranslation from "hooks/useTranslation";
const Step2 = ({
  setDataByKey,
  nextStep,
  depositBanks = [],
  dataPayment,
  backStep,
}) => {
  const { t } = useTranslation();
  const onclickBank = (b) => {
    setDataByKey("bank", b);
  };

  return (
    <div className="step-0">
      <div className="submit-step">
        {depositBanks
          .filter((b) => dataPayment.network === b.network)
          .map((b) => {
            return (
              <Button
                className={`btn-currency ${dataPayment.bank &&
                  dataPayment.bank.account_name === b.account_name
                  ? "active"
                  : ""
                  }`}
                key={b.name}
                height="40px"
                color="green100"
                onClick={onclickBank.bind(null, b)}
              >
                {t("Transfer to")} <b>{b.account_name}</b> ({b.bank_name})
              </Button>
            );
          })}
        <br />
        <Button onClick={() => nextStep()} disabled={!dataPayment.bank}>
          {t("Continue")}
        </Button>
        <Button transparent={true} onClick={() => backStep()}>
          {t("Back")}
        </Button>
      </div>
    </div>
  );
};

export default Step2;

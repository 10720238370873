import { IRootState } from "..";

export const getRequestJoinGroups = (state: IRootState) =>
  state.groups.requests_join;

export const getSuggestGroups = (state: IRootState) => state.groups.suggests;

export const getDataAccesses = (state: IRootState) =>
  state.friends.dataAccesses;

export const getJoinedGroups = (state: IRootState) => state.groups.joined;

export const getManageGroups = (state: IRootState) => state.groups.manage;

export const getInvitedGroups = (state: IRootState) =>
  state.groups.invited;
import { IRootState } from "..";

export const getReward = (state: IRootState) => state.settings.reward;
export const getadvertisementPosition = (state: IRootState) =>
  state.settings.adPostition;
export const getChannel = (state: IRootState) => state.settings.channel;
export const getDocuments = (state: IRootState) => state.settings.documents;
export const getNfts = (state: IRootState) => state.settings.nfts;
export const getQA = (state: IRootState) => state.settings.qa;
export const getProjects = (state: IRootState) => state.settings.projects;
export const getSupports = (state: IRootState) => state.settings.supports;
export const getBanner = (state: IRootState) => state.settings.banner;
export const getCities = (state: IRootState) => state.settings.cities;
export const getLocations = (state: IRootState) => state.settings.locations;
export const getRateUSDToVND = (state: IRootState) => state.settings.usd_vnd;
export const getObjects = (state: IRootState) => state.settings.objects;
export const getLogo = (state: IRootState) => state.settings.logos;
export const getMenus = (state: IRootState) => state.settings.menus;
export const getCareers = (state: IRootState) => state.settings.careers;
export const getHobbies = (state: IRootState) => state.settings.hobbies;
export const getFields = (state: IRootState) => state.settings.categoryField;
export const getIconSet = (state: IRootState) => state.settings.icon_set;
export const getCompanions = (state: IRootState) => state.settings.companions;
export const getMenusByKey = (position: string) => (state: IRootState) => {
  return state.settings.menus.filter((a) => a.position === position);
};
export const getNations = (state: IRootState) => state.settings.nations;
export const getLocales = (state: IRootState) => state.settings.locales;
export const getReligions = (state: IRootState) => state.settings.religions;
export const getLanguages = (state: IRootState) => state.settings.languages;
export const getUtilityCategories = (state: IRootState) =>
  state.settings.utility_categories;
export const getCityCurrent = (state: IRootState) => state.settings.city;
export const getWeatherCurrent = (state: IRootState) => state.settings.weather;
export const getForecaseCurrent = (state: IRootState) =>
  state.settings.forecast;
export const getLocationCurrent = (state: IRootState) =>
  state.settings.location;
export const getCensorPolicy = (state: IRootState) =>
  state.settings.censor_policy;
export const getGeneral = (state: IRootState) => state.settings.general;
export const getFamilies = (state: IRootState) => state.settings.families;
export const getRoles = (state: IRootState) => state.settings.roles;
export const getTooltip = (state: IRootState) => state.settings.tooltip;
export const getFrameUseDefault = (state: IRootState) =>
  state.settings.frame_default;
export const getIsApp = (state: IRootState) => state.settings.is_app;
export const getIsPreview = (state: IRootState) => state.settings.is_preview;
export const getUserSettings = (state: IRootState) => state.settings.user_settings;

import { TreeSelect } from "antd";

type ITreeSelectProps = {
  placeholder?: string;
  options: any[];
  onChange: (e: string) => void;
  className?: string;
  label?: string;
  value?: any;
  expandDefault?: boolean
};

export default function TreeSelectComponent(props: ITreeSelectProps) {
  const { label, placeholder, options, onChange, className, value, expandDefault } = props;

  const handleChangeDropdown = (e: string) => {
    onChange(e);
  };

  return (
    <>
      {label && <span className="text-xs">{label}</span>}
      <TreeSelect
        className={`${className ? className : ""}`}
        style={{ width: "100%" }}
        dropdownStyle={{ maxHeight: 400, overflow: "auto", zIndex:"100000" }}
        placeholder={placeholder || "Chọn"}
        allowClear
        treeDefaultExpandAll={expandDefault}
        onChange={(e) => handleChangeDropdown(e)}
        treeData={options}
        value={value}
      />
    </>
  );
}

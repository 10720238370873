/* eslint-disable react-hooks/exhaustive-deps */
import NotificationService from "api/notifications";
import { onMessageListener, requestForToken } from "firebases/firebase";
import React, { useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { getFriendRequestsAsync } from "redux/reducers/friends/friends.action";
import { useAppDispatch } from "redux/reducers/hook";
import { setNotification } from "redux/reducers/notifications/notifications.reducers";
import { getUrlImage } from "utils/common";

const Notification = () => {
  const [number, setNumber] = useState(0);
  const dispatch = useAppDispatch();
  const audioPlayer = useRef(null);

  useEffect(() => {
    try {
      requestForToken();
      const channel = new BroadcastChannel("notifications");
      channel.addEventListener("message", (event) => {
        createNotification(event.data?.data);
      });
    } catch (error) {}
  }, []);

  useEffect(() => {
    onMessageListener()
      .then((payload: any) => {
        console.log({
          payload,
        });
        const { body, image, title } = payload.notification;
        createNotification(payload.data);
        if (body) {
          setNumber((prev) => prev + 1);
          toast(
            <div className="toast_notification">
              <img src={getUrlImage(image)} alt="" />
              <div className="title_body">
                <div className="title">{title}</div>
                <div className="body">{body}</div>
              </div>
            </div>
          );
        }
      })
      .catch((err) => console.log("failed: ", err));
  }, [number]);

  const createNotification = async (data) => {
    const { type } = data;
    if (type && type === "ADD_FRIEND") {
      dispatch(
        getFriendRequestsAsync({
          query: {
            page: 1,
            limit: 10,
          },
        })
      );
    }
    const res: any = await NotificationService.getNotifications({
      query: {
        limit: 1,
        page: 1,
      },
    });
    if (res && res.docs && res.docs.length > 0) {
      playAudio();
      dispatch(setNotification(res.docs[0]));
    }
  };

  function playAudio() {
    audioPlayer.current.play();
  }

  return (
    <>
      <audio ref={audioPlayer} src={"/assets/notification.mp3"} />
    </>
  );
};

export default Notification;

import { IRootState } from "..";

export const getSuggestChannels = (state: IRootState) => state.channels.suggests;

export const getDataAccesses = (state: IRootState) =>
  state.friends.dataAccesses;

export const getFollowingChannels = (state: IRootState) => state.channels.followings;

export const getManageChannels = (state: IRootState) => state.channels.manage;

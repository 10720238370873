import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import { convertUrl, getUrlImage } from "utils/common";

const View = (props) => {
  const { data, resize_image } = props;
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true
  };

  const openWindown = (link: string) => {
    window.open(convertUrl(link))
  }

  return <>
    <Slider {...settings}>
      {
        data?.map(item => 
          <img 
            className={item.link ? "cursor-pointer" : ""} 
            src={getUrlImage(item?.src, resize_image || 1000)} alt="" onClick={() => item.link && openWindown(item.link)} />
        )
      }
		 </Slider>
  </>
}

export default View
/* eslint-disable no-useless-concat */
/* eslint-disable indent */
import { Col, Row } from "reactstrap";
import { renderAmount } from "utils/render";
import { BsFillInfoCircleFill } from "react-icons/bs";
import ToolTip from "components/v-tooltip/View";
import Card from "components/v-teamplate/Card";
import { getSymbolStock } from "utils/common";
import useTranslation from "hooks/useTranslation";

const View = (props: any) => {
  const { t } = useTranslation();
  const renderNote = (el) => {
    return <span>{el.note || ""}</span>;
  };

  return (
    <Card>
      <div className="p-3">
        <div>
          <p>
            {" "}
            {props.type === "deposit" ? t("Deposit") : t("Withdraw")}{" "}
            {getSymbolStock(process.env.REACT_APP_STOCK_DEFAULT)} {t("history")}
          </p>
        </div>
        {props.lists.length &&
          props.lists.map((el: any, index: number) => (
            <div
              key={index}
              className={`p-2 ${
                index < props.lists.length - 1 ? "border-bottom" : ""
              }`}
            >
              <Row lg={12}>
                <Col xs={8} sm={6}>
                  <div className="estock">
                    <h6>{t("Reference")}: {el.content}</h6>
                    <h6>{new Date(el.updatedAt).toLocaleString()}</h6>
                  </div>
                </Col>
                <Col xs={4} sm={6}>
                  <div className="amount">
                    {props.type === "withdraw" ? (
                      <h6>
                        {renderAmount(-el.amount)}{" "}
                        {getSymbolStock(process.env.REACT_APP_STOCK_DEFAULT)}
                      </h6>
                    ) : (
                      <h6>
                        {" "}
                        {renderAmount(el.amount_received)}{" "}
                        {getSymbolStock(process.env.REACT_APP_STOCK_DEFAULT)}
                      </h6>
                    )}
                    <div
                      className={
                        el.status === "pending"
                          ? "pending"
                          : el.status === "success"
                          ? "success"
                          : "declined"
                      }
                    >
                      {el.status === "error" && (
                        <ToolTip
                          id={`${el._id}_statis`}
                          content={renderNote(el)}
                        >
                          <span
                            id={`tooltip-` + `${el._id}_statis`}
                            className="gift"
                          >
                            <BsFillInfoCircleFill color="yellow" />
                          </span>
                        </ToolTip>
                      )}
                      <span>{t(el.status)}</span>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
      </div>
    </Card>
  );
};

export default View;

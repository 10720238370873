/* eslint-disable react-hooks/exhaustive-deps */
import VAvatar from "components/v-avatar";
import VInput from "components/v-input";
import VModal from "components/v-modal";
import useTranslation from "hooks/useTranslation";
import { useState } from "react";
import { toast } from "react-toastify";
import VNameVerify from "../../v-name-verify";
import ProfileService from "api/profiles";
import VButton from "components/v-button";

const View = (props) => {
  const { dataUser, openModal, onLoad, setOpenModal } = props;
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  
  const submit = async () => {
    try {
      setLoading(true);
      const res = await ProfileService.pairing({
        body: {
          user_id: dataUser?._id,
          data
        },
      });
      if (res) {
        toast.success(t("SUCCESS"));
        setOpenModal(false);
        setLoading(false);
        if (onLoad) {
          onLoad({
            dataUser,
          });
        }
      }
    } catch (error: any) {
      toast.error(t(error.errors));
      setLoading(false);
    }
  };

  const onChange = (key: string, value: string) => {
    setData({
      ...data,
      [key]: value,
    });
  };

  return (
    <VModal
      title={t("Pairing")}
      isOpen={openModal}
      loading={loading}
      centered={true}
      showFooter={false}
      children={
        <div>
          <div className="profile-img">
            <div className="img-fluid d-flex justify-content-center">
              <VAvatar
                hideName={true}
                size={"70px"}
                userData={dataUser}
                // handleClickType={() => handleClick("choose_image", "avatar")}
                // handleUpload={handleUploadAvatar}
              />
            </div>
          </div>
          <div className="text-center w-100 fs-5 d-flex flex-wrap justify-content-center align-items-center mt-2">
            <span>{t("Bạn muốn ghép đôi với")}</span>
            <div className="profile-header">
              <div className="user-detail position-relative mt-0 mb-0">
                <div className="profile-detail">
                  <h4
                    style={{
                      maxWidth:
                        dataUser?.full_name?.length > 20 ? "250px" : "100%",
                      margin: "auto",
                    }}
                  >
                    <VNameVerify
                      dataUser={dataUser}
                      fontSize={"16px"}
                      className={"username"}
                    />
                  </h4>
                </div>
              </div>
            </div>
          </div>
          <VInput
            className={"mt-2"}
            label={t("Tên mối quan hệ")}
            value={data?.name}
            onChange={(e) => onChange("name", e.target.value)}
          />
          <div className="text-center mt-3">
            <VButton className="w-44" loading={loading} onClick={submit}>
              {t("Pairing")}
            </VButton>
          </div>
        </div>
      }
      onCancel={() => setOpenModal(false)}
      onConfirm={submit}
    />
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import VTab from "components/v-tab";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import ProfileImages from "./components/profile-images";
import ProfileVideos from "./components/profile-videos";
import Card from "components/v-teamplate/Card";
import ProfileMP3 from "./components/profile-mp3";
import ProfileFiles from "./components/profile-files";
import DropdownModal from "components/v-dropdown-modal/View";
import VIcon from "components/v-icon";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { useDispatch } from "react-redux";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import VModal from "components/v-modal";
import { copyText, getUrlImage } from "utils/common";
import { toast } from "react-toastify";

const View = (props: any) => {
  const { dataUser, isMyProfile, menus, tab, setTab } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const menuPostShare = useAppSelector(getMenusByKey("post_share"));
  const listActionShare = menuPostShare?.filter(
    (share) => !share?.parent_id && share?.key !== "send_to_message"
  );
  const menuPostOtherSharing = menuPostShare?.filter(
    (el) =>
      el?.parent_id ===
      menuPostShare?.find((t) => t.key === "other_sharing")?._id
  );
  const [tabMenu, setTabMenu] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");

  useEffect(() => {
    const tabActive = menus.find((m) => m.key === tab.key);
    const _tabMenu = menus.filter((el) => el.parent_id === tabActive.parent_id);
    setTabMenu(_tabMenu);
  }, [menus]);

  const handleClickOption = (name, value) => {
    if (name === "copy") {
      copyText(window.location.href);
      toast.success(t("Copied to clipboard!"));
    } else {
      if (value?.key === "other_sharing") {
        setOpenModal(true);
        setTitle(value?.name);
      } else {
        dispatch(
          setOpenWebModulesPopupAction({
            page: "create",
            openWebModuleKey: "share_drive",
            typeShare: value?.key,
            contentShare: window.location.href,
          })
        );
      }
    }
  };

  const handlePostOtherSharing = (action: any) => {
    window.open(`${action?.url?.replace("{url}", `${window.location.href}`)}`);
  };

  const renderTab = () => {
    if (tab.key === "images")
      return (
        <Col>
          <ProfileImages dataUser={dataUser} isMyProfile={isMyProfile} />
        </Col>
      );
    if (tab.key === "videos")
      return (
        <Col>
          <ProfileVideos dataUser={dataUser} isMyProfile={isMyProfile} />
        </Col>
      );
    if (tab.key === "mp3")
      return (
        <Col>
          <ProfileMP3 dataUser={dataUser} isMyProfile={isMyProfile} />
        </Col>
      );
    if (tab.key === "files")
      return (
        <Col>
          <ProfileFiles dataUser={dataUser} isMyProfile={isMyProfile} />
        </Col>
      );

    return (
      <span className="w-100 text-center mt-3">
        {t("No content to display")}
      </span>
    );
  };

  return (
    <Card>
      <VTab
        tab={tab}
        setTab={setTab}
        menus={tabMenu}
        activeBorder="bottom"
        noBackground={true}
        isSelect={true}
      />
      <Row className="m-0">
        <div className="position-relative" style={{ zIndex: 101 }}>
          <div className="action-item-media rounded-circle p-3">
            <DropdownModal
              label={
                <div className="item-action-corver">
                  <VIcon width={12} height={10} name={"option.svg"}></VIcon>
                </div>
              }
              menu={[
                {
                  key: t("Chia sẻ"),
                  children: listActionShare,
                  onClick: (_) => handleClickOption("share", _),
                },
                {
                  key: t("Copy link"),
                  onClick: (_) => handleClickOption("copy", _),
                },
              ]}
            />
          </div>
        </div>
        {renderTab()}
      </Row>
      <VModal
        title={t(title)}
        isOpen={openModal}
        centered={true}
        // disableConfirm={!isPolicy}
        confirmLabel={t("Add")}
        classNameBody={"px-2"}
        children={
          <div className="d-flex justify-content-center flex-wrap my-3">
            {menuPostOtherSharing?.map((el, index) => (
              <div key={index} className="col-3 max-w-[100px]">
                <div
                  className="d-flex position-relative flex-column justify-content-center align-items-center cursor-pointer rounded-lg"
                  onClick={() => handlePostOtherSharing(el)}
                >
                  <div className="d-flex align-items-center justify-content-center p-2">
                    <img
                      width={30}
                      src={getUrlImage(el?.img_icon, 150)}
                      alt=""
                    />
                  </div>
                  <div className={`d-flex align-items-center text-xs mt-1`}>
                    {`${el.name}`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
        onCancel={() => setOpenModal(false)}
      />
    </Card>
  );
};

export default View;

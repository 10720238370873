/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import Card from "components/v-teamplate/Card";
import { useAppSelector } from "redux/reducers/hook";
import { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import VTab from "components/v-tab";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import GeneralSetting from "./components/general-setting";
import NotificationSetting from "./components/notification-setting";
import UserSettingsService from "api/user-settings";
import ComingSoon from "pages/coming-soon/comingsoon";
import { getUserProfile } from "redux/reducers/users/users.selector";

const View = (props) => {
  const { dataUser } = props;
  const userData = useAppSelector(getUserProfile);

  const menus = useAppSelector(getMenusByKey("tab_setting_user"));
  const query = useQuery();
  const [tab, setTab] = useState(null);
  const [loadMenu, setLoadMenu] = useState(false);

  const [state, setState] = useState({
    post: {},
    notification: {},
  });

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const res: any = await UserSettingsService.getUserSettings({
        query: {
          user_id: dataUser?._id || userData?._id,
        },
      });
      if (res) {
        setState({
          post: res.post,
          notification: res.notification,
        });
      }
    } catch (error) {}
  };

  const renderTab = () => {
    if (tab) {
      if (tab.key === "general")
        return (
          <GeneralSetting
            data={state.post}
            dataUser={dataUser || userData}
            onload={getData}
          />
        );
      if (tab.key === "notification")
        return (
          <NotificationSetting data={state.notification} onload={getData} />
        );
    }
    return <ComingSoon />;
  };

  return (
    <>
      <VTab hide={Boolean(dataUser)} tab={tab} setTab={setTab} menus={menus} />
      <Card isOpacity={true}>{renderTab()}</Card>
    </>
  );
};

export default View;

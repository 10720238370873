import { request, parseErrorResponse } from "./request";

const createPayment = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/payments/deposit`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPaymentByUserId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/payments/${req.userId}?type=${req.type}&stock=${req.stock}`, )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const withdraw = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/payments/withdraw`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PaymentService = {
  createPayment,
  withdraw,
  getPaymentByUserId
};

export default PaymentService;

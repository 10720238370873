/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { getPDFSrc } from "redux/reducers/ui-settings/ui-settings.selector";
import { setPostViewPdf } from "redux/reducers/ui-settings/ui-settings.reducer";
import { useDispatch } from "react-redux";
import { isMobile } from "react-device-detect";

import HTMLFlipBook from "react-pageflip";
import VIcon from "components/v-icon";
// import { isDesktop } from "react-device-detect";
import FlipBookImageContext from "context/FlipBookImageContext/View";

// eslint-disable-next-line react/display-name
const Page = React.forwardRef((props: any, ref: any) => {
  return (
    <div className="page" ref={ref}>
      <div
        className="h-100 page-content"
        style={{ backgroundImage: `url("${props.data}")` }}
      ></div>
      <div className="text-white flipbook_page_num">{props.page}</div>
    </div>
  );
});

const PageCover = React.forwardRef((props: any, ref: any) => {
  const { metaSetting, type } = props;

  return (
    <div className="cover" ref={ref} data-density="hard">
      {metaSetting[type] && (
        <div
          className="detail-page"
          style={{ backgroundImage: `url(${metaSetting[type]})` }}
        >
          <span>{metaSetting?.name ?? ""}</span>
        </div>
      )}
    </div>
  );
});

const View = (props) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const flipBookRef = useRef(null);
  const btnNextRef = useRef(null);
  const closeBtnRef = useRef(null);
  const [myInterval] = useState(null);

  const [imageViews, setImageViews] = useState<any>([]);
  const PDFJS = window.pdfjsLib;

  const pdfSrc = useAppSelector(getPDFSrc);
  const { flipBookImage: metaSetting } = useContext(FlipBookImageContext);

  const flipbookBg = useMemo(() => {
    if (metaSetting) {
      const siteMedia = metaSetting?.flipbook_cover_background ?? "";
      if (siteMedia)
        return {
          backgroundImage: `url(${siteMedia})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        };
    }

    return {};
  }, [metaSetting]);

  async function renderPage() {
    const _PDF_DOC = await PDFJS.getDocument({ url: pdfSrc });
    const imagePdfList = [];
    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");

    for (let i = 1; i <= _PDF_DOC.numPages; i++) {
      var page = await _PDF_DOC.getPage(i);
      var viewport = page.getViewport({ scale: 1 });

      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };

      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");
      imagePdfList.push(img);
    }
    setImageViews(imagePdfList);
  }

  useEffect(() => {
    if (pdfSrc) renderPage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pdfSrc]);

  const toggle = () => {
    clearInterval(myInterval);
    dispatch(
      setPostViewPdf({
        pdfSrc: "",
        isOpenPdfViewer: false,
      })
    );
  };

  useEffect(() => {
    const n = setTimeout(() => {
      if (pdfSrc?.length > 0) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }, 50);
    return () => {
      clearTimeout(n);
    };
  }, [pdfSrc]);

  // useEffect(() => {
  //   const setHeight = () => {
  //     const doc = document.documentElement;
  //     doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  //   };

  //   window.addEventListener("resize", setHeight);
  //   setHeight();

  //   return () => {
  //     window.removeEventListener("resize", setHeight);
  //   };
  // }, []);

  if (!imageViews.length) return null;

  return (
    <>
      {isOpen && (
        <div className="book-flip">
          <div className="book-flip-wrapper">
            <div className="book-flip-tool">
              <div className="book-flip-toolbar">
                <div
                  className="book-flip-close"
                  ref={closeBtnRef}
                  onClick={() => toggle()}
                >
                  <VIcon name="cancel.svg" width={20} height={20} />
                </div>
              </div>
            </div>

            {metaSetting && (
              <HTMLFlipBook
                ref={flipBookRef}
                width={600}
                height={700}
                minWidth={315}
                maxWidth={700}
                minHeight={420}
                maxHeight={900}
                showCover={true}
                flippingTime={1500}
                style={{ margin: "0 auto", ...flipbookBg }}
                maxShadowOpacity={0}
                className="album-web"
                startPage={0}
                size="stretch"
                // size="fixed"
                drawShadow={true}
                usePortrait={true}
                startZIndex={0}
                autoSize={true}
                mobileScrollSupport={true}
                clickEventForward={true}
                useMouseEvents={true}
                swipeDistance={30}
                showPageCorners={true}
                disableFlipByClick={false}
              >
                <PageCover
                  key={0}
                  metaSetting={metaSetting}
                  type={"flipbook_cover_first"}
                />

                {imageViews.map((data: any, index: number) => (
                  <Page
                    key={index}
                    data={data}
                    page={
                      isMobile
                        ? `${index + 1} / ${imageViews.length}`
                        : `${index + 1}`
                    }
                  />
                ))}

                <PageCover
                  metaSetting={metaSetting}
                  type={"flipbook_cover_end"}
                />
              </HTMLFlipBook>
            )}
            <div className="book-flip-navigate nav-left">
              <button
                type="button"
                className="btn btn-info btn-sm btn-prev"
                onClick={() => flipBookRef.current.pageFlip().flipPrev()}
              >
                <VIcon name="left_flipbook.svg" width={20} height={20} />
              </button>
            </div>

            <div className="book-flip-navigate nav-right">
              <button
                ref={btnNextRef}
                type="button"
                className="btn btn-info btn-sm btn-next"
                onClick={() => flipBookRef.current.pageFlip().flipNext()}
              >
                <VIcon name="right_flipbook.svg" width={20} height={20} />
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default View;

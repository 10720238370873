/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import APIs from "api";
import { renderDateTime } from "utils/times";
import { getUrlImage } from "utils/common";
import useTranslation from "hooks/useTranslation";
import FsLightbox from "fslightbox-react";

const View = () => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [listLightbox, setListLightbox] = useState({
    toggler: true,
    slide: 1,
    list: [],
  });

  const getSupports = async () => {
    try {
      const mySupports: any = await APIs.SUPPORT.getSupportsByUser();
      setData(mySupports.support);
    } catch (error) {}
  };

  const handleViewFile = (url) => {
    setListLightbox((prev) => ({
      ...prev,
      toggler: !listLightbox.toggler,
      list: [url],
    }));
  };

  useEffect(() => {
    getSupports();
  }, []);

  return (
    <div className="px-1">
      <FsLightbox
        toggler={listLightbox.toggler}
        sources={listLightbox?.list?.map((img) => getUrlImage(img))}
        slide={listLightbox.slide}
      />
      <ul className="iq-timeline ms-1">
        {data && data.length > 0 ? (
          data.map((el, index) => (
            <li key={index}>
              <div className="timeline-dots"></div>
              <div className="timeline-border"></div>
              <Card className="w-fit-content">
                <div className="px-2 py-1">{renderDateTime(el.createdAt)}</div>
              </Card>
              <Card>
                <Card.Body>
                  <div
                    className={`border-content-time-status
                      ${
                        el.status === "pending"
                          ? "status-pending"
                          : el.status === "resolved"
                          ? "status-resolved"
                          : "status-declined"
                      }
                      `}
                  >
                    {t(el.status)}
                  </div>
                  <h6 className="fw-bold">{el.qa_id?.name}</h6>
                  <div className="history-qa-item">
                    {Object.keys(el?.value || {})?.map((value, i) => {
                      if (value !== "attachments") {
                        if (value !== "qa_id") {
                          return (
                            <div key={i} className="d-flex align-items-center">
                              -
                              <span
                                className="fw-600 fs-6"
                                style={{
                                  fontSize: "16px"
                                }}
                                dangerouslySetInnerHTML={{
                                  __html: value,
                                }}
                              ></span>
                              :
                              <div className="ms-2">{el.value[value]}</div>
                            </div>
                          );
                        }
                      } else {
                        let imgs = el?.value[value];
                        if (!Array.isArray(imgs)) {
                          imgs = [imgs];
                        }

                        return (
                          <div key={i} className="atachment-media">
                            {imgs.map((at, index) => (
                              <div key={index} className="file">
                                <img
                                  src={getUrlImage(at)}
                                  alt=""
                                  onClick={() => handleViewFile(at)}
                                />
                              </div>
                            ))}
                          </div>
                        );
                      }
                    })}
                  </div>
                </Card.Body>
              </Card>
            </li>
          ))
        ) : (
          <div className="text-center">{t("No content was found!")}</div>
        )}
      </ul>
    </div>
  );
};

export default View;

import { createSlice } from "@reduxjs/toolkit";
import { getSosCategoriesAsync } from "./categories.actions";

type IField = {
  id: string;
  label: string;
  options: [
    {
      name: string;
      listTrademark: [
        {
          icon: any;
          name: string;
          link: string;
        }
      ];
    }
  ];
  fieldOptions: {
    isCurrencyDiscount: boolean;
  };
  placeholder: string;
  typeField: string;
};

export type ICustomBtn = {
  name: string;
  link: string;
  icon: any;
  textColor: string;
  backgroundColor: string;
  position?: string;
  activeInteractionFormId: string;
};

export type ICategorySos = {
  _id: string;
  name: string;
  color: string;
  icon: any;
  logoMedal?: any;
  logoMap?: any;
  mapLogoSize?: "sm" | "md" | "lg";
  custumButton: {
    leftButton: ICustomBtn;
    rightButton: ICustomBtn;
    surveyButton: ICustomBtn;
  };
  outsideCustomButton: {
    leftButton: ICustomBtn;
    rightButton: ICustomBtn;
  };
  positionIndex: number;
  parent_id: any;
  fields: IField[];
  requireAttachments: boolean;
  enableCountdown: boolean;
  enable: boolean;
  disablePost: boolean;
  isEmergency?: boolean;
};

export interface ICategoriessState {
  sos: ICategorySos[];
  activeCategoryId?: string;
}

const initialState: ICategoriessState = {
  sos: [],
  activeCategoryId: "",
};

export const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    setCategoriesReducer: (state: ICategoriessState, { payload }) => {
      state.sos = payload;
    },
    setActiveCategory: (state: ICategoriessState, { payload }) => {
      state.activeCategoryId = payload;
    },
  },
  extraReducers: {
    [`${getSosCategoriesAsync.pending}`]: (
      state: ICategoriessState,
      { payload }
    ) => {},
    [`${getSosCategoriesAsync.fulfilled}`]: (
      state: ICategoriessState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.sos = payload;
      }
    },
    [`${getSosCategoriesAsync.rejected}`]: (
      state: ICategoriessState,
      { payload }
    ) => {},
  },
});

export const { setCategoriesReducer, setActiveCategory } =
  categoriesSlice.actions;

export default categoriesSlice.reducer;

/* eslint-disable no-template-curly-in-string */
import React from "react";
import { CKEditor } from "ckeditor4-react";
import { fileReqHeader, getURL } from "./upload-config";
import { useAppSelector } from "redux/reducers/hook";
import { getProfile } from "redux/reducers/users/users.selector";

let _window: any = window;

type IProps = {
  initData: string;
  onChange: (data: string) => void;
};

function PostCKEditor({ initData, onChange }: IProps) {
  const users = useAppSelector(getProfile);

  const uploadUrl = getURL("upload").concat(
    "user::".concat(String(users._id || ""))
  );

  const replaceFileUrl = (arg0: any) => {
    return arg0?.replace("${VIEW_URL}", getURL("view"));
  };

  return (
    <div className="post-ck-editor w-full mb-2">
      <CKEditor
        config={{
          placeholder: "xxxx",
          removePlugins: "sourcearea",
          extraPlugins: "colorbutton, justify, uploadimage",
          // filebrowserUploadMethod: "form",
          uploadImage_supportedTypes: /image\/(png|jpeg)/,
          fileTools_requestHeaders: fileReqHeader,

          // fileTools_uploadFileName: "files",
          imageUploadUrl: uploadUrl, // '/uploader/upload.php?type=Images',
        }}
        type={"classic"}
        initData={initData}
        name="my-editor"
        onBeforeLoad={() => {
          _window.CKEDITOR.disableAutoInline = true;
        }}
        onChange={(data) => {
          let dataView = replaceFileUrl(data.editor.getData());
          onChange(dataView);
        }}
      />
    </div>
  );
}

export default PostCKEditor;

import React from "react";
import { Container } from "react-bootstrap";

//Sweet alert
import FriendFollow from "../follow";
import FriendSuggest from "../request/FriendSuggest";

const FriendRequestAndSuggest = () => {

  return (
    <>
      <Container>
        <div className="mt-2">
          <FriendFollow />
        </div>
        <div className="mt-2">
          <FriendSuggest />
        </div>
      </Container>
    </>
  );
};

export default FriendRequestAndSuggest;

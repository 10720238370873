/* eslint-disable indent */
import Card from "components/v-teamplate/Card";
import { Col, Row } from "reactstrap";
import useTranslation from "hooks/useTranslation";
const View = (props: any) => {
  const { stock, type } = props;
  const { t } = useTranslation();
  return (
    <Card isOpacity={true}>
      <div className="detais-rigth-view">
        <div className="title-action-wallets">
          <p>
            {type === "deposit" ? t("Deposit") : t("Withdraw")}{" "}
            {String(stock || "").toUpperCase()} {t("history")}
          </p>
        </div>

        {props.lists.length &&
          props.lists.map((el: any, index: number) => (
            <div key={index} className="content-right-view">
              <Row lg={12}>
                <Col xs={8} sm={6}>
                  <div className="details">
                    <h6>{t("Reference")}: {el.content}</h6>
                    <h6>{new Date(el.updatedAt).toLocaleString()}</h6>
                  </div>
                </Col>
                <Col xs={4} sm={6}>
                  <div className="amount">
                    {props.type === "withdraw" ? (
                      <h6>
                        - {el.amount.toFixed(0)}{" "}
                        {String(stock || "").toUpperCase()}
                      </h6>
                    ) : (
                      <h6>
                        + {el.amount_received.toFixed(0)}{" "}
                        {String(stock || "").toUpperCase()}
                      </h6>
                    )}
                    <div
                      className={
                        el.status === "pending"
                          ? "pending"
                          : el.status === "success"
                            ? "success"
                            : "declined"
                      }
                    >
                      {t(el.status)}
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          ))}
      </div>
    </Card>
  );
};

export default View;

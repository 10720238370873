/* eslint-disable react-hooks/exhaustive-deps */
import BusinessService from "api/businesses";
import UserSettingsService from "api/user-settings";
import VButton from "components/v-button";
import VIcon from "components/v-icon";
import VInput from "components/v-input";
import VBusinessCard from "components/v-objects/v-business-card";
import Card from "components/v-teamplate/Card";
import { BUSINESS_ACTION } from "config/enums";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { IObject } from "types";

const View = (props) => {
  const { dataUser, isMyProfile } = props;
  const { t } = useTranslation();
  const [code, setCode] = useState();
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [businesses, setBusinesses] = useState([]);
  const [businessTop, setBusinessTop] = useState(null);

  useEffect(() => {
    if (isMyProfile) {
      getBussinessTop();
    }
  }, [isMyProfile]);

  useEffect(() => {
    if (dataUser && dataUser._id) {
      getBusinesses();
    }
  }, [dataUser]);

  const getBusinesses = async () => {
    const res: IObject = await BusinessService.getJoined({
      query: {
        page: 1,
        limit: 20,
        userId: dataUser._id,
      },
    });
    if (res && res.docs) {
      setBusinesses(res.docs);
    }
  };

  const getBussinessTop = async () => {
    const res: IObject = await UserSettingsService.getUserSettings();
    if (res && res.business) {
      setBusinessTop(res.business);
      setCode(res.business.refer_code);
    }
  };

  const handleCancel = () => {
    setOpen(false);
    if (businessTop) {
      setCode(businessTop.refer_code);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await UserSettingsService.updateUserBusiness({
        body: {
          business_code: code,
        },
      });
      setLoading(false);
      setOpen(false);
    } catch (error) {
      toast.error(t(error.errors));
      setLoading(false);
    }
  };

  return (
    <div>
      <Card>
        <Card.Body>
          {isMyProfile && (
            <div>
              {!open && (
                <VIcon
                  name="edit-page.svg"
                  onClick={(_) => {
                    getBussinessTop();
                    setOpen(true);
                  }}
                />
              )}
              {open && (
                <div className="text-center">
                  <VInput
                    value={code}
                    label={t("Enter code business")}
                    type="text"
                    name="refer_code"
                    onChange={(e) => setCode(e.target.value)}
                  />
                  <div className="mt-2">
                    <VButton
                      loading={loading}
                      onClick={handleCancel}
                      className={`btn-secondary me-2`}
                    >
                      {t("Cancel")}
                    </VButton>
                    <VButton loading={loading} onClick={handleSubmit}>
                      {t("Submit")}
                    </VButton>
                  </div>
                </div>
              )}
              <hr />
            </div>
          )}
          <div className="row">
            {businesses &&
              (businesses || []).map((business, index) => (
                <VBusinessCard
                  key={index}
                  className={
                    business.to.refer_code === code && "business_active"
                  }
                  count={business?.count?.count?.business}
                  col_lg={4}
                  col_md={4}
                  business={{
                    ...business,
                    ...business.to,
                  }}
                  type={BUSINESS_ACTION.VIEW_DETAIL}
                />
              ))}
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default View;

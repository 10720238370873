/* eslint-disable jsx-a11y/alt-text */
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";

const Step0 = ({ dataPayment, nextStep, setDataByKey, setDataPayment }) => {
  const { t } = useTranslation();
  const currencies = [
    {
      name: "vnd",
      icon: "/assets/images/coins/vnd.png",
    },
    {
      name: "usdb",
      icon: "/assets/images/coins/usdt.png",
    },
  ];

  return (
    <div className="step-0">
      {currencies.map((c, index) => (
        <VButton
          key={index}
          className={`btn-currency ${
            dataPayment.currency === c.name ? "active" : ""
          }`}
          onClick={() => {
            setDataPayment((dataPayment) => {
              return {
                ...dataPayment,
                network: c.name === "vnd" ? "bank" : "stock",
                currency: c.name,
              };
            });
          }}
        >
          <img className="icon-currenct" src={c.icon} />
          {c.name.toUpperCase()}
        </VButton>
      ))}
      <div className="submit-step">
        <VButton onClick={() => nextStep()} disabled={!dataPayment.currency}>
          {t("Continue")}
        </VButton>
      </div>
    </div>
  );
};

export default Step0;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
} from "react";
import TreeSelectComponent from "components/treeSelect";
import { getUrlImage } from "utils/common";
import PostDirectory from "api/post-directory";
import useTranslation from "hooks/useTranslation";

const View = (props: {
  userId: string,
  postDirectory: any,
  setPostDirectory: any,
  isOnlySave: boolean,
}) => {
  const { t } = useTranslation();
  const { postDirectory, setPostDirectory, isOnlySave = false} = props;

  const [postDirectories, setUserDirectories] = useState<any[]>([]);
  const [postDirectorieInfo, setUserDirectorieInfo] = useState<any>();

  const getUserDirectory = async (id: string) => {
    try {
      const postsData: any = await PostDirectory.getPostDirectoryByUserId({
        params: {userId: id}
      });

      const userDirectories = postsData?.value || [];
      let directoryData = [];

      if (isOnlySave) directoryData = getDataUserDirectoryOnlySave(userDirectories);

      setUserDirectories(directoryData);
      setUserDirectorieInfo(postsData?.infoDirectory);
    } catch (error) {}
  };

  const getDataUserDirectoryOnlySave = (userDirectories: any[]) => {
    const objectSave = userDirectories?.filter((dir) => dir?.name.toLocaleLowerCase().replaceAll(" ", "").trim() === 'đãlưu');
    
    if (!objectSave) return [];

    const {id} = objectSave[0];

    return userDirectories?.filter((dir: { id: any; parent_id: any; }) => {
      return (dir?.id === id || dir?.parent_id === id);
    })
  }

  const getTreeSelectData = () => {
    let data;

    const mapData = (list: any) => {
      const dataChild = list.map((item: any) => child(item));
      return dataChild.length > 0 ? dataChild : null;
    };

    const child = (ct: any) => {
      const dataChild = postDirectories.filter(
        (dir) => dir.parent_id === ct?.id
      );
      return {
        label: (
          <div
            className="flex items-center py-2"
            style={{
              color:
                postDirectorieInfo?.color?.[
                  !ct?.parent_id ? "parent" : "child"
                ],
            }}
          >
            <img
              className="mr-1 rounded-2xl"
              width={14}
              height={14}
              src={
                ct?.icon
                  ? getUrlImage(ct?.icon)
                  : `${process.env.REACT_APP_WEB_UI_URL}/assets/icons/default/list-folder.svg`
              }
              alt=""
            />
            {ct?.status === "private" && (
              <img
                className="mr-1"
                width={14}
                height={14}
                src={`${process.env.REACT_APP_WEB_UI_URL}/assets/icons/default/lock.svg`}
                alt=""
              />
            )}

            {ct?.name}
          </div>
        ),        
        value: ct?.id,
        children: dataChild.length > 0 ? mapData(dataChild) : [],
      };
    };

    data = postDirectories
      ?.filter((dir) => !dir.parent_id)
      .map((el: any) => child(el));

    return data;
  };

  const getPostDirectoryById = (bigObjectArray: any, id: string) => {
    const findObjectsWithId = (objArray: any) => {
      let results: any[] = [];

      for (let i = 0; i < objArray.length; i++) {
        let obj = objArray[i];
        results = results.concat(findObjectsWithIdRecursive(obj));
      }

      return results;
    };

    const findObjectsWithIdRecursive = (obj: any) => {
      let results: any[] = [];

      if (typeof obj === "object") {
        if (obj?.id === id) {
          results.push(obj);
        }

        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            results = results.concat(findObjectsWithIdRecursive(obj[key]));
          }
        }
      }

      return results;
    };

    return findObjectsWithId(bigObjectArray).map((el: any) => {
      return {
        label: t(el?.name),
        value: el?.id,
        children: el?.children_id ? [] : null,
      };
    })[0];
  };

  useEffect(() => {
    getUserDirectory(props.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div className="text-center border-b-2 relative p-3">
        <div className="d-flex justify-content-between my-2 flex-column align-items-start">
          <TreeSelectComponent
            {...(!!postDirectory?.value ? { value: postDirectory } : {})}
            onChange={(e) => {
              setPostDirectory(getPostDirectoryById(postDirectories, e));
            }}
            className="!rounded-md !bg-white my"
            placeholder={t("Chọn thư mục lưu")}
            options={getTreeSelectData()}
            label={t("Sắp xếp vào thư mục lưu của bạn")}
          />
        </div>        
      </div>
    </>
  );
};

export default View;

/* eslint-disable indent */
import { t } from "i18next";
import React, { useRef, useState } from "react";
import { Col } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import { Link } from "react-router-dom";
// import PageBackgrounds from "components/v-page-background";

type IProps = {
  trend: {
    [key: string]: any;
    [count: number]: any;
  };
  type?: string;
  reload?: any;
  col_lg?: number;
  col_md?: number;
  className?: string;
};

const View = ({ trend, reload, col_lg, col_md, className }: IProps) => {
  // const { t } = useTranslation();
  // const history = useHistory();
  const [progress, setProgress] = useState(0);
  const refLoad = useRef(null);

  const redirectToSearch = () => {
    if (trend._id && trend._id?.includes("#")) {
      return `/search/hashtag?search=${trend._id?.substr(1)}`;
    } else {
      return `/search/everybody?search=${trend._id}`;
    }
  };

  return (
    <Col
      md={col_md || 12}
      lg={col_lg || 12}
      className={`${className ? className : "v-trending-box"}`}
    >
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        ref={refLoad}
      />
      <Link to={redirectToSearch} className={"text-link"}>
        <div
          className="box d-flex justify-content-between justify-content-center align-items-center cursor-pointer"
        >
          <div>
            <div className="category">{t("Trending")}</div>
            <div className="fw-bold key">{trend?.key || trend?._id}</div>
            <div className="number">
              {trend?.count} {"search"}
            </div>
          </div>
          <div className="fw-bold cursor-pointer">...</div>
        </div>
      </Link>
    </Col>
  );
};

export default View;

/* eslint-disable no-mixed-operators */
import { IoMdArrowRoundBack } from "react-icons/io";
import { getCategoryMapButton } from "utils/getComponents";
import { ICategorySos } from "redux/reducers/categories/categories.reducers";
import { AiOutlineRight, AiOutlineDown } from "react-icons/ai";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { getSosCategories } from "redux/reducers/categories/categories.selector";
import useQuery from "hooks/useQuery";
import VSlider from "components/v-slider-custom";

const sliderSettings = {
  dots: false,
  infinite: false,
  speed: 500,
  slidesToShow: 8,
  slidesToScroll: 4,
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 8,
        slidesToScroll: 4,
        infinite: false,
        dots: false
      }
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 3,
        slidesToScroll: 2,
        initialSlide: 2,
        dots: false
      }
    },
    {
      breakpoint: 480,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 1,
        dots: false
      }
    }
  ]
};

const CategoryList = ({
  onCategoryChange,
  categoriesFilter,
}: {
  onCategoryChange: (id: string) => void;
  categoriesFilter?: string[];
}) => {
  const {categoryId} = useQuery();
  const [dataShowing, setDataShowing] = useState<any[]>([]);
  const [categorySelectedId, setCategorySelectedId] = useState("all");
  const categories = useAppSelector(getSosCategories);

  useEffect(() => {
    if (categoryId) {
      setCategorySelectedId(categoryId);
    }    
  }, [categoryId]);

  const allCategories = categoriesFilter?.length
    ? categories.filter((category) => categoriesFilter.includes(category._id))
    : categories;
  const getCategoryItem = (el: ICategorySos) => {
    const active = categorySelectedId === el._id;

    return (
      <div
        key={el?._id}
        className="mb-2 me-1"
        onClick={() => {
          setCategorySelectedId(el._id);
          onCategoryChange(el._id);
        }}
      >
        {getCategoryMapButton(
          el.name,
          el.color || "#202124",
          el.icon,
          active,
          active ? <AiOutlineDown size={14} /> : <AiOutlineRight size={14} />,
          el
        )}
      </div>
    );
  };

  const handleBack = () => {
    const category: any = allCategories.find(
      (ct) => String(ct._id) === categorySelectedId
    );
    const parent = allCategories.find(
      (ct) => String(ct._id) === String(category.parent_id)
    );

    if (parent) {
      const list = allCategories.filter(
        (ct) => String(ct.parent_id) === String(parent.parent_id)
      );
      setDataShowing([list, ...dataShowing[0]]);
      setCategorySelectedId(parent._id);
      onCategoryChange(parent._id);
    } else {
      const rootItem = allCategories.filter((ct) => !Boolean(ct.parent_id));
      setDataShowing([rootItem]);
      setCategorySelectedId("all");
      onCategoryChange("all");
    }
  };

  const getMenu = (categorySelectedId: string) => {
    let a = false;
    let newArr: any[][] = [];

    const category: any = allCategories.find(
      (ct) => String(ct._id) === categorySelectedId
    );

    if (category) {
      const listsSameParent = allCategories.filter(
        (ct) => String(ct._id) === String(category?._id)
      );
      const listsChilCategorySelected = allCategories.filter(
        (ct) => String(ct.parent_id) === String(categorySelectedId)
      );
      newArr = [listsSameParent, listsChilCategorySelected];

      const getParent = (parent_id: string) => {
        const parent = allCategories.find(
          (ct) => String(ct._id) === parent_id
        );
        if (parent) {
          getParent(parent.parent_id);
        } else {
          a = true;
        }
      };
      getParent(category.parent_id);
    }

    if (a) setDataShowing(newArr);
  };

  useEffect(() => {
    const rootItem = allCategories.filter((ct) => !Boolean(ct.parent_id));
    setDataShowing([rootItem]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allCategories?.length]);

  useEffect(() => {
    if (allCategories.length) {
      getMenu(categorySelectedId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [categorySelectedId, allCategories.length]);

  return (
    <div className="d-flex flex-row my-1 justify-content-center">        
      {dataShowing.map((list: any[], idx: number) => {
        const isSlider = (categorySelectedId === 'all' || idx > 0 && list?.length >= 8);
        
        return list?.length > 0 && (
          <div key={idx} className={`${isSlider ? 'w-100 post-category_slider' : 'd-flex'}`}>
            {
              isSlider ? (
                <VSlider settings={sliderSettings}>
                  {
                    list?.map((item, index: number) => {
                      return (
                        <div key={index}>{getCategoryItem(item)}</div>
                      )
                    })
                  }                  
                </VSlider>
              ) : (
                <>
                  {
                    idx === 0 && (
                      <div className="w-fit-content cursor-pointer pe-1 text-dark" onClick={() => handleBack()}>
                        <IoMdArrowRoundBack size={20} />
                      </div>
                    )
                  }     

                  {
                    list?.map((item, index: number) => {
                      return <div key={index}>{getCategoryItem(item)}</div>;
                    })  
                  }
                </>
              )                 
            }                               
          </div>
        );
      })}
    </div>
  );
};

export default CategoryList;

/* eslint-disable indent */
import { useState } from "react";
import { useForm, Controller } from "react-hook-form";

import Input from "components/v-input";
import Button from "components/v-button";
import useTranslation from "hooks/useTranslation";
import { copyText, formatCurrency, randString } from "utils/common";
import { toast } from "react-toastify";
import VQRCode from "components/v-qr-code";
import { Col, Container, Row } from "reactstrap";

const Step3 = ({ nextStep, dataPayment, setDataByKey, backStep }) => {
  const { t } = useTranslation();
  const [confirmAmount] = useState(formatCurrency(dataPayment.amount));
  const [confirmString] = useState(randString(false, 10));
  // const [globalError, setGlobalError] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();

  const confirmSubmit = (data) => {
    if (data.cfAmount !== confirmAmount) {
      return setError("cfAmount", new Error(t("Invalid amount!")));
    }

    if (dataPayment.currency === "vnd" && data.cfString !== confirmString) {
      return setError("cfString", new Error(t("Invalid amount!")));
    }

    setDataByKey("amount", dataPayment.amount);
    setDataByKey("content", confirmString);

    nextStep();
  };

  const handleCopy = (tx: string) => () => {
    copyText(tx);
    toast.success(t("Copied to clipboard!"));
  };

  return (
    <div className="content-right-view">
      <Container>
        <Row>
          <Col xs={12} md={dataPayment.currency === "vnd" ? 12 : 8}>
            <div className="mt-3">
              <Input
                transparent={true}
                label={t("Network")}
                defaultValue={String(dataPayment.network).toUpperCase()}
                disabled={true}
              />
            </div>
            <div className="mt-3">
              <Input
                transparent={true}
                label={`${dataPayment.bank && dataPayment.bank.bank_name} -  ${
                  dataPayment.bank && dataPayment.bank.account_name
                }`}
                defaultValue={
                  dataPayment.bank && dataPayment.bank.account_number
                }
                suffix={
                  <div className="copy-btn">
                    <img
                      src="/assets/images/icons/copy.svg"
                      alt="copy.svg"
                      onClick={handleCopy(
                        dataPayment.bank && dataPayment.bank.account_number
                      )}
                    />
                  </div>
                }
                disabled={true}
              />
            </div>
            <div className="mt-3">
              <Input
                transparent={true}
                label={t("Amount to be paid")}
                defaultValue={
                  confirmAmount +
                  " " +
                  String(dataPayment.currency).toUpperCase()
                }
                suffix={
                  <div className="copy-btn">
                    <img
                      src="/assets/images/icons/copy.svg"
                      alt="copy.svg"
                      onClick={handleCopy(confirmAmount)}
                    />
                  </div>
                }
                disabled={true}
              />
            </div>
            <div className="mt-3">
              {dataPayment.currency === "vnd" && (
                <Input
                  transparent={true}
                  disabled={true}
                  label={t("Bank transfer content")}
                  placeholder="LDC9DI038K"
                  defaultValue={confirmString}
                  suffix={
                    <img
                      src="/assets/images/icons/copy.svg"
                      alt="copy.svg"
                      onClick={handleCopy(confirmString)}
                    />
                  }
                />
              )}
            </div>
          </Col>
          {dataPayment.currency !== "vnd" && (
            <Col xs={12} md={4}>
              <VQRCode value={dataPayment.bank.account_number} size={150} />
              <br />
              <small>
                {" "}
                <b>{t("Network")}:</b>{" "}
                {String(dataPayment.network || "").toUpperCase()}{" "}
              </small>
              <br />
              <small>
                <b>{t("Note")}:</b>
                {t(
                  "Please direct to the selected network. We are not responsible for this issue"
                )}
                .
              </small>
            </Col>
          )}
        </Row>
        <div className="confirm">
          <p>{t("Please confirm again.")}</p>
          <div className="mt-3">
            <Controller
              name="cfAmount"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  transparent={true}
                  label={`${"The"} ${String(
                    dataPayment.currency
                  ).toUpperCase()} ${"amount you will transfer"}`}
                  hasError={Boolean(errors.cfAmount)}
                  placeholder={`10,000 ${String(
                    dataPayment.currency
                  ).toUpperCase()}`}
                  {...field}
                />
              )}
            />
          </div>
          <div className="mt-3">
            {dataPayment.currency === "vnd" && (
              <Controller
                name="cfString"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Input
                    transparent={true}
                    hasError={Boolean(errors.cfString)}
                    label={t("The bank transfer content you will use")}
                    placeholder="LDC9DI038K"
                    {...field}
                  />
                )}
              />
            )}
          </div>
          <div className="submit-step">
            <Button onClick={handleSubmit(confirmSubmit)}>
              {t("I’m ready to transfer money")}
            </Button>
            <Button transparent={true} onClick={() => backStep()}>
              {t("Back")}
            </Button>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default Step3;

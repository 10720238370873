/* eslint-disable array-callback-return */
/* eslint-disable indent */
// import VIcon from "components/v-icon";
// import Card from "components/v-teamplate/Card";
// import { OverlayTrigger, Row, Tooltip } from "react-bootstrap";
import useTranslation from "hooks/useTranslation";
// import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { checkIsAuth, getUrlImage } from "utils/common";
import { useEffect, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import VModal from "components/v-modal";
import UploadFile from "components/uploadFile";
import VInput from "components/v-input";
import UploadService from "api/uploads";
import APIsUser from "api/users";
import { getUserByIdAsync } from "redux/reducers/users/users.actions";
import { toast } from "react-toastify";
import VButton from "components/v-button";
import { getUserProfile } from "redux/reducers/users/users.selector";
import VSortable from "components/v-sortable";
import VIcon from "components/v-icon";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { isMobile } from "react-device-detect";

const View = (props) => {
  const { dataUser } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const profile: any = useAppSelector(getUserProfile);
  const isAuth = checkIsAuth(dataUser, profile._id);
  // const menus: any = useAppSelector(getMenusByKey("my_link_profile"));
  // const additionalData = dataUser?.profile?.additionalData;
  const [myLink, setMyLink] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [linkActive, setLinkActive] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [listFiles, setListFiles] = useState<any>({});
  const [show, setShow] = useState(false);

  useEffect(() => {
    if (dataUser?.profile?.my_link) {
      setMyLink(dataUser?.profile?.my_link);
    }
  }, [dataUser]);

  // const menusLink = dataUser
  //   ? menus?.filter(
  //       (el) =>
  //         (el.key === "facebook" && additionalData?.["Facebook"]) ||
  //         (el.key === "zalo" && additionalData?.["Zalo"]) ||
  //         (el.key === "x-twitter" && additionalData?.["Twitter"]) ||
  //         (el.key === "hahalolo" && additionalData?.["Hahalolo"]) ||
  //         (el.key === "lotus" && additionalData?.["Lotus"]) ||
  //         (el.key === "gapo" && additionalData?.["Gapo"])
  //     )
  //   : menus;

  // const handleClick = (data) => {
  //   const key = data.key;
  //   let url;
  //   switch (key) {
  //     case "facebook":
  //       url = additionalData?.["Facebook"];
  //       break;
  //     case "zalo":
  //       url = additionalData?.["Zalo"];
  //       break;
  //     case "x-twitter":
  //       url = additionalData?.["Twitter"];
  //       break;
  //     case "hahalolo":
  //       url = additionalData?.["Hahalolo"];
  //       break;
  //     case "lotus":
  //       url = additionalData?.["Lotus"];
  //       break;
  //     case "gapo":
  //       url = additionalData?.["Gapo"];
  //       break;

  //     default:
  //       break;
  //   }
  //   window.open(url || data?.url);
  // };

  const handelAddLink = () => {
    const newItem = {
      id: uuidv4(),
      title: "",
      icon: "",
      link: "",
    };
    // setMyLink([...myLink, newItem]);
    setLinkActive(newItem);
  };

  const handleEdit = () => {
    setOpenModal(true);
    setMyLink(dataUser?.profile?.my_link);
    setListFiles({});
    const newItem = {
      id: uuidv4(),
      title: "",
      icon: "",
      link: "",
    };
    setLinkActive(newItem);
  };

  const handleCancel = () => {
    if (linkActive) {
      setLinkActive(null);
    } else {
      setOpenModal(false);
      setMyLink(dataUser?.profile?.my_link);
    }
  };

  const handleDeleteItemLink = (item) => {
    setMyLink(myLink?.filter((el) => el.id !== item?.id));
  };

  const handelClickItem = (item) => {
    if (!isEdit) {
      window.open(item?.link);
    }
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const onChange = (name, value, id) => {
    setLinkActive((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const sort = (data) => {
    setMyLink(data);
  };

  const handleConfirmAddItemLink = () => {
    const isEditItem = myLink?.find((el) => el?.id === linkActive?.id);
    if (!isEditItem) {
      setMyLink([...myLink, linkActive]);
    } else {
      setMyLink(
        myLink?.map((el) => (el.id === linkActive?.id ? linkActive : el))
      );
    }
    setLinkActive(null);
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let listImg;
      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${dataUser?._id}`);

        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await UploadService.uploadMultiFile({
          body: formData,
        });
      }

      let newData = myLink;

      if (listImg && listImg?.length > 0) {
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              for (let index = 0; index < myLink.length; index++) {
                const element = myLink[index];
                if (String(element.id) === String(item)) {
                  myLink[index] = {
                    ...myLink[index],
                    icon: el?.filePath,
                  };
                }
                if (element.children?.length > 0) {
                  myLink[index] = {
                    ...myLink[index],
                    children: myLink[index]?.children.map((chil) =>
                      String(chil.id) === String(item)
                        ? {
                            ...chil,
                            icon: el?.filePath,
                          }
                        : chil
                    ),
                  };
                }
              }
            }
          });
        });
      }

      const res = await APIsUser.updateProfile({
        body: {
          my_link: newData,
        },
      });

      if (res) {
        dispatch(
          getUserByIdAsync({
            params: {
              id: dataUser._id,
              user_id: dataUser._id,
            },
          })
        );
        setOpenModal(false);
        setIsEdit(false);
        toast.success(t("Successfull!"));
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <>
      {isMobile && !show && (
        <div
          className="contact-social-link"
          style={{ bottom: "60px", right: show ? "60px" : "0px" }}
        >
          <VButton
            className={"px-2 py-0"}
            onClick={() => setShow(!show)}
            width={26}
          >
            <VIcon name="back.svg" />
          </VButton>
        </div>
      )}
      <div className="contact-social-link" style={{right:!isMobile ? "" : show ? "8px" : "-100px", transition: "right 1s"}}>
        {isMobile && (
          <div className="d-flex justify-content-end">
            <div className="bg-secondary rounded-circle w-fit-content">
              <VIcon
                className="p-0"
                name="close.svg"
                width={14}
                height={14}
                onClick={() => {
                  setShow(false);
                }}
              />
            </div>
          </div>
        )}
        {myLink?.length > 0 &&
          myLink?.map((el) => (
            <div className="mx-1 my-2 cursor-pointer">
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>{t(el.title)}</Tooltip>}
              >
                <img
                  className="rounded-circle"
                  onClick={() => handelClickItem(el)}
                  width={42}
                  height={42}
                  src={getUrlImage(el.icon, 100)}
                  alt=""
                />
              </OverlayTrigger>
            </div>
          ))}

        {dataUser && isAuth && (
          <div className="d-flex justify-content-center">
            <VButton
              className={"py-1"}
              onClick={() => handleEdit()}
              width={36}
            >
              +
            </VButton>
          </div>
        )}
      </div>

      <VModal
        title={t("Other Chat Links")}
        isOpen={openModal}
        centered={true}
        confirmLabel={t(linkActive ? "Add" : "")}
        cancelLabel={t(linkActive ? "Back" : "")}
        classNameBody={"px-2"}
        children={
          <div>
            {linkActive ? (
              <>
                <div className="upload-file d-flex align-items-center my-2">
                  <div>
                    <UploadFile
                      padding={"0px"}
                      hideTextUpload={true}
                      id={linkActive?.id}
                      width={"40px"}
                      height={"40px"}
                      ratio={"50*50"}
                      src={linkActive?.icon}
                      file={listFiles?.[linkActive?.id]}
                      setFile={setFile}
                    />
                  </div>
                  <div className="row d-flex align-items-between ms-2 w-100">
                    <div className="col-6">
                      <VInput
                        label={"Title"}
                        value={linkActive?.title}
                        onChange={(e) =>
                          onChange("title", e.target.value, linkActive?.id)
                        }
                      />
                    </div>
                    <div className="col-6">
                      <VInput
                        label={"Link"}
                        value={linkActive?.link}
                        onChange={(e) =>
                          onChange("link", e.target.value, linkActive?.id)
                        }
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4">
                  <VButton
                    height={"34px"}
                    onClick={() => handleCancel()}
                    width={40}
                  >
                    {t("Danh sách quản lý")}
                  </VButton>
                </div>
              </>
            ) : (
              <>
                <VSortable
                  arrayDefault={myLink}
                  listSort={myLink?.map((el) => ({
                    oldId: el.id,
                    item: (
                      <div className="d-flex align-items-center mb-2">
                        <VIcon
                          name={"list-six-dot.svg"}
                          width={20}
                          className="me-2"
                        />
                        <div className="bg-light bg-gradient py-1 px-2 rounded d-flex flex-column w-100">
                          <div className="d-flex align-items-center justify-content-between cursor-pointer">
                            <div className="d-flex align-items-center">
                              <img
                                className="rounded"
                                width={50}
                                height={50}
                                src={
                                  listFiles?.[el.id]
                                    ? URL.createObjectURL(listFiles?.[el.id])
                                    : getUrlImage(el.icon, 100)
                                }
                                alt=""
                              />
                              <div className="ms-2">
                                <div className="text-ellipsis short-1 fw-bold">
                                  {el?.title}
                                </div>
                                <div
                                  className="text-ellipsis short-1"
                                  style={{
                                    fontSize: "10px",
                                  }}
                                >
                                  {el?.link}
                                </div>
                              </div>
                            </div>

                            <div className="d-flex">
                              <div
                                className="bg-body mx-1 px-1 rounded"
                                onClick={() => {
                                  setLinkActive(
                                    el?.id === linkActive?.id ? null : el
                                  );
                                  setIsEdit(true);
                                }}
                              >
                                <VIcon name="edit-page.svg" width={10} />
                              </div>
                              <div
                                className="bg-body mx-1 px-1 rounded"
                                onClick={() => handleDeleteItemLink(el)}
                              >
                                <VIcon name="delete.svg" width={10} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ),
                  }))}
                  handleEndSort={(e) => sort(e)}
                />
                <div className="d-flex justify-content-end">
                  <VButton onClick={() => handelAddLink()} width={40}>
                    +
                  </VButton>
                </div>
              </>
            )}
          </div>
        }
        loading={loading}
        onConfirm={() =>
          linkActive ? handleConfirmAddItemLink() : handleSubmit()
        }
        onCancel={() => handleCancel()}
      />
    </>
  );
};

export default View;

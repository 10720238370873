import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useAppDispatch } from "redux/reducers/hook";
import {
  setOpenWebModulesPopupAction,
  update,
} from "redux/reducers/ui-settings/ui-settings.reducer";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";

const VCapcha = ({
  captchaId,
  isVerify,
}: {
  captchaId?: string;
  isVerify?: { show: boolean; verify: boolean };
}) => {
  const { t } = useTranslation();
  const [capchaAction, setCapchaAction] = useState({
    isStart: false,
    isSuccess: false,
    isFail: false,
  });
  const dispatch = useAppDispatch();
  const { captcha_status, isCloseCaptchaMidway } =
    useSelector(getUISettingState);

  useEffect(() => {
    if (isCloseCaptchaMidway) {
      setCapchaAction({
        isStart: false,
        isSuccess: false,
        isFail: false,
      });
    }
  }, [isCloseCaptchaMidway]);

  const handleActionCapcha = (name: string, value: any) => {
    setCapchaAction((state) => ({
      ...state,
      [name]: value,
    }));
    onClickOpenVerifyCapcha();
  };

  const onClickOpenVerifyCapcha = () => {
    dispatch(
      setOpenWebModulesPopupAction({
        openWebModuleKey: "open-captcha",
        captchaId,
      })
    );
  };

  const handleRenderMainAction = () => {
    if (
      !captcha_status &&
      !capchaAction.isFail &&
      !capchaAction.isStart &&
      !capchaAction.isSuccess
    ) {
      return (
        <input
          id="start-capcha"
          type="checkbox"
          className="form-check-input"
          checked={capchaAction.isStart}
          onChange={(e) => {
            dispatch(update({ name: "isCloseCaptchaMidway", value: false }));
            handleActionCapcha("isStart", e.target.checked);
          }}
        />
      );
    }
    if (capchaAction.isSuccess || captcha_status) {
      return (
        <img src="/assets/icons/default/done.svg" className="done" alt="done" />
      );
    }
    if (capchaAction.isFail) {
      return (
        <img src="/assets/icons/default/fail.svg" className="fail" alt="fail" />
      );
    }
    if (capchaAction.isStart) {
      return (
        <img src="/assets/icons/default/spin.svg" className="spin" alt="spin" />
      );
    }
  };

  const handleRenderSubTitle = () => {
    if (capchaAction.isSuccess || captcha_status) {
      return t("Xác mình thành công. Bạn có 180 giây để đăng nhập.");
    }
    if (capchaAction.isFail) {
      return t("Thất bại");
    }
    if (capchaAction.isStart) {
      return t("Đang xác minh...");
    }
    if (!captcha_status) {
      return t("Xác minh bạn không phải là người máy");
    }
  };

  return (
    <div id="v-capcha" className="capcha">
      <div style={{ display: "flex", alignItems: "center" }}>
        {handleRenderMainAction()}
        <label htmlFor="start-capcha">
          <p style={{ margin: 0 }}>{handleRenderSubTitle()}</p>
          {isVerify.show && !captcha_status && !isVerify.verify && (
            <span style={{ color: "red" }}>{t("Bạn cần trả lời captcha")}</span>
          )}
        </label>
      </div>
      <div>
        <img
          style={{
            margin: "auto",
            width: "30px",
            height: "30px",
            display: "block",
          }}
          src="/assets/images/logo/logo.png"
          alt=""
        />
        <span
          style={{
            color: "var(--bs-link-color)",
            fontSize: "10px",
            textAlign: "center",
          }}
        >
          {t("Quyền riêng tư")}
        </span>
      </div>
    </div>
  );
};

export default VCapcha;

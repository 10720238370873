/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import {
  getGroupInvitedAsync,
  getGroupJoinedAsync,
  getGroupManageAsync,
  getGroupRequestAsync,
  getGroupSuggestsAsync,
} from "./groups.action";

export interface IGroupState {
  requests_join: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  suggests: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  manage: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  joined: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  invited: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  dataAccesses: any;
}

const initialState: IGroupState = {
  requests_join: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  suggests: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  manage: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  joined: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  invited: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  dataAccesses: {},
};

export const Groups = createSlice({
  name: "groups",
  initialState,
  reducers: {
    removeGroupRequestJoin: (state: IGroupState, action: any) => {
      const { docs } = state.requests_join;
      state.requests_join.docs = docs.filter((f) => f.to_id !== action.payload);
      state.requests_join.totalDocs = state.requests_join.totalDocs - 1;
    },
    removeGroupSuggest: (state: IGroupState, action) => {
      const { docs } = state.suggests;
      state.suggests.docs = docs.filter((f) => f._id !== action.payload);
      state.suggests.totalDocs = state.suggests.totalDocs - 1;
    },
    removeGroupInvited: (state: IGroupState, action) => {
      const { docs } = state.invited;
      state.invited.docs = docs.filter((f) => f.from_id !== action.payload);
      state.invited.totalDocs = state.invited.totalDocs - 1;
    },
    setDataAccesses: (state: IGroupState, action) => {
      const data = action.payload;
      data.forEach((item) => {
        state.dataAccesses[item.user_id] = item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGroupRequestAsync.pending, (state) => {})
      .addCase(getGroupRequestAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.requests_join = action.payload || {};
        } else {
          state.requests_join = {
            ...state.requests_join,
            page,
            docs: state.requests_join.docs.concat(docs),
          };
        }
      })
      .addCase(getGroupSuggestsAsync.pending, (state) => {})
      .addCase(getGroupSuggestsAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.suggests = action.payload || {};
        } else {
          state.suggests = {
            ...state.suggests,
            page,
            docs: state.suggests.docs.concat(docs),
          };
        }
      })
      .addCase(getGroupManageAsync.pending, (state) => {})
      .addCase(getGroupManageAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.manage = action.payload || {};
        } else {
          state.manage = {
            ...state.manage,
            page,
            docs: state.manage.docs.concat(docs),
          };
        }
      })
      .addCase(getGroupJoinedAsync.pending, (state) => {})
      .addCase(getGroupJoinedAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.joined = action.payload || {};
        } else {
          state.joined = {
            ...state.joined,
            page,
            docs: state.joined.docs.concat(docs),
          };
        }
      })
      .addCase(getGroupInvitedAsync.pending, (state) => {})
      .addCase(getGroupInvitedAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.invited = action.payload || {};
        } else {
          state.invited = {
            ...state.invited,
            page,
            docs: state.invited.docs.concat(docs),
          };
        }
      });
  },
});

export const {
  removeGroupRequestJoin,
  setDataAccesses,
  removeGroupSuggest,
  removeGroupInvited,
} = Groups.actions;

export default Groups.reducer;

import UserService from "api/users";
import { useEffect, useState } from "react";
import VAvatar from "components/v-avatar";
import VLoading from "components/v-loading";
import { getUserAsync } from "redux/reducers/users/users.actions";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import useTranslation from "hooks/useTranslation";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { getObjects } from "redux/reducers/settings/settings.selector";
import { getUrlImage } from "utils/common";

const SwitchAccount = (props) => {
  const { setOpenModalSwitch } = props;
  const userData = useAppSelector(getUserProfile);
  const objectsSetting = useAppSelector(getObjects);
  const [loading, setLoading] = useState(true);
  const [listAccount, setListAccout] = useState([]);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleClick = async (userId: string) => {
    try {
      setLoading(true);
      const res: any = await UserService.loginAccountSwitch({
        body: {
          idUserLogin: userId,
        },
      });
      if (res) {
        await localStorage.setItem("auth", res.token);
        await dispatch(getUserAsync());
        setOpenModalSwitch(false);
        toast.success(
          `${t(`Bạn đang hoạt động dưới tên`)} ${
            res.user?.full_name || res.user?.first_name || res.user?.last_name
          }`
        );
        window.location.reload();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  const getAccount = async () => {
    try {
      setLoading(true);
      const res: any = await UserService.getAccountSwitch();
      if (res) {
        setListAccout(res);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  useEffect(() => {
    getAccount();
  }, []);

  return (
    <div className="switch-account">
      <div className="text-center mb-2">
        <span>
          {t("Thao tác giúp bạn chuyển đổi nhanh các tài khoản quản lý")}
        </span>
      </div>
      {loading && (
        <div className="_loading">
          <VLoading />
        </div>
      )}
      {listAccount?.length > 0 &&
        listAccount.map((el, index) => (
          <div
            key={index}
            className={`
            item-account 
            ${el._id === userData._id ? "active" : ""} 
            d-flex justify-content-between align-items-center
            `}
            onClick={() => handleClick(el?._id)}
          >
            <VAvatar className="m-0" size={"40px"} userData={el} />
            <img
              width={50}
              src={getUrlImage(
                objectsSetting?.find((item) => item.key === el.object_type)
                  ?.logo,
                150
              )}
              alt=""
            />
          </div>
        ))}
    </div>
  );
};

export default SwitchAccount;

/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from "reactstrap";
import { formatNumber, getBase64Image } from "utils/common";
import {
  BotttomLeft,
  Container,
  Content,
  Footer,
  Header,
  HeaderLeft,
  HeaderRight,
  Label,
  Logo,
  PaymentInfo,
  TopRight,
  Total,
  Value,
  LogoVzonex,
} from "../styles";
import Table from "components/table";
import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import DownloadImg from "components/download/View";
import APIs from "api";

const Invoice = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const [owner, setOwner] = useState(null);
  const [estock, setEstock] = useState(null);
  const history = useHistory();
  const imgRef = useRef(null);
  const [logo, setLogo] = useState(null);

  const columns = [
    {
      key: "Logo",
      title: "Logo",
      render: (item: any) => (
        <img className="logo" src={logo || invoice.project_id.logo} alt="" />
      ),
    },
    {
      key: "Project",
      title: "Project",
      render: (item: any) =>
        `${item.project_id ? item.project_id["name"] : "N/A"}`,
    },
    {
      key: "Price",
      title: "Price",
      render: (item: any) =>
        `${formatNumber(item.project_id["stock_price"])} (${
          item.project_id
            ? String(invoice.project_id.stock_to_buy).toUpperCase()
            : "N/A"
        })`,
    },
    {
      key: "amountReceived",
      title: "AmountReceived",
      render: (item: any) =>
        `${formatNumber(item.project.amountReceived)} (${
          item.project_id
            ? String(invoice.project_id.symbol).toUpperCase()
            : "N/A"
        })`,
    },
    {
      key: "total",
      title: "Total",
      render: (item: any) =>
        `${formatNumber(item.project.amount)} (${
          item.project_id
            ? String(invoice.project_id.stock_to_buy).toUpperCase()
            : "N/A"
        })`,
    },
  ];

  useEffect(() => {
    if (id) {
      APIs.PURCHASE.getInvoice({
        params: {
          id,
          type: "project",
        },
      })
        .then((res: any) => {
          if (res && res.invoice) {
            setInvoice(res.invoice);
            setOwner(res.owner);
            setEstock(res.setEstock);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [history, id]);

  useEffect(() => {
    const getImages = async () => {
      if (invoice && invoice.project_id) {
        const res = await getBase64Image(invoice.project_id.logo);
        setLogo(res);
      }
    };
    getImages();
  }, [invoice]);

  return (
    <>
      <Container ref={imgRef}>
        <TopRight src="/assets/images/vzx/invoice1.png" />
        <BotttomLeft src="/assets/images/vzx/invoice2.png" />
        <LogoVzonex>
          <img src="/assets/images/vzx/logo.png" alt="" />
        </LogoVzonex>
        {invoice && (
          <>
            <Header>
              <Logo>
                {/* <img src="/assets/images/vzx/logo.png" alt=""></img> */}
                <img src={logo || invoice.project_id.logo} alt=""></img>
              </Logo>

              <Row>
                <Col xs={6}>
                  <HeaderLeft>
                    <p>Invoice To</p>
                    <h5>{invoice.user_id.email}</h5>
                    <p className="desc">
                      Invoice to buy shares of{" "}
                      <span> {invoice.project_id.name}</span> project
                    </p>
                  </HeaderLeft>
                </Col>
                <Col xs={6}>
                  <HeaderRight>
                    <h3>INVOICE</h3>
                    <p>
                      Invoice #{" "}
                      <span>{new Date(invoice.createdAt).getTime()}</span>
                    </p>
                    <p>
                      Date: <span>{invoice.createdAt}</span>
                    </p>
                  </HeaderRight>
                </Col>
              </Row>
            </Header>

            <Content>
              <Table columns={columns} dataSource={[invoice]}></Table>
            </Content>

            <Row>
              <Col>
                <PaymentInfo>
                  <h5>Payment Info</h5>
                  <Row>
                    <Col xs={4}>
                      <Label>Account#</Label>
                      <Label>Stocks</Label>
                    </Col>
                    <Col xs={8}>
                      <Value>{invoice.user_id._id.slice(10)}...</Value>
                      <Value>
                        {String(invoice.project_id.stock_to_buy).toUpperCase()}
                      </Value>{" "}
                    </Col>
                  </Row>
                </PaymentInfo>
              </Col>

              <Col>
                <Total>
                  <Row>
                    <Col>
                      <p>Sub Total</p>
                      <p>Tax</p>
                      <p className="total">Total</p>
                    </Col>
                    <Col>
                      <p>
                        {`${formatNumber(invoice.project.amount)} (${
                          invoice.project_id
                            ? String(
                                invoice.project_id.stock_to_buy
                              ).toUpperCase()
                            : "N/A"
                        })`}
                      </p>
                      <p>0.00</p>
                      <p className="total-value">
                        {" "}
                        {`${formatNumber(invoice.project.amount)} (${
                          invoice.project_id
                            ? String(
                                invoice.project_id.stock_to_buy
                              ).toUpperCase()
                            : "N/A"
                        })`}
                      </p>
                    </Col>
                  </Row>
                </Total>
              </Col>
            </Row>

            <Footer>
              <h3>Thank you for trusting us</h3>
              <p>For any queries related to this document please contact us</p>
              <a>{owner ? owner.email : "support@vzonex.com"}</a>
              <p>
                Address :
                {estock
                  ? estock.userInfo.address
                  : " Số 6-A12, KĐT Lê Trọng Tấn - Gleximco, An Khánh, Hoài Đức, Hà Nội."}
              </p>
            </Footer>
          </>
        )}
      </Container>

      {invoice && (
        <DownloadImg
          imgRef={imgRef}
          fileName={`invoice_${String(
            invoice.project_id.symbol
          ).toUpperCase()}`}
        />
      )}
    </>
  );
};

export default Invoice;

import { isVnOrigin } from "utils/common";

export const WEB_MODULE_URL = isVnOrigin()
  ? process.env.REACT_APP_WEB_MODULE_URL_VN
  : process.env.REACT_APP_WEB_MODULE_URL;

export const WEB_CHAT_URL = process.env.REACT_APP_WEB_CHAT_URL;

export const WEB_SOCKET_URL = isVnOrigin()
  ? process.env.REACT_APP_MODULE_SOCKET_URL_VN
  : process.env.REACT_APP_MODULE_SOCKET_URL;

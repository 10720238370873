/* eslint-disable indent */
import React from "react";
import Card from "components/v-teamplate/Card";

// images

// import user05 from "assets/images/user/05.jpg";
// import user06 from "assets/images/user/06.jpg";
// import user07 from "assets/images/user/07.jpg";
// import user08 from "assets/images/user/08.jpg";
// import user09 from "assets/images/user/09.jpg";
// import user10 from "assets/images/user/10.jpg";
import VAvatar from "components/v-avatar";
import VActionFriend from "../v-actions-friend";
import useTranslation from "hooks/useTranslation";
import { Col } from "react-bootstrap";
import PageBackgrounds from "components/v-page-background";
import { getUrlProfile } from "utils/common";
import VNameVerify from "../v-name-verify";
import { useHistory } from "react-router-dom";
import { OBJECT_TYPE } from "config/enums";
import { isMobile } from "react-device-detect";

type IProps = {
  page: {
    [key: string]: any;
  };
  type?: string;
  reload?: any;
  col_lg?: number;
  col_md?: number;
};

const View = ({ page, type, reload, col_lg, col_md }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory()

  const count =
    page.count && page.count.count
      ? page.count.count
      : {
          post: 0,
          follower: 0,
        };

  return (
    <Col md={col_md || 4} lg={col_lg || 4} className={"v-business-card col-6"}>
      <Card className="mb-0">
        <div className="top-bg-image">
          <div className="cover-image">
            <PageBackgrounds
              page={"page_bg_profile"}
              width="100%"
              height="100%"
              position="relative"
              zIndex={"0"}
              object_type={page?.object_type || OBJECT_TYPE.PAGE}
              data={page?.profile?.covers}
            />
          </div>
        </div>
        <Card.Body className="text-center">
          <div className="group-icon">
            <VAvatar
              isClick={true}
              userData={page}
              size={isMobile ? "60px" :"80px"}
              hideName={true}
            />
          </div>
          <div className="info-user m-0">
            <h5
              className="fw-bold mb-1 position-relative"
              onClick={(_) => history.push(getUrlProfile(page))}
            >
              <VNameVerify
                className={
                  "mb-0 d-flex align-items-center justify-content-center"
                }
                dataUser={page}
                fontSizeIconVerify={12}
              />
            </h5>
            <p className="my-1 description ">
              {page.profile && page.profile.description
                ? page.profile.description
                : t("Share moments - Keep good memories")}
            </p>
          </div>

          <div className="group-details d-inline-block pb-3">
            <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
              {/* <li className="pe-3 ps-3">
                <p className="mb-0">{t("Post")}</p>
                <h6>{count.post || 0}</h6>
              </li>
              <li className="pe-3 ps-3">
                <p className="mb-0">{t("Follower")}</p>
                <h6>{count.follower || 0}</h6>
              </li> */}
              <li className="pe-3 ps-3">
                <p className="mb-0">
                  <span className="me-1 fw-bold">{count.follower || 0}</span>
                  {t("Visit")}
                </p>
              </li>
            </ul>
          </div>
          {type && (
            <VActionFriend
              className="btn  d-block w-100"
              type={type}
              to_id={page?._id}
              reload={reload}
              isShowText={true}
            />
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default View;

import { Container } from "react-bootstrap";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";

import VButton from "components/v-button";
import { GROUP_ACTION } from "config/enums";
import { getRequestJoinGroups } from "redux/reducers/groups/groups.selector";
import { getGroupRequestAsync } from "redux/reducers/groups/groups.action";
import { removeGroupRequestJoin } from "redux/reducers/groups/groups.reducer";
import VGroupCard from "components/v-objects/v-group-card";

const View = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const groups: any = useAppSelector(getRequestJoinGroups);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = groups;

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getGroupRequestAsync({
        query: {
          page,
          limit,
        },
      })
    );

    setLoading(false);
  };

  const handleViewMore = () => {
    getData({
      page: page + 1,
    });
  };

  const reload = ({ to_id }) => {
    dispatch(removeGroupRequestJoin(to_id));
  };

  return (
    <>
      <div>
        <Container>
          <div className="row">
            {groups &&
              (groups.docs || []).map((group, index) => (
                <VGroupCard
                  key={index}
                  col_lg={3}
                  col_md={4}
                  count={group?.count?.count?.group}
                  group={{
                    ...group,
                    ...group.to,
                  }}
                  type={GROUP_ACTION.CANCEL_REQUEST}
                  reload={reload}
                />
              ))}
          </div>
        </Container>

        <div className="col-12">
          {page < groups.totalPages && (
            <div className="text-center p-2">
              <VButton
                type="button"
                loading={loading}
                size={"small"}
                onClick={handleViewMore}
              >
                {" "}
                {t("View more")}
              </VButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default View;

/* eslint-disable array-callback-return */
import VModal from "components/v-modal";
import { OBJECT_TYPE } from "config/enums";
import useTranslation from "hooks/useTranslation";
import { useState } from "react";
import FormBusiness from "../profile-about/components/business/FormSettingBusiness";
import FormGroup from "../profile-about/components/groups/FormSettingGroup";
import FormPage from "../profile-about/components/pages/FormSettingPage";
import FormChannel from "../profile-about/components/channel/FormSettingChannel";
import BusinessService from "api/businesses";
import GroupsService from "api/groups";
import PagesService from "api/pages";
import { REGEX_EMAIL } from "utils/validates";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getBusinessManageAsync } from "redux/reducers/businesses/businesses.action";
import { getGroupManageAsync } from "redux/reducers/groups/groups.action";
import { getPageManageAsync } from "redux/reducers/pages/pages.action";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { Form } from "react-bootstrap";
import UploadFile from "components/uploadFile";
import APIs from "api";
import ChannelService from "api/channels";
import { getChannelManageAsync } from "redux/reducers/channels/channels.action";

/* eslint-disable indent */
const defaultValidate = {
  email: null,
  phone: null,
  full_name: null,
  password: null,
};

const View = (props) => {
  const { t } = useTranslation();
  const { objectType, openModal, setOpenModal, onSuccess, isChat } = props;

  const menus: any = useAppSelector(getMenusByKey("signup_policy"));
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(null);
  const [validate, setValidate] = useState(defaultValidate);
  const [isPolicy, setIsPolicy] = useState(false);
  const [listFiles, setListFiles] = useState(null);
  const dispatch = useAppDispatch();

  const performValidate = (props = [], currentDetail = null) => {
    let _result = validate;
    let _detail = currentDetail || state;
    let _isValid = true;
    if (props.length === 0) {
      for (const property in _result) {
        props.push(property);
      }
    }
    props.forEach((prop) => {
      switch (prop) {
        case "full_name":
          _result[prop] = !_detail.full_name
            ? `${t("name")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "phone":
          _result[prop] =
            !_detail.phone && !isChat
              ? `${t("Phone")} ${t("message.cant-be-empty")}`
              : null;
          break;
        case "email":
          _result[prop] =
            !_detail.email && objectType === OBJECT_TYPE.BUSINESS
              ? `${t("Email")} ${t("message.cant-be-empty")}`
              : null;
          if (_detail.email && !REGEX_EMAIL.test(_detail.email))
            _result[prop] = `${t("message.invalid-email")}`;
          break;
        case "password":
          _result[prop] =
            !_detail.password && objectType === OBJECT_TYPE.BUSINESS
              ? `${t("Password")} ${t("message.cant-be-empty")}`
              : null;
          break;
        default:
          break;
      }
    });
    setValidate((prev) => ({
      ...prev,
      ..._result,
    }));

    for (const property in _result) {
      if (_result[property]) {
        _isValid = false;
        break;
      }
    }
    return _isValid;
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };
  const submit = async () => {
    setLoading(true);
    const isValid = performValidate([]);
    if (!isValid) return setLoading(false);
    try {
      let res;
      let listImg;
      let avatar;
      if (Object.keys(listFiles || {}).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users`);
        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
      }

      if (listImg && listImg?.length > 0) {
        avatar = listImg[0]?.filePath;
      }

      if (objectType === OBJECT_TYPE.BUSINESS) {
        res = await BusinessService.create({
          body: {
            account: {
              full_name: state.full_name,
              email: state?.email,
              phone: state?.phone,
              object_type: state.object_type,
              password: state?.password,
              avatar: avatar,
            },
            profile: {
              ...state,
              location: state?.address || state?.location,
              additionalData: state.additionalData,
            },
          },
        });
        dispatch(
          getBusinessManageAsync({
            query: {
              page: 1,
              limit: 10,
            },
          })
        );
      }

      if (objectType === OBJECT_TYPE.GROUP) {
        res = await GroupsService.create({
          body: {
            account: {
              full_name: state.full_name,
              phone: state?.phone,
              object_type: state.object_type,
              group_type: state.group_type,
              avatar: avatar,
              ...(isChat && {
                disabledTimeline: true,
              }),
            },
            profile: {
              ...state,
              location: state?.address || state?.location,
              additionalData: state.additionalData,
            },
          },
        });
        dispatch(
          getGroupManageAsync({
            query: {
              page: 1,
              limit: 10,
            },
          })
        );
      }

      if (objectType === OBJECT_TYPE.PAGE) {
        res = await PagesService.create({
          body: {
            account: {
              full_name: state.full_name,
              phone: state?.phone,
              object_type: state.object_type,
              group_type: state.group_type,
              avatar: avatar,
            },
            profile: {
              ...state,
              location: state?.address || state?.location,
              additionalData: state.additionalData,
            },
          },
        });
        dispatch(
          getPageManageAsync({
            query: {
              page: 1,
              limit: 10,
            },
          })
        );
      }

      if (objectType === OBJECT_TYPE.CHANNEL) {
        res = await ChannelService.create({
          body: {
            account: {
              full_name: state.full_name,
              phone: state?.phone,
              object_type: state.object_type,
              group_type: state.group_type,
              avatar: avatar,
            },
            profile: {
              ...state,
              location: state?.address || state?.location,
              additionalData: state.additionalData,
            },
          },
        });
        dispatch(
          getChannelManageAsync({
            query: {
              page: 1,
              limit: 10,
            },
          })
        );
      }

      if (res) {
        onSuccess && onSuccess(res);
        setOpenModal(false);
        setLoading(false);
      }
    } catch (error) {
      console.log({
        error,
      });
      toast.error(t(error?.errors));
      setLoading(false);
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setListFiles(null);
  };

  return (
    <VModal
      title={t("Create")}
      isOpen={openModal}
      loading={loading}
      centered={true}
      disableConfirm={!isPolicy}
      confirmLabel={t("Create")}
      classNameBody={"px-2"}
      onCancel={() => handleCloseModal()}
      onConfirm={submit}
    >
      <div>
        <div className={"form-group "}>
          <Form.Label>{t("Avatar")}</Form.Label>
          <div className="upload-container">
            <div className="list-upload">
              <div className={"upload-file"}>
                <UploadFile
                  id={"avatar"}
                  ratio="340x340"
                  file={listFiles}
                  setFile={setFile}
                />
              </div>
            </div>
          </div>
        </div>
        {objectType === OBJECT_TYPE.BUSINESS && (
          <FormBusiness
            isCreate={true}
            data={{
              object_type: OBJECT_TYPE.BUSINESS,
            }}
            setDataCreate={setState}
            validateCreate={validate}
          />
        )}
        {objectType === OBJECT_TYPE.GROUP && (
          <FormGroup
            isCreate={true}
            isChat={isChat}
            data={{
              object_type: OBJECT_TYPE.GROUP,
            }}
            setDataCreate={setState}
            validateCreate={validate}
          />
        )}
        {objectType === OBJECT_TYPE.PAGE && (
          <FormPage
            isCreate={true}
            data={{
              object_type: OBJECT_TYPE.PAGE,
            }}
            setDataCreate={setState}
            validateCreate={validate}
          />
        )}
        {objectType === OBJECT_TYPE.CHANNEL && (
          <FormChannel
            isCreate={true}
            data={{
              object_type: OBJECT_TYPE.CHANNEL,
            }}
            setDataCreate={setState}
            validateCreate={validate}
          />
        )}
        <div className="d-flex">
          <div>
            <input
              className="form-check-input me-2"
              type="checkbox"
              onChange={(e) => setIsPolicy(e.target.checked)}
            />
          </div>
          <label className="form-check-label" htmlFor="customCheck1">
            {t(menus[0]?.description)}{" "}
            <a href={menus[0]?.url} target="_blank" rel="noreferrer">
              {t(menus[0]?.name)}
            </a>
          </label>
        </div>
      </div>
    </VModal>
  );
};

export default View;

/* eslint-disable array-callback-return */
/* eslint-disable indent */
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import Card from "components/v-teamplate/Card";

import VIcon from "components/v-icon";
import PreviewFamily from "../components/Preview";
import FormFamilySetting from "../components/FormSetting";

type IProps = {
  dataUser: any;
  isMyProfile: any;
};

const IntroduceFamily = (props: IProps) => {
  const { dataUser, isMyProfile } = props;
  const location = useLocation();
  const history = useHistory();

  const isEdit = queryString.parse(location.search)?.isEdit;

  const handleAction = () => {
    const search = new URLSearchParams(window.location.search);
    if(isEdit){
      search.delete("isEdit")
    } else {
      search.set("isEdit", `true`)
    }
    history.push(`?${search}`);
  };

  return (
    <Card>
      <Card.Body>
        <div className="d-flex justify-content-end mb-3">
          {isMyProfile && (
            <div className=" cursor-pointer" onClick={handleAction}>
              {isEdit ? (
                <VIcon name="cancel.svg" width={20} height={16} />
              ) : (
                <VIcon name="edit-page.svg" />
              )}
            </div>
          )}
        </div>
        {isEdit ? (
          <>
            <FormFamilySetting
              dataUser={dataUser}
              isMyProfile={isMyProfile}
            />
          </>
        ) : (
          <div className="acc-edit">
            <div className="user-account">
              <PreviewFamily userData={dataUser} />
            </div>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default IntroduceFamily;

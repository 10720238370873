import { Container } from "react-bootstrap";
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
// import useTranslation from "hooks/useTranslation";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";

// import VButton from "components/v-button";
import { FRIEND_ACTION } from "config/enums";
import VPageCard from "components/v-objects/v-page-card";
import { getSuggestPages } from "redux/reducers/pages/pages.selector";
import { getPageSuggestsAsync } from "redux/reducers/pages/pages.action";
import { removePageSuggest } from "redux/reducers/pages/pages.reducer";
import VInfiniteLoad from "components/v-infinite-load";

const View = () => {
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const pages: any = useAppSelector(getSuggestPages);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = pages;

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getPageSuggestsAsync({
        query: {
          page,
          limit,
        },
      })
    );
    setLoading(false);
  };

  const handleViewMore = () => {
    getData({
      page: page + 1,
    });
  };

  const reload = ({ to_id }) => {
    dispatch(removePageSuggest(to_id));
  };

  return (
    <VInfiniteLoad
      canLoadMore={page + 1 <= pages.totalPages}
      isLoadingMore={loading}
      onLoadMore={handleViewMore}
    >
      <div>
        <Container>
          <div className="row suggest-pages">
            {pages &&
              (pages.docs || []).map((page, index) => (
                <VPageCard
                  key={index}
                  col_lg={3}
                  col_md={4}
                  page={page}
                  type={FRIEND_ACTION.FOLLOW}
                  reload={reload}
                />
              ))}
          </div>
        </Container>

        {/* <div className="col-12">
          {page < pages.totalPages && (
            <div className="text-center p-2">
              <VButton
                type="button"
                loading={loading}
                size={"small"}
                onClick={handleViewMore}
              >
                {" "}
                {t("View more")}
              </VButton>
            </div>
          )}
        </div> */}
      </div>
    </VInfiniteLoad>
  );
};

export default View;

/* eslint-disable max-len */
import Input from "components/v-input";
import Button from "components/v-button";
import { toast } from "react-toastify";
import Network from "../../networks";
import useTranslation from "hooks/useTranslation";

const Step1 = ({ dataPayment, nextStep, setDataByKey, backStep }) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    setDataByKey("amount", e);
  };

  const handleClick = () => {
    if (+dataPayment.amount <= 0)
      return toast.error(t("Amount must be greater 0!"));
    nextStep();
  };

  return (
    <div className="content-right-view">
      <Network
        network={dataPayment.network}
        setNetwork={(data) => {
          setDataByKey("network", data);
        }}
      />
      <Input
        isCurrency={true}
        transparent={true}
        label={`${t("Amount")} (${String(dataPayment.stock || "").toUpperCase()}) *`}
        value={dataPayment.amount}
        onChange={(e) => handleChange(e.target.value)}
      />
      <div className="text-description-warning">
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          {`${dataPayment.stock.toUpperCase()} ${t("deposit is free. Vzonex does not collect")} ${dataPayment.stock.toUpperCase()} 
          ${t("deposit fee, however, in some instances, you may have to bear a small transaction fee charged directly by the processing bank or network.")}`}
        </p>
      </div>
      <div className="submit-step">
        <Button onClick={handleClick}>{t("Continue")}</Button>
        <Button transparent={true} onClick={() => backStep()}>
          {t("Back")}
        </Button>
      </div>
    </div>
  );
};

export default Step1;

import Button from "components/v-button";
const View = ({ network, setNetwork }) => {
  return (
    <div className="mb-3">
      <span>Network</span>
      <div className="network">
        <Button
          height="40px"
          className={`btn-network ${network === "vzonex" && "active"}`}
          onClick={() => setNetwork("vzonex")}
        >
          VZONEX
        </Button>
        <Button
          height="40px"
          className={`btn-network ${network === "bsc" && "active"}`}
          onClick={() => setNetwork("bsc")}
          disabled={true}
        >
          BSC
        </Button>
        <Button
          height="40px"
          className={`btn-network ${network === "ethereum" && "active"}`}
          onClick={() => setNetwork("ethereum")}
          disabled={true}
        >
          ERC20
        </Button>
        <Button
          height="40px"
          className={`btn-network ${network === "tron" && "active"}`}
          onClick={() => setNetwork("tron")}
          disabled={true}
        >
          TRC20
        </Button>
      </div>
    </div>
  );
};

export default View;

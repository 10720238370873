/* eslint-disable max-len */
import { Middleware } from "redux";
import { configureStore } from "@reduxjs/toolkit";

import rootReducer, { IRootState } from "./reducers";

import socketMiddleware from "./middleware/socketMiddleware";
import vdbSocketModules from "./middleware/vdbSocketModules";

const logger: Middleware<{}, IRootState> = (store) => (next) => (action) => {
  // console.group(action.type)
  // console.info('dispatching', action)
  let result = next(action);
  // console.log('next state', store.getState())
  // console.groupEnd()
  return result;
};

export default function configureAppStore(preloadedState?: any) {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => [
      ...getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: ["vdb_socket/connect_socket"],
        },
      }),
      logger,
      socketMiddleware,
      vdbSocketModules,
    ],
    preloadedState,
  });

  // if (process.env.NODE_ENV !== 'production' && module.hot) {
  // }

  return store;
}

import { DEFAULT_RADIUS_KM } from "constants/provinces";
import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const getUserAuth = () =>
  new Promise((resolve, reject) => {
    request()
      .get("users/userAuth")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getByOption = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`users/getByOption?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserByToken = (token) =>
  new Promise((resolve, reject) => {
    request({
      headers: {
        Authorization: "Beare " + token,
      },
    })
      .get("users/userAuth")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getNonce = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`users/nonce/${req.params.address}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const editUser = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .put(`users/edit`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changePassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put("users/changePassword", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkPassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/checkpassword", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const check2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/check2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSecurity = () =>
  new Promise((resolve, reject) => {
    request()
      .get("users/security")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const enable2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/enable-2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const disable2FA = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/disable-2fa", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSharings = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`commissions/sharings`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getBalancesByUser = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/finances/balances/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSalesStatistics = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`users/sales-statistics`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createPinCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/create-pin", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resetPinCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("users/reset-pin", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkPinExist = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("users/check-exist-pin")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const editUserSecure = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .put(`users/edit-secure`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getRevenuesInSystem = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/revenues/system")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const claimRevenueSystem = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/revenues/claim-system", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const updateProfile = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put("/users/edit-profile", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getAccountAddressAndCareer = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/users/user-address-career")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getPermissions = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/users/permissions")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const updateCover = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/profiles/covers?${qs.stringify(req.query)}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getProfileById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/profile/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createToken = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/users/createToken`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const deleteToken = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/users/deleteToken`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getDataAccessByUsers = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/getDataAccess/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getDataAccessByOwner = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/getDataAccessByOwner`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRankings = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/getRankings?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRankingByUser = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/getRanking/${req.params.id}/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAccountSwitch = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/users/account-switch")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const loginAccountSwitch = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/users/login-account-switch", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserByReferCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/by-refer-code/${req.refer_code}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const userDeleteAccount = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/users/delete-account`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUsersFamily = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/getUsersFamily`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateCoordinate = (req: { body: { lat: number; lng: number } }) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/profiles/coordinate`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getObjectsUsers = (coords: { lat: number; lng: number }, params: any) =>
  new Promise((resolve, reject) => {
    const { lat, lng } = coords;
    const query = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    request()
      .get(
        `/profiles/user-by-coor-and-radius?lat=${lat}&lng=${lng}&r=${DEFAULT_RADIUS_KM}&${query}`
      )
      .then((res) => {
        const { docs } = res?.data?.data as any;
        if (docs) resolve(docs);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserOnline = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/user-onlines/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRankingsOnline = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/user-onlines/rankings?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserDepartments = (userId: string, type?: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/departments/${userId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const counter = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users/counter`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UserService = {
  getUserAuth,
  getSecurity,
  enable2FA,
  disable2FA,
  editUser,
  changePassword,
  getBalancesByUser,
  checkPassword,
  check2FA,
  getNonce,
  getSharings,
  getSalesStatistics,
  createPinCode,
  resetPinCode,
  checkPinExist,
  editUserSecure,
  getUserByToken,
  getRevenuesInSystem,
  claimRevenueSystem,
  getByOption,
  updateProfile,
  getAccountAddressAndCareer,
  updateCover,
  getPermissions,
  getProfileById,
  createToken,
  deleteToken,
  getDataAccessByUsers,
  getDataAccessByOwner,
  getRankings,
  getAccountSwitch,
  loginAccountSwitch,
  getRankingByUser,
  getUserByReferCode,
  userDeleteAccount,
  getUsersFamily,
  updateCoordinate,
  getObjectsUsers,
  getUserOnline,
  getRankingsOnline,
  getUserDepartments,
  counter,
};

export default UserService;

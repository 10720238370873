/* eslint-disable indent */
import { Form } from "react-bootstrap";
import TextareaAutosize from 'react-textarea-autosize';
import { useSelector } from "react-redux";
import * as SettingSelector from "redux/reducers/setting-system/selectors"

interface IProps {
	onChange?: any;
	label?: any;
	errMessage?: string;
	[key: string]: any;
}

const View = ({
	color = "",
	id = "",
	name = "",
	className,
	ref,
	label,
	minRows,
	maxRows,
	disabled = false,
	required = false,
	value = null,
	placeholder = "",
	defaultValue = null,
	hasError = false,
	prefix = null,
	suffix = null,
	suffixIcon = null,
	errMessage = "",
	onChange = () => { },
	onBlur = () => { },
	...props
}: IProps) => {
	const themeScheme = useSelector(SettingSelector.theme_scheme)

	return (
		<Form.Group className="form-group v_input">
			<div className="material-textfield">
				<TextareaAutosize
					className={`form-control mb-0 ${className}`}
					id={id}
					name={name}
					minRows={minRows || 3}
					maxRows={maxRows || 3}
					placeholder={placeholder}
					{...(defaultValue ? { ...{ defaultValue } } : {})}
					onChange={onChange}
				/>
				{/* <Form.Control
					as="textarea"
					className={`form-control mb-0 ${className}`}
					id={id}
					name={name}
					rows={rows}
					placeholder={placeholder}
					{...(defaultValue ? { ...{ defaultValue } } : {})}
					onChange={onChange}
				/> */}
				<label className={`${themeScheme} d-flex align-items-center`}>{label} {required && <span className="required">*</span>}</label>
			</div>
		</Form.Group>
	)
}

export default View;
/* eslint-disable indent */
import { nanoid } from "nanoid";
import { setAdvertisement } from "redux/reducers/advertisments/advertisments.reducers";
import { setQrCodeLogin } from "redux/reducers/auth/auth.reducer";
import { setReward } from "redux/reducers/settings/settings.reducer";
import { setBalances, setUserOnline } from "redux/reducers/users/users.reducer";
import io from "socket.io-client";
import { IObject } from "types";

const socketMiddleware = (store: IObject) => (next: any) => {
  const connectSocket = () => {
    const token = localStorage.getItem("auth");

    if (token) {
      const socket = io(`${process.env.REACT_APP_SOCKET_URL}/users`, {
        query: {
          token,
        },
        transports: ["websocket"],
      });
      socket.on("balances", (balances) => {
        store.dispatch(setBalances(balances));
      });
      socket.on("rewards", (data) => {
        store.dispatch(setReward(data));
      });
      socket.on("user-online", (data) => {
        store.dispatch(setUserOnline(data));
      });
      socket.on("user-offline", (data) => {
        store.dispatch(setUserOnline(data));
      });
    }
  };

  const connectSocketAd = () => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}/advertisements`, {
      transports: ["websocket"],
    });
    socket.on("advertisement", (data) => {
      store.dispatch(setAdvertisement(data));
    });
  };

  const connectSocketLoginQr = () => {
    const socket = io(`${process.env.REACT_APP_SOCKET_URL}/auth`, {
      transports: ["websocket"],
    });
    const qr_code_login = `VDB_QR_LOGIN_${nanoid(10)}${Date.now()}`;
    store.dispatch(setQrCodeLogin(qr_code_login));
    socket.emit("get_code_qr_login", qr_code_login);

    socket.on("verify-login-qr", (token) => {
      if (token && !localStorage.getItem("auth")) {
        localStorage.setItem("auth", token);
        window.location.reload();
      }
    });
  };

  return (action: any) => {
    switch (action.type) {
      case "users/setConnectSocket": {
        if (action.payload) {
          connectSocket();
        }
        return next(action);
      }
      case "settings/setConnectSocket": {
        if (action.payload) {
          connectSocketAd();
        }
        return next(action);
      }
      case "auth/setConnectSocket": {
        if (action.payload) {
          connectSocketLoginQr();
        }
        return next(action);
      }
      default:
        return next(action);
    }
  };
};

export default socketMiddleware;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import Button from "components/v-button";
import useTranslation from "hooks/useTranslation";
import Input from "components/v-input";
import { formatNumber, randString } from "utils/common";
import Verify from "../../verify";
import Network from "../../networks";
import HistoryP2P from "../history-p2p";
import { useHistory } from "react-router-dom";
import Card from "components/v-teamplate/Card";
import PaymentService from "api/payments";
import { IObject } from "types";
import useQuery from "hooks/useQuery";
import queryString from "query-string";

type IProps = {
  url: string;
  userId?: string;
  balances: IObject;
  reloadBalance: () => {};
};

const View = (props: IProps) => {
  const { url, userId, balances, reloadBalance } = props;
  const query = useQuery();
  const { stock } = query;
  const history = useHistory();
  const { t } = useTranslation();

  const [step, setStep] = useState(1);
  const [amount, setAmount] = useState<number>(null);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [address, setAddress] = useState(null);
  const [network, setNetwork] = useState("bsc");
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });

  useEffect(() => {
    getPayment();
  }, []);

  const handleChange = (e) => {
    if (+e < 0) {
      setAmount(0);
    } else if (+e === 0) {
      setAmount(null);
    } else {
      setAmount(+e);
    }
  };

  const handleClickButtonTop = async () => {
    setErrMessage("");
    if (step !== 2) {
      setStep(step + 1);
    } else {
      try {
        if (amount > 0) {
          setLoading(true);
          await PaymentService.withdraw({
            body: {
              user_id: userId,
              verify,
              amount: amount,
              content: randString(false, 10),
              address,
              network,
              stock: stock,
            },
          });
          getPayment();
          reloadBalance();
          toast.success(t("Withdraw successful!"));
          setStep(1);
          setLoading(false);
        } else {
          setErrMessage(t("Please enter amount"));
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(t(error?.errors));
      }
    }
  };

  const backStep = () => {
    if (step === 1) {
      const _query = {
        ...query,
        method: 'withdraw_stock',
      };
      history.push(`${url}/?${queryString.stringify(_query)}`);
    }
    setStep(step - 1);
  };

  const getPayment = async () => {
    try {
      const res: any = await PaymentService.getPaymentByUserId({
        userId: userId,
        type: "withdraw",
        stock,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card isOpacity={true}>
      <div className="detais-rigth-view">
        <div className="title-action-wallets">
          <p>
            {t("Withdraw")} {String(stock || "").toUpperCase()}
          </p>
        </div>

        {step === 1 && (
          <div className="content-right-view">
            <div className="mb-2">
              <Network network={network} setNetwork={setNetwork} />
              <Input
                transparent={true}
                label={t("Address")}
                placeholder="0x9f0F7601b9143ACc36aE838800153805698e8f08"
                onChange={(e) => setAddress(e.target.value)}
              />
            </div>
            <Input
              isCurrency={true}
              transparent={true}
              label={`${t("Amount")} (${stock?.toUpperCase()})*`}
              placeholder="10,000"
              value={amount}
              hasError={errMessage ? true : false}
              errMessage={t(errMessage)}
              suffix={
                <div
                  className="cursor-pointer"
                  onClick={() => setAmount(+balances[stock] || 0)}
                >
                  {t("MAX")}
                </div>
              }
              onChange={(e) => handleChange(e.target.value)}
            />
            <div className="text-description-warning">
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                {t("Maximum")} {String(stock || "").toUpperCase()}{" "}
                {t("Withdrawal")}:{" "}
                <span>
                  {formatNumber(+balances[stock] || 0)}
                  {String(stock).toUpperCase()}
                </span>
              </p>
            </div>
            <div className="text-description-warning">
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                {t(
                  "Please enter the correct network address for the transaction."
                )}
              </p>
            </div>
          </div>
        )}
        {step === 2 && <Verify verify={verify} setVerify={setVerify} />}
      </div>
      <div className="submit-step">
        <Button loading={loading} height="40px" onClick={handleClickButtonTop}>
          {step === 2 ? t("Confirm") : t("Continue")}
        </Button>
        {step !== 0 && (
          <Button transparent={true} height="40px" onClick={() => backStep()}>
            {t("Back")}
          </Button>
        )}
      </div>
      {hisDeposit.length ? (
        <HistoryP2P lists={hisDeposit} type="withdraw" stock={stock} />
      ) : (
        <></>
      )}
    </Card>
  );
};

export default View;

import { createAsyncThunk } from "@reduxjs/toolkit";
import NotificationService from "api/notifications";
import { IRequestData } from "api/request";

export const getNotificationsClientAsync = createAsyncThunk(
  "notifications/list/client",
  async (req: IRequestData) => {
    try {
      const response: any = await NotificationService.getNotifications(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getNotificationsOwnerAsync = createAsyncThunk(
  "notifications/list/owner",
  async () => {
    try {
      const response: any = await NotificationService.getNotificationsOwner({});
      return response;
    } catch (error) {
      return error;
    }
  }
);

/* eslint-disable indent */
import VLoading from "components/v-loading";
import { ReactElement, useEffect } from "react";
import { useInView } from "react-intersection-observer";

const InfiniteLoad = ({
  canLoadMore,
  isLoadingMore,
  onLoadMore,
  children,
}: {
  canLoadMore: boolean;
  isLoadingMore: boolean;
  onLoadMore: () => void;
  children: ReactElement;
}) => {
  const { ref, inView } = useInView({ trackVisibility: true, delay: 500 });
  useEffect(() => {
    if (inView && canLoadMore) {
      onLoadMore();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inView, canLoadMore]);

  return (
    <div>
      {children}
      <div
        ref={ref}
        className="my-6 text-center text-lg font-medium text-white"
      >
        {canLoadMore && <VLoading />}
      </div>
    </div>
  );
};

export default InfiniteLoad;

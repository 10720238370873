/* eslint-disable max-len */
import { useEffect, useState } from "react";
import Map from "./View";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";
import { MapsProvider } from "pages/sos/maps/MapsContext";
import { MapContextProvider } from "components/smart-maps/context";

const VMap = (props) => {
  const [googleKey, setGoogleKey] = useState("");
  const generals = useAppSelector(getGeneral);
  
  useEffect(() => {
    setGoogleKey(
      generals?.site?.find((el: any) => el.key === "google_map_api_key")
        ?.content
    );
  }, [generals]);

  return (
    <MapsProvider>
      <MapContextProvider>
        {googleKey && <Map {...props} googleMapKey={googleKey} />}
      </MapContextProvider>
    </MapsProvider>
  );
};
export default VMap;

import React, { useState } from "react";
import Input from "components/v-input";
import Dropdown from "components/v-dropdown";
import Modal from "components/v-modal";
// import { Modal } from "react-bootstrap";

import { useForm, Controller } from "react-hook-form";
import { toast } from "react-toastify";
import { listBank } from "config/constant";
import useTranslation from "hooks/useTranslation";
import BankSevice from "api/banks";
interface IProps {
  isOpen: boolean;
  setIsOpen: any;
  userId: string;
}

export default function View({ isOpen, setIsOpen, userId }: IProps) {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const { t } = useTranslation();

  const [error, setError] = useState(null);
  const [bank, setBank] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleAddBank = async (data) => {
    if (!bank?.label) {
      return setError(new Error("Please select bank name!"));
    }
    setError(null);
    setLoading(true);
    const body = {
      account_name: data.bankAccountHolderName,
      account_number: data.bankAccountNumber,
      bank_name: bank?.label,
      user_id: userId,
    };
    try {
      await BankSevice.createBank({
        body,
      });
      toast.success(t("Added bank successful!"));
      setIsOpen(false);
    } catch (err) {
      setError(t(err.message));
    }
    setLoading(false);
  };

  const onChangeBankName = (e) => {
    setBank(e.target);
  };

  const renderModal = () => {
    return (
      <div className="mt-4 p-3">
        <form>
          <Dropdown
            width={"100%"}
            transparent={true}
            hasError={error}
            label={t("Bank name")}
            options={listBank}
            defaultValue={t("Choose the bank name")}
            value={bank?.value || ""}
            onChange={onChangeBankName}
          />

          <div className="mt-3">
            <Controller
              name="bankAccountNumber"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  type="number"
                  transparent={true}
                  placeholder="Your bank account number"
                  label="Bank account number"
                  errMessage={
                    errors.bankAccountNumber &&
                    t(String(errors.bankAccountNumber?.message))
                  }
                  {...field}
                />
              )}
            />
          </div>
          <div className="mt-3">
            <Controller
              name="bankAccountHolderName"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Input
                  transparent={true}
                  placeholder={t("Your bank account holder name")}
                  label={t("Bank account holder name")}
                  errMessage={
                    errors.bankAccountHolderName &&
                    t(String(errors.bankAccountHolderName?.message))
                  }
                  {...field}
                />
              )}
            />
          </div>
        </form>
      </div>
    );
  };

  return (
    <Modal
      isOpen={isOpen}
      title={t("Add new bank account")}
      centered={true}
      onConfirm={handleSubmit(handleAddBank)}
      onCancel={() => setIsOpen(false)}
      loading={loading}
      children={renderModal()}
    />
  );
}

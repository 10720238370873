/* eslint-disable indent */
import VIcon from "components/v-icon";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import VisitCard from "pages/social/settings/components/visit-card/components/visit-orther";
import { toast } from "react-toastify";
import { copyText, getUrlProfile } from "utils/common";
import BusinessCode from "../../object-details/referral-code";
import { isUser } from "utils/friends";
import { isBusiness } from "utils/friends";
import { isPage } from "utils/friends";
import { isGroup } from "utils/friends";
import VQRCode from "components/v-qr-code";

const View = ({ dataUser }) => {
  const domain = window.location.origin;
  const { t } = useTranslation();
  const { object_type } = dataUser;

  const handleCopy = (value) => {
    copyText(value);
    toast.success(t("Copied"));
  };

  return (
    <div className="profile-qr-code">
      <Card>
        <Card.Body>
          <h4 className="text-center fw-bold">
            {t(
              isUser(object_type)
                ? "Friend invite link"
                : isBusiness(object_type)
                ? "Link to invite to join the business"
                : isGroup(object_type)
                ? "Link to invite to join the group"
                : isPage(object_type)
                ? "Link to invite to join the page"
                : "Qr code"
            )}
          </h4>
          <div className="share-app d-flex justify-content-center">
            <p className="url-share">{`${domain}${getUrlProfile(dataUser)}`}</p>
            <VIcon
              onClick={() => handleCopy(`${domain}${getUrlProfile(dataUser)}`)}
              className="cursor-pointer"
              name="copy.svg"
            />
          </div>
          <BusinessCode dataUser={dataUser} />
          <div className="d-flex justify-content-center my-2">
            <VQRCode value={`${domain}${getUrlProfile(dataUser)}`} size={120} />
          </div>
          <div>
            <VisitCard type={"profile"} dataUser={dataUser} />
          </div>
        </Card.Body>
      </Card>
      
      {/* <div className="d-flex justify-content-center">
        <Card className="share-app p-2">
          <span>{t("Share by link")}</span>
          <div className="d-flex">
            <p className="url-share">{`${domain}${getUrlProfile(dataUser)}`}</p>
            <VIcon
              onClick={() => handleCopy(`${domain}${getUrlProfile(dataUser)}`)}
              className="cursor-pointer"
              name="copy.svg"
            />
          </div>
        </Card>
        <Card className="share-app cursor-pointer ms-2">
          <div className="item">
            <VIcon name="message.svg" />
          </div>
        </Card>
        <Card className="share-app cursor-pointer ms-2">
					<div className="item">
						<VIcon name="logo.svg" />
					</div>
				</Card>
      </div> */}
    </div>
  );
};

export default View;

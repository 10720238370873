/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import {
  getChannelFollowingsAsync,
  getChannelManageAsync,
  getChannelSuggestsAsync,
} from "./channels.action";

export interface IChannelState {
  suggests: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  manage: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  followings: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  dataAccesses: any;
}

const initialState: IChannelState = {
  suggests: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 10,
  },
  manage: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 10,
  },
  followings: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 10,
  },
  dataAccesses: {},
};

export const Channels = createSlice({
  name: "channels",
  initialState,
  reducers: {
    removeChannelSuggest: (state: IChannelState, action: any) => {
      const { docs } = state.suggests;
      state.suggests.docs = docs.filter((f) => f._id !== action.payload);
      state.suggests.totalDocs = state.suggests.totalDocs - 1;
    },
    setDataAccesses: (state: IChannelState, action) => {
      const data = action.payload;
      data.forEach((item) => {
        state.dataAccesses[item.user_id] = item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getChannelSuggestsAsync.pending, (state) => {})
      .addCase(getChannelSuggestsAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.suggests = action.payload || {};
        } else {
          state.suggests = {
            ...state.suggests,
            page,
            docs: state.suggests.docs.concat(docs),
          };
        }
      })
      .addCase(getChannelManageAsync.pending, (state) => {})
      .addCase(getChannelManageAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.manage = action.payload || {};
        } else {
          state.manage = {
            ...state.manage,
            page,
            docs: state.manage.docs.concat(docs),
          };
        }
      })
      .addCase(getChannelFollowingsAsync.pending, (state) => {})
      .addCase(getChannelFollowingsAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.followings = action.payload || {};
        } else {
          state.followings = {
            ...state.followings,
            page,
            docs: state.followings.docs.concat(docs),
          };
        }
      });
  },
});

export const { setDataAccesses, removeChannelSuggest } = Channels.actions;

export default Channels.reducer;

import useQuery from "hooks/useQuery";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/reducers/hook";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";
import { v4 as uuidv4 } from "uuid";
import NewsFeedModule from "pages/social/home/newsfeed";

const FeedsByPlugin = (props : any) => {
  const { type, plugin } = props;
  const dispatch = useDispatch();
  const query = useQuery();
  const { search = "", lat = "" , lg = "" } = query;
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const { reloadNewsFeedNumber } = useAppSelector(getUISettingState);

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <NewsFeedModule
      className={"iframe-newfeed-hashtag"}
      reloadNewsFeedNumber={reloadNewsFeedNumber}
      moduleSessionId={moduleSessionId}
      fromSessionUid={fromSessionUid}
      search={search?.length > 1 ? search : ""}
      lat={lat ? lat : ""}
      lng={lg ? lg : ""}
      type={type}
      plugin={plugin}
      getAll={true}
    />
  );
};
export default FeedsByPlugin;

/* eslint-disable indent */
import { Route } from "react-router-dom";
import { checkIsPreviewPublic } from "utils/common";
import Preview from "pages/preview";
import MyView from "pages/my"


const Dashboard = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => {
				return checkIsPreviewPublic() ? (
					<Preview />
				) : (
					<MyView />
				)
			}
			}
		/>
	)
};

export default Dashboard;

/* eslint-disable indent */
import useTranslation from "hooks/useTranslation";
import SearchService from "api/search";
import { useEffect, useState } from "react";
import ItemBirthday from "../item";
import { Row } from "react-bootstrap";

const ListBirthday = ({ tab }) => {
  const { t } = useTranslation();
  const [today, setToday] = useState([]);
  const [tomorrow, setTomorrow] = useState([]);
  const [inMonth, setInMonth] = useState([]);

  const getDataBirthday = async () => {
    try {
      const res: any = await SearchService.getBirthday();
      if (res) {
        setToday(res.today || []);
        setTomorrow(res.tomorrow || []);
        setInMonth(res.inMonth || []);
      }
    } catch (error) {}
  };

  const renderList = (title, data) => {
    return (
      <div className="mt-3">
        <div id="utilities_page" className="mb-3">
          <div className="utilities">
            <div className="category-name-container border-bottom border-3">
              <div className="category-name px-5 py-2 text-white fit position-relative">
                <span className="name-utilities">{t(title)}</span>
                <div className="slash"></div>
              </div>
            </div>
          </div>
        </div>
        <div className="birthday">
          <Row>
            {data
              ?.sort(
                (a, b) =>
                  new Date(a?.profile?.birthday).getDate() -
                  new Date(b?.profile?.birthday).getDate()
              )
              ?.map((el) => (
                <ItemBirthday userData={el} tab={tab} />
              ))}
          </Row>
        </div>
      </div>
    );
  };

  useEffect(() => {
    getDataBirthday();
  }, []);

  return (
    <div className="birthday-container">
      {today && today.length > 0 && renderList("Today", today)}
      {tomorrow && tomorrow.length > 0 && renderList("Tomorrow", tomorrow)}
      {inMonth && inMonth.length > 0 && renderList("This month", inMonth)}
    </div>
  );
};

export default ListBirthday;

import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const View = (props) => {
  const { children, settings } = props;

  return <>
    <Slider {...settings}>
      {children}
		 </Slider>
  </>
}

export default View
/* eslint-disable indent */

import { Switch, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import { ROUTES } from "config/routes";

import SidebarSettings from "./sidebar";
import AccountSetting from "./components/account-settings";
import ProfileSetting from "./components/profile-settings";
import ChangePasswordSetting from "./components/change-password-settings";
import VisitCard from "./components/visit-card";
import Affiliate from "./components/affiliate-settings";
import VerificationSetting from "./components/verification-settings";
import SecuritySetting from "./components/security-settings";
import SpecialNameSetting from "./components/special-name-settings";
import SettingsUser from "./components/settings";
import InvoiceSetting from "./components/invoice-setting";
import AuthSession from "./components/auth-sessions-settings";
import DeleteAccountSetting from "./components/detete-account-settings";
import Error404 from "components/v-error-404";
import DigitalAffiliateBankSetting from "./components/digital-affiliate-bank";
import PrivacySecurity from "./components/privacy-security";

const View = () => {
  return (
    <Container>
      <Row className="justify-content-center">
        <Col lg={4} className={"px-1"}>
          <SidebarSettings />
        </Col>
        <Col lg={8} className={"px-1"}>
          <Switch>
            <Route path={ROUTES.ACCOUNT_SETTINGS} component={AccountSetting} />
            <Route path={ROUTES.PROFILE_SETTINGS} component={ProfileSetting} />
            <Route
              path={ROUTES.PASSWORD_SETTINGS}
              component={ChangePasswordSetting}
            />
            <Route path={ROUTES.VISIT_CARD_SETTINGS} component={VisitCard} />
            <Route path={ROUTES.AFFILIATE_SETTINGS} component={Affiliate} />
            <Route
              path={ROUTES.VERIFICATION_SETTINGS}
              component={VerificationSetting}
            />
            <Route
              path={ROUTES.SECURITY_SETTINGS}
              component={SecuritySetting}
            />
            <Route path={ROUTES.SPECIAL_NAME} component={SpecialNameSetting} />
            <Route path={ROUTES.INVOICE_SETTINGS} component={InvoiceSetting} />
            <Route path={ROUTES.AUTH_SESSIONS} component={AuthSession} />

            <Route path={ROUTES.SETTING_GENERAL} component={SettingsUser} />
            <Route
              path={ROUTES.DELETE_ACCOUNT_SETTINGS}
              component={DeleteAccountSetting}
            />
            <Route
              path={ROUTES.DIGITAL_AFFILIATE_BANK}
              component={DigitalAffiliateBankSetting}
            />
            <Route
              path={ROUTES.PRIVACY_SECURITY}
              component={PrivacySecurity}
            />
            <Route path={ROUTES.SETTING} component={Error404} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import Input from "components/v-input";
import Button from "components/v-button";
import Dropdown from "components/v-dropdown";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import IDWithdraw from "./components/IDWithdraw";
import { useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { formatNumber, getUrlProfile } from "utils/common";
import Verify from "../../verify";
import Network from "../../networks";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import Avatar from "components/v-avatar";
import { Link, useHistory } from "react-router-dom";
import { IObject } from "types";
import useQuery from "hooks/useQuery";
import UserService from "api/users";
import TransactionService from "api/transactions";

const optionsPhone = [
  {
    label: "VN +84",
    value: "VN",
  },
];

const WalletWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  fee,
  errorAmount,
  amount,
  handleChange,
  onBlurTo,
}) => {
  const stockName = String(tokenName).toUpperCase();
  const { t } = useTranslation();

  return (
    <div className="detais-rigth-view">
      <div className="mt-3">
        <Controller
          name="to"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Input
                transparent={true}
                label={t(`Stock ${stockName} Address`)}
                placeholder={t(`Stock ${stockName} Address`)}
                hasError={errors.to}
                {...field}
                onBlur={onBlurTo}
              />
            );
          }}
        />
      </div>
      <div className="mt-3">
        {/* <span>Network</span> */}
        <Network network={"vzonex"} setNetwork={() => {}} />
      </div>
      <div className="mt-3">
        <Input
          isCurrency={true}
          type="text"
          transparent={true}
          label={`${t("Stock")} ${stockName}  ${t("Amount")}`}
          placeholder="0.00"
          value={amount}
          hasError={amount > +userBalance[tokenName] || errorAmount}
          suffix={
            <div className="cursor-pointer" onClick={setMaxAmount}>
              {t("MAX")}
            </div>
          }
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>

      <p className="mt-3">
        {t("Maximum")} {stockName} {t("Available")}:{" "}
        <span className="declined">{userBalance[tokenName]}</span> {stockName}
      </p>

      <p>
        {t("Withdraw fee stock")} {stockName}:{" "}
        <span className="declined">{fee.amount}</span>{" "}
        {String(fee.stock || "").toUpperCase()}
      </p>
      {/* <p>You can <SpanColor color="green100">buy</SpanColor> or <SpanColor color="orange100">deposit</SpanColor> VZX</p> */}
      <div className="text-description-warning mt-3">
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          {t(
            "AvailaThe above fees are not fixed, can vary depending on the state of the blockchain networksble"
          )}
        </p>
      </div>
      <div className="text-description-warning">
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>{t("Estimated t˝ime to complete: less than a minute")}</p>
      </div>
    </div>
  );
};

const EmailWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();
  const { t } = useTranslation();

  return (
    <div className="detais-rigth-view">
      <div className="mt-1">
        <Controller
          name="to"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Input
                transparent={true}
                label={t("Email")}
                placeholder={t("Input Vzonex Email")}
                hasError={errors.to}
                {...field}
              />
            );
          }}
        />
      </div>
      <div className="mt-3">
        <Input
          transparent={true}
          label={`${t("Stock")} ${stockName} ${t("Amount")}`}
          placeholder="0.00"
          isCurrency={true}
          type="text"
          value={amount}
          hasError={amount > +userBalance[tokenName] || errorAmount}
          suffix={
            <div className="cursor-pointer" onClick={setMaxAmount}>
              {t("MAX")}
            </div>
          }
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>

      <p className="mt-3">
        {t("Maximum")} {stockName} {t("Available")}:{" "}
        <span className="declined">{userBalance[tokenName]}</span> {stockName}
      </p>
      <p>
        {t("You can")} <span className="success">{t("buy")}</span> {t("or")}{" "}
        <span className="orange">{t("deposit")}</span> {stockName}
      </p>
      <div className="mt-3">
        <Controller
          name="note"
          control={control}
          render={({ field }) => {
            return (
              <Input
                type={"textarea"}
                height="100px"
                transparent={true}
                label={t("Message")}
                placeholder={`${t("Welcome to")} ${stockName} ${t("Stock")}`}
                hasError={errors.note}
                {...field}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

const PhoneWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const stockName = String(tokenName).toUpperCase();
  const { t } = useTranslation();
  return (
    <div className="detais-rigth-view">
      <div className="withdraw-phone">
        <p>
          {t(
            "You can donate coin to your friends via phone numbers, even when your friends have not added their phone number to Stock Vzonex."
          )}
        </p>
        <div className="d-flex mt-3">
          <Dropdown
            options={optionsPhone}
            defaultValue={"VN +84"}
            onChange={() => {}}
          />
          <div className="w-100 me-3">
            <Controller
              name="to"
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                return (
                  <Input
                    type={"number"}
                    transparent={true}
                    placeholder={t("Phone number")}
                    hasError={errors.to}
                    {...field}
                  />
                );
              }}
            />
          </div>
        </div>
      </div>
      <div className="mt-3">
        <Input
          transparent={true}
          label={`${t("Stock")} ${stockName} ${t("Amount")}`}
          placeholder="0.00"
          isCurrency={true}
          type="text"
          value={amount}
          hasError={amount > +userBalance[tokenName] || errorAmount}
          suffix={
            <div className="cursor-pointer" onClick={setMaxAmount}>
              {t("MAX")}
            </div>
          }
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>
      <p className="mt-3">
        {t("Maximum")} {stockName} {t("Available")}:{" "}
        <span className="declined">{userBalance[tokenName]}</span> {stockName}
      </p>
      <p>
        {t("You can")} <span className="success">{t("buy")}</span> {t("or")}{" "}
        <span className="orange">{t("deposit")}</span> {stockName}
      </p>
      <div className="mt-3">
        <Controller
          name="note"
          control={control}
          render={({ field }) => {
            return (
              <Input
                type={"textarea"}
                height="100px"
                transparent={true}
                label={t("Message")}
                placeholder="Welcome to VZX Stock"
                hasError={errors.note}
                {...field}
              />
            );
          }}
        />
      </div>
    </div>
  );
};

type IProps = {
  url: string;
  userId?: string;
  balances: IObject;
  reloadBalance: () => {};
};

const View = (props: IProps) => {
  const { userId, balances, reloadBalance } = props;

  const { t } = useTranslation();
  const userBalance = balances;
  const query = useQuery();
  const { address, amountStock } = query;
  const [tab, setTab] = useState<string>("wallet");
  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(null);
  const cryptos = useAppSelector(getCryptos);
  const [showVerify, setShowVerify] = useState(false);
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });
  const tokenName = query.stock;
  const [amount, setAmount] = useState<number>(null);
  const [errorAmount, setErrorAmount] = useState<boolean>(false);
  const [profileDeposit, setProfileDeposit] = useState<any>(null);
  const history = useHistory();

  console.log({
    address,
    amountStock,
  });

  const {
    control,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    setShowVerify(false);
  }, [tab]);

  useEffect(() => {
    if (cryptos) setStock(cryptos.find((c) => c.symbol === tokenName));
  }, [cryptos, tokenName]);

  useEffect(() => {
    if (address) {
      getUserDeposit(String(address), "wallet");
    }
  }, [address]);

  useEffect(() => {
    if (Number(amountStock) > 0) {
      setAmount(Number(amountStock));
    }
    if (address) {
      setValue("to", address);
    }
  }, [amountStock, address]);

  const getUserDeposit = async (value: string, tab: string) => {
    try {
      const res: any = await UserService.getByOption({
        query: {
          value,
          tab,
        },
      });
      console.log({ res });
      if (res) {
        setProfileDeposit(res);
      } else {
        setProfileDeposit(null);
      }
    } catch (error) {
      console.log(error);
      
      setProfileDeposit(null);
    }
  };

  const handleChange = (e) => {
    setAmount(e);
  };

  const handleSubmitWithdraw = async (data) => {
    const body = {
      method: tab,
      note: data.note,
      to: data.to,
      token: tokenName,
      amount: Number(amount),
      network: "vzonex",
      verify,
    };
    try {
      if (amount <= 0 || !amount) {
        setErrorAmount(true);
      } else {
        if (!showVerify) {
          setShowVerify(true);
          return;
        }
        setLoading(true);
        if (tab === "wallet") {
          await TransactionService.withdraw({
            body: {
              ...body,
              user_id: userId,
            },
          });
        } else {
          await TransactionService.transfer({
            body: {
              ...body,
              user_id: userId,
            },
          });
        }
        reloadBalance();
        setLoading(false);
        setShowVerify(false);
        toast.success(t("Withdraw successful!"));
        setVerify({
          email_code: "",
          google_code: "",
        });
      }
    } catch (err) {
      setLoading(false);
      toast.error(t(err.errors || "Withdraw failed!"));
    }
  };

  const setMaxAmount = () => {
    setAmount(userBalance[tokenName]);
  };

  const handleChangeTab = (tab: string) => {
    setTab(tab);
    setErrorAmount(false);
    setAmount(null);
    setValue("to", null);
    setProfileDeposit(null);
  };

  const onBlurTo = (e: any) => {
    getUserDeposit(e.target.value, tab);
  };

  const feeTransfer =
    stock && stock.fee_transfer
      ? stock.fee_transfer
      : { stock: tokenName, percent: 0 };
  // const amount = watch("amount");
  const amountFee = formatNumber(((+amount || 0) * feeTransfer.percent) / 100);

  const formProps = {
    userBalance,
    control,
    errors,
    tokenName,
    fee: {
      amount: amountFee,
      stock: feeTransfer.stock,
    },
    setMaxAmount,
  };

  console.log({
    amount,
    profileDeposit,
  });

  return (
    <Card isOpacity={true}>
      <div className="detais-rigth-view">
        <div className="title-action-wallets">
          <p>
            {t("Transfer Stock")} ({String(tokenName).toUpperCase()})
          </p>
        </div>
        <div className="tabs">
          <div
            className={`tab ${tab === "wallet" ? "active" : ""}`}
            onClick={() => handleChangeTab("wallet")}
          >
            {t("Wallet")}
          </div>
          <div
            className={`tab ${tab === "email" ? "active" : ""}`}
            onClick={() => handleChangeTab("email")}
          >
            {t("Email")}
          </div>
          <div
            className={`tab ${tab === "phone" ? "active" : ""}`}
            onClick={() => handleChangeTab("phone")}
          >
            {t("Phone Number")}
          </div>
          <div
            className={`tab ${tab === "id" ? "active" : ""}`}
            onClick={() => handleChangeTab("id")}
          >
            ID
          </div>
        </div>

        {profileDeposit && (
          <div className="mb-0 px-3">
            <Link
              onClick={() => history.push(getUrlProfile(profileDeposit))}
              className="d-flex align-items-center iq-sub-card border-0"
            >
              <Avatar
                className="m-0"
                size={"30px"}
                fontSize={"14px"}
                rezise={50}
                userData={profileDeposit}
              />
            </Link>
          </div>
        )}
        <form onSubmit={handleSubmit(handleSubmitWithdraw)}>
          {tab === "wallet" &&
            (showVerify ? (
              <Verify verify={verify} setVerify={setVerify} />
            ) : (
              <WalletWithdraw
                {...formProps}
                amount={amount}
                errorAmount={errorAmount}
                onBlurTo={onBlurTo}
                handleChange={handleChange}
              />
            ))}

          {tab === "email" &&
            (showVerify ? (
              <Verify verify={verify} setVerify={setVerify} />
            ) : (
              <EmailWithdraw
                {...formProps}
                amount={amount}
                errorAmount={errorAmount}
                handleChange={handleChange}
              />
            ))}

          {tab === "phone" &&
            (showVerify ? (
              <Verify verify={verify} setVerify={setVerify} />
            ) : (
              <PhoneWithdraw
                {...formProps}
                amount={amount}
                errorAmount={errorAmount}
                handleChange={handleChange}
              />
            ))}

          {tab === "id" &&
            (showVerify ? (
              <Verify verify={verify} setVerify={setVerify} />
            ) : (
              <IDWithdraw
                {...formProps}
                amount={amount}
                errorAmount={errorAmount}
                handleChange={handleChange}
              />
            ))}

          <div className="submit-step mt-4">
            <Button type="submit" height="40px" loading={loading}>
              {t("Submit")}
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default View;

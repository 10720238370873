/* eslint-disable indent */
import Card from "components/v-teamplate/Card";
import { formatCurrency, getUrlImage } from "utils/common";
import { useHistory } from "react-router-dom";
import useTranslation from "hooks/useTranslation";

const BadgesDetail = ({ data, show_descrition = false }) => {
  const history = useHistory();
  const { t } = useTranslation();

  const onClickDetail = (id) => {
    history.push(`?detail=${id}`);
  };

  return (
    <Card className="cursor-pointer">
      <Card.Body>
        <div
          className="iq-badges text-center"
          onClick={() => onClickDetail(data?._id)}
        >
          <div className="badges-icon">
            <img
              className="avatar-80 rounded"
              src={getUrlImage((data?.image || data?.object?.icon), 100)}
              alt=""
            />
          </div>
          <h5 className="mb-2">{t(data?.name)}</h5>
          {/* <p>Richard McClintock, a Latin professor consectetur.</p> */}
          <span className="text-uppercase">
            {!data?.price || data?.price === 0
              ? t("Free")
              : `${t("You pay fees")}: ${formatCurrency(
                  data?.price
                )} / 1 ${"gift times"}`}
          </span>
        </div>
        {show_descrition && data?.description && (
          <div
            className="mb-0 "
            dangerouslySetInnerHTML={{
              __html: data?.description,
            }}
          ></div>
        )}
      </Card.Body>
    </Card>
  );
};

export default BadgesDetail;

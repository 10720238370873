/* eslint-disable */

type IProps = {
  path: string;
  className?: string;
  pluginCountry?: boolean;
};

export default function VDBImage({
  path,
  className,
}: IProps) {

  return (
    <img className={className || ""} src={path} alt="image" />
  );
}

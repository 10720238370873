/* eslint-disable max-len */
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import VInput from "components/v-input";
import VLoading from "components/v-loading";
import Card from "components/v-teamplate/Card";
import { useDebounce } from "hooks/useDebounce";
import { useState, useCallback, useEffect } from "react";
import { Form } from "react-bootstrap";
// import getMyLocation from "src/components/google-maps/gps-request";
// import Loading from "src/components/core/loading";
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";
const ALOW_COUNTRY_LIST = ["vn", "th", "la", "cn", "us", "gb"];

type IGoogleMapProps = {
  className?: string;
  googleMapKey: string;
  lat?: number;
  lng?: number;
  setPlace?: (location: any) => void;
  onSelectRegionMaps?: (location: any) => void;
  onSelectedLoactionPostMaps?: (location: any) => void;
};

interface IPlaceOption {
  label: string;
  text: string;
  value: string;
  placeDetail: google.maps.places.PlaceResult;
}

const View = (props: IGoogleMapProps) => {
  const {
    className,
    lat,
    lng,
    onSelectRegionMaps = () => {},
    onSelectedLoactionPostMaps = () => {},
  } = props;
  const [map, setMap] = useState<any>(null);
  const [clickPossition, setClickPosition] = useState<any>({});
  const [loading, setLoading] = useState<boolean>(false);

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    // isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: props.googleMapKey,
  });

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: props.googleMapKey,
    libraries: ["places"],
  });

  const onLoad = useCallback(async function callback(map: any) {
    try {
      // This is just an example of getting and using the map instance!!! don't just blindly copy!
      setLoading(true);
      setMap(map);
      map.setZoom(18);
      let myLocation;
      if (lat && lng) {
        myLocation = {
          lat: lat,
          lng: lng,
        };
      }
      if (localStorage.getItem("last_shared_coordinates")) {
        const lastUserCoordinates = localStorage
          .getItem("last_shared_coordinates")
          ?.split(",");
        myLocation = {
          lat: Number(lastUserCoordinates?.[0]),
          lng: Number(lastUserCoordinates?.[1]),
        };
      } else {
        // const my = await getMyLocation();
        // myLocation = my;
      }
      map.setCenter(myLocation);
      setClickPosition(myLocation);
      onSelectRegionMaps(myLocation);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [locationInputValue, setLocationInputValue] = useState("");
  const [placeOptions, setPlaceOptions] = useState<IPlaceOption[]>([]);

  const debouncedValue = useDebounce(locationInputValue, 1000);

  useEffect(() => {
    getPlacePredictions({
      input: debouncedValue,
      componentRestrictions: { country: ALOW_COUNTRY_LIST },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  useEffect(() => {
    // fetch place details for the first element in placePredictions array
    if (placePredictions?.length) {
      let options: IPlaceOption[] = [];
      placePredictions.forEach((placePrediction, index) => {
        placesService?.getDetails(
          {
            placeId: placePrediction.place_id,
          },
          (placeDetails: google.maps.places.PlaceResult | null) => {
            if (placeDetails) {
              options = [
                ...options,
                {
                  text: String(placeDetails.formatted_address),
                  label: String(placeDetails.name),
                  value: String(placeDetails.place_id),
                  placeDetail: placeDetails,
                },
              ];
            }
            if (index === placePredictions.length - 1) {
              setPlaceOptions(options);
            }
          }
        );
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placePredictions]);
  
  return (
    <div className="container-maps-custom">
      <Form.Group className="form-group col-md-12 my-2">
        <VInput
          label={"Search"}
          type="text"
          name="search"
          value={locationInputValue}
          onChange={(event) => setLocationInputValue(event.target.value)}
        />
      </Form.Group>
      {placeOptions?.length > 0 && locationInputValue && (
        <Card className="modal-search-location">
          {placeOptions.map((placeOption: any, index) => (
            <div
              key={`place_${index}`}
              className="d-flex p-2 cursor-pointer rounded-md"
              onClick={() => {
                const location = {
                  lat: placeOption.placeDetail?.geometry?.location?.lat(),
                  lng: placeOption.placeDetail?.geometry?.location?.lng(),
                };
                const c = new window.google.maps.LatLng(location);
                map.setZoom(18);
                map.setCenter(c);
                setClickPosition(location);
                onSelectRegionMaps(location);
                setPlaceOptions([])
              }}
            >
              <div
                className={`d-flex justify-content-center align-items-center p-2`}
              >
                {/* <Icon
                name="react_icons_SlLocationPin"
                reactIconsProps={{
                  size: "20",
                  color: "#4284F3",
                }}
              /> */}
              </div>
              <div className="d-flex flex-column justify-content-between">
                <h4 className="font-bold">{placeOption.label}</h4>
                <span className="text-[#838383]">{placeOption.text}</span>
              </div>
            </div>
          ))}
        </Card>
      )}
      <div className="map-geocode-details">
        {isLoaded && (
          <div className="h-100">
            {loading && <VLoading />}
            <GoogleMap
              zoom={16}
              onLoad={onLoad}
              onClick={(e: any) => {
                const location = {
                  lat: e.latLng.lat(),
                  lng: e.latLng.lng(),
                };
                setClickPosition(location);
                const c = new window.google.maps.LatLng(location);
                map.setZoom(18);
                map.setCenter(c);
                onSelectRegionMaps(location);
                onSelectedLoactionPostMaps(location);
              }}
              mapContainerClassName={className}
            >
              <>
                {clickPossition?.lat && (
                  <Marker
                    position={new window.google.maps.LatLng(clickPossition)}
                  />
                )}
              </>
            </GoogleMap>
          </div>
        )}
      </div>
    </div>
  );
};

export default View;

/* eslint-disable indent */
import VButton from "components/v-button";
import VInput from "components/v-input";
import useTranslation from "hooks/useTranslation";
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";
import VDropdown from "components/v-dropdown";
import { weekdays } from "config/constant";
import { Label } from "reactstrap";
import Switch from "react-switch";

const WorkTimeSetting = ({ state, setState }) => {
  const { t } = useTranslation();
  const handleRemoveWorktime = (id: string) => {
    setState((prev) => ({
      ...prev,
      work_time: {
        ...prev.work_time,
        value: (prev?.work_time?.value || []).filter((el) => el?.id !== id),
      },
    }));
  };

  const handleChangeWorkTime = (value, id, name) => {
    setState((prev) => ({
      ...prev,
      work_time: {
        ...prev.work_time,
        value: prev?.work_time?.value?.map((el) =>
          el?.id === id
            ? {
                ...el,
                [name]: value,
              }
            : el
        ),
      },
    }));
  };

  const handleChangeStautsWorkTime = (value) => {
    setState((prev) => ({
      ...prev,
      work_time: {
        ...(prev?.work_time || {}),
        status: value,
      },
    }));
  };

  const handleChangeWorkTimeBreaks = (value, id, name) => {
    setState((prev) => ({
      ...prev,
      work_time: {
        ...prev.work_time,
        value: prev?.work_time?.value?.map((el) =>
          el?.id === id
            ? {
                ...el,
                break_time: {
                  ...el?.break_time,
                  [name]: value,
                },
              }
            : el
        ),
      },
    }));
  };

  const handleAddWorktime = () => {
    setState((prev) => ({
      ...prev,
      work_time: {
        ...prev?.work_time,
        value: (prev?.work_time?.value || []).concat({
          id: uuidv4(),
          day_name: null,
          from: "",
          to: "",
          break_time: {
            from: "",
            to: "",
          },
          name: "",
        }),
      },
    }));
  };

  return (
    <div>
      <div className="d-flex align-items-center">
        <Form.Label className="me-4">{t("Work time")}</Form.Label>
        <Form.Label>
          <div className="d-flex me-4 align-items-center">
            <Label className="mb-0 me-2">{t("Status")}:</Label>
            <Switch
              width={44}
              height={20}
              onChange={(e) =>
                handleChangeStautsWorkTime(e ? "enable" : "disable")
              }
              checked={state?.work_time?.status === "enable"}
            />
          </div>
        </Form.Label>
      </div>

      <div>
        {state?.work_time?.value &&
          Array.isArray(state?.work_time?.value) &&
          state?.work_time.value.length > 0 &&
          state?.work_time.value.map((el) => (
            <div key={el.id} className="mt-2">
              <div className="d-flex justify-content-end align-items-center mb-1">
                <div className="d-flex me-4 align-items-center">
                  <Label className="mb-0 me-2">{t("Status")}:</Label>
                  <Switch
                    onChange={(e) =>
                      handleChangeWorkTime(
                        e ? "enable" : "disable",
                        el.id,
                        "status"
                      )
                    }
                    checked={el.status === "enable"}
                  />
                </div>
                <VButton
                  className="btn btn-primary py-0"
                  onClick={() => handleRemoveWorktime(el?.id)}
                >
                  {t("Remove")}
                </VButton>
              </div>
              <Form.Group className={`form-group col-12`}>
                <VInput
                  label={t("Name of working hours")}
                  id={"name"}
                  name={"name"}
                  value={el?.name}
                  onChange={(e) =>
                    handleChangeWorkTime(e.target.value, el.id, "name")
                  }
                />
              </Form.Group>
              <div className="row">
                <Form.Group className={`form-group col-md-6`}>
                  <VDropdown
                    key={"from_day"}
                    placeholder={t("From")}
                    options={weekdays?.map((el: any) => {
                      return {
                        label: t(el.name),
                        value: el.value,
                      };
                    })}
                    value={el?.from_day}
                    onChange={(e) =>
                      handleChangeWorkTime(e.target.value, el.id, "from_day")
                    }
                  />
                </Form.Group>
                <Form.Group className={`form-group col-md-6`}>
                  <VDropdown
                    key={"to_day"}
                    placeholder={t("To")}
                    options={weekdays?.map((el: any) => {
                      return {
                        label: t(el.name),
                        value: el.value,
                      };
                    })}
                    value={el?.to_day}
                    onChange={(e) =>
                      handleChangeWorkTime(e.target.value, el.id, "to_day")
                    }
                  />
                </Form.Group>
              </div>
              <div className="row">
                <Form.Group className={`form-group col-md-6`}>
                  <VInput
                    type={"time"}
                    label={t("From")}
                    id={"from"}
                    name={"from"}
                    value={el?.from}
                    onChange={(e) =>
                      handleChangeWorkTime(e.target.value, el.id, "from")
                    }
                  />
                </Form.Group>
                <Form.Group className={`form-group col-md-6`}>
                  <VInput
                    type={"time"}
                    label={t("To")}
                    id={"to"}
                    name={"to"}
                    value={el?.to}
                    onChange={(e) =>
                      handleChangeWorkTime(e.target.value, el.id, "to")
                    }
                  />
                </Form.Group>
              </div>
              <div>
                <Label>{t("Break time")}</Label>
                <div className="row">
                  <Form.Group className={`form-group col-md-6`}>
                    <VInput
                      type={"time"}
                      label={t("From")}
                      id={"break_time_from"}
                      name={"break_time_from"}
                      value={el?.break_time?.from}
                      onChange={(e) =>
                        handleChangeWorkTimeBreaks(
                          e.target.value,
                          el.id,
                          "from"
                        )
                      }
                    />
                  </Form.Group>
                  <Form.Group className={`form-group col-md-6`}>
                    <VInput
                      type={"time"}
                      label={t("To")}
                      id={"break_time_to"}
                      name={"break_time_to"}
                      value={el?.break_time?.to}
                      onChange={(e) =>
                        handleChangeWorkTimeBreaks(e.target.value, el.id, "to")
                      }
                    />
                  </Form.Group>
                </div>
              </div>
            </div>
          ))}
        <div className="mb-3">
          <VButton className="btn btn-primary py-0" onClick={handleAddWorktime}>
            {t("Add work time")}
          </VButton>
        </div>
      </div>
    </div>
  );
};

export default WorkTimeSetting;

import styled, { css } from "styled-components";

import { Input, Label as BLabel } from "reactstrap";
import CurrencyInput from 'react-currency-input-field';

export const InputWrapper = styled.div`
  position: relative;
`;

const CustomInput = styled(Input)<{ transparent: boolean }>`
  background: ${(prop) => (prop.transparent ? "transparent" : "white")};
  border-radius: 8px;
  border: 1px solid gray !important;
  color: ${(prop) => (prop.transparent ? "white" : "black")};
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px;
  height: ${(props) => props.height || "46px"};
  
  &:focus,
  &:hover {
    background: ${(prop: any) => prop.transparent ? "transparent" : "white"};
    color: ${(prop: any) => (prop.transparent ? "white" : "black")};

    ${(props: any) =>
    !props.invalid &&
      css`
        outline: none !important;
        box-shadow: none !important;
      `}
  }

  &:disabled {
    background-color: ${(prop) => (prop.transparent ? "#6868687c" : "white")};
  }

  &::placeholder {
    color: ${(prop) => (prop.transparent ? "#ffffffc0" : "#00000081")};
  }

  margin-bottom: 15px;
`;

export const CustomInputCurrency = styled(CurrencyInput)<{ transparent: boolean }>`
  background: ${(prop) => (prop.transparent ? "transparent" : "white")};
  border-radius: 8px;
  border: 1px solid gray;
  color: ${(prop) => (prop.transparent ? "white" : "black")};
  outline: none !important;
  box-shadow: none !important;
  font-size: 14px;
  height: ${(props) => props.height || "46px"};
  padding: 0 10px;
  
  &:focus,
  &:hover {
    background: ${(prop: any) =>
    prop.transparent ? "transparent" : "white"};
        color: ${(prop: any) => (prop.transparent ? "white" : "black")};

    ${(props: any) =>
    !props.invalid &&
      css`
        outline: none !important;
        box-shadow: none !important;
      `}
  }

  &:disabled {
    background-color: ${(prop) => (prop.transparent ? "#6868687c" : "white")};
  }

  &::placeholder {
    color: ${(prop) => (prop.transparent ? "#ffffffc0" : "#00000081")};
  }

  margin-bottom: 15px;
`;

export const Label = styled(BLabel)`
  color: ${(props) => (props.hasError ? props.theme.colors.red100 : "white")};
  font-size: 14px;
`;

export const SuffixIcon = styled.div<{ hasError, color?, height?: any }>`
  position: absolute;
  right: 10px;
  bottom: ${(props) => props.hasError ? "36px" : 0};
  height: ${(props) => props.height || "46px"};
  background-color: transparent;
  color: ${props => props.color || 'white'};
  border: none;
  outline: none;
  display: flex;
  align-items: center;

  button {
    margin-right: -10px;
    padding: 0 20px !important;
  }
`;

export default CustomInput;

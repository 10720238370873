/* eslint-disable indent */
import styled from "styled-components";

import { Table } from "reactstrap";

const CustomTable = styled(Table)<{ props: any }>`
  width: ${(props) => (props.props.width ? props.props.width : "100%")};
  /* background: ${(props) => props.theme.colors[props.props.bgTable]}; */
  outline: none !important;
  /* color: white; */
  box-shadow: none !important;
  font-size: 14px;
  margin-bottom: 0px;

  thead {
    color: #1f1f1f;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
    /* background-color: ${(props) =>
      props.theme.colors[props.props.bgHeader] ||
      props.theme.colors.grey200}; */
  }

  td,
  th {
    padding: 15px;
    vertical-align: middle !important;
    border-right: 0.5px solid #4284f3;
    border-bottom: 0.5px solid #4284f3;
    text-align: center;
    :first-child {
      border-left: none;
    }
    :last-child {
      border-right: none;
    }
  }
  tr {
    &:hover {
      cursor: pointer;
      /* background-color: ${(props) => props.theme.colors.grey200}; */
    }
  }
`;

export const TdTable = styled.td`
  color: ${(props) => props.theme.colors[props.color]};

  img {
    width: auto;
    height: 100%;
    max-height: 40px;
  }
`;

export default CustomTable;

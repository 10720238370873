import Table, { TdTable } from "./styles";

type IProps = {
  columns: any;
  dataSource?: any;
  onChange?: any;
  bgTable?: any;
  bgHeader?: any;
  dataEdit?: any;
  isEdit?: boolean;
  onBlurText?: () => void
};

const View = ({ columns, dataSource, onChange, dataEdit, isEdit, onBlurText, ...props }: IProps) => {
  const handleClickRow = (value: any) => {
    if (onChange) {
      onChange(value)

    }
  }
  return (
    <Table props={props}>
      <thead>
        <tr>
          {columns?.map((cl: any, index: number) => (
            <th
              data-key={`${cl.key}_${index}`}
              contentEditable={isEdit}
              onBlur={onBlurText}
              key={cl.key}>
              {dataEdit?.[`${cl.key}_${index}`] || cl.title}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {dataSource &&
          dataSource.length > 0 &&
          dataSource?.map((item: any, indexx: number) => (
            <tr
              key={indexx}
              {...(onChange ? { onClick: (() => handleClickRow(item)) } : {})}
            >
              {columns?.map((cl: any, index: number) => (
                <TdTable key={index}>
                  {cl.render ? cl.render(item) : cl.index ? indexx + 1 : item[cl.dataIndex] || "N/A"}
                </TdTable>
              ))}
            </tr>
          ))}
      </tbody>
    </Table >
  );
};

export default View;

import { IObject } from "types";

export const sortStringObject = (a: IObject, b: IObject, field) => {
  const fa = a[field].toLowerCase();
  const fb = b[field].toLowerCase();
  if (fa < fb) {
    return -1;
  }
  if (fa > fb) {
    return 1;
  }
  return 0;
};

/* eslint-disable indent */

import { Switch, Route } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";

import SidebarSearch from "./sidebar";
import ComingSoon from "pages/coming-soon/comingsoon";
import { ROUTES } from "config/routes";
import Everybody from "./components/Everybody";
import Business from "./components/Business";
import CEO from "./components/Ceo";
import Group from "./components/Group";
import Page from "./components/Page";
import Hashtag from "./components/Hashtag";
import Trending from "./components/Trending";
import Posts from "./components/Posts";
import Videos from "./components/Videos";
import Organization from "./components/Organization";
import FeedByPlugins from "./search-feeds";

const View = () => {
  return (
    <Container className="search-wrapper">
      <Row className="justify-content-center">
        <Col lg={4} className={"px-1"}>
          <SidebarSearch />
        </Col>
        <Col lg={8} className={"px-1"}>
          {/* <Col lg={12}>
            <Card>
              <Col sm={12}>
                <VInput
                  transparent={true}
                  label={t("Search here")}
                  suffix={<VIcon width={16} name="search.svg" />}
                  onChange={(e) => handleChange(e.target.value)}
                />
              </Col>
            </Card>
          </Col> */}
          <Switch>
            <Route path={ROUTES.SEARCH_EVERYBODY} component={Everybody} />
            <Route path={ROUTES.SEARCH_POST} component={Posts} />
            <Route path={ROUTES.SEARCH_BUSINESS} component={Business} />
            <Route path={ROUTES.SEARCH_ORGANIZATION} component={Organization} />
            <Route path={ROUTES.SEARCH_CEO} component={CEO} />
            <Route path={ROUTES.SEARCH_GROUP} component={Group} />
            <Route path={ROUTES.SEARCH_PAGE} component={Page} />
            <Route path={ROUTES.SEARCH_HASHTAG} component={Hashtag} />
            <Route path={ROUTES.SEARCH_TREND} component={Trending} />
            <Route path={ROUTES.SEARCH_VIDEO} component={Videos} />
            <FeedByPlugins path="/" component={FeedByPlugins} />
            <Route component={ComingSoon} />
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

export default View;

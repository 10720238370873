/* eslint-disable indent */
import { useEffect, useState } from "react";
import VButton from "components/v-button";

import Table from "components/v-table";

import { renderDateTime } from "utils/times";
// import { useAppSelector, useAppDispatch } from "redux/reducers/hook";
// import { getUserProfile } from "redux/reducers/users/users.selector";
// import { setProfile } from "redux/reducers/users/users.reducer";
import useTranslation from "hooks/useTranslation";
// import { getUrlProfile } from "utils/common";
import InvoicesService from "api/invoices";

const View = () => {
  const { t } = useTranslation();
  const [listInvoice, setListInvoice] = useState([]);
  // const history = useHistory();
  // const userData: any = useAppSelector(getUserProfile);
  // const dispatch = useAppDispatch();

  const columns = [
    {
      key: "type",
      title: t("Type"),
      dataIndex: "type",
      render: (item) => t(item.type),
    },
    {
      key: "status",
      title: t("Status"),
      render: (item: any) => (
        <span
          className={`${
            item.status === "complete"
              ? "success"
              : item.status === "pending"
              ? "pending"
              : "declined"
          }`}
        >
          {item.status}
        </span>
      ),
    },
    {
      key: "createdAt",
      title: t("Created At"),
      render: (item) => <span>{renderDateTime(item.createdAt)}</span>
    },
    {
      key: "action",
      title: t("Action"),
      render: (item) => (
        <div>
          <VButton
            className={"btn btn-primary"}
            disabled={true}
            // onClick={() => handleChooseKeyBuy(item)}
          >
            {t("View")}
          </VButton>
        </div>
      ),
    },
  ];

  const getInvoice = async (page) => {
    try {
      const res: any = await InvoicesService.getInvoicesByUser({
        query: {
          limit: 10,
          page,
          type: "digital_affiliatebank"
          },
      });
      if (res) {
        setListInvoice(res.content);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getInvoice(1);
  }, []);

  return (
    <>
      {listInvoice && listInvoice.length > 0 && (
        <div className="mt-4 overflow-auto">
          <Table columns={columns} dataSource={listInvoice}></Table>
        </div>
      )}
    </>
  );
};

export default View;

import qs from "querystring";

export const getRoutePath = (
  path: string,
  options: { params?: any; query?: any }
) => {
  const { params = {}, query = {} } = options;
  let fullPath = path;

  const paramsKeys = Object.keys(params);

  if (paramsKeys.length > 0) {
    paramsKeys.forEach((key: string) => {
      fullPath = String(fullPath).replace(`:${key}`, String(params[key]));
    });
  }

  const queryKeys = Object.keys(query);

  if (queryKeys.length > 0) {
    fullPath = fullPath + "?" + qs.stringify(query);
  }

  return fullPath;
};

export const ROUTES = {
  // auth
  STORY_BOOK: "/story-books",
  LOGIN: "/auth/sign-in",
  LOGIN_CALLBACK: "/auth/sign-in/:callback_type",
  REGISTER: "/auth/register",
  LOGIN_QR: "/auth/login-qr",

  // social
  WELCOME: "/welcome",
  HOME: "/",
  WEBVIEW: "/wv/:key",
  USER_PRIVACY_SETTING: "/user-privacy-setting",
  FRIENDS: "/friends",
  FRIEND_PROFILE: "/friend-profile",
  FRIEND_FOLLOW: "/friend-follow",
  FRIEND_REQUEST: "/friend-request",
  SUGGESTS: "/suggests",
  PROFILE: "/profile/:id",
  PROFILE_PREVIEW: "/preview/:id",
  PROFILE_IMAGES: "/profile-images",
  PROFILE_VIDEOS: "/profile-videos/:id",
  PROFILE_EVENTS: "/profile-events",
  PROFILE_EVENTS_DETAIL: "/profile-events/detail",
  PROFILE_BADGES: "/profile-badges",
  PROFILE_MASCOTS: "/profile-mascots",
  PROFILE_QUOTATIONS: "/quotations",
  PROFILE_FORUM: "/profile-forum",
  PROFILE_BUSINESS: "/business/:id",
  PROFILE_GROUP: "/group/:id",
  PROFILE_PAGE: "/page/:id",
  PROFILE_CHANNEL: "/channel/:id",
  PROFILE_RANKING: "/ranking/:id",
  SEARCH: "/search",
  RANKING: "/rankings",
  SCAN: "/scan",
  PROFILE_FAMILY: "/family/:id",
  CONVERSATION: "/conversations/:id",
  CONVERSATION_INVITE: "/conversations/:id/invite",
  CONVERSATION_PROFILE_CHAT: "/conversations/:id/profile-chat",
  CONVERSATIONS: "/conversations",
  PROFILE_CHAT: "/profile-chat/:id/settings",

  BUSINESS: "/businesses",
  GROUP: "/groups",
  PAGES: "/pages",
  NOTIFICATION: "/notifications",
  FILES: "/files",
  CALENDAR: "/calendar",
  CHAT: "/chat",
  TODO: "/todo",
  BIRTHDAY: "/birthday",
  WEATHER: "/weather",
  MUSIC: "/music",
  MARKET_PLACE: "/market-place",
  STORE: "/store",
  STORE_GRID: "/store-grid",
  STORE_LIST: "/store-list",
  STORE_DETAIL: "/store-detail",
  STORE_CHECKOUT: "/store-checkout",
  UTILITIES: "/utilities",
  TEAM: "/team",
  COMINGSOON: "/coming-soon",
  MAINTENACE: "/maintenance",
  BLOG: "/blog",
  FAQ: "/faqs",
  PRICING: "/pricing",
  PRIVACY: "/privacy-policy",
  TERMS: "/terms-of-service",
  FEED_VIEW: "/feed-view",
  FAMILY_TREE: "/family-tree/:id",
  FINANCIAL_NEED: "/financial-need",
  CHANNEL: "/channel",
  MY_CHANNEL: "/my-channel",

  // my
  REGISTER_CALLBACK: "/auth/register/:callback_type",
  VERIFY_EMAIL: "/auth/verify-email",
  VERIFY_LOGIN: "/auth/verify-login",
  FORGOT_PASSWORD: "/auth/forgot-password",
  RESET_PASSWORD: "/auth/reset-password",
  FAST_REGISTER: "/auth/fast-register",
  MY_PROFILE: "/my/profile",
  MY_WALLETS: "/my/wallets",
  MY_SWAP: "/my/swap",
  MY_BY_SELL: "/my/buy-and-sell",
  MY_REFERRALS: "/my/referrals",
  SHOP: "/shop",
  SHOP_USER_ID: "/shop/:userId",
  SHOP_LIST: "/shop-list",
  SHOP_LIST_USER_ID: "/shop-list/:userId",
  MY_IDO: "/my/ico",
  MY_IBO_ROUND: "/my/ico/:projectId",
  MY_IBO: "/my/ibo-project",
  MY_TASK_DETAIL: "/my/task/detail/:id",
  MY_TASK: "/my/task",
  VZONE_BANK: "/my/vzone-bank",
  BORROW_LEND: "/my/borrow-lend",
  BLOCKCHAIN: "/my/blockchain-genealogy",
  PETAVERSE: "/my/petaverse",
  ADVERTISEMENT: "/my/advertisement",
  INVEST: "/my/invests",
  INVEST_HISTORY_DETAIL: "/my/invests/history/:id",
  REWARD: "/my/rewards",
  CHANNEL_VZX: "/my/channel",
  SALESTATISTICS: "/my/sales-statistics",
  QA: "/qa",
  CAMPAIGN: "/my/campaign",
  CAMPAIGNDETAIL: "/my/campaign/:campaignId",
  SUPPORTS: "/supports",
  DASHBOARD: "/my/dashboard",
  ADVERTISEMENTS: "/ads",
  EVENTS_V3: "/events-v3",
  // GROUP_CHAT: "/my/group_chat",

  //message
  MESSAGE: "/message",

  //profit
  PROFIT: "/my/ibo-project/:projectId/edit",
  ROUND: "/my/ibo-project/:projectId/round",

  // wallets routes
  DEPOSIT_VND: "/my/wallets/vndb/deposit",
  WITHDRAW_VND: "/my/wallets/vndb/withdraw",
  DEPOSIT_STOCK: "/my/wallets/:stock/deposit",
  WITHDRAW_STOCK: "/my/wallets/:stock/withdraw/",
  DEPOSIT_P2P: "/my/wallets/:stock/deposit/p2p",
  WITHDRAW_P2P: "/my/wallets/:stock/withdraw/p2p",
  DEPOSIT_SPOT: "/my/wallets/:stock/deposit/spot",
  WITHDRAW_SPOT: "/my/wallets/:stock/withdraw/spot",
  WITHDRAW_CV: "/my/wallets/:stock/withdraw/cv",
  WITHDRAW_QRMART: "/my/wallets/:stock/withdraw/qr-mart",
  HISTORY_STOCK: "/my/wallets/:stock/history",

  // invoice , certificate
  INVOICE_PAYMENT: "/invoice/transactions/:type/:id",
  INVOICE: "/invoice/ico/:project/:id",
  INVOICE_INVEST: "/invoice/investment/:package/:id",
  INVOICE_PRODUCT: "/invoice/order/:product/:id",
  INVOICE_CERTIFICATE: "/invoice/certificate/:id",
  CERTIFICATE_HOLD_STOCK: "/certificate/:projectId",

  //web view
  WEB_VIEW: "/web-view/project-share-profit/:projectId",
  WEB_VIEW_ROUND: "/web-view/project-round/:projectId",

  //landingpage project
  LANGDINGPAGE_PROJECT: [
    "/personal/:symbol",
    "/business/:symbol",
    "/organization/:symbol",
    "/student/:symbol",
  ],
  LANGDINGPAGE_PROJECT_WHITEPAPER: [
    "/personal/:symbol/whitepaper",
    "/business/:symbol/whitepaper",
    "/organization/:symbol/whitepaper",
  ],
  LANGDINGPAGE_PROJECT_TERMS: [
    "/personal/:symbol/terms",
    "/business/:symbol/terms",
    "/organization/:symbol/terms",
  ],

  //event
  DETAIL_EVENT: "/events/:id",
  EVENTS: "/events",
  CREATE_EVENT: "/events/create",
  MY_EVENT: "/my/events",

  //user settings
  SETTING: "/settings",
  SETTING_GENERAL: "/settings/general",
  ACCOUNT_SETTINGS: "/settings/account",
  PROFILE_SETTINGS: "/settings/profile",
  PASSWORD_SETTINGS: "/settings/password",
  VISIT_CARD_SETTINGS: "/settings/visit-card",
  AFFILIATE_SETTINGS: "/settings/affiliate",
  VERIFICATION_SETTINGS: "/settings/verification",
  SECURITY_SETTINGS: "/settings/security",
  SPECIAL_NAME: "/settings/special-name",
  INVOICE_SETTINGS: "/settings/invoice",
  AUTH_SESSIONS: "/settings/auth-sessions",
  DELETE_ACCOUNT_SETTINGS: "/settings/delete-account",
  DIGITAL_AFFILIATE_BANK: "/settings/digital-affiliate-bank",
  PRIVACY_SECURITY: "/settings/privacy_security",

  // search
  SEARCH_EVERYBODY: "/search/everybody",
  SEARCH_PAGE: "/search/page",
  SEARCH_CEO: "/search/ceo",
  SEARCH_BUSINESS: "/search/business",
  SEARCH_ORGANIZATION: "/search/organization",
  SEARCH_GROUP: "/search/group",
  SEARCH_POST: "/search/post",
  SEARCH_IMAGE: "/search/image",
  SEARCH_VIDEO: "/search/video",
  SEARCH_FILE: "/search/file",
  SEARCH_EVENT: "/search/event",
  SEARCH_LOCATION: "/search/location",
  SEARCH_TREND: "/search/trend",
  SEARCH_HASHTAG: "/search/hashtag",
  SEARCH_FIELD: "/search/field",

  //embed
  EMBED_POST: "/embed/posts/:id",

  //details post
  POST_DETAIL: "/posts/:id",

  //moderator
  CENSOR: "/my/censor",
  CENSOR_REGISTER: "/my/censor/register",
  CENSOR_RIGHT_AND_OBLIGATION: "/my/censor/right_and_obligation",

  // VIDEO_YTB, FB
  VIDEO_LINK: "/videos",
  NEW_EVENTS: "/new-events",
  SURVEYS: "/surveys",

  NEW_FILES: "/new-files",
  AUDIOS: "/audios",
  PRODUCTS: "/products",

  // V_MEDIA
  V_MEDIA: "/v-media",

  // sos map
  SOS_MAP: "/smart-map",

  // ********* user ********* //
  sos: {
    home: "/",
    map: "/maps",
    postsCategory: "/categories/:categoryId",
    postDetail: "/posts/:id",
    censor: {
      root: "/censor",
      register: "/censor/register",
      listRequest: "/censor/list-request",
      postsApprove: "/censor/approve-posts",
      postsVolunteer: "/censor/posts-volunteer",
    },
    profile: {
      root: "/profile/:id",
      historyPosts: {
        root: "/profile/:id/histories",
        byCategory: "/profile/:id/category/:categoryId",
      },
      edit: "/profile/:id/edit",
      info: "/profile/:id/info",
      wallpaper: "/profile/:id/wallpaper",
      ctGroupsPosts: "/profile/:id/ct-groups-posts/:ctGroupId",
      plugin: "/profile/:id/plugin",
    },
    historyPostsHelpers: "/my-helpers",
  },
  LOCATIONS: "/locations",
  IMAGES: "/images",
  BANNER: "/banners",
  QUESTION_FORM: "/question-form",
  PAY: "/pay/:id",
  REELS: "/moments",
  QUESTION: "/question",

  // chat
  CHAT_DEMO: "/chat-demo",
  CHAT_JOIN_ROOM: "/join/:id",
  REGISTER_RECEIVE_INFO: "/my/register-receive-info",

  // adv
  ADV: {
    BOOKING_MANAGE: "/adv/booking-manage",
    SCREEN_MANAGE: "/adv/screen-manage",
  },
};

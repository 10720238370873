import ProfileIntroduce from "./introduce";

type IProps = {
  isMyProfile: boolean;
  dataUser: any;
};

const View = (props: IProps) => {
  const { isMyProfile, dataUser } = props;

  return (
    <div className="profile-left-sidebar">
      <ProfileIntroduce isMyProfile={isMyProfile} dataUser={dataUser} />
      {/* {(!dataUser || dataUser.object_type !== OBJECT_TYPE.PAGE) && (
        <Friends isAuth={isAuth} dataUser={dataUser} />
      )} */}
    </div>
  );
};

export default View;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from "reactstrap";
import Button from "components/v-button";
import Input from "components/v-input";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { useEffect, useState } from "react";
import {
  disable2faAsync,
  enable2faAsync,
  securityAsync,
} from "redux/reducers/securities/securities.action";
import { getSecurity } from "redux/reducers/securities/securities.selector";
import Switch from "react-switch";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { toast } from "react-toastify";
import {
  editUserAsync,
  getUserAsync,
} from "redux/reducers/users/users.actions";
// import { signMessage } from "utils/ethers";
import APIs from "api";
import Verify from "../verify";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";

const View = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const user = useAppSelector(getUserProfile);
  const { is_enable, secret_key, uri } = useAppSelector(getSecurity);
  const [twoFa, setTwoFa] = useState("");
  const [errMessage, setErrMessage] = useState({});
  const [loading, setLoading] = useState(false);
  const [loadingPin, setLoadingPin] = useState(false);
  const [emailSecure, setEmailSecure] = useState(false);
  // const [loginByAddress, setLoginByAddress] = useState(false);
  // const [publicAddress, setPublicAddress] = useState(null);
  const [pinExist, setPinExist] = useState(false);
  const [pinCode, setPinCode] = useState(false);
  const [isReset, setIsReset] = useState(false);

  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });
  const [openSecure, setOpenSecure] = useState(false);

  const handleClick = async () => {
    setLoading(true);
    if (!twoFa) setErrMessage({ otp: "2Fa incorrect!" });
    if (!is_enable) {
      const res: any = await dispatch(
        enable2faAsync({
          body: {
            otp: twoFa,
          },
        })
      );
      setErrMessage({});
      if (res.payload.errors) {
        setErrMessage({ twoFa: res.payload.errors });
      }
    } else {
      const res: any = await dispatch(
        disable2faAsync({
          body: {
            otp: twoFa,
          },
        })
      );
      setErrMessage({});
      if (res.payload.errors) {
        setErrMessage({ twoFa: res.payload.errors });
      }
    }
    setTwoFa("");
    setLoading(false);
  };

  // const createSignature = async () => {
  //   try {
  //     const res: any = await signMessage("Create Signature Login");
  //     setPublicAddress(res.address);
  //     await dispatch(
  //       editUserAsync({
  //         params: {
  //           id: user._id,
  //         },
  //         body: {
  //           public_address: res.address.toLowerCase(),
  //         },
  //       })
  //     );
  //   } catch (error) {
  //     toast.error(error.message);
  //   }
  // };

  const handleChangeEmailSecure = async (checked) => {
    setEmailSecure(checked);
    await dispatch(
      editUserAsync({
        params: {
          id: user._id,
        },
        body: {
          email_secure: checked,
        },
      })
    );
  };

  const handleChangePinSecure = async (checked) => {
    setOpenSecure(true);
  };

  // const handleLoginByAddress = async (checked) => {
  //   setLoginByAddress(checked);
  //   await dispatch(
  //     editUserAsync({
  //       params: {
  //         id: user._id,
  //       },
  //       body: {
  //         login_by_address: checked,
  //       },
  //     })
  //   );
  // };

  const handleSubmit = async () => {
    try {
      if (isReset || !pinExist) {
        setLoadingPin(true);
        if (Number.isInteger(+pinCode) && String(pinCode).length === 6) {
          if (!isReset) {
            await APIs.USER.createPinCode({
              body: {
                verify,
                code: pinCode,
              },
            });
          } else
            await APIs.USER.resetPinCode({
              body: {
                verify,
                code: pinCode,
              },
            });
        } else {
          setLoadingPin(false);
          return toast.error(t("Code is not in the correct format!"));
        }
      }
      !isReset &&
        (await APIs.USER.editUserSecure({
          body: {
            verify,
            pin_secure: !user.pin_secure,
          },
        }));
      await dispatch(getUserAsync());
      await getData();
      setIsReset(false);
      setOpenSecure(false);
      setLoadingPin(false);
    } catch (error) {
      setLoadingPin(false);
      toast.error(error.errors);
    }
  };

  useEffect(() => {
    dispatch(securityAsync());
  }, [dispatch]);

  useEffect(() => {
    if (user) {
      setEmailSecure(user.email_secure);
      // setLoginByAddress(user.login_by_address);
      // setPublicAddress(user.public_address);
    }
  }, [user]);

  const getData = async () => {
    try {
      const res = await APIs.USER.checkPinExist({});
      if (res) setPinExist(true);
      else setPinExist(false);
    } catch (error) {
      setPinExist(false);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <Card isOpacity={true}>
      <div className="setting-authenticator">
        {/* <div className="mb-2">
          <h3>Signature</h3>

          {!publicAddress ? (
            <Button onClick={createSignature} loading={false}>
              Create
            </Button>
          ) : (
            <>
              {" "}
              <Switch onChange={handleLoginByAddress} checked={loginByAddress} />
              <p>Address : {publicAddress}</p>
            </>
          )}
        </div> */}

        <div className="mb-2">
          <h3>{t("Email Secure")}</h3>
          <Switch
            width={44}
            height={20}
            onChange={handleChangeEmailSecure}
            checked={emailSecure}
          />
        </div>

        <div className="mb-2">
          <h3>{t("Set up pin code")}</h3>
          <Switch
            width={44}
            height={20}
            onChange={handleChangePinSecure}
            checked={user.pin_secure}
          />
          {openSecure && (
            <div className="pin-secure">
              <Verify verify={verify} setVerify={setVerify} />
              {(isReset || !pinExist) && (
                <div className="mt-2">
                  <Input
                    label={t("Pin code")}
                    transparent={true}
                    value={pinCode}
                    onChange={(e) => setPinCode(e.target.value)}
                    type="number"
                    placeHolder={"Please input pin code"}
                  />
                  <span className="details">{t("Enter the 6 digit code")}</span>
                </div>
              )}
              <div className="d-flex mt-2">
                <Button onClick={() => handleSubmit()} loading={loadingPin}>
                  {t("Submit")}
                </Button>
                &ensp;
                <Button
                  onClick={() => {
                    setOpenSecure(false);
                    setIsReset(false);
                  }}
                >
                  {t("Cancel")}
                </Button>
              </div>
            </div>
          )}
          <br />
          {!openSecure && user.pin_secure && (
            <a
              className="reset"
              onClick={() => {
                setOpenSecure(true);
                setIsReset(true);
              }}
            >
              {t("Reset pin code")}
            </a>
          )}
        </div>

        <div>
          <h3 className="mb-2">{t("Two-Factor Authentication")}</h3>
          <Row>
            {!is_enable && (
              <Col col lg={6} md={12}>
                <div className="authenticator">
                  <img
                    alt=""
                    src={`https://api.qrserver.com/v1/create-qr-code/?size=250*250&data=${uri}`}
                  />
                  <div className="ms-3">
                    <h5>{t("Google Authenticator")}</h5>
                    <p>
                      {t(
                        "Download and install Google Authenticator. Scan the QR code or copy the key."
                      )}
                    </p>
                  </div>
                </div>
                <div className="my-2">
                  <Input transparent value={secret_key} />
                </div>
              </Col>
            )}
            <Col col lg={6} md={12}>
              <span>{t("Two-Factor Authentication")}</span>
              <Input
                transparent={true}
                value={twoFa}
                onChange={(e) => setTwoFa(e.target.value)}
                errMessage={errMessage["twoFa"]}
                type="number"
              />
              <div className="mt-2 text-center">
                <Button onClick={handleClick} loading={loading}>
                  {t(!is_enable ? "Enable" : "Disable")}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <div className="bottom">
          <Row>
            <Col col md={6}>
              <div className="tutorial">
                <h4>{t("How to Anable Authenticator App")}</h4>
                <p>
                  {t(
                    "2-step verification uses your mobile phone device to provide an extra layer of security for your Hootsuite account. Powered by Google Authenticator."
                  )}
                </p>
                <a
                  href="https://support.google.com/accounts/answer/1066447?hl=en&co=GENIE.Platform%3DiOS&oco=1"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <img alt="" src="/assets/images/icons/authenticator.svg" />
                </a>
              </div>
            </Col>
            <Col col md={6}>
              <div className="step">
                <div className="item">
                  <span>1</span>
                  <p>
                    {t(
                      "Install the Google Authenticator App. For your mobile device."
                    )}
                    <span>{t("Learn more")} </span>
                  </p>
                </div>
                <div className="item">
                  <span>2</span>
                  <p>
                    {t(
                      "Scan the QR code or manually input your secret code into the Google Authenticator App."
                    )}
                  </p>
                </div>
                <div className="item">
                  <span>3</span>
                  <p>
                    {t(
                      "Input the 6-degit code that the Google Authenticator App provider."
                    )}
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </Card>
  );
};

export default View;

import { createAsyncThunk } from "@reduxjs/toolkit";
import BusinessService from "api/businesses";
import { IRequestData } from "api/request";

export const getBusinessSuggestsAsync = createAsyncThunk(
  "businesses/suggest",
  async (req: IRequestData) => {
    try {
      const response: any = await BusinessService.getSuggests(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBusinessJoinedAsync = createAsyncThunk(
  "businesses/joined",
  async (req: IRequestData) => {
    try {
      const response: any = await BusinessService.getJoined(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBusinessManageAsync = createAsyncThunk(
  "businesses/manage",
  async (req: IRequestData) => {
    try {
      const response: any = await BusinessService.getManage(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBusinessRequestAsync = createAsyncThunk(
  "businesses/request",
  async (req: IRequestData) => {
    try {
      const response: any = await BusinessService.getRequestJoined(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBusinessInvitedAsync = createAsyncThunk(
  "businesses/invited",
  async (req: IRequestData) => {
    try {
      const response: any = await BusinessService.getInvitedByUser(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import * as SettingSelector from "redux/reducers/setting-system/selectors";

import Video from "components/video";
import SliderCustom from "components/v-slider";
import { getUrlImage } from "utils/common";
import PageBackgroundService from "api/page-backgrounds";
import { getObjects } from "redux/reducers/settings/settings.selector";
import FsLightbox from "fslightbox-react";
import { isMobile } from "react-device-detect";

const View = (props) => {
  const {
    page,
    data,
    optionsFilter,
    width,
    height,
    position,
    zIndex,
    isAuth = true,
    object_type,
    resize_image,
    subBackground,
  } = props;
  const [background, setBackground] = useState<any>({});
  const [listBackgrounds, setListBackgrounds] = useState([]);
  const themeScheme = useSelector(SettingSelector.theme_scheme);
  const objects = useSelector(getObjects);
  const [listLightbox, setListLightbox] = useState({
    toggler: true,
    slide: 1,
    list: [],
  });

  const handlePreview = (data) => {
    if (data?.type === "slide") {
      setListLightbox({
        ...listLightbox,
        toggler: !listLightbox.toggler,
        list: data?.slide?.map((item) => item?.src),
      });
    }
    if (data?.type === "video" || data?.type === "link") {
      setListLightbox({
        ...listLightbox,
        toggler: !listLightbox.toggler,
        list: [data?.video || data?.url],
      });
    }
    if (data?.type === "image") {
      setListLightbox({
        ...listLightbox,
        toggler: !listLightbox.toggler,
        list: [data?.url],
      });
    }
  };
  useEffect(() => {
    if (!data) getData();
  }, [data]);

  useEffect(() => {
    if (data) {
      if (object_type) {
        const object = objects.find((o) => o.key === object_type);
        if (object) {
          if (
            (data.type === "slide" &&
              (!data.slide || data.slide.length === 0)) ||
            (data.type !== "slide" && !data.url)
          ) {
            return setBackground({
              type: "image",
              url: object.cover,
            });
          }
        }
      }
      setBackground(data);
    } else {
      const pageBackgrounds = !isAuth
        ? getItemByArea(optionsFilter, listBackgrounds)
        : listBackgrounds;
      const _pageBackground =
        pageBackgrounds.find((i) => i?.filter?.name !== "default") ||
        pageBackgrounds[0];
      if (_pageBackground) {
        if (themeScheme !== "dark") {
          if (_pageBackground.light.type === "video") {
            setBackground(_pageBackground.light);
          } else {
            let images = [];
            pageBackgrounds.forEach((element) => {
              images = [...images, ...element.light.slide];
            });
            setBackground({
              ..._pageBackground.light,
              slide: images,
            });
          }
        } else {
          if (_pageBackground.light.type === "video") {
            setBackground(_pageBackground.dark);
          } else {
            let images = [];
            pageBackgrounds.forEach((element) => {
              images = [...images, ...element.dark.slide];
            });
            setBackground({
              ..._pageBackground.dark,
              slide: images,
            });
          }
        }
      } else {
        setBackground("");
      }
    }
  }, [data, listBackgrounds, themeScheme, optionsFilter]);

  const getData = async () => {
    let res: any = [];
    if (!isAuth) {
      res = await PageBackgroundService.getPageBackgroundsNoAuth({
        params: {
          position: page,
        },
      });
    } else {
      res = await PageBackgroundService.getPageBackgrounds({
        params: {
          position: page,
        },
      });
    }
    if (res) {
      setListBackgrounds(res);
    }
  };

  const compareLocation = (a, b) => {
    if (a && b && a === b) {
      return true;
    }
    return false;
  };

  const getItemByArea = (options, listItems) => {
    const userLocation = options?.location || {};

    if (!userLocation.country)
      return listItems.filter((b) => b.filter && b.filter.name === "default");

    const ward = listItems.filter((b) =>
      compareLocation(
        userLocation.ward,
        b.filter.location ? b.filter.location.ward : ""
      )
    );
    if (ward && ward.length > 0) return ward;
    const district = listItems.filter((b) =>
      compareLocation(
        userLocation.district,
        b.filter?.location && !b.filter?.location.ward
          ? b.filter?.location.district
          : ""
      )
    );
    if (district && district.length > 0) return district;
    const province = listItems.filter((b) =>
      compareLocation(
        userLocation.province,
        b.filter?.location && !b.filter?.location.district
          ? b.filter?.location.province
          : ""
      )
    );
    if (province && province.length > 0) return province;
    const country = listItems.filter((b) =>
      compareLocation(
        userLocation.country,
        b.filter?.location && !b.filter?.location.province
          ? b.filter?.location.country
          : ""
      )
    );
    if (country && country.length > 0) return country;
    return [];
  };

  return (
    <>
      <FsLightbox
        toggler={listLightbox.toggler}
        sources={listLightbox?.list?.map((img) => getUrlImage(img))}
        slide={listLightbox.slide}
      />
      {background && (
        <div
          className="backgroud-page"
          style={{
            width: width || "100vw",
            height: height || "100vh",
            position: position || "fixed",
            zIndex: `${zIndex || -99}`,
          }}
          onClick={() => handlePreview(background)}
        >
          {(background?.type === "video" || background?.type === "link") && (
            <Video
              page={page}
              isEdit={false}
              link={getUrlImage(background?.video || background.url)}
            />
          )}
          {background?.type === "slide" && (
            <>
              {background?.slide?.length === 1 ? (
                <div
                  style={{
                    backgroundImage: `url(${getUrlImage(
                      background?.slide[0]?.[
                        isMobile && background?.slide[0]?.src_mobile
                          ? "src_mobile"
                          : "src"
                      ],
                      resize_image || 1920
                    )})`,
                    height: "100%",
                    backgroundSize: "cover",
                  }}
                ></div>
              ) : (
                <SliderCustom
                  data={background?.slide}
                  resize_image={resize_image}
                />
              )}
            </>
          )}
          {background?.type === "image" && (
            <>
              {
                <div
                  style={{
                    backgroundImage: `url(${getUrlImage(
                      subBackground || background?.url,
                      resize_image || 1920
                    )})`,
                    height: "100%",
                    backgroundSize: "cover",
                  }}
                ></div>
              }
            </>
          )}
        </div>
      )}
    </>
  );
};

export default View;

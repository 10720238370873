import { createContext, useEffect, useState } from "react";
import APIs from "api";
import SettingService from "api/settings";

type IFuncState = (value: any) => void;
const dfFunc = (value: any) => {};

export const MapContext = createContext<{
  ages: any[];
  careers: any[];
  nations: any[];
  religions: any[];
  objects: any[];
  locations: any[];
  objectFiles: any;
  categoryPosts: any[];
  setAges: IFuncState;
  setCareers: IFuncState;
  setNations: IFuncState;
  setReligions: IFuncState;
  setObjects: IFuncState;
  setLocations: IFuncState;
  setObjectFiles: IFuncState;
  setCategoryPosts: IFuncState;
}>({
  ages: [],
  careers: [],
  nations: [],
  religions: [],
  objects: [],
  locations: [],
  objectFiles: {},
  categoryPosts: [],
  setAges: dfFunc,
  setCareers: dfFunc,
  setNations: dfFunc,
  setReligions: dfFunc,
  setObjects: dfFunc,
  setLocations: dfFunc,
  setObjectFiles: dfFunc,
  setCategoryPosts: dfFunc,
});

export const MapContextProvider = (props: any) => {
  const [ages, setAges] = useState<any[]>([]);
  const [careers, setCareers] = useState<any[]>([]);
  const [nations, setNations] = useState<any[]>([]);
  const [religions, setReligions] = useState<any[]>([]);
  const [objects, setObjects] = useState<any[]>([]);
  const [locations, setLocations] = useState<any[]>([]);
  const [objectFiles, setObjectFiles] = useState<any>({});
  const [categoryPosts, setCategoryPosts] = useState<any[]>([]);

  const getAllCareers = async () => {
    try {
      const careers: any = await SettingService.getCareers();
      setCareers(careers);
    } catch (error) {}
  };
  const getAllNations = async () => {
    try {
      const nations: any = await SettingService.getNations();
      setNations(nations);
    } catch (error) {}
  };
  const getAllReligions = async () => {
    try {
      const religions: any = await SettingService.getReligions();
      setReligions(religions);
    } catch (error) {}
  };

  const getAgesPost = async () => {
    try {
      const agesData: any = await APIs.POSTS.getAgesForVideoPost();
      setAges(agesData);
    } catch (error) {}
  };

  const getAllObjects = async () => {
    try {
      const res: any = await APIs.SETTING.getSettingByName("objects");
      if (res?.setting?.value) {
        setObjects(res.setting.value);
      }
    } catch {}
  };

  const getLocations = async () => {
    try {
      const res: any = await APIs.SETTING.getLocations({
        query: {
          type: "country",
        },
      });
      if (res) {
        setLocations(res);
      }
    } catch {}
  };


  const getObjectFiles = async () => {
    try {
      const res: any = await APIs.SETTING.getObjectFiles();
      if (res) {
        setObjectFiles(res);
      }
    } catch {}
  };
  
  const getCategoryPosts = async () => {
    try {
      const res: any = await APIs.POSTS.getCategoryPostsForVideoPost();
      if (res) {
        setCategoryPosts(res);
      }
    } catch {}
  };

  useEffect(() => {
    getAgesPost();
    getAllCareers();
    getAllNations();
    getAllReligions();
    getAllObjects();
    getLocations();
    getObjectFiles();
    getCategoryPosts();
  }, []);

  return (
    <MapContext.Provider
      value={{
        ages,
        careers,
        nations,
        religions,
        objects,
        locations,
        objectFiles,
        categoryPosts,
        setAges,
        setCareers,
        setNations,
        setReligions,
        setObjects,
        setLocations,
        setObjectFiles,
        setCategoryPosts,
      }}
    >
      {props.children}
    </MapContext.Provider>
  );
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";

import { FRIEND_TYPE } from "config/enums";
import VFriend from "components/v-objects/v-friend";
import UserService from "api/users";
import { useAppDispatch } from "redux/reducers/hook";
import { getDataAccessByOwnerAsync } from "redux/reducers/users/users.actions";
import PageService from "api/pages";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  col?: string
};

const MembersJoined = (props: IProps, ref) => {
  const { dataUser, col } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [members, setMembers] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    limit: 20,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [dataAccesses, setDataAccesses] = useState([]);
  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    reloadData: () => {
      getData(1);
    },
  }));

  useEffect(() => {
    if (dataUser && dataUser._id) getData(lazyParams.page);
  }, [lazyParams]);

  const getData = async (page) => {
    setLoading(true);
    const res: any = await PageService.getFollowers({
      params: {
        id: dataUser._id,
      },
      query: {
        ...lazyParams,
        page,
      },
    });

    if (res && res.docs) {
      if (res.page === 1) {
        setMembers(res.docs);
      } else {
        setMembers((prev) => prev.concat(res.docs));
      }
      getDataAccesses(res.docs.map((f) => f.from_id));
      setTotalPages(res.totalPages);
    }
    setLoading(false);
  };

  const getDataAccesses = async (userIds) => {
    const res: any = await UserService.getDataAccessByUsers({
      query: {
        userIds,
      },
    });
    if (res && res.length > 0) {
      setDataAccesses(res);
    }
  };

  const handleViewMore = () => {
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const reload = ({ to_id }) => {
    dispatch(getDataAccessByOwnerAsync());
  };

  return (
    <div className="d-flex flex-wrap">
      {" "}
      {members.map((member, index) => {
        return (
          <div key={index} className={`d-flex justify-content-center p-1 col-6 ${col ? col : "col-md-4"}`}>
            <div className="rps-container">
              <VFriend
                friend={{
                  to_id: member.from_id,
                  to: member.from,
                  profile: member.profile
                }}
                dataAccessFriend={dataAccesses.find(
                  (d) => d.user_id === member.from_id
                )}
                reload={reload}
                type={FRIEND_TYPE.FRIEND}
              />
            </div>
          </div>
        );
      })}
      <div className="col-12">
        {lazyParams.page < totalPages && (
          <div className="text-center p-2">
            <VButton
              type="button"
              loading={loading}
              size={"small"}
              onClick={handleViewMore}
            >
              {" "}
              {t("View more")}
            </VButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default forwardRef(MembersJoined);

// Firebase Cloud Messaging Configuration File.
// Read more at https://firebase.google.com/docs/cloud-messaging/js/client && https://firebase.google.com/docs/cloud-messaging/js/receive

import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyDDs1lwbysBrlb4mwSuH-usLgzAHU3ebCw",
  authDomain: "vdiary-book-abe00.firebaseapp.com",
  projectId: "vdiary-book-abe00",
  storageBucket: "vdiary-book-abe00.appspot.com",
  messagingSenderId: "230641012908",
  appId: "1:230641012908:web:8527cf5506f7923744cf09",
  measurementId: "G-DPRCBB22WD"
};

let messaging = null;

try {
  initializeApp(firebaseConfig);
  messaging = getMessaging();
} catch (error) {}

export const requestForToken = () => {
  return getToken(messaging, {
    vapidKey: process.env.REACT_APP_FIREBASE_FCM_VAPID_KEY,
  })
    .then((currentToken) => {
      if (currentToken) {
        localStorage.setItem("firebaseToken", currentToken);
        console.log("current token for client: ", currentToken);
        // Perform any other neccessary action with the token
      } else {
        // Show permission request UI
        console.log(
          "No registration token available. Request permission to generate one."
        );
      }
    })
    .catch((err) => {
      console.log("An error occurred while retrieving token. ", err);
    });
};

// Handle incoming messages. Called when:
// - a message is received while the app has focus
// - the user clicks on an app notification created by a service worker `messaging.onBackgroundMessage` handler.
export const onMessageListener = () =>
  new Promise((resolve) => {
    try {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    } catch (error) {}
  });

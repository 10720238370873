import useTranslation from "hooks/useTranslation";

const View = ({ userData }) => {
  const { t } = useTranslation();
  const { profile } = userData;

  return (
    <>
      <div className="row-account">
        <div className="label">{t("Name")}</div>
        <div className="content">{profile?.family_data?.name}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("Description")}</div>
        <div className="content">{profile?.family_data?.description || ""}</div>
      </div>
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Error404 from "components/v-error-404";

import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import {
  getDataUser,
  getUserAddressAndCareer,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import { useParams } from "react-router-dom";
import { getIsApp, getMenusByKey } from "redux/reducers/settings/settings.selector";
import useQuery from "hooks/useQuery";
import VTab from "components/v-tab";
import ComingSoon from "pages/coming-soon/comingsoon";
import { isUser } from "utils/friends";
import { checkIsMyProfile, convertUrl } from "utils/common";
import { getUserByIdAsync } from "redux/reducers/users/users.actions";
import { isMobile } from "react-device-detect";
import Request from "./tab-views/requests";
import Relation from "./tab-views/relations";
import IntroduceFamily from "./tab-views/introduce";
import FamilyHeader from "./family-header";
import VWebView from "components/v-web-view";
import ProfileTimeline from "../user/profile/profile-timeline";
import ProfileLeftSidebarObject from "../object-details/profile-left-sidebar";
import ProfileLeftSidebar from "./profile-left-sidebar";
import { getPostDirectoryByUserIdAsync } from "redux/reducers/post-directory/post-directory.actions";
import { getPostDirectory } from "redux/reducers/post-directory/post-directory.selectors";

const View = () => {
  const isApp = useAppSelector(getIsApp)
  const dispatch = useAppDispatch();

  const params = useParams();
  const { id } = params;
  const profile: any = useAppSelector(getUserProfile);
  const [loading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const menus = useAppSelector(getMenusByKey("family_tree"));
  const [tab, setTab] = useState(null);
  const query = useQuery();
  const [loadMenu, setLoadMenu] = useState(false);
  const dataUser = useAppSelector(getDataUser);
  const userCommunity = useAppSelector(getUserAddressAndCareer);
  const isMyProfile = checkIsMyProfile(profile, id);
  const [userFamily, setUserFamily] = useState(null);
  const postDirectory = useAppSelector(getPostDirectory);

  useEffect(() => {
    if (userCommunity && userCommunity.family) {
      setUserFamily(userCommunity.family);
      dispatch(
        getPostDirectoryByUserIdAsync({
          params: {
            userId: userCommunity.family._id,
          },
        })
      );
    }
  }, [userCommunity]);

  useEffect(() => {
    if (dataUser) {
      if (isUser(dataUser.object_type)) {
        setIsNotFound(false);
      } else {
        setIsNotFound(true);
      }
    }
  }, [dataUser]);

  useEffect(() => {
    getUserById();
  }, [id]);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  useEffect(() => {}, []);

  const getUserById = async () => {
    setLoading(true);
    try {
      dispatch(
        getUserByIdAsync({
          params: {
            id: id,
          },
        })
      );
    } catch (error) {
      setIsNotFound(true);
    }
    setLoading(false);
  };

  const renderTab = () => {
    if (tab && tab.type_view === "internal") {
      if (tab.key === "timeline" && userFamily)
        return (
          <>
            {!isApp && (
              <Col md={4}>
                <ProfileLeftSidebarObject
                  isAuth={isMyProfile}
                  dataUser={userFamily}
                  dataFolder={postDirectory}
                />
              </Col>
            )}
            <Col id={"post_profile"} className="ps-0" md={8}>
              <ProfileTimeline userId={userFamily._id} />
            </Col>
          </>
        );

      if (tab.key === "introduce")
        return (
          <>
            {!isMobile && (
              <Col md={4}>
                <ProfileLeftSidebar
                  isMyProfile={isMyProfile}
                  dataUser={dataUser}
                />
              </Col>
            )}
            <Col className={!isMobile ? "ps-0" : ""} md={8}>
              <IntroduceFamily isMyProfile={isMyProfile} dataUser={dataUser} />
            </Col>
          </>
        );
      if (tab.key === "requests")
        return (
          <>
            {!isMobile && (
              <Col md={4}>
                <ProfileLeftSidebar
                  isMyProfile={isMyProfile}
                  dataUser={dataUser}
                />
              </Col>
            )}
            <Col className={!isMobile ? "ps-0" : ""} md={8}>
              {isMyProfile && <Request />}
            </Col>
          </>
        );
      if (tab.key === "relations")
        return (
          <>
            {!isMobile && (
              <Col md={4}>
                <ProfileLeftSidebar
                  isMyProfile={isMyProfile}
                  dataUser={dataUser}
                />
              </Col>
            )}
            <Col className={!isMobile ? "ps-0" : ""} md={8}>
              {isMyProfile && <Relation dataUser={dataUser} />}
            </Col>
          </>
        );
    }
    if (tab && tab.type_view === "webview") {
      return (
        <>
          {!isMobile && (
            <Col md={4}>
              <ProfileLeftSidebar
                isMyProfile={isMyProfile}
                dataUser={dataUser}
              />
            </Col>
          )}
          <Col className={!isMobile ? "ps-0" : ""} md={8}>
            <VWebView
              className={"iframe-newfeed-profile"}
              url={convertUrl(tab.url, dataUser)}
              widthScrollbar={tab?.width_scrollbar}
            />
          </Col>
        </>
      );
    }

    return <ComingSoon />;
  };

  const _menus = useMemo(() => {
    return menus.filter((m) => {
      if (!isMyProfile && (m.key === "requests" || m.key === "relations")) {
        return false;
      }
      return true;
    });
  }, [isMyProfile, menus]);

  return isNotFound && !loading ? (
    <Error404 />
  ) : (
    !loading && (
      <div className="profile-wrapper">
        <Container>
          <Row>
            <Col sm={12}>
              {profile && (
                <FamilyHeader dataUser={dataUser} isMyProfile={isMyProfile} />
              )}
            </Col>
            <Col sm={12}>
              <VTab
                tab={tab}
                setTab={setTab}
                menus={_menus}
                activeBorder="bottom"
              />
              <Row>{renderTab()}</Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import WebModulesIframe from "components/iframe-web-module";
import { WEB_MODULE_URL } from "config/env";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import VBannerPage from "components/v-teamplate/v-banner-page";
import { Col, Container, Row } from "react-bootstrap";
import VRightView from "components/v-right-view";
import qs from "query-string";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";
import { useLocation } from "react-router-dom";
import { setRedirectUrl } from "redux/reducers/ui-settings/ui-settings.reducer";

const Reels = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const general = useAppSelector(getGeneral);

  const show_banner_right_on_container =
    general?.banner_right_layout !== "show_on_right_bar" || null;

  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const [tab, setTab] = useState<string>("");

  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const location = useLocation();

  const isInReelsPage = useMemo(() => {
    const urlParams = new URLSearchParams(location?.search);
    const isApp = urlParams.get("is_app");
    return !isApp;
  }, [location]);
  const tabIdParam = useMemo(() => {
    const urlParams = new URLSearchParams(location?.search);
    return urlParams.get("tab");
  }, [location]);
  const token = localStorage.getItem("auth");

  const getIframeUrl = (key: string) => {
    const pathKey = {
      reels: `/reels`,
    };

    const params = {
      origin: window.origin,
      vdb_token: localStorage.getItem("auth"),
      moduleSessionId,
      fromSessionUid,
      lang: i18n.language ? `${i18n.language}` : "",
      tab,
    };

    if (pathKey[key] && moduleSessionId && fromSessionUid) {
      return `${WEB_MODULE_URL}${pathKey[key] || "/"}?${qs.stringify(params)}`;
    }
    return null;
  };

  const sendMessageToIframe = () => {
    const iframeElement = document.getElementById(
      "reels-iframe"
    ) as HTMLIFrameElement;
    const iframeWindow = iframeElement.contentWindow;
    iframeWindow.postMessage("load_more", "*");
  };

  const renderContent = (children) => {
    return isInReelsPage ? <Container>{children}</Container> : children;
  };

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    window.addEventListener("scroll", (e: any) => {
      const scrollPosition = window.scrollY;

      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;

      const reachedBottom = scrollPosition + windowHeight >= documentHeight;

      if (reachedBottom) {
        sendMessageToIframe();
      }
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  useEffect(() => {
    if (!tabIdParam) return;
    setTab((prev) => prev || tabIdParam);
  }, [tabIdParam]);

  useEffect(() => {
    return () => {
      dispatch(setRedirectUrl({ url: "" }));
    };
  }, []);

  return (
    <>
      {renderContent(
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <VBannerPage position="banner_top_reels" />
            <WebModulesIframe
              id="reels-iframe"
              url={getIframeUrl("reels")}
              title={tab || ""}
              loading={loading}
              onLoad={() => setLoading(false)}
              className="iframe-moments"
            />
          </Col>
          {token && show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1 "}>
              <VRightView
                className="newfeed-rightview"
                positionBanner={"banner_right_home"}
              />
            </Col>
          )}
        </Row>
      )}
    </>
  );
};

export default Reels;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable array-callback-return */
import UserService from "api/users";
import UploadService from "api/uploads";
// import VDropdown from "components/v-dropdown";
import VInput from "components/v-input";
import VCodeVip from "components/v-code-vip";
import VUpload from "components/v-upload";
import { CORVER_TYPE, BACKGROUND_PROFILE_TYPE } from "config/enums";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { Form } from "react-bootstrap";
import useTranslation from "hooks/useTranslation";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/reducers/hook";

import { v4 as uuidv4 } from "uuid";
import { getUserByIdAsync } from "redux/reducers/users/users.actions";
import { Label } from "reactstrap";
import VButton from "components/v-button";
import UploadFile from "components/uploadFile";
import VTab from "components/v-tab";
import VModal from "components/v-modal";
import ReusePhotos from "./ReusePhotos";
import VRadio from "components/v-radio";
import Switch from "react-switch";

const defaultStateUpload = [
  {
    id: uuidv4(),
    src: "",
    isNew: true,
    file: null,
  },
];

const menuUpdate = [
  { key: "update_cover", name: "Covers" },
  { key: "update_background_profile", name: "Background profile" },
  { key: "popup_welcome", name: "Popup welcome" },
];

const EditCover = ({ dataUser, setLoading, setOpenModal, action }, ref) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const coverRef = useRef(null);

  const permissions = dataUser?.permissions || {};

  const { covers, background, popup_welcome } = dataUser.profile;

  const [state, setState] = useState({
    type: "image",
    url: "",
    slide: [],
    banners: {
      text_color: "",
      background_color: "",
      content: [],
    },
    title: "",
    description: "",
    status: "enable",
    action_button: [
      {
        id: "",
        name: "",
        color: "",
        background_color: "",
        link: "",
        type: "text",
        button_image: null,
      },
    ],
  });
  const [openVip, setOpenVip] = useState(false);
  const [openModalFile, setOpenModalFile] = useState(false);

  const [listFile, setListFile] = useState(defaultStateUpload);
  const [listFileBanner, setListFileBanner] = useState({});
  const [keyEdit, setKeyEdit] = useState("update_cover");
  const [type, setType] = useState<any>(CORVER_TYPE);

  const submit = () => {
    coverRef.current.submit();
  };

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit();
    },
  }));

  useEffect(() => {
    setKeyEdit(action);
    if (action === "update_cover") {
      setState(covers);
      setType(CORVER_TYPE);
    } else if (action === "update_background_profile") {
      setState(background);
      setType(BACKGROUND_PROFILE_TYPE);
    } else {
      setState(popup_welcome);
      setType(CORVER_TYPE);
    }
  }, [action]);

  useEffect(() => {
    if (covers.type === type.IMAGE || covers.type === type.VIDEO) {
      setListFile([
        {
          id: uuidv4(),
          src: covers.url,
          isNew: false,
          file: null,
        },
      ]);
    } else if (covers.type === type.SLIDE) {
      const dataCover = covers.slide?.map((el) => ({
        ...el,
        isNew: false,
      }));
      setListFile(dataCover);
    }
  }, [covers]);

  useEffect(() => {
    if (keyEdit === "update_cover") {
      if (covers.type === type.IMAGE || covers.type === type.VIDEO) {
        setListFile([
          {
            id: uuidv4(),
            src: covers.url,
            isNew: false,
            file: null,
          },
        ]);
      } else if (covers.type === type.SLIDE) {
        const dataCover = covers.slide?.map((el) => ({
          ...el,
          isNew: false,
        }));
        setListFile(dataCover);
      }
    }
    if (keyEdit === "update_background_profile") {
      if (background.type === type.IMAGE || background.type === type.VIDEO) {
        setListFile([
          {
            id: uuidv4(),
            src: background.url,
            isNew: false,
            file: null,
          },
        ]);
      } else if (background.type === type.SLIDE) {
        const dataBackground = background.slide?.map((el) => ({
          ...el,
          isNew: false,
        }));
        setListFile(dataBackground);
      }
    }
    if (keyEdit === "popup_welcome") {
      if (
        popup_welcome.type === type.IMAGE ||
        popup_welcome.type === type.VIDEO
      ) {
        setListFile([
          {
            id: uuidv4(),
            src: popup_welcome.url,
            isNew: false,
            file: null,
          },
        ]);
      } else if (popup_welcome.type === type.SLIDE) {
        const dataPopupWelcome = popup_welcome.slide?.map((el) => ({
          ...el,
          isNew: false,
        }));
        setListFile(dataPopupWelcome);
      }
    }
  }, [keyEdit]);

  const handleChangeTypeEdit = (type: string) => {
    setKeyEdit(type);
    if (type === "update_cover") {
      setState(covers);
      setType(CORVER_TYPE);
    } else if (type === "update_background_profile") {
      setState(background);
      setType(BACKGROUND_PROFILE_TYPE);
    } else {
      setState(popup_welcome);
      setType(CORVER_TYPE);
    }
  };

  const setFile = (f: any, id: string) => {
    setListFileBanner((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const handelUploadButtonImage = async (f: any, id: string) => {
    try {
      const formData = new FormData();
      formData.append("files", f);
      formData.append("folderPath", `users/${dataUser?._id}`);

      const button_image: any = await UploadService.uploadFile({
        body: formData,
      });
      if (button_image) {
        setState((prev) => ({
          ...prev,
          action_button: (prev?.action_button || []).map((el) =>
            el.id === id ? { ...el, button_image: button_image?.filePath } : el
          ),
        }));
      }
    } catch (error) {}
  };

  const onChange = (name, value, key?: string) => {
    if (name === "type" && value !== type.LINK) {
      setListFile(defaultStateUpload);
    } else {
      setListFile([]);
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeInfoPopupWelcome = (name, value) => {
    if (name === "status") {
      setState((prev) => ({
        ...prev,
        [name]: value ? "enable" : "disable",
      }));
    } else {
      setState((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const onChangeActionPopupWelcome = (name, value, id) => {
    setState((prev) => ({
      ...prev,
      action_button: (prev?.action_button || []).map((el) =>
        el.id === id ? { ...el, [name]: value } : el
      ),
    }));
  };

  const handleAddBanner = () => {
    setState((prev) => ({
      ...prev,
      banners: {
        ...prev.banners,
        content: [
          ...(prev.banners?.content || []),
          {
            id: uuidv4(),
            value: "",
            link: "",
            icon: "",
          },
        ],
      },
    }));
  };

  const handleAddActionButton = () => {
    setState((prev) => ({
      ...prev,
      action_button: [
        ...(prev.action_button || []),
        {
          id: uuidv4(),
          name: "",
          link: "",
          color: "",
          background_color: "",
          type: "text",
          button_image: null,
        },
      ],
    }));
  };

  const removeBanner = (index) => {
    setState((prev) => ({
      ...prev,
      banners: {
        ...prev.banners,
        content: (prev.banners?.content || []).filter(
          (el, idx) => index !== idx
        ),
      },
    }));
  };

  const onChangeBanner = (name: string, value: string, id?: string) => {
    if (name === "text_color" || name === "background_color") {
      setState((prev) => ({
        ...prev,
        banners: {
          ...prev?.banners,
          [name]: value,
        },
      }));
    } else {
      setState((prev) => ({
        ...prev,
        banners: {
          ...prev?.banners,
          content: prev?.banners?.content?.map((el) =>
            el.id === id ? { ...el, [name]: value } : el
          ),
        },
      }));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let listUpload = listFile;
      const checkIsFileUpload = listFile.find((el) => el.isNew);

      if (listFile && listFile.length > 0 && checkIsFileUpload) {
        const formData = new FormData();
        formData.append("folderPath", `users/${dataUser?._id}`);

        const statesNew = listFile
          .filter((st) => st.isNew)
          .map((stNew) => {
            formData.append("files", stNew.file);
            return stNew;
          });

        const listImg: any = await UploadService.uploadMultiFile({
          body: formData,
        });
        listImg.forEach((el) => {
          statesNew[el.id] = {
            ...statesNew[el.id],
            src: el?.filePath,
          };
        });

        listUpload = listUpload.map((st) => {
          const newStFound = statesNew.find((c) => c.id === st.id);
          if (newStFound) {
            return {
              ...st,
              ...newStFound,
            };
          }
          return st;
        });
      }

      let listImgBanner;
      if (Object.keys(listFileBanner || {}).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${dataUser?._id}`);

        Object.keys(listFileBanner).map((el: any) => {
          formData.append("files", listFileBanner[el]);
        });
        listImgBanner = await UploadService.uploadMultiFile({
          body: formData,
        });
      }

      let dataBannerContent = state?.banners?.content;

      if (
        dataBannerContent?.length > 0 &&
        listImgBanner &&
        listImgBanner?.length > 0
      ) {
        listImgBanner?.map((el: any) => {
          Object.keys(listFileBanner || {}).map(
            (item: any, indexKey: number) => {
              if (el.id === indexKey) {
                for (let index = 0; index < dataBannerContent.length; index++) {
                  const element = dataBannerContent[index];
                  if (String(element.id) === String(item)) {
                    dataBannerContent = dataBannerContent.map((item, idx) =>
                      idx === index
                        ? {
                            ...item,
                            icon: el?.filePath,
                          }
                        : item
                    );
                  }
                }
              }
            }
          );
        });
      }

      const data = {
        type: state.type || type.IMAGE,
        url: listUpload[0]?.src || state.url,
        slide: state.type === type.SLIDE ? listUpload : [],
        banners: {
          ...state.banners,
          content: dataBannerContent,
        },
        title: state.title,
        description: state.description,
        action_button: state.action_button,
        status: state.status,
      };

      const res = await UserService.updateCover({
        query: {
          key: keyEdit,
        },
        body: {
          ...data,
          user_id: dataUser?._id,
        },
      });
      if (res) {
        dispatch(
          getUserByIdAsync({
            params: {
              id: dataUser?._id,
            },
          })
        );
        // toast.success(t("success"));
        setOpenModal(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t("Update failed"));
    }
  };

  const renderContentModal = () => {
    return (
      <ReusePhotos
        ref={coverRef}
        type={keyEdit}
        dataUser={dataUser}
        setLoading={setLoading}
        setOpenModal={setOpenModal}
      />
    );
  };

  return (
    <>
      <div className="container">
        <div>
          <VTab
            tab={menuUpdate?.find((el) => el.key === keyEdit)}
            setTab={(e) => handleChangeTypeEdit(e.key)}
            menus={menuUpdate}
            activeBorder="bottom"
            noRedirect={true}
            noBackground={true}
          />
        </div>
        {keyEdit === "popup_welcome" && (
          <div className="my-3 d-flex align-items-center justify-content-between">
            <Label className="mb-0 fw-bold">{t("Kích hoạt tính năng")}</Label>
            <Switch
              width={44}
              height={20}
              onChange={(v) => onChangeInfoPopupWelcome("status", v)}
              checked={state.status === "enable"}
            />
          </div>
        )}
        <div className="row">
          <Form.Group className="form-group">
            <Label className="fw-bold">
              {t(
                keyEdit === "update_cover"
                  ? "Cover type"
                  : keyEdit === "update_background_profile"
                  ? "Background type"
                  : "Popup welcome"
              )}
            </Label>
            <VRadio
              className={"d-flex justify-content-between"}
              options={Object.keys(type).map((el: any) => {
                return {
                  label: el,
                  value: type[el],
                  disabled:
                    type[el] === type.IMAGE && keyEdit === "update_cover"
                      ? false
                      : !permissions[`profile.${keyEdit}`],
                };
              })}
              value={state.type}
              defaultValue={"image"}
              onChange={(e) => onChange("type", e)}
            />
          </Form.Group>
          {state.type && state.type !== type.LINK && (
            <div
              hidden={
                keyEdit === "update_background_profile" &&
                state?.type === "video"
              }
            >
              <VUpload
                isMulti={state.type === type.SLIDE}
                listFile={listFile}
                typeUpload={state.type === type.SLIDE ? "image" : state.type}
                // isCrop={keyEdit === "update_cover"}
                // aspect={100 / 32}
                setListFile={setListFile}
              />
            </div>
          )}
          {state.type && state.type === type.LINK && (
            <Form.Group className="form-group">
              <VInput
                value={state.url}
                label={`${t("enter_url")} ${t("facebook")} / ${t("youtube")}`}
                onChange={(e) => onChange("url", e.target.value)}
              />
            </Form.Group>
          )}
        </div>

        {/* VIP */}
        {!permissions[`profile.${keyEdit}`] && (
          <div className="bg-light bg-gradient py-1 px-2 mb-2 rounded">
            <div className="d-block">
              <a
                href="https://vzonex.com/my/shop"
                target={"_blank"}
                rel={"noreferrer"}
              >
                {t("please_click_buy_code")}
              </a>
            </div>
            <div className="mb-2 d-flex align-items-center justify-content-between">
              <Label className="mb-0 fw-bold">VIP</Label>
              <Switch
                width={44}
                height={20}
                onChange={(v) => setOpenVip(v)}
                checked={openVip}
              />
            </div>
            {openVip && (
              <VCodeVip permission={`profile.${keyEdit}`} user={dataUser} />
            )}
          </div>
        )}
        {keyEdit === "update_background_profile" &&
          permissions[`profile.${keyEdit}`] && (
            <div>
              <VButton
                disabled={state.banners?.content?.length > 2}
                onClick={() => setOpenModalFile(true)}
              >
                {t("Use templates")}
              </VButton>
            </div>
          )}
        {/* Banner */}
        {keyEdit === "update_cover" && (
          <>
            <div className="my-2">
              <VButton onClick={() => setOpenModalFile(true)}>
                {t("Choose a photo")}
              </VButton>
            </div>
            <div className="banner-cover-profile-setting">
              <Label className="fw-bold">{t("Banner cover")}</Label>
              <div className="d-flex mb-3">
                <div className={"input-color-setting"}>
                  <VInput
                    label={t("Text color")}
                    value={state?.banners?.text_color}
                    onChange={(e) =>
                      onChangeBanner("text_color", e.target.value)
                    }
                  />
                </div>
                <div className="setting-color-post-directory">
                  <VInput
                    id="colorfield"
                    name="color"
                    type="color"
                    value={state?.banners?.text_color}
                    onChange={(e) =>
                      onChangeBanner("text_color", e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="d-flex mb-3">
                <div className={"input-color-setting"}>
                  <VInput
                    label={t("Background color")}
                    value={state?.banners?.background_color}
                    onChange={(e) =>
                      onChangeBanner("background_color", e.target.value)
                    }
                  />
                </div>
                <div className="setting-color-post-directory">
                  <VInput
                    id="bgcolorfield"
                    name="color"
                    type="color"
                    value={state?.banners?.background_color}
                    onChange={(e) =>
                      onChangeBanner("background_color", e.target.value)
                    }
                  />
                </div>
              </div>
              {state.banners?.content?.map((el, index) => (
                <div key={index} className="banner-header-setting-profile">
                  <div className="input-color-setting d-flex">
                    <div className="image-upload">
                      <UploadFile
                        id={el.id}
                        src={el.icon}
                        ratio="70x70"
                        file={listFileBanner?.[el.id]}
                        setFile={setFile}
                      />
                    </div>
                    <div className="content-banner">
                      <div className={"mb-3"}>
                        <VInput
                          label={t("Banner content")}
                          value={el.value}
                          onChange={(e) =>
                            onChangeBanner("value", e.target.value, el.id)
                          }
                        />
                      </div>
                      <div className={"mb-3"}>
                        <VInput
                          label={t("Link")}
                          value={el.link}
                          onChange={(e) =>
                            onChangeBanner("link", e.target.value, el.id)
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="setting-color-post-directory text-center">
                    <VButton
                      className={"py-0 bg-danger"}
                      onClick={() => removeBanner(index)}
                    >
                      -
                    </VButton>
                  </div>
                </div>
              ))}
              <div>
                <VButton
                  disabled={state.banners?.content?.length > 2}
                  onClick={handleAddBanner}
                >
                  +
                </VButton>
              </div>
            </div>
          </>
        )}

        {/* Popup welcome */}
        {keyEdit === "popup_welcome" && (
          <>
            <div className={"mb-2"}>
              <VInput
                label={t("Title")}
                value={state.title}
                onChange={(e) =>
                  onChangeInfoPopupWelcome("title", e.target.value)
                }
              />
            </div>
            <div className={"mb-2"}>
              <VInput
                label={t("Description")}
                value={state.description}
                onChange={(e) =>
                  onChangeInfoPopupWelcome("description", e.target.value)
                }
              />
            </div>
            <Label>{t("Action button")}</Label>
            {state?.action_button?.map((el) => (
              <div className="border p-2 mb-2">
                <div className="mb-3">
                  <VRadio
                    options={[
                      {
                        label: t("text"),
                        value: "text",
                      },
                      {
                        label: t("image"),
                        value: "image",
                      },
                    ]}
                    value={el.type}
                    defaultValue={"text"}
                    onChange={(e) =>
                      onChangeActionPopupWelcome("type", e, el.id)
                    }
                  />
                </div>
                {el.type === "image" ? (
                  <div
                    className="position-relative mb-3 m-auto"
                    style={{
                      width: "100px",
                      height: "100px",
                    }}
                  >
                    <UploadFile
                      id={el.id}
                      src={el.button_image}
                      ratio="70x70"
                      setFile={handelUploadButtonImage}
                    />
                  </div>
                ) : (
                  <>
                    <div className={"mb-3"}>
                      <VInput
                        label={t("Name button")}
                        value={el.name}
                        onChange={(e) =>
                          onChangeActionPopupWelcome(
                            "name",
                            e.target.value,
                            el.id
                          )
                        }
                      />
                    </div>
                    <div className="banner-cover-profile-setting">
                      <div className="d-flex mb-3">
                        <div className={"input-color-setting"}>
                          <VInput
                            label={t("Color")}
                            value={el.color}
                            onChange={(e) =>
                              onChangeActionPopupWelcome(
                                "color",
                                e.target.value,
                                el.id
                              )
                            }
                          />
                        </div>
                        <div className="setting-color-post-directory">
                          <VInput
                            id="bgcolorfield"
                            name="color"
                            type="color"
                            value={el.color}
                            onChange={(e) =>
                              onChangeActionPopupWelcome(
                                "color",
                                e.target.value,
                                el.id
                              )
                            }
                          />
                        </div>
                      </div>
                      <div className="d-flex mb-3">
                        <div className={"input-color-setting"}>
                          <VInput
                            label={t("Background color")}
                            value={el.background_color}
                            onChange={(e) =>
                              onChangeActionPopupWelcome(
                                "background_color",
                                e.target.value,
                                el.id
                              )
                            }
                          />
                        </div>
                        <div className="setting-color-post-directory">
                          <VInput
                            id="bgcolorfield"
                            name="background_color"
                            type="color"
                            value={el.background_color}
                            onChange={(e) =>
                              onChangeActionPopupWelcome(
                                "background_color",
                                e.target.value,
                                el.id
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className={"mb-3"}>
                  <VInput
                    label={t("Link")}
                    value={el.link}
                    onChange={(e) =>
                      onChangeActionPopupWelcome("link", e.target.value, el.id)
                    }
                  />
                </div>
              </div>
            ))}
            <div>
              <VButton
                disabled={state.action_button?.length > 1}
                onClick={handleAddActionButton}
              >
                +
              </VButton>
            </div>
          </>
        )}
      </div>
      <VModal
        title={t("Background profile")}
        isOpen={openModalFile}
        // loading={loading}
        centered={true}
        className={"modal-setting-background-profile"}
        children={renderContentModal()}
        onCancel={() => setOpenModalFile(false)}
        onConfirm={submit}
      />
    </>
  );
};

export default forwardRef(EditCover);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import PostService from "api/posts";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";

import useTranslation from "hooks/useTranslation";
import VIcon from "components/v-icon";
import VTimeline from "components/v-timeline";
import { renderDate } from "utils/times";
import useQuery from "hooks/useQuery";
import { useHistory } from "react-router-dom";
import VChannel from "components/v-channel";
import DropdownModal from "components/v-dropdown-modal/View";
import { saveAs } from "file-saver";
import { useDispatch } from "react-redux";
import { copyText, getUrlImage } from "utils/common";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { toast } from "react-toastify";
import VModal from "components/v-modal";

const ProfileMP3 = (props) => {
  const { dataUser } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const menuPostShare = useAppSelector(getMenusByKey("post_share"));
  const listActionShare = menuPostShare?.filter(
    (share) => !share?.parent_id && share?.key !== "send_to_message"
  );
  const menuPostOtherSharing = menuPostShare?.filter(
    (el) =>
      el?.parent_id ===
      menuPostShare?.find((t) => t.key === "other_sharing")?._id
  );
  
  const [postVideo, setPostVideo] = useState([]);
  const [videos, setVideos] = useState<any>([]);
  const [mediaTimeline, setMediaTimeline] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");

  const handleView = (id) => {
    if (window.location.search) {
      history.push(`${window.location.search}&view=${id}`);
    } else {
      history.push(`?view=${id}`);
    }
  };

  const handlePostOtherSharing = (action: any) => {
    window.open(`${action?.url?.replace("{url}", `${window.location.href}`)}`);
  };

  const handleClickOption = (type: string, file: any, item?: any) => {
    if (type === "download") {
      saveAs(
        getUrlImage(file?.post_file_path),
        `${file?.post_file_name || `${Date.now()}.png`}`
      );
    }
    if (type === "view") {
      dispatch(
        setOpenWebModulesPopupAction({
          openWebModuleKey: "post_detail",
          payload: {
            post: {
              _id: file._id,
            },
          },
        })
      );
    }

    if (type === "copy") {
      copyText(`${window.location.origin}/posts/${file?.post_id}`);
      toast.success(t("Copied to clipboard!"));
    } 
    if (type === "share") {
      if (item?.key === "other_sharing") {
        setOpenModal(true);
        setTitle(item?.name);
      } else {
        dispatch(
          setOpenWebModulesPopupAction({
            page: "create",
            openWebModuleKey: "share_drive",
            typeShare: item?.key,
            contentShare: `${window.location.origin}/posts/${file?.post_id}`
          })
        );
      }
    }
  };

  const custumValueTimeline = (data) => {
    if (data?.length > 0) {
      const checkDate = (date1, date2) => {
        const a = new Date(date1);
        const b = new Date(date2);
        if (
          a.getDate() === b.getDate() &&
          a.getMonth() === b.getMonth() &&
          a.getFullYear() === b.getFullYear()
        ) {
          return true;
        }
        return false;
      };

      let value = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (value.find((el) => checkDate(el.createdAt, element.createdAt))) {
          value = value.map((item) =>
            checkDate(item.createdAt, element.createdAt)
              ? {
                  ...item,
                  content: (item?.content || []).concat(element),
                }
              : item
          );
        } else {
          value = value.concat({
            createdAt: element.createdAt,
            content: [element],
          });
        }
      }
      setMediaTimeline(value);
    }
  };

  const getFiles = async () => {
    try {
      const res: any = await PostService.getPostByUser({
        user_id: dataUser._id,
        query: {
          post_type: "audio",
          limit: 20,
          page: pageCurrent,
        },
      });
      if (res) {
        custumValueTimeline([...postVideo, ...res]);
        setPostVideo([...postVideo, ...res]);

        let listVideo = [];
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          listVideo = listVideo.concat(element.post_files);
        }
        setVideos([...videos, ...listVideo]);
        setPageCurrent(pageCurrent + 1);
      }
    } catch (error) {}
  };

  window.onscroll = function () {
    if (
      Number(window.innerHeight + window.pageYOffset) >=
      Number(document.body.offsetHeight - 10)
    ) {
      getFiles();
    }
  };

  useEffect(() => {
    getFiles();
  }, [dataUser]);

  return (
    <>
      {query?.view ? (
        <VChannel type="audio" data={postVideo} />
      ) : (
        <>
          {videos.length > 0 ? (
            <VTimeline
              data={mediaTimeline?.map((media, index) => ({
                icon: (
                  <img src={`/assets/icons/default/book-diary.svg`} alt="" />
                ),
                title: renderDate(media?.createdAt),
                bgColorTime: "#3498db33",
                colorTime: "#4284F3",
                children: (
                  <Row>
                    {media?.content?.map((el, index) => (
                      <Col
                        key={index}
                        className={`${"col-sm-3 col-lg-2"} col-6 mb-3 d-flex align-items-center justify-content-center`}
                      >
                        <div className="user-images position-relative border">
                          <div className="action-item-media">
                            <DropdownModal
                              label={
                                <div className="item-action-corver">
                                  <VIcon
                                    width={12}
                                    height={10}
                                    name={"option.svg"}
                                  ></VIcon>
                                </div>
                              }
                              menu={[
                                {
                                  key: t("Download"),
                                  onClick: (_) =>
                                    handleClickOption("download", el),
                                },
                                {
                                  key: t("View Original Diary"),
                                  onClick: (_) => handleClickOption("view", el),
                                },
                                {
                                  key: t("Chia sẻ"),
                                  children: listActionShare,
                                  onClick: (_) => handleClickOption("share", el, _),
                                },
                                {
                                  key: t("Copy link"),
                                  onClick: (_) => handleClickOption("copy", el, _),
                                }
                              ]}
                            />
                          </div>
                          <div
                            className="item-image"
                            onClick={() => handleView(el._id)}
                          >
                            <div className="items-mp3">
                              <VIcon
                                className="d-flex justify-content-center"
                                name="music.svg"
                              />
                              <span className="text-ellipsis short-2 px-1 mt-2">
                                {el?.post_files[0]?.post_file_name}
                              </span>
                            </div>
                          </div>
                        </div>
                      </Col>
                    ))}
                  </Row>
                ),
              }))}
            />
          ) : (
            <span className="w-100 text-center mt-3">
              {t("No content to display")}
            </span>
          )}
        </>
      )}
      <VModal
        title={t(title)}
        isOpen={openModal}
        centered={true}
        // disableConfirm={!isPolicy}
        confirmLabel={t("Add")}
        classNameBody={"px-2"}
        children={
          <div className="d-flex justify-content-center flex-wrap my-3">
            {menuPostOtherSharing?.map((el, index) => (
              <div key={index} className="col-3 max-w-[100px]">
                <div
                  className="d-flex position-relative flex-column justify-content-center align-items-center cursor-pointer rounded-lg"
                  onClick={() => handlePostOtherSharing(el)}
                >
                  <div className="d-flex align-items-center justify-content-center p-2">
                    <img
                      width={30}
                      src={getUrlImage(el?.img_icon, 150)}
                      alt=""
                    />
                  </div>
                  <div className={`d-flex align-items-center text-xs mt-1`}>
                    {`${el.name}`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
};

export default ProfileMP3;

import APIs from "api";
import Input from "components/v-input";
import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { securityAsync } from "redux/reducers/securities/securities.action";
import { getSecurity } from "redux/reducers/securities/securities.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { verify, setVerify } = props;
  const user = useAppSelector(getUserProfile);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();
  const { is_enable } = useAppSelector(getSecurity);
  const [isSend, setIsSend] = useState(false);

  const getCode = async () => {
    try {
      if(isSend) return;
      setLoading(true);
      await APIs.AUTH.resendCode({
        body: { email: user.email, title: "Verification Code - Set pin code" },
      });
      setIsSend(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.errors);
    }
  };

  useEffect(() => {
    dispatch(securityAsync());
  }, [dispatch]);

  return (
    <div>
      <div className="content-rigth-view">
        <Input
          transparent={true}
          label="Email verification code"
          onChange={(e) =>
            setVerify({
              ...verify,
              email_code: e.target.value,
            })
          }
          value={verify.email_code}
          suffix={
            <div
              className="cursor-pointer text-primary"
              onClick={getCode}
              hidden={loading}
            >
              {!isSend ? "Get code" : "Code Send"}
            </div>
          }
        />
        <span className="text-details">
          Enter the 6 digit code send to {user.email}
        </span>
        {is_enable && (
          <div className="mt-2">
            {" "}
            <Input
              transparent={true}
              label=" Google verification code"
              onChange={(e) =>
                setVerify({
                  ...verify,
                  google_code: e.target.value,
                })
              }
              value={verify.google_code}
            />
            <span className="text-details">
              Enter the 6 digit code from Google Authenticator
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

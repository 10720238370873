import { useAppSelector } from "redux/reducers/hook";
import { getIconSet } from "redux/reducers/settings/settings.selector";

/* eslint-disable jsx-a11y/alt-text */

type IProps = {
  name: string;
  height?: number;
  width?: number;
  badge?: number;
  className?: string;
  onClick?: any;
};
const View = ({
  name,
  width,
  height,
  badge = 0,
  className,
  onClick,
}: IProps) => {
  const folderIcon = useAppSelector(getIconSet) || "default";
  return name ? (
    <div className={`v-icon ${className ? className : ""}`}>
      <img
        src={`/assets/icons/${folderIcon}/${name}`}
        width={width || 20}
        height={height || 20}
        onClick={(_) => onClick && onClick()}
      />
      {badge ? <span className="v-icon-badge">{badge}</span> : ""}
    </div>
  ) : (
    <img
      src={`/assets/images/VDB-64.png`}
      width={width || 20}
      height={height || 20}
      onClick={(_) => onClick && onClick()}
    />
  );
};

export default View;

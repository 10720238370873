/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { useParams } from "react-router-dom";
import FriendService from "api/friends";
import VButton from "components/v-button";
import VModal from "components/v-modal";
import VActionBusiness from "components/v-objects/v-actions-business";
import VActionFriend from "components/v-objects/v-actions-friend";
import VActionGroup from "components/v-objects/v-actions-group";
import VipsService from "api/vips";

import {
  BUSINESS_ACTION,
  FRIEND_ACTION,
  FRIEND_TYPE,
  GROUP_ACTION,
} from "config/enums";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import {
  checkVipGroup,
  isBusiness,
  isCommunity,
  isGroup,
  isPage,
  isUser,
} from "utils/friends";
import { Input } from "reactstrap";
import GroupService from "api/groups";
import { enableChat } from "utils/common";
import PermissionsGate from "components/permissions-gate/PermissionsGate";

type IProps = {
  t: any;
  dataUser: any;
  isMyProfile: boolean;
  relationData: any;
};

const SocialActionProfile = (props: IProps) => {
  const { t, dataUser, isMyProfile, relationData } = props;
  const profile = useAppSelector(getUserProfile);
  const params = useParams();
  const { id } = params;
  const [isOpen, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [code, setCode] = useState("");

  const [relation, setRelation] = useState({
    fromFollow: null,
    fromFriend: null,
    toFriend: null,
    fromObject: null,
    toObject: null,
  });

  useEffect(() => {
    if (!isMyProfile && dataUser && dataUser._id) {
      setRelation(relationData);
    }
  }, [isMyProfile, relationData]);

  const getRelation = async () => {
    const res: any = await FriendService.getRelationWithToUser({
      params: {
        to_id: dataUser._id,
      },
    });
    setRelation(res);
  };

  const activedCode = Boolean(profile?.data_access?.vip_groups?.includes(id));

  async function handleActive() {
    let groupProfileID = id;
    var bodyFormData = new URLSearchParams();
    bodyFormData.append("type", "vip_group");
    bodyFormData.append("user_id", profile._id + "");
    bodyFormData.append("code", code);
    bodyFormData.append("object_id", groupProfileID);

    if (!code || code === null) {
      toast.error(t("Active Code can't not null"));
      return;
    }

    if (code?.length < 12 || !code?.includes("VDB")) {
      toast.error(t("Active Code not match"));
      return;
    }

    const status: any = await VipsService.activeCodeForProductPost(
      bodyFormData
    );

    if ("SUCCESS" === status) {
      const res = await GroupService.requestJoin({
        body: {
          to_id: dataUser?._id,
        },
      });
      if (res) {
        getRelation();
      }
      setCode("");
      toast.success(t("Unlock success!"));
      handleClose();
    } else {
      toast.error(t("Unlock fail! ".concat(t(status))));
    }
  }

  const Content = () => {
    return (
      <div className="active-container">
        <div className="justify-content-center">
          <div className="d-flex justify-content-center">
            <div className="d-flex relative text-center mt-4">
              <Input
                placeholder={t("Input code to unlock")}
                value={code}
                autoFocus
                className="rounded-sm py-1 px-3 text-center w-[100%]"
                onChange={(event) => setCode(event.target.value)}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center rounded-sm mt-4 mb-4">
            <Button onClick={handleActive}> {t("Active")}</Button>
          </div>
        </div>
      </div>
    );
  };

  const renderButtonFriend = () => {
    if (isMyProfile) {
      return;
    }
    const { fromFriend, toFriend } = relation;
    if (
      isUser(dataUser.object_type) ||
      isCommunity(dataUser.object_type) ||
      isBusiness(dataUser.object_type)
    ) {
      if (fromFriend && fromFriend.type === FRIEND_TYPE.FRIEND) {
        return (
          <VActionFriend
            type={FRIEND_ACTION.UNFRIEND}
            to_id={fromFriend?.to_id}
            reload={getRelation}
            isShowText={true}
          />
        );
      }
      if (fromFriend && fromFriend.type === FRIEND_TYPE.REQUEST_FRIEND) {
        return (
          <VActionFriend
            type={FRIEND_ACTION.CANCEL_REQUEST}
            to_id={fromFriend?.to_id}
            reload={getRelation}
            isShowText={true}
          />
        );
      }
      if (toFriend && toFriend.type === FRIEND_TYPE.REQUEST_FRIEND) {
        return (
          <VActionFriend
            type={FRIEND_ACTION.RESPONSE}
            isShowText={true}
            to_id={toFriend?.from_id}
            reload={getRelation}
          />
        );
      }
      return (
        <VActionFriend
          type={FRIEND_ACTION.REQUEST_FRIEND}
          isShowText={true}
          to_id={dataUser?._id}
          reload={getRelation}
        />
      );
    }
  };

  const showActiveCodeModal = () => {
    setOpen(true);
  };
  const renderButtonJoin = () => {
    if (isMyProfile) {
      return;
    }
    const { fromObject, toObject } = relation;
    // USER
    if (isBusiness(dataUser.object_type)) {
      if (fromObject && fromObject.type === FRIEND_TYPE.BUSINESS) {
        return (
          <VActionBusiness
            type={BUSINESS_ACTION.LEAVE_GROUP}
            to_id={fromObject?.to_id}
            reload={getRelation}
            isShowText={true}
          />
        );
      }
      if (fromObject && fromObject.type === FRIEND_TYPE.REQUEST_BUSINESS) {
        return (
          <VActionBusiness
            type={BUSINESS_ACTION.CANCEL_REQUEST}
            to_id={fromObject?.to_id}
            reload={getRelation}
            isShowText={true}
          />
        );
      }
      if (toObject && toObject.type === FRIEND_TYPE.INVITE_BUSINESS) {
        return (
          <VActionBusiness
            type={BUSINESS_ACTION.RESPONSE}
            to_id={toObject?.from_id}
            reload={getRelation}
            isShowText={true}
          />
        );
      }

      return (
        <VActionBusiness
          type={BUSINESS_ACTION.REQUEST_JOIN}
          to_id={dataUser?._id}
          reload={getRelation}
          isShowText={true}
        />
      );
    }

    if (isGroup(dataUser.object_type)) {
      if (fromObject && fromObject.type === FRIEND_TYPE.GROUP) {
        return (
          <VActionGroup
            type={GROUP_ACTION.LEAVE_GROUP}
            to_id={fromObject?.to_id}
            reload={getRelation}
            isShowText={true}
          />
        );
      }

      if (fromObject && fromObject.type === FRIEND_TYPE.REQUEST_GROUP) {
        return (
          <VActionGroup
            type={GROUP_ACTION.CANCEL_REQUEST}
            to_id={fromObject?.to_id}
            reload={getRelation}
            isShowText={true}
          />
        );
      }
      if (toObject && toObject.type === FRIEND_TYPE.INVITE_GROUP) {
        return (
          <VActionGroup
            type={GROUP_ACTION.RESPONSE}
            to_id={toObject?.from_id}
            reload={getRelation}
            isShowText={true}
          />
        );
      }
      if (checkVipGroup(dataUser) && !activedCode) {
        return (
          <>
            <VButton icon={<></>} onClick={(_) => showActiveCodeModal()}>
              {t("Join")}
            </VButton>
            <VModal
              isOpen={isOpen}
              title={t("Active to join vip group")}
              centered={true}
              onCancel={handleClose}
              showFooter={false}
              children={<Content />}
            />
          </>
        );
      }
      return (
        <VActionGroup
          type={GROUP_ACTION.REQUEST_JOIN}
          to_id={dataUser?._id}
          reload={getRelation}
          isShowText={true}
        />
      );
    }

    return <></>;
  };

  const renderButtonFollow = () => {
    const { fromFollow } = relation;
    if (isMyProfile || isGroup(dataUser.object_type)) {
      return;
    }
    if (fromFollow && fromFollow.type === FRIEND_TYPE.FOLLOW) {
      return (
        <VActionFriend
          type={FRIEND_ACTION.UNFOLLOW}
          isShowText={true}
          to_id={fromFollow?.to_id}
          reload={getRelation}
        />
      );
    }
    return (
      <VActionFriend
        type={FRIEND_ACTION.FOLLOW}
        to_id={dataUser?._id}
        reload={getRelation}
        isShowText={true}
      />
    );
  };

  const renderButtonMessage = () => {
    if (
      isMyProfile ||
      isPage(dataUser.object_type) ||
      isGroup(dataUser.object_type)
    ) {
      return;
    }
    return (
      <VActionFriend
        type={FRIEND_ACTION.CHAT}
        to_id={dataUser?._id}
        reload={getRelation}
        isShowIcon={true}
        isShowText={false}
      />
    );
  };

  const renderButtonChatV2 = () => {
    if (isMyProfile) {
      // isPage(dataUser.object_type) ||
      // isGroup(dataUser.object_type)
      return;
    }

    const { fromFriend } = relation;

    if (fromFriend && fromFriend.type === FRIEND_TYPE.FRIEND) {
      return (
        <VActionFriend
          type={FRIEND_ACTION.CONVERSATION}
          to_id={dataUser?._id}
          isShowIcon={true}
          isShowText={false}
        />
      );
    }
    return;
  };

  return (
    <div className="social-info">
      <div className="action">
        {!isMyProfile && (
          <div className="d-flex justify-content-end mb-2">
            {" "}
            <div className="me-2">{renderButtonFriend()}</div>
            <div className="me-2">{renderButtonJoin()}</div>
            <div className="me-2">{renderButtonFollow()}</div>
            {enableChat() ? (
              <PermissionsGate
                userLoggined={profile._id}
                key_permission="message"
                relation={relation}
              >
                <div className="d-flex">{renderButtonChatV2()}</div>
              </PermissionsGate>
            ) : (
              <div className="d-flex me-2">{renderButtonMessage()}</div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default SocialActionProfile;

import React, { useEffect, useState } from "react";

// //header
import Header from "pages/home-v2/layouts/HeaderStyle/header";

// //sidebar
// import RightSidebar from "./layouts/SidebarStyle/rightsidebar";

// //sidebar
import Sidebar from "pages/home-v2/layouts/SidebarStyle/sidebar";

// //footer
import Footer from "pages/home-v2/layouts/FooterStyle/footer";

// //default

// // share-offcanvas
// // import ShareOffcanvas from '../../components/share-offcanvas'
// import Quota from "components/v-quota";

// //settingoffCanvas
// import SettingOffCanvas from "components/setting/SettingOffCanvas";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { setConnectSocket } from "redux/reducers/users/users.reducer";
import {
  getAccountAddressAndCareerAsync,
  getBalancesAsync,
  getDataAccessByOwnerAsync,
  getUserOnlineAsync,
} from "redux/reducers/users/users.actions";
import {
  getIsLoggedIn,
  getUserProfile,
  //   getDataUser,
} from "redux/reducers/users/users.selector";
// import Contribute from "components/setting/Contribute";
import Maintenance from "pages/maintenance/maintenance";
import { OBJECT_TYPE } from "config/enums";
import { getMenusAsync } from "redux/reducers/settings/settings.action";
// import { isMobile } from "react-device-detect";
// import BannerApp from "layouts/Banner";
// import { useLocation } from "react-router-dom";
import {
  getGeneral,
  //   getIsApp,
} from "redux/reducers/settings/settings.selector";
import WebModule from "modals/web-modules";
import FeedHome from "pages/home-v2/layouts/FeedHome";
import LeftAndRightSidebar from "pages/home-v2/layouts/LeftAndRightSidebar";
// import { Card } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import withAuth from "HOCs/withAuth";
import { RiArrowUpSLine } from "react-icons/ri";
import useTranslation from "hooks/useTranslation";
import PageBackgroundsHomeV2 from "./page-background";

const HomeV2 = () => {
  const dispatch = useAppDispatch();
  const isLogined = useAppSelector(getIsLoggedIn);
  const profile = useAppSelector(getUserProfile);
  const { t } = useTranslation();

  //   const dataUser = useAppSelector(getDataUser);
  //   const location = useLocation();

  const general = useAppSelector(getGeneral);

  const [menuActive, setMenuActive] = useState("home_v2");

  //   const isConversation =
  //     String(location?.pathname)?.includes("profile-chat") ||
  //     String(location?.search)?.includes("?isChat") ||
  //     dataUser.object_type === "CHAT";

  const [maintenance, setMaintenance] = useState(false);

  const [isExpanedHomeFeed, setExpanedHomeFeed] = useState(false);

  const [expanedLeftRightBarKey, setExpanedLeftRightBarKey] = useState(
    isMobile ? "" : "both"
  );

  //   const isApp = useAppSelector(getIsApp);

  const getMetaSetting = async () => {
    try {
      if (general) {
        if (profile.object_type !== OBJECT_TYPE.COMMUNITY) {
          setMaintenance(general?.maintenance || false);
        } else {
          setMaintenance(false);
        }
      }
    } catch (error) {}
  };

  const onClickMenu = (key: string) => {
    if (key === "gif") {
      setExpanedLeftRightBarKey("right");
    } else {
      setMenuActive(key);
    }
  };

  useEffect(() => {
    getMetaSetting();
  }, [general, isLogined]);

  useEffect(() => {
    if (isLogined && profile && profile._id) {
      dispatch(getMenusAsync());
      dispatch(setConnectSocket(true));
      dispatch(getAccountAddressAndCareerAsync());
      dispatch(getDataAccessByOwnerAsync());
      dispatch(getUserOnlineAsync());
      dispatch(
        getBalancesAsync({
          params: {
            id: profile._id,
          },
        })
      );
    }
  }, [isLogined]);

  return (
    <div className="home-v2">
      <PageBackgroundsHomeV2 />

      <Header />

      {/* <Card className="quota-desktop d-none d-lg-block overflow-hidden m-2">
        <Quota />
      </Card> */}
      <Sidebar />
      {/* <div className="main-content"> */}
      {/* <div id="content-page" className="content-page"> */}
      {maintenance ? (
        <Maintenance />
      ) : (
        <div className="home-v2-feed">
          {menuActive === "news_feed" && (
            <WebModule
              openWebModuleKey={"news_feed"}
              page={"events"} // force to events because no need to modal
            />
          )}

          <div className={["home_v2"].includes(menuActive) ? "" : "hide-tmp"}>
            <WebModule
              openWebModuleKey="home-v2"
              page={"events"} // force to events because no need to modal
            />
          </div>
        </div>
      )}

      <div
        className="text-expand lineUp"
        onClick={() => setExpanedHomeFeed(true)}
      >
        <div className="d-flex justify-content-center align-items-center">
          <RiArrowUpSLine size={16} />
          <span className="ms-1">
            {t("Cập nhật thông tin cuộc sống, giải trí, kinh doanh...")}
          </span>
        </div>
      </div>

      {["home_v2"].includes(menuActive) && (
        <FeedHome
          isExpaned={isExpanedHomeFeed}
          setExpaned={setExpanedHomeFeed}
        />
      )}

      <LeftAndRightSidebar
        expanedLeftRightBarKey={expanedLeftRightBarKey}
        setExpanedLeftRightBarKey={setExpanedLeftRightBarKey}
      />

      {/* <RightSidebar /> */}
      <Footer onClickMenu={onClickMenu} />
    </div>
  );
};

export default withAuth(HomeV2, {
  noRedirect: true,
});

import { Card } from "react-bootstrap";
import { Checkbox } from "antd";
import { getUrlImage } from "utils/common";

type IProps = {
  id: string;
  label?: string;
  name?: string;
  type?: string;
  value?: any;
  required?: boolean;
  options: Option[];
  error?: string;
  field?: any;
  image?: string;
};

interface Option {
  label: string;
  value: string;
  disabled?: boolean;
}

const View = ({
  id = "",
  label = "",
  name = "",
  value = "",
  options = null,
  required = false,
  error,
  field,
  image,
}: IProps) => {
  return (
    <Card border="success" className="mb-4 question_item_card" id={id}>
      {label && (
        <p className="text-sm mb-2">
          <b>{label}</b> {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div>
        {image && (
          <img
            style={{
              height: "200px",
              width: "auto",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
            src={getUrlImage(image, 1000)}
            alt="sub"
          />
        )}
      </div>
      <Checkbox.Group
        name={name}
        defaultValue={value}
        options={options}
        {...field}
      />
      {error && <p className="_error mb-0">{error}</p>}
    </Card>
  );
};

export default View;

import { parseErrorResponse, request, IRequestData } from "./request";

const getQuestions = (id: any) => {
  return new Promise((resolve, reject) => {
    request()
      .get(`/question-and-answers/${id}`)
      .then(res => {
        const { data } = res.data;

        if (data) {
          resolve(data)
        } else {
          reject(new Error('Something went error!'))
        }
      })
      .catch(parseErrorResponse)
      .then(reject)
  })
}

const answerQuestion = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/answers`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const QuestionsService = {
  getQuestions,
  answerQuestion
}

export default QuestionsService
import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getProducts = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/products/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInfoCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/codes/check-code/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getProductById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/products/${req.product_id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getProductRelated = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/products/related?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getProductInStorageByType = (req: { query: any }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/storages/product-in-storage-by-type?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCategoriesByTradeMark = (req: { query: any }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/categories/getByTradeMark?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ProductService = {
  getProducts,
  getInfoCode,
  getProductById,
  getProductRelated,
  getProductInStorageByType,
  getCategoriesByTradeMark,
};

export default ProductService;

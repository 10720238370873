/* eslint-disable jsx-a11y/iframe-has-title */
import VWebView from "components/v-web-view";
interface IProps {
  url: string,
  widthScrollbar?: string;
  minHeight?: string;
}


const WebViewFeed = (props: IProps) => {
  const { url, widthScrollbar } = props;
  return (
    <VWebView
      className="iframe-webview-home"
      url={url}
      widthScrollbar={widthScrollbar}
    />
  );
};

export default WebViewFeed;

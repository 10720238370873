/* eslint-disable array-callback-return */
import { useState, useEffect } from "react";
import VForm from "components/v-form";
import FromTemplateService from "api/form-templates";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";
import LoansService from "api/loans";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import APIs from "api";
import { toast } from "react-toastify";
import { LOANS_TYPE } from "config/enums";
import { Form } from "react-bootstrap";
import VInput from "components/v-input";
import SelectRegions from "components/v-location";

const View = () => {
  const { t } = useTranslation();
  const userData = useAppSelector(getUserProfile);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    amount: 0,
    address: {
      country: '',
      province: '',
      district: '',
      ward: '',
    },
  });
  const [fieldAdditionalData, setFieldAdditionalData] = useState(null);
  const [additionalData, setAdditionalData] = useState({});
  const [listFiles, setListFiles] = useState<any>({});
  const [validate, setValidate] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let listImg;
      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${userData?._id}`);

        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
      }
      let data = {
        ...additionalData,
      };

      if (listImg && listImg?.length > 0) {
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              data = {
                ...data,
                [item]: el?.filePath,
              };
            }
          });
        });
      }

      const res = await LoansService.sendLoansByType({
        type: LOANS_TYPE.LOANS,
        body: {
          ...state,
          additionalData: {
            ...data
          }
        },
      });
      if (res) {
        setLoading(false);
        setListFiles({});
        setAdditionalData({});
        toast.success(t("Success"));
      }
    } catch (error) {
      setLoading(false);
      toast.success(t(error.errors));
    }
  };

  const handleChangeFields = (value, name) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const getFormTemplateLoans = async () => {
    try {
      const res: any = await FromTemplateService.getForm({
        query: {
          type: "loans",
          key: "loans",
        },
      });
      if (res) {
        setFieldAdditionalData(res);
      }
    } catch (error) {
      setFieldAdditionalData([]);
    }
  };

  useEffect(() => {
    getFormTemplateLoans();
  }, []);

  return (
    <>
      <div className="row">
        <Form.Group className={`form-group col-md-12`}>
          <VInput
            label={"The amount you need to borrow"}
            id={"amount"}
            type={"number"}
            required={true}
            name={"amount"}
            value={state.amount}
            onChange={(e) => handleChangeFields(e.target.value, "amount")}
          />
        </Form.Group>
        <Form.Group className="form-group col-12">
          <SelectRegions
            styleProps={{
              col: "col-12",
            }}
            required={true}
            hideLabel={true}
            isDisabled={false}
            address={state.address}
            setAddress={(e) => handleChangeFields(e, "address")}
          />
        </Form.Group>
        <VForm
          colItem={"col-12 col-md-6"}
          fields={fieldAdditionalData?.fields}
          additionalData={additionalData}
          listFiles={listFiles}
          validate={validate}
          setListFiles={setListFiles}
          setValidate={setValidate}
          setIsValid={setIsValid}
          setFile={setFile}
          setAdditionalData={setAdditionalData}
        />
      </div>
      <VButton
        loading={loading}
        height={"36px"}
        className="btn btn-primary me-2"
        disabled={!isValid || !state.address.country || state.amount <= 0}
        onClick={handleSubmit}
      >
        {t("Save")}
      </VButton>
    </>
  );
};

export default View;

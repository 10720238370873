/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable max-len */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
import VButton from "components/v-button";
import React, { useEffect, useState } from "react";
import { Card, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom";
// import useTranslation from "hooks/useTranslation";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
// import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import VIcon from "components/v-icon";
import VBanner from "components/v-banner";
import APIs from "api";
import { checkValidUrlFB } from "utils/convertIdVideo";
import { getUrlImage } from "utils/common";

const WelcomeModalCenter = (props) => {
  // const { t } = useTranslation();
  const history = useHistory();
  const { data, isPopupProfile } = props;

  const [open, setOpen] = useState(true);
  const userData: any = useAppSelector(getUserProfile);
  // const menus: any = useAppSelector(getMenusByKey("welcome"));
  // const menuWelcome = menus.find((el) => el.key === "welcome");
  const [banners, setBanners] = useState(null);

  useEffect(() => {
    if (data && data.type) {
      const custum_data = {
        facebook:
          data.type === "link" && checkValidUrlFB(data.url) ? data.url : "",
        images:
          data.type === "slide"
            ? data.slide
            : data.type === "image"
            ? [{ src: data.url }]
            : "",
        video: data.type === "video" ? data.url : "",
        youtube:
          data.type === "link" && !checkValidUrlFB(data.url) ? data.url : "",
        description: data.description || "",
        name: data.title || "",
      };
      setBanners([custum_data]);
    }
  }, [data]);

  const navigateUrl = (url) => {
    setOpen(false);

    if (url?.includes(window.origin)) {
      history.push(url?.split(window.origin)?.[1]);
    } else {
      window.open(url);
    }
  };

  const getBanners = async () => {
    try {
      if (isPopupProfile) return;
      const data: any = await APIs.BANNERS.getsByPosition({
        params: {
          position: "banner_welcome",
        },
      });
      if (data && data.length > 0) {
        setBanners(data);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getBanners();
  }, []);

  return (
    <div>
      {banners && (
        <Modal
          className="welcome_modal"
          id="post-modal"
          centered
          onHide={() => setOpen(false)}
          show={open}
        >
          <Card className="m-0">
            <div className="banner-wrapper">
              <div
                className="close-modal-welcome"
                onClick={() => setOpen(false)}
              >
                <VIcon name="close.svg" />
              </div>
              <div className="">
                <VBanner
                  data={banners}
                  isPopupProfile={isPopupProfile}
                  position={"banner_welcome"}
                />
              </div>
              <div className="content_welcome">
                <h5 className="title_welcome">
                  {banners[0]?.name && (
                    <h3 className="text-primary mt-2 mb-1">
                      {banners[0]?.name}
                    </h3>
                  )}
                  {banners[0]?.description && (
                    <span
                      className="my-2"
                      onClick={() =>
                        navigateUrl(
                          banners[0]?.link || `/profile/${userData?.id}`
                        )
                      }
                    >
                      {banners[0]?.description}
                    </span>
                  )}
                </h5>
                {isPopupProfile && data?.action_button?.length > 0 ? (
                  <div className="d-flex justify-content-center mb-2">
                    {data?.action_button.map((el) => (
                      <>
                        {el?.type === "image" ? (
                          <div
                            className="cursor-pointer px-3"
                            onClick={() =>
                              navigateUrl(
                                el?.link ||
                                  `${window?.origin}/profile/${userData?.id}`
                              )
                            }
                          >
                            <img
                              height={37}
                              width={"auto"}
                              src={getUrlImage(el?.button_image)}
                              alt=""
                            />
                          </div>
                        ) : (
                          <VButton
                            key={el.id}
                            type="button"
                            style={{
                              color: el.color,
                              backgroundColor: el.background_color,
                              borderColor: el.background_color,
                            }}
                            className="btn btn-primary mx-1"
                            onClick={() =>
                              navigateUrl(
                                el?.link ||
                                  `${window?.origin}/profile/${userData?.id}`
                              )
                            }
                          >
                            <span className="fw-bold">{el?.name}</span>
                          </VButton>
                        )}
                      </>
                    ))}
                  </div>
                ) : (
                  banners[0]?.action_href?.length > 0 && (
                    <div className="d-flex justify-content-center mb-2">
                      {banners[0]?.action_href?.map((el) => (
                        <>
                          {el?.type === "image" ? (
                            <div
                              className="cursor-pointer px-3"
                              onClick={() =>
                                navigateUrl(
                                  el?.link ||
                                    `${window?.origin}/profile/${userData?.id}`
                                )
                              }
                            >
                              <img
                                height={37}
                                width={"auto"}
                                src={getUrlImage(el?.button_image)}
                                alt=""
                              />
                            </div>
                          ) : (
                            <VButton
                              key={el.id}
                              type="button"
                              style={{
                                color: el.text_color,
                                backgroundColor: el.background_color,
                                borderColor: el.background_color,
                              }}
                              className="btn btn-primary mx-1"
                              onClick={() =>
                                navigateUrl(
                                  el?.link ||
                                    `${window?.origin}/profile/${userData?.id}`
                                )
                              }
                            >
                              <span className="fw-bold">{el?.button_name}</span>
                            </VButton>
                          )}
                        </>
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          </Card>
        </Modal>
      )}
    </div>
  );
};

export default WelcomeModalCenter;

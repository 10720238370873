/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile, getVerify } from "redux/reducers/users/users.selector";
import KYC from "./Kyc";
// import Verify from "./Verify";
import { BsFillInfoCircleFill } from "react-icons/bs";
import useTranslation from "hooks/useTranslation";
import Card from "components/v-teamplate/Card";
import VAvatar from "components/v-avatar";
import VButton from "components/v-button";
import VTimeline from "components/v-timeline";
import FromTemplateService from "api/form-templates";

const Verification = () => {
  const defaultState = {
    status: "",
    note: "",
  };
  const verify = useAppSelector(getVerify);
  const user = useAppSelector(getUserProfile);

  const [status, setStatus] = useState("init");
  const [percent, setPercent] = useState(50);
  const [stepCurrent, setStepCurrent] = useState(null);
  const [kyc, setKYC] = useState(defaultState);
  const [formAdditionalDataKYC, setFormAdditionalDataKYC] = useState<any>(null);
  const [formAdditionalDataVerify, setFormAdditionalDataVerify] =
    useState<any>(null);

  const { t } = useTranslation();
  const method = status === "init" ? "kyc" : "verify";

  useEffect(() => {
    if (verify && verify.status !== "declined") {
      setStatus(verify.status);
      if (verify.status === "verified") {
        setPercent(100);
      }
      if (
        verify.status === "kyc" ||
        verify.status === "pending_verified" ||
        verify.status === "declined_verified"
      ) {
        setPercent(75);
      }
    }
    setKYC(verify);
  }, [verify]);

  useEffect(() => {
    getFormTemplateKyc();
    getFormTemplateVerify();
  }, [user.object_type]);

  const handleClick = () => {
    setStepCurrent(status);
  };

  const getFormTemplateKyc = async () => {
    try {
      const res: any = await FromTemplateService.getForm({
        query: {
          type: "kyc",
          object_type: user.object_type,
        },
      });
      if (res) {
        setFormAdditionalDataKYC(res);
      }
    } catch (error) {
      setFormAdditionalDataKYC(null);
    }
  };

  const getFormTemplateVerify = async () => {
    try {
      const res: any = await FromTemplateService.getForm({
        query: {
          type: "verify",
          object_type: user.object_type,
        },
      });
      if (res) {
        setFormAdditionalDataVerify(res);
      }
    } catch (error) {
      setFormAdditionalDataVerify(null);
    }
  };

  const getColorVerify = (type, status) => {
    if (type === "verify")
      switch (status) {
        case "pending_verified":
          return "pending";
        case "declined_verified":
          return "declined";
        case "verified":
          return "success";
        default:
          return "gray";
      }
    else
      switch (status) {
        case "pending_kyc":
          return "pending";
        case "kyc":
          return "success";
        case "pending_verified":
          return "success";
        case "verified":
          return "success";
        case "declined_verified":
          return "success";
        default:
          return "gray";
      }
  };

  const renderListRequest = (form) => {
    return (
      form &&
      form.fields?.length > 0 &&
      form.fields?.map((el, index) => <li key={index}>{t(el.label)}</li>)
    );
  };

  const getInfoStep = (step) => {
    switch (step) {
      case 0:
        return {
          title: t("Verified"),
          bgColorTime: "#3498db33",
          colorTime: "#4284F3",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">
                    {t("Update personal information")}
                  </h6>
                  <span className="fw-600">- {t("Verified")}:</span>
                  <ul>
                    <li>{t("Personal information")}</li>
                    <li>{t("Personal address")}</li>
                    <li>{`${t("Personal")} ${t("Business")}`}</li>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      case 1:
        return {
          title: t("Verified"),
          bgColorTime: "#3498db33",
          colorTime: "#4284F3",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">
                    {t("Verify email and phone number")}
                  </h6>
                  <span className="fw-600">- {t("Verified")}:</span>
                  <ul>
                    <li>{t("Number phone")}</li>
                    <li>{t("Email")}</li>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      case 2:
        const isKyc =
          status !== "init" &&
          status !== "pending_kyc" &&
          status !== "declined";
        return {
          title: isKyc ? t("Verified") : t("Not verified"),
          bgColorTime: isKyc ? "#3498db33" : "",
          colorTime: isKyc ? "#4284F3" : "",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">{t("kyc_information")}</h6>
                  <span className="fw-600">
                    - {status === "kyc" ? t("Verified") : t("Request")}:
                  </span>
                  <ul>{renderListRequest(formAdditionalDataKYC)}</ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      case 3:
        const isVerify = status === "verified";
        return {
          title: isVerify ? t("Verified") : t("Not verified"),
          bgColorTime: isVerify
            ? "#3498db33"
            : status === "declined_verified"
            ? "#ff7a7a80"
            : "",
          colorTime: isVerify
            ? "#4284F3"
            : status === "declined_verified"
            ? "#e93d3df0"
            : "",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">{t("Authentication used")}</h6>
                  <span className="fw-600">
                    - {status === "verified" ? t("Verified") : t("Request")}:
                  </span>
                  <ul>{renderListRequest(formAdditionalDataVerify)}</ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      default:
        return {
          title: "",
          children: <></>,
        };
    }
  };

  return (
    <Card isOpacity={true}>
      <Card.Header className="card-header d-flex justify-content-between information-header-setting">
        <div className="information-user">
          <VAvatar
            className="m-0"
            size={"70px"}
            fontSize={"22px"}
            fontWeight={"600"}
            userData={user}
            isEdit={false}
            additionalData={
              <div className="status-verify-account">
                <span className="me-2">{t(status)}</span>
                <img
                  src={`/assets/icons/default/verify-${getColorVerify(
                    status !== "pending_verified" &&
                      status !== "declined_verified" &&
                      status !== "verified"
                      ? "kyc"
                      : "verify",
                    status
                  )}.svg`}
                  alt=""
                />
              </div>
            }
          />
        </div>
        <div
          className="title-circular"
          style={{ width: "70px", height: "100%" }}
        >
          <CircularProgressbar
            value={percent}
            text={`${percent}%`}
            styles={buildStyles({
              textColor: percent === 100 ? "#00AC11" : "#FFE600",
              pathColor: "#4284F3",
              textSize: "20px",
            })}
          />
        </div>
      </Card.Header>
      <Card.Body>
        <div className="verification-wrapper">
          {!stepCurrent && (
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-12 col-md-6 mb-4 content-kyc-request">
                {status !== "verified" ? (
                  <>
                    <span>{t("Request")}:</span>
                    <ul>
                      {renderListRequest(
                        method === "kyc"
                          ? formAdditionalDataKYC
                          : formAdditionalDataVerify
                      )}
                    </ul>
                  </>
                ) : (
                  <div>{t("Congratulations verify")}</div>
                )}
                <div className="step">
                  {status !== "verified" && status !== "pending_verified" && (
                    <VButton
                      disabled={status === "pending_kyc"}
                      onClick={handleClick}
                    >
                      {t("Start")}
                    </VButton>
                  )}
                  {(kyc?.status === "declined" ||
                    kyc?.status === "declined_verified") &&
                    kyc?.note && (
                      <div className="note border rounded p-2">
                        <BsFillInfoCircleFill color="yellow" />
                        {t("Note")}:
                        <div
                          className="mb-0"
                          dangerouslySetInnerHTML={{
                            __html: kyc?.note,
                          }}
                        ></div>
                      </div>
                    )}
                </div>
              </div>
              <div className="px-1 col-12 col-md-6">
                <VTimeline
                  data={Array(4)
                    .fill(0)
                    .map((el, index) => ({
                      icon: (
                        <img
                          src={`/assets/icons/default/verify-${
                            index === 0 || index === 1
                              ? "success"
                              : getColorVerify(
                                  index === 2 ? "kyc" : "verify",
                                  status
                                )
                          }.svg`}
                          alt=""
                        />
                      ),
                      ...getInfoStep(index),
                    }))}
                />
              </div>
            </div>
          )}
          {(stepCurrent === "init" ||
            stepCurrent === "kyc" ||
            stepCurrent === "declined_verified") && (
            <KYC
              stepAction={method}
              formAdditionalData={
                method === "kyc"
                  ? formAdditionalDataKYC
                  : formAdditionalDataVerify
              }
              setStepCurrent={setStepCurrent}
            />
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export default Verification;

/* eslint-disable array-callback-return */
/* eslint-disable indent */
import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
import queryString from "query-string";

import Card from "components/v-teamplate/Card";
import FromSettingBusiness from "./components/business/FormSettingBusiness";
import FromSettingProfile from "./components/user/FormSettingProfile";
import PreviewBusiness from "./components/business/PreviewBusiness";
import PreviewProfile from "./components/user/PreviewProfile";

import {
  isBusiness,
  isChannel,
  isCommunity,
  isGroup,
  isPage,
  isUser,
} from "utils/friends";
import VIcon from "components/v-icon";
import FormSettingGroup from "./components/groups/FormSettingGroup";
import PreviewGroup from "./components/groups/PreviewGroup";
import FormSettingPage from "./components/pages/FormSettingPage";
import PreviewPage from "./components/pages/PreviewPage";
import FormSettingChannel from "./components/channel/FormSettingChannel";
import PreviewChannel from "./components/channel/PreviewChannel";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  isMyProfile: any;
};

const View = (props: IProps) => {
  const { isAuth, dataUser, isMyProfile } = props;

  const location = useLocation();

  const history = useHistory();
  const { t } = useTranslation();

  const isEdit = queryString.parse(location.search)?.isEdit;

  const handleAction = () => {
    const search = new URLSearchParams(window.location.search);
    if(isEdit){
      search.delete("isEdit")
    } else {
      search.set("isEdit", `true`)
    }
    history.push(`?${search}`);
  };

  return (
    <Card>
      <Card.Body className="position-relative">
        {dataUser?.user_setting?.post?.profile === "only_me" && !isAuth ? (
          <div className="text-center">
            {t("You cannot view this person's information.")}
          </div>
        ) : (
          <>
            <div className="position-absolute top-0 end-0 mx-2 my-1 d-flex justify-content-end">
              {isAuth && (
                <div className=" cursor-pointer" onClick={handleAction}>
                  {isEdit ? (
                    <VIcon name="cancel.svg" width={20} height={16} />
                  ) : (
                    <VIcon name="edit-page.svg" />
                  )}
                </div>
              )}
            </div>
            {isEdit ? (
              <>
                {isBusiness(dataUser?.object_type) && (
                  <FromSettingBusiness
                    data={dataUser}
                    isMyProfile={isMyProfile}
                  />
                )}
                {(isUser(dataUser?.object_type) ||
                  isCommunity(dataUser?.object_type)) && (
                  <FromSettingProfile
                    data={dataUser}
                    isMyProfile={isMyProfile}
                  />
                )}
                {isGroup(dataUser?.object_type) && (
                  <FormSettingGroup
                    data={dataUser}
                    isMyProfile={isMyProfile}
                  />
                )}
                {isPage(dataUser?.object_type) && (
                  <FormSettingPage
                    data={dataUser}
                    isMyProfile={isMyProfile}
                  />
                )}
                 {isChannel(dataUser?.object_type) && (
                  <FormSettingChannel
                    data={dataUser}
                    isMyProfile={isMyProfile}
                  />
                )}
              </>
            ) : (
              <div className="acc-edit">
                <div className="user-account">
                  {isBusiness(dataUser?.object_type) && (
                    <PreviewBusiness userData={dataUser} isAuth={isAuth} />
                  )}
                  {(isUser(dataUser?.object_type) ||
                    isCommunity(dataUser?.object_type)) && (
                    <PreviewProfile userData={dataUser} isAuth={isAuth} />
                  )}
                  {isGroup(dataUser?.object_type) && (
                    <PreviewGroup userData={dataUser} isAuth={isAuth} />
                  )}
                  {isPage(dataUser?.object_type) && (
                    <PreviewPage userData={dataUser} isAuth={isAuth} />
                  )}
                  {isChannel(dataUser?.object_type) && (
                    <PreviewChannel userData={dataUser} isAuth={isAuth} />
                  )}
                  {Object.keys(dataUser?.profile?.additionalData || {})?.map(
                    (value, i) => (
                      <div key={i} className={dataUser?.profile?.additionalData?.[value]?.html ? "row-editor" : "row-account"}>
                        <div className="label">{t(value)}</div>
                        <div className="content">
                          {dataUser?.profile?.additionalData?.[value]?.html ? (
                            <div
                              dangerouslySetInnerHTML={{
                                __html: dataUser?.profile?.additionalData?.[value]?.html
                              }}
                            ></div>
                          ) : (
                            dataUser?.profile?.additionalData?.[value]
                          )}
                        </div>
                      </div>
                    )
                  )}
                </div>
              </div>
            )}
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default View;

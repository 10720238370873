/* eslint-disable react-hooks/exhaustive-deps */
import withAuth from "HOCs/withAuth";
import WebModulesIframe from "components/iframe-web-module";
// import { WebModulesIframeStyled } from "components/iframe-web-module/styles";
import { WEB_MODULE_URL } from "config/env";
import Header from "layouts/dashboard/HeaderStyle/header";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

import queryString from "query-string";

import { v4 as uuidv4 } from "uuid";

const ChatDemo = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const location = useLocation();
  const query = queryString.parse(location?.search);

  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);

  const dispatch = useDispatch();

  const { i18n } = useTranslation();

  const params = {
    origin: window.origin,
    vdb_token: localStorage.getItem("auth"),
    moduleSessionId,
    fromSessionUid,
    lang: i18n.language ? `${i18n.language}` : "",
    ...query,
  };

  const url = `${WEB_MODULE_URL}/chat-demo?${queryString.stringify(params)}`;

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  return (
    <div>
      <Header />

      <WebModulesIframe
        url={url}
        title="web modules modal"
        className="iframe-chat"
        loading={loading}
        onLoad={() => setLoading(false)}
      />
    </div>
  );
};

export default withAuth(ChatDemo);

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import Table from "components/v-table";

import APIs from "api";
import { useEffect, useState } from "react";
import { renderAmount } from "utils/render";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import useQuery from "hooks/useQuery";

const View = (props) => {
  const { t } = useTranslation();
  const [tab, setTab] = useState<string>("deposit");
  const [dataHistory, setDataHistory] = useState<any>([]);
  const [dataLocks, setDataLocks] = useState([]);
  const { stock } = useQuery();

  const columns = [
    tab === "deposit" && {
      title: t("From"),
      dataIndex: "from",
      key: "from",
    },
    tab === "withdraw" && {
      title: t("To"),
      dataIndex: "to",
      key: "to",
      render: (data: any) => <span>{data.to.slice(0, 20)}...</span>,
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <span className="orange">{renderAmount(data.amount)}</span>
      ),
    },
    {
      title: t("Amount Locked"),
      dataIndex: "amountLocked",
      key: "amountLocked",
      render: (data: any) => (
        <span className="orange">{renderAmount(data.amountLocked)}</span>
      ),
    },
    {
      title: t("Amount Received"),
      dataIndex: "amountReceived",
      key: "amountReceived",
      render: (data: any) => (
        <span className="orange">
          {renderAmount(data.amountReceived || data.amount)}
        </span>
      ),
    },
    tab === "changes" && {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    tab !== "changes" && {
      title: t("Fee"),
      dataIndex: "fee",
      key: "fee",
      render: (data: any) => (
        <>
          <span className="orange">{data.fee.amount}</span> (
          {String(data.fee.symbol || "").toUpperCase()})
        </>
      ),
    },
    tab === "withdraw" && {
      title: t("VAT"),
      dataIndex: "VAT",
      key: "VAT",
      render: (data: any) => (
        <>
          <span className="orange">{data?.VAT || 0}</span>
        </>
      ),
    },
    {
      title: t("Status"),
      dataIndex: "status",
      key: "status",
      render: (data: any) => (
        <span
          className={`${
            data.status === "success"
              ? "success"
              : data.status === "pending"
              ? "pending"
              : "declined"
          }`}
        >
          {data.status}
        </span>
      ),
    },
    {
      title: t("Note"),
      dataIndex: "description",
      key: "description",
    },
    {
      title: t("Created at"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString()}</span>
      ),
    },
    {
      key: "hash",
      title: t("Hash"),
      render: (item: any) => (
        <a
          href={`${
            process.env.REACT_APP_VZX_SCAN || "https://block.vzonex.com"
          }/tx/${item.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.hash?.slice(0, 10)}...
        </a>
      ),
    },
    {
      key: "invoice",
      title: t("Invoice"),
      render: (item: any) => (
        <a
          href={`/invoice/transactions/${tab}/${item._id}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {t("View")}
        </a>
      ),
    },
  ];

  const columnsLock = [
    {
      title: t("Type"),
      dataIndex: "type",
      key: "type",
    },
    {
      title: t("Amount"),
      dataIndex: "amount",
      key: "amount",
      render: (data: any) => (
        <span className="orange">{renderAmount(data.amount)}</span>
      ),
    },
    {
      title: t("Created at"),
      dataIndex: "createdAt",
      key: "createdAt",
      render: (data: any) => (
        <span>{new Date(data.createdAt).toLocaleString()}</span>
      ),
    },
  ];

  const getHistory = async () => {
    try {
      if (tab) {
        if (tab === "locks") {
          const res: any = await APIs.TRANSACTION.getLocks({
            params: {
              stock: stock,
            },
          });
          if (res && res.content) {
            setDataLocks(res.content);
          } else setDataLocks([]);
        } else {
          const res: any = await APIs.TRANSACTION.getTransactions({
            query: {
              stock: stock,
              type: tab,
            },
          });
          setDataHistory(res?.transactions);
        }
      } else {
        setDataHistory([]);
        setDataLocks([]);
      }
    } catch (error) {
      setDataHistory([]);
      setDataLocks([]);
    }
  };

  useEffect(() => {
    if (stock) getHistory();
  }, [tab, stock]);

  return (
    <Card isOpacity={true}>
      <div className="p-3">
        <div>
          <h3>{t("History")}</h3>
        </div>
        <div className="tabs">
          <div
            className={`tab ${tab === "deposit" ? "active" : ""}`}
            onClick={() => setTab("deposit")}
          >
            {t("Deposit")}
          </div>
          <div
            className={`tab ${tab === "withdraw" ? "active" : ""}`}
            onClick={() => setTab("withdraw")}
          >
            {t("Withdraw")}
          </div>
          <div
            className={`tab ${tab === "changes" ? "active" : ""}`}
            onClick={() => setTab("changes")}
          >
            {t("Changes")}
          </div>
          <div
            className={`tab ${tab === "locks" ? "active" : ""}`}
            onClick={() => setTab("locks")}
          >
            {t("Lock And Unlock")}
          </div>
        </div>
        <div className="history-transfer">
          <div>
            {tab === "locks" ? (
              <Table
                columns={columnsLock.filter((a) => a)}
                dataSource={dataLocks}
                bgTable={"grey200"}
              ></Table>
            ) : (
              <Table
                columns={columns.filter((a) => a)}
                dataSource={dataHistory}
                bgTable={"grey200"}
              ></Table>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default View;

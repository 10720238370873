import { request, parseErrorResponse } from "./request";

const findOneByOption = (req: { body: any }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/apply-captchas/by-options`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CaptchaService = {
  findOneByOption,
};

export default CaptchaService;

/* eslint-disable jsx-a11y/iframe-has-title */
import useQuery from "hooks/useQuery";
import AuthLayout from "layouts/AuthLayout";
import Login from "pages/auth/login";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const ShareUrl = () => {
  const { i18n } = useTranslation();
  const token = localStorage.getItem("auth");
  const query = useQuery();
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(token){
      setShow(true);
    } else {
      setShow(false);
    }
  }, [token]);

  return (
    <div className="share-url-wrapper">
      {show ? (
        <iframe
          src={`${process.env.REACT_APP_WEB_MODULE_URL}/share?vdb_token=${token}&url=${query?.url}&lang=${
            i18n.language ? `${i18n.language}` : ""
          }`}
          width={"1200px"}
          height={"auto"}
          style={{
            minHeight: "1200px"
          }}
        ></iframe>
      ) : (
        <AuthLayout>
          <Login />
        </AuthLayout>
      )}
    </div>
  );
};

export default ShareUrl;

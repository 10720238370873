import { createAsyncThunk } from "@reduxjs/toolkit";
import { getRateUSDTToVND } from "utils/currency";
import LocaleSevice from "api/locales";
import { IRequestData } from "api/request";
import SettingService from "api/settings";
import UserSettingsService from "api/user-settings";

export const getSettingsAsync = createAsyncThunk("setting/all", async () => {
  try {
    const response = await SettingService.getSettings();
    return response;
  } catch (error) {
    return error;
  }
});

export const getAdvertisementPositionsync = createAsyncThunk(
  "setting/advertisementPosition",
  async () => {
    try {
      const response = await SettingService.getSettingByName(
        "advertisement_position"
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getLogoAsync = createAsyncThunk("setting/logos", async () => {
  try {
    const response = await SettingService.getSettingByName("logos");
    return response;
  } catch (error) {
    return error;
  }
});

export const getChannelAsync = createAsyncThunk("setting/channel", async () => {
  try {
    const response = await SettingService.getSettingByName("channel");
    return response;
  } catch (error) {
    return error;
  }
});

export const getDocumentsAsync = createAsyncThunk(
  "setting/documents",
  async () => {
    try {
      const response = await SettingService.getSettingByName("documents");
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getNftsAsync = createAsyncThunk("setting/nfts", async () => {
  try {
    const response = await SettingService.getSettingByName("nfts");
    return response;
  } catch (error) {
    return error;
  }
});

export const getQAAsync = createAsyncThunk("setting/q&a", async () => {
  try {
    const response = await SettingService.getSettingByName("q&a");
    return response;
  } catch (error) {
    return error;
  }
});

export const getSupportsAsync = createAsyncThunk(
  "setting/supports",
  async () => {
    try {
      const response = await SettingService.getSettingByName("supports");
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBannerAsync = createAsyncThunk("setting/banner", async () => {
  try {
    const response = await SettingService.getSettingByName("banner");
    return response;
  } catch (error) {
    return error;
  }
});

export const getProjectsAsync = createAsyncThunk(
  "setting/projects",
  async () => {
    try {
      const response = await SettingService.getSettingByName("projects");
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getObjectsAsync = createAsyncThunk("setting/objects", async () => {
  try {
    const response = await SettingService.getSettingByName("objects");
    return response;
  } catch (error) {
    return error;
  }
});

export const getCitiesAsync = createAsyncThunk("setting/cities", async () => {
  try {
    const response = [];
    return response;
  } catch (error) {
    return error;
  }
});

export const getLocationAsync = createAsyncThunk(
  "setting/location",
  async (req: IRequestData) => {
    try {
      const response = await SettingService.getLocations(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getRateUSDTToVNDAsync = createAsyncThunk(
  "setting/rate",
  async () => {
    try {
      const response = await getRateUSDTToVND();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getMenusAsync = createAsyncThunk("setting/menus", async () => {
  try {
    const response = await SettingService.getMenus();
    return response;
  } catch (error) {
    return error;
  }
});

export const getAllMenusAsync = createAsyncThunk("setting/menus", async () => {
  try {
    const response = await SettingService.getAllMenus();
    return response;
  } catch (error) {
    return error;
  }
});

export const getHobbiesAsync = createAsyncThunk("setting/hobbies", async () => {
  try {
    const response = await SettingService.getHobbies();
    return response;
  } catch (error) {
    return error;
  }
});

export const getCareersAsync = createAsyncThunk("setting/careers", async () => {
  try {
    const response = await SettingService.getCareers();
    return response;
  } catch (error) {
    return error;
  }
});

export const getFamiliesAsync = createAsyncThunk(
  "setting/families",
  async () => {
    try {
      const response = await SettingService.getFamilies();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getFieldsAsync = createAsyncThunk("setting/fields", async () => {
  try {
    const response = await SettingService.getCategoryFields();
    return response;
  } catch (error) {
    return error;
  }
});

export const getNationsAsync = createAsyncThunk("setting/nations", async () => {
  try {
    const response = await SettingService.getNations();
    return response;
  } catch (error) {
    return error;
  }
});

export const getLocaleAsync = createAsyncThunk("setting/locales", async () => {
  try {
    const response = await LocaleSevice.getAll();
    return response;
  } catch (error) {
    return error;
  }
});

export const getReligionsAsync = createAsyncThunk(
  "setting/religions",
  async () => {
    try {
      const response = await SettingService.getReligions();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getDefaultObjectSettingsAsync = createAsyncThunk(
  "setting/default",
  async () => {
    try {
      const response = await SettingService.getDefaultSettingsObject();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUserSettingsAsync = createAsyncThunk(
  "setting/user-settings",
  async (req: any) => {
    try {
      const response = await UserSettingsService.getUserSettings(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";

export const getQuotasAsync = createAsyncThunk("quotas/list", async () => {
  try {
    const response: any = await API.QUOTAS.getQuotas();
    return response;
  } catch (error) {
    return error;
  }
});

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
// import useTranslation from "hooks/useTranslation";
import Utility from "./utility";
import { getUtilities, getUtilitiesByType } from "api/utilities";
// import Events from "./events";
import { Row, Col, Container } from "react-bootstrap";
import VBannerPage from "components/v-teamplate/v-banner-page";
// import VInput from "components/v-input";
import PageBackgrounds from "components/v-page-background";
import VRightView from "components/v-right-view";
import VIcon from "components/v-icon";
import { useAppSelector } from "redux/reducers/hook";
import {
  getGeneral,
  getMenusByKey,
  getUtilityCategories,
} from "redux/reducers/settings/settings.selector";
import VTab from "components/v-tab";
import useQuery from "hooks/useQuery";
import VWebView from "components/v-web-view";
import VButton from "components/v-button";
import CategoryService from "api/categories";
import { ROUTES } from "config/routes";

const View = () => {
  // const { t } = useTranslation();
  const [utilities, setUtilities] = useState({ all: [], native: [] });
  const [team, setTeam] = useState([]);
  const categories = useAppSelector(getUtilityCategories);
  const isRouterTeam = window.location.pathname === ROUTES.TEAM;

  const menus = useAppSelector(getMenusByKey("tab_utilities"));
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null

  const [tab, setTab] = useState(null);
  const [loadMenu, setLoadMenu] = useState(false);
  const query = useQuery();
  const [webview, setWebview] = useState<string | null>(null);
  const [tabTeam, setTabTeam] = useState<any>([]);

  const getTabTeam = async () => {
    try {
      const groupType = await CategoryService.getByGroup({
        query: { group: "utility_type" },
      });
      setTabTeam(groupType);
    } catch (error) {}
  };
  const getUtility = async () => {
    try {
      if (categories && categories.length > 0) {
        const formating = (data) => {
          const groupByCategory = [...data].reduce((prev, next) => {
            const { category } = next;
            prev[category] = prev[category] ?? [];
            prev[category].push(next);
            return prev;
          }, {});
          return categories
            .map((c) => c.key)
            .map((category) => ({
              name: category,
              childs: groupByCategory[category],
            }));
        };
        if (isRouterTeam) {
          const data = await getUtilitiesByType({
            query: {
              type: query.tab || tabTeam[0]?.key,
            },
          });
          setTeam(formating(data));
        } else {
          getUtilities().then((data: any) => {
            setUtilities((state) => ({
              ...state,
              all: formating(data.all),
              native: formating(data.native),
            }));
          });
        }
      }
    } catch (error) {}
  };
  useEffect(() => {
    getUtility();
  }, [categories, query, tabTeam]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  useEffect(() => {
    if (isRouterTeam) getTabTeam();
  }, [isRouterTeam]);

  useEffect(() => {
    const tabMenu = isRouterTeam ? tabTeam : menus;
    if (loadMenu)
      if (query.tab) {
        if (tabMenu && tabMenu.length > 0) {
          const menu = tabMenu.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(tabMenu[0]);
        }
      } else {
        setTab(tabMenu[0]);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadMenu, menus]);

  const webviewHandler = (target) => {
    setWebview(target);
  };

  return (
    <>
      <PageBackgrounds
        page={isRouterTeam ? "page_bg_teams" : "page_bg_utilities"}
      />
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <div id="utilities_page">
              <VBannerPage
                position={
                  isRouterTeam ? "banner_top_teams" : "banner_top_utilities"
                }
              />
              <div className="bg-white shadow-md p-3">
                <div className="d-flex align-items-center justify-content-between pb-3">
                  <Col sm={12}>
                    <VTab
                      tab={tab}
                      setTab={setTab}
                      menus={isRouterTeam ? tabTeam : menus}
                      activeBorder="bottom"
                    />
                  </Col>
                  {/* <Col sm={12} md={5}>
                    <VInput
                      transparent={true}
                      label={t("Utilities")}
                      suffix={<VIcon width={16} name="search.svg" />}
                    />
                  </Col> */}
                </div>
                <div>
                  {!webview &&
                    // (tab?.key === "all" ? utilities.all : utilities.native)
                    //   .filter(
                    //     (utility) =>
                    //       utility.childs?.length > 0 || utility.childs
                    //   )
                    (isRouterTeam
                      ? team
                      : tab?.key === "all"
                      ? utilities.all
                      : utilities.native
                    )
                      ?.filter(
                        (utility) =>
                          utility.childs?.length > 0 || utility.childs
                      )
                      .map((utility) => (
                        <Utility
                          setWebview={webviewHandler}
                          key={utility.name}
                          name={utility.name}
                          childs={utility.childs}
                        />
                      ))}
                  {webview && (
                    <>
                      <div className="pb-2">
                        <VButton
                          icon={<VIcon name="back.svg" />}
                          onClick={() => setWebview(null)}
                        />
                      </div>
                      <VWebView url={webview} height="700px" />
                    </>
                  )}
                </div>
              </div>
              {/* <div className="mb-5">
                <Events data={events} title="event" />
              </div>
              <div>
                <Events data={events} title="news" />
              </div> */}
            </div>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={isRouterTeam ? "tab_right_teams" : "tab_right_utility"}
                positionBanner={
                  isRouterTeam ? "banner_right_teams" : "banner_right_utility"
                }
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default View;

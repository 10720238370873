import { IRootState } from "..";

export const getUIPostsState = (state: IRootState) => state.UISettings.posts;
export const getUISettingState = (state: IRootState) => state.UISettings;
export const getUIPGaleryState = (state: IRootState) =>
  state.UISettings.galeryImages;
export const getUIPTemplateState = (state: IRootState) =>
  state.UISettings.serialized;
export const getRandomTemplateCategoryState = (state: IRootState) =>
  state.UISettings.randomCategory;
export const getRandomTemplateImageState = (state: IRootState) =>
  state.UISettings.randomTemplateImage;
export const getUIPStoryState = (state: IRootState) => state.UISettings.stories;
export const getIsConnectionError = (state: IRootState) =>
  state.UISettings.isConnectionError;
export const getAudioSrc = (state: IRootState) => state.UISettings.audioSrc;
export const getMusicMetadata = (state: IRootState) =>
  state.UISettings.musicMetadata;
export const getUsernamePost = (state: IRootState) =>
  state.UISettings.usernamePost;
export const getPDFSrc = (state: IRootState) => state.UISettings.pdfSrc;
export const getPostDetailState = (state: IRootState) =>
  state.UISettings.postDetail;
export const getProductState = (state: IRootState) => state.UISettings.product;

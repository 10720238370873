import { request, parseErrorResponse } from "./request";

const getAllCategory = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/category-faqs`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAllQa = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/qas`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CategoryFaqsService = {
  getAllCategory,
  getAllQa
};

export default CategoryFaqsService;
import { createAsyncThunk } from "@reduxjs/toolkit";
import APIs from "api";

export const getSosCategoriesAsync = createAsyncThunk("sos-categories/get", async () => {
  try {
    const response = await APIs.CATEGORIES.getByGroup({
      query: {
        group: "product_category_normal"
      }
    })
    return response;
  } catch (error) {
    return error;
  }
});

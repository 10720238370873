/* eslint-disable indent */
import { Link, useHistory } from "react-router-dom";

import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import VIcon from "components/v-icon";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { getUrlImage } from "utils/common";
import { isUser, isBusiness, isPage, isChannel, isGroup } from "utils/friends";
import useQuery from "hooks/useQuery";

const SidebarSettingObjects = ({ dataUser }) => {
  const query = useQuery();
  const history = useHistory();
  const { t } = useTranslation();
  const menuAll = useAppSelector(getMenusByKey("tab_menu_setting_account"));

  const menuSettingObject = [
    "general",
    "affiliate",
    "special-name",
    "verification",
    "privacy_security",
    "profile",
    "account",
    "visit-card",
    "delete-account",
  ];

  const menuRequieKyc =
    dataUser?.status_kyc !== "verified" && dataUser?.status_kyc !== "kyc"
      ? ["special-name"]
      : [];

  const menus = menuAll.filter(
    (menu) =>
      menuSettingObject.includes(menu.key) && !menuRequieKyc.includes(menu.key)
  );

  const handleClick = (key) => {
    const search = new URLSearchParams(window.location.search);
    search.set("key", String(key));
    history.push(`?${search}`);
  };

  return (
    <Card>
      <div className="sidebar_settings">
        <Card.Body>
          {menus?.length > 0 &&
            menus.map((el) => (
              <div
                key={el._id}
                className="sidebar-item"
                hidden={
                  !isUser(dataUser.object_type) &&
                  !isBusiness(dataUser.object_type) &&
                  !isPage(dataUser.object_type) &&
                  !isGroup(dataUser.object_type) &&
                  !isChannel(dataUser.object_type) &&
                  (el.key === "profile" || el.key === "verification")
                }
              >
                <Link
                  className={`${
                    query?.key === `${el.key}` ? "active" : ""
                  } nav-link `}
                  aria-current="page"
                  onClick={() => handleClick(el.key)}
                >
                  {el?.img_icon ? (
                    <img
                      width={20}
                      height={20}
                      src={getUrlImage(el?.img_icon)}
                      alt=""
                    />
                  ) : (
                    <VIcon name="" />
                  )}
                  <span className="item-name text-red-500">
                    {t(
                      el.name === "Verify"
                        ? isBusiness(dataUser.object_type)
                          ? "Verify business"
                          : isPage(dataUser.object_type)
                          ? "Verify page"
                          : isGroup(dataUser.object_type)
                          ? "Verify group"
                          : isChannel(dataUser.object_type)
                          ? "Verify channel"
                          : "Verify"
                        : el.name
                    )}
                  </span>
                </Link>
              </div>
            ))}
        </Card.Body>
      </div>
    </Card>
  );
};
export default SidebarSettingObjects;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
import VAvatar from "components/v-avatar";
import { useHistory } from "react-router-dom";
import { getUrlProfile } from "utils/common";
import { BUSINESS_ACTION } from "config/enums";
import VActionBusiness from "../v-actions-business";
import VNameVerify from "../v-name-verify";

type IProps = {
  member: {
    _id: string;
    object_type: string;
    full_name: string;
  };
  business_id: string;
  dataAccessFriend?: any;
  reload: any;
  type?: string;
};

const View = ({ member, reload, type, business_id }: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const handleClickItem = (friend) => {
    history.push(getUrlProfile(friend));
  };
  const renderAction = () => {
    switch (type) {
      case BUSINESS_ACTION.ACCEPT_MEMBER:
        return (
          <div className="action-member gap-1">
            <VActionBusiness
              type={BUSINESS_ACTION.ACCEPT_MEMBER}
              to_id={member?._id}
              reload={reload}
              isShowIcon={false}
              isShowText={true}
              business_id={business_id}
            />
            <VActionBusiness
              type={BUSINESS_ACTION.CANCEL_ACCEPT_MEMBER}
              to_id={member?._id}
              reload={reload}
              isShowIcon={false}
              isShowText={true}
              business_id={business_id}
            />
          </div>
        );
      case BUSINESS_ACTION.REMOVE_MEMBER:
        return (
          <div className="action-member gap-1">
            <VActionBusiness
              type={BUSINESS_ACTION.REMOVE_MEMBER}
              to_id={member?._id}
              reload={reload}
              isShowIcon={false}
              isShowText={true}
              business_id={business_id}
            />
            {/* <VActionBusiness
              type={BUSINESS_ACTION.DELETE}
              isShowIcon={false}
              isShowText={true}
              reload={reload}
            /> */}
          </div>
        );

      case BUSINESS_ACTION.REVOKE_INVITE:
        return (
          <div className="action-member gap-1">
            <VActionBusiness
              type={BUSINESS_ACTION.REVOKE_INVITE}
              isShowIcon={false}
              isShowText={true}
              reload={reload}
              to_id={member?._id}
              business_id={business_id}
            />
          </div>
        );

      default:
    }
  };

  return (
    <>
      <div className="v-friend">
        <div className="iq-friendlist-block p-2 rounded position-relative py-3">
          <img
            className="position-absolute top-0 end-0 p-2"
            src="/assets/icons/default/option.svg"
            alt="option button"
          />
          <div className="d-flex flex-column align-items-center justify-content-between">
            <div className="d-flex flex-column align-items-center text-center mb-2">
              <Link className="mb-1" onClick={(_) => handleClickItem(member)}>
                <VAvatar 
                  size={"80px"} 
                  userData={member} 
                  hideName={true} 
                />
              </Link>
              <div className="info-user m-0">
                <h5
                  className="fw-bold mb-1 position-relative"
                  onClick={(_) => handleClickItem(member)}
                >
                  <VNameVerify
                    className={"mb-0 d-flex align-items-center"}
                    dataUser={member}
                    fontSizeIconVerify={12}
                  />
                </h5>
                {type !== BUSINESS_ACTION.ACCEPT_MEMBER && (
                  <p className="my-1">
                    <>{t("Member")}</>
                  </p>
                )}
              </div>
            </div>
            <div className="card-header-toolbar d-flex align-items-center mb-1">
              {renderAction()}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(View);

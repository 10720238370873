/* eslint-disable indent */

import Countdown from "components/v-countdown";
import React, { useMemo, useState } from "react";
import { compareDateWithCur } from "utils/times";
import { getFullNameByNumber, getUrlImage } from "utils/common";
import VTooltip from "components/v-tooltip";
import VIcon from "components/v-icon";
import VReward from "components/v-reward";
import useTranslation from "hooks/useTranslation";
import { renderDate } from "utils/times";
import VButton from "components/v-button";
import VModal from "components/v-modal";
import TaskService from "api/tasks";
import { getCampaignsAsync } from "redux/reducers/tasks/tasks.actions";
import { useAppDispatch } from "redux/reducers/hook";
import { toast } from "react-toastify";

const CampaignItem = ({ campaign, setTab, menus }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const data = useMemo(() => {
    let title = "";
    let time = 0;
    if (campaign.start_date && compareDateWithCur(campaign.start_date) > 0) {
      title = "Upcoming";
      time = new Date().getTime() + compareDateWithCur(campaign.start_date);
    } else {
      if (campaign.end_date && compareDateWithCur(campaign.end_date) < 0) {
        title = "Closed";
      } else {
        title = "Happening";
        time = new Date().getTime() + compareDateWithCur(campaign.end_date);
      }
    }

    return {
      time,
      title,
    };
  }, [campaign]);

  const joinCampaign = async () => {
    try {
      setLoading(true);
      await TaskService.uploadFile({
        body: {
          files: [],
          campaignId: campaign._id,
        },
      });
      dispatch(getCampaignsAsync());
      setTab(menus[1]);
      setLoading(false);
    } catch (error) {
      toast.error("Tham gia không thành công");
      setLoading(false);
    }
  };

  const renderAffilate = (
    <div>
      {Object.keys(campaign.affiliates || {}).map((k) => {
        return (
          <div>
            Level {String(k).toUpperCase()} : <br />
            <VReward reward={campaign.affiliates[k]} />
          </div>
        );
      })}
    </div>
  );

  const renderAction = (
    <span className="text-warning line-height d-flex">
      {campaign.affiliates && (
        <VTooltip key={`${campaign._id}_affilate`} content={renderAffilate}>
          <VIcon
            name="common/affilate.svg"
            width={20}
            height={20}
            className="mx-1"
          />
        </VTooltip>
      )}

      {campaign.reward &&
        (campaign.reward.codes.length > 0 ||
          campaign.reward.stocks.length > 0 ||
          campaign.reward.tickets.length > 0 ||
          campaign.reward.vouchers.length > 0) && (
          <VTooltip
            key={`${campaign._id}_gift`}
            content={<VReward reward={campaign.reward} />}
          >
            <VIcon
              name="common/gift.svg"
              className="mx-1"
              width={20}
              height={20}
            />
          </VTooltip>
        )}
    </span>
  );

  const renderModal = (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: campaign.info.guide,
        }}
      ></div>
    </>
  );

  return (
    <div className="item">
      <div
        className="top"
        style={{
          backgroundImage: `url(${getUrlImage(campaign.info.image)})`,
        }}
      >
        <div className="d-flex justify-content-between p-2">
          <div className={`status ${data.title} `}>{t(data.title)}</div>
          <div className="d-none d-sm-block">{renderAction}</div>
        </div>
      </div>
      <div className="bottom">
        <span className="name">{getFullNameByNumber(campaign.name, 20)}</span>
        <div className="task-time d-flex">
          <div className="time">
            <div className="from">
              {t("From")}: {renderDate(campaign?.start_date)}
            </div>
            <div className="to">
              {t("To")}: {renderDate(campaign?.end_date)}
            </div>
          </div>
          <div className="b-countdown">
            {data.time > 0 && <Countdown date={data.time} />}
          </div>
        </div>
        <div className="row my-2 w-50">
          <VButton
            className={"v-button-animation "}
            onClick={(_) => setOpenModal(true)}
          >
            {t("View detail")}
          </VButton>
        </div>
      </div>
      <VModal
        title={t("Campaign detail")}
        isOpen={openModal}
        centered={true}
        children={renderModal}
        confirmLabel={"Tham gia"}
        loading={loading}
        onConfirm={joinCampaign}
        onCancel={() => setOpenModal(false)}
      />
    </div>
  );
};

export default CampaignItem;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import VFriend from "components/v-objects/v-friend";
import { useAppDispatch } from "redux/reducers/hook";
import { getDataAccessByOwnerAsync } from "redux/reducers/users/users.actions";
import VInfiniteLoad from "components/v-infinite-load";
import DepartmentService from "api/department";

const MemberDepartment = (props: any) => {
  const dispatch = useAppDispatch();
  const { departmentId, col } = props;
  const [loading, setLoading] = useState(false);
  const [friends, setFriends] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    limit: 18,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (departmentId) getData();
  }, [lazyParams, departmentId]);

  const getData = async () => {
    const res: any = await DepartmentService.getMemberInDepartment({
      params: {
        departmentId: departmentId,
      },
    });
    if (res) {
      if (res) {
        setFriends(res);
        setTotalPages(res.length)
      }
    }
    setLoading(false);
  };

  const handleViewMore = () => {
    setLoading(true);
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const reload = ({ to_id }) => {
    dispatch(getDataAccessByOwnerAsync());
    setFriends((friends) => friends.filter((f) => f.to_id !== to_id));
  };

  return (
    <div>
      <div className="col-12">
        <VInfiniteLoad
          canLoadMore={lazyParams.page + 1 <= totalPages}
          isLoadingMore={loading}
          onLoadMore={handleViewMore}
        >
          <div className="d-flex flex-wrap">
            {" "}
            {friends.map((friend, index) => {
              return (
                <div
                  key={index}
                  className={`d-flex justify-content-center p-1 col-6 ${
                    col || "col-md-4"
                  }`}
                >
                  <div className={`rps-container`}>
                    <VFriend
                      friend={{
                        ...friend,
                        to: friend.member,
                      }}
                      dataAccessFriend={friend.member}
                      reload={reload}
                      // type={type}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </VInfiniteLoad>
      </div>
    </div>
  );
};

export default MemberDepartment;

import ComingSoon from "pages/coming-soon/comingsoon";

const AdvFeed = () => {
  return (
    <>
      <ComingSoon />
    </>
  );
};

export default AdvFeed;

/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import React, { useEffect, useState, useContext } from "react";
import { Row, Col, Container } from "react-bootstrap";

//image

import PageBackgrounds from "components/v-page-background";
import VTab from "components/v-tab";
import VRightView from "components/v-right-view";
import { useAppSelector } from "redux/reducers/hook";
import WebViewFeed from "./tab-views/web-view";
import AdvFeed from "./tab-views/advs-feed";
import {
  getGeneral,
  getIsApp,
  getLocationCurrent,
  getMenusByKey,
} from "redux/reducers/settings/settings.selector";
import useQuery from "hooks/useQuery";
import Quota from "components/v-quota";

import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import {
  setOpenShopPopupAction,
  setOpenWebModulesPopupAction,
} from "redux/reducers/ui-settings/ui-settings.reducer";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";

import NewsFeedModule from "./newsfeed";
// import { useTranslation } from "react-i18next";
import NewsFeedContext from "context/NewsFeedContext/View";
import ProfileTimeline from "../user/profile/profile-timeline";
// import MiniChat from "../user/profile/conversations/mini-chat";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { useHistory } from "react-router-dom";
import { getQuotas } from "redux/reducers/quotas/quotas.selector";
import VBanner from "components/v-banner";
import VCreatePopover from "components/v-create-popover";
// import PlatformsSocialNetwork from "../object-details/profile-left-sidebar/platforms-social-network";

const View = () => {
  const menus = useAppSelector(getMenusByKey("tab_feed"));
  const _quotas = useAppSelector(getQuotas);
  const location_current = useAppSelector(getLocationCurrent);     

  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container =
    general?.banner_right_layout !== "show_on_right_bar" || null;

  const [lastLoadFeed, setLastLoadFeed] = useState(null);
  const [tab, setTab] = useState(null);
  const query = useQuery();

  const newsFeedContext = useContext(NewsFeedContext);

  const [loadMenu, setLoadMenu] = useState(false);

  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const { reloadNewsFeedNumber, openShopKey } =
    useAppSelector(getUISettingState);
  const [isOpen, setIsOpen] = useState(false);
  const token = localStorage.getItem("auth");
  const user = useAppSelector(getUserProfile);
  const history = useHistory();
  const isApp = useAppSelector(getIsApp);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [loadMenu, query.tab]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  useEffect(() => {
    const { timeReloaded } = newsFeedContext;
    if (
      timeReloaded &&
      timeReloaded !== lastLoadFeed &&
      tab?.key === "news_feed"
    ) {
      setTabCustom({ ...tab });
      setLastLoadFeed(timeReloaded);
    }
  }, [newsFeedContext?.timeReloaded, tab]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  useEffect(() => {
    if (openShopKey === "open-user-profile-to-create-product") {
      dispatch(
        setOpenShopPopupAction({
          openShopKey: "open-create-product",
        })
      );
      history.push(`/profile/${user?._id}?tab=products`);
    }
  }, [openShopKey]);

  const setTabCustom = (tab) => {
    // reload news feed tab
    if (tab.key === "news_feed") {
      setTab(null);
      setTimeout(() => setTab(tab), 0);
      return;
    }

    setTab(tab);
  };

  const renderTab = () => {
    if (tab && tab?.url?.includes("profile_embed")) {
      const userId = tab.url.split("profile_embed/")[1];
      return (
        <Col id={"post_profile"} className="ps-0" md={12}>
          <ProfileTimeline userId={userId} />
        </Col>
      );
    }
    if (tab && tab.type_view === "internal") {
      if (tab.key === "news_feed") {
        // return <NewsFeed />
        return (
          <NewsFeedModule
            className={`${isApp ? "iframe-is-app" : ""} ${
              _quotas?.length > 0 ? "iframe-newfeed" : "iframe-webview-home"
            }`}
            reloadNewsFeedNumber={reloadNewsFeedNumber}
            moduleSessionId={moduleSessionId}
            fromSessionUid={fromSessionUid}
          />
        );
      }

      if (tab.key === "adv_feed") return <AdvFeed />;
    }

    if (tab && tab.type_view === "webview") {
      if (tab.key === 'minigame') {
        const url = `${tab.url}&query_lat=${location_current?.lat ?? ''}&query_lng=${location_current?.long ?? ''}`;
        return <WebViewFeed url={url} widthScrollbar={tab?.width_scrollbar} />;
      }
      
      if (tab?.key?.includes("post_type")) {
        return (
          <NewsFeedModule
            reloadNewsFeedNumber={reloadNewsFeedNumber}
            moduleSessionId={moduleSessionId}
            fromSessionUid={fromSessionUid}
            type={tab?.url}
            viewTab={1}
          />
        );
      }
      if (tab.required_token) {
        const url = `${tab.url}?token=${localStorage.getItem("auth")}`;        
        return <WebViewFeed url={url} widthScrollbar={tab?.width_scrollbar} />;
      }      

      return (
        <WebViewFeed url={tab.url} widthScrollbar={tab?.width_scrollbar} />
      );
    }
  };

  const onClickCreate = (page: string) => {
    let key = "post_create";
    if (page === "product") {
      key = "open-create-product-quick";
    }
    setIsOpen(false);
    dispatch(
      setOpenWebModulesPopupAction({
        page: page,
        ...(page === "product" && { userId: user?._id }),
        openWebModuleKey: key,
      })
    );
  };

  return (
    <>
      {!token ? (
        <div className="container">
          <img
            src="https://file2.vdiarybook.com:4443/api/files/admin/f77871eb-6769-4dc6-80f1-d56bb60e0531.jpg"
            alt=""
            style={{ width: "100%", height: "auto" }}
          />
        </div>
      ) : (
        <div className="home-wrapper">
          <PageBackgrounds page={"page_bg_home"} />
          <Container>
            <Row className="justify-content-center">
              <Col
                lg={show_banner_right_on_container ? 8 : 12}
                className={`px-1 newfeed-container`}
              >
                {tab?.key === "news_feed" && <Quota />}
                <VTab
                  tab={tab}
                  setTab={setTabCustom}
                  menus={menus}
                  activeBorder="bottom"
                />
                <div className="mb-2">
                  <VBanner
                    position={"banner_top_feed_home"}
                    isBannerPage={true}
                  />
                </div>
                {renderTab()}
              </Col>
              {show_banner_right_on_container && (
                <Col lg={4} className={"d-none d-lg-block px-1"}>
                  <VRightView
                    className="newfeed-rightview"
                    positionBanner={"banner_right_home"}
                  />
                </Col>
              )}
            </Row>
          </Container>
          <VCreatePopover
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            onClickCreate={onClickCreate}
          />
        </div>
      )}
      {/* <PlatformsSocialNetwork /> */}
    </>
  );
};

export default View;

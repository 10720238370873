/* eslint-disable */
import { useContext, useEffect, useRef, useState } from "react";
import useTranslation from "hooks/useTranslation";
import Dropdown from "../core/dropdown";
import APIs from "api";
import { MapContext } from "components/smart-maps/context";
import { Col, Container, Row } from "react-bootstrap";

type IAddress = {
  country?: string | null;
  province?: string | null;
  district?: string | null;
  ward?: string | null;
  detail?: string | null;
};

type IProps = {
  colNum: number;
  address: IAddress;
  setAddress: (data: IAddress) => void;
};

const View = (props: IProps) => {
  const { address, setAddress, colNum = 3 } = props;

  const { locations } = useContext(MapContext);

  const { t } = useTranslation();
  const firstLoad = useRef(false);

  useEffect(() => {
    if (locations && locations.length > 0 && !firstLoad.current && address) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, address, firstLoad.current]);

  const fetchData = async () => {
    setCountries(locations?.filter((a) => a.type === "country"));
    if (address.country) {
      firstLoad.current = true;
      const _provinces = await getLocationsByParent(address.country);
      setProvinces(_provinces);
      if (address.province) {
        const _districts: any = await getLocationsByParent(address.province);
        setDistricts(_districts);
      }
      if (address.district) {
        const _wards: any = await getLocationsByParent(address.district);
        setWards(_wards);
      }
    }
  };

  const [countries, setCountries] = useState<any>([]);
  const [provinces, setProvinces] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [wards, setWards] = useState<any>([]);

  const getLocationsByParent = async (parent: string) => {
    try {
      const locations = await APIs.SETTING.getLocations({
        query: {
          parent: parent,
        },
      });
      return locations;
    } catch (error) {
      return [];
    }
  };

  const setFilterCities = async (key: string, e: any) => {
    const code = e;
    if (key === "country") {
      setDistricts([]);
      setWards([]);
      if (code) {
        const _provices = await getLocationsByParent(code);
        if (_provices) {
          setProvinces(_provices);
          setAddress({
            country: code,
            province: null,
            district: null,
            ward: null,
          });
        } else {
          setAddress({
            country: null,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setAddress({
          country: null,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "province") {
      setWards([]);
      if (code) {
        const _districts = await getLocationsByParent(code);
        if (_districts) {
          setDistricts(_districts);
          setAddress({
            ...address,
            province: code,
            district: null,
            ward: null,
          });
        } else {
          setDistricts([]);
          setAddress({
            ...address,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setDistricts([]);
        setAddress({
          ...address,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "district") {
      if (code) {
        const _wards = await getLocationsByParent(code);
        setWards(_wards);
        setAddress({
          ...address,
          district: code,
          ward: null,
        });
      } else {
        setWards([]);
        setAddress({
          ...address,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "ward") {
      if (code) {
        setAddress({
          ...address,
          ward: code,
        });
      } else {
        setAddress({
          ...address,
          ward: null,
        });
      }
    }
  };

  return (
    <Container>
      <Row>
        <Col xs={colNum} className="my-1">
          <div className="country">
            <Dropdown
              placeholder={t("Country")}
              options={countries?.map((el: any) => {
                return {
                  label: el?.name,
                  value: String(el?._id),
                };
              })}
              value={address?.country}
              onChange={(e: any) => setFilterCities("country", e)}
              className="!rounded-md !bg-white my"
            />
          </div>
        </Col>
        <Col xs={colNum} className="my-1">
          <div className="province">
            <Dropdown
              placeholder={t("Province")}
              options={provinces?.map((el: any) => {
                return {
                  label: el?.name,
                  value: String(el?._id),
                };
              })}
              value={address?.province}
              onChange={(e: any) => setFilterCities("province", e)}
              className="!rounded-md !bg-white my"
            />
          </div>
        </Col>
        <Col xs={colNum} className="my-1">
          <div className="district">
            <Dropdown
              placeholder={t("District")}
              options={districts?.map((el: any) => {
                return {
                  label: el?.name,
                  value: String(el?._id),
                };
              })}
              value={address?.district}
              onChange={(e: any) => setFilterCities("district", e)}
              className="!rounded-md !bg-white my"
            />
          </div>
        </Col>
        <Col xs={colNum} className="my-1">
          <div className="ward">
            <Dropdown
              placeholder={t("Ward")}
              options={wards?.map((el: any) => {
                return {
                  label: el?.name,
                  value: String(el?._id),
                };
              })}
              value={address?.ward}
              onChange={(e: any) => setFilterCities("ward", e)}
              className="!rounded-md !bg-white my"
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Error404 from "components/v-error-404";

import { useParams } from "react-router-dom";
import VQRCode from "components/v-qr-code";
import { getUrlImage } from "utils/common";
import styled from "styled-components";
import LocationSvg from "assets/images/icon/location.svg";
import MailSvg from "assets/images/icon/mail.svg";
import { ROUTES } from "config/routes";
import useTranslation from "hooks/useTranslation";
import Header from "layouts/dashboard/HeaderStyle/header";

import VNameVerify from "components/v-objects/v-name-verify";

import { useAppSelector } from "redux/reducers/hook";
import { getLocations } from "redux/reducers/settings/settings.selector";
import RoomService from "api/rooms";
import withAuth from "HOCs/withAuth";
import { getUserProfile } from "redux/reducers/users/users.selector";
// import NewsFeedContext from "context/NewsFeedContext/View";

const Avatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .national_flag {
    position: absolute;
    top: -25%;
    left: -25%;
    width: 50%;
    height: auto;
    max-height: 50px;
  }
`;

const ImageDetail = styled.img`
  width: ${(props) => props.width || "22px"};
  height: auto;
`;

const ChatButton = styled.button`
  width: 250px;
`;

const ProfileContent = styled.div`
  max-width: calc(100% - 200px);
  min-width: 350px;

  .username {
    position: relative;
    display: flex;
  }
`;

interface IProps {
  userId?: string;
}

const View = (props: IProps) => {
  const params = useParams();

  const { id: query_id } = params;

  const qrLink = window.location.origin + "/join/" + query_id;

  // const { user } = useContext(NewsFeedContext);

  const { userId: id = query_id } = props;

  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);

  const [room, setRoom] = useState(null);

  const locations = useAppSelector(getLocations);
  const userData = useAppSelector(getUserProfile);

  const roomInfo =
    room?.type === "chat_friend"
      ? room?.people?.find((e) => e?._id !== userData?._id)
      : room?.owner;

  useEffect(() => {
    getRoomById();
  }, [id]);

  const getRoomById = async () => {
    setLoading(true);
    try {
      const roomGot = await RoomService.getRoomByRoomId(id);
      setRoom(roomGot);
      setIsNotFound(false);
    } catch {
      setIsNotFound(true);
    }

    setLoading(false);
  };

  const country = useMemo(() => {
    if (room) {
      const _country = roomInfo?.profile?.location?.country;
      if (_country) {
        return locations?.find(
          (location: { _id: any }) => location._id === _country
        );
      }
    }
    return null;
  }, [room, locations]);

  return isNotFound && !loading ? (
    <Error404 />
  ) : (
    !loading && room && (
      <div>
        <Header />
        <div className="content-preview">
          <div
            className={`profile-wrapper ${query_id ? "mt-5" : ""}`}
            id="profile-preview"
          >
            <div className="bg-white p-5 rounded">
              <div className="d-flex flex-wrap justify-content-center">
                <ProfileContent className="profile-detail flex-grow-1">
                  <div className="d-flex mb-5">
                    <Avatar>
                      <img
                        alt="avatar"
                        src={
                          getUrlImage(roomInfo?.avatar, 150) ||
                          "/assets/images/VDB-64.png"
                        }
                      />

                      {country && (
                        <img
                          className="national_flag"
                          src={getUrlImage(country?.icon)}
                          alt=""
                        />
                      )}
                    </Avatar>

                    <div className="profile-name">
                      <h3>
                        <VNameVerify
                          dataUser={roomInfo}
                          className={"username"}
                        />
                      </h3>

                      {roomInfo?.object_type && (
                        <h5 className="mb-2">{t(roomInfo?.object_type)}</h5>
                      )}

                      <a href={`${ROUTES.CHAT_DEMO}?invite=${roomInfo?._id}`}>
                        <ChatButton className="btn btn-primary btn-lg">
                          {t("JOIN GROUP")}
                        </ChatButton>
                      </a>
                    </div>
                  </div>
                  <div>
                    <h4>
                      <b>{t("Descriptions")}</b>
                    </h4>
                    {roomInfo?.location_detail && (
                      <p className="mb-1">
                        <ImageDetail src={LocationSvg} alt="" />{" "}
                        {roomInfo?.location_detail}
                      </p>
                    )}

                    {roomInfo?.email && (
                      <p className="mb-3">
                        <ImageDetail src={MailSvg} alt="" />{" "}
                        <a href={`mailto:${roomInfo?.email}`}>
                          {roomInfo?.email}
                        </a>
                      </p>
                    )}

                    {roomInfo?.description && (
                      <>
                        <hr />
                        <p>{roomInfo?.description}</p>
                      </>
                    )}
                  </div>
                </ProfileContent>
                <div className="profile-qr">
                  <VQRCode value={qrLink} size={150} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default withAuth(View);

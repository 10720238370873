import useQuery from "hooks/useQuery";
import { useEffect } from "react";
import VerificationSetting from "pages/social/settings/components/verification-settings";
import { useAppDispatch } from "redux/reducers/hook";
import { getUserAsync } from "redux/reducers/users/users.actions";

const View = () => {
  const dispatch = useAppDispatch();
  const query = useQuery();
  const token = query?.token;
  useEffect(() => {
    localStorage.setItem("auth", token);
    dispatch(getUserAsync());
  }, [token]);

  return <VerificationSetting />;
};

export default View;

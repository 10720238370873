/* eslint-disable indent */
import { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

import VInput from "components/v-input";
import VButton from "components/v-button";

import useTranslation from "hooks/useTranslation";
import APIsUser from "api/users";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { isBusiness, isPage, isGroup, isChannel } from "utils/friends";
import { getUserAsync, getUserByIdAsync } from "redux/reducers/users/users.actions";
// import { getUserByIdAsync } from "redux/reducers/users/users.actions";

const defaultValidate = {
  full_name: null,
  first_name: null,
  last_name: null,
  phone: null,
  vzonex_id: null,
  vzonebank_id: null,
};

const FormAccountSettings = ({ data, isMyProfile }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const userProfile = useAppSelector(getUserProfile);
  const userData = data || userProfile;

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(data);
  const [validate, setValidate] = useState(defaultValidate);
  const [isValid, setIsValid] = useState(false);

  const isAccountOwner =
    isBusiness(userData.object_type) ||
    isPage(userData.object_type) ||
    isGroup(userData.object_type) ||
    isChannel(userData.object_type);

  const disabledField = ["kyc", "verified"].includes(userData?.status_kyc);
  const handleCancel = () => {
    const search = new URLSearchParams(window.location.search);
    search.delete("isEdit");
    history.push(`?${search}`);
  };

  const handleChange = (value, name) => {
    let _state = { ...state };
    _state[name] = value;
    setState(_state);

    performValidate([name], _state);
  };

  const performValidate = (props = [], currentDetail = null) => {
    let _result = isAccountOwner
      ? {
          full_name: null,
          phone: null,
        }
      : {
          first_name: null,
          last_name: null,
          phone: null,
        };
    let _detail = currentDetail || state;
    let _isValid = true;
    if (props.length === 0) {
      for (const property in _result) {
        props.push(property);
      }
    }
    props.forEach((prop) => {
      switch (prop) {
        case "full_name":
          _result[prop] = !_detail.full_name
            ? `${t("Name")} ${t("message.cant-be-empty")}`
            : null;
          break;

        case "first_name":
          _result[prop] = !_detail.first_name
            ? `${t("First name")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "last_name":
          _result[prop] = !_detail.last_name
            ? `${t("Last name")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "phone":
          _result[prop] = !_detail.phone
            ? `${t("Phone")} ${t("message.cant-be-empty")}`
            : null;
          break;
        default:
          break;
      }
    });
    setValidate((prev) => ({
      ...prev,
      ..._result,
    }));

    for (const property in _result) {
      if (_result[property]) {
        _isValid = false;
        break;
      }
    }
    setIsValid(_isValid);
    return _isValid;
  };

  const handleEdit = async () => {
    try {
      const isValid = performValidate([]);
      if (!isValid) return;

      setLoading(true);
      const newData = isAccountOwner
        ? {
            full_name: state.full_name,
            phone: state.phone,
            vzonex_id: state.vzonex_id,
            vzonebank_id: state.vzonebank_id,
            referred_by: data?.referred_by || state.referred_by,
          }
        : {
            full_name: state.full_name,
            last_name: state.last_name,
            first_name: state.first_name,
            phone: state.phone,
            vzonex_id: state.vzonex_id,
            vzonebank_id: state.vzonebank_id,
            referred_by: userData?.referred_by || state.referred_by,
          };
      const res: any = await APIsUser.editUser({
        body: { 
          ...newData,
          user_id: userData?._id,
        },
      });
      if (res) {
        if (isMyProfile) dispatch(getUserAsync());
        dispatch(
          getUserByIdAsync({
            params: {
              id: data._id,
              user_id: data._id,
            },
          })
        );
        handleCancel();
        toast.success(t(`${res}`));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(`${error?.errors}`));
    }
  };

  useEffect(() => {
    setState(data)
  },[data])

  return (
    <Form>
      <Row>
        {isAccountOwner ? (
          <Form.Group className="form-group col-12 col-sm-6">
            <VInput
              label={t("Full name")}
              type="text"
              id="full_name"
              name="full_name"
              disabled={disabledField}
              defaultValue={state.full_name}
              errMessage={validate.full_name}
              onChange={(e) => handleChange(e.target.value, "full_name")}
            />
          </Form.Group>
        ) : (
          <>
            <Form.Group className="form-group col-12 col-sm-6">
              <VInput
                label={t("First name")}
                type="text"
                id="first_name"
                name="first_name"
                disabled={disabledField}
                defaultValue={state.first_name}
                errMessage={validate.first_name}
                onChange={(e) => handleChange(e.target.value, "first_name")}
              />
            </Form.Group>
            <Form.Group className="form-group col-12 col-sm-6">
              <VInput
                label={t("Last name")}
                type="text"
                id="last_name"
                name="last_name"
                disabled={disabledField}
                defaultValue={state.last_name}
                errMessage={validate.last_name}
                onChange={(e) => handleChange(e.target.value, "last_name")}
              />
            </Form.Group>
          </>
        )}
        <Form.Group className="form-group col-12 col-sm-6">
          <VInput
            label={t("Enter email")}
            type="email"
            id="email"
            name="email"
            disabled={true}
            defaultValue={state.email}
          />
        </Form.Group>
        <Form.Group className="form-group col-12 col-sm-6">
          <VInput
            label={t("Phone")}
            type="number"
            id="phone"
            name="phone"
            defaultValue={state.phone}
            errMessage={validate.phone}
            onChange={(e) => handleChange(e.target.value, "phone")}
          />
        </Form.Group>
        {/* <Form.Group className="form-group col-12 col-sm-6">
          <VInput
            label={`Vzonex ID`}
            id="vzonex_id"
            name="vzonex_id"
            defaultValue={state.vzonex_id}
            errMessage={validate.vzonex_id}
            onChange={(e) => handleChange(e.target.value, "vzonex_id")}
          />
        </Form.Group>
        <Form.Group className="form-group col-12 col-sm-6">
          <VInput
            label={`VzoneBank ID`}
            id="vzonebank_id"
            name="vzonebank_id"
            defaultValue={state.vzonebank_id}
            errMessage={validate.vzonebank_id}
            onChange={(e) => handleChange(e.target.value, "vzonebank_id")}
          />
        </Form.Group> */}
        <Form.Group className="form-group col-12 col-sm-6">
          <VInput
            label={t("Referral code")}
            type="text"
            id="referred_by"
            name="referred_by"
            defaultValue={state.referred_by}
            disabled={Boolean(userData.referred_by)}
            onChange={(e) => handleChange(e.target.value, "referred_by")}
          />
        </Form.Group>
      </Row>
      <VButton
        loading={loading}
        height={"36px"}
        className="me-2"
        disabled={!isValid}
        onClick={handleEdit}
      >
        {t("save")}
      </VButton>
      <VButton
        loading={loading}
        onClick={handleCancel}
        height={"36px"}
        className="btn bg-danger border-danger"
      >
        {t("Cancel")}
      </VButton>
    </Form>
  );
};

export default FormAccountSettings;

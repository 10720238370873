/* eslint-disable */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Marker,
  Circle,
  InfoWindow,
  DirectionsService,
  DirectionsRenderer,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { Library } from "@googlemaps/js-api-loader";
import { DEFAULT_RADIUS } from "constants/provinces";
import { DEFAULT_MAP_ZOOM, MapContext } from "pages/sos/maps/MapsContext";
import { MapContext as SosMapContext } from "components/smart-maps/context";
import { useAppSelector } from "redux/reducers/hook";
import { getSosCategories } from "redux/reducers/categories/categories.selector";

import { FloatButton, Input, Switch } from "antd";
import { ROUTES } from "config/routes";
import { useHistory } from "react-router-dom";
import Spinner from "components/spinner";
import CategoryList from "components/menu-category/category-list";
import Button from "components/v-button";
import { getProfile } from "redux/reducers/users/users.selector";
import Collapse from "react-bootstrap/Collapse";
import { FaLocationArrow } from "react-icons/fa";
import { toast } from "react-toastify";
import useQuery from "hooks/useQuery";
import { getQueryString, getUrlImage, getUrlProfile } from "utils/common";
import WebViewTab from "components/v-right-view/tab-views/Webview";
import UserCategory from "components/smart-maps/components/user-category";
import PostCategory from "components/smart-maps/components/post-category";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Col, Row } from "react-bootstrap";
import Modal from "components/v-modal";
import { t } from "i18next";
import APIs from "api";
import MapFilterSelector from "../MapFilterSelector";
import { WEB_MODULE_URL } from "config/env";
import UserPreview from "pages/social/user/preview";

const PIXEL_ICON_SIZE_WITH_ZOOM_SM = [
  2, 3, 4, 5, 6, 7, 8, 10, 12, 14, 16, 18, 20, 22, 24, 25, 25, 25, 25, 25,
];
const PIXEL_ICON_SIZE_WITH_ZOOM_MD = [
  2, 3, 5, 8, 10, 14, 16, 19, 22, 25, 28, 31, 35, 40, 45, 50, 50, 50, 50, 50,
];
const PIXEL_ICON_SIZE_WITH_ZOOM_LG = [
  2, 3, 5, 8, 10, 15, 20, 25, 32, 40, 50, 53, 70, 90, 105, 125, 125, 125, 125,
  125,
];

const PIXEL_ICON_SIZE_WITH_ZOOM = {
  sm: PIXEL_ICON_SIZE_WITH_ZOOM_SM,
  md: PIXEL_ICON_SIZE_WITH_ZOOM_MD,
  lg: PIXEL_ICON_SIZE_WITH_ZOOM_LG,
};

const MAPS_OPTION: google.maps.MapOptions = {
  disableDefaultUI: true,
  zoomControl: true,
  controlSize: 25,
  streetViewControlOptions: {
    position: 10,
  },
  streetViewControl: true,
};

function GoogleMapAPI({ googleKey }) {
  const history = useHistory();
  const params = useQuery();
  const { categoryId: queryCategoryId, objectId: queryObjectId } = params;

  const [directionResponse, setDirectionResponse] =
    useState<google.maps.DirectionsResult>();

  const [userDirectory, setUserDirectory] = useState<{
    value: string;
    label?: string;
    children?: object;
  }>();
  const [libraries] = useState<Library[]>(["places"]);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: googleKey,
    libraries,
  });

  const allCategories = useAppSelector(getSosCategories);

  const mapContext = useContext(MapContext);
  const { objectFiles } = useContext(SosMapContext);
  const users = useAppSelector(getProfile);

  const [mapToolOption, setMapToolOption] = useState({
    placeLabel: true,
    placeDetail: true,
    addressName: true,
  });

  const {
    posts,
    coords,
    myLocation,
    setMap,
    isLoading,
    objectUsers,
    objectUserSelected,
    setObjectUserSelected,
    selectedPostId,
    destinationCoords,
    setDestinationCoords,
    setSelectedPostId,
    map,
    mapSetting,
    setCoords,
    fetchMapMarkers,
  } = mapContext;

  const [mapZoom, setMapZoom] = useState(DEFAULT_MAP_ZOOM);
  const [allMarkers, setAllMarkers] = useState<
    { marker: google.maps.Marker; size: "md" | "sm" | "lg" }[]
  >([]);

  const [travelMode] = useState<google.maps.TravelMode>(
    "DRIVING" as google.maps.TravelMode
  );
  const [placeSearchBox, setPlaceSearchBox] =
    useState<google.maps.places.SearchBox>();

  const [searchText, setSearchText] = useState<string>("");

  const dispatch = useDispatch();
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const [hoverUserTimeout, setHoverUserTimeout] = useState(null);
  const [isShowUserInfo, setIsShowUserInfo] = useState(true);
  const [isShowSearchMapUser, setIsShowSearchMapUser] = useState(false);

  const [previewUser, setPreviewUser] = useState("");
  const [isShowUserCategoryModal, setIsShowUserCategoryModal] = useState(false);
  const [isShowPostCategoryModal, setIsShowPostCategoryModal] = useState(false);

  const [postDirectory, setPostDirectory] = useState<any>("");

  const userCardRef = useRef(null);
  const userCardProfileRef = useRef(null);

  useEffect(() => {
    const pushMessage = (event) => {
      if (event.data && !event?.data?.source && !event?.data?.type) {
        const { height = 0, userId } = JSON.parse(event.data);
        const elm = userCardRef?.current as Element;

        if (elm) {
          const iframe = elm?.firstChild?.firstChild as any;
          if (iframe?.getAttribute("src")?.includes(userId)) {
            iframe.style.height = `${height - 1}px`;
            iframe.parentElement.nextSibling.style.display = "block";
          }
        }

        const elm1 = userCardProfileRef?.current as Element;
        if (elm1) {
          const iframe1 = elm1?.firstChild?.firstChild as any;
          if (iframe1?.getAttribute("src")?.includes(userId))
            iframe1.style.height = `${height - 1}px`;
        }
      }
    };

    window.addEventListener("message", pushMessage);

    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      window.removeEventListener("message", pushMessage);

      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  const containerStyle = {
    width: "100%",
    height: "100vh",
    transition: "height 0.5s ease-in-out",
  };

  const [placeResult, setPlaceResult] = useState<
    google.maps.places.PlaceResult[]
  >([]);
  const [hoverMarker, setHoverMarker] = useState<{ userId?: string }>();

  const setMapBoundDirection = (myLocation, destinationCoords) => {
    var bounds = new google.maps.LatLngBounds();
    bounds.extend(myLocation);
    bounds.extend(destinationCoords);
    map.fitBounds(bounds);
  };

  const onLoad = React.useCallback(
    (loadedMap: google.maps.Map) => {
      loadedMap.setOptions(MAPS_OPTION);
      coords && loadedMap.setCenter(coords);
      coords && loadedMap.setZoom(DEFAULT_MAP_ZOOM);
      loadedMap.addListener("zoom_changed", () => {
        setMapZoom(loadedMap.getZoom() || 0);
      });
      setMap(loadedMap);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [coords]
  );

  const onInputSearchLoad = (ref: google.maps.places.SearchBox) =>
    setPlaceSearchBox(ref);

  const onPlacesChanged = () => {
    setPlaceResult(placeSearchBox?.getPlaces() || []);
    setSearchText("");
  };

  const onUnmount = React.useCallback(
    function callback(map: any) {
      setMap();
    },
    [setMap]
  );

  const mapStyleOptions = () => {
    let optionStyle: google.maps.MapTypeStyle[] = [];

    if (!mapToolOption.addressName) {
      optionStyle = [
        ...optionStyle,
        ...[
          {
            featureType: "administrative.land_parcel",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "poi",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road.local",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      ];
    }
    if (!mapToolOption.addressName) {
      optionStyle = [
        ...optionStyle,
        ...[
          {
            featureType: "administrative.neighborhood",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "water",
            elementType: "labels.text",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
          {
            featureType: "road",
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      ];
    }
    if (!mapToolOption.placeLabel) {
      optionStyle = [
        ...optionStyle,
        ...[
          {
            elementType: "labels",
            stylers: [
              {
                visibility: "off",
              },
            ],
          },
        ],
      ];
    }
    return optionStyle;
  };

  const toggleMarkerDetail = () => {
    setHoverMarker({});
    removeDirection();
    history.push(
      getQueryString(params, [
        "delete_postId",
        "delete_userId",
        "delete_directionId",
      ])
    );
  };

  const directionsCallback = (
    response: google.maps.DirectionsResult | null,
    status: google.maps.DirectionsStatus
  ) => {
    if (response && status === "OK") {
      const directionId = selectedPostId
        ? selectedPostId
        : objectUserSelected.userId;
      history.push(getQueryString({ ...params, directionId }));
      setDirectionResponse(response);
      setMapBoundDirection(myLocation, destinationCoords);
    }
  };

  const redirectToProfileUser = (user: any) => {
    window.open(getUrlProfile(user));
  };

  const redirectToPreviewUser = (user: any) => {
    setPreviewUser(user._id);
  };

  const redirectToPost = (id: any) => {
    window.open(ROUTES.POST_DETAIL.replace(":id", id));
  };

  const _renderPostMarkers = (post: any, index: number) => {
    const postCoordinate = {
      lat: post?.post_place?.geometry?.location?.lat,
      lng: post?.post_place?.geometry?.location?.lng,
    };

    if (!postCoordinate?.lat || !postCoordinate?.lng) return null;

    const postCategory = allCategories.find(
      ({ _id }) => _id === post.categoryId
    );
    const marketIconURLDefault = `${window.location.origin}/assets/images/icons/post_map_img.svg`;
    const postImage = post?.post_files[0]?.post_file_path;
    const marketIconURL = postImage
      ? getUrlImage(postImage, 700)
      : marketIconURLDefault;
    const categoryMapIconSize = postImage
      ? "md"
      : postCategory?.mapLogoSize || "lg";

    return (
      <Marker
        key={`profile_${index}`}
        onLoad={(marker) => {
          setAllMarkers((oldState) => [
            ...oldState,
            { marker, size: categoryMapIconSize },
          ]);
        }}
        zIndex={index + 2000}
        position={postCoordinate}
        shape={{ coords: [25, 25, 25], type: "circle" }}
        icon={{
          url: marketIconURL,
          size: new window.google.maps.Size(
            PIXEL_ICON_SIZE_WITH_ZOOM[categoryMapIconSize][
              (map?.getZoom() || 0) - 1
            ],
            PIXEL_ICON_SIZE_WITH_ZOOM[categoryMapIconSize][
              (map?.getZoom() || 0) - 1
            ]
          ),
          scaledSize: new window.google.maps.Size(
            PIXEL_ICON_SIZE_WITH_ZOOM[categoryMapIconSize][
              (map?.getZoom() || 0) - 1
            ],
            PIXEL_ICON_SIZE_WITH_ZOOM[categoryMapIconSize][
              (map?.getZoom() || 0) - 1
            ]
          ),
        }}
        onClick={() => {
          history.push(
            getQueryString(params, [`add_postId_${post._id}`, "delete_userId"])
          );
        }}
      ></Marker>
    );
  };

  const objectFileDefault = useMemo(() => {
    if (objectFiles.setting) {
      const {
        setting: { value: objectFileList },
      } = objectFiles;
      const objectFile = objectFileList.find((object) => object.key == "USER");
      return getUrlImage(objectFile?.icon_maps);
    }

    return null;
  }, [objectFiles]);

  const _renderObjectUsersMarkers = (objectUser: any, index: number) => {
    const markerCoordinates = {
      lat: +objectUser.coordinate.lat,
      lng: +objectUser.coordinate.lng,
    };

    const objectMapIconSize: "sm" | "md" | "lg" = "lg";

    if (!objectFiles.setting) return null;

    const {
      setting: { value: objectFileList },
    } = objectFiles;

    const objectFile = objectFileList.find(
      (object) => object.key == objectUser.object_type
    );
    const marketIconURL = objectFile
      ? getUrlImage(objectFile?.icon_maps)
      : objectFileDefault;

    return (
      <Marker
        key={`post_${index}`}
        zIndex={index + 1000}
        position={markerCoordinates}
        shape={{ coords: [25, 25, 25], type: "circle" }}
        icon={{
          url: marketIconURL,
          size: new window.google.maps.Size(
            PIXEL_ICON_SIZE_WITH_ZOOM[objectMapIconSize][
              (map?.getZoom() || 0) - 1
            ],
            PIXEL_ICON_SIZE_WITH_ZOOM[objectMapIconSize][
              (map?.getZoom() || 0) - 1
            ]
          ),
          scaledSize: new window.google.maps.Size(
            PIXEL_ICON_SIZE_WITH_ZOOM[objectMapIconSize][
              (map?.getZoom() || 0) - 1
            ],
            PIXEL_ICON_SIZE_WITH_ZOOM[objectMapIconSize][
              (map?.getZoom() || 0) - 1
            ]
          ),
        }}
        onLoad={(marker) => {
          setAllMarkers((oldState) => [
            ...oldState,
            { marker, size: objectMapIconSize },
          ]);
        }}
        onClick={() => {
          clearTimeout(hoverUserTimeout);
          setHoverUserTimeout({});

          history.push(
            getQueryString(params, [
              `add_userId_${objectUser?.user?._id}`,
              "delete_postId",
            ])
          );
        }}
        onMouseOver={() => {
          const timeout = setTimeout(
            () => setHoverMarker({ userId: objectUser?.user?._id }),
            500
          );
          setHoverUserTimeout(timeout);
        }}
        onMouseOut={(e) => {
          console.log(e);
        }}
      >
        {hoverMarker && _renderObjectUserDetail(objectUser?.user?._id)}
      </Marker>
    );
  };

  const removeDirection = () => {
    setDestinationCoords(undefined);
    setDirectionResponse(undefined);
  };

  const closeUserCollapse = () => {
    removeDirection();
    setObjectUserSelected({});
    map?.setZoom(DEFAULT_MAP_ZOOM);
    history.push(
      getQueryString(params, ["delete_userId", "delete_directionId"])
    );
  };

  const closePostCollapse = () => {
    setSelectedPostId("");
    history.push(getQueryString(params, ["delete_postId"]));
  };

  const closeSearchUserCollapse = () => {
    setIsShowSearchMapUser(false);
  };

  const _renderObjectUserDetail = (id: string) => {
    return (
      hoverMarker.userId &&
      hoverMarker.userId === id && (
        <InfoWindow>
          <div className="position-relative" ref={userCardRef}>
            <WebViewTab
              url={`${WEB_MODULE_URL}/user-card/${id}?origin=${
                window.origin
              }&vdb_token=${localStorage.getItem(
                "auth"
              )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&map=1&lang=vi`}
              minHeight="0px"
              width="326px"
            />
            <Button
              className="btn-close-collapse"
              style={{ display: "none" }}
              onClick={() => setHoverMarker({})}
            >
              <img src={"/assets/icons/sos-map/fa-close.svg"} alt="" />
            </Button>
          </div>
        </InfoWindow>
      )
    );
  };

  const saveUserCategory = () => {
    setIsShowUserCategoryModal(true);
    setUserDirectory(null);
  };

  const savePostCategory = () => {
    setIsShowPostCategoryModal(true);
  };

  const _renderSearchMapObject = () => {
    return (
      <Collapse in dimension="width">
        <div
          className={`map-object-search ${isShowSearchMapUser ? "open" : ""}`}
          style={{
            minHeight: "100%",
            height: "100%",
          }}
        >
          <div className="map-detail-wrapper">
            <Button
              className="btn-close-collapse"
              onClick={closeSearchUserCollapse}
            >
              <img src={"/assets/icons/sos-map/fa-close.svg"} alt="" />
            </Button>

            <MapFilterSelector />
          </div>
        </div>
      </Collapse>
    );
  };

  const _renderUserProfile = () => {
    const selectedUser = objectUsers.find(
      (objectUser) => objectUser?.user._id === objectUserSelected.userId
    );

    if (!selectedUser) return null;

    const {
      coordinate,
      gender,
      count,
      user,
      user: { _id, website, location_detail, createdAt },
    } = selectedUser || {};

    const markerCoordinates = {
      lat: coordinate.lat,
      lng: coordinate.lng,
    };

    return (
      <Collapse in dimension="width">
        <div
          className="map-place-detail"
          style={{
            minHeight: "100%",
            height: "100%",
          }}
        >
          <div className="map-detail-wrapper">
            <Button className="btn-close-collapse" onClick={closeUserCollapse}>
              <img src={"/assets/icons/sos-map/fa-close.svg"} alt="" />
            </Button>
            <div className="map-post-wrapper">
              <div className="map-preview d-flex justify-content-space-between">
                <div className="w-100">
                  <div className="map-iw" ref={userCardProfileRef}>
                    <WebViewTab
                      url={`${WEB_MODULE_URL}/user-card/${_id}?origin=${
                        window.origin
                      }&vdb_token=${localStorage.getItem(
                        "auth"
                      )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&map=1&lang=vi`}
                      minHeight="310px"
                    />
                  </div>

                  <Row className="border">
                    <Col
                      xs={6}
                      className={`py-2 text-center cursor-pointer ${
                        isShowUserInfo
                          ? "text-primary-map border-bottom border-primary"
                          : ""
                      }`}
                      onClick={() => setIsShowUserInfo(true)}
                    >
                      <span>{t("Thông tin")}</span>
                    </Col>
                    <Col
                      xs={6}
                      className={`py-2 text-center cursor-pointer ${
                        !isShowUserInfo
                          ? "text-primary-map border-bottom border-primary"
                          : ""
                      }`}
                      onClick={() => setIsShowUserInfo(false)}
                    >
                      <span>{t("Map")}</span>
                    </Col>
                  </Row>

                  <div className="py-3 mb-3 border-bottom">
                    <div className="d-flex align-items-baseline justify-content-center marp-user_function">
                      <Button
                        variant="link"
                        className={`w-25 p-0 travel-mode-unselected`}
                        onClick={() => {
                          !directionResponse &&
                            setDestinationCoords(markerCoordinates);
                          history.push(
                            getQueryString(params, ["delete_directionId"])
                          );
                          directionResponse && removeDirection();
                        }}
                      >
                        <img
                          src={"/assets/icons/sos-map/map_direction.svg"}
                          alt="xem"
                          className="px-1"
                        />
                        <p className="text-primary-map lh-1 mb-0 mt-1">
                          {t("Direct")}
                        </p>
                      </Button>
                      <Button
                        variant="link"
                        className={`w-25 p-0 travel-mode-unselected`}
                        onClick={saveUserCategory}
                      >
                        <img
                          src={"/assets/icons/sos-map/map_save.svg"}
                          alt="xem"
                          className="px-1"
                        />
                        <p className="text-primary-map lh-1 mb-0 mt-1">
                          {t("Save")}
                        </p>
                      </Button>
                      <Button
                        variant="link"
                        className={`w-25 p-0 travel-mode-unselected`}
                        onClick={() => redirectToPreviewUser(user)}
                      >
                        <img
                          src={"/assets/icons/sos-map/map_share.svg"}
                          alt="xem"
                          className="px-1"
                        />
                        <p className="text-primary-map lh-1 mb-0 mt-1">
                          {t("Share")}
                        </p>
                      </Button>
                    </div>
                  </div>

                  {isShowUserInfo && (
                    <div className="user-info m-2">
                      {location_detail && (
                        <p className="d-flex align-items-center">
                          <img
                            src={"/assets/icons/sos-map/fa-home.svg"}
                            alt="xem"
                          />
                          <span>Sinh sống tại {location_detail}</span>
                        </p>
                      )}
                      <p className="d-flex align-items-center">
                        <img
                          src={"/assets/icons/sos-map/fa-gender.svg"}
                          alt="xem"
                        />
                        <span>Giới tính {t(gender)}</span>
                      </p>
                      {website && (
                        <p className="d-flex align-items-center">
                          <img
                            src={"/assets/icons/sos-map/fa-web.svg"}
                            alt="xem"
                          />
                          <span>{website}</span>
                        </p>
                      )}
                      {count?.friend && (
                        <p className="d-flex align-items-center">
                          <img
                            src={"/assets/icons/sos-map/fa-gender.svg"}
                            alt="xem"
                          />
                          <span>{count?.friend} bạn bè</span>
                        </p>
                      )}
                      <p className="d-flex align-items-center">
                        <img
                          src={"/assets/icons/sos-map/fa-datetime.svg"}
                          alt="xem"
                        />
                        <span>
                          Ngày lập:{" "}
                          {new Date(createdAt).toLocaleString("en-GB")}
                        </span>
                      </p>
                      <p className="d-flex align-items-center">
                        <img
                          src={"/assets/icons/sos-map/fa-location.svg"}
                          alt="xem"
                        />
                        <span>Đến từ Việt Nam</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Button
            className="btn-user-detail"
            onClick={() => redirectToProfileUser(user)}
          >
            <img src="/assets/icons/sos-map/fa-pencil.svg" /> Xem chi tiết
          </Button>
        </div>
      </Collapse>
    );
  };

  const _renderPostPreview = () => {
    const selectedPost = posts.find((post) => post._id === selectedPostId);

    if (!selectedPost) return null;

    return (
      <Collapse in dimension="width">
        <div
          className="map-place-detail post"
          style={{ minHeight: "100%", height: "100%" }}
        >
          <Button className="btn-close-collapse" onClick={closePostCollapse}>
            <img src={"/assets/icons/sos-map/fa-close.svg"} alt="" />
          </Button>
          <WebViewTab
            url={`${WEB_MODULE_URL}/posts/${selectedPostId}?origin=${
              window.origin
            }&vdb_token=${localStorage.getItem(
              "auth"
            )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&postShareId=undefined&lang=vi&page=undefined&plugin=undefined&map=1`}
            minHeight="calc(100vh - 165px)"
          />
          <div className="d-flex justify-content-center">
            <Button
              className="btn-user-detail"
              onClick={() => redirectToPost(selectedPostId)}
            >
              <img src="/assets/icons/sos-map/fa-pencil.svg" /> Xem chi tiết
            </Button>
            <Button
              className="btn-user-detail"
              onClick={() => savePostCategory()}
            >
              <img src={"/assets/icons/sos-map/map_save.svg"} alt="xem" />
              {t("Save")}
            </Button>
          </div>
        </div>
      </Collapse>
    );
  };

  const _renderPlaceResultBox = (place: google.maps.places.PlaceResult) => {
    return (
      place.geometry?.location && (
        <InfoWindow position={place.geometry?.location}>
          <div className="map-preview profile-card">
            <h5>{place.name}</h5>
            {place.types?.length && <div>{place.types[0]}</div>}
            <div>{place.formatted_address}</div>
            <div>{place.vicinity}</div>

            <a
              className="py-1"
              target="_blank"
              href={place.url}
              rel="noreferrer"
            >
              Xem trên Google Maps
            </a>
          </div>
        </InfoWindow>
      )
    );
  };

  useEffect(() => {
    allMarkers.forEach(({ marker, size }) => {
      const markerIcon: google.maps.Icon = marker.getIcon() as google.maps.Icon;
      marker.setIcon({
        ...markerIcon,
        size: new window.google.maps.Size(
          PIXEL_ICON_SIZE_WITH_ZOOM[size][mapZoom - 1],
          PIXEL_ICON_SIZE_WITH_ZOOM[size][mapZoom - 1]
        ),
        scaledSize: new window.google.maps.Size(
          PIXEL_ICON_SIZE_WITH_ZOOM[size][mapZoom - 1],
          PIXEL_ICON_SIZE_WITH_ZOOM[size][mapZoom - 1]
        ),
      });
    });
  }, [mapZoom, allMarkers]);

  useEffect(() => {
    map?.setOptions({
      styles: mapStyleOptions(),
      ...MAPS_OPTION,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mapToolOption]);

  useEffect(() => {
    if (placeResult.length && placeResult[0].geometry?.location) {
      map?.setCenter(placeResult[0].geometry?.location);
      setCoords({
        lat: placeResult[0].geometry?.location.lat(),
        lng: placeResult[0].geometry?.location.lng(),
      });
      map?.setZoom(DEFAULT_MAP_ZOOM);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [placeResult]);

  const directionDetail = useMemo(
    () =>
      directionResponse?.routes?.[0] && {
        duration: directionResponse?.routes?.[0].legs?.[0]?.duration?.text,
        distance: directionResponse?.routes?.[0].legs?.[0]?.distance?.text,
        midPoint: {
          lat: directionResponse?.routes?.[0].overview_path?.[
            +(directionResponse.routes[0].overview_path.length / 2).toFixed()
          ].lat(),
          lng: directionResponse?.routes?.[0].overview_path?.[
            +(directionResponse.routes[0].overview_path.length / 2).toFixed()
          ].lng(),
        },
      },
    [directionResponse]
  );

  const saveUserCategoryHandle = async () => {
    try {
      if (!userDirectory) {
        toast.error(t("Vui lòng chọn danh mục của bạn."));
        return;
      }

      await APIs.DEPARTMENT.addMemberToDepartment({
        body: {
          member_id: objectUserSelected.userId,
          department_id: userDirectory?.value ?? "",
          user_id: users._id,
        },
      });

      setIsShowUserCategoryModal(false);
      toast.success(t("Thành công."));
    } catch (error) {
      //
    }
  };

  const savePostCategoryHandle = async () => {
    try {
      if (!postDirectory) {
        toast.error(t("Vui lòng chọn thư mục để lưu post."));
        return;
      }

      const selectedPost: any = posts.find(
        (post) => post._id === selectedPostId
      );

      await APIs.POSTS.createPost({
        body: {
          post_shared: {
            post_shared_id: selectedPost?.id,
            post_original_shared_id: selectedPost?.id,
          },
          post_plugins: selectedPost?.post_plugins ?? [],
          directory_id: postDirectory?.value ?? "",
        },
      });

      setPostDirectory("");
      setIsShowPostCategoryModal(false);
      toast.success(t("Thành công."));
    } catch (error) {
      toast.error(error?.errors);
    }
  };

  return (
    <Spinner loading={isLoading || !isLoaded}>
      <div className="GoogleMap">
        {isLoaded && coords && (
          <>
            <GoogleMap
              mapContainerStyle={containerStyle}
              zoom={DEFAULT_MAP_ZOOM}
              center={coords}
              onLoad={onLoad}
              onUnmount={onUnmount}
              onClick={() => {
                toggleMarkerDetail();
                setPlaceResult([]);
              }}
            >
              <div
                className={`category-map-filter px-2 ${
                  selectedPostId ? "post-selected" : "post-unselected"
                }`}
              >
                <CategoryList
                  categoriesFilter={mapSetting?.mapAllowedCategories}
                  onCategoryChange={(id) => {
                    if (id !== "all") {
                      setSelectedPostId("");
                      history.push(
                        getQueryString(params, [
                          `add_categoryId_${id}`,
                          "delete_postId",
                        ])
                      );
                    } else {
                      setSelectedPostId("");
                      history.push(
                        getQueryString(params, [
                          "delete_categoryId",
                          "delete_postId",
                        ])
                      );
                    }
                  }}
                />
              </div>
              <div className="location-map-filter">
                {myLocation && (
                  <Button
                    className="d-flex location-map-btn"
                    onClick={() => {
                      map && map.setCenter(myLocation);
                      map && map.setZoom(DEFAULT_MAP_ZOOM);
                      history.push(ROUTES.SOS_MAP);
                      setCoords(myLocation);
                      fetchMapMarkers();
                      setPlaceResult([]);
                    }}
                  >
                    <img
                      width={18}
                      height={18}
                      src={"/assets/images/icons/my-location.svg"}
                      alt="location"
                    />
                  </Button>
                )}
              </div>
              <div className="map-tool">
                <div className="px-1">
                  <div className="px-1">Công cụ</div>
                  <div className="d-flex">
                    <div className="d-flex align-items-center">
                      <div className="px-1">Place icon</div>
                      <Switch
                        size="small"
                        defaultChecked
                        checked={mapToolOption.placeLabel}
                        onChange={(value) =>
                          setMapToolOption((oldState) => ({
                            ...oldState,
                            placeLabel: value,
                          }))
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="px-1">Street name</div>
                      <Switch
                        size="small"
                        defaultChecked
                        checked={mapToolOption.addressName}
                        onChange={(value) =>
                          setMapToolOption((oldState) => ({
                            ...oldState,
                            addressName: value,
                          }))
                        }
                      />
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="px-1">Place detail</div>
                      <Switch
                        size="small"
                        checked={mapToolOption.placeDetail}
                        onChange={(value) =>
                          setMapToolOption((oldState) => ({
                            ...oldState,
                            placeDetail: value,
                          }))
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
              <FloatButton
                shape="circle"
                type="primary"
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}${ROUTES.SOS_MAP}?userId=${users._id}`
                  );
                  toast.success("Đã sao chép vị trí của bạn");
                }}
                style={{
                  right: "5px",
                  bottom: window.innerWidth <= 577 ? "45px" : "80px",
                  position: "absolute",
                }}
                icon={<FaLocationArrow />}
              />
              {/* Child components, such as markers, info windows, etc. */}
              {!queryObjectId &&
                posts
                  .filter((post) =>
                    !queryCategoryId
                      ? true
                      : post.categoryId === queryCategoryId
                  )
                  .map((post, index) => _renderPostMarkers(post, index))}
              {!queryCategoryId &&
                objectUsers
                  .filter((objectUser) => objectUser?.user?._id !== users?._id)
                  .filter((objectUser) =>
                    !queryObjectId
                      ? true
                      : queryObjectId === "user"
                      ? !objectUser?.object?._id
                      : objectUser?.object?._id === queryObjectId
                  )
                  .map(
                    (objectUser, index) =>
                      objectUser.coordinate?.lat &&
                      objectUser.coordinate?.lng &&
                      _renderObjectUsersMarkers(objectUser, index)
                  )}

              {coords && (
                <Circle
                  center={coords}
                  radius={mapSetting.mapRadius || DEFAULT_RADIUS}
                  options={{
                    strokeColor: "#4284F3",
                    fillOpacity: 0.08,
                  }}
                  onClick={() => {
                    toggleMarkerDetail();
                    setPlaceResult([]);
                  }}
                />
              )}
              {!directionResponse &&
                myLocation?.lat &&
                destinationCoords?.lat && (
                  <DirectionsService
                    options={{
                      destination: destinationCoords,
                      origin: myLocation,
                      travelMode,
                    }}
                    callback={directionsCallback}
                  />
                )}
              {directionResponse && (
                <DirectionsRenderer
                  options={{
                    directions: directionResponse,
                    polylineOptions: {
                      strokeColor: "#4284f3",
                      strokeOpacity: 1.0,
                      strokeWeight: 6,
                    },
                    suppressMarkers: true,
                    preserveViewport: true,
                  }}
                />
              )}
              {directionDetail && (
                <InfoWindow position={directionDetail.midPoint}>
                  <div className="map-preview profile-card">
                    <div>Khoảng cách: {directionDetail.distance}</div>
                    <div>Thời gian: {directionDetail.duration}</div>
                  </div>
                </InfoWindow>
              )}
              {myLocation && (
                <Marker
                  icon={"/assets/images/gif/google-marker-40x40.gif"}
                  position={myLocation}
                />
              )}
              {placeResult.length &&
                placeResult.map((place) => _renderPlaceResultBox(place))}
              <StandaloneSearchBox
                onLoad={onInputSearchLoad}
                onPlacesChanged={onPlacesChanged}
              >
                <Input.Search
                  onChange={(e) => setSearchText(e.target.value)}
                  value={searchText}
                  placeholder="Tìm kiếm địa điểm"
                  className="map-search-input"
                  size="large"
                />
              </StandaloneSearchBox>
              <button
                onClick={() => setIsShowSearchMapUser(!isShowSearchMapUser)}
                className="btn-search_advance bg-primary-map px-2"
              >
                <img
                  className="mr-1"
                  src={"/assets/icons/sos-map/filter.svg"}
                  alt=""
                />
                {t("Bộ lọc nâng cao")}
              </button>
            </GoogleMap>
            <Modal
              isOpen={previewUser}
              centered={true}
              title={t("Share")}
              size="lg"
              onCancel={() => setPreviewUser("")}
            >
              <UserPreview userId={previewUser} />
            </Modal>
            {isShowUserCategoryModal && (
              <Modal
                isOpen={true}
                centered={true}
                title={t("Save")}
                onCancel={() => setIsShowUserCategoryModal(false)}
                onConfirm={saveUserCategoryHandle}
              >
                <UserCategory
                  userId={users._id}
                  userDirectory={userDirectory}
                  setUserDirectory={setUserDirectory}
                />
              </Modal>
            )}

            {isShowPostCategoryModal && (
              <Modal
                isOpen={true}
                centered={true}
                title={t("Save")}
                onCancel={() => setIsShowPostCategoryModal(false)}
                onConfirm={savePostCategoryHandle}
              >
                <PostCategory
                  userId={users._id}
                  postDirectory={postDirectory}
                  setPostDirectory={setPostDirectory}
                  isOnlySave={true}
                />
              </Modal>
            )}
            {_renderUserProfile()}
            {_renderPostPreview()}
            {_renderSearchMapObject()}
          </>
        )}
      </div>
    </Spinner>
  );
}

export default GoogleMapAPI;

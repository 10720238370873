/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import {
  getPageFollowingsAsync,
  getPageManageAsync,
  getPageSuggestsAsync,
} from "./pages.action";

export interface IPageState {
  suggests: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  manage: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  followings: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  dataAccesses: any;
}

const initialState: IPageState = {
  suggests: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  manage: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  followings: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  dataAccesses: {},
};

export const Pages = createSlice({
  name: "pages",
  initialState,
  reducers: {
    removePageSuggest: (state: IPageState, action: any) => {
      const { docs } = state.suggests;
      state.suggests.docs = docs.filter((f) => f._id !== action.payload);
      state.suggests.totalDocs = state.suggests.totalDocs - 1;
    },
    setDataAccesses: (state: IPageState, action) => {
      const data = action.payload;
      data.forEach((item) => {
        state.dataAccesses[item.user_id] = item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPageSuggestsAsync.pending, (state) => {})
      .addCase(getPageSuggestsAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.suggests = action.payload || {};
        } else {
          state.suggests = {
            ...state.suggests,
            page,
            docs: state.suggests.docs.concat(docs),
          };
        }
      })
      .addCase(getPageManageAsync.pending, (state) => {})
      .addCase(getPageManageAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.manage = action.payload || {};
        } else {
          state.manage = {
            ...state.manage,
            page,
            docs: state.manage.docs.concat(docs),
          };
        }
      })
      .addCase(getPageFollowingsAsync.pending, (state) => {})
      .addCase(getPageFollowingsAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.followings = action.payload || {};
        } else {
          state.followings = {
            ...state.followings,
            page,
            docs: state.followings.docs.concat(docs),
          };
        }
      });
  },
});

export const { setDataAccesses, removePageSuggest } = Pages.actions;

export default Pages.reducer;

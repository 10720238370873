const View = ({
  data,
  onload,
  dataUser = { _id: "", object_type: "USER" },
}) => {
  return (
    <div className="wrapper-general-setting">
      <iframe
        className={`iframe-privacy`}
        src={`${process.env.REACT_APP_WEB_CHAT_URL}/privacy-permission/${
          dataUser?._id
        }?role_name=${dataUser?.object_type}&vdb_token=${localStorage.getItem(
          "auth"
        )}`}
        title="privacy-permission"
      />
    </div>
  );
};

export default View;

/* eslint-disable array-callback-return */
/* eslint-disable indent */

import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import VQRCode from "components/v-qr-code";
import { useDispatch, useSelector } from "react-redux";
// import { exportComponentAsJPEG } from "react-component-export-image";
import VButton from "components/v-button";
import { useAppSelector } from "redux/reducers/hook";
import APIs from "api";
import { editUser } from "redux/reducers/users/users.reducer";
import { getLogo } from "redux/reducers/settings/settings.selector";
import { getUserAsync } from "redux/reducers/users/users.actions";
import useTranslation from "hooks/useTranslation";
import { copyText, getUrlImage, getUrlProfile } from "utils/common";
import * as SettingSelector from "redux/reducers/setting-system/selectors";
import VIcon from "components/v-icon";
import * as htmlToImage from "html-to-image";

const styleEdit = {
  minHeight: "5px",
  minWidth: "30px",
  border: "0.5px solid",
  borderRadius: "4px",
};

const View = (props) => {
  const domain = window.location.origin;
  const { dataUser, refFront, refBack, type, data, background, isSetting } = props;
  const isProfile = type === "profile";

  const themeScheme = useSelector(SettingSelector.theme_scheme);
  const logo = useAppSelector(getLogo);
  const _logoMobile = logo[`logo_mobile_light`];

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const valueByType = dataUser?.profile?.[type];

  const [loading, setLoading] = useState(false);

  const [isEditVisit, setIsEditVisit] = useState<boolean>(false);
  const [listFiles, setListFiles] = useState<any>({});
  const [infoVisit, setInfoVisit] = useState<any>(data || {});
  const [showMessDownload] = useState<boolean>(false);
  const [profile, setProfile] = useState<any>(null);

  useEffect(() => {
    if (dataUser) {
      setProfile(dataUser);
    }
  }, [dataUser]);

  const onBlurText = (e: any) => {
    setInfoVisit((prev) => ({
      ...prev,
      [e.target.getAttribute("data-key")]: e.target.innerText,
    }));
  };

  const handleCopy = (value) => {
    copyText(value);
    toast.success(t("Copied"));
  };

  const handleEdit = async () => {
    setLoading(true);
    let data = {
      ...infoVisit,
    };
    if (Object.keys(listFiles).length > 0) {
      const formData = new FormData();
      formData.append("folderPath", `users/${dataUser?._id}`);
      Object.keys(listFiles).map((el: any) => {
        formData.append("files", listFiles[el]);
      });
      const listImg: any = await APIs.UPLOAD.uploadMultiFile({
        body: formData,
      });
      if (listImg?.length > 0) {
        await Promise.all(
          // eslint-disable-next-line array-callback-return
          listImg?.map((el: any) => {
            Object.keys(listFiles).map((item: any, indexKey: number) => {
              if (el.id === indexKey) {
                data = {
                  ...data,
                  [item]: el?.filePath,
                };
              }
            });
          })
        );
        setListFiles({});
      }
    }
    const user: any = await dispatch(
      editUser({
        params: {
          id: profile._id,
        },
        body: {
          visit_card: {
            ...profile.profile.visit_card,
            [type]: { ...data },
          },
        },
      })
    );

    if (user) {
      const data: any = await dispatch(getUserAsync());
      setInfoVisit(data?.payload?.user?.profile?.visit_card?.[type]);
      setLoading(false);
      toast.success(t("Successfull!"));
    } else {
      toast.error(t("Error"));
      setLoading(false);
    }
  };

  const handleClickCard = async () => {
    if (isEditVisit) {
      await handleEdit();
    }
    setIsEditVisit(!isEditVisit);
  };

  const downloadImage = async (ref, fileName) => {
    const dataUrl = await htmlToImage.toPng(ref.current);

    // download image
    const link = document.createElement("a");
    link.download = fileName;
    link.href = dataUrl;
    link.click();
  };

  const handleDownloadVisit = async () => {
    setLoading(true);
    downloadImage(
      refFront,
      `${profile?.full_name}_visitFront_${new Date().getTime()}` || "vdiarybook"
    );
    downloadImage(
      refBack,
      `${profile?.full_name}_visitBack_${new Date().getTime()}` || "vdiarybook"
    );
    setLoading(false);
  };

  const renderVisit = (hide) => {
    return (
      <>
        <div
          className={`visit-card-wrapper ${
            hide ? "visit-card-wrapper-download" : ""
          }`}
        >
          <div className="visit-card-container">
            <div className="title">
              <span className={`${themeScheme}`}>{t("Front visit card")}</span>
            </div>
            <div className="image" ref={hide ? refFront : null}>
              <img
                src={
                  background?.back
                    ? getUrlImage(background?.back, 500)
                    : "/assets/images/frontvisitcard.png"
                }
                alt="frontvisit"
              />
              <div className="detail-front">
                <div className="logo-visit">
                  <img
                    src={getUrlImage(
                      valueByType?.logo || _logoMobile || "",
                      100
                    )}
                    alt=""
                  />
                </div>
                <div className="content">
                  <div className="name-visit">
                    {(
                      profile?.profile?.[type]?.key || "VDIARYBOOK"
                    ).toUpperCase()}
                  </div>
                  <div
                    className="description-visit"
                    data-key={"descFrontVisit"}
                    contentEditable={isEditVisit}
                    style={isEditVisit ? styleEdit : {}}
                    onBlur={onBlurText}
                  >
                    {infoVisit?.descFrontVisit || "Slogan"}
                  </div>
                </div>
                {isProfile && (
                  <div
                    className="link-visit"
                    data-key={"linkFrontVisit"}
                    contentEditable={isEditVisit}
                    style={isEditVisit ? styleEdit : {}}
                    onBlur={onBlurText}
                  >
                    {infoVisit?.linkFrontVisit || domain}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="visit-card-container">
            <div className="title">
              <span className={`${themeScheme}`}>{t("Back visit card")}</span>
            </div>
            <div className="image" ref={hide ? refBack : null}>
              <img
                src={
                  background?.back
                    ? getUrlImage(background?.back, 500)
                    : "/assets/images/backvisitcard.png"
                }
                alt="backvisit"
              />
              <div className="detail-back">
                <div className="left-detail">
                  <div className="logo-visit">
                    <img src={getUrlImage(profile?.avatar, 300) || ""} alt="" />
                  </div>
                  <div className="content">
                    {isProfile && (
                      <>
                        <div
                          className="name-visit"
                          data-key={"nameBackVisit"}
                          contentEditable={isEditVisit}
                          style={isEditVisit ? styleEdit : {}}
                          onBlur={onBlurText}
                        >
                          {infoVisit?.nameBackVisit || "Vdiarybook"}
                        </div>
                        <div
                          className="description-visit"
                          data-key={"descBackVisit"}
                          contentEditable={isEditVisit}
                          style={isEditVisit ? styleEdit : {}}
                          onBlur={onBlurText}
                        >
                          {infoVisit?.descBackVisit || "Description"}
                        </div>
                      </>
                    )}
                  </div>
                  <VQRCode
                    value={`${domain}${getUrlProfile(profile)}`}
                    size={60}
                    isDownload={false}
                    isCopy={false}
                  />
                </div>

                <div className="right-detail">
                  <div className="header-right">
                    <h5
                      data-key={"name"}
                      contentEditable={isEditVisit && !isProfile}
                      style={isEditVisit ? styleEdit : {}}
                      onBlur={(e) => onBlurText(e)}
                    >
                      {`${
                        isProfile
                          ? profile?.full_name
                          : infoVisit?.name?.toUpperCase() || "Name"
                      }`}
                    </h5>
                    <p
                      data-key={"positionVisit"}
                      contentEditable={isEditVisit}
                      style={isEditVisit ? styleEdit : {}}
                      onBlur={onBlurText}
                    >
                      {infoVisit?.positionVisit || "Position"}
                    </p>
                  </div>
                  {type === "profile" && (
                    <>
                      <div className="content-right">
                        <p data-key={"phone"}>{profile?.phone || "+84..."}</p>
                        <p
                          data-key={"phone2"}
                          contentEditable={isEditVisit}
                          style={isEditVisit ? styleEdit : {}}
                          onBlur={onBlurText}
                        >
                          {infoVisit?.phone2 || "+84..."}
                        </p>
                        <p>{profile?.email}</p>
                        <p
                          data-key={"linkBackVisit"}
                          contentEditable={isEditVisit}
                          style={isEditVisit ? styleEdit : {}}
                          onBlur={onBlurText}
                        >
                          {infoVisit?.linkBackVisit || "www.yourwebsite.com"}
                        </p>
                      </div>
                      <div className="footer-right">
                        <p>{profile?.location_detail || ""}</p>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      {isSetting && <h4 className="mb-2 p-2">{t(type)}</h4>}
      {renderVisit(false)}
      {isSetting && (
        <div className="d-flex justify-content-center py-4">
          <VButton
            onClick={handleClickCard}
            loading={loading}
            height="36px"
            className={"btn btn-primary mx-1"}
          >
            {t(isEditVisit ? "Save" : "Edit")}
          </VButton>
          <VButton
            onClick={handleDownloadVisit}
            loading={loading}
            height="36px"
            className={"btn btn-primary mx-1"}
          >
            {t("Download")}
          </VButton>
          <VButton
            onClick={() =>
              handleCopy(`${domain}${getUrlProfile(profile)}?tab=qr-code`)
            }
            loading={loading}
            height="36px"
            className={"btn btn-primary mx-1"}
          >
            <VIcon name="share.svg" />
          </VButton>
        </div>
      )}
      {showMessDownload && (
        <div className="message-kyc">
          You have not KYC or are waiting for approval.{" "}
          {/* <p onClick={() => setTab("verification")}>Click here !</p> */}
        </div>
      )}
      <div
        style={{
          position: "fixed",
          zIndex: 1000,
          width: "auto",
          height: "100%",
          top: "-10000000px",
          left: "-100000000px",
        }}
      >
        {renderVisit(true)}
      </div>
    </>
  );
};

export default View;

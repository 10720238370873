import { WEB_CHAT_URL } from "config/env";

export const getIframeUrlChat = (
  key: string,
  language: any,
  params?: { id: string }
) => {
  const originDomain = WEB_CHAT_URL;

  const pathKey = {
    conversations: `/conversations`,
    conversation_detail: `/conversations/${params?.id}`,
    // conversation_mini: `/conversations/mini-chat`,
    // mini_chat_detail: `/conversations/mini-chat/${userIdMiniChat}`,
  };

  return `${originDomain}${pathKey[key] || "/"}?origin=${
    window.origin
  }&vdb_token=${localStorage.getItem("auth")}&lang=${
    language ? `${language}` : ""
  }`;
};

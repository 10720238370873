import VUpload from "components/v-upload";
import { useState } from "react";
import { Card } from "react-bootstrap";
import { getUrlImage } from "utils/common";
import { v4 as uuidv4 } from "uuid";

type IProps = {
  id: string;
  label?: string;
  name?: string;
  format?: string;
  required?: boolean;
  placeholder?: string;
  showTime?: any;
  error?: string;
  field?: any;
  image?: string;
};

const defaultStateUpload = [
  {
    id: uuidv4(),
    src: "",
    isNew: true,
    file: null,
  },
];

const View = ({
  id = "",
  label = "",
  name = "",
  required = false,
  error,
  field,
  image,
}: IProps) => {
  const [listFile, setListFile] = useState(defaultStateUpload);

  return (
    <Card border="success" className="mb-4 question_item_card" id={id}>
      {label && (
        <p className="text-sm mb-2">
          <b>{label}</b> {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div>
        {image && (
          <img
            style={{
              height: "200px",
              width: "auto",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
            src={getUrlImage(image, 1000)}
            alt="sub"
          />
        )}
      </div>
      <VUpload listFile={listFile} setListFile={setListFile} />
      {error && <p className="_error mb-0">{error}</p>}
    </Card>
  );
};

export default View;

import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

export const createNotification = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/notifications/`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getNotifications = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/notifications?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getNotificationRead = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/notifications/read?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getNotificationsOwner = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/notifications/owner`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const readNotification = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/notifications/read/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const sendNotification = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/send-notification/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const NotificationService = {
  getNotifications,
  getNotificationsOwner,
  createNotification,
  readNotification,
  getNotificationRead,
  sendNotification,
};

export default NotificationService;

import { IRootState } from "..";

export const getRequestJoinBusinesses = (state: IRootState) =>
  state.businesses.requests_join;

export const getSuggestBusinesses = (state: IRootState) =>
  state.businesses.suggests;

export const getDataAccesses = (state: IRootState) =>
  state.friends.dataAccesses;

export const getJoinedBusinesses = (state: IRootState) =>
  state.businesses.joined;

export const getManageBusinesses = (state: IRootState) =>
  state.businesses.manage;

export const getInvitedBusinesses = (state: IRootState) =>
  state.businesses.invited;

/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import VModal from "components/v-modal";
import { useEffect, useState } from "react";
import APIs from "api";
import { toast } from "react-toastify";
import VForm from "components/v-form";
import FromTemplateService from "api/form-templates";
import useTranslation from "hooks/useTranslation";
import { TYPE_KYC } from "config/enums";

const defaultState = {
  status: "",
  note: "",
};
const View = ({ userData, type, openModal, setOpenModal }) => {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);

  const [listFiles, setListFiles] = useState<any>({});
  const [additionalData, setAdditionalData] = useState({});
  const [formAdditionalData, setFormAdditionalDataKYC] = useState<any>({});
  const [isValid, setIsValid] = useState(false);
  const [kyc, setKYC] = useState<any>(defaultState);
  const [status, setStatus] = useState("init");
  const [validate, setValidate] = useState<any>(null);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const submit = async () => {
    setLoading(true);
    try {
      let listImg;
      let data = {
        object: userData?.object_type,
        ...additionalData,
      };

      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${userData?._id}`);

        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
      }
      if (listImg && listImg?.length > 0) {
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              data = {
                ...data,
                [item]: el?.filePath,
              };
            }
          });
        });
      }
      try {
        const kyc: any = await APIs.KYC.createKycOptions({
          user_id: userData?._id,
          type: type,
          body: {
            ...data,
            method: "kyc",
          },
        });
        if (kyc) {
          toast.success(t("Successfull!"));
          setListFiles({});
          getKyc();
        }
      } catch (err) {
        toast.error(t(err?.errors));
      }
    } catch (err) {
      toast.error(t(err?.errors || "Something went wrong!"));
    }
    setLoading(false);
  };

  const getKyc = async () => {
    try {
      const res: any = await APIs.KYC.getKycOptions({
        user_id: userData?._id,
        type: type,
      });
      if (res) {
        setKYC(res.kyc);
        setStatus(res.kyc.status);
      }
    } catch (error) {}
  };

  const getFormTemplateKyc = async () => {
    try {
      const res: any = await FromTemplateService.getForm({
        query: {
          type: type,
          option_id:
            type === TYPE_KYC.CAREER ? userData?.profile?.career_id : "",
        },
      });
      if (res) {
        setFormAdditionalDataKYC(res);
      }
    } catch (error) {
      setFormAdditionalDataKYC(null);
    }
  };

  useEffect(() => {
    const check = () => {
      const listRequied = formAdditionalData?.fields?.filter(
        (el) => el.required
      );

      for (let index = 0; index < listRequied?.length; index++) {
        const item = listRequied[index];
        if (!additionalData[item.label] && !listFiles[item.label]) {
          return false;
        }
      }
      return true;
    };
    setIsValid((_) => check());
  }, [additionalData, listFiles]);

  useEffect(() => {
    if (userData && type && openModal) {
      getKyc();
      getFormTemplateKyc();
    }
  }, [userData, type, openModal]);

  return (
    <VModal
      title={t("Verification")}
      isOpen={openModal}
      loading={loading}
      centered={true}
      disableConfirm={!isValid || status === "pending_kyc" || status === "kyc"}
      confirmLabel={t("Verification")}
      onCancel={() => handleCloseModal()}
      onConfirm={submit}
    >
      <div className="upload-kyc col-12">
        {status === "pending_kyc" ? (
          <div className="text-center">
            {t("Your request is being approved")}
          </div>
        ) : status === "kyc" ? (
          <div className="text-center">{t("Congratulations verify")}</div>
        ) : (
          <>
            {formAdditionalData && formAdditionalData.fields?.length > 0 && (
              <div className="row">
                <VForm
                  // colItem={"col-6"}
                  fields={formAdditionalData?.fields}
                  additionalData={additionalData}
                  listFiles={listFiles}
                  validate={validate}
                  setListFiles={setListFiles}
                  setValidate={setValidate}
                  setIsValid={setIsValid}
                  setFile={setFile}
                  setAdditionalData={setAdditionalData}
                />
              </div>
            )}
            {kyc?.status === "declined" && kyc?.note && (
              <div className="note">
                {t("Note")}:
                <div
                  className="mb-0"
                  dangerouslySetInnerHTML={{
                    __html: kyc?.note,
                  }}
                ></div>
              </div>
            )}

            {formAdditionalData?.attachments?.enable && (
              <div
                className="mb-0 "
                dangerouslySetInnerHTML={{
                  __html: formAdditionalData?.attachments?.guide,
                }}
              ></div>
            )}
          </>
        )}
      </div>
    </VModal>
  );
};

export default View;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import useQuery from "hooks/useQuery";
import React, { useEffect, useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import VWebView from "components/v-web-view";
import ComingSoon from "pages/coming-soon/comingsoon";
import VTab from "components/v-tab";
import AllFriends from "pages/social/user/profile/friends/Friends";
import AllMember from "pages/social/business/members";
import FollowSuggest from "./suggest";
import FriendRequest from "./request/FriendRequest";
import VBannerPage from "components/v-teamplate/v-banner-page";
import PageBackgrounds from "components/v-page-background";
import VRightView from "components/v-right-view";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { FRIEND_TYPE } from "config/enums";
import { isUser } from "utils/friends";

const View = () => {
  const menus = useAppSelector(getMenusByKey("tab_friends"));
  const dataUser = useAppSelector(getUserProfile);
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  const [tab, setTab] = useState(null);
  const query = useQuery();
  const [loadMenu, setLoadMenu] = useState(false);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  const renderTab = () => {
    if (tab && tab.type_view === "internal") {
      if (tab.key === "all")
        return isUser(dataUser.object_type) ? (
          <AllFriends
            dataUser={dataUser}
            type={FRIEND_TYPE.FRIEND}
            isMyProfile={true}
            col={"col-lg-2 col-md-3"}
          />
        ) : (
          <AllMember isAuth={true} dataUser={dataUser} col={"col-lg-2 col-md-3"} />
        );
      if (tab.key === "friend-request") return <FriendRequest />;
      if (tab.key === "friend-suggest") return <FollowSuggest />;
      if (tab.key === "friend-following")
        return (
          <AllFriends
            dataUser={dataUser}
            type={FRIEND_TYPE.FOLLOW}
            isMyProfile={true}
            col={"col-md-4"}
          />
        );
    }
    if (tab && tab.type_view === "webview") {
      return (
        <VWebView
          url={tab.url}
          widthScrollbar={tab?.width_scrollbar}
          height="700px"
        />
      );
    }
    return <ComingSoon />;
  };

  return (
    <div className="profile-wrapper">
      <PageBackgrounds page={"page_bg_friends"} />
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <VBannerPage position="banner_top_friends" />
            <Card className="p-3">
              <Col sm={12}>
                <VTab
                  tab={tab}
                  setTab={setTab}
                  menus={menus}
                  activeBorder="bottom"
                  noBackground={true}
                />
                {renderTab()}
              </Col>
            </Card>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_friends"}
                positionBanner={"banner_right_friends"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default View;

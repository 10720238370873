/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import VButton from "components/v-button";
import VInput from "components/v-input";

import SpecialNameAPIs from "api/special-name";
import Card from "components/v-teamplate/Card";
import Table from "components/v-table";

import { formatNumber } from "utils/common";
import { useAppSelector, useAppDispatch } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { setProfile } from "redux/reducers/users/users.reducer";
import useTranslation from "hooks/useTranslation";
import { getUrlProfile } from "utils/common";
import VIcon from "components/v-icon";
import Error404 from "components/v-error-404";

const View = (props) => {
  const { t } = useTranslation();
  const history = useHistory();
  const userAuth: any = useAppSelector(getUserProfile);
  const dispatch = useAppDispatch();

  const userData = props?.dataUser || userAuth;
  const columns = [
    {
      key: "key",
      title: t("Name diarybook"),
      dataIndex: "key",
    },
    {
      key: "type",
      title: t("Type"),
      dataIndex: "type",
      render: (item) => t(item.type),
    },
    {
      key: "price",
      title: t("Price"),
      render: (item) => (item.price ? formatNumber(item.price) : "N/A"),
    },
    {
      key: "action",
      title: t("Action"),
      render: (item) => (
        <div>
          <VButton
            className={"btn btn-primary"}
            onClick={() => handleChooseKeyBuy(item)}
          >
            {t("Buy")}
          </VButton>
        </div>
      ),
    },
  ];

  const [loading, setLoading] = useState(false);

  const [errMessage, setErrMessage] = useState<any>({
    key: "",
  });

  const [list, setList] = useState<any>([]);
  const [listOwn, setListOwn] = useState<any>([]);
  const [key, setKey] = useState("");
  const [state, setState] = useState({
    key: null,
    canBuy: false,
    code: null,
    price: null,
  });

  const handleChooseKeyBuy = (value) => {
    setState({
      ...state,
      key: value.key,
      canBuy: true,
      price: value.price,
    });
    setKey("");
  };

  const handleChange = (name, value) => {
    if (name === "key") {
      setKey(value);
      setState({
        ...state,
        canBuy: false,
      });
    } else {
      setState({
        ...state,
        code: value,
      });
    }
  };

  const handleSearch = async () => {
    setLoading(true);
    try {
      if (!key || key.includes(" "))
        return setErrMessage({
          key: t("Invalid"),
        });
      const check = await SpecialNameAPIs.search({
        body: {
          key: key,
        },
      });
      if (check) {
        setErrMessage([]);
        setState({
          price: null,
          code: null,
          canBuy: true,
          key: key,
        });
        toast.success(t(String(check)));
      }
      setLoading(false);
    } catch (error) {
      setErrMessage({
        key: error.errors,
      });
      setLoading(false);
    }
  };

  const hanldeBuy = async () => {
    try {
      setLoading(true);
      const res = await SpecialNameAPIs.activated({
        query: {
          user_id: userData?._id,
        },
        body: {
          ...state,
        },
      });
      if (res) {
        dispatch(
          setProfile({
            ...state,
            special_name: state.key,
          })
        );
        if(props?.isWebView){
          toast.success(t("Successfull"));
        } else {
          history.push(
            getUrlProfile({
              ...userData,
              special_name: state.key,
            })
          );
        }
      }
      setLoading(false);
    } catch (error) {
      toast.error(t(error.errors));
      setLoading(false);
    }
  };

  const handleActiveSpecialName = async (key) => {
    try {
      const res = await SpecialNameAPIs.activated({
        body: {
          key: key,
        },
      });
      if (res) {
        dispatch(
          setProfile({
            ...state,
            special_name: key,
          })
        );
        if(props?.isWebView){
          toast.success(t("Successfull"));
        } else {
          history.push(
            getUrlProfile({
              ...userData,
              special_name: key,
            })
          );
        }
      }
    } catch (error) {
      toast.error(t(error.errors));
    }
  };

  const getSpecial = async () => {
    try {
      const res = await SpecialNameAPIs.getSpeciallName();
      if (res) {
        setList(res || []);
      }
    } catch (error) {}
  };

  const getSpecialOwn = async () => {
    try {
      const res = await SpecialNameAPIs.getSpeciallNameByUserId({
        query: {
          user_id: userData?._id,
        },
      });
      if (res) {
        setListOwn(res || []);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getSpecial();
    getSpecialOwn();
  }, []);

  if (
    userData?.status_kyc !== "verified" &&
    userData?.status_kyc !== "kyc"
  )
    return <Error404 />;

  return (
    <Card isOpacity={true}>
      <div className="mt-3 p-3">
        <h3>{t("Special name")}</h3>
        {listOwn && listOwn.length > 0 && (
          <div className="mt-3">
            <h6>{t("List in possession")}</h6>
            {listOwn.map((el, i) => (
              <div key={i} className="d-flex align-items-center">
                <span className="ms-2 fw-bold">{`${i + 1}. ${el.key}`}</span>
                {userData?.special_name === el.key ? (
                  <VIcon name={"verify-success.svg"} className="ms-3" />
                ) : (
                  <div
                    className="ms-3 cursor-pointer"
                    onClick={() => handleActiveSpecialName(el.key)}
                  >
                    {t("Use")}
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
        <div className="mt-4">
          <VInput
            transparent={true}
            label={t("Name vdiarybook")}
            errMessage={t(errMessage.key)}
            suffix={
              <VIcon
                width={16}
                name="search.svg"
                onClick={() => handleSearch()}
              />
            }
            onChange={(e) => handleChange("key", e.target.value)}
          />
        </div>
        {state.canBuy && (
          <div className="mt-4">
            <div>
              <label>{t("Name diarybook")}: </label>
              <span className="ms-2 fw-bold">{state.key}</span>
            </div>
            {state.price && (
              <>
                <div>
                  <label>{t("Price")}: </label>
                  <span className="ms-2">{formatNumber(state.price || 0)}</span>
                </div>
                <div className="mt-3">
                  <VInput
                    label={t("Enter code to activate feature")}
                    onChange={(e) => handleChange("code", e.target.value)}
                  />
                </div>
                <div className="text-end mt-2">
                  <a
                    href="https://vzonex.com/my/shop"
                    target={"_blank"}
                    rel={"noreferrer"}
                  >
                    {t("Code here")}
                  </a>
                </div>
              </>
            )}
            <div className="pt-4 text-center">
              <VButton
                loading={loading}
                height="36px"
                className={"btn btn-primary"}
                onClick={() => hanldeBuy()}
              >
                {t("Active")}
              </VButton>
            </div>
          </div>
        )}
        {list && list.length > 0 && (
          <div className="mt-4 overflow-auto">
            <Table columns={columns} dataSource={list}></Table>
          </div>
        )}
      </div>
    </Card>
  );
};

export default View;

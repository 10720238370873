/* eslint-disable indent */
import React, { useState, useEffect, useMemo } from "react";
import { Nav, Container } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
//Componets

import { useAppSelector } from "redux/reducers/hook";
import { theme_scheme } from "redux/reducers/setting-system/selectors";
import {
  getLocations,
  getLogo,
  getMenusByKey,
} from "redux/reducers/settings/settings.selector";
import PageBackgrounds from "components/v-page-background";
import useTranslation from "hooks/useTranslation";
import { isMobile } from "react-device-detect";
import { getUrlImage } from "utils/common";
import VInput from "components/v-input";
import VIcon from "components/v-icon";
import VButton from "components/v-button";
import AuthAPI from "api/auth";
import { ROUTES } from "config/routes";
import { toast } from "react-toastify";
import { getCompanions } from "redux/reducers/settings/settings.selector";
import LanguageAuth from "pages/auth/components/language";
import axios from "axios";

const View = (props) => {
  const { t } = useTranslation();
  // const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useAppSelector(theme_scheme);
  const logo = useAppSelector(getLogo);
  const companions = useAppSelector(getCompanions);
  const menus = useAppSelector(getMenusByKey("footer_preview"));
  const locationAll = useAppSelector(getLocations);
  const [countryName, setCountryName] = useState<string>();

  const minisidebar = () => {
    document
      .getElementsByTagName("ASIDE")[0]
      ?.classList?.toggle("sidebar-mini");
  };

  const _logo = logo[`logo_${theme}`];

  const country = useMemo(() => {
    if (countryName) {
      return locationAll?.find(
        (location: { name: string }) =>
          location.name.toLowerCase().replaceAll(" ", "") ===
          countryName.toLowerCase().replaceAll(" ", "")
      );
    } else {
      return locationAll.find(
        (location: { default: boolean }) => location.default
      );
    }
  }, [locationAll, countryName]);

  useEffect(() => {
    if (isMobile) {
      minisidebar();
    }
    navigator.geolocation.getCurrentPosition(async function (position) {
      const res = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${position.coords.latitude}&longitude=${position.coords.longitude}
        &localityLanguage=en`
      );
      if (res && res.data) {
        setCountryName(res.data.countryName);
      }
    });
  }, []);

  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (name, value) => {
    let _state = state;
    _state[name] = value;
    setState(_state);
    // performValidate([name], _state);
  };

  const handleClickFooter = (route) => {
    history.push(route);
  };

  const handleLogin = async () => {
    try {
      setLoading(true);
      const res: any = await AuthAPI.login(state);
      if (res) {
        if (res.require_2fa || res.require_email) {
          history.push(
            `${ROUTES.VERIFY_LOGIN}?twofa=${res.require_2fa}&&email=${res.require_email}&&token=${res.token}`
          );
        } else {
          localStorage.setItem("auth", res.token);
          history.push("/");
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.errors === "USER_NOT_VERIFY") {
        history.push(`${ROUTES.VERIFY_EMAIL}?email=${state.email}`);
      } else {
        toast.error(t("Incorrect account or password."));
      }
    }
    setLoading(false);
  };

  if (history?.location?.pathname.includes("preview"))
    return (
      <>
        <div className="iq-top-navbar">
          <Nav
            variant="light"
            className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
          >
            <PageBackgrounds
              page={"page_bg_header"}
              width="100%"
              height="100%"
              position={"absolute"}
            />
            <Container fluid className="navbar-inner">
              {/* logo desktop */}
              <div className="d-flex align-items-center">
                <div>
                  <Link
                    className="d-flex align-items-center gap-2 iq-header-logo d-flex"
                    to={ROUTES.LOGIN}
                  >
                    <img src={getUrlImage(_logo)} alt="" />
                    {country && (
                      <div className="flag-icon-logo">
                        <img
                          className="national_flag"
                          src={getUrlImage(country?.logo || country?.icon, 100)}
                          alt=""
                        />
                      </div>
                    )}
                  </Link>
                </div>
              </div>
            </Container>
          </Nav>
        </div>
        <div className="content-preview">{props.children}</div>
      </>
    );

  return (
    <>
      <div className="iq-top-navbar">
        <Nav
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          <PageBackgrounds
            page={"page_bg_header"}
            width="100%"
            height="100%"
            position={"absolute"}
          />
          <Container fluid className="navbar-inner">
            {/* logo desktop */}
            <div className="d-flex align-items-center">
              <div>
                <Link
                  className="d-flex align-items-center gap-2 iq-header-logo d-flex"
                  to={ROUTES.LOGIN}
                >
                  <img src={getUrlImage(_logo, 150)} alt="" />
                  {country && (
                    <div className="flag-icon-logo">
                      <img
                        className="national_flag"
                        src={getUrlImage(country?.logo || country?.icon, 100)}
                        alt=""
                      />
                    </div>
                  )}
                </Link>
              </div>
            </div>
            <div className="">
              <form
                action="#"
                className="d-flex align-items-center"
                data-bs-toggle="modal"
                data-bs-target="#exampleModalFullscreenSm"
              >
                <div className="d-none d-lg-flex align-items-center">
                  <div className="me-2">
                    <VInput
                      label={t("enter_email")}
                      type="text"
                      id="email"
                      name="email"
                      required={true}
                      // errMessage={validate.email}
                      onChange={(e) => handleChange("email", e.target.value)}
                    />
                  </div>
                  <div className="me-2">
                    <VInput
                      label={t("enter_password")}
                      type={showPassword ? "text" : "password"}
                      id="password"
                      name="password"
                      onChange={(e) => handleChange("password", e.target.value)}
                      // errMessage={validate.password}
                      suffix={
                        <div
                          className="cursor-pointer"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          <VIcon
                            name={showPassword ? "eye.svg" : "eye_off.svg"}
                          />
                        </div>
                      }
                    />
                  </div>
                  <div className="me-2">
                    <VButton loading={loading} onClick={handleLogin}>
                      {t("Login")}
                    </VButton>
                  </div>
                  <div className="me-2">
                    <VButton
                      loading={loading}
                      className={"v-button  btn btn-success"}
                      onClick={() => handleClickFooter(ROUTES.REGISTER)}
                    >
                      {t("Register now")}
                    </VButton>
                  </div>
                </div>
                <div className="me-2 d-lg-none">
                  <VButton
                    loading={loading}
                    onClick={() => handleClickFooter(ROUTES.LOGIN)}
                  >
                    {t("Login")}
                  </VButton>
                </div>
              </form>
            </div>
          </Container>
        </Nav>
      </div>
      <div className="content-preview">{props.children}</div>
      <Nav
        variant="light"
        className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0 footer-preview"
      >
        <div className="pb-1 pt-0">
          <div className="container">
            <div className="content-footer mt-2">
              <div className="logo-footer">
                {companions.length > 0 &&
                  companions?.map((cp, index) => (
                    <Link
                      className="item"
                      key={index}
                      to={cp.link}
                      target="_blank"
                    >
                      <img src={getUrlImage(cp.icon)} alt="" />
                    </Link>
                  ))}
              </div>
              <div className="d-flex justify-content-center align-items-center flex-wrap mt-1">
                <div>
                  <LanguageAuth />
                </div>
                {menus?.map((el) => (
                  <a className="mx-2" href={el.url}>
                    {el.name}
                  </a>
                ))}
              </div>
              {/* <span className="text-center px-2">
                {t("descriptions_footer_auth_layout")}
              </span> */}
              {/* <div className="d-flex align-items-center flex-wrap justify-content-center">
						<h6>{t("See more information on")}</h6>
						<div>
							<Link
								className="logo-footer-preview"
								to={"#"}
							>
								<img width={5} src={getUrlImage(_logo)} alt="" />
							</Link>
						</div>
					</div>
					<div className="action-footer">
						<div className="mx-2">
							<VButton
								loading={loading}
								onClick={() => handleClickFooter(ROUTES.LOGIN)}
							>
								{t("Login")}
							</VButton>
						</div>
						<div>{t("Or")}</div>
						<div className="mx-2">
							<VButton
								loading={loading}
								className={"btn-footer-register"}
								onClick={() => handleClickFooter(ROUTES.REGISTER)}
							>
								{t("register")}
							</VButton>
						</div>
					</div> */}
            </div>
          </div>
        </div>
      </Nav>
    </>
  );
};

export default View;

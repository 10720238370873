/* eslint-disable jsx-a11y/anchor-is-valid */

type IProps = {
  totalPages?: number;
  page?: number;
  hasNextPage?: boolean;
  hasPrevPage?: boolean;
  getData: (page: number) => {};
};

const View = (props: IProps) => {
  const { totalPages, page, hasNextPage, hasPrevPage, getData } = props;
  const handlePrev = () => {
    if (hasPrevPage) {
      getData(page - 1);
    }
  };

  const handleNext = () => {
    if (hasNextPage) {
      getData(page + 1);
    }
  };

  const handleClick = (_page) => {
    getData(_page);
  };

  return (
    <div className="d-flex justify-content-center">
      <nav aria-label="Page navigation ">
        <ul className="pagination">
          <li
            className={`page-item ${page === 1 ? "disabled" : ""}`}
            onClick={(_) => handleClick(1)}
          >
            <a className="page-link">First</a>
          </li>
          <li className="page-item" onClick={handlePrev}>
            <a className="page-link" href="#" aria-label="Previous">
              <span aria-hidden="true">&laquo;</span>
            </a>
          </li>
          {totalPages > 6 ? (
            <>
              {page < 5 &&
                Array(5)
                  .fill(0)
                  .map((_, index) => {
                    return (
                      <li
                        onClick={(_) => handleClick(index + 1)}
                        className={`page-item ${
                          page === index + 1 ? "active" : ""
                        }`}
                      >
                        <a className="page-link" href="#">
                          {index + 1}
                        </a>
                      </li>
                    );
                  })}
              {page >= 5 &&
                page < totalPages - 1 &&
                [page - 2, page - 1, page, page + 1, page + 2].map((index) => {
                  return (
                    <li
                      onClick={(_) => handleClick(index)}
                      className={`page-item ${page === index ? "active" : ""}`}
                    >
                      <a className="page-link" href="#">
                        {index}
                      </a>
                    </li>
                  );
                })}
              {page >= 5 &&
                page >= totalPages - 1 &&
                [
                  totalPages - 4,
                  totalPages - 3,
                  totalPages - 2,
                  totalPages - 1,
                  totalPages,
                ].map((index) => {
                  return (
                    <li
                      onClick={(_) => handleClick(index)}
                      className={`page-item ${page === index ? "active" : ""}`}
                    >
                      <a className="page-link" href="#">
                        {index}
                      </a>
                    </li>
                  );
                })}
            </>
          ) : (
            Array(totalPages)
              .fill(0)
              .map((_, index) => {
                return (
                  <li
                    onClick={(_) => handleClick(index + 1)}
                    className={`page-item ${
                      page === index + 1 ? "active" : ""
                    }`}
                  >
                    <a className="page-link" href="#">
                      {index + 1}
                    </a>
                  </li>
                );
              })
          )}
          <li className="page-item" onClick={handleNext}>
            <a className="page-link" href="#" aria-label="Next">
              <span aria-hidden="true">&raquo;</span>
            </a>
          </li>
          <li
            className={`page-item ${page === totalPages ? "disabled" : ""}`}
            onClick={(_) => handleClick(totalPages)}
          >
            <a className="page-link">Last</a>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default View;

import GoogleMapAPI from "components/smart-maps/components/GoogleMapAPI";
import { MapContext } from "pages/sos/maps/MapsContext";
import { useContext, useEffect, useState } from "react";
import GeolocationRequest from "./GeolocationRequest";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const MapWrapper = () => {
  const mapContext = useContext(MapContext);
  const { fetchingCoords, geolocationPermission } =
    mapContext;

  const [googleKey, setGoogleKey] = useState("");
  const generals = useAppSelector(getGeneral);

  useEffect(() => {
    setGoogleKey(
      generals?.site?.find((el: any) => el.key === "google_map_api_key")
        ?.content
    );
  }, [generals]);

  return (
    <div className="smart-maps">
      {(geolocationPermission === "granted") && googleKey &&
        (!fetchingCoords ? (
          <GoogleMapAPI googleKey={googleKey}/>
        ) : (
          <div className="loading-maps">Đang tải vị trí của bạn...</div>
        ))}

      {geolocationPermission !== "granted" && (
        <div className="loading-maps">
          {!fetchingCoords ? (
            <GeolocationRequest />
          ) : (
            <>Đang tải vị trí của bạn...</>
          )}
        </div>
      )}
    </div>
  );
};

export default MapWrapper;

/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Row, Col } from "react-bootstrap";
import { formatNumber, getBase64Image, getUrlImage } from "utils/common";
import VTable from "components/v-table";
import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import DownloadImg from "components/download/View";
import APIs from "api";
import useTranslation from "hooks/useTranslation";

const Invoice = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const imgRef = useRef(null);
  const [logo, setLogo] = useState(null);

  const columns = [
    {
      key: "Logo",
      title: <span style={{ fontWeight: 700 }}>{t("Hình ảnh")}</span>,
      render: (item: any) => (
        <img
          className="logo"
          src={logo || getUrlImage(invoice.product_detail.thumbnail)}
          alt=""
        />
      ),
    },
    {
      key: "Product",
      title: <span style={{ fontWeight: 700 }}>Tên sản phẩm</span>,
      render: (item: any) =>
        `${item.product_id ? item.product_detail["name"] : "N/A"}`,
    },
    {
      key: "Price",
      title: <span style={{ fontWeight: 700 }}>Giá tiền</span>,
      render: (item: any) =>
        `${formatNumber(item.product["price"])} (${
          item.product ? String(item.product.token).toUpperCase() : "N/A"
        })`,
    },
    {
      key: "quantity",
      title: <span style={{ fontWeight: 700 }}>Số lượng</span>,
      render: (item: any) => `${formatNumber(item.product.quantity)} `,
    },
    {
      key: "total",
      title: <span style={{ fontWeight: 700 }}>Tổng tiền</span>,
      render: (item: any) =>
        `${formatNumber(item.product.total)} (${
          item.product ? String(item.product.token).toUpperCase() : "N/A"
        })`,
    },
  ];

  useEffect(() => {
    if (id) {
      APIs.PURCHASE.getInvoice({
        params: {
          id,
        },
      })
        .then((res: any) => {
          if (res && res.invoice) {
            setInvoice(res.invoice);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [history, id]);

  useEffect(() => {
    const getImages = async () => {
      if (invoice && invoice.project_id) {
        const res = await getBase64Image(
          getUrlImage(invoice.product_detail.thumbnail)
        );
        setLogo(res);
      }
    };
    getImages();
  }, [invoice]);

  return (
    <>
      <div ref={imgRef} className="container-invoice pt-0 pl-0">
        <div className="thumbnail">
          <img src="/assets/images/invoice-thumbnail.png" alt="" />
        </div>
        <div className="logo-top">
          <img src="/assets/images/logo.png" alt="logo" />
        </div>

        {invoice && (
          <>
            <div>
              <div className="invoice-title">
                <img src="/assets/images/invoice-header.png" alt="" />
                <span>{t("HÓA ĐƠN")}</span>
              </div>

              <Row>
                <Col xs={4}>
                  <p className="heading-bold">{t("Thông tin")}</p>
                  <div className="row-item">
                    <span className="label">{t("Mã số")}:</span>
                    <span className="value">
                      #{new Date(invoice.createdAt).getTime()}
                    </span>
                  </div>
                  <div className="row-item">
                    <span className="label">{t("Địa chỉ ví")}:</span>

                    <span className="value">
                      {invoice.user._id.slice(10)}...
                    </span>
                  </div>

                  <div className="row-item">
                    <span className="label">{t("Cổ phiếu")}:</span>
                    <span className="value">
                      #{new Date(invoice.createdAt).getTime()}
                    </span>
                  </div>

                  <div className="row-item">
                    <span className="label">{t("Ngày")}:</span>
                    <span className="value">
                      {new Date(invoice.createdAt).toLocaleDateString()}
                    </span>
                  </div>
                </Col>
                <Col xs={4}>
                  <p className="heading-normal">{t("Người gửi")}</p>
                  <div className="row-item">
                    <span className="label">support@vzonex.com</span>
                  </div>
                  <div className="row-item">
                    <span className="label">{t("Địa chỉ")}:</span>
                    <span className="value">
                      Số 6-A12, KĐT Lê Trọng Tấn - Gleximco, An Khánh, Hoài Đức,
                      Hà Nội.
                    </span>
                  </div>
                </Col>
                <Col xs={4}>
                  <p className="heading-bold">{t("Người nhận")}</p>
                  <div className="row-item">
                    <span className="label">
                      ID : {invoice.user.refer_code}
                    </span>
                  </div>
                  <div className="row-item">
                    <span className="label">
                      Name : {invoice.user.full_name}
                    </span>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="content">
              <VTable columns={columns} dataSource={[invoice]}></VTable>
            </div>

            <Row>
              <Col>
                <div className="invoice-payment-info">
                  <img src="/assets/images/invoice-qr.png" alt="" />
                  <div>
                    <div>
                      <img
                        src="/assets/images/PaymentBg.png"
                        alt=""
                        className="payment-info-img"
                      />
                      <div>
                        <div>
                          <div className="payment-row-item">
                            <div>
                              <p>SubTotal:</p>
                              <span>{`${formatNumber(invoice.product.total)} (${
                                invoice.product_id
                                  ? String(invoice.product.token).toUpperCase()
                                  : "N/A"
                              })`}</span>
                            </div>
                            <div>
                              <p>Tax: VAT 0%</p>
                              <span>0.00%</span>
                            </div>
                            <div>
                              <p>Discount(5%):</p>
                              <span>{`${formatNumber(
                                (invoice.product.total * 5) / 100
                              )} (${
                                invoice.product_id
                                  ? String(invoice.product.token).toUpperCase()
                                  : "N/A"
                              })`}</span>
                            </div>
                            <div>
                              <p>Total due:</p>
                              <span>{`${formatNumber(
                                invoice.product.total -
                                  (invoice.product.total * 5) / 100
                              )} (${
                                invoice.product_id
                                  ? String(invoice.product.token).toUpperCase()
                                  : "N/A"
                              })`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="invoice-footer">
              <span>
                {t(
                  "Mọi thắc mắc liên quan đến tài liệu này vui lòng liên hệ với chúng tôi"
                )}
              </span>
              <p>{t("Cảm ơn bạn đã tin tưởng chúng tôi!")}</p>
            </div>
          </>
        )}
      </div>

      {invoice && (
        <DownloadImg
          imgRef={imgRef}
          fileName={`invoice_${invoice.product.token}`}
        />
      )}
    </>
  );
};

export default Invoice;

/* eslint-disable indent */

import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getIsApp } from "redux/reducers/settings/settings.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { convertUrl } from "utils/common";

/* eslint-disable jsx-a11y/iframe-has-title */
const View = (props) => {
  const {
    className,
    key,
    url,
    allow,
    width,
    height,
    widthScrollbar = "0px",
  } = props;

  const [loading, setLoading] = useState(true);
  const isApp = useAppSelector(getIsApp)
  const user = useAppSelector(getUserProfile);

  return (
    <div
      style={{
        width: width || "100%",
        overflow: "hidden",
        borderRadius: "5px",
      }}
      key={key}
    >
      {loading && (
        <div className="d-flex justify-content-center p-3">
          <Spinner />
        </div>
      )}
      <iframe
        className={`${isApp ? "iframe-is-app" : ""} ${className}`}
        onLoad={() => {
          setLoading(false);
        }}
        src={convertUrl(url, user)}
        allow={allow}
        style={{
          width: `calc(100% + ${widthScrollbar})`,
          height: height,
        }}
      >
        <p>Your browser does not support iframes.</p>
      </iframe>
    </div>
  );
};

export default View;

import { Tooltip } from "react-tooltip";
import { useAppSelector } from "redux/reducers/hook";
import { getTooltip } from "redux/reducers/settings/settings.selector";

const View = () => {
  const dataTooltip = useAppSelector(getTooltip);
  return (
    <>
      {dataTooltip?.map((el) => (
        <Tooltip
          className="v-tooltip-container"
          anchorSelect={`.${el?.name}`}
          place="top"
          closeEvents={{
            mouseleave: true,
            blur: true,
            click: true,
            dblclick: true,
            mouseup: true,
          }}
        >
          <div
            className="mb-0 w-100"
            dangerouslySetInnerHTML={{
              __html: el?.value,
            }}
          ></div>
        </Tooltip>
      ))}
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "components/v-teamplate/Card";

//Sweet alert
import useTranslation from "hooks/useTranslation";
import MembersRequest from "./Request";
import MembersJoined from "./Joined";
import VSearch from "components/v-search";

import SearchService from "api/search";
import { OBJECT_TYPE } from "config/enums";
import debounce from "lodash.debounce";
import VList from "components/v-list-member";
import BusinessService from "api/businesses";
import MembersInvite from "./Invite";
import VLoading from "components/v-loading";
import VSelectUser from "components/v-select-user";
import ModalInvite from "pages/social/object-details/modal-invite";
import VIcon from "components/v-icon";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  col?: string;
};

const View = (props: IProps) => {
  const { isAuth, dataUser, col } = props;
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isShowListUsers, setIsShowListUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const refJoined = useRef(null);
  const refInvited = useRef(null);
  const listUsersRef = useRef(null);

  useEffect(() => {
    if (openModal) handleSearchMembers("");
  }, [openModal]);

  useEffect(() => {
    setUsers((state) =>
      state.filter((user) => !selectedUsers.some((sU) => sU._id === user._id))
    );
  }, [isShowListUsers, isSearching]);

  const handleSearchMembers = async (value: string) => {
    setIsSearching(true);
    const res: any = await SearchService.searchFriendInvite({
      query: {
        page: 1,
        limit: 20,
        search: value,
        object_type: OBJECT_TYPE.BUSINESS,
        object_id: dataUser._id,
      },
    });
    if (res && res.docs) {
      setIsShowListUser(true);
      setLoading(false);
      setIsSearching(false);
      return setUsers(res.docs);
    }
    return;
  };
  const debouncedChangeHandler = useCallback(
    debounce(handleSearchMembers, 1000),
    []
  );

  const handleSelectMembers = (value: any) => {
    if (selectedUsers.some((item) => item._id === value._id)) {
      return setSelectedUsers(
        selectedUsers.filter((item) => item._id !== value._id)
      );
    }

    return setSelectedUsers((prev) => [...prev, value]);
  };

  const onClickOutSide = (e) => {
    const { target } = e;
    if (listUsersRef?.current === null) {
      setIsShowListUser(false);
      return;
    }
    if (listUsersRef?.current === undefined) {
      setIsShowListUser(false);
      return;
    }
    if (!listUsersRef?.current?.contains(target)) {
      setIsShowListUser(false);
      return;
    }
  };

  const handleDeleteSelectedUser = (value) => {
    if (value) {
      setSelectedUsers(selectedUsers.filter((item) => item !== value));
    }
  };

  const handleAddMember = async () => {
    try {
      if (selectedUsers.length > 0) {
        setLoadingConfirm(true);
        await BusinessService.addMembers({
          body: {
            memberIds: selectedUsers,
            businessId: dataUser._id,
          },
        });
        refInvited.current.reloadData();
        setOpenModal(false);
        setUsers([]);
        setSelectedUsers([]);
        setLoadingConfirm(false);
      }
    } catch (error) {
      setLoadingConfirm(false);
    }
  };

  const renderChildrenAddMember = () => (
    <div className="members-body-wrapper" onClick={onClickOutSide}>
      <div className="members-search-wrapper select-none">
        <VSearch
          handleChange={debouncedChangeHandler}
          className="add-members-search-input"
          handleKeyDown={() => {
            setLoading(true);
            setUsers([]);
          }}
          handleClick={() => setIsShowListUser(true)}
        />
        {isShowListUsers && (
          <div
            ref={listUsersRef}
            className="members-selection-users"
            onClick={(e) => e.stopPropagation()}
          >
            <VIcon
              name="close.svg"
              onClick={() => {
                setIsShowListUser(false);
              }}
            />
            {loading && <VLoading />}
            {users.map((item, index) => (
              <VSelectUser
                data={item}
                key={index}
                onChange={handleSelectMembers}
                className="px-4"
                selectedUsers={selectedUsers}
              />
            ))}
          </div>
        )}
      </div>
      <div className="members-list-selected">
        <VList data={selectedUsers} onDelete={handleDeleteSelectedUser} />
      </div>
    </div>
  );

  return (
    <Col>
      <Container>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="members-header-title">
                  <h4 className="card-title">{t("Member")}</h4>
                  {isAuth && (
                    <button
                      className="btn-add-members"
                      onClick={() => setOpenModal(true)}
                    >
                      {t("Add members")}
                    </button>
                  )}
                </div>
              </Card.Header>
              <Card.Body>
                <MembersJoined
                  ref={refJoined}
                  isAuth={isAuth}
                  dataUser={dataUser}
                  col={col || "col-md-2"}
                />
              </Card.Body>
            </Card>
            {isAuth && (
              <>
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">{t("Request join")}</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <MembersRequest
                      isAuth={isAuth}
                      dataUser={dataUser}
                      reloadData={(_) => {
                        refJoined.current.reloadData();
                      }}
                      col={col || "col-md-2"}
                    />
                  </Card.Body>
                </Card>
                <Card>
                  <Card.Header className="d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">{t("Invite members")}</h4>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <MembersInvite
                      ref={refInvited}
                      isAuth={isAuth}
                      dataUser={dataUser}
                      col={col || "col-md-2"}
                    />
                  </Card.Body>
                </Card>
              </>
            )}
          </Col>
        </Row>
        <ModalInvite
          isOpen={openModal}
          onCancel={() => {
            setOpenModal(false);
            setUsers([]);
            setSelectedUsers([]);
            setIsShowListUser(false);
            setLoading(false);
          }}
          loading={loadingConfirm}
          centered={true}
          title={<span className="member-tilte">{t("Add member")}</span>}
          children={renderChildrenAddMember()}
          onConfirm={handleAddMember}
        />
      </Container>
    </Col>
  );
};

export default React.memo(View);

/* eslint-disable react-hooks/exhaustive-deps */
import UserService from "api/users";
import UploadService from "api/uploads";
import VDropdown from "components/v-dropdown";
import VInput from "components/v-input";
import VCodeVip from "components/v-code-vip";
import VUpload from "components/v-upload";
import { CORVER_TYPE } from "config/enums";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Form } from "react-bootstrap";
import useTranslation from "hooks/useTranslation";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/reducers/hook";
import VSwitch from "components/v-switch";

import { v4 as uuidv4 } from "uuid";
import { getUserByIdAsync } from "redux/reducers/users/users.actions";

const defaultStateUpload = [
  {
    id: uuidv4(),
    src: "",
    isNew: true,
    file: null,
  },
];

const EditCover = ({ dataUser, setLoading, setOpenModal }, ref) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const permissions = dataUser?.permissions || {};

  const { covers } = dataUser.profile?.family_data || {};

  const [state, setState] = useState({
    type: "image",
    url: "",
    slide: [],
  });
  const [openVip, setOpenVip] = useState(false);

  const [listFile, setListFile] = useState(defaultStateUpload);

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit();
    },
  }));

  useEffect(() => {
    if (covers) {
      setState(covers);
      if (
        covers.type === CORVER_TYPE.IMAGE ||
        covers.type === CORVER_TYPE.VIDEO
      ) {
        setListFile([
          {
            id: uuidv4(),
            src: covers.url,
            isNew: false,
            file: null,
          },
        ]);
      } else if (covers.type === CORVER_TYPE.SLIDE) {
        setListFile(covers.slide);
      }
    }
  }, [covers]);

  const onChange = (name, value) => {
    if (name === "type" && value !== CORVER_TYPE.LINK) {
      setListFile(defaultStateUpload);
    } else {
      setListFile([]);
    }
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let listUpload = listFile;
      if (listFile && listFile.length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${dataUser?._id}`);

        const statesNew = listFile
          .filter((st) => st.isNew)
          .map((stNew) => {
            formData.append("files", stNew.file);
            return stNew;
          });

        const listImg: any = await UploadService.uploadMultiFile({
          body: formData,
        });
        listImg.forEach((el) => {
          statesNew[el.id] = {
            ...statesNew[el.id],
            src: el?.filePath,
          };
        });

        listUpload = listUpload.map((st) => {
          const newStFound = statesNew.find((c) => c.id === st.id);
          if (newStFound) {
            return {
              ...st,
              ...newStFound,
            };
          }
          return st;
        });
      }

      const data = {
        type: state.type || CORVER_TYPE.IMAGE,
        url: listUpload[0]?.src || state.url,
        slide: state.type === CORVER_TYPE.SLIDE ? listUpload : [],
      };

      const res = await UserService.updateProfile({
        body: {
          family_data: {
            ...(dataUser.profile?.family_data || {}),
            covers: data,
          },
        },
      });
      if (res) {
        dispatch(
          getUserByIdAsync({
            params: {
              id: dataUser?._id,
            },
          })
        );
        // toast.success(t("success"));
        setOpenModal(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(t(error.errors));
    }
  };

  return (
    <>
      <div className="container">
        <div className="row">
          <Form.Group className="form-group">
            <VDropdown
              label={<div>{t("Cover type")}</div>}
              placeholder={t("Cover type")}
              value={state.type}
              options={Object.keys(CORVER_TYPE).map((el: any) => {
                return {
                  label: el,
                  value: CORVER_TYPE[el],
                  isDisabled:
                    CORVER_TYPE[el] === CORVER_TYPE.IMAGE
                      ? false
                      : !permissions["profile.update_cover"],
                };
              })}
              onChange={(e: any) => onChange("type", e.target.value)}
            />
          </Form.Group>
          {state.type !== CORVER_TYPE.LINK && (
            <VUpload
              isMulti={state.type === CORVER_TYPE.SLIDE}
              listFile={listFile}
              setListFile={setListFile}
            />
          )}
          {state.type === CORVER_TYPE.LINK && (
            <Form.Group className="form-group">
              <VInput
                value={state.url}
                label={`${t("enter_url")} ${t("facebook")} / ${t("youtube")}`}
                onChange={(e) => onChange("url", e.target.value)}
              />
            </Form.Group>
          )}
        </div>

        {/* VIP */}
        {!permissions["profile.update_cover"] && (
          <>
            <div className="d-block">
              <a
                href="https://vzonex.com/my/shop"
                target={"_blank"}
                rel={"noreferrer"}
              >
                {t("please_click_buy_code")}
              </a>
            </div>
            <div className="mb-2 d-flex align-items-center">
              <label className="me-2 d-block">VIP : </label>
              <VSwitch onChange={(v) => setOpenVip(v)} checked={openVip} />
            </div>
            {openVip && (
              <VCodeVip permission="profile.update_cover" user={dataUser} />
            )}
          </>
        )}
      </div>
    </>
  );
};

export default forwardRef(EditCover);

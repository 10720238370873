/* eslint-disable */
// import provinces from "constants/provinces";
import { getUrlImage } from "./common";
import { Button } from "react-bootstrap";

const getStatusLabel = (status: string): JSX.Element => {
  const colors: any = {
    pending: "text-warning",
    off: "text-muted",
    happening: "text-success",
    approved: "text-success",
    declined: "text-danger",
    cancelled: "text-danger",
    accomplished: "text-success",
  };

  return (
    <span
      className={`d-inline ${colors[status]}
		`}
    >
      {status === "pending"
        ? "Chờ xác nhận"
        : status === "happening"
        ? "Đã duyệt"
        : status === "declined"
        ? "Từ chối"
        : status === "off"
        ? "Tạm ẩn"
        : status === "cancelled"
        ? "Huỷ bỏ"
        : status === "accomplished"
        ? "Hoàn thành"
        : status}
    </span>
  );
};

const isSameImagURLWithServer =
  process.env.REACT_APP_API_URL === process.env.REACT_APP_FILE_URL;

const addSizeToImgUrl = (path: string, size: number) => {
  if (isSameImagURLWithServer || !path) {
    return path;
  }

  const [ext] = path.split(".").slice(-1);

  const f = `${path.substring(0, path.length - ext.length - 1)}--${
    size || 300
  }.${ext}`;
  return f;
};

const getFilePath = (path: string) => {
  if (!path) {
    return path;
  }

  if (isSameImagURLWithServer) {
    return `${process.env.REACT_APP_API_URL?.replace("/api", "")}/${path}`;
  }

  return `${process.env.REACT_APP_FILE_URL}/${path.replace(
    "src/assets/uploads/",
    ""
  )}`;
};

const getCategoryButton = (
  title: string,
  color: string,
  icon: any,
  isActive: boolean = false,
  suffix: any = null,
  isLabel: any = false
) => {
  return (
    <Button
      style={{
        color: isLabel ? color : isActive ? "white" : color,
        borderColor: isLabel ? "white" : isActive ? color : color,
        backgroundColor: isLabel ? "white" : isActive ? color : color,
      }}
      className={`w-100 h-30 ${
        (!isActive || isLabel) && `bg-transparent ${!color && "text-primary"}`
      }`}
    >
      <div className="d-flex h-100 w-100 align-items-center">
        {icon && (
          <img
            className="h-100 w-auto me-2"
            src={getFilePath(icon.path) || ""}
            alt="icon"
          />
        )}{" "}
        <span className="text-nowrap text-button-post">
          {typeof title === "string" && title}
        </span>
        {(isLabel ? null : suffix) && (
          <span className="ms-1 d-flex align-items-center">{suffix}</span>
        )}
      </div>
    </Button>
  );
};

const getCategoryMapButton = (
  title: string,
  color: string,
  icon: any,
  isActive: boolean = false,
  suffix: any = null,
  mapIcon: any
) => {
  return (
    <Button
      style={{
        color: isActive ? "white" : color,
        border: "none",
        backgroundColor: isActive ? color : "white",
        boxShadow:
          "0 1px 2px rgba(60,64,67,0.3), 0 1px 3px 1px rgba(60,64,67,0.15)",
        borderRadius: "27px",
        height: "32px",
      }}
      className={`w-100 h-30 ${!isActive && `${!color && "text-primary"}`}`}
    >
      <div className="d-flex h-100 w-100 align-items-center justify-content-around">
        {icon && (
          <img
            className="h-100 w-auto me-2"
            src={
              (mapIcon?.icon
                ? getUrlImage(mapIcon.icon)
                : getUrlImage(icon.icon)) || ""
            }
            alt="icon"
          />
        )}{" "}
        <span className="text-nowrap text-button-post clip-text">
          {typeof title === "string" && title}
        </span>
        {suffix && (
          <span className="ms-1 d-flex align-items-center">{suffix}</span>
        )}
      </div>
    </Button>
  );
};

const getNameProvince = (code: number, sosProvinces: any) => {
  return sosProvinces.find((pro: any) => String(pro.code) === String(code))
    ?.name;
};

const getNameDistrict = (
  codePro: number,
  codeDis: number,
  sosProvinces: any
) => {
  const districts = sosProvinces?.find(
    (pro: any) => String(pro.code) === String(codePro)
  )?.districts;
  return districts?.find((dist: any) => String(dist.code) === String(codeDis))
    ?.name;
};

const getNameWard = (
  codePro: number,
  codeDis: number,
  codeWard: number,
  sosProvinces: any
) => {
  const wards = sosProvinces
    ?.find((pro: any) => String(pro.code) === String(codePro))
    ?.districts.find(
      (dist: any) => String(dist.code) === String(codeDis)
    )?.wards;
  return wards?.find((ward: any) => String(ward.code) === String(codeWard))
    ?.name;
};

// const getFullnameAddress = (data: {
//   detail?: string;
//   districtCode?: string;
//   provinceCode?: string;
//   wardCode?: string;
// }) => {
//   let fullText = [];
//   const province = provinces.find(
//     (pro: any) => String(pro.code) === String(data?.provinceCode)
//   );

//   if (province) {
//     fullText.push(province.name);
//     if (province.districts && data.districtCode) {
//       const district = province.districts.find(
//         (item: any) => String(item.code) === String(data.districtCode)
//       );

//       if (district) {
//         fullText.push(district.name);

//         if (district.wards) {
//           const ward = district.wards.find(
//             (item: any) => String(item.code) === String(data.wardCode)
//           );
//           if (ward) {
//             fullText.push(ward.name);
//           }
//         }
//       }
//     }
//   }

//   if (data.detail) {
//     fullText.push(data.detail);
//   }

//   return fullText.join(", ");
// };

const getTypeAnswersLabel = (type: string): JSX.Element => {
  return (
    <span>
      {type === "choice" ? "Trắc nghiệm" : type === "text" ? "Text" : type}
    </span>
  );
};

const getTreeCategorySelect = (sosCategories: any[]) => {
  let data;
  const mapData = (list: any) => {
    const dataChil = list.map((item: any) => chil(item));
    return dataChil.length > 0 ? dataChil : null;
  };
  const chil = (ct: any) => {
    const chilData = sosCategories.filter(
      (category) => String(category.parentId) === String(ct._id)
    );
    return {
      label: ct?.title,
      value: ct?._id,
      children: mapData(chilData),
    };
  };

  data = sosCategories
    .filter((ct) => !Boolean(ct.parentId))
    .map((el) => chil(el));
  return data;
};

export {
  getStatusLabel,
  addSizeToImgUrl,
  getFilePath,
  getCategoryButton,
  getNameProvince,
  getNameDistrict,
  getNameWard,
  // getFullnameAddress,
  getTypeAnswersLabel,
  getTreeCategorySelect,
  getCategoryMapButton,
};

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import VSlider from "components/v-slider";
import { convertUrl, getUrlImage } from "utils/common";
import BannersService from "api/banners";
import VButton from "components/v-button";
import { BiVolumeMute } from "react-icons/bi";
import { VscUnmute } from "react-icons/vsc";
import { useHistory } from "react-router-dom";

type IProps = {
  userId?: string;
  position: string;
  isBannerPage?: boolean;
  isPopupProfile?: boolean;
  data?: any;
};

const View = ({
  userId,
  position,
  isBannerPage = false,
  isPopupProfile = false,
  data,
}: IProps) => {
  const history = useHistory();
  const [banners, setBanners] = useState(null);
  const [mute, setMute] = useState<boolean>(true);

  useEffect(() => {
    getBanners();
  }, [position]);

  useEffect(() => {
    setBanners(data);
  }, [data]);

  const getBanners = async () => {
    try {
      if (isPopupProfile) return;

      const data: any = await BannersService.getsByPosition({
        params: {
          position,
        },
        query: {
          userId,
        },
      });
      if (data && data.length > 0) {
        if (isBannerPage) {
          const _banner =
            data.find((i) => i?.filter?.name !== "default") || data[0];
          if (_banner) {
            if (_banner.video || _banner.youtube || _banner.facebook) {
              setBanners([_banner]);
              return;
            }
          }
          let images = [];
          data.forEach((element) => {
            images = [...images, ...element.images];
          });
          setBanners([
            {
              images: images.filter((a) => a.src),
            },
          ]);
        } else {
          setBanners(data);
        }
      }
    } catch (error) {}
  };

  const handleClickAction = (action) => {
    if (
      action.link &&
      action.link.includes(process.env.REACT_APP_CLIENT_DOMAIN)
    ) {
      const url = String(action?.link)?.split(
        process.env.REACT_APP_CLIENT_DOMAIN
      )[1];
      return history.push(convertUrl(url));
    }
    if (action.link) {
      window.open(convertUrl(action.link));
    }
  };

  const handleMute = (e) => {
    e.stopPropagation();
    setMute(!mute);
  };

  const render = () => {
    const renderActionButton = (banner) => {
      if (position === "banner_welcome") return <></>;
      if (banner?.action_href?.length > 0) {
        return banner?.action_href?.map((action) => (
          <div
            className={`action-href-banner text-ellipsis short-1 ${
              action?.position || "bottom-left"
            }`}
          >
            {action?.type === "image" ? (
              <div
                className="cursor-pointer"
                onClick={() => handleClickAction(action)}
              >
                <img
                  height={50}
                  width={"auto"}
                  src={getUrlImage(action?.button_image)}
                  alt=""
                />
              </div>
            ) : (
              <VButton
                className={"py-0 px-2"}
                style={{
                  color: action.text_color,
                  backgroundColor: action.background_color,
                  borderColor: action.background_color,
                }}
                onClick={() => handleClickAction(action)}
              >
                {action.button_name}
              </VButton>
            )}
          </div>
        ));
      }
      return <></>;
    };

    const renderSound = () => {
      return (
        <div className="_speaker" onClick={handleMute}>
          {mute ? (
            <BiVolumeMute size={22} color="white" />
          ) : (
            <VscUnmute size={22} color="white" />
          )}
        </div>
      );
    };

    return banners
      .sort((prev, next) => prev.order - next.order)
      .map((banner) => {
        if (banner.youtube) {
          return (
            <div key={banner?._id} className="banner-item container-video-link">
              <ReactPlayer
                muted={mute}
                loop={true}
                controls={false}
                key={banner._id}
                playing={true}
                className="player-video-link w-100"
                url={banner.youtube}
              />
              {renderSound()}
              {renderActionButton(banner)}
            </div>
          );
        }
        if (banner.facebook) {
          return (
            <div key={banner?._id} className="banner-item container-video-link">
              <ReactPlayer
                muted={mute}
                loop={true}
                controls={false}
                key={banner._id}
                playing={true}
                className="player-video-link w-100"
                url={banner.facebook}
              />
              {renderSound()}
              {renderActionButton(banner)}
            </div>
          );
        }
        if (banner.video || banner?.link_video) {
          return (
            <div key={banner?._id} className="banner-item">
              <div
                onClick={() =>
                  handleClickAction({ link: banner?.link_href_video })
                }
              >
                <ReactPlayer
                  muted={mute}
                  loop={true}
                  config={{
                    soundcloud: {
                      options: {
                        download: true,
                      },
                    },
                  }}
                  controls={false}
                  key={banner._id}
                  playing={true}
                  className="player w-100"
                  url={getUrlImage(banner?.video || banner?.link_video)}
                />
              </div>
              {renderSound()}
              {renderActionButton(banner)}
            </div>
          );
        }
        if (banner.images.length > 0) {
          return (
            <div key={banner?._id} className="banner-item">
              <VSlider data={banner.images} resize_image={1200} />
              {renderActionButton(banner)}
            </div>
          );
        }

        return null;
      });
  };

  return (
    banners?.length > 0 && (
      <div>
        <div className="v-banner">{render()}</div>
      </div>
    )
  );
};

export default View;

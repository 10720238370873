/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { useEffect, useState } from "react";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import { useHistory } from "react-router-dom";
import VIcon from "components/v-icon";
import { getPostDirectory } from "redux/reducers/post-directory/post-directory.selectors";
import { useAppSelector } from "redux/reducers/hook";
import { DIRECTORY_STATUS } from "config/enums";
import { getUrlImage } from "utils/common";
import useQuery from "hooks/useQuery";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  dataFolder: any;
};

const View = (props: IProps) => {
  const { isAuth } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQuery();
  const postDirectory = useAppSelector(getPostDirectory);

  const [limitShow, setLimitShow] = useState(5);
  const [postDirectoryData, setPostDirectoryData] = useState(
    postDirectory?.value
  );
  const [postDirectoryRender, setPostDirectoryRender] = useState([]);

  const handleClick = (id: string) => {
    setPostDirectoryData((prev) =>
      prev.map((el) =>
        el.id === id
          ? {
              ...el,
              show: !Boolean(el?.show),
            }
          : el
      )
    );
  };

  const handleAction = () => {
    history.push("?setting=post-directory");
  };

  const handlFilterByDirectory = (id: string) => {
    history.push(`?tab=timeline&post_directory=${id}`);
  };

  const getItems = (mapList, parentId) => {
    let items = mapList.filter((e: any) => e.parent_id === parentId);
    items.forEach((el: any) => {
      const items = getItems(mapList, el.id);
      if (items && items != null && items.length > 0) {
        el.children = items;
      }
    });
    return items;
  };

  useEffect(() => {
    if (postDirectoryData && postDirectoryData.length > 0) {
      const value = postDirectoryData?.map((el) => ({
        status: DIRECTORY_STATUS.PUBLIC,
        ...el,
      }));

      let tree = value.filter((e: any) => !e.parent_id || e?.parent_id == null);

      tree.forEach((el: any) => {
        const items = getItems(value, el.id);
        if (items && items != null && items.length > 0) {
          el.children = items;
        }
      });
      setPostDirectoryRender(tree);
    }
  }, [postDirectoryData]);

  useEffect(() => {
    if (postDirectory?.value?.length > 0)
      setPostDirectoryData(postDirectory.value);
  }, [postDirectory]);

  const renderPostDirectory = (el) => {
    return (
      <>
        {el.name && (
          <>
            <div
              className={`d-flex justify-content-between px-1 ${
                query.post_directory === el.id ? "active-item-directory" : ""
              }`}
            >
              <div className="d-flex">
                {el.icon ? (
                  <div className="icon-post-directory">
                    <img src={getUrlImage(el.icon, 50)} alt="" />
                  </div>
                ) : (
                  <VIcon name={"list-folder.svg"} width={16} className="me-2" />
                )}
                {el.status === DIRECTORY_STATUS.PRIVATE && (
                  <VIcon name={"lock.svg"} width={16} className="me-2" />
                )}
                <span
                  className="fw-bold"
                  style={{
                    color:
                      postDirectory?.infoDirectory?.color?.[
                        !el.parent_id ? "parent" : "child"
                      ],
                  }}
                  onClick={() => handlFilterByDirectory(el.id)}
                >
                  {el.name}
                </span>
              </div>
              {el?.children?.filter((item) => item.name)?.length > 0 && (
                <div
                  className={`${
                    el.show ? "" : "drop-list-folder"
                  } icon-right-directory`}
                >
                  <VIcon
                    name="dropdown.svg"
                    width={16}
                    onClick={() => handleClick(el.id)}
                  />
                </div>
              )}
            </div>
            {el.show && (
              <ul className="m-0">
                {el?.children
                  ?.filter((directory) =>
                    isAuth ? {} : directory.status !== DIRECTORY_STATUS.PRIVATE
                  )
                  ?.map((item) => renderPostDirectory(item))}
              </ul>
            )}
          </>
        )}
      </>
    );
  };

  return (
    <>
      {(!postDirectory?.infoDirectory?.display_type ||
        postDirectory?.infoDirectory?.display_type === "vertical") && (
        <Card>
          <Card.Header className={"p-2"}>
            <div className="w-100 d-flex justify-content-between">
              <div className="title-left-sidebar">
                {postDirectory?.infoDirectory?.name ||
                  t("Table of Contents Diary")}
              </div>
              {isAuth && (
                <div className="cursor-pointer" onClick={handleAction}>
                  <VIcon name="edit-page.svg" width={16} className="me-2" />
                </div>
              )}
            </div>
          </Card.Header>

          <Card.Body className="p-2">
            <div>
              {postDirectoryRender &&
                postDirectoryRender
                  ?.filter((directory) =>
                    isAuth ? {} : directory.status !== DIRECTORY_STATUS.PRIVATE
                  )
                  ?.slice(0, limitShow)
                  ?.map((el) => (
                    <div key={el.id} className="cursor-pointer">
                      {renderPostDirectory(el)}
                    </div>
                  ))}
              {postDirectoryRender?.length > 5 && (
                <div className="text-center">
                  {limitShow > 5 ? (
                    <div
                      className="cursor-pointer text-primary"
                      onClick={() => setLimitShow(5)}
                    >
                      {t("Collapse")}
                    </div>
                  ) : (
                    <div
                      className="cursor-pointer text-primary"
                      onClick={() => setLimitShow(postDirectoryRender?.length)}
                    >
                      {t("See more")}
                    </div>
                  )}
                </div>
              )}
            </div>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";
import Error404 from "components/v-error-404";

import { useParams } from "react-router-dom";
import VQRCode from "components/v-qr-code";
import { getUrlImage, getUrlProfile } from "utils/common";
import UserService from "api/users";
import styled from "styled-components";
import LocationSvg from "assets/images/icon/location.svg";
import MailSvg from "assets/images/icon/mail.svg";
import MessageSvg from "assets/images/icon/message.svg";
import { ROUTES } from "config/routes";
import useTranslation from "hooks/useTranslation";

import VNameVerify from "components/v-objects/v-name-verify";

import { useAppSelector } from "redux/reducers/hook";
import { getLocations } from "redux/reducers/settings/settings.selector";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import { useDispatch } from "react-redux";

const Avatar = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  margin-right: 15px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
  }

  .national_flag {
    position: absolute;
    top: -25%;
    left: -25%;
    width: 50%;
    height: auto;
    max-height: 50px;
  }
`;

const ImageDetail = styled.img`
  width: ${(props) => props.width || "22px"};
  height: auto;
`;

const ChatButton = styled.button`
  width: 250px;
`;

const ProfileContent = styled.div`
  max-width: calc(100% - 200px);
  min-width: 350px;

  .username {
    position: relative;
    display: flex;
  }
`;

interface IProps {
  userId?: string;  
}

const View = (props: IProps) => {
  const params = useParams();
  const { id: query_id } = params;

  const {userId:id = query_id} = props;

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const domain = window.location.origin;
  const [loading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const [profile, setProfile] = useState(null);
  const locations = useAppSelector(getLocations);

  const handleTexting = async (to_id: string) => {
    dispatch(
      setOpenWebModulesPopupAction({
        openWebModuleKey: "mini_chat_detail",
        payload: {
          userIdMiniChat: to_id,
        },
      })
    );
  };

  useEffect(() => {
    getUserById();
  }, [id]);

  const getUserById = async () => {
    setLoading(true);
    try {
      const profile = await UserService.getProfileById({
        params: {
          id: id,
        },
      });

      setProfile(profile);
      setIsNotFound(false);
    } catch {
      setIsNotFound(true);
    }

    setLoading(false);
  };

  const country = useMemo(() => {
    if (profile) {
      const _country = profile?.user?.profile?.location?.country;
      if (_country) {
        return locations?.find(
          (location: { _id: any }) => location._id === _country
        );
      }
    }
    return null;
  }, [profile, locations]);

  return isNotFound && !loading ? (
    <Error404 />
  ) : (
    !loading && profile && (
      <div className={`profile-wrapper ${query_id ? 'mt-5' : ''}`} id="profile-preview">
        <div className="bg-white p-5 rounded">
          <div className="d-flex flex-wrap justify-content-center">
            <ProfileContent className="profile-detail flex-grow-1">
              <div className="d-flex mb-5">
                <Avatar>
                  <img
                    alt="avatar"
                    src={
                      getUrlImage(profile?.user?.avatar, 150) ||
                      "/assets/images/VDB-64.png"
                    }
                  />

                  {country && (
                    <img
                      className="national_flag"
                      src={getUrlImage(country?.icon)}
                      alt=""
                    />
                  )}
                </Avatar>

                <div className="profile-name">
                  <h3>
                    <VNameVerify
                      dataUser={profile?.user}
                      className={"username"}
                    />
                  </h3>

                  {profile?.user?.object_type && (
                    <h5 className="mb-2">{t(profile?.user?.object_type)}</h5>
                  )}
                  
                  <ChatButton 
                    className="btn btn-primary btn-lg"
                    onClick={
                      !!props?.userId
                        ? () => handleTexting(profile?.user?._id)
                        : () => {
                          window.open(String(`${ROUTES.MESSAGE}?r=${profile?.user?._id}`));
                        }
                    }
                  >
                    <ImageDetail
                      width={"25px"}
                      src={MessageSvg}
                      alt=""
                      className="mb-1"
                      style={{ marginRight: "5px" }}
                    />
                    {t("chatting")}
                  </ChatButton>                  
                </div>
              </div>
              <div>
                <h4>
                  <b>{t("Descriptions")}</b>
                </h4>
                {profile?.user?.location_detail && (
                  <p className="mb-1">
                    <ImageDetail src={LocationSvg} alt="" />{" "}
                    {profile?.user?.location_detail}
                  </p>
                )}

                {profile?.user?.email && (
                  <p className="mb-3">
                    <ImageDetail src={MailSvg} alt="" />{" "}
                    <a href={`mailto:${profile?.user?.email}`}>
                      {profile?.user?.email}
                    </a>
                  </p>
                )}

                {profile?.user?.profile?.description && (
                  <>
                    <hr />
                    <p>{profile?.user?.profile?.description}</p>
                  </>
                )}
              </div>
            </ProfileContent>
            <div className="profile-qr">
              <VQRCode
                value={`${domain}${getUrlProfile(profile.user)}`}
                size={150}
              />
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import VIcon from "components/v-icon";
import { useCallback, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { getUrlImage } from "utils/common";
import { renderDateTime } from "utils/times";
import useQuery from "hooks/useQuery";
import { useHistory } from "react-router-dom";
import VAvatar from "components/v-avatar";
import useTranslationHook from "hooks/useTranslation";
import VRightView from "components/v-right-view";
import VBannerPage from "components/v-teamplate/v-banner-page";
// import ReactPlayer from "react-player";
import { ROUTES } from "config/routes";
import VWebView from "components/v-web-view";
import ProfileHeader from "pages/social/object-details/profile-header";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import {
  getFields,
  getGeneral,
} from "redux/reducers/settings/settings.selector";
import { useAppSelector } from "redux/reducers/hook";
import {
  getLinks,
  getLinksFaceBook,
  getLinksYoutube,
} from "utils/convertIdVideo";
import ReactPlayer from "react-player";
import VDropdown from "components/v-dropdown";
import { WEB_MODULE_URL } from "config/env";
import debounce from "lodash.debounce";
import VInput from "components/v-input";

const optionsFilter = [
  {
    key: "all",
    name: "all",
  },
  // {
  //   key: "new",
  //   name: "moi nhat",
  // },
];

const View = (props) => {
  const {
    data,
    dataUser = null,
    isAuth = false,
    type,
    key_select = "",
    filter,
    setFilter,
  } = props;
  const { t } = useTranslationHook();
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const query = useQuery();
  const history = useHistory();
  const general = useAppSelector(getGeneral);
  const categoryFiels = useAppSelector(getFields);
  const [videoDetail, setVideoDetail] = useState(null);

  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);

  const isChannelImageProfile = type === "image" && key_select === "profile";
  const image_default_audio = general?.site?.find(
    (el) => el.key === "post_image_default_audio"
  );
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null

  const handleFilter = (type: string, value: any) => {
    if (type === "type") {
      setFilter({
        [type]: value,
        category_id: null,
        search: "",
      });
    } else {
      setFilter((prev) => ({
        ...prev,
        [type]: value,
      }));
    }
  };

  const handleSearch = (e) => {
    setFilter((prev) => ({
      ...prev,
      search: e.target.value,
    }));
  };

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 1000), []);

  const renderUrlLink = (post: any) => {
    const links = getLinks(post.post_content);
    const convertLink = links.join(" ");
    const linkYT = getLinksYoutube(convertLink);
    const linkFB = getLinksFaceBook(post?.post_content);
    if (linkYT && linkYT[0]) {
      return linkYT[1] || linkYT[0];
    } else if (linkFB && linkFB[0]) {
      return linkFB[0];
    }
  };

  const handleClickItem = (item) => {
    const search = new URLSearchParams(window.location.search);

    search.set("view", String(item?._id));
    history.push(`?${search}`);
    window.scrollTo(0, 0);
  };

  const handleClickChannel = (id) => {
    history.push(ROUTES.PROFILE_VIDEOS.replace(":id", id));
  };

  const renderCountView = (data) => {
    const view = data?.find((el) => el.feel_type === "view");
    if (view) {
      return view.count_views;
    }
    return 0;
  };

  useEffect(() => {
    const _videoDetail = data?.find((el) => el._id === query.view);
    if (_videoDetail) {
      setVideoDetail(_videoDetail);
    } else {
      setVideoDetail(data[0]);
    }
  }, [query.view, data]);

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  return (
    <Row className="px-1 justify-content-center">
      <Col className={`col-12 ${show_banner_right_on_container ? "col-md-8" : ""}`} style={{maxWidth: "45rem"}}>
        {!isAuth && (
          <>
            {dataUser ? (
              <ProfileHeader
                dataUser={dataUser}
                isMyProfile={isAuth}
                isChannel={true}
              />
            ) : (
              <VBannerPage position="banner_top_profile_videos" />
            )}
          </>
        )}

        {videoDetail && (
          <VWebView
            key={videoDetail._id}
            className={"iframe-webview-channel"}
            url={`${WEB_MODULE_URL}/channel/${
              isChannelImageProfile ? videoDetail?.post_id : videoDetail._id
            }?origin=${window.origin}&vdb_token=${localStorage.getItem(
              "auth"
            )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&lang=${
              i18n.language ? `${i18n.language}` : ""
            }`}
          />
        )}

        <div className="d-flex flex-wrap align-items-center py-2">
          <div className="mb-3 d-flex align-items-center">
            {optionsFilter.map((el) => (
              <div
                key={el.key}
                className={`d-flex align-items-center justify-content-center px-3 mx-1 rounded cursor-pointer text-center ${
                  filter?.type === el.key && !filter?.category_id
                    ? "bg-primary text-white"
                    : "border"
                }`}
                style={{
                  minWidth: "70px",
                  height: "33px",
                }}
                onClick={() => handleFilter("type", el.key)}
              >
                {t(el.name)}
              </div>
            ))}
          </div>
          <div className="mb-3">
            <VInput
              transparent={true}
              label={t("Tìm kiếm")}
              suffix={<VIcon width={16} name="search.svg" />}
              onChange={debouncedChangeHandler}
            />
          </div>
          <div className="ms-1" style={{ minWidth: "300px" }}>
            <VDropdown
              placeholder={t("Chủ đề")}
              value={filter?.category_id}
              options={categoryFiels?.map((el: any) => {
                return {
                  label: el.name,
                  value: el?._id,
                };
              })}
              onChange={(e: any) => handleFilter("category_id", e.target.value)}
            />
          </div>
        </div>
        <div>
          {/* {data?.length > 0 && (
            <strong className="fs-4">{t("Related logs")}</strong>
          )} */}
          {data?.length > 0 ? (
            data?.map((el) => (
              <Col key={el._id} className={`d-flex mt-2`}>
                <div className="col-6 col-md-4">
                  <div className="user-images user-video position-relative overflow-hidden">
                    <div
                      className="item-image "
                      onClick={() => handleClickItem(el)}
                    >
                      {type === "audio" ? (
                        <div className="icon-mp3">
                          {image_default_audio?.site_media ? (
                            <img
                              src={getUrlImage(image_default_audio?.site_media)}
                              alt=""
                            />
                          ) : (
                            <VIcon name="music.svg" />
                          )}
                        </div>
                      ) : type === "image" ? (
                        <img
                          className="img-fluid rounded"
                          src={getUrlImage(
                            el?.post_file_path ||
                              el?.post_files[0]?.post_file_path
                          )}
                          alt="img"
                        />
                      ) : type === "link" ? (
                        <div className="pe-none">
                          <ReactPlayer
                            width={"100%"}
                            height={"100%"}
                            playing={false}
                            muted={true}
                            loop={false}
                            controls={false}
                            url={renderUrlLink(el)}
                          />
                        </div>
                      ) : (
                        <video className="img-fluid rounded">
                          <source
                            src={getUrlImage(el?.post_files[0]?.post_file_path)}
                            type={el?.post_file_type}
                          />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                    {type !== "image" && (
                      <div
                        className={`btn-play-video ${
                          type === "audio" ? "btn-play-audio" : ""
                        }`}
                      >
                        <VIcon className="_play-audio" name="play.svg" />
                      </div>
                    )}
                  </div>
                </div>
                <div className="ms-3">
                  <strong className="text-ellipsis short-2">
                    {el.post_title || t("Unnamed")}
                  </strong>
                  <div className="text-ellipsis short-1">
                    {renderDateTime(el.createdAt)}
                  </div>
                  <div className="text-ellipsis short-1 d-flex align-items-center">
                    <VIcon name={"eye.svg"} className="me-1" width={14} />
                    {renderCountView(el.post_count_feels)}
                  </div>
                  <div
                    className="text-ellipsis short-1 d-flex align-items-center"
                    style={{
                      maxWidth: "150px",
                    }}
                    onClick={() =>
                      handleClickChannel(
                        type === "image"
                          ? el?.user_profile?._id
                          : el?.profile?._id
                      )
                    }
                  >
                    <VAvatar
                      className="m-0"
                      size={"12px"}
                      fontSize={"12px"}
                      spacingImgWithName={"5px"}
                      rezise={50}
                      fontSizeIconVerify={8}
                      userData={
                        type === "image" ? el?.user_profile : el.profile
                      }
                    />
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <div>Không có nội dung nào phù hợp</div>
          )}
        </div>
      </Col>
      {show_banner_right_on_container && (
        <Col className="col-12 col-md-4 pb-3">
          <VRightView
            menuKey={"tab_right_profile_videos"}
            positionBanner={"banner_right_profile_videos"}
          />
        </Col>
      )}
    </Row>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable indent */
import React, { useState, useContext, useEffect, useMemo } from "react";

//router
import { Link, useLocation, useHistory } from "react-router-dom";

//react-bootstrap
import {
  Accordion,
  useAccordionButton,
  AccordionContext,
  Nav,
  Tooltip,
  OverlayTrigger,
} from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import useTranslation from "hooks/useTranslation";
import {
  getUserAddressAndCareer,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import { convertUrl, getUrlImage } from "utils/common";
import VIcon from "components/v-icon";
import { isMobile } from "react-device-detect";
import { ROUTES } from "config/routes";
import * as SettingSelector from "redux/reducers/setting-system/selectors";
import NewsFeedContext from "context/NewsFeedContext/View";

// import VTab from "components/v-tab";

const defaultKeyHeadTab = "dashboard";

function CustomToggle({ children, eventKey, onClick, active }: any) {
  const { activeEventKey } = useContext(AccordionContext);

  const decoratedOnClick = useAccordionButton(eventKey, (active) =>
    onClick({ state: !active, eventKey: eventKey })
  );

  const isCurrentEventKey: any = activeEventKey === eventKey;

  return (
    <Link
      // to="#"
      aria-expanded={isCurrentEventKey ? "true" : "false"}
      className={`nav-link border-bottom`}
      role="button"
      onClick={(e) => {
        decoratedOnClick(isCurrentEventKey);
      }}
    >
      {children}
    </Link>
  );
}

const VerticalNav = React.memo(() => {
  const userData: any = useAppSelector(getUserProfile);

  const menuDashboard = useAppSelector(getMenusByKey("dashboard"));
  const menuShop = useAppSelector(getMenusByKey("menu_shop_sidebar"));

  const sidebarHeadTabs = useAppSelector(getMenusByKey("sidebar_head_tab"));
  const news = useAppSelector(getUserAddressAndCareer);

  const newsFeedContext = useContext(NewsFeedContext);

  const [, setActiveMenu] = useState<any>(false);
  const [listMenuDashboard, setListMenuDashboard] = useState({});
  const history = useHistory();
  const [loadMenu, setLoadMenu] = useState(false);
  const [categoriesDashboard, setCategoriesDashboard] = useState([]);
  const [sidebarHeadTab, setSidebarHeadTab] = useState(
    sidebarHeadTabs.find((item) => item.order === 0) || sidebarHeadTabs[0]
  );

  //location
  const location = useLocation();

  const categoriesShop = useMemo(() => {
    if (!menuShop) return [];
    return menuShop.filter(
      (m) => m?.position === "menu_shop_sidebar" && !m.parent_id
    );
  }, [menuShop]);

  const listMenuShop = useMemo(() => {
    if (categoriesShop && categoriesShop.length > 0) {
      const _data = menuShop.filter(
        (m) => m.position === "menu_shop_sidebar" && m.parent_id
      );
      let _result = {};
      categoriesShop.forEach((c) => {
        const list = _data.filter((e) => e.parent_id === c._id);
        const _listContainerChildren = list.map((e) => {
          return {
            ...e,
            childrens: menuShop.filter((i) => i.parent_id === e._id),
          };
        });
        _result = {
          ..._result,
          [c.key]: _listContainerChildren,
        };
      });
      return _result;
    }
    return [];
  }, [categoriesShop]);

  const defaultHeadTab = useMemo(
    () => sidebarHeadTabs?.find((e: any) => e?.key === defaultKeyHeadTab),
    [sidebarHeadTabs]
  );

  useEffect(() => {
    if (categoriesDashboard && categoriesDashboard.length > 0) {
      const _data = menuDashboard.filter(
        (m) => m.position === "dashboard" && m.parent_id
      );
      let _result = {};
      categoriesDashboard.forEach((c) => {
        const list = _data.filter((e) => e.parent_id === c._id);
        const _listContainerChildren = list.map((e) => {
          return {
            ...e,
            childrens: menuDashboard.filter((i) => i.parent_id === e._id),
          };
        });
        _result = {
          ..._result,
          [c.key]: _listContainerChildren,
        };
      });
      setListMenuDashboard(_result);
    }
  }, [categoriesDashboard]);

  useEffect(() => {
    if (loadMenu) {
      const _data = menuDashboard.filter(
        (m) => m.position === "dashboard" && !m.parent_id
      );
      setCategoriesDashboard(_data);
    }
  }, [loadMenu]);

  useEffect(() => {
    if (menuDashboard && menuDashboard.length > 0) {
      setLoadMenu(true);
    }
  }, [menuDashboard]);

  useEffect(() => {
    if (!sidebarHeadTabs) return;

    const params = new URLSearchParams(location?.search);
    const queryObject = Object.fromEntries(params);

    const item =
      sidebarHeadTabs?.find((e: any) => e?.key === queryObject?.tab) ||
      defaultHeadTab;

    setSidebarHeadTab(item);
  }, [sidebarHeadTabs]);

  const handleClickMenu = (menu) => {
    if (isMobile) {
      document
        .getElementsByTagName("ASIDE")[0]
        ?.classList.toggle("sidebar-mini");
    }
    switch (menu.type_view) {
      case "internal":
        if (menu.key === "news_feed")
          newsFeedContext.setTimeReloaded(Date.now());
        history.push(convertUrl(menu.url, userData) || "/");
        break;
      case "link":
        window.open(convertUrl(menu.url), "_blank");
        break;
      case "webview":
        history.push(`/wv/${menu.key}`);
        break;
      default:
        break;
    }
  };

  const handleClickNews = (menu) => {
    if (isMobile) {
      document
        .getElementsByTagName("ASIDE")[0]
        ?.classList.toggle("sidebar-mini");
    }
    if (!menu) return;
    history.push(
      convertUrl(ROUTES.PROFILE.replace(":id", menu?.special_name || menu._id))
    );
  };

  return (
    <React.Fragment>
      <div
        className="close-sidebar-mobile"
        onClick={() => handleClickNews(null)}
      >
        <VIcon name="close.svg" />
      </div>
      
      {/* <VTab
        menus={sidebarHeadTabs}
        tab={sidebarHeadTab}
        setTab={setSidebarHeadTab}
      /> */}

      {(sidebarHeadTab?.key === "dashboard" || !sidebarHeadTab) && (
        <VerticalMenu
          categories={categoriesDashboard}
          listMenu={listMenuDashboard}
          newsProps={{ news, handleClickNews }}
          setActiveMenu={setActiveMenu}
          handleClickMenu={handleClickMenu}
        />
      )}

      {sidebarHeadTab?.key === "shop" && (
        <VerticalMenu
          categories={categoriesShop}
          listMenu={listMenuShop}
          setActiveMenu={setActiveMenu}
          handleClickMenu={handleClickMenu}
        />
      )}
    </React.Fragment>
  );
});

interface IProps {
  categories: any;
  listMenu: any;
  setActiveMenu: Function;
  handleClickMenu: Function;
  newsProps?: {
    news?: any;
    handleClickNews?: Function;
  };
}

const VerticalMenu = ({
  categories,
  listMenu,
  setActiveMenu,
  handleClickMenu,
  newsProps,
}: IProps) => {
  const themeScheme = useAppSelector(SettingSelector.theme_scheme);
  const userData: any = useAppSelector(getUserProfile);
  const { news, handleClickNews } = newsProps ?? {};

  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
      {categories.map((category, idx) => (
        <div key={idx}>
          <li className="nav-item static-item">
            <a
              className="nav-link static-item disabled border-bottom border-secondary fw-bold"
              href="#"
            >
              <span className="default-icon">{t(category.name)}</span>
              <span
                className="mini-icon"
                data-bs-toggle="tooltip"
                title={t(category.name)}
                data-bs-placement="right"
              >
                -
              </span>
            </a>
          </li>

          {(listMenu[category.key] || []).map((menu, index) => {
            if (menu.childrens && menu.childrens.length > 0) {
              return (
                <Accordion.Item
                  key={index}
                  as="li"
                  eventKey="friends-menu"
                  bsPrefix="nav-item"
                  className={`${themeScheme}_sidebar`}
                >
                  <CustomToggle
                    eventKey={menu.name}
                    onClick={(activeKey) => setActiveMenu(activeKey)}
                  >
                    {/* <div className="d-flex nav-item border-bottom"> */}
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip>{t(menu.name)}</Tooltip>}
                    >
                      {menu?.img_icon ? (
                        <img
                          width={"20px"}
                          // height={"20px"}
                          src={getUrlImage(menu?.img_icon, 25)}
                          alt=""
                        />
                      ) : (
                        <VIcon name={menu.icon} />
                      )}
                    </OverlayTrigger>
                    <span className="item-name">{t(menu.name)}</span>
                    <i className="right-icon">
                      <VIcon width={16} name={"dropdown.svg"} />
                    </i>
                    {/* </div> */}
                  </CustomToggle>
                  <Accordion.Collapse eventKey={menu.name}>
                    <ul className="sub-nav">
                      {(menu.childrens || []).map((m, i) => (
                        <Nav.Item
                          key={i}
                          as="li"
                          className={`${themeScheme}_sidebar`}
                        >
                          <a
                            className={`${
                              location.pathname === convertUrl(m.url, userData)
                                ? "active"
                                : ""
                            } nav-link border-bottom`}
                            onClick={(_) => handleClickMenu(m)}
                          >
                            <OverlayTrigger
                              placement="right"
                              overlay={<Tooltip>{t(m.name)}</Tooltip>}
                            >
                              {m?.img_icon ? (
                                <img
                                  width={"20px"}
                                  // height={"20px"}
                                  src={getUrlImage(m?.img_icon, 25)}
                                  alt=""
                                />
                              ) : (
                                <VIcon name={m.icon} />
                              )}
                            </OverlayTrigger>
                            <span className="item-name">{t(m.name)}</span>
                          </a>
                        </Nav.Item>
                      ))}
                    </ul>
                  </Accordion.Collapse>
                </Accordion.Item>
              );
            } else {
              return (
                <>
                  {newsProps &&
                  category?.key === "social" &&
                  menu?.name === "news" ? (
                    <Accordion.Item
                      key={idx}
                      as="li"
                      eventKey="friends-menu"
                      bsPrefix="nav-item"
                      className={`${themeScheme}_sidebar`}
                    >
                      <CustomToggle
                        eventKey={menu?.name}
                        onClick={(activeKey) => setActiveMenu(activeKey)}
                      >
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip>{t(menu?.name)}</Tooltip>}
                        >
                          {menu?.img_icon ? (
                            <img
                              width={"20px"}
                              src={getUrlImage(menu?.img_icon, 25)}
                              alt=""
                            />
                          ) : (
                            <VIcon name={menu.icon} />
                          )}
                        </OverlayTrigger>
                        <span className="item-name">{`${t(menu?.name)} (${
                          Object.keys(news)?.length
                        })`}</span>
                        <i className="right-icon">
                          <VIcon width={16} name={"dropdown.svg"} />
                        </i>
                        {/* </div> */}
                      </CustomToggle>

                      <Accordion.Collapse eventKey={"news"}>
                        <ul className="sub-nav">
                          {Object.keys(news).map((key, i) => (
                            <Nav.Item
                              key={i}
                              as="li"
                              className={`${themeScheme}_sidebar`}
                            >
                              <Link
                                className={`${
                                  location.pathname ===
                                  `/profile/${news?.[key]?.special_name}`
                                    ? "active"
                                    : ""
                                } nav-link border-bottom`}
                                aria-current="page"
                                onClick={() => handleClickNews(news?.[key])}
                              >
                                <OverlayTrigger
                                  placement="right"
                                  overlay={
                                    <Tooltip>
                                      {t(news?.[key]?.full_name)}
                                    </Tooltip>
                                  }
                                >
                                  {news?.[key]?.avatar ? (
                                    <img
                                      width={"20px"}
                                      // height={"20px"}
                                      src={getUrlImage(news?.[key]?.avatar, 25)}
                                      alt=""
                                    />
                                  ) : (
                                    <VIcon name={menu.icon} />
                                  )}
                                </OverlayTrigger>
                                <span className="item-name">
                                  {t(news?.[key]?.full_name)}
                                </span>
                              </Link>
                            </Nav.Item>
                          ))}
                        </ul>
                      </Accordion.Collapse>
                    </Accordion.Item>
                  ) : (
                    <Nav.Item
                      key={index}
                      as="li"
                      className={`${themeScheme}_sidebar`}
                    >
                      <a
                        className={`${
                          location.pathname === menu.url ? "active" : ""
                        } nav-link border-bottom`}
                        aria-current="page"
                        onClick={(_) => handleClickMenu(menu)}
                      >
                        <OverlayTrigger
                          placement="right"
                          overlay={<Tooltip>{t(menu.name)}</Tooltip>}
                        >
                          {menu?.img_icon ? (
                            <img
                              width={"20px"}
                              // height={"20px"}
                              src={getUrlImage(menu?.img_icon, 25)}
                              alt=""
                            />
                          ) : (
                            <VIcon name={menu.icon} />
                          )}
                        </OverlayTrigger>
                        <span className="item-name">{t(menu.name)}</span>
                      </a>
                    </Nav.Item>
                  )}
                </>
              );
            }
          })}
        </div>
      ))}
    </Accordion>
  );
};

const areEqual = (prevProps, nextProps) => true;

export default React.memo(VerticalNav, areEqual);

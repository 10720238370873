import useTranslation from "hooks/useTranslation";
import { Link } from "react-router-dom";
import { isValidURL } from "utils/common";
import WorkTimePreview from "../utils/Work-time-preview";
const PreviewGroup = ({ userData, isAuth = false }) => {
  const { t } = useTranslation();
  const { profile } = userData;

  const handleClick = (url) => {
    window.open(url);
  };
  return (
    <>
      <div className="row-account">
        <div className="label">{t("Group name")}</div>
        <div className="content">{userData?.full_name}</div>
      </div>
      {/* <div className="row-account">
        <div className="label">{t("Founding")}</div>
        <div className="content">{renderDate(profile?.birthday)}</div>
      </div> */}
      <div className="row-account">
        <div className="label">{t("Work time")}</div>
        <div className="content">
          <WorkTimePreview work_time={profile?.work_time} />
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Address")}</div>
        <div className="content">
          <Link
            onClick={() =>
              handleClick(
                `https://maps.google.com/maps?q=${encodeURIComponent(
                  userData?.location_detail
                )}&t={{2}}&z=14&ie=UTF8`
              )
            }
            target={"_blank"}
          >
            {userData?.location_detail || ""}
          </Link>
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Website")}</div>
        <div className="content">
          {isValidURL(profile?.website || profile?.business_info?.website) ? (
            <a
              href={profile?.website || profile?.business_info?.website}
              target={"_blank"}
              rel="noreferrer"
            >
              {profile?.website || profile?.business_info?.website}
            </a>
          ) : (
            profile?.website || profile?.business_info?.website
          )}
        </div>
      </div>
      {profile?.secondary_site?.length > 0 &&
        profile?.secondary_site.map((el) => (
          <div key={el?.id} className="row-account">
            <div className="label">{el?.name_website}</div>
            <div className="content">
              {isValidURL(el?.value) ? (
                <a href={el?.value} target={"_blank"} rel="noreferrer">
                  {el?.value}
                </a>
              ) : (
                el?.value
              )}
            </div>
          </div>
        ))}
      <div className="row-account">
        <div className="label">{t("Introduce")}</div>
        <div className="content">{profile?.description}</div>
      </div>
    </>
  );
};

export default PreviewGroup;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileGroup from "pages/social/groups/profile";
import ConversationsService from "api/conversations";
import UserProfile from "pages/social/user/profile";

const Conversation = (props: any) => {
  const [conversation, setConversation] = useState<any>({});
  const params = useParams();
  const isTypeGroup = conversation?.type === "group";

  const { id } = params;
  const getConversationDetailById = async () => {
    try {
      const conversation_id: any =
        await ConversationsService.getConversationDetailById(id);

      if (conversation_id) {
        setConversation(conversation_id);
      }
    } catch (error) {}
  };
  useEffect(() => {
    if (id) {
      getConversationDetailById();
    }
  }, [id]);

  return <>{isTypeGroup ? <ProfileGroup /> : <UserProfile isChat={true} />}</>;
};

export default Conversation;

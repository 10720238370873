/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import Step0 from "./steps/step0";
import Step1 from "./steps/step1";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTES } from "config/routes";
import AuthApi from "api/auth";
import useTranslation from "hooks/useTranslation";
import { REGEX_EMAIL } from "utils/validates";
import { OBJECT_TYPE } from "config/enums";

function View({ setOptions }) {
  const { t } = useTranslation();

  const defaultState = {
    gender: "MALE",
    email: "",
    family_id: null,
  };

  const defaultValidate = {
    email: null,
    password: null,
    phone: null,
    family_id: null,
  };

  const [step, setStep] = useState<number>(0);
  const [role, setRole] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(defaultState);
  const history = useHistory();

  const [validate, setValidate] = useState(defaultValidate);
  const [isValid, setIsValid] = useState(false);

  const handleChangeState = (name, value) => {
    let _state = state;
    _state[name] = value;
    setState(_state);
    performValidate([name], _state);
  };

  useEffect(() => {
    setValidate(defaultValidate);
    setState(defaultState);
  }, []);

  const performValidate = (props = [], currentDetail = null) => {
    let _result = validate;
    let _detail = currentDetail || state;
    let _isValid = true;
    if (props.length === 0) {
      for (const property in _result) {
        props.push(property);
      }
    }
    props.forEach((prop) => {
      switch (prop) {
        case "full_name":
          _result[prop] = !_detail.full_name
            ? `${t("name")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "first_name":
          _result[prop] = !_detail.first_name
            ? `${t("message.cant-be-empty")}`
            : null;
          break;
        case "last_name":
          _result[prop] = !_detail.last_name
            ? `${t("message.cant-be-empty")}`
            : null;
          break;
        case "phone":
          _result[prop] = !_detail.phone
            ? `${t("Phone")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "email":
          _result[prop] = !_detail.email
            ? `${t("Email")} ${t("message.cant-be-empty")}`
            : null;
          if (_detail.email && !REGEX_EMAIL.test(_detail.email))
            _result[prop] = `${t("message.invalid-email")}`;
          break;
        case "password":
          _result[prop] = !_detail.password
            ? `${t("Password")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "referral_code":
          _result[prop] = !_detail.referred_by
            ? `${t("Referral code")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "family_id":
          if (
            role !== OBJECT_TYPE.BUSINESS &&
            role !== OBJECT_TYPE.ORGANIZATION
          ) {
            _result[prop] = !_detail.family_id
              ? `${t("Family")} ${t("message.cant-be-empty")}`
              : null;
            break;
          } else {
            break;
          }
        default:
          break;
      }
    });
    setValidate((prev) => ({
      ...prev,
      ..._result,
    }));

    for (const property in _result) {
      if (_result[property]) {
        _isValid = false;
        break;
      }
    }
    setIsValid(_isValid);
    return _isValid;
  };

  const handleRegister = async ({
    location,
    home_town,
    position,
    birthday,
  }) => {
    try {
      const isValid = performValidate([]);
      if (!isValid) return;

      setLoading(true);
      const res: any = await AuthApi.register({
        ...state,
        family_id: state?.family_id || null,
        location,
        home_town,
        position,
        birthday,
        object_type: role,
      });
      if (res) {
        history.push(`${ROUTES.VERIFY_EMAIL}?email=${state.email}`);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(error?.errors));
    }
  };

  return (
    <>
      {step === 0 && (
        <Step0
          setState={setState}
          state={state}
          setRole={setRole}
          setStep={setStep}
        />
      )}
      {step === 1 && (
        <Step1
          state={state}
          role={role}
          loading={loading}
          isValid={isValid}
          validate={validate}
          setOptions={setOptions}
          setIsValid={setIsValid}
          setLoading={setLoading}
          setState={setState}
          setStep={setStep}
          handleRegister={handleRegister}
          handleChangeState={handleChangeState}
        />
      )}
    </>
  );
}

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import VInput from "components/v-input";
import { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "redux/reducers/hook";
import { getLocations } from "redux/reducers/settings/settings.selector";
import { ISettingLanguage } from "types/settings.type";
import { getUrlImage } from "utils/common";

const LanguageAuth = () => {
  const { i18n } = useTranslation();
  const locations = useAppSelector(getLocations);
  const languages = locations?.filter((a) => a.type === "country");
  const [dataShow, setDataShow] = useState(languages);
  const handleChangeLanguage = (e: ISettingLanguage) => {
    i18n.changeLanguage(e.keyword);
  };

  const getCurrentLanguage = () => {
    const language = languages?.find(
      (language) => language.keyword === i18n.language
    );
    return (
      <>
        <img src={getUrlImage(language?.icon)} alt={language?.keyword} />
        <span>{language?.name}</span>
      </>
    );
  };

  useEffect(() => {
    if (languages && languages.length > 0 && i18n.language) {
      if (!languages.some((l) => l.keyword === i18n.language))
        i18n.changeLanguage("vi");
    }
  }, [i18n.language, languages]);

  const handleSearch = (value) => {
    if (!value) {
      setDataShow(languages);
    } else {
      const filter = languages?.filter((el) =>
        el.name?.toLowerCase()?.includes(value?.toLowerCase())
      );
      setDataShow(filter);
    }
  };

  useEffect(() => {
    setDataShow(languages);
  }, [locations]);

  return (
    <Dropdown className="flag-icon">
      <Dropdown.Toggle>{getCurrentLanguage()}</Dropdown.Toggle>
      <Dropdown.Menu>
        <div className="p-1">
          <VInput
            type="text"
            id="name"
            name="name"
            onChange={(e) => handleSearch(e.target.value)}
          />
        </div>
        {dataShow?.length > 0 &&
          dataShow.map((language) => (
            <Dropdown.Item
              key={language?._id}
              onClick={() => handleChangeLanguage(language)}
            >
              <img src={getUrlImage(language?.icon)} alt={language.keyword} />
              <span>{language.name}</span>
            </Dropdown.Item>
          ))}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageAuth;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  isBusiness,
  isGroup,
  isPage,
} from "utils/friends";

type IProps = {
  t: any;
  dataUser: any;
};

const SocialInfoProfile = (props: IProps) => {
  const { t, dataUser } = props;

  const count =
    dataUser.count && dataUser.count.count
      ? dataUser.count.count
      : {
          post: 0,
          follower: 0,
          friend: 0,
          following: 0,
          group: 0,
          business: 0,
        };

  const isFollowing =
    !isGroup(dataUser.object_type) &&
    !isPage(dataUser.object_type) &&
    !isBusiness(dataUser.object_type);
  const isFriend =
    !isGroup(dataUser.object_type) && !isPage(dataUser.object_type);
  const isMemberBusiness = isBusiness(dataUser.object_type);
  const isMemberGroup = isGroup(dataUser.object_type);

  return (
    <>
      <li className="text-center px-2">
        <h6>{t("Follower")}</h6>
        <p className="mb-0"> {`${count.follower || 0}`}</p>
      </li>
      {isFriend && (
        <li className="text-center px-2">
          <h6>{t("Friend")}</h6>
          <p className="mb-0"> {`${count.friend || 0}`}</p>
        </li>
      )}
      {isMemberBusiness && (
        <li className="text-center px-2">
          <h6>{t("Member")}</h6>
          <p className="mb-0"> {`${count.business || 0}`}</p>
        </li>
      )}
      {isMemberGroup && (
        <li className="text-center px-2">
          <h6>{t("Member")}</h6>
          <p className="mb-0"> {`${count.group || 0}`}</p>
        </li>
      )}
      {isFollowing && (
        <li className="text-center px-2">
          <h6>{t("Following")}</h6>
          <p className="mb-0"> {`${count.following || 0}`}</p>
        </li>
      )}
    </>
  );
};

export default SocialInfoProfile;

import { request, parseErrorResponse } from "./request";

const getCryptos = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/cryptos`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getStocksSwap = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/cryptos/stock-swap`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getStockProjetsSwap = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/cryptos/stock-pre-mainet`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getStocksPrice = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/cryptos/price-stock`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const swap = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/cryptos/swap`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const swapStocksProjectAsync = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/cryptos/swap-stocks-pre-mainet`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getHistorySwap = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/swaps`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const StockService = {
  getCryptos,
  getStocksSwap,
  getStockProjetsSwap,
  getStocksPrice,
  swap,
  swapStocksProjectAsync,
  getHistorySwap,
};

export default StockService;

import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { WEB_MODULE_URL } from "config/env";
import { getIsApp } from "redux/reducers/settings/settings.selector";
import { useAppSelector } from "redux/reducers/hook";

export default function NewsFeed({
  reloadNewsFeedNumber,
  moduleSessionId,
  fromSessionUid,
  type = "",
  className = "",
  hashtag = "",
  search = "",
  plugin = "",
  viewTab,
  lat = "",
  lng = "",
  getAll,
}: any) {
  const [loadingNewsFeed, setLoadingNewsFeed] = useState(true);
  const { i18n } = useTranslation();
  const isApp = useAppSelector(getIsApp);

  useEffect(() => {
    if (reloadNewsFeedNumber) {
      setLoadingNewsFeed(true);
    }
  }, [reloadNewsFeedNumber]);

  return (
    <div key={reloadNewsFeedNumber}>
      {loadingNewsFeed && (
        <div className="d-flex justify-content-center p-3">
          <Spinner />
        </div>
      )}
      <div className="d-flex justify-content-center">
        <iframe
          className={`${isApp ? "iframe-is-app" : ""} ${
            className || "iframe-webview-home"
          } newfeed-container`}
          onLoad={() => {
            setLoadingNewsFeed(false);
          }}
          src={`${WEB_MODULE_URL}?origin=${
            window.origin
          }&vdb_token=${localStorage.getItem(
            "auth"
          )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}${
            type ? `&type=${type}` : ""
          }${hashtag ? `&hashtag=${hashtag}` : ""}${
            search ? `&search=${search}` : ""
          }${lat ? `&lat=${lat}` : ""}${lng ? `&lng=${lng}` : ""}${
            plugin ? `&plugin=${plugin}` : ""
          }${getAll ? `&getAll=${getAll}` : ""}&lang=${
            i18n.language ? `${i18n.language}&viewTab=${viewTab}` : ""
          }`}
          title="module_news_feed"
          allow="clipboard-read; clipboard-write; microphone; geolocation; camera; display-capture; fullscreen; autoplay;"
          referrerPolicy="no-referrer"
          // sandbox="allow-scripts allow-forms allow-same-origin allow-popups"
        />
      </div>
    </div>
  );
}

import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import Switch from "react-switch";
import VButton from "components/v-button";
import UserSettingsService from "api/user-settings";
import { toast } from "react-toastify";

const View = ({ data, onload }) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    enable: true,
  });
  const handleChange = (checked) => {
    setState({
      enable: checked,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await UserSettingsService.updateUserSettings({
        body: {
          notification: state,
        },
      });
      if (res) {
        onload();
        setLoading(false);
        toast.success(t("Success"));
      }
    } catch (error) {
      setLoading(false);
      toast.success(t(error.errors));
    }
  };

  useEffect(() => {
    if (data) {
      setState(data);
    }
  }, [data]);

  return (
    <div className="wrapper-notification-setting">
      <Form>
        <div className="d-flex align-items-center mb-2">
          <Form.Label>{t("Status")}</Form.Label>
          <div className="ms-4">
            <Switch
              width={44}
              height={20}
              onChange={handleChange}
              checked={state.enable}
            />
          </div>
        </div>
        <VButton
          loading={loading}
          height={"36px"}
          className="btn btn-primary me-2"
          onClick={handleSubmit}
        >
          {t("save")}
        </VButton>
      </Form>
    </div>
  );
};

export default View;

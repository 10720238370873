/* eslint-disable indent */
import useTranslation from "hooks/useTranslation";
import { convertUrl, getUrlImage } from "utils/common";
import { useHistory } from "react-router-dom";

const View = ({ name, childs, setWebview }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const viewHandler = (target) => {
    switch (target?.type_view) {
      case "link": {
        window.open(convertUrl(target.link), "_blank");
        break;
      }
      case "webview": {
        setWebview(target.link);
        break;
      }
      case "internal": {
        history.push(target.link);
        break;
      }
      default:
        break;
    }
  };

  return (
    <div>
      <div className="utilities">
        <div className="category-name-container border-bottom border-3">
          <div className="category-name px-5 py-2 text-white fit position-relative">
            <span className="name-utilities">{t(name)}</span>
            <div className="slash"></div>
          </div>
        </div>
      </div>
      <div className="row my-5">
        {(childs || []).map((child) => (
          <div key={child.id} className="col-4 col-md-3 col-lg-2 mb-2">
            <div
              onClick={() => viewHandler(child)}
              className="d-flex flex-column"
              style={{ cursor: "pointer" }}
            >
              <img
                src={getUrlImage(child.logo)}
                alt={child.name}
                style={{ width: "60px", height: "60px" }}
                className="m-auto p-1 border-2 border rounded-3"
              />
              <p className="text-center my-2 lh-1">{child.name}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default View;

/* eslint-disable no-unused-vars */
import UploadService from "api/uploads";
import VIcon from "components/v-icon";
import { useMemo, useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { ControllerRenderProps } from "react-hook-form";
import { BiPlus } from "react-icons/bi";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { getUrlImage } from "utils/common";

type IProps = {
  id: string;
  label?: string;
  name?: string;
  format?: string;
  required?: boolean;
  placeholder?: string;
  showTime?: any;
  error?: string;
  field?: ControllerRenderProps<any, any>;
  image?: string;
};

const VQuestionMedia = ({
  id = "",
  label = "",
  name = "",
  required = false,
  error,
  field,
  image,
}: IProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const user = useAppSelector(getUserProfile);
  const [filePath, setFilePath] = useState({
    path: field.value?.path,
    name: field.value?.name,
    type: field.value?.type,
  });

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange = async (e) => {
    const formData = new FormData();
    formData.append("folderPath", `users/${user?._id}`);
    formData.append("files", e.target.files[0]);
    const file: any = await UploadService.uploadFile({
      body: formData,
    });
    const value = {
      path: file.filePath,
      name: e.target.files[0]?.name,
      type: e.target.files[0]?.type,
    };
    field.onChange(value);
    setFilePath(value);
  };

  const renderIconFile = useMemo(() => {
    if (filePath?.type) {
      if (filePath?.type?.indexOf("xls") > 0) {
        return <VIcon name={"excel.svg"} height={60} width={60} />;
      }
      if (filePath?.type?.indexOf("doc") > 0) {
        return <VIcon name={"word.svg"} height={60} width={60} />;
      }
      if (filePath?.type?.indexOf("ptp") > 0) {
        return <VIcon name={"powerpoint.svg"} height={60} width={60} />;
      }
      if (filePath?.type?.indexOf("pdf") > 0) {
        return <VIcon name={"pdf.svg"} height={60} width={60} />;
      }
      if (filePath?.type?.indexOf("rar") > 0) {
        return <VIcon name={"rar.svg"} height={10} width={10} />;
      }
      if (filePath?.type?.indexOf("zip") > 0) {
        return <VIcon name={"zip.svg"} height={60} width={60} />;
      }
      if (filePath?.type?.indexOf("mp4") > 0) {
        return <VIcon name={"video.svg"} height={60} width={60} />;
      }

      return <VIcon name={"file.svg"} />;
    }
  }, [filePath]);

  return (
    <Card border="success" className="mb-4 question_item_card" id={id}>
      {label && (
        <p className="text-sm mb-2 font-semibold">
          <b>{label}</b> {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div style={{ display: "flex", gap: "10px" }}>
        <div
          onClick={() => handleClick()}
          style={{
            height: "100px",
            width: "100px",
            border: "1px",
            borderStyle: "dashed",
            position: "relative",
            borderRadius: "20px",
            cursor: "pointer",
          }}
        >
          {filePath?.path ? (
            filePath?.type?.includes("image") ? (
              <img
                style={{ height: "100%", width: "100%" }}
                src={getUrlImage(filePath?.path, 1000)}
                alt="sub"
              />
            ) : (
              <div
                style={{
                  position: "absolute",
                  top: "50%",
                  left: "50%",
                  transform: "translate(-50%,-50%)",
                }}
              >
                {renderIconFile}
              </div>
            )
          ) : (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <BiPlus />
            </div>
          )}
        </div>
        {filePath && <span>{filePath.name}</span>}
      </div>
      <input
        ref={inputRef}
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
      />
      {error && <p className="_error mb-0">{error}</p>}
    </Card>
  );
};

export default VQuestionMedia;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { createContext, useCallback, useEffect, useState } from "react";
import APIs from "api";
import { getDistance } from "geolib";
import useQuery from "hooks/useQuery";
import { getLatLngByAddressName } from "utils/getAddress";
import { useAppSelector } from "redux/reducers/hook";
import { getLocations } from "redux/reducers/settings/settings.selector";

export const DEFAULT_MAP_ZOOM = 11;
export const MARKER_VIEW_ZOOM = 16;

type MapsType = {
  coords?: { lat: number; lng: number };
  setCoords: (coords?: { lat: number; lng: number }) => void;
  myLocation?: { lat: number; lng: number };
  setMyLocation: (coords?: { lat: number; lng: number }) => void;
  destinationCoords?: { lat: number; lng: number };
  setDestinationCoords: (coords?: { lat: number; lng: number }) => void;
  geolocationPermission?: "granted" | "prompt" | "denied";
  getCurrentGeolocation: () => void;
  fetchingCoords: boolean;
  posts: any[];
  map?: google.maps.Map;
  setMap: (map?: google.maps.Map) => void;
  isLoading: boolean;
  objectUsers: any[];
  selectedPostId: string;
  setSelectedPostId: (postId: string) => void;
  objectUserSelected: { userId?: string; lat?: any; lng?: any };
  setObjectUserSelected: (config: {
    userId?: string;
    lat?: any;
    lng?: any;
  }) => void;
  mapSetting: {
    mapUsualIcon?: any;
    mapRadius?: number;
    mapUserLocationIcon?: any;
    mapAllowedObjects?: string[];
    mapAllowedCategories?: string[];
  };
  fetchMapMarkers: () => void;
};

const initialMaps: MapsType = {
  setCoords: () => {},
  setMyLocation: () => {},
  setDestinationCoords: () => {},
  getCurrentGeolocation: () => {},
  fetchingCoords: false,
  posts: [],
  setMap: () => {},
  isLoading: false,
  objectUsers: [],
  selectedPostId: "",
  setSelectedPostId: () => {},
  setObjectUserSelected: () => {},
  objectUserSelected: {},
  mapSetting: {},
  fetchMapMarkers: () => {},
};

export const MapContext = createContext<MapsType>(initialMaps);
const { Provider } = MapContext;

export const MapsProvider = ({
  children,
}: {
  children: React.ReactElement;
}) => {
  const locations = useAppSelector(getLocations);
  const [coords, setCoords] = useState<{ lat: number; lng: number }>();
  const [myLocation, setMyLocation] = useState<{ lat: number; lng: number }>();

  const [destinationCoords, setDestinationCoords] = useState<{
    lat: number;
    lng: number;
  }>();
  const [mapSetting] = useState<{
    mapUsualIcon?: any;
    mapRadius?: number;
    mapUserLocationIcon?: any;
    mapAllowedObjects?: string[];
    mapAllowedCategories?: string[];
  }>({});

  const params = useQuery();
  const { userId: queryUserId, postId: queryPostId } = params;

  const [geolocationPermission, setGeolocationPermission] = useState<
    "granted" | "prompt" | "denied"
  >();

  const [fetchingCoords, setFetchingCoords] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [map, setMap] = useState<google.maps.Map>();
  const [posts, setPosts] = useState<any[]>([]);
  const [objectUsers, setObjectUsers] = useState<any[]>([]);

  const [selectedPostId, setSelectedPostId] = useState<string>("");
  const [objectUserSelected, setObjectUserSelected] = useState<{
    userId?: string;
    lat?: any;
    lng?: any;
  }>({});

  const getCurrentGeolocation = useCallback((fetchingPost: boolean = true) => {
    fetchingPost && setFetchingCoords(true);
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        const userCoordinates = {
          lat: pos.coords.latitude,
          lng: pos.coords.longitude,
        };
        const latUserCoordinates =
          localStorage.getItem("last_shared_coordinates")?.split(",") || [];

        if (
          !latUserCoordinates.length ||
          getDistance(userCoordinates, {
            lat: +latUserCoordinates[0],
            lng: +latUserCoordinates[1],
          }) > 100 // user move larger 100m
        ) {
          setMyLocation(userCoordinates);
          localStorage.setItem(
            "last_shared_coordinates",
            `${pos.coords.latitude},${pos.coords.longitude}`
          );
        }

        fetchingPost && setFetchingCoords(false);
        setGeolocationPermission("granted");
      },
      () => {
        setGeolocationPermission("denied");
        fetchingPost && setFetchingCoords(false);
      }
    );
  }, []);

  const getObjectPostNearBy = useCallback(
    async (coords: { lat: number; lng: number }, queryObject: any) => {
      try {
        setIsLoading(true);
        const response: any = await APIs.SOSPOSTS.getSosPostsByCoordinates(
          coords,
          queryObject
        );
        setPosts(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    []
  );

  const getObjectUserNearBy = useCallback(
    async (coords: { lat: number; lng: number }, queryObject: any) => {
      try {
        setIsLoading(true);
        const response: any = await APIs.USER.getObjectsUsers(
          coords,
          queryObject
        );
        setObjectUsers(response);
        setIsLoading(false);
      } catch (error) {
        setIsLoading(false);
      }
    },
    []
  );

  const fetchDefaultLocation = useCallback(() => {
    if (navigator.permissions && navigator.permissions.query) {
      navigator.permissions.query({ name: "geolocation" }).then((result) => {
        if (result.state === "granted") {
          setGeolocationPermission("granted");
          if (localStorage.getItem("last_shared_coordinates")) {
            const lastUserCoordinates = localStorage
              .getItem("last_shared_coordinates")
              ?.split(",");
            setMyLocation({
              lat: Number(lastUserCoordinates?.[0]),
              lng: Number(lastUserCoordinates?.[1]),
            });
            getCurrentGeolocation(false);
          } else {
            getCurrentGeolocation();
          }
        }

        if (result.state === "prompt") {
          setGeolocationPermission("prompt");
          getCurrentGeolocation(false);
        }
        if (result.state === "denied") {
          setGeolocationPermission("denied");
        }
      });
    } else {
      setGeolocationPermission("prompt");
    }
  }, [getCurrentGeolocation]);

  useEffect(() => {
    fetchDefaultLocation();
  }, []);

  useEffect(() => {
    const newCoords = getLatLngByAddressName(params, locations) ?? myLocation;
    if (
      newCoords &&
      (coords?.lat !== newCoords?.lat || coords?.lng !== newCoords?.lng)
    )
      setCoords(newCoords);

    fetchMapMarkers(newCoords);
  }, [params, myLocation]);

  const fetchMapMarkers = useCallback(
    (coordinate = coords) => {
      getObjectUserNearBy(coordinate, params);
      getObjectPostNearBy(coordinate, params);
    },
    [params]
  );

  useEffect(() => {
    if (objectUsers.length) {
      const selectedProfile: any = objectUsers.find(
        (objectProfile) => objectProfile?.user?._id === queryUserId
      );
      const userData = selectedProfile
        ? {
            userId: selectedProfile?.user?._id,
            lat: +selectedProfile.coordinate.lat,
            lng: +selectedProfile.coordinate.lng,
          }
        : {};

      setObjectUserSelected(userData);
    }
  }, [objectUsers, queryUserId, setObjectUserSelected]);

  useEffect(() => {
    if (posts.length) {
      const selectedPostId: string =
        posts.find((post) => post._id === queryPostId)?._id ?? "";
      setSelectedPostId(selectedPostId);
    }
  }, [posts, queryPostId, setSelectedPostId]);

  return (
    <Provider
      value={{
        coords,
        setCoords,
        myLocation,
        setMyLocation,
        destinationCoords,
        setDestinationCoords,
        geolocationPermission,
        getCurrentGeolocation,
        fetchingCoords,
        posts,
        setMap,
        map,
        isLoading,
        objectUsers,
        selectedPostId,
        setSelectedPostId,
        objectUserSelected,
        setObjectUserSelected,
        mapSetting,
        fetchMapMarkers,
      }}
    >
      {children}
    </Provider>
  );
};

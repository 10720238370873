import Form from "react-bootstrap/Form";
import { useSelector } from "react-redux";
import * as SettingSelector from "redux/reducers/setting-system/selectors";

interface IProps {
  onChange?: any;
  label?: any;
  value?: any;
  errMessage?: string;
  disabled?: boolean;
  [key: string]: any;
}

const View = ({
  color = "",
  id = "",
  name = "",
  className,
  ref,
  label = "" || <></>,
  disabled = false,
  value = null,
  required = false,
  placeholder = "",
  defaultValue = null,
  hasError = false,
  prefix = null,
  suffix = null,
  suffixIcon = null,
  errMessage = "",
  type = "text",
  onChange = () => {},
  onBlur = () => {},
  ...props
}: IProps) => {
  const themeScheme = useSelector(SettingSelector.theme_scheme);
  return (
    <Form.Group className="v_input">
      <div className="material-textfield">
        <input
          disabled={disabled}
          placeholder={placeholder}
          type={type}
          onChange={onChange}
          className={`form-control mb-0 ${className}`}
          id={id}
          name={name}
          {...(value ? { ...{ value } } : {})}
          {...(defaultValue ? { ...{ defaultValue } } : {})}
          {...props}
        />
        <label className={`${themeScheme} d-flex align-items-center`}>
          {label} {required && <span className="required">*</span>}
        </label>
        {suffix && <div className="_suffix">{suffix}</div>}
      </div>
      {errMessage && <small className="_error">{errMessage}</small>}
    </Form.Group>
  );
};

export default View;

/* eslint-disable max-len */
/* eslint-disable indent */
import Header from "layouts/dashboard/HeaderStyle/header";
import VWebView from "components/v-web-view";
import withAuth from "HOCs/withAuth";
import useQuery from "hooks/useQuery";

const View = () => {
  const query = useQuery();
  const token = localStorage.getItem("auth");
  return (
    <>
      <Header />
      <VWebView
        className="iframe-webview-message"
        allow="camera *;microphone *; geolocation"
        url={`${process.env.REACT_APP_CHAT}/?userID=${query?.r}&token=${token}`}
      />
    </>
  );
};

export default withAuth(View);
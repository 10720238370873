/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

import { getDepositBanks as getDepositBanksSelector } from "redux/reducers/banks/banks.selector";

import useTranslation from "hooks/useTranslation";
import { toast } from "react-toastify";

import History from "../history-vnd";
import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import APIs from "api";
import Step0 from "./Step0";
import Card from "components/v-teamplate/Card";
import PaymentService from "api/payments";
import UploadService from "api/uploads";
import { getDepositBanksAsync } from "redux/reducers/banks/banks.actions";

const titles = {
  0: "Select currency",
  1: "Select amount",
  2: "Select deposit methods",
  3: "Confirm deposit information",
  4: "Send and upload proof",
};

type IProps = {
  url?: string;
  userId: string;
  reloadBalance: () => {};
};

const View = (props: IProps) => {
  const { userId } = props;

  const defaultData = {
    bank: null,
    amount: null,
    content: "",
    proof: "",
    currency: "",
    hash: null,
    network: "vzonex",
    stock: process.env.REACT_APP_STOCK_DEFAULT,
  };
  const { t } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);

  const [file, setFile] = useState();
  const [errUpload] = useState<boolean>(false);

  const [dataPayment, setDataPayment] = useState(defaultData);
  const depositBanks = useSelector(getDepositBanksSelector);
  const [hisDeposit, setHisDeposit] = useState<any>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getDepositBanksAsync());
    getPayment();
  }, []);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const backStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const canclePayment = () => {
    setActiveStep(0);
    setDataPayment(defaultData);
  };

  const setDataByKey = (key, value) => {
    setDataPayment({
      ...dataPayment,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let resImage;
      if (file) {
        const formData = new FormData();
        formData.append("folderPath", `payments`);
        formData.append("files", file);
        resImage = await UploadService.uploadFile({
          body: formData,
        });
      }
      await PaymentService.createPayment({
        body: {
          user_id: userId,
          type: "deposit",
          bank: dataPayment.bank,
          amount: dataPayment.amount,
          content: dataPayment.content,
          proof: resImage.filePath,
          currency: dataPayment.currency,
          hash: dataPayment.hash,
          network: dataPayment.network,
          stock: process.env.REACT_APP_STOCK_DEFAULT,
        },
      });
      setLoading(false);
      setActiveStep(0);
      getPayment();
      setDataPayment(defaultData);
      toast.success(t("Deposit successful!"));
    } catch (err) {
      // handle error
      setLoading(false);
      return toast.error(err.errors);
    }
    setLoading(false);
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: userId,
        type: "deposit",
        stock: process.env.REACT_APP_STOCK_DEFAULT,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Card isOpacity={true}>
        <div className="title-action-wallets">
          <p>{t(titles[activeStep])}</p>
          <div className="d-flex mt-2">
            <div className={`dot-step ${activeStep === 0 ? "active" : ""}`} />
            <div className={`dot-step ${activeStep === 1 ? "active" : ""}`} />
            <div className={`dot-step ${activeStep === 2 ? "active" : ""}`} />
            <div className={`dot-step ${activeStep === 3 ? "active" : ""}`} />
            <div className={`dot-step ${activeStep === 4 ? "active" : ""}`} />
          </div>
        </div>

        {activeStep === 0 && (
          <Step0
            dataPayment={dataPayment}
            nextStep={nextStep}
            setDataByKey={setDataByKey}
            setDataPayment={setDataPayment}
          />
        )}

        {activeStep === 1 && (
          <Step1
            dataPayment={dataPayment}
            nextStep={nextStep}
            setDataByKey={setDataByKey}
            backStep={backStep}
          />
        )}
        {activeStep === 2 && (
          <Step2
            dataPayment={dataPayment}
            nextStep={nextStep}
            setDataByKey={setDataByKey}
            depositBanks={depositBanks}
            backStep={backStep}
          />
        )}
        {activeStep === 3 && (
          <Step3
            nextStep={nextStep}
            dataPayment={dataPayment}
            setDataByKey={setDataByKey}
            backStep={backStep}
          />
        )}
        {activeStep === 4 && (
          <Step4
            canclePayment={canclePayment}
            setDataByKey={setDataByKey}
            dataPayment={dataPayment}
            handleSubmit={handleSubmit}
            loading={loading}
            backStep={backStep}
            file={file}
            setFile={setFile}
            errUpload={errUpload}
          />
        )}
      </Card>

      {hisDeposit.length ? (
        <History lists={hisDeposit} type="deposit" />
      ) : (
        <></>
      )}
    </>
  );
};

export default View;

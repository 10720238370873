import {
  FRIEND_ACTION,
  FRIEND_TYPE,
  GROUP_TYPE,
  OBJECT_TYPE,
} from "config/enums";

export const getMutualFriends = (owner, to) => {
  const _friends1 = owner?.friends || [];
  const _friends2 = to?.friends || [];
  return _friends1.filter((f1) => _friends2.includes(f1)).length;
};

export const getStatusFriend = (
  owner,
  to,
  fromUser,
  toUser,
  type = FRIEND_TYPE.FRIEND
) => {
  const from_id = fromUser._id || owner.user_id;
  const to_id = toUser._id || to?.user_id;
  const _friends1 = owner?.friends || [];

  if (type === FRIEND_TYPE.FOLLOW) {
    const _followings1 = owner?.followings || [];
    if (_followings1.includes(to_id)) return FRIEND_ACTION.UNFOLLOW;
    if (
      isUser(toUser?.object_type) ||
      isBusiness(toUser?.object_type) ||
      isPage(toUser?.object_type)
    )
      return FRIEND_ACTION.FOLLOW;
  } else {
    if (_friends1.includes(to_id)) return FRIEND_ACTION.UNFRIEND;

    const _request_friends1 = owner?.request_friends || [];
    if (_request_friends1.includes(to_id)) return FRIEND_ACTION.CANCEL_REQUEST;

    const _request_friends2 = to?.request_friends || [];
    if (_request_friends2.includes(from_id)) return FRIEND_ACTION.RESPONSE;

    if (isUser(toUser?.object_type)) return FRIEND_ACTION.REQUEST_FRIEND;
  }
};

export const isUser = (type: any) => {
  if (
    [
      OBJECT_TYPE.USER,
      OBJECT_TYPE.CHAT,
      OBJECT_TYPE.CEO,
      OBJECT_TYPE.UNDER_18,
      // OBJECT_TYPE.BUSINESS,
    ].includes(type)
  ) {
    return true;
  }
  return false;
};

export const isPage = (type: any) => {
  if ([OBJECT_TYPE.PAGE].includes(type)) {
    return true;
  }
  return false;
};

export const isBusiness = (type: any) => {
  if ([OBJECT_TYPE.BUSINESS, OBJECT_TYPE.ORGANIZATION].includes(type)) {
    return true;
  }
  return false;
};

export const isGroup = (type: any) => {
  if (String(type) === OBJECT_TYPE.GROUP) {
    return true;
  }
  return false;
};

export const checkVipGroup = (data: any) => {
  if (
    String(data?.object_type) === OBJECT_TYPE.GROUP &&
    String(data?.group_type) === GROUP_TYPE.VIP
  ) {
    return true;
  }
  return false;
};

export const isCommunity = (type: any) => {
  if (String(type) === OBJECT_TYPE.COMMUNITY) {
    return true;
  }
  return false;
};

export const isChannel = (type: any) => {
  if ([OBJECT_TYPE.CHANNEL].includes(type)) {
    return true;
  }
  return false;
};

/* eslint-disable react-hooks/exhaustive-deps */
import useQuery from "hooks/useQuery";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import VWebView from "components/v-web-view";
import ComingSoon from "pages/coming-soon/comingsoon";
import VTab from "components/v-tab";
import SuggestBusiness from "./suggest";
import ManageBussiness from "./manage";
import PendingBusiness from "./pending";
import JoinedBusiness from "./joined";
import ModalCreate from "pages/social/object-details/modal-create";
import { OBJECT_TYPE } from "config/enums";
import VBannerPage from "components/v-teamplate/v-banner-page";
import PageBackgrounds from "components/v-page-background";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";
import VInput from "components/v-input";
import VIcon from "components/v-icon";
import VRightView from "components/v-right-view";
import InvitedBusiness from "./invited";
import SearchService from "api/search";
import ModalInvite from "../object-details/modal-invite";
import VSearch from "components/v-search";
import VLoading from "components/v-loading";
import VSelectUser from "components/v-select-user";
import VList from "components/v-list-member";
import BusinessService from "api/businesses";
import { getUserProfile } from "redux/reducers/users/users.selector";
import debounce from "lodash.debounce";

const View = () => {
  const menus = useAppSelector(getMenusByKey("business_tab"));
  const dataUser = useAppSelector(getUserProfile);
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  const [tab, setTab] = useState(null);
  const query = useQuery();
  const [loadMenu, setLoadMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalInvite, setOpenModalInvite] = useState(false);
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [isShowListUsers, setIsShowListUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const listUsersRef = useRef(null);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [createdBusiness, setCreatedBusiness] = useState(null);
  const [isSearching, setIsSearching] = useState(false);

  useEffect(() => {
    setUsers((state) =>
      state.filter((user) => !selectedUsers.some((sU) => sU._id === user._id))
    );
  }, [isShowListUsers, isSearching]);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  const handleAddMember = async () => {
    try {
      if (selectedUsers.length > 0 && createdBusiness) {
        setLoadingConfirm(true);
        await BusinessService.addMembers({
          body: {
            memberIds: selectedUsers,
            businessId: createdBusiness._id,
          },
        });
        setCreatedBusiness(null);
        setOpenModalInvite(false);
        setUsers([]);
        setSelectedUsers([]);
        setLoadingConfirm(false);
      }
    } catch (error) {
      setLoadingConfirm(false);
    }
  };

  const handleSearchMembers = async (value: string) => {
    setIsSearching(true);
    const res: any = await SearchService.searchFriendInvite({
      query: {
        page: 1,
        limit: 20,
        search: value,
        object_type: OBJECT_TYPE.BUSINESS,
        object_id: dataUser._id,
      },
    });
    if (res && res.docs) {
      setIsShowListUser(true);
      setLoading(false);
      setIsSearching(false);
      return setUsers(res.docs);
    }
    return;
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleSearchMembers, 1000),
    []
  );

  const handleSelectMembers = (value: any) => {
    if (selectedUsers.some((item) => item._id === value._id)) {
      return setSelectedUsers(
        selectedUsers.filter((item) => item._id !== value._id)
      );
    }

    return setSelectedUsers((prev) => [...prev, value]);
  };

  const onClickOutSide = (e) => {
    const { target } = e;
    if (listUsersRef?.current === null) {
      setIsShowListUser(false);
      return;
    }
    if (listUsersRef?.current === undefined) {
      setIsShowListUser(false);
      return;
    }
    if (!listUsersRef?.current?.contains(target)) {
      setIsShowListUser(false);
      return;
    }
  };

  const handleDeleteSelectedUser = (value) => {
    if (value) {
      setSelectedUsers(selectedUsers.filter((item) => item !== value));
    }
  };

  const renderChildrenAddMember = () => (
    <div className="members-body-wrapper" onClick={onClickOutSide}>
      <div className="members-search-wrapper select-none">
        <VSearch
          handleChange={debouncedChangeHandler}
          className="add-members-search-input"
          handleKeyDown={() => {
            setLoading(true);
            setUsers([]);
          }}
          handleClick={() => setIsShowListUser(true)}
        />
        {isShowListUsers && (
          <div
            ref={listUsersRef}
            className="members-selection-users"
            onClick={(e) => e.stopPropagation()}
          >
            <VIcon
              name="close.svg"
              onClick={() => {
                setIsShowListUser(false);
              }}
            />
            {loading && <VLoading />}
            {users.map((item, index) => (
              <VSelectUser
                data={item}
                key={index}
                onChange={handleSelectMembers}
                className="px-4"
                selectedUsers={selectedUsers}
              />
            ))}
          </div>
        )}
      </div>
      <div className="members-list-selected">
        <VList data={selectedUsers} onDelete={handleDeleteSelectedUser} />
      </div>
    </div>
  );

  const renderTab = () => {
    if (tab && tab.type_view === "internal") {
      if (tab.key === "manage") return <ManageBussiness />;
      if (tab.key === "suggest") return <SuggestBusiness />;
      if (tab.key === "pending") return <PendingBusiness />;
      if (tab.key === "joined") return <JoinedBusiness />;
      if (tab.key === "invited") return <InvitedBusiness />;
    }
    if (tab && tab.type_view === "webview") {
      return (
        <VWebView
          url={tab.url}
          widthScrollbar={tab?.width_scrollbar}
          height="700px"
        />
      );
    }
    return <ComingSoon />;
  };

  return (
    <div className="profile-wrapper">
      <ModalCreate
        objectType={OBJECT_TYPE.BUSINESS}
        openModal={openModal}
        setOpenModal={setOpenModal}
        onSuccess={(res) => {
          setOpenModalInvite(true);
          setCreatedBusiness(res);
        }}
      />
      <ModalInvite
        isOpen={openModalInvite}
        children={renderChildrenAddMember()}
        onConfirm={handleAddMember}
        title={<span className="member-tilte">{t("Add member")}</span>}
        centered={true}
        loading={loadingConfirm}
        onCancel={() => {
          setOpenModalInvite(false);
          setUsers([]);
          setSelectedUsers([]);
          setIsShowListUser(false);
          setLoading(false);
        }}
      />
      <PageBackgrounds page={"page_bg_business"} />
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <VBannerPage position="banner_top_businesses" />
            <Card className="p-3">
              <Col sm={12}>
                <div className="mb-3 d-flex justify-content-between">
                  <Col sm={12} md={5}>
                    <VInput
                      transparent={true}
                      label={t("Business")}
                      suffix={<VIcon width={16} name="search.svg" />}
                    />
                  </Col>
                  <VButton onClick={() => setOpenModal(true)}>
                    {t("Create")}
                  </VButton>
                </div>
                <VTab
                  tab={tab}
                  setTab={setTab}
                  menus={menus}
                  activeBorder="bottom"
                  noBackground={true}
                />
                {renderTab()}
              </Col>
            </Card>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_business"}
                positionBanner={"banner_right_business"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default View;

/* eslint-disable indent */

import Countdown from "components/v-countdown";
import React, { useMemo, useState } from "react";
import { compareDateWithCur } from "utils/times";
import { getFullNameByNumber, getUrlImage } from "utils/common";
import VTooltip from "components/v-tooltip";
import VIcon from "components/v-icon";
import VReward from "components/v-reward";
import useTranslation from "hooks/useTranslation";
import { renderDate } from "utils/times";
import VButton from "components/v-button";
import VModal from "components/v-modal";
import TaskDetail from "./detail";

const TaskItem = ({ task }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const data = useMemo(() => {
    let title = "";
    let time = 0;
    const { frame } = task.time;
    if (task.time.start_date && compareDateWithCur(task.time.start_date) > 0) {
      title = "Upcoming";
      time = new Date().getTime() + compareDateWithCur(task.time.start_date);
    } else {
      title = "Happening";
      time = new Date().getTime() + compareDateWithCur(task.time.end_date);
    }
    if (frame && frame.length > 0) {
      const hourCur = new Date().getHours();
      let distance = null;
      title = "Upcoming";

      for (let index = 0; index < frame.length; index++) {
        const element = frame[index];
        if (hourCur === element) {
          distance = 1;
          title = "Happening";
          break;
        }
        if (hourCur < element) {
          distance = element - hourCur;
          break;
        }
      }
      if (!distance) distance = frame[0] + 24 - hourCur;
      time =
        new Date().getTime() +
        distance * 3600000 -
        new Date().getMinutes() * 60000;
    }
    return {
      time,
      title,
    };
  }, [task]);

  const renderAffilate = (
    <div>
      {Object.keys(task.affiliates || {}).map((k) => {
        return (
          <div>
            Level {String(k).toUpperCase()} : <br />
            <VReward reward={task.affiliates[k]} />
          </div>
        );
      })}
    </div>
  );

  const renderAction = (
    <span className="text-warning line-height d-flex">
      {task.affiliates && (
        <VTooltip key={`${task._id}_affilate`} content={renderAffilate}>
          <VIcon
            name="common/affilate.svg"
            width={20}
            height={20}
            className="mx-1"
          />
        </VTooltip>
      )}

      {task.reward &&
        (task.reward.codes.length > 0 ||
          task.reward.stocks.length > 0 ||
          task.reward.tickets.length > 0 ||
          task.reward.vouchers.length > 0) && (
          <VTooltip
            key={`${task._id}_gift`}
            content={<VReward reward={task.reward} />}
          >
            <VIcon
              name="common/gift.svg"
              className="mx-1"
              width={20}
              height={20}
            />
          </VTooltip>
        )}
    </span>
  );

  return (
    <div className="item">
      <div
        className="top"
        style={{
          backgroundImage: `url(${getUrlImage(task.banner)})`,
        }}
      >
        <div className="d-flex justify-content-between p-2">
          <div className={`status ${data.title} `}>{t(data.title)}</div>
          <div className="d-none d-sm-block">{renderAction}</div>
        </div>
      </div>
      <div className="bottom">
        <span className="name">{getFullNameByNumber(task.name, 20)}</span>
        <div className="task-time d-flex">
          <div className="time">
            <div className="from">
              {t("From")}: {renderDate(task.time?.start_date)}
            </div>
            <div className="to">
              {t("To")}: {renderDate(task.time?.end_date)}
            </div>
          </div>
          <div className="b-countdown">
            <Countdown date={data.time} />
          </div>
        </div>
        <div className="row my-2 w-50">
          <VButton
            className={"v-button-animation "}
            onClick={(_) => setOpenModal(true)}
          >
            {t("View detail")}
          </VButton>
        </div>
      </div>
      <VModal
        title={t("Task detail")}
        isOpen={openModal}
        centered={true}
        children={<TaskDetail id={task._id} />}
        onCancel={() => setOpenModal(false)}
      />
    </div>
  );
};

export default TaskItem;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import VChannel from "components/v-channel";
import PostService from "api/posts";
import { useParams, useHistory } from "react-router-dom";
import UserService from "api/users";
import { ROUTES } from "config/routes";
import { OBJECT_TYPE } from "config/enums";

const View = (props) => {
  const { isAuth } = props;

  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const dataUser = useAppSelector(getUserProfile);
  const [postVideo, setPostVideo] = useState([]);
  const [dataUserChannel, setDataUserChannel] = useState(dataUser);

  const [pageCurrent, setPageCurrent] = useState(1);

  const getFiles = async (id) => {
    try {
      const post: any = await PostService.getPostByUser({
        user_id: id || dataUser._id,
        query: {
          post_type: "video",
          limit: 10,
          page: pageCurrent,
        },
      });
      if (post) {
        const postExistFile = post?.filter(
          (el) =>
            el?.post_files?.length > 0 && el?.post_plugins?.includes("video")
        );
        setPostVideo([...postVideo, ...postExistFile]);
        setPageCurrent(pageCurrent + 1);
      }
    } catch (error) {}
  };

  const getUserById = async () => {
    try {
      const res: any = await UserService.getProfileById({
        params: {
          id: id,
        },
      });
      if (res?.user) {
        getFiles(res.user._id);
        setDataUserChannel(res?.user);
        if (res.user.object_type === OBJECT_TYPE.CHANNEL) {
          history.push(getUserProfile(res.user));
        } else {
          if (res.user.special_name) {
            history.push(
              ROUTES.PROFILE_VIDEOS.replace(":id", res.user.special_name)
            );
          }
        }
      }
    } catch (error) {}
  };

  window.onscroll = function () {
    if (
      Number(window.innerHeight + window.pageYOffset) >=
      Number(document.body.offsetHeight - 10)
    ) {
      getUserById();
    }
  };

  useEffect(() => {
    getUserById();
  }, [id]);

  return (
    <>
      <Container>
        <Row>
          <Col lg={12} className={"px-1"}>
            <VChannel
              type="video"
              data={postVideo}
              dataUser={dataUserChannel}
              isAuth={isAuth}
            />
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import { Container } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";
import { GROUP_ACTION } from "config/enums";
import { getGroupSuggestsAsync } from "redux/reducers/groups/groups.action";
import { removeGroupSuggest } from "redux/reducers/groups/groups.reducer";
import { getSuggestGroups } from "redux/reducers/groups/groups.selector";
import VGroupCard from "components/v-objects/v-group-card";
import VInfiniteLoad from "components/v-infinite-load";

const View = () => {
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const groups: any = useAppSelector(getSuggestGroups);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = groups;

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getGroupSuggestsAsync({
        query: {
          page,
          limit,
        },
      })
    );
    setLoading(false);
  };

  const handleViewMore = () => {
    getData({
      page: page + 1,
    });
  };

  const reload = ({ to_id }) => {
    dispatch(removeGroupSuggest(to_id));
  };

  return (
    <>
      <div>
        <Container>
          <VInfiniteLoad
            onLoadMore={handleViewMore}
            canLoadMore={page < groups.totalPages}
            isLoadingMore={loading}
          >
            <div className="row">
              {groups &&
                (groups.docs || []).map((group, index) => (
                  <VGroupCard
                    key={index}
                    col_lg={3}
                    col_md={4}
                    group={group}
                    count={group?.count?.count?.group}
                    type={GROUP_ACTION.REQUEST_JOIN}
                    reload={reload}
                  />
                ))}
            </div>
          </VInfiniteLoad>
        </Container>
      </div>
    </>
  );
};

export default View;

import { createAsyncThunk } from "@reduxjs/toolkit";
import ChannelService from "api/channels";
import { IRequestData } from "api/request";

export const getChannelSuggestsAsync = createAsyncThunk(
  "channels/suggest",
  async (req: IRequestData) => {
    try {
      const response: any = await ChannelService.getSuggests(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getChannelFollowingsAsync = createAsyncThunk(
  "channels/followings",
  async (req: IRequestData) => {
    try {
      const response: any = await ChannelService.getFollowings(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getChannelManageAsync = createAsyncThunk(
  "channels/manage",
  async (req: IRequestData) => {
    try {
      const response: any = await ChannelService.getManage(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getFollowersAsync = createAsyncThunk(
  "channels/followers",
  async (req: IRequestData) => {
    try {
      const response: any = await ChannelService.getFollowers(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

/* eslint-disable react-hooks/exhaustive-deps */
import MapWrapper from "components/smart-maps/MapWrapper";
import { MapsProvider } from "./MapsContext";
import { useEffect } from "react";
import { MapContextProvider } from "components/smart-maps/context";
import { getSosCategoriesAsync } from "redux/reducers/categories/categories.actions";
import { useAppDispatch } from "redux/reducers/hook";

export default function Maps() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    document
      .getElementById("content-page")
      ?.classList.add("max-width-content-page");

    dispatch(getSosCategoriesAsync());

    return () => {
      document
        .getElementById("content-page")
        ?.classList.remove("max-width-content-page");
    };
  }, []);

  return (
    <MapsProvider>
      <MapContextProvider>
        <MapWrapper />
      </MapContextProvider>
    </MapsProvider>
  );
}

import React, { useState, useEffect } from "react";
import { ReactSortable } from "react-sortablejs";

interface ItemType {
  chosen: boolean;
  id: number;
  oldId: string;
  item: any;
}
const BasicFunction = ({ arrayDefault, listSort, handleEndSort }) => {
  const [state, setState] = useState<ItemType[]>([]);

  const endSort = (data) => {
    if (data?.length > 0) {
      setState(data);
      let newState = [];
      let isChangeIdx = false
      for (let index = 0; index < data?.length; index++) {
        const element = data[index];
        const value = arrayDefault?.find(
          (item) => item.id === element.oldId
        );
        const a = arrayDefault?.findIndex(
          (item) => item.id === element.oldId
        );
        if(a !== index){
          isChangeIdx = true
        }
        newState.push(value);
      }
      if(isChangeIdx){
        handleEndSort(newState);
      }
    }
  };

  useEffect(() => {
    const _state = listSort?.map((el, index) => ({
      ...el,
      id: index,
      oldId: el?.oldId,
      item: el?.item,
    }));

    setState(_state);
  }, [listSort]);

  return (
    <ReactSortable 
      delayOnTouchOnly={true}
      delay={200}
      list={state}
      multiDrag
      setList={(e) => endSort(e)}
    >
      {Array.isArray(state) &&
        state.map((el) => <div key={el.id}>{el.item}</div>)}
    </ReactSortable>
  );
};

export default BasicFunction;

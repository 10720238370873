/* eslint-disable indent */
/* eslint-disable max-len */

import { convertS3Link } from "api/s3-request";
import { OBJECT_TYPE } from "config/enums";
import { ROUTES } from "config/routes";

/* eslint-disable no-useless-escape */
function isAmount(data) {
  const re = /^[0-9]*\.?[0-9]*$/;
  let istrue = false;

  if (data === "" || re.test(data)) {
    istrue = true;
    return istrue;
  }
  return istrue;
}

function valueIsNumber(data) {
  const re = /^[0-9]*$/;
  let istrue = false;

  if (data === "" || re.test(data)) {
    istrue = true;
    return istrue;
  }
  return istrue;
}

function skipSpace(data) {
  const value = data.replace(/\s/g, "");
  return value;
}

function isValueText(data) {
  const re = /^[a-zA-Z ]*$/;
  let istrue = false;
  if (data === "" || re.test(data)) {
    istrue = true;
    return istrue;
  }
  return istrue;
}

function validateEmail(email) {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

const getUrlImage = (img, rezise?: number) => {
  if (!img) return "/assets/images/VDB-64.png";

  const typeFile = img.split(".")[img.split(".").length - 1];
  const checkFileImage = () => {
    const permission_type = [
      "jpg",
      "jpeg",
      "gif",
      "png",
      "tiff",
      "bmp",
      "svg",
      "psd",
      "ai",
      "webp",
    ];
    if (permission_type.includes(typeFile.toLowerCase())) {
      return true;
    }
    return false;
  };

  let newImg = img.replace("uploads/", "/");

  if (checkFileImage() && rezise) {
    newImg = newImg.replace(`.${typeFile}`, `--${rezise}.${typeFile}`);
  }

  if (
    String(newImg).includes("http://") ||
    String(newImg).includes("https://")
  ) {
    return newImg;
  }
  return `${
    process.env.REACT_APP_FILE || "https://file.vdiarybook.com"
  }/api/files/${newImg}`;
};

const type = {
  id_card: "Identity Card",
  passport: "Passport",
  driving_license: "Driving License",
};

const getType = (typeId) => type[typeId];

const formatNumber = (number, minimumFractionDigits = 2) => {
  return new Intl.NumberFormat([], {
    minimumFractionDigits: minimumFractionDigits,
  }).format(number);
};

const formatCurrency = (number) => {
  return new Intl.NumberFormat([], {
    minimumFractionDigits: 0,
  }).format(number);
};

const formatCurrencyByType = (
  number,
  type = "en-US",
  currency = "USD",
  minimumFractionDigits = 2
) => {
  return new Intl.NumberFormat(type || [], {
    minimumFractionDigits,
    style: "currency",
    currency,
  }).format(number);
};

function randString(isToken = false, len = 10) {
  var text = "";
  var possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  var n = len;
  if (isToken) {
    n = 40;
    possible = "abcdefghijklmnopqrstuvwxyz0123456789-";
  }
  for (var i = 0; i < n; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

function randCodeDeposit(isToken = false, len = 10) {
  var text = "";
  var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  var n = len;
  if (isToken) {
    n = 40;
    possible = "abcdefghijklmnopqrstuvwxyz0123456789-";
  }
  for (var i = 0; i < n; i++)
    text += possible.charAt(Math.floor(Math.random() * possible.length));

  return text;
}

function copyText(text: string) {
  return navigator.clipboard.writeText(text);
}

const getBase64Image = async (url) => convertS3Link(url);

function uppercasefirst(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const getLinkYoutubeIframe = (idVideo: string, isPreview?: boolean) => {
  return `https://www.youtube.com/embed/${idVideo}?autoplay=1&mute=${
    isPreview ? "0" : "1"
  }&loop=1&playlist=${idVideo}&rel=0&modestbranding=1&autohide=1&showinfo=0&controls=${
    isPreview ? "1" : "0"
  }&disablekb=1&egm=1&fs=0&version=3&wmode=opaque&enablejsapi=1`;
};

const convertUrl = (url: string, userAuth?: any) => {
  let _url = url;
  const { _id } = userAuth || {};
  if (url.includes("{token}")) {
    _url = _url.replace("{token}", localStorage.getItem("auth"));
  }
  if (url.includes("{userId}")) {
    _url = _url.replace("{userId}", `${_id}`);
  }
  return _url;
};

const formatDate = (date) => {
  return new Date(date).toLocaleString();
};

const getFullNameByNumber = (fullName = "", number = 12) => {
  if (fullName.length < number) {
    return fullName;
  } else {
    return `${fullName.slice(0, number)}...`;
  }
};

const getUrlProfile = (user) => {
  if (user) {
    const role = user.object_type;

    switch (role) {
      case OBJECT_TYPE.BUSINESS:
        return ROUTES.PROFILE_BUSINESS.replace(
          ":id",
          user.special_name || user._id
        );
      case OBJECT_TYPE.ORGANIZATION:
        return ROUTES.PROFILE_BUSINESS.replace(
          ":id",
          user.special_name || user._id
        );
      case OBJECT_TYPE.GROUP:
        return ROUTES.PROFILE_GROUP.replace(
          ":id",
          user.special_name || user._id
        );
      case OBJECT_TYPE.PAGE:
        return ROUTES.PROFILE_PAGE.replace(
          ":id",
          user.special_name || user._id
        );
      case OBJECT_TYPE.CHANNEL:
        return ROUTES.PROFILE_CHANNEL.replace(
          ":id",
          user.special_name || user._id
        );
      default:
        return ROUTES.PROFILE.replace(":id", user.special_name || user._id);
    }
  }
};

const checkIsAuth = (profile, idCheck: string) => {
  if (
    String(profile?._id) === String(idCheck) ||
    (profile?.special_name &&
      String(profile?.special_name) === String(idCheck)) ||
    (profile?.owner_id && String(profile?.owner_id) === String(idCheck))
  ) {
    return true;
  }
  return false;
};

const checkIsMyProfile = (profile, idCheck: string) => {
  if (
    String(profile?._id) === String(idCheck) ||
    (profile?.special_name && String(profile?.special_name) === String(idCheck))
  ) {
    return true;
  }
  return false;
};

const checkIsPreviewPublic = () => {
  // check preview profile page
  if (window.location.pathname.includes("preview")) return true;

  const tokenAuth = localStorage.getItem("auth");
  if (
    !tokenAuth &&
    // window.location.pathname !== "/" &&
    window.location.pathname !== ROUTES.REGISTER
  ) {
    return true;
  }
  return false;
};

const getSymbolStock = (stock) => {
  return String(stock).toUpperCase();
};

const isValidURL = (text: string) => {
  // eslint-disable-next-line no-useless-escape
  if (!text || typeof text !== "string") {
    return false;
  }

  const res = text.match(
    // eslint-disable-next-line no-useless-escape
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
  );
  return res !== null;
};

const getNewParamsByKey = (params = {}, keys = []) => {
  const data = { ...params };
  if (!keys.length) return data;

  keys.forEach((k) => {
    const [action, key, value] = k.split("_");
    switch (action) {
      case "add":
        data[key] = value;
        break;
      case "delete":
        data[key] && delete data[key];
        break;
      default:
        break;
    }
  });

  return data;
};

const getQueryString = (data = {}, keys = []) => {
  if ((!data || !Object.keys(data).length) && !keys.length) return;

  const params = getNewParamsByKey(data, keys);
  const pathName = window.location.pathname;
  if (!params || !Object.keys(params)?.length) return pathName;

  Object.keys(params)?.forEach((key) => {
    if (!params[key] || params[key] === "none") delete params[key];
  });

  const query = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&")
    .replaceAll("null", "");
  return `${pathName}?${query}`;
};

const checkIsQueryAddress = (params) => {
  if (!params) return false;

  const paramKeys = Object.keys(params);
  const addressKeys = ["country", "province", "district", "ward"];
  const intersection = addressKeys.filter((x) => paramKeys.includes(x));

  return intersection.length > 0;
};

const removeVietnameseTones = (str) => {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(
    /!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g,
    " "
  );
  return str;
};

const checkSizeFileUpload = (file: any) => {
  const number = file.size / 1048576;
  return number;
};

const checkSrcIsVideo = (src: any) => {
  const type = src?.split(".")[src.split(".").length - 1];
  switch (type?.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mov":
    case "quicktime":
      // etc
      return true;
  }
  return false;
};

const checkFileIsVideo = (filename: any) => {
  const type = filename?.type?.split("/")[1];
  switch (type?.toLowerCase()) {
    case "m4v":
    case "avi":
    case "mpg":
    case "mp4":
    case "mov":
    case "quicktime":
      // etc
      return true;
  }
  return false;
};

const enableChat = () => {
  return true;
  // return window.origin.includes('.net') || window.origin.includes('localhost');
};

const isVnOrigin = () => {
  return window.origin.includes(".vn");
};

const isLiveSite = () => {
  return window.origin.includes(".com") || window.origin.includes(".vn");
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

export {
  enableChat,
  isVnOrigin,
  isLiveSite,
  isValidURL,
  formatDate,
  isAmount,
  valueIsNumber,
  skipSpace,
  isValueText,
  validateEmail,
  getUrlImage,
  getType,
  formatNumber,
  formatCurrency,
  randString,
  randCodeDeposit,
  copyText,
  getBase64Image,
  formatCurrencyByType,
  uppercasefirst,
  getLinkYoutubeIframe,
  convertUrl,
  getUrlProfile,
  getFullNameByNumber,
  checkIsMyProfile,
  checkIsAuth,
  checkIsPreviewPublic,
  getSymbolStock,
  getQueryString,
  checkIsQueryAddress,
  removeVietnameseTones,
  checkSizeFileUpload,
  checkSrcIsVideo,
  checkFileIsVideo,
  sleep,
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "components/v-teamplate/Card";

import VBannerPage from "components/v-teamplate/v-banner-page";
import useTranslation from "hooks/useTranslation";
import Users from "./Users";
import VDropdown from "components/v-dropdown";
import VInput from "components/v-input";
import VIcon from "components/v-icon";
import debounce from "lodash.debounce";
import VRightView from "components/v-right-view";
import VTab from "components/v-tab";
import useQuery from "hooks/useQuery";
import ComingSoon from "pages/coming-soon/comingsoon";
import UserOnline from "./Onlines";
import Post from "./Post";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const menus = [
  {
    key: "user",
    name: "User",
  },
  {
    key: "online",
    name: "Online",
  },
  {
    key: "post",
    name: "Post",
  },
];
const View = () => {
  const { t } = useTranslation();
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null

  const [sortBy, setSortBy] = useState("");
  const [search, setSearch] = useState("");
  const [tab, setTab] = useState(null);
  const query = useQuery();

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 1000), []);

  useEffect(() => {
    if (query.tab) {
      if (menus && menus.length > 0) {
        const menu = menus.find((m) => m.key === query.tab);
        if (menu) {
          setTab(menu);
        } else setTab(menus[0]);
      }
    } else {
      setTab(menus[0]);
    }
  }, [query.tab]);

  const renderTab = () => {
    if (tab) {
      if (tab.key === "user") return <Users sortBy={sortBy} search={search} />;
      if (tab.key === "online") return <UserOnline />;
      if (tab.key === "post") return <Post />;
    }
    return <ComingSoon />;
  };

  return (
    <Container>
      <Row className="ranking-pages">
        <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
          <VBannerPage position="banner_top_rankings" />
          <Card className="p-3">
            <Col sm={12}>
              <div className="mb-3 d-flex justify-content-between">
                <div className="col-6 col-md-3 pe-2">
                  <div className="form-group">
                    <VInput
                      label={t("Search by code")}
                      type="text"
                      id="code_vdb"
                      name="code_vdb"
                      onChange={debouncedChangeHandler}
                      suffix={<VIcon width={16} name="search.svg" />}
                    />
                  </div>
                </div>
                <div className="col-6 col-md-3">
                  <VDropdown
                    placeholder={t("SortBy")}
                    value={sortBy}
                    options={[
                      { label: t("Refer"), value: "refers" },
                      { label: t("Friend"), value: "friend" },
                      { label: t("Follow"), value: "follower" },
                    ]}
                    onChange={(e: any) => setSortBy(e.target.value)}
                  />
                </div>
              </div>
              <VTab
                tab={tab}
                setTab={setTab}
                menus={menus}
                activeBorder="bottom"
                noBackground={true}
              />
              {renderTab()}
            </Col>
          </Card>
        </Col>
        {show_banner_right_on_container && (
          <Col lg={4} className={"d-none d-lg-block px-1"}>
            <VRightView
              menuKey={"tab_right_rankings"}
              positionBanner={"banner_right_rankings"}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default View;

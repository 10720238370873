import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const getSuggests = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/friends/suggests/${req.params.type}?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRequest = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/friends/request?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getFriends = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/friends/${req.params.user_id}?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRelationWithToUser = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/friends/getRelationWithToUser/${req.params.to_id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/friends/create`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/friends/update-status`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateNicknameFriend = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/friends/update-nickname`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getFriendMutual = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/friends/friend-mutual`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const FriendService = {
  create,
  update,
  getRequest,
  getFriends,
  getRelationWithToUser,
  getSuggests,
  updateNicknameFriend,
  getFriendMutual
};

export default FriendService;

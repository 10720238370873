/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useMemo } from "react";

import { useSelector } from "react-redux";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";
import { useDispatch } from "react-redux";

import { v4 as uuidv4 } from "uuid";
import WebModulesIframe from "components/iframe-web-module";
import {
  WebModulesIframeStyled,
  WebModulesIframeMiniChatStyled,
} from "components/iframe-web-module/styles";
import qs from "qs";
import { useTranslation } from "react-i18next";
import { WEB_MODULE_URL, WEB_CHAT_URL } from "config/env";
import VIcon from "components/v-icon";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import { useHistory } from "react-router-dom";

type IProps = {
  openWebModuleKey?: string;
  postDetail?: {
    _id: string;
  };
  extraQuery?: any;
  postShare?: any;
  page?: string;
  userProfileId?: string;
  conversationId?: string;
};

export default function View(props: IProps) {
  const {
    openWebModuleKey: openWebModuleKeyRedux,
    postDetail: postDetailRedux,
    postCreateUserId: postCreateUserIdRedux,
    postShare: postShareIdRedux,
    page: postPageRedux,
    plugin: postPluginRedux,
    postType: postTypeRedux,
    userIdMiniChat,
    openShopKey,
    payProductDetail,
    typeShare,
    contentShare,
    category,
    captchaId,
    tab,
    redirectUrl,
    idLive,
  } = useSelector(getUISettingState);

  const openWebModuleKey = props.openWebModuleKey || openWebModuleKeyRedux;
  const postDetail = props.postDetail || postDetailRedux;
  const postShare = props.postShare || postShareIdRedux;
  const page = props.page || postPageRedux;
  const plugin = postPluginRedux;
  const postType = postTypeRedux;

  // const conversationId = props.conversationId || userIdMiniChat;

  const { i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const dispatch = useDispatch();
  const history = useHistory();

  const isShowCloseBtn = true;

  const getIframeUrl = (key: string) => {
    const isChat = [
      "conversations",
      "conversation_plugins",
      "conversation_mini",
      "mini_chat_detail",
      "live",
    ].includes(key);

    const originDomain = isChat ? WEB_CHAT_URL : WEB_MODULE_URL;

    const pathKey = {
      conversations: `/conversations/${
        props?.userProfileId ? props?.userProfileId : ""
      }`,
      conversation_plugins: `/conversations/${props?.userProfileId}`,
      conversation_mini: `/conversations/mini-chat`,
      mini_chat_detail: `/conversations/mini-chat/${userIdMiniChat}`,
      post_create: "/posts/create",
      post_detail: `/posts/${postDetail?._id}`,
      news_feed: "/",
      news_post_ads: "/advertisement",
      post_share: "/posts/post-share",
      events: "/events",
      "home-v2": "/new-home",
      "home-feeds": "/home-feeds",
      "profile-feed": `/profile/${props?.userProfileId}`,
      "open-buy-product": `/products/buy/${postDetail?._id}`,
      "open-donate-modal": "/donate",
      "open-advanced-filter": "/shop",
      "open-pay-product": `/pay/${payProductDetail?.user_id}`,
      "open-create-product-quick": `/create-product-quick`,
      share_drive: `/posts/share_drive`,
      "open-captcha": `/captcha`,
      live: `/live/${idLive}`,
    };

    // LOUIS PRODUCT
    if (
      [
        "open-buy-product",
        "open-donate-modal",
        "open-advanced-filter",
        "open-pay-product",
        "open-create-product-quick",
        "open-captcha",
      ].includes(key)
    ) {
      return `${originDomain}${pathKey[key] || "/"}?origin=${
        window.origin
      }&vdb_token=${localStorage.getItem(
        "auth"
      )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&lang=${
        i18n.language ? `${i18n.language}` : ""
      }&page=${page}&plugin=${plugin}&postType=${postType}&type_modal=${
        postDetail?.type_modal
      }&${
        postDetail?.permute &&
        Object.keys(postDetail?.permute).reduce((prev, next) => {
          return prev + next + "=" + (postDetail.permute[next] || "") + "&";
        }, "")
      }&back=${window.localStorage.getItem("prev")}&isBuySuccess=${
        openShopKey === "pay-product-verify" || ""
      }&permute_index=${postDetail?.permute_index}&post_id=${postDetail?._id}${
        key === "open-advanced-filter" ? "&in_advanced=yes" : ""
      }&${payProductDetail && qs.stringify(payProductDetail)}&category=${
        category?.trim() || ""
      }&tab=${tab?.trim() || ""}&captchaId=${captchaId}`;
    }

    if (pathKey[key] && moduleSessionId && fromSessionUid) {
      if (key === "live") {
        return `${originDomain}${
          pathKey[key] || "/"
        }?is_create=${true}&vdb_token=${localStorage.getItem("auth")}`;
      }
      return `${originDomain}${pathKey[key] || "/"}?origin=${
        window.origin
      }&vdb_token=${localStorage.getItem(
        "auth"
      )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&postShareId=${
        postShare._id || ""
      }${props.extraQuery ? "&" + qs.stringify(props.extraQuery || {}) : ""}${
        postCreateUserIdRedux ? `&userId=${postCreateUserIdRedux}` : ""
      }&lang=${i18n.language ? `${i18n.language}` : ""}&page=${
        page || ""
      }&plugin=${plugin || ""}&postType=${postType || ""}
      ${typeShare ? `&postContentShare=${contentShare || ""}` : ""}${
        typeShare ? `&typeShare=${typeShare}` : ""
      }`;
    }

    return null;
  };

  const onLoadIframeOk = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
  }, [openWebModuleKey]);

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  useEffect(() => {
    if (!redirectUrl) return;
    history.push(redirectUrl);
  }, [redirectUrl]);

  const isMiniChat = openWebModuleKey === "mini_chat_detail";

  const ifURL = useMemo(() => {
    return getIframeUrl(openWebModuleKey);
  }, [page, openWebModuleKey, moduleSessionId, fromSessionUid, userIdMiniChat]);

  const onCloseIframe = () => {
    dispatch(
      setOpenWebModulesPopupAction({
        openWebModuleKey: null,
      })
    );
  };

  const handleMessage = (event: MessageEvent) => {
    if (
      event.origin === process.env.REACT_APP_WEB_CHAT_URL ||
      event.origin === process.env.REACT_APP_WEB_MODULE_URL
    ) {
      if (event.data?.action === "close") {
        onCloseIframe();
      } else if (event.data?.action === "open") {
        dispatch(
          setOpenWebModulesPopupAction({
            openWebModuleKey: event.data?.payload.type,
            payload: event.data?.payload,
          })
        );
      }
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessage);
    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [openWebModuleKey]);

  return (
    <>
      {page === "events" || page === "conversation" ? (
        <WebModulesIframe
          url={ifURL}
          className="iframe-webview-home"
          title="web modules"
          onLoad={() => onLoadIframeOk()}
          loading={loading}
        />
      ) : // Master modules popup
      isMiniChat ? (
        <WebModulesIframeMiniChatStyled>
          <WebModulesIframe
            url={ifURL}
            className="iframe"
            title="web modules modal"
            onLoad={() => onLoadIframeOk()}
            loading={loading}
          />
          {isShowCloseBtn && (
            <button className="btn-close-iframe" onClick={onCloseIframe}>
              <VIcon className="" width={16} height={16} name="close.svg" />
            </button>
          )}
        </WebModulesIframeMiniChatStyled>
      ) : (
        <WebModulesIframeStyled
          className="web-modules-modal"
          hidden={!Boolean(openWebModuleKey)}
        >
          <WebModulesIframe
            url={ifURL}
            className="iframe"
            title="web modules modal"
            onLoad={() => onLoadIframeOk()}
            loading={loading}
          />
        </WebModulesIframeStyled>
      )}
    </>
  );
}

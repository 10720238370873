/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
// import { Col, Row } from "react-bootstrap";

import { useAppSelector } from "redux/reducers/hook";
import {
  getUIPGaleryState,
  getAudioSrc,
  getMusicMetadata,
  getUsernamePost,
} from "redux/reducers/ui-settings/ui-settings.selector";
import { setPostGaleryImages } from "redux/reducers/ui-settings/ui-settings.reducer";
import { useDispatch } from "react-redux";

import HTMLFlipBook from "react-pageflip";
import VIcon from "components/v-icon";
import { getUrlImage } from "utils/common";
import { isMobile } from "react-device-detect";
import FlipBookImageContext from "context/FlipBookImageContext/View";
import { VscUnmute } from "react-icons/vsc";
import { BiVolumeMute } from "react-icons/bi";
import { BsFillPauseFill, BsPlayFill } from "react-icons/bs";
import { FaMusic } from "react-icons/fa";
import useTranslation from "hooks/useTranslation";

const Page = React.forwardRef((props: any, ref: any) => {
  const downloadImage = (imageUrl, fileName) => {
    fetch(imageUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.style.display = "none";
        document.body.appendChild(link);

        link.href = url;
        link.setAttribute("download", fileName);
        link.click();
        document.body.removeChild(link);
        URL.revokeObjectURL(url);
      })
      .catch((error) => {});
  };

  return (
    <div className="page" ref={ref}>
      {/* <div
        style={{
          backgroundImage: `url(${getUrlImage(
            props?.data?.post_file_path,
            700
          )})`,
          height: "100%",
          backgroundSize: `${props?.data?.post_file_path ? "100%" : "auto"}`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
        }}
      ></div>
      <div
        style={{
          backgroundImage: `url(${getUrlImage(
            props?.data?.post_file_path,
            150
          )})`,
          height: "100%",
          backgroundSize: `${props?.data?.post_file_path ? "100% 100%" : "auto"}`,
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          position: "absolute",
          top: 0,
          left: 0
        }}
      ></div> */}

      <div className="flipbook_page" id="flipbook_page_id">
        <img
          className="flipbook_image_page"
          src={`${getUrlImage(props?.data?.post_file_path, 700)}`}
          onLoad={props.onLoadFlipbookImag}
          style={
            props.minHeight !== "full"
              ? {
                  height: props.minHeight,
                  width: "100%",
                }
              : {
                  height: "100%",
                  width: "auto",
                }
          }
        />
        <img
          className="flipbook_image_page--thump"
          src={`${getUrlImage(props?.data?.post_file_path, 50)}`}
        />
      </div>

      <div className="flipbook_page_wrapper">
        <div
          className="flipbook_page_num"
          style={{
            color: "white",
          }}
        >
          {props.page}
        </div>
        <VIcon
          name="common/download.svg"
          className="container-download-qrcode mb-2"
          width={20}
          height={20}
          onClick={() =>
            downloadImage(
              getUrlImage(props?.data?.post_file_path),
              props?.data?.post_file_name
            )
          }
        />
      </div>
    </div>
  );
});

const PageCover = React.forwardRef((props: any, ref: any) => {
  const { metaSetting, type } = props;

  return (
    <div className="cover" ref={ref} data-density="hard">
      {metaSetting[type] && (
        <div
          className="detail-page"
          style={{ backgroundImage: `url(${metaSetting[type]})` }}
        >
          <span>{metaSetting?.name ?? ""}</span>
        </div>
      )}
    </div>
  );
});

const View = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const dataImage = useAppSelector(getUIPGaleryState);
  const audioSrc = useAppSelector(getAudioSrc);
  const musicMetadata = useAppSelector(getMusicMetadata);
  const usernamePost = useAppSelector(getUsernamePost);
  const audioPlayer = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const flipBookRef = useRef(null);
  const btnNextRef = useRef(null);
  const closeBtnRef = useRef(null);
  const [myInterval, setMyInterval] = useState(null);
  const [mute, setMute] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);

  const { flipBookImage: metaSetting } = useContext(FlipBookImageContext);

  const [minHeight, setMinHeight] = useState<"full" | number>("full");

  const onLoadFlipbookImag = (e) => {
    const isImgVertical = +e.target.height > +e.target.width;
    if (!isImgVertical) {
      if (minHeight === "full" || minHeight < +e.target.height) {
        if (document.getElementById("flipbook_page_id")?.clientHeight > 0) {
          setMinHeight(
            Math.min(
              document.getElementById("flipbook_page_id")?.clientHeight,
              +e.target.height
            )
          );
        } else {
          setMinHeight(+e.target.height);
        }
      }
    }
  };

  const flipbookBg = useMemo(() => {
    if (metaSetting) {
      const siteMedia = metaSetting?.flipbook_cover_background ?? "";
      if (siteMedia)
        return {
          backgroundImage: `url(${siteMedia})`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
        };
    }

    return {};
  }, [metaSetting]);

  const toggleGalleryImages = () => {
    clearInterval(myInterval);
    setMute(false);
    setIsPlaying(false);
    dispatch(
      setPostGaleryImages({
        galeryImages: [],
        isOpenGaleryImage: false,
      })
    );
  };

  const handlePlayAudio = () => {
    if (!isPlaying) {
      audioPlayer.current.play();
      if (dataImage.length > 1) {
        let ratioTime =
          Math.round(audioPlayer.current.duration) / (dataImage.length / 2);

        if (ratioTime > 5) {
          ratioTime = 5;
        }
        if (ratioTime < 0.5) {
          ratioTime = 1.5;
        }
        setMyInterval(
          setInterval(() => {
            btnNextRef.current.click();
          }, ratioTime * 1000)
        );
      }
    } else {
      audioPlayer.current.pause();
      clearInterval(myInterval);
    }
    setIsPlaying(!isPlaying);
  };

  const handleMuteAudio = () => {
    if (!mute) {
      audioPlayer.current.muted = true;
    } else {
      audioPlayer.current.muted = false;
    }
    setMute(!mute);
  };

  useEffect(() => {
    const n = setTimeout(() => {
      if (dataImage.length > 0) {
        setIsOpen(true);
      } else {
        setIsOpen(false);
      }
    }, 50);
    return () => {
      clearTimeout(n);
    };
  }, [dataImage]);

  useEffect(() => {
    if (audioSrc && !isMobile) {
      setIsPlaying(true);
      audioPlayer.current.play();
      if (dataImage.length > 1) {
        audioPlayer.current.onloadedmetadata = function () {
          let ratioTime =
            Math.round(audioPlayer.current.duration) / (dataImage.length / 2);

          if (ratioTime > 5) {
            ratioTime = 5;
          }
          if (ratioTime < 0.5) {
            ratioTime = 1.5;
          }
          setMyInterval(
            setInterval(() => {
              btnNextRef.current.click();
            }, ratioTime * 1000)
          );
        };
      }
    }
  }, [audioSrc, isMobile]);

  // useEffect(() => {
  //   const setHeight = () => {
  //     const doc = document.documentElement;
  //     doc.style.setProperty("--app-height", `${window.innerHeight}px`);
  //   };

  //   window.addEventListener("resize", setHeight);
  //   setHeight();

  //   return () => {
  //     window.removeEventListener("resize", setHeight);
  //   };
  // }, []);

  const handleMessageOpenGalary = (event: MessageEvent) => {
    if (
      event.origin === process.env.REACT_APP_WEB_MODULE_URL &&
      event.data?.action === "open_flipbook" &&
      event.data?.payload?.type === "open_flipbook"
    ) {
      dispatch(
        setPostGaleryImages({
          ...event.data?.payload,
          isOpenGaleryImage: true,
        })
      );
    }
  };

  useEffect(() => {
    window.addEventListener("message", handleMessageOpenGalary);
    return () => {
      window.removeEventListener("message", handleMessageOpenGalary);
    };
  }, []);

  useEffect(() => {
    setMinHeight("full");
  }, [isOpen]);

  if (dataImage.length === 0) {
    return <></>;
  }

  return (
    <>
      {isOpen && (
        <div className="book-flip">
          <div className="book-flip-wrapper">
            <div className="book-flip-tool">
              <div className="book-flip-toolbar">
                <div
                  className="book-flip-close"
                  ref={closeBtnRef}
                  onClick={() => toggleGalleryImages()}
                >
                  <VIcon name="cancel.svg" width={20} height={20} />
                </div>
              </div>
            </div>
            {audioSrc && (
              <div className="audio-metadata-wrapper">
                <div className="audio-metadata">
                  <div className="music-metadata">
                    <FaMusic size={20} color="#4284F3" />
                    <span className="text-metadata">
                      {musicMetadata
                        ? musicMetadata.title + " - " + musicMetadata.artist
                        : t(`Âm thanh gốc của ${usernamePost}`)}
                    </span>
                  </div>
                  <div className="book-flip-audio-controlls">
                    <div className="play-audio-icon" onClick={handlePlayAudio}>
                      {isPlaying ? (
                        <BsFillPauseFill size={28} color="#4284F3" />
                      ) : (
                        <BsPlayFill size={28} color="#4284F3" />
                      )}
                    </div>
                    <div className="mute-audio-icon" onClick={handleMuteAudio}>
                      {mute ? (
                        <BiVolumeMute size={22} color="#4284F3" />
                      ) : (
                        <VscUnmute size={22} color="#4284F3" />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}

            {metaSetting && (
              <HTMLFlipBook
                ref={flipBookRef}
                width={600}
                height={700}
                minWidth={315}
                maxWidth={700}
                minHeight={420}
                maxHeight={900}
                showCover={true}
                flippingTime={1500}
                style={{ margin: "0 auto", ...flipbookBg }}
                maxShadowOpacity={0}
                className="album-web"
                startPage={0}
                size="stretch"
                // size="fixed"
                drawShadow={true}
                usePortrait={true}
                startZIndex={0}
                autoSize={true}
                mobileScrollSupport={true}
                clickEventForward={true}
                useMouseEvents={true}
                swipeDistance={30}
                showPageCorners={true}
                disableFlipByClick={false}
              >
                <PageCover
                  key={0}
                  metaSetting={metaSetting}
                  type={"flipbook_cover_first"}
                />
                {dataImage.map((data: any, index: number) => (
                  <Page
                    key={index}
                    data={data}
                    page={
                      isMobile
                        ? `${index + 1} / ${dataImage.length}`
                        : `${index + 1}`
                    }
                    onLoadFlipbookImag={onLoadFlipbookImag}
                    minHeight={minHeight}
                  />
                ))}

                <PageCover
                  metaSetting={metaSetting}
                  type={"flipbook_cover_end"}
                />
              </HTMLFlipBook>
            )}

            <div className="book-flip-navigate nav-left">
              <button
                type="button"
                className="btn btn-info btn-sm btn-prev"
                onClick={() => flipBookRef.current.pageFlip().flipPrev()}
              >
                <VIcon name="left_flipbook.svg" width={20} height={20} />
              </button>
            </div>

            <div className="book-flip-navigate nav-right">
              <button
                ref={btnNextRef}
                type="button"
                className="btn btn-info btn-sm btn-next"
                onClick={() => flipBookRef.current.pageFlip().flipNext()}
              >
                <VIcon name="right_flipbook.svg" width={20} height={20} />
              </button>
            </div>
          </div>
        </div>
      )}
      {audioSrc && <audio src={getUrlImage(audioSrc)} ref={audioPlayer} />}
    </>
  );
};

export default View;

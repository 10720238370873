import VTable from "components/v-table";
import { useAppSelector } from "redux/reducers/hook";
import { getHistoryTask } from "redux/reducers/tasks/tasks.selector";
import { IObject } from "types";
import { renderDateTime } from "utils/times";

const View = () => {
  const histories = useAppSelector(getHistoryTask);
  const columns = [
    {
      key: "task",
      title: "Task",
      render: (item: IObject) => `${item.object && item.object["name"]}`,
    },
    {
      key: "createdAt",
      title: "createdAt",
      render: (item: IObject) => renderDateTime(item.createdAt),
    },
    {
      key: "status",
      title: "Status",
      render: (item: IObject) => (
        <div className={item.status}>{item.status}</div>
      ),
    },
  ];

  return (
    <>
      <VTable columns={columns} dataSource={histories}></VTable>
    </>
  );
};

export default View;

/* eslint-disable jsx-a11y/iframe-has-title */
import { Col, Container } from "react-bootstrap";
import Card from "components/v-teamplate/Card";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { v4 as uuidv4 } from "uuid";
import { getUserProfile } from "redux/reducers/users/users.selector";
import useQuery from "hooks/useQuery";
import { getIsApp } from "redux/reducers/settings/settings.selector";

const View = () => {
  const { i18n } = useTranslation();
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserProfile);
  const { question_id } = useQuery();
  const isApp = useAppSelector(getIsApp)

  useEffect(() => {
    window.localStorage.setItem(
      "prev",
      `${window.origin}${window.location.pathname}`
    );

    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="product-wrapper">
      <Container>
        <Col sm={12} className="d-flex">
          <Col>
            <Col sm={12} className={"mt-2 "}>
              <Card className="p-1" isOpacity={true}>
                <Col sm={12}>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      className={`${
                        isApp ? "iframe-is-app" : ""
                      } iframe-product-list`}
                      allow="clipboard-read; clipboard-write"
                      src={`${
                        process.env.REACT_APP_WEB_MODULE_URL
                      }/question-and-answer/table?vdb_token=${localStorage.getItem(
                        "auth"
                      )}&origin=${
                        window.origin
                      }&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&lang=${
                        i18n.language ? `${i18n.language}` : ""
                      }&user_id=${user?._id}&question_id=${
                        question_id ? question_id : ""
                      }&where=client`}
                    >
                      <p>Your browser does not support iframes.</p>
                    </iframe>
                  </div>
                </Col>
              </Card>
            </Col>
          </Col>
        </Col>
      </Container>
    </div>
  );
};

export default View;

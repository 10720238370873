import Input from "components/v-input";
import { Controller } from "react-hook-form";
import useTranslation from "hooks/useTranslation";
const IDWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange
}) => {
  const stockName = String(tokenName).toUpperCase();
  const { t } = useTranslation();
  return (
    <div className="rigth-view-id">
      <div>
        <Controller
          name="to"
          control={control}
          rules={{ required: true }}
          render={({ field }) => {
            return (
              <Input
                transparent={true}
                label={t("ID Vzonex")}
                placeholder={t("Input Vzonex ID")}
                hasError={errors.to}
                {...field}
              />
            );
          }}
        />
      </div>
      <div className="mt-3">
        <Input
          transparent={true}
          label={`${t("Stock")} ${stockName} ${t("Amount")}`}
          placeholder="0.00"
          isCurrency={true}
          type="text"
          value={amount}
          hasError={amount > +userBalance[tokenName] || errorAmount}
          suffix={
            <div
              className="cursor-pointer"
              onClick={setMaxAmount}
            >
              {t("MAX")}
            </div>
          }
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>

      <p className="mt-3">
        {t("Maximum")} {stockName} {t("Available")}:{" "}
        <span className="declined">{userBalance[tokenName]}</span>{" "}
        {stockName}
      </p>
      <p>
        {t("You can")} <span className="success">{t("buy")}</span> {t("or")}{" "}
        <span className="orange">{t("deposit")}</span> {stockName}
      </p>
      <div className="mt-3" />
      <Controller
        name="note"
        control={control}
        render={({ field }) => {
          return (
            <Input
              type={"textarea"}
              height="100px"
              transparent={true}
              label={t("Message")}
              placeholder={`${t("Welcome to")} ${stockName} ${t("Stock")}`}
              hasError={errors.note}
              {...field}
            />
          );
        }}
      />
    </div>
  );
};

export default IDWithdraw;

import useTranslation from "hooks/useTranslation";
import React from "react";

export default function View({ reward }) {
  const { t } = useTranslation();
  return (
    <div className="v-reward">
      {reward["stocks"]?.length > 0 && (
        <div className="">
          <strong className="me-2">{t("Stocks")} :</strong>
          {reward["stocks"].map((s) => (
            <div>
              <span>
                +{s.amount} ({String(s.symbol).toUpperCase()})
              </span>
            </div>
          ))}
        </div>
      )}
      {reward["vouchers"]?.length > 0 && (
        <div className="">
          <strong>{t("Vouchers")} :</strong>
          {reward["vouchers"].map((s) => (
            <div>
              <span>
                +{s.amount} ({s.id})
              </span>
            </div>
          ))}
        </div>
      )}
      {reward["tickets"]?.length > 0 && (
        <div className="">
          <strong className="me-2">{t("Tickets")} :</strong>
          {reward["tickets"].map((s) => (
            <div>
              <span>
                +{s.amount} ({s.game_name})
              </span>
            </div>
          ))}
        </div>
      )}

      {reward["codes"]?.length > 0 && (
        <div className="">
          <strong>{t("Codes")} :</strong>
          {reward["codes"].map((s) => (
            <div>
              <span>
                +{s.codes} ({s.symbol})
              </span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

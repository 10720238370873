/* eslint-disable react-hooks/exhaustive-deps */
import UserService from "api/users";
import VAvatar from "components/v-avatar";
import VPanigation from "components/v-panigation";
import VTable from "components/v-table";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const Users = ({ sortBy, search }) => {
  const { t } = useTranslation();

  const params = useParams();
  const { id } = params;
  // const rankingIcon = {
  //   0: "/assets/images/ranking/1.svg",
  //   1: "/assets/images/ranking/2.svg",
  //   2: "/assets/images/ranking/3.svg",
  // };
  const [ranking, setRanking] = useState({
    docs: [],
    page: 1,
  });

  const columns = [
    {
      key: "ratings",
      title: t("Position"),
      render: (item: any, index) => {
        return (
          <span>{(ranking.page - 1) * 10 + index + 1}</span>
        )
      },
    },
    {
      key: "User",
      title: t("User"),
      render: (item: any) => {
        return (
          <div className="d-flex justify-content-between">
            <VAvatar
              className="m-1"
              isClick={true}
              size="25px"
              fontSizeIconVerify={12}
              userData={item.user}
            />
            {/* {index < 3 && (
              <img width={30} height={30} src={rankingIcon[index]} alt="" />
            )} */}
          </div>
        );
      },
    },
    {
      key: "refers",
      title: t("Refers"),
      render: (item) => {
        return `${item?.count?.refers || 0}`;
      },
    },
    {
      key: "Friends",
      title: t("Friends"),
      render: (item) => {
        return `${item?.count?.friend || 0}`;
      },
    },
    {
      key: "follower",
      title: t("Followers"),
      render: (item) => {
        return `${item.count.follower || 0}`;
      },
    },
  ];

  useEffect(() => {
    getRankings(1);
  }, [sortBy, search]);

  const getRankings = async (page) => {
    const res: any = await UserService.getRankingByUser({
      params: {
        id: id,
      },
      query: {
        limit: 10,
        page,
        sortField: sortBy,
        sortOrder: -1,
        search: search || "",
      },
    });
    if (res) setRanking(res);
  };

  return (
    <>
      <div className="table-container-forum">
        <VTable columns={columns} dataSource={ranking["docs"]}></VTable>
      </div>
      <VPanigation {...ranking} getData={getRankings} />
    </>
  );
};

export default Users;

import { request, parseErrorResponse } from "./request";

const my = () =>
  new Promise(async (resolve, reject) => {
    request()
      .get("auth-sessions/my")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AuthSessionService = {
  my,
};

export default AuthSessionService;

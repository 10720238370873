/* eslint-disable indent */
import { Switch, Route } from "react-router-dom";
import FeedByType from "pages/social/feeds";
import { ROUTES } from "config/routes";
import { POST_PLUGIN, POST_TYPE } from "config/enums";
import ComingSoon from "pages/coming-soon/comingsoon";

const FeedByTypes = ({ component: Component, ...rest }) => {
  const feedTypes = [
    { path: ROUTES.NEW_FILES, type: POST_TYPE.FILE },
    { path: ROUTES.AUDIOS, type: POST_TYPE.AUDIO },
    { path: ROUTES.LOCATIONS, plugin: POST_PLUGIN.LOCATIONS },
    { path: ROUTES.IMAGES, plugin: POST_PLUGIN.IMAGE },
    { path: ROUTES.PRODUCTS, type: POST_TYPE.PRODUCT },
  ];

  const getType = (path: string) => {
    return feedTypes.find((e) => e.path === path)?.type || "";
  };

  const getPlugin = (path: string) => {
    return feedTypes.find((e) => e.path === path)?.plugin || "";
  };

  return (
    <Switch>
      <Route
        {...rest}
        render={(props) => {
          if (
            getType(rest?.location?.pathname) ||
            getPlugin(rest?.location?.pathname)
          ) {
            return (
              <FeedByType
                type={getType(rest?.location?.pathname)}
                plugin={getPlugin(rest?.location?.pathname)}
              />
            );
          } else {
            if (rest?.location?.pathname === "/") {
              return <FeedByType />;
            }
          }
          return <ComingSoon />;
        }}
      />
    </Switch>
  );
};

export default FeedByTypes;

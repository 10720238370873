import VInput from "components/v-input";
import Dropdown from "components/v-dropdown";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";

/* eslint-disable indent */

const defaultValidate = {
	day: null,
	month: null,
	year: null,
};

const View = (props) => {
	const { date, setIsValid, disabled, onChange } = props;
	const [validate, setValidate] = useState(defaultValidate);
	const { t } = useTranslation();

	const [state, setState] = useState({
		day: null,
		month: null,
		year: null
	})

	const performValidate = (props = [], currentDetail = null) => {
		let _result = validate;
		let _detail = currentDetail || state;
		let _isValid = true;
		if (props.length === 0) {
			for (const property in _result) {
				props.push(property);
			}
		}
		props.forEach((prop) => {
			switch (prop) {
				case "day":
					_result[prop] = !_detail.day
						? `${t("name")} ${t("message.cant-be-empty")}`
						: null;
					if (_detail.day > 31)
						_result[prop] = `${t("message.invalid-day")}`;
					break;
				case "month":
					_result[prop] = !_detail.month
						? `${t("message.cant-be-empty")}`
						: null;
					if (_detail.month > 12)
						_result[prop] = `${t("message.invalid-month")}`;
					break;
				case "year":
					_result[prop] = !_detail.year
						? `${t("message.cant-be-empty")}`
						: null;

					if (_detail.year > new Date().getFullYear())
						_result[prop] = `${t("message.invalid-year")}`;
					break;
				default:
					break;
			}
		});
		setValidate((prev) => ({
			...prev,
			..._result,
		}));

		for (const property in _result) {
			if (_result[property]) {
				_isValid = false;
				break;
			}
		}
		setIsValid(_isValid);
		return _isValid;
	};


	const handleChangeBirthday = (value, name) => {
		let _state = state;
		_state[name] = value;
		setState(_state);

		performValidate([name], _state);
		const { day, month, year } = _state;
		const newDate = new Date(`${month}-${day}-${year}`)
		onChange(newDate)
	}

	useEffect(() => {
		if(date){
			setState({
				day: new Date(date).getDate(),
				month: new Date(date).getMonth() + 1,
				year: new Date(date).getFullYear()
				})
			}
			setIsValid(true)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	},[])

	return (
		<div>
			<div className="d-flex">
				<div className="w-33">
					<VInput
						label={t("Day")}
						type="text"
						id="day"
						name="day"
						disabled={disabled}
						defaultValue={state.day}
						errMessage={validate.day}
						onChange={(e) => handleChangeBirthday(e.target.value, "day")}
					/>
				</div>
				&ensp;
				<div className="w-33">
					<Dropdown
						label={""}
						disabled={disabled}
						placeholder={t("Month")}
						options={Array(12)
							.fill(0)
							?.map((el: any, index: number) => {
								return {
									label: index + 1,
									value: index + 1,
								};
							})}
						value={state.month}
						onChange={(e) => handleChangeBirthday(e.target.value, "month")}
					/>
				</div>
				&ensp;
				<div className="w-33">
					<VInput
						label={t("Year")}
						type="text"
						id="year"
						name="year"
						disabled={disabled}
						errMessage={validate.year}
						defaultValue={state.year}
						onChange={(e) => handleChangeBirthday(e.target.value, "year")} />
				</div>
			</div>
		</div>
	)
}

export default View;
import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";
import { IRequestData } from "api/request";

export const getWalletsAsync = createAsyncThunk("wallets/list", async (req) => {
  try {
    const response = await API.WALLET.getWallets(req);
    return response;
  } catch (error) {
    return error;
  }
});

export const getWalletByOptionAsync = createAsyncThunk(
  "wallets/options",
  async (req: IRequestData) => {
    try {
      const response = await API.WALLET.getWalletsByUserAndOptions(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createWalletAsync = createAsyncThunk(
  "wallets/create",
  async (req) => {
    try {
      const response = await API.WALLET.createWallet(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

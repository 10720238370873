/* eslint-disable jsx-a11y/iframe-has-title */
import Card from "components/v-teamplate/Card";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { Col, Container } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { v4 as uuidv4 } from "uuid";
import { getUserProfile } from "redux/reducers/users/users.selector";

const ScreenManage = () => {
  const { i18n } = useTranslation();
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const dispatch = useAppDispatch();
  const user = useAppSelector(getUserProfile);

  useEffect(() => {
    window.localStorage.setItem(
      "prev",
      `${window.origin}${window.location.pathname}`
    );

    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="adv-booking">
      <Container>
        <Col sm={12} className="d-flex">
          <Col>
            <Col sm={12} className="mt-2">
              <Card className="p-1" isOpacity>
                <Col sm={12}>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      className="booking-manage"
                      allow="clipboard-read; clipboard-write"
                      src={`${
                        process.env.REACT_APP_ADV_URL
                      }/product-banner?branchId=${
                        user?._id
                      }&vdb_token=${localStorage.getItem("auth")}&origin=${
                        window.origin
                      }&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&lang=${
                        i18n.language ? `${i18n.language}` : ""
                      }`}
                    >
                      <p>Your browser does not support iframes.</p>
                    </iframe>
                  </div>
                </Col>
              </Card>
            </Col>
          </Col>
        </Col>
      </Container>
    </div>
  );
};

export default ScreenManage;

/* eslint-disable react-hooks/exhaustive-deps */
import SearchService from "api/search";
import VButton from "components/v-button";
import { GROUP_ACTION, OBJECT_TYPE } from "config/enums";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";
import { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import debounce from "lodash.debounce";
import VLoading from "components/v-loading";
import VGroupCard from "components/v-objects/v-group-card";

const Group = () => {
  const query = useQuery();
  const { t } = useTranslation();
  const [lazyParams, setLazyParams] = useState({
    limit: 12,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const getData = async (search, lazyParams) => {
    setLoading(true);
    const res: any = await SearchService.search({
      query: {
        ...lazyParams,
        search,
        objectType: OBJECT_TYPE.GROUP,
      },
    });
    if (res && res.docs) {
      if (res.page === 1) {
        setData(res.docs);
      } else {
        setData((prev) => prev.concat(res.docs));
      }
      setTotalPages(res.totalPages);
    }
    setLoading(false);
  };

  const onSearch = useCallback(debounce(getData, 500), []);

  useEffect(() => {
    if (query.search && query.search?.length > 1) {
      setLoading(true);
      setLazyParams((prev) => ({
        ...prev,
        page: 1,
      }));
      setTotalPages(1);
      onSearch(query.search, {
        page: 1,
        limit: 12,
      });
    } else {
      setData([]);
    }
  }, [query.search]);

  const handleViewMore = () => {
    setLoading(true);
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
    onSearch(query.search, {
      ...lazyParams,
      page: lazyParams.page + 1,
    });
  };

  return (
    <Card className="card-common p-3">
      <div className="container">
        <div className="row">
          {data?.length > 0 ? data.map((item, index) => {
            return (
              <VGroupCard
                key={index}
                col_lg={4}
                col_md={4}
                group={item}
                type={GROUP_ACTION.VIEW_DETAIL}
              />
            );
          }) : !loading && <span className="text-center w-100">{t("No result is found")}</span>}
        </div>
        {loading && <VLoading />}
        {lazyParams.page < totalPages && (
          <div className="text-center p-2">
            <VButton type="button" size={"small"} onClick={handleViewMore}>
              {" "}
              {t("View more")}
            </VButton>
          </div>
        )}
      </div>
    </Card>
  );
};

export default Group;

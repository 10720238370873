import React from "react";
import { useAppSelector } from "redux/reducers/hook";
import { theme_scheme } from "redux/reducers/setting-system/selectors";

type IProps = {
  isOpacity? : boolean,
  className? : string,
  children? : any
}

const Card: any = (props : IProps) => {
  const { isOpacity } = props;
  const theme = useAppSelector(theme_scheme);
  return (
    <div
      className={`card bg-opacity-98 ${
        isOpacity
          ? theme === "light"
            ? "bg-light-opacity-98"
            : "bg-dark-opacity-98"
          : "bg-opacity-98"
      } ${props.className ? props.className : ""}`}
    >
      {" "}
      {props.children}{" "}
    </div>
  );
};
Card.Header = (props) => (
  <div
    className={`card-header d-flex justify-content-between ${
      props.className ? props.className : ""
    }`}
  >
    {" "}
    {props.children}{" "}
  </div>
);
Card.Body = (props) => (
  <div className={`card-body ${props.className ? props.className : ""}`}>
    {" "}
    {props.children}{" "}
  </div>
);
Card.Footer = (props) => <div className="card-footer"> {props.children} </div>;
Card.Header.Title = (props) => (
  <div className={`header-title ${props.className ? props.className : ""}`}>
    {" "}
    {props.children}{" "}
  </div>
);
Card.Header.Action = (props) => (
  <div className={`header-action ${props.className ? props.className : ""}`}>
    {" "}
    {props.children}{" "}
  </div>
);

export default Card;

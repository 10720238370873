import { ReactElement } from "react";
import { TreeSelect } from "antd";
import { getUrlImage } from "utils/common";
const { TreeNode } = TreeSelect;

type ITreeSelectProps = {
  placeholder?: string;
  options: any[];
  onChange: (e: string) => void;
  className?: string;
  label?: string | ReactElement;
  value?: any;
  expandDefault?: boolean;
  classNameLabel?: string;
  isCustom?: boolean;
  [key: string]: any;
  treeCheckable?: boolean;
};

export default function TreeSelectComponent(props: ITreeSelectProps) {
  const {
    label,
    placeholder,
    options,
    className,
    value,
    expandDefault,
    classNameLabel,
    onChange,
    isCustom = false,
    error,
    treeCheckable,
    ...rest
  } = props;

  const handleChangeDropdown = (e: string) => {
    onChange(e);
  };

  const renderTreeNode = (node: any) => (
    <TreeNode
      value={node.value}
      title={
        <span>
          <img
            src={getUrlImage(node.icon)}
            alt={node.name}
            style={{
              height: "1rem",
              width: "1rem",
              display: "inline-block",
              marginRight: "0.5rem",
            }}
          />{" "}
          {node.name}
        </span>
      }
      key={node.value}
    >
      {node.children && node.children.map(renderTreeNode)}
    </TreeNode>
  );

  return (
    <>
      {label && <span className={classNameLabel || "text-xs"}>{label}</span>}
      <TreeSelect
        className={`${className ? className : ""}`}
        style={{ width: "100%" }}
        dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
        placeholder={placeholder || "Chọn"}
        allowClear
        treeDefaultExpandAll={expandDefault}
        onChange={(e) => handleChangeDropdown(e)}
        treeData={!isCustom ? options : undefined}
        value={value}
        treeCheckable={treeCheckable}
        {...rest}
      >
        {isCustom && options.map(renderTreeNode)}
      </TreeSelect>
      {error && <p className="text-red-500 mb-0">{error}</p>}
    </>
  );
}

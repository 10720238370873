import styled from "styled-components";

export const WebModulesIframeStyled = styled.div`
  position: fixed;
  width: 100vw;
  height: var(--app-height);
  left: 0;
  top: 0;
  overflow: hidden;
  /* background-color: rgba(0, 0, 0, 0.137); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;

  .iframe {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .loading-icon {
    position: absolute;
  }
`;

export const WebModulesIframeMiniChatStyled = styled.div`
  position: fixed;
  width: 350px;
  height: 500px;
  right: 150px;
  bottom: 10px;
  overflow: hidden;
  /* background-color: rgba(0, 0, 0, 0.137); */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999999;

  .iframe {
    width: 100%;
    height: 100%;
    overflow: auto;
  }

  .loading-icon {
    position: absolute;
  }

  @media screen and (max-width: 576px) {
    right: unset;
    bottom: unset;
    top: 0;
    width: 100vw;
    height: var(--app-height);
  }
`;

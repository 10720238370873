/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { ROUTES } from "config/routes";
import AuthApi from "api/auth";
import { REGEX_EMAIL } from "utils/validates";
import MyButton from "components/v-button";
import VInput from "components/v-input";
import useTranslation from "hooks/useTranslation";
import useQuery from "hooks/useQuery";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import VIcon from "components/v-icon";
import FooterForm from "../components/footer-form";
import Card from "components/v-teamplate/Card";
import HeaderForm from "../components/header-form";
import { OBJECT_TYPE } from "config/enums";

function View({ setOptions }) {
  const { t } = useTranslation();

  const defaultState = {
    email: "",
    referred_by: "",
  };

  const defaultValidate = {
    email: null,
    password: null,
    phone: null,
    last_name: null,
    first_name: null,
  };

  const query = useQuery();
  const menus: any = useAppSelector(getMenusByKey("signup_policy"));

  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(defaultState);
  const history = useHistory();
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const [validate, setValidate] = useState(defaultValidate);
  const [isValid, setIsValid] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);

  const handleBack = () => {
    history.push(ROUTES.LOGIN);
  };

  const handleChangeState = (name, value) => {
    let _state = state;
    _state[name] = value;
    setState(_state);
    performValidate([name], _state);
  };

  useEffect(() => {
    if (query.refer_code) {
      handleChangeState("referred_by", query.refer_code);
    }
  }, [query.refer_code]);

  useEffect(() => {
    setValidate(defaultValidate);
    setState(defaultState);
  }, []);

  const performValidate = (props = [], currentDetail = null) => {
    let _result = validate;
    let _detail = currentDetail || state;
    let _isValid = true;
    if (props.length === 0) {
      for (const property in _result) {
        props.push(property);
      }
    }
    props.forEach((prop) => {
      switch (prop) {
        case "full_name":
          _result[prop] = !_detail.full_name
            ? `${t("name")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "first_name":
          _result[prop] = !_detail.first_name
            ? `${t("message.cant-be-empty")}`
            : null;
          break;
        case "last_name":
          _result[prop] = !_detail.last_name
            ? `${t("message.cant-be-empty")}`
            : null;
          break;
        case "phone":
          _result[prop] = !_detail.phone
            ? `${t("Phone")} ${t("message.cant-be-empty")}`
            : null;
          break;
        case "email":
          _result[prop] = !_detail.email
            ? `${t("Email")} ${t("message.cant-be-empty")}`
            : null;
          if (_detail.email && !REGEX_EMAIL.test(_detail.email))
            _result[prop] = `${t("message.invalid-email")}`;
          break;
        case "password":
          _result[prop] = !_detail.password
            ? `${t("Password")} ${t("message.cant-be-empty")}`
            : null;
          break;
        default:
          break;
      }
    });
    setValidate((prev) => ({
      ...prev,
      ..._result,
    }));

    for (const property in _result) {
      if (_result[property]) {
        _isValid = false;
        break;
      }
    }
    setIsValid(_isValid);
    return _isValid;
  };

  const handleSubmit = async ({ location, home_town, position, birthday }) => {
    try {
      const isValid = performValidate([]);
      if (!isValid) return;

      setLoading(true);
      const res: any = await AuthApi.fastRegister({
        ...state,
        register_type: "fast",
        object_type: OBJECT_TYPE.USER,
        location,
        home_town,
        position,
        birthday,
      });
      if (res) {
        localStorage.setItem("auth", res.token);
        history.push(ROUTES.HOME);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(error?.errors));
    }
  };

  return (
    <>
      <Card className="form-user col-md-6" isOpacity={true}>
        <HeaderForm />
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0 fs-2">{t("register")}</h1>
        </div>
        {/* <span>{t("For people under 18 years old")}</span> */}
        {/* {userBy && (
        <span>
          {t("You are registering an account via the invitation of")}{" "}
          <b>{userBy?.full_name}</b>
        </span>
      )} */}
        <div>
          <form className="mt-3">
            <div className="form-group">
              <>
                <div className="d-flex justify-content-between">
                  <div className="form-group w-50 me-1">
                    <VInput
                      label={t("Last name")}
                      type="text"
                      id="name"
                      name="last_name"
                      errMessage={validate.last_name}
                      onChange={(e) =>
                        handleChangeState("last_name", e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group w-50 ms-1">
                    <VInput
                      label={t("First name")}
                      type="text"
                      id="first_name"
                      name="first_name"
                      errMessage={validate.first_name}
                      onChange={(e) =>
                        handleChangeState("first_name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group w-50 me-1">
                    <VInput
                      label={t("Enter email")}
                      type="email"
                      id="email"
                      name="email"
                      errMessage={validate.email}
                      onChange={(e) =>
                        handleChangeState("email", e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group w-50 ms-1">
                    <VInput
                      label={t("Phone")}
                      type={"number"}
                      id="phone"
                      name="phone"
                      errMessage={validate.phone}
                      onChange={(e) =>
                        handleChangeState("phone", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <VInput
                    label={t("Password")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    errMessage={validate.password}
                    onChange={(e) =>
                      handleChangeState("password", e.target.value)
                    }
                    suffix={
                      <div
                        className="cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <VIcon
                          name={showPassword ? "eye.svg" : "eye_off.svg"}
                        />
                      </div>
                    }
                  />
                </div>
                <div className="form-group">
                  <VInput
                    label={t("Referral code")}
                    type="text"
                    id="referred_by"
                    name="referred_by"
                    disabled={Boolean(query.refer_code)}
                    value={state.referred_by}
                    onChange={(e) =>
                      handleChangeState("referred_by", e.target.value)
                    }
                  />
                </div>
              </>
            </div>
            <div className="d-inline-block w-100">
              <div className="d-flex">
                <div>
                  <input
                    className="form-check-input me-2"
                    type="checkbox"
                    onChange={(e) => setIsPolicy(e.target.checked)}
                  />
                </div>
                <label className="form-check-label" htmlFor="customCheck1">
                  {t(menus[0]?.description)}{" "}
                  <a href={menus[0]?.url} target="_blank" rel="noreferrer">
                    {t(menus[0]?.name)}
                  </a>
                </label>
              </div>
              <div className="d-flex justify-content-between align-items-center mt-2">
                <MyButton
                  onClick={handleBack}
                  height={"36px"}
                  className="btn btn-primary"
                  loading={loading}
                >
                  {t("back")}
                </MyButton>
                <MyButton
                  onClick={handleSubmit}
                  height={"36px"}
                  className="btn btn-primary"
                  loading={loading}
                  disabled={!isValid || !isPolicy}
                >
                  {t("register")}
                </MyButton>
              </div>
            </div>
          </form>
        </div>
        <FooterForm isHideGoogle={true} />
      </Card>
    </>
  );
}

export default View;

/* eslint-disable array-callback-return */
/* eslint-disable indent */
import React from "react";
// import { useLocation } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
// import queryString from "query-string";

import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
// import FormProfileSettings from "./FormProfileEdit";
// import PreviewProfile from "./PreviewProfile";

import Card from "components/v-teamplate/Card";

import Avatar from "components/v-avatar";
import APIs from "api";
import { toast } from "react-toastify";
import { setProfile } from "redux/reducers/users/users.reducer";
// import VIcon from "components/v-icon";
import VModal from "components/v-modal";
import { useRef, useState } from "react";
import ReusePhotos from "pages/social/object-details/profile-header/components/ReusePhotos";
import { checkSizeFileUpload } from "utils/common";
import EditFrame from "pages/social/object-details/profile-header/components/EditFrame";
import About from "pages/social/object-details/profile-about";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";

const ProfileSetting = (props) => {
  // const location = useLocation();

  // const history = useHistory();
  const { t } = useTranslation();
  const userProfile: any = useAppSelector(getUserProfile);
  const dispatch = useAppDispatch();
  const chain_id_setting: any = useAppSelector(
    getMenusByKey("chain_id_setting")
  );
  // const { profile } = userData;

  const userData = props.dataUser || userProfile;
  // const isEdit = queryString.parse(location.search)?.isEdit;

  const coverRef = useRef(null);
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [typeEditAvatar, setTypeEditAvatar] = useState("");

  const submit = () => {
    coverRef.current.submit();
  };

  // const handleAction = () => {
  //   const search = new URLSearchParams(window.location.search);
  //   if (isEdit) {
  //     search.delete("isEdit");
  //   } else {
  //     search.set("isEdit", `true`);
  //   }
  //   history.push(`?${search}`);
  // };

  const handleEditAvatar = (type: string) => {
    setOpenModal(true);
    setTypeEditAvatar(type);
  };

  const handleClickId = (item) => {
    window.open(item?.url);
  };

  const handleUploadBackground = async (file: any) => {
    try {
      if (file) {
        if (checkSizeFileUpload(file) > 20)
          return toast.error(t("File size must not exceed 20Mb"));
        const formData = new FormData();
        formData.append("files", file);
        formData.append("folderPath", `users/${userData._id}`);
        const imgRes: any = await APIs.UPLOAD.uploadFile(
          {
            body: formData,
          },
          true
        );
        if (imgRes) {
          const res: any = await APIs.USER.editUser({
            body: {
              avatar: imgRes.filePath,
              user_id: userData?._id,
            },
          });
          if (res) {
            dispatch(
              setProfile({
                ...userData,
                avatar: imgRes.filePath,
              })
            );
            toast.success(t(res));
          }
        }
      }
    } catch (error: any) {
      toast.success(t(error?.errors));
    }
  };

  const renderContentModal = () => {
    if (typeEditAvatar === "choose_image") {
      return (
        <ReusePhotos
          ref={coverRef}
          type={"avatar"}
          dataUser={userData}
          setLoading={setLoading}
          setOpenModal={setOpenModal}
        />
      );
    }
    if (typeEditAvatar === "edit_frame") {
      <EditFrame
        ref={coverRef}
        type={"avatar"}
        dataUser={userData}
        isMyProfile={true}
        setLoading={setLoading}
        setOpenModal={setOpenModal}
      />;
    }
    return <></>;
  };

  console.log(chain_id_setting);

  return (
    <Card>
      <Card.Header className="card-header d-flex justify-content-between information-header-setting">
        <Avatar
          className="m-0"
          size={"80px"}
          fontSize={"26px"}
          userData={userData}
          isEdit={true}
          handleClickType={(e) => handleEditAvatar(e)}
          handleUpload={handleUploadBackground}
          additionalData={
            chain_id_setting?.length > 0 && (
              <div
                className="fs-5 cursor-pointer"
                onClick={() => handleClickId(chain_id_setting[0])}
              >
                {chain_id_setting[0]?.name}
              </div>
            )
          }
        />
      </Card.Header>
      <About isAuth={true} dataUser={userProfile} isMyProfile={true} />
      <VModal
        title={t("avatar")}
        isOpen={openModal}
        loading={loading}
        centered={true}
        children={renderContentModal()}
        onCancel={() => setOpenModal(false)}
        onConfirm={submit}
      />
    </Card>
  );
};

export default ProfileSetting;

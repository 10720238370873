import { parseErrorResponse, request } from "./request";

const create = (req: { body: any }) => {
  return new Promise((resolve, reject) => {
    request()
      .post(`/censor-verify`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const update = (req: { body: any; params: { id: string } }): Promise<string> =>
  new Promise((resolve, reject) => {
    request()
      .put(`/censor-verify/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const CensorService = {
  create,
  update,
};

export default CensorService;

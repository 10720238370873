/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import {
  getNotificationsClientAsync,
  getNotificationsOwnerAsync,
} from "./notifications.actions";
import { IObject } from "types";

export interface INotificationState {
  client: {
    docs: IObject[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  owner: IObject[];
  access: boolean;
  read: IObject[];
}

const initialState: INotificationState = {
  client: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 20,
  },
  owner: [],
  access: true,
  read: [],
};

export const NotificationSlice = createSlice({
  name: "notifications",
  initialState,
  reducers: {
    setNotification: (state: INotificationState, action: IObject) => {
      const { _id } = action.payload;
      const { docs } = state.client;
      if (!docs.some((d) => d._id === _id)) {
        state.client.docs = [action.payload, ...docs];
        state.client.totalDocs = state.client.totalDocs + 1;
      } else {
        state.client.docs = docs.map((n) =>
          n._id === _id ? action.payload : n
        );
      }
    },
    setLazyParams: (state: INotificationState, action) => {
      const { page } = action.payload;
      state.client.page = page;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getNotificationsClientAsync.pending, (state) => {})
      .addCase(getNotificationsClientAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.client = action.payload || {};
        } else {
          state.client = {
            ...state.client,
            ...action.payload,
            page,
            docs: state.client.docs.concat(docs),
          };
        }
      })
      .addCase(getNotificationsOwnerAsync.pending, (state) => {})
      .addCase(getNotificationsOwnerAsync.fulfilled, (state, action) => {
        const { notifications, errors } = action.payload;
        if (errors === "Not Authenticated") state.access = false;
        else {
          state.owner = notifications;
          state.access = true;
        }
      });
  },
});

export const { setNotification, setLazyParams } = NotificationSlice.actions;

export default NotificationSlice.reducer;

import React, { useState } from "react";

import AuthLayout from "layouts/AuthLayout";
import AuthRouter from "router/auth-router";
import withAuth from "HOCs/withAuth";

function View() {
  const [options, setOptions] = useState({
    location: null
  })

  return (
    <AuthLayout options={options} >
      <AuthRouter setOptions={setOptions}/>
    </AuthLayout>
  );
}

export default withAuth(View);

/* eslint-disable import/no-anonymous-default-export */
import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

export const getPageBackgrounds = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/page-backgrounds/${req.params.position}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getPageBackgroundsNoAuth = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/page-backgrounds/no-auth/${req.params.position}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

export const getBackgroundProfile = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`/post-backgrounds?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PageBackgroundService = {
  getPageBackgrounds,
  getPageBackgroundsNoAuth,
  getBackgroundProfile
};

export default PageBackgroundService;

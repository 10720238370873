/* eslint-disable react-hooks/exhaustive-deps */
import {
  useEffect,
  useState,
} from "react";
import APIs from "api";
import TreeSelectComponent from "components/treeSelect";
import { getUrlImage } from "utils/common";
import { t } from "i18next";

const View = (props: {
  userId: string,
  userDirectory: any,
  setUserDirectory: (e) => void,
}) => {
  const {userDirectory, setUserDirectory} = props;

  const [userDirectories, setUserDirectories] = useState<any[]>([]);
  const [userDirectorieInfo, setUserDirectorieInfo] = useState<any>();

  const getUserDirectory = async (id: string) => {
    try {
      const usersData: any = await APIs.USER.getUserDepartments(
        id || "",
        "filter"
      );
      setUserDirectories(usersData?.value || []);
      setUserDirectorieInfo(usersData?.infoDirectory);
    } catch (error) {}
  };

  const getTreeSelectData = () => {
    let data;

    const mapData = (list: any) => {
      const dataChild = list.map((item: any) => child(item));
      return dataChild.length > 0 ? dataChild : null;
    };

    const child = (ct: any) => {
      const dataChild = userDirectories.filter(
        (dir) => dir.parent_id === ct?._id
      );
      return {
        label: (
          <div
            className="flex items-center py-2"
            style={{
              color:
                userDirectorieInfo?.color?.[
                  !ct?.parent_id ? "parent" : "child"
                ],
            }}
          >
            <img
              className="mr-1 rounded-2xl"
              width={14}
              height={14}
              src={
                ct?.icon
                  ? getUrlImage(ct?.icon)
                  : `${window.location.origin}/assets/icons/default/list-folder.svg`
              }
              alt=""
            />
            {ct?.status === "private" && (
              <img
                className="mr-1"
                width={14}
                height={14}
                src={`${window.location.origin}/assets/icons/default/lock.svg`}
                alt=""
              />
            )}

            {t(ct?.title)}
          </div>
        ),
        value: ct?._id,
        children: dataChild.length > 0 ? mapData(dataChild) : [],
      };
    };

    data = userDirectories
      ?.filter((dir) => !dir.parent_id)
      .map((el: any) => child(el));

    return data;
  };

  const getUserDirectoryById = (bigObjectArray: any, id: string) => {
    const findObjectsWithId = (objArray: any) => {
      let results: any[] = [];

      for (let i = 0; i < objArray.length; i++) {
        let obj = objArray[i];
        results = results.concat(findObjectsWithIdRecursive(obj));
      }

      return results;
    };

    const findObjectsWithIdRecursive = (obj: any) => {
      let results: any[] = [];

      if (typeof obj === "object") {
        if (obj?._id === id) {
          results.push(obj);
        }

        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            results = results.concat(findObjectsWithIdRecursive(obj[key]));
          }
        }
      }

      return results;
    };

    return findObjectsWithId(bigObjectArray).map((el: any) => {
      return {
        label: t(el?.title),
        value: el?._id,
        children: el?.children_id ? [] : null,
      };
    })[0];
  };

  useEffect(() => {
    getUserDirectory(props.userId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  return (
    <>
      <div className="text-center border-b-2 relative p-3">
        <div className="d-flex justify-content-between my-2 flex-column align-items-start">
          <TreeSelectComponent
            {...(!!userDirectory?.value ? { value: userDirectory } : {})}
            onChange={(e) => {
              setUserDirectory(getUserDirectoryById(userDirectories, e));
            }}
            className="!rounded-md !bg-white my"
            placeholder={t("Chọn sổ Nhật ký")}
            options={getTreeSelectData()}
            label={t("Sắp xếp vào danh mục Nhật ký của bạn")}
          />
        </div>        
      </div>
    </>
  );
};

export default View;

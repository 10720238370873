/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";

import { useEffect, useState } from "react";

import { Card } from "reactstrap";
import VInput from "components/v-input";
import VButton from "components/v-button";
import VTab from "components/v-tab";
import useQuery from "hooks/useQuery";
import QA from "./components/qa";
import Details from "./components/details";
import { getCategoryFaqs, getFas } from "redux/reducers/faqs/faqs.selectors";
import useTranslation from "hooks/useTranslation";
import History from "./components/history";
import { useHistory } from "react-router-dom";
import VIcon from "components/v-icon";
import {
  getCategoryFaqsAsync,
  getFasAsync,
} from "redux/reducers/faqs/faqs.actions";

const View = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const query = useQuery();
  const history = useHistory();

  const categoryFaqs: any = useAppSelector(getCategoryFaqs);
  const qas: any = useAppSelector(getFas);

  const [menus, setMenus] = useState([]);
  const [tab, setTab] = useState(null);
  const [search, setSearch] = useState([]);

  useEffect(() => {
    dispatch(getCategoryFaqsAsync());
    dispatch(getFasAsync());
  }, []);

  const onChangeSearch = (e) => {
    if (e.target.value) {
      const dataSearch = qas.filter((el) => el.name.includes(e.target.value));
      setSearch(dataSearch);
    } else {
      setSearch([]);
    }
  };

  const handleSearch = (data) => {
    let tabMenu;
    const getTab = (id) => {
      const a = categoryFaqs.find((m) => m._id === id);

      if (a) {
        const menu = menus.find((el) => el._id === a._id);
        if (menu) {
          tabMenu = menu;
        } else {
          getTab(a.parent_id);
        }
      }
    };
    getTab(data.category_faq_id);
    if (tabMenu) {
      setSearch([]);
      const category = categoryFaqs?.find(
        (el) =>
          el.parent_id === tabMenu?._id &&
          String(el._id) === String(data.category_faq_id)
      );
      return history.push(
        `?tab=${tabMenu.key}${category ? `&helpref=${category.key}` : ""}`
      );
    }
  };

  const handleCreateQuestion = () => {
    history.push(`?form_support=true`);
  };

  useEffect(() => {
    if (query.tab) {
      if (menus && menus.length > 0) {
        const menu = menus.find((m) => m.key === query.tab);
        if (menu) {
          setTab(menu);
        } else setTab(menus[0]);
      }
    } else {
      setTab(menus[0]);
      const tabDefault = menus[0]?.key;
      if (tabDefault) {
        history.push(`?tab=${tabDefault}`);
      }
    }
  }, [menus, query.tab]);

  useEffect(() => {
    if (query.form_support === "true") {
      if (menus && menus.length > 0) {
        setTab(menus[1]);
        history.push(`?tab=${menus[1].key}&openDefault=true`);
      }
    }
  }, [menus, query.form_support]);

  useEffect(() => {
    const cateSupport = categoryFaqs?.find((el) => el.key === "support");
    const data = categoryFaqs?.filter(
      (el) => String(el.parent_id) === String(cateSupport._id)
    );
    if (!localStorage.getItem("auth")) {
      const dataPreview = data.filter((item) => item.key !== "history");
      setMenus(dataPreview);
    } else {
      setMenus(data);
    }
  }, [categoryFaqs]);

  const renderTab = () => {
    if (query.tab === "history") return <History />;
    if (query.tab && query.helpref) {
      return <Details helpref={query.helpref} />;
    }
    if (query.tab === tab?.key) {
      const category = categoryFaqs?.filter((el) => el.parent_id === tab?._id);
      if (!category.length) {
        return <Details helpref={query.tab} />;
      }
      return <QA categoryFaqs={category} />;
    }
  };

  return (
    <div className="container support-wrapper">
      <Card className="p-3 m-1">
        <h4 className="text-center text-primary fs-3">
          {categoryFaqs?.find((el) => el.key === "support")?.description}
        </h4>
        <div className="d-flex justify-content-between mt-4">
          <div className="col-6 col-md-4 position-relative">
            <VInput
              transparent={true}
              label={t("search")}
              suffix={<VIcon width={16} name="search.svg" />}
              onChange={onChangeSearch}
            />
            {search?.length > 0 && (
              <Card className="search-support">
                <div className="p-3">
                  {search.map((el) => (
                    <div
                      className="item-search"
                      onClick={() => handleSearch(el)}
                    >
                      {el.name}
                    </div>
                  ))}
                </div>
              </Card>
            )}
          </div>
          <VButton className={"btn btn-primary"} onClick={handleCreateQuestion}>
            {t("Make a question")}
          </VButton>
        </div>
      </Card>
      <div className="p-1">
        <VTab
          tab={tab}
          isSelect={true}
          menus={menus || []}
          noBackground={true}
          setTab={setTab}
        />
      </div>
      {renderTab()}
    </div>
  );
};

export default View;

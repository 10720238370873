/* eslint-disable indent */
/* eslint-disable no-useless-escape */
import Button from "components/v-button";
import React, { useState } from "react";
import { toast } from "react-toastify";
import Scanner from "react-webcam-qr-scanner";
import { useHistory } from "react-router-dom";

import { copyText } from "utils/common";

import ContainerScan, {
  ScanerImg,
  Scan,
  Line,
  ContentQr,
  Detail,
} from "./styles";
import VModal from "components/v-modal";

const View = () => {
  const history = useHistory();

  const [result, setResult] = useState({
    isLink: true,
    type_modal: null,
    value: "",
  });

  const handleScan = (data) => {
    if (String(data?.data).includes("pay")) {
      return history.push(
        `${data?.data}?token=${localStorage.getItem("auth")}`
      );
    }

    if (data !== null) {
      const textQr = data?.data?.split("-");
      if (textQr[0] === "vzxwallet" && textQr[1] && textQr[2]) {
        // eslint-disable-next-line max-len
        history.push(
          `/my/wallets/?method=withdraw_spot&stock=${textQr[1].toLowerCase()}&address=${
            textQr[2]
          }${Number(textQr[3]) > 0 && `&amountStock=${textQr[3]}`}`
        );
      } else {
        isValidURL(data.data);
      }
    }
  };

  const handleClickContent = () => {
    if (result.isLink) {
      window.open(result.value);
    } else {
      copyText(result.value);
      toast.success("Copied!");
    }
  };

  const isValidURL = (string: string) => {
    if (string !== result.value) {
      const splitedString = string.split(":");
      const prefix = splitedString[0];
      const typeofModal = splitedString[1];
      const value = splitedString[2];
      switch (prefix) {
        case "_modal_": {
          setResult({
            isLink: false,
            type_modal: typeofModal,
            value: value,
          });
          break;
        }
        default: {
          const res = string.match(
            /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
          );
          if (res) {
            setResult({
              isLink: true,
              type_modal: null,
              value: res[0],
            });
          } else {
            setResult({
              isLink: false,
              type_modal: null,
              value: string,
            });
          }
        }
      }
    }
  };

  const handleScannerLoad = (mode) => {
    console.log(mode);
  };

  const closeModalHandler = () => {
    setResult({
      isLink: false,
      type_modal: null,
      value: "",
    });
  };

  const modalRender = () => {
    switch (result.type_modal) {
      case "_product_": {
        return (
          <VModal
            isOpen={result.type_modal}
            title="Product"
            centered={true}
            onCancel={closeModalHandler}
            children={
              <iframe
                title="product"
                src={`${process.env.REACT_APP_SHOP_URL}/products/${result.value}/use`}
                style={{
                  width: "100%",
                  height: "300px",
                }}
                allow="clipboard-read; clipboard-write; microphone"
              >
                <p>Your browser does not support iframes.</p>
              </iframe>
            }
          />
        );
      }
    }
  };

  return (
    <>
      <ContainerScan>
        {result.value && !result.type_modal && (
          <ContentQr>
            <Detail isLink={result?.isLink}>
              <span>{result.value}</span>
              <Button transparent={true} onClick={handleClickContent}>
                {result?.isLink ? "Open" : "Copy"}
              </Button>
            </Detail>
          </ContentQr>
        )}
        {result.value && result.type_modal && modalRender()}
        <>
          <Scanner
            className="some-classname"
            onDecode={handleScan}
            onScannerLoad={handleScannerLoad}
            constraints={{
              audio: false,
              video: {
                facingMode: { exact: "environment" },
              },
            }}
            captureSize={{ width: 1280, height: 720 }}
          />

          <ScanerImg>
            <Scan>
              <img src="/assets/images/icons/qrScan.svg" alt="" />
              <Line></Line>
            </Scan>
          </ScanerImg>
        </>
      </ContainerScan>
    </>
  );
};

export default View;

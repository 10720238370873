/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-concat */
/* eslint-disable max-len */
/* eslint-disable indent */
import VButton from "components/v-button";

import Countdown from "components/v-countdown";
import Input from "components/input";
import Modal from "components/v-modal";
import ToolTip from "components/v-tooltip/View";
import { useState } from "react";
import {
  BsCameraVideoFill,
  BsFillGiftFill,
  BsFillImageFill,
} from "react-icons/bs";
import { FcStatistics } from "react-icons/fc";
import { TbAffiliate, TbWorld } from "react-icons/tb";
import { RiDivideFill } from "react-icons/ri";
import { toast } from "react-toastify";
import { Button, Row } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { formatNumber, getUrlImage } from "utils/common";
import { compareDateWithCur } from "utils/times";
import { getStocksPrice } from "redux/reducers/cryptos/cryptos.selector";
import CongratulationInvests from "components/v-congratulations";
import useTranslation from "hooks/useTranslation";
import { renderDate } from "utils/times";
import VDropdown from "components/v-dropdown";
import { IObject } from "types";
import { getUserProfile } from "redux/reducers/users/users.selector";
import VReward from "components/v-reward";
import InvestService from "api/invest";

type IProps = {
  _package: IObject;
};

const PackageItem = ({ _package }: IProps) => {
  const outputDefault = {
    stock: 0,
    vndb: 0,
    name_stock: "",
  };
  const { t } = useTranslation();
  const stockPrice = useAppSelector(getStocksPrice);
  const user = useAppSelector(getUserProfile);

  const [amountInvest, setAmountInvest] = useState(0);
  const [profit, setProfit] = useState(null);
  const [loading, setLoading] = useState(false);
  const [amount, setAmount] = useState(0);

  const [openModal, setOpenModal] = useState(false);
  const [term, setTerm] = useState(false);
  const [showImage, setShowImage] = useState(false);
  const [showVideo, setShowVideo] = useState(false);
  const [openModalCongratulation, setOpenModalCongratulation] = useState(false);
  const [congratulationInverst, setCongratulationInverst] = useState<any>(null);
  const [imageSelected, setImageSelected] = useState(null);
  const [videoSelected, setVideoSelected] = useState(null);

  const [showCal, setShowCal] = useState(false);
  const [output, setOutput] = useState(outputDefault);

  const { vndb } = stockPrice;

  const handleChange = (event) => {
    let amountTemp = +event;
    let profitTam = null;
    const { profits } = _package;
    if (amountTemp > 0 && profits.length > 0) {
      if (profit) {
        profitTam = profit;
      } else {
        profitTam = profits[0];
      }
      const stockTemp = (amountTemp * profitTam.percent) / 100;
      const vnexTemp =
        (stockTemp * stockPrice[profitTam.stock].vnd) / vndb?.vnd;
      setOutput({
        stock: stockTemp,
        vndb: vnexTemp,
        name_stock: profitTam.stock,
      });
      setAmount(amountTemp);
    } else {
      setOutput({
        ...output,
        stock: 0,
        vndb: 0,
      });
      setAmount(null);
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const purchaseInvestPackage = await InvestService.investPackage({
        body: {
          package_id: _package._id,
          amount: amountInvest,
          profit_index: profit?.index,
          is_term: term,
        },
      });
      setCongratulationInverst(purchaseInvestPackage);
      setOpenModal(false);
      setOpenModalCongratulation(true);
      toast.success("Congratulations on your successful investment!");
      setLoading(false);
      setAmountInvest(0);
    } catch (error) {
      setLoading(false);
      toast.error(error.errors);
    }
  };

  const handleClosed = () => {
    setOpenModal(false);
    setCongratulationInverst(null);
    setShowImage(false);
    setShowVideo(false);
    setShowCal(false);
    setLoading(false);
    setAmount(0);
    setAmountInvest(0);
  };

  const renderModal = () => {
    if (_package) {
      return (
        <div className="package-modal">
          {_package.is_term && (
            <div
              className="editor"
              dangerouslySetInnerHTML={{
                __html: _package.term,
              }}
            ></div>
          )}
          <hr />
          <div className="packages">
            {(_package.profits || []).map((p) => (
              <Button
                className={profit?.index === p.index ? "active" : ""}
                color="info"
                onClick={() => setProfit(p)}
              >
                {p.time_lock} {_package.type_lock}s
              </Button>
            ))}
          </div>
          <label>Amount {String(_package.invest.stock).toUpperCase()}</label>
          <Input
            name="amountFrom"
            isCurrency={true}
            value={amountInvest}
            placeholder="0.00"
            onValueChange={(e) => setAmountInvest(e)}
          />
          {_package.is_term && (
            <div className="accept_term">
              <input
                type="checkbox"
                checked={term}
                onChange={(e) => setTerm(e.target.checked)}
              />
              {t("I have read and agree to the terms of the project")}
            </div>
          )}
          <VButton
            disabled={!term || !amountInvest}
            onClick={handleSubmit}
            loading={loading}
          >
            {t("TRUST - JOIN PROJECT")}
          </VButton>
        </div>
      );
    }
  };

  const renderCountDown = (p) => {
    let time = 0;
    if (p.time.start_date && compareDateWithCur(p.time.start_date) > 0) {
      time = new Date().getTime() + compareDateWithCur(p.time.start_date);
    } else {
      time = new Date().getTime() + compareDateWithCur(p.time.end_date);
    }
    return (
      <div className="row">
        <div className="col-6">
          <p className="from">
            {t("From")} : {renderDate(p.time.start_date)}
          </p>
          <p>
            {t("To")} : {renderDate(p.time.end_date)}
          </p>
        </div>
        <div className="col-6">
          <Countdown date={time} />
        </div>
      </div>
    );
  };

  const renderReward = () => {
    return (
      <div className="package-tooltip">
        <VReward reward={_package.reward} />
      </div>
    );
  };

  const renderAffilate = (p: any) => {
    return (
      <div className="package-tooltip">
        {Object.keys(p.affiliates || {}).map((key) => {
          const reward = p.affiliates[key];
          return (
            <>
              <div>Level : {String(key).split("f")[1]}</div>
              <hr />
              <VReward reward={reward} />
              <br />
            </>
          );
        })}
      </div>
    );
  };

  const renderStatis = (p: any) => {
    const amountJoin = (p.investors || []).filter((u) => u === user._id).length;
    return (
      <div className="package-tooltip">
        <div>
          <strong>{t("You join project")}: </strong>{" "}
          {formatNumber(amountJoin, 0)}/
          {formatNumber(p.require.max_per_user, 0) || 0}
        </div>
        <hr />
        <div>
          <strong>{t("Total package")}:</strong>{" "}
          {formatNumber(p.amount_invested, 0) || 0}/
          {formatNumber(p.require.total_package, 0)}
        </div>
        <hr />
      </div>
    );
  };

  const renderImage = (image) => {
    return (
      <div className="package-modal">
        <div className="image">
          <img className="img-info" src={getUrlImage(image)} alt="" />
        </div>
      </div>
    );
  };

  const renderVideo = (video) => {
    return (
      <div className="package-modal">
        <div className="video-responsive">
          <iframe
            width="560"
            height="315"
            src={`https://www.youtube.com/embed/${video}`}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen={true}
          ></iframe>
        </div>
      </div>
    );
  };

  const renderCal = () => {
    if (_package) {
      return (
        <div className="package-modal">
          <Row>
            <div className="packages">
              {(_package.profits || []).map((p) => (
                <Button
                  className={profit?.index === p.index ? "active" : ""}
                  color="info"
                  onClick={() => {
                    setAmount(0);
                    setProfit(p);
                  }}
                >
                  {p.time_lock} {_package.type_lock}s
                </Button>
              ))}
            </div>
            <Input
              label="Amount"
              name="amount"
              isCurrency={true}
              value={amount}
              onValueChange={(e) => handleChange(e)}
            />
            <div className="btn-swap-claim">
              <Button children="=>" />
            </div>
            <div className="output">
              <span>Total profits: </span>
              <div>
                ~ {formatNumber(output ? output?.stock : 0)}{" "}
                {String(output.name_stock || "").toUpperCase()}
              </div>
              <div>~ {formatNumber(output?.vndb)} VNDB</div>
            </div>
          </Row>
        </div>
      );
    }
  };

  return (
    <>
      <div className="package-item col-12 col-md-6">
        <div className="package-status">
          <span className={`${_package.status} status`}>{_package.status}</span>
        </div>
        {_package.promotionImage && (
          <div className="package-promotion">
            {/* {_package.time?.promotion_date &&
        renderCountDownPromotion(_package.time?.promotion_date)} */}
            <div className="promotion-image">
              <img src={getUrlImage(_package.promotionImage)} alt="" />
            </div>
          </div>
        )}
        <div
          className="package-bg"
          style={{
            backgroundImage: `url(${getUrlImage(_package.background)})`,
          }}
        >
          <div className="package-image">
            <img
              src={
                getUrlImage(_package.icon) ||
                "/assets/images/icons/_package.png"
              }
              alt=""
            />
          </div>
          <div className="package-amount">
            {`${formatNumber(_package.invest.from, 0)} - ${formatNumber(
              _package.invest.to,
              0
            )} ${String(_package.invest.stock).toUpperCase()}`}{" "}
          </div>
          <div className="package-introduce">
            <p>{_package.name}</p>
            <span>{_package.description}</span>
          </div>
          <div className="package-countdown">{renderCountDown(_package)}</div>
          <hr />

          <div className="package-profit">
            <div className="trust-time">
              <div className="title">{t("Choose time trust")}</div>
              <VDropdown
                placeholder={t("Choose time")}
                value={profit?.index}
                options={(_package.profits || []).map((item) => ({
                  label: `${item.time_lock} ${_package.type_lock}s - ${item.percent}%`,
                  value: item.index,
                }))}
                onChange={(e: any) => {
                  setProfit(
                    (_package.profits || []).find(
                      (p) => p.index === e.target.value
                    )
                  );
                }}
              />
              {profit && (
                <div className="trust-detail">
                  <p>
                    {t("Trust time")} :{" "}
                    <span>
                      {profit?.time_lock} {_package.type_lock}s
                    </span>
                  </p>
                  <p>
                    {t("Min Claim Reward")}:{" "}
                    <span>
                      {formatNumber(_package.require.min_withdraw_profit, 0)}{" "}
                      {String(profit?.stock).toUpperCase()}
                    </span>
                  </p>
                </div>
              )}{" "}
            </div>
            <hr />
            <div className="trust-reward">
              <p className="title">{t("Trust Reward")}</p>
              {profit && (
                <div className="trust-detail">
                  <p>
                    Total:
                    <span>
                      {" "}
                      {`${formatNumber(
                        (+profit.percent * +_package.invest.from) / 100
                      )} => ${formatNumber(
                        (+profit.percent * +_package.invest.to) / 100
                      )}`}
                    </span>
                  </p>
                  <p>
                    {_package.type_lock === "month"
                      ? "Monthly"
                      : _package.type_lock === "day"
                      ? "Bonus daily"
                      : "Weekly"}
                    :
                    <span>
                      {`${formatNumber(
                        (profit.percent * _package.invest.from) /
                          +profit.time_lock /
                          100,
                        3
                      )} => ${formatNumber(
                        (profit.percent * _package.invest.to) /
                          +profit.time_lock /
                          100,
                        3
                      )} `}
                    </span>
                  </p>
                </div>
              )}
            </div>
            <hr />
            <div className="trust-info">
              <ToolTip
                id={`${_package._id}_gift`}
                content={renderReward()}
                placement="top"
              >
                <span id={`tooltip-` + `${_package._id}_gift`} className="gift">
                  <BsFillGiftFill size={20} className="reward" />
                </span>
              </ToolTip>
              <ToolTip
                id={`${_package._id}_aff`}
                content={renderAffilate(_package)}
                placement="top"
              >
                <span id={`tooltip-` + `${_package._id}_aff`} className="gift">
                  <TbAffiliate size={22} />
                </span>
              </ToolTip>
              <ToolTip
                id={`${_package._id}_statis`}
                content={renderStatis(_package)}
                placement="top"
              >
                <span
                  id={`tooltip-` + `${_package._id}_statis`}
                  className="gift"
                >
                  <FcStatistics size={25} />
                </span>
              </ToolTip>
              <ToolTip
                id={`${_package._id}_image`}
                content={renderImage(_package.image)}
                placement="top"
              >
                <span
                  id={`tooltip-` + `${_package._id}_image`}
                  className="gift"
                  onClick={() => {
                    setImageSelected(_package.image);
                    setShowImage(true);
                  }}
                >
                  <BsFillImageFill size={20} />
                </span>
              </ToolTip>
              <ToolTip
                id={`${_package._id}_video`}
                content={"Click view video"}
                placement="top"
              >
                <span
                  id={`tooltip-` + `${_package._id}_video`}
                  className="gift"
                  onClick={() => {
                    setVideoSelected(_package.video);
                    setShowVideo(true);
                  }}
                >
                  <BsCameraVideoFill size={25} />
                </span>
              </ToolTip>
              <ToolTip
                id={`${_package._id}_cal`}
                placement="top"
                content={"Click view investment returns"}
              >
                <span
                  id={`tooltip-` + `${_package._id}_cal`}
                  className="gift"
                  onClick={() => {
                    setShowCal(true);
                  }}
                >
                  <RiDivideFill size={25} />
                </span>
              </ToolTip>
              <ToolTip
                id={`${_package._id}_website`}
                content={_package.website}
                placement="top"
              >
                <span
                  id={`tooltip-` + `${_package._id}_website`}
                  className="gift"
                  onClick={() => {
                    window.open(_package.website);
                  }}
                >
                  <TbWorld size={18} />
                </span>
              </ToolTip>
            </div>
            <div className="trust-object">
              Priority for :{" "}
              {String(_package.objects.toString()).replaceAll(",", ", ")}
            </div>
          </div>
          <hr />

          <div className="package-footer">
            <Button
              onClick={() => {
                setOpenModal(true);
              }}
              disabled={
                compareDateWithCur(_package.time.start_date) > 0 ? true : false
              }
              className="btn-glow"
            >
              {t("TRUST - JOIN PROJECT")}
            </Button>
          </div>
        </div>
      </div>
      <Modal
        title={_package.name}
        isOpen={openModal}
        children={renderModal()}
        onCancel={handleClosed}
        centered={true}
      />
      <Modal
        isOpen={showImage}
        children={renderImage(imageSelected)}
        onCancel={handleClosed}
        centered={true}
      />
      <Modal
        isOpen={showVideo}
        children={renderVideo(videoSelected)}
        onCancel={handleClosed}
        centered={true}
      />
      <Modal
        isOpen={showCal}
        children={renderCal()}
        onCancel={handleClosed}
        centered={true}
      />
      <CongratulationInvests
        type="investsSuccess"
        background={congratulationInverst?.package_id?.backgroundSuccess}
        userName={congratulationInverst?.user?.fullName}
        avataUser={congratulationInverst?.user?.avatar}
        logoProject={congratulationInverst?.package_id?.icon}
        logoLevel={congratulationInverst?.package_id?.logoLevel}
        amount={congratulationInverst?.investment?.amount}
        symbol={congratulationInverst?.package_id?.invest?.stock}
        createdAt={congratulationInverst?.createdAt}
        openModalCongratulation={openModalCongratulation}
        setOpenModalCongratulation={setOpenModalCongratulation}
      />
    </>
  );
};

export default PackageItem;

import { Card } from "react-bootstrap";
import VDropdown from "components/v-dropdown";
import { Controller } from "react-hook-form";
import VInput from "components/v-input";
import useTranslation from "hooks/useTranslation";
import { getUrlImage } from "utils/common";

type IProps = {
  id: string;
  label?: string;
  error?: string;
  errorOther?: string;
  type?: string;
  placeholder?: string;
  value?: any;
  options?: any;
  required?: boolean;
  isMulti?: boolean;
  hasOtherAnswer?: boolean;
  field?: any;
  control?: any;
  nameOther?: string;
  image?: string;
};

const View = ({
  id = "",
  label = "",
  error = "",
  errorOther = "",
  value = "",
  placeholder = "",
  options = null,
  required = false,
  isMulti = false,
  hasOtherAnswer = false,
  control,
  nameOther,
  field,
  image,
}: IProps) => {
  const { t } = useTranslation();
  const { value: prarent_value } = field;

  return (
    <Card border="success" className="mb-4 question_item_card" id={id}>
      {label && (
        <p className="text-sm mb-2">
          <b>{label}</b> {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div>
        {image && (
          <img
            style={{
              height: "200px",
              width: "auto",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
            src={getUrlImage(image, 1000)}
            alt="sub"
          />
        )}
      </div>
      <VDropdown
        isMulti={isMulti}
        value={value}
        placeholder={placeholder}
        options={
          hasOtherAnswer
            ? [...options, { label: t("Lựa chọn khác"), value: nameOther }]
            : options
        }
        {...field}
      />

      {error && <p className="_error mb-0">{error}</p>}

      {hasOtherAnswer && prarent_value === nameOther && (
        <Controller
          name={nameOther}
          control={control}
          rules={{ required: required }}
          render={({ field }) => {
            return (
              <VInput
                className="mt-1"
                id={`question_${nameOther}`}
                type="text"
                errMessage={errorOther}
                placeholder={t("Nhập lựa chọn khác")}
                {...field}
              />
            );
          }}
        />
      )}
    </Card>
  );
};

export default View;

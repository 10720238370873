/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import Card from "components/v-teamplate/Card";
import { useAppSelector } from "redux/reducers/hook";
import { useEffect, useState } from "react";
import useQuery from "hooks/useQuery";
import VTab from "components/v-tab";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import ChooseService from "./components/choose-service";
import ComingSoon from "pages/coming-soon/comingsoon";
import BillManagement from "./components/bill-management";
import { getUserProfile } from "redux/reducers/users/users.selector";
import Error404 from "components/v-error-404";

const View = () => {
  const dataUser: any = useAppSelector(getUserProfile);
  const menus = useAppSelector(getMenusByKey("tab_settings_invoices"));
  const query = useQuery();
  const [tab, setTab] = useState(null);
  const [loadMenu, setLoadMenu] = useState(false);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  const renderTab = () => {
    if (tab) {
      if (tab.key === "type_of_service") return <ChooseService />;
      if (tab.key === "bill_management") return <BillManagement />;
    }
    return <ComingSoon />;
  };

  if (dataUser?.status_kyc !== "verified" && dataUser?.status_kyc !== "kyc") return <Error404 />

  return (
    <>
      <VTab tab={tab} setTab={setTab} menus={menus} />
      <Card isOpacity={true} className={"px-2"}>
        {renderTab()}
      </Card>
    </>
  );
};

export default View;

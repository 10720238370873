import React from "react";
import { useParams } from "react-router-dom";
import ModalWebModule from "modals/web-modules";

const EmbedPost = () => {
  const { id } = useParams();

  return (
    <ModalWebModule
      openWebModuleKey="post_detail"
      extraQuery={{
        preview: true
      }}
      postDetail={{
        _id: id
      }}
    />
  );
};

export default EmbedPost;

import { request, parseErrorResponse } from "./request";

const getDepartmentByUserId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/departments/${req.params.user_id}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const addMemberToDepartment = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/departments/addMemberToDepartment`, req.body)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMemberInDepartment = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/departments/getMemberInDepartment/${req.params.departmentId}`)
      .then((res: any) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
const DepartmentService = {
  getDepartmentByUserId,
  addMemberToDepartment,
  getMemberInDepartment
};

export default DepartmentService;

/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import VButton from "components/v-button";
import VForm from "components/v-form";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { toast } from "react-toastify";
import { setProfile } from "redux/reducers/users/users.reducer";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import SearchService from "api/categories";
import TreeSelectComponent from "components/v-select";
import RegisterReceiveInfo from "api/register-receive-info";

const Register = ({
  formAdditionalData,
  onHide,
  category,
}: {
  formAdditionalData: any;
  onHide: () => void;
  category?: string;
}) => {
  const [listFiles, setListFiles] = useState<any>({});
  const [additionalData, setAdditionalData] = useState<any>({});
  const [validate, setValidate] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);
  const [categories, setCategories] = useState<any[]>([]);
  const { t } = useTranslation();
  const user = useAppSelector(getUserProfile);
  const dispatch = useAppDispatch();
  const menus: any = useAppSelector(getMenusByKey("censor_register_policy"));

  useEffect(() => {
    getCategories();
  }, []);

  useEffect(() => {
    if (category)
      setAdditionalData((state) => ({
        ...state,
        categories: [category],
      }));
  }, [category]);

  useEffect(() => {
    const check = () => {
      const listRequied = formAdditionalData?.fields?.filter(
        (el) => el.required
      );

      for (let index = 0; index < listRequied?.length; index++) {
        const item = listRequied[index];
        if (!additionalData[item.label] && !listFiles[item.label]) {
          return false;
        }
      }
      return true;
    };
    setIsValid((_) => check());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalData, listFiles]);

  useEffect(() => {
    if (user.register_receive_info) {
      setAdditionalData({
        location: user.register_receive_info?.location,
        description: user.register_receive_info?.description,
        categories: user.register_receive_info?.categories,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  const getCategories = async () => {
    try {
      const res: any = await SearchService.getByGroup({
        query: {
          group: "product_category_normal",
        },
      });
      if (res) {
        setCategories(res);
      }
    } catch (error) {}
  };

  const setFile = (f: any, id: string, type?: string, idFile?: string) => {
    if (type === "mutil") {
      return setListFiles((prev) => ({
        ...prev,
        [id]: {
          ...prev?.[id],
          [idFile]: f,
        },
      }));
    }
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const handleSubmit = async () => {
    if (isPolicy) {
      try {
        let response;
        if (
          user.register_receive_info &&
          (user.register_receive_info.status === "pending" ||
            user.register_receive_info.status === "denied")
        ) {
          response = await RegisterReceiveInfo.update({
            params: { id: user.register_receive_info._id },
            body: additionalData,
          });
          dispatch(
            setProfile({
              register_receive_info: {
                ...user.register_receive_info,
                ...additionalData,
              },
            })
          );
        } else {
          const res = await RegisterReceiveInfo.create({
            body: additionalData,
          });
          dispatch(setProfile({ register_receive_info: res }));
          response = "Success";
        }
        toast.success(t(t(response)));
      } catch (error) {
        toast.error(t(t("Something wen't wrong.")));
      }
    }
  };

  const recursiveCates = useMemo(() => {
    if (categories && categories.length > 0) {
      const convertToTree = (arr: Array<any>, parent_id?: string) => {
        let tree = [];
        for (let i = 0; i < arr.length; i++) {
          if (arr[i]?.parent_id === parent_id) {
            arr[i].value = String(arr[i]?._id);
            arr[i].label = arr[i].name;
            arr[i].icon = arr[i].icon;
            let children = convertToTree(arr, arr[i]?._id);
            if (children.length > 0) {
              arr[i].children = children;
            }
            tree.push(arr[i]);
          }
        }
        return tree;
      };
      const parents = categories.filter((c) => !c.parent_id);
      const _allChild = categories.filter((c) => c.parent_id);
      return parents.map((c) => ({
        ...c,
        value: String(c._id),
        label: c.name,
        children: convertToTree(_allChild, c._id),
      }));
    }
    return [];
  }, [categories]);

  return (
    <div className="form-register">
      {formAdditionalData && formAdditionalData.fields?.length > 0 && (
        <div className="row">
          <VForm
            fields={formAdditionalData?.fields?.map((i) => {
              if (i.typeField === "location") {
                return { ...i, col: 2 };
              }
              return i;
            })}
            additionalData={additionalData}
            listFiles={listFiles}
            validate={validate}
            setListFiles={setListFiles}
            setValidate={setValidate}
            setIsValid={setIsValid}
            setFile={setFile}
            setAdditionalData={setAdditionalData}
          />
        </div>
      )}
      <TreeSelectComponent
        id={`shop-product_category_id`}
        className="!rounded-md !bg-white my"
        value={additionalData.categories}
        options={recursiveCates}
        isCustom
        onChange={(e) => {
          setAdditionalData((state) => ({
            ...state,
            categories: e,
          }));
        }}
        classNameLabel="text-md mb-2 block"
        label={
          <span>
            {t("Danh mục niêm yết")} <span className="text-red-400">*</span>
          </span>
        }
        allowClear
        multiple
      />
      <div>
        <p style={{ margin: 0 }}>
          <b>{t("Danh mục niêm yết")}</b>
        </p>
        <p>
          {t("Chi phí/lượt thông báo:")} <span></span>
        </p>
      </div>
      <div className="d-flex">
        <div>
          <input
            className="form-check-input me-2"
            type="checkbox"
            onChange={(e) => setIsPolicy(e.target.checked)}
          />
        </div>
        <label className="form-check-label" htmlFor="customCheck1">
          {t(menus[0]?.description)}{" "}
          <a href={menus[0]?.url} target="_blank" rel="noreferrer">
            {t(menus[0]?.name)}
          </a>
        </label>
      </div>
      <div className="submit-register">
        {user.object_type !== "COMMUNITY" ? (
          <div className="d-flex gap-3 mt-3">
            <VButton className="cancel" onClick={onHide} loading={false}>
              {t("Cancel")}
            </VButton>
            <VButton
              className="send"
              onClick={handleSubmit}
              loading={false}
              disabled={!isValid || !isPolicy}
            >
              {t("Send")}
            </VButton>
          </div>
        ) : (
          <VButton onClick={onHide}>{t("Back")}</VButton>
        )}
      </div>
    </div>
  );
};

export default Register;

/* eslint-disable no-empty-pattern */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IAuthState {
  noticePopup: {
    type: string,
    amount: number
  },
  connectSocket: boolean;
  qr_code_login: string;
}

const initialState: IAuthState = {
  noticePopup: {
    type: "",
    amount: 0
  },
  connectSocket: false,
  qr_code_login: '',
};

export const registerSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setNoticePopup: (state: IAuthState, action: PayloadAction<{
      type: string,
      amount: number
    }>) => {
      state.noticePopup = action.payload;
    },
    setConnectSocket: (state: IAuthState, action: PayloadAction<boolean>) => {
      state.connectSocket = action.payload;
    },
    setQrCodeLogin: (state: IAuthState, action: PayloadAction<string>) => {
      state.qr_code_login = action.payload;
    },
  },
  extraReducers: {}
});

export const { setNoticePopup, setConnectSocket, setQrCodeLogin } = registerSlice.actions;

export default registerSlice.reducer;

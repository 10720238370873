/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import SearchService from "api/search";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";
import { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import debounce from "lodash.debounce";
import VTrendingCard from "components/v-objects/v-trending-card";
import VInfiniteLoad from "components/v-infinite-load";

const Trending = () => {
  const query = useQuery();
  const { t } = useTranslation();
  const [lazyParams, setLazyParams] = useState({
    limit: 10,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const getData = async (search, lazyParams) => {
    if (search?.length > 1) {
      setLoading(true);
      const res: any = await SearchService.trending({
        query: {
          key: search,
        },
      });
  
      if (res && res.data) {
        // if (res.page === 1) {
        setData([...res.data]);
        // } else {
        //   setData((prev) => prev.concat(res.docs));
        // }
        setTotalPages(res?.length / 10);
      }
      setLoading(false);
    }
  };

  const onSearch = useCallback(debounce(getData, 500), []);

  useEffect(() => {
    // if (query.search) {
    setLoading(true);
    setLazyParams((prev) => ({
      ...prev,
      page: 1,
    }));
    setTotalPages(1);
    onSearch(query.search, {
      page: 1,
      limit: 10,
    });
    // } else {
    //   setData([]);
    // }
  }, [query.search]);

  const handleViewMore = () => {
    setLoading(true);
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
    onSearch(query.search, {
      ...lazyParams,
      page: lazyParams.page + 1,
    });
  };

  return (
    <Card className="card-common p-3">
      <div className="container">
        <div className="title fw-bold font-size-16">{t('Trending for you')}</div>
        <VInfiniteLoad
          onLoadMore={handleViewMore}
          canLoadMore={lazyParams.page < totalPages}
          isLoadingMore={loading}
        >
          <div className="row">
            {data?.length > 0
              ? data.map((item, index) => {
                  return (
                    <VTrendingCard
                      key={index}
                      col_lg={12}
                      col_md={12}
                      trend={item}
                    />
                  );
                })
              : !loading && (
                  <span className="text-center w-100">
                    {t("No result is found")}
                  </span>
                )}
          </div>
        </VInfiniteLoad>
      </div>
    </Card>
  );
};

export default Trending;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import useQuery from "hooks/useQuery";

import VBannerPage from "components/v-teamplate/v-banner-page";
import StarService from "api/stars";
import { useHistory } from "react-router-dom";
import BadgesDetail from "./details";
import VIcon from "components/v-icon";
import { ROUTES } from "config/routes";
import useTranslation from "hooks/useTranslation";
import VRightView from "components/v-right-view";
import APIs from "api";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const ProfileBadgesMascots = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const query = useQuery();
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  const [data, setData] = useState([]);
  const [star, setStar] = useState([]);
  const [feelings, setFeelings] = useState([]);
  const [mascots, setMascots] = useState<any[]>([]);

  const isRouterMascots = window.location.pathname === ROUTES.PROFILE_MASCOTS;

  const getAllStar = async () => {
    try {
      const res: any = await StarService.getAll();
      if (res) {
        setData(res);
        const star = res.filter(
          (el) => el.type === "star" && el.status === "enable"
        );
        const feelings = res.filter(
          (el) => el.type === "feeling" && el.status === "enable"
        );
        setStar(star);
        setFeelings(feelings);
      }
    } catch (error) {}
  };

  const getMascots = async () => {
    try {
      const res: any = await APIs.PRODUCT.getProductInStorageByType({
        query: {
          type: "mascot",
        },
      });
      if (res && res?.docs) {
        setMascots(res.docs);
        setData(res.docs);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isRouterMascots) {
      getMascots();
    } else {
      getAllStar();
    }
  }, [window.location.pathname]);

  return (
    <>
      <Container>
        <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
          <VBannerPage
            position={
              isRouterMascots
                ? "banner_top_profile_mascots"
                : "banner_top_profile_badges"
            }
          />
          {query?.detail ? (
            <>
              <div
                className="mt-2"
                onClick={() =>
                  history.push(isRouterMascots ? "" : ROUTES.PROFILE_BADGES)
                }
              >
                <VIcon name="left-arrow.svg" />
              </div>
              <div className="mt-5 mb-2 text-center">
                <BadgesDetail
                  data={data?.find((el) => el._id === query?.detail)}
                  show_descrition={true}
                />
              </div>
            </>
          ) : isRouterMascots ? (
            <Row className="mt-2">
              {mascots?.length > 0 && (
                <>
                  <div id="mascots_page" className="mb-3">
                    <div className="utilities">
                      <div className="category-name-container border-bottom border-3">
                        <div className="category-name px-5 py-2 text-white fit position-relative">
                          <span className="name-utilities">{t("Mascot")}</span>
                          <div className="slash"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {mascots.map((el) => (
                    <div key={el._id} className="col-lg-4 col-md-6 mt-5 mb-2">
                      <BadgesDetail data={el} />
                    </div>
                  ))}
                </>
              )}
            </Row>
          ) : (
            <>
              <Row className="mt-2">
                {star?.length > 0 && (
                  <>
                    <div id="utilities_page" className="mb-3">
                      <div className="utilities">
                        <div className="category-name-container border-bottom border-3">
                          <div className="category-name px-5 py-2 text-white fit position-relative">
                            <span className="name-utilities">{t("Star")}</span>
                            <div className="slash"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {star.map((el) => (
                      <div key={el._id} className="col-lg-4 col-md-6 mt-5 mb-2">
                        <BadgesDetail data={el} />
                      </div>
                    ))}
                  </>
                )}
              </Row>
              <Row>
                {feelings?.length > 0 && (
                  <>
                    <div id="utilities_page" className="mb-3">
                      <div className="utilities">
                        <div className="category-name-container border-bottom border-3">
                          <div className="category-name px-5 py-2 text-white fit position-relative">
                            <span className="name-utilities">
                              {t("Feelings")}
                            </span>
                            <div className="slash"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {feelings.map((el) => (
                      <div key={el._id} className="col-lg-4 col-md-6 mt-5 mb-2">
                        <BadgesDetail data={el} />
                      </div>
                    ))}
                  </>
                )}
              </Row>
            </>
          )}
        </Col>
        {show_banner_right_on_container && (
          <Col lg={4} className={"d-none d-lg-block px-1"}>
            <VRightView
              menuKey={
                isRouterMascots
                  ? "tab_right_profile_mascots"
                  : "tab_right_profile_badges"
              }
              positionBanner={
                isRouterMascots
                  ? "banner_right_profile_mascots"
                  : "banner_right_profile_badges"
              }
            />
          </Col>
        )}
      </Container>
    </>
  );
};

export default ProfileBadgesMascots;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { FC, useEffect, useRef, useState } from "react";

import { useDispatch } from "react-redux";
import { setPostViewTemplate } from "redux/reducers/ui-settings/ui-settings.reducer";

import { Preview } from "vdiarybook-lidojs/editor";

import { sleep } from "utils/common";
import { toPng } from "html-to-image";

import { useLocation } from "react-router-dom";

interface PreviewModalProps {
  serialized: any[];
}

const PreviewModal: FC<PreviewModalProps> = ({ serialized }) => {
  const previewRef = useRef<HTMLDivElement>(null);

  const [curPage, setCurPage] = useState<number>(1);
  const [curPageElement, setCurPageElement] = useState<HTMLElement>();
  const [slideElements, setSlideElements] = useState([]);

  const dispatch = useDispatch();
  const location: any = useLocation();

  const handleClose = async () => {
    previewRef.current.classList.remove("active");
    await sleep(300);
    dispatch(
      setPostViewTemplate({ serialized: [], isOpenTemplatePreview: false })
    );
  };

  const handleDownloadSlide = async () => {
    const dataUrl = await toPng(curPageElement);

    const link = document.createElement("a");
    link.href = dataUrl;
    link.download = "image.png";
    link.click();
    link.remove();
  };

  const handleGoToSlide = (index: number) => {
    const slides: any = document.querySelectorAll(
      "#modal-preview-template > div > div:nth-child(3) > div:first-child > div"
    );

    Array.from(slides).forEach((item: any, slideIdx: number) => {
      const slide = item.querySelectorAll(":scope > div:nth-child(1)")[0];
      slide.style.visibility = slideIdx === index ? "visible" : "hidden";
      slide.style.opacity = slideIdx === index ? "1" : "0";
    });
  };

  const addButtonStyle = () => {
    // add class for btn prev, next
    const previewChildDiv: any = previewRef.current?.querySelectorAll(
      ":scope > div:nth-child(1) > div"
    );

    if (previewChildDiv) {
      [0, 1].forEach((index) => {
        if (previewChildDiv[index]) {
          previewChildDiv[index]
            .querySelectorAll(":scope > div:nth-child(1)")[0]
            .classList?.add(serialized?.length > 1 ? "btn-slide" : "d-none");
        }
      });

      previewChildDiv[2].classList?.add(
        location?.pathname === "/" ? "zoom-90" : "zoom-70"
      );
    }

    // add class active
    previewRef.current.classList.add("active");
  };

  const getSlideElements = async () => {
    const slides: any = document.querySelectorAll(
      "#modal-preview-template > div > div:nth-child(3) > div:first-child > div"
    );

    const cloneElements = [];

    Array.from(slides).forEach((slide: any, idx: number) => {
      const clonedSlide = slide
        .querySelectorAll(":scope > div:nth-child(1)")[0]
        .cloneNode(true);
      clonedSlide.style.visibility = "visible";
      clonedSlide.style.opacity = "1";
      if (idx > 4) {
        return;
      } else {
        cloneElements.push(clonedSlide);
      }
    });

    setSlideElements(cloneElements);
  };

  const listenDOMChange = () => {
    if (!serialized || serialized?.length < 2) return;

    // pagination bar
    const intervalId = setInterval(() => {
      const slides: any = document.querySelectorAll(
        "#modal-preview-template > div > div:nth-child(3) > div:first-child > div"
      );

      for (let i = 0; i < slides.length; i++) {
        const slide = slides[i].querySelectorAll(":scope > div")[0];

        const styles = window.getComputedStyle(slide);
        const visibility = styles.getPropertyValue("visibility");

        if (visibility === "visible") {
          setCurPageElement(slide);
          setCurPage(i + 1);
        }
      }
    }, 200);

    return intervalId;
  };

  useEffect(() => {
    sleep(1).then(() => {
      addButtonStyle();
      getSlideElements();
    });
  }, []);

  useEffect(() => {
    const intervalId = listenDOMChange();
    return () => clearInterval(intervalId);
  }, [serialized]);

  return (
    <div ref={previewRef} id="modal-preview-template">
      <Preview />
      <div className="btn-close" onClick={handleClose}>
        <img
          src="/assets/icons/default/cancel.svg"
          alt="cancel-icon"
          width={16}
          height={16}
        />
      </div>

      {/* tool */}
      <div className="tool-wrapper">
        {/* slides */}
        <div className="tool-wrapper__image-list">
          {slideElements.map((slide, idx) => (
            <div
              key={idx}
              onClick={() => handleGoToSlide(idx)}
              className={`tool-wrapper__image-dom ${
                curPage === idx + 1 ? "active-dom" : ""
              }`}
              dangerouslySetInnerHTML={{ __html: slide.innerHTML }}
            />
          ))}
        </div>

        {/* pagination bar */}
        <div className="tool-wrapper__pagination-bar">
          {curPage} / {serialized?.length || 1}
        </div>

        {/* download button */}
        <button
          className="tool-wrapper__download-btn"
          onClick={handleDownloadSlide}
        >
          <img
            src="/assets/icons/default/download.svg"
            alt="cancel-icon"
            width={16}
            height={16}
          />
        </button>
      </div>
    </div>
  );
};

export default PreviewModal;

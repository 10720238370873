import VAvatar from "components/v-avatar";
import VIcon from "components/v-icon";
import useTranslation from "hooks/useTranslation";

type IProps = {
  data: any;
  isShowSeleted?: boolean;
  onDelete: (value: any) => void;
};

const View = ({ isShowSeleted = true, data, onDelete }: IProps) => {
  const { t } = useTranslation();

  return (
    <div className="list-wrapper">
      {isShowSeleted && (
        <p className="list-selected-text">
          {t("Selected")}
          <span className="list-highlight-text">{` ${data.length}/50`}</span>
        </p>
      )}
      <div className="list-selected">
        {data &&
          data.length > 0 &&
          data.map((item, index) => (
            <div className="list-selected-item px-2" key={index}>
              <VAvatar
                size={"50px"}
                userData={item}
                rezise={50}
                className="m-0"
              />
              <VIcon name="close.svg" onClick={() => onDelete(item)} />
            </div>
          ))}
      </div>
    </div>
  );
};

export default View;

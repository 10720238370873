import { IRootState } from "redux/reducers";

export const getUserProfile = (state: IRootState) => ({
  ...state.users.profile,
});
export const getToken = (state: IRootState) => state.users.token;
export const getIsLoggedIn = (state: IRootState) => state.users.isLoggedIn;
export const getBalances = (state: IRootState) => state.users.balances;
export const getLockedBalances = (state: IRootState) =>
  state.users.locked_balances;
export const getSharings = (state: IRootState) => state.users.sharings;
export const getVerify = (state: IRootState) => state.users.verify;
export const getSalesStatistics = (state: IRootState) =>
  state.users.sales_statistics;
export const getUserAddressAndCareer = (state: IRootState) =>
  state.users.user_for_news;
export const getPermissions = (state: IRootState) => state.users.permissions;
export const getRelationRequesting = (state: IRootState) =>
  state.users.relation_requesting;
export const getRelations = (state: IRootState) => state.users.relations;
export const getDataAccessOwner = (state: IRootState) => state.users.dataAccess;
export const getDataUser = (state: IRootState) => state.users.dataUser;
export const getAuthSessions = (state: IRootState) => state.users.authSessions;
export const getProfile = (state: IRootState) => ({
  ...state.users.profile,
});
export const getUserOnline = (state: IRootState) => state.users.userOnline;

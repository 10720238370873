/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useEffect, useState } from "react";
import { ROUTES } from "config/routes";
import { Link, useHistory, useLocation } from "react-router-dom";
import VButton from "components/v-button";
import AuthAPI from "api/auth";
import Card from "components/v-teamplate/Card";
import FooterForm from "../components/footer-form";
import VInput from "components/v-input";
import HeaderForm from "../components/header-form";
import { toast } from "react-toastify";
import { REGEX_EMAIL } from "utils/validates";
import useTranslation from "hooks/useTranslation";
import VIcon from "components/v-icon";
import VCapcha from "components/v-capcha";
import useQuery from "hooks/useQuery";
import APIs from "api";
import { useSelector } from "react-redux";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";
// import { useSelector } from "react-redux";
// import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";
// import { useSelector } from "react-redux";
// import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";

function View() {
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const [state, setState] = useState({
    email: "",
    password: "",
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [validate, setValidate] = useState({
    email: null,
    password: null,
  });
  const [isValid, setIsValid] = useState(false);
  const { t } = useTranslation();
  const [captcha, setCaptcha] = useState<any>();
  const { captcha_status } = useSelector(getUISettingState);
  const query = useQuery();
  const [isVerifyCaptcha, setIsVerifyCaptcha] = useState({
    verify: false,
    show: false,
  });

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        onSubmit();
        event.preventDefault();
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  useEffect(() => {
    handleGetCaptcha();
  }, []);

  const handleGetCaptcha = async () => {
    try {
      const res = await APIs.CAPTCHA.findOneByOption({
        body: {
          path: location.pathname,
        },
      });
      if (res) {
        setCaptcha(res);
      }
    } catch (error) {}
  };

  const handleFastRegister = () => {
    history.push(ROUTES.FAST_REGISTER);
  };

  const handleLoginQr = () => {
    history.push(ROUTES.LOGIN_QR);
  };

  const handleChange = (name, value) => {
    let _state = state;
    _state[name] = value;
    setState(_state);
    performValidate([name], _state);
  };

  const performValidate = (props = [], currentDetail = null) => {
    let _result = validate;
    let _detail = currentDetail || state;
    let _isValid = true;
    if (props.length === 0) {
      for (const property in _result) {
        props.push(property);
      }
    }
    props.forEach((prop) => {
      switch (prop) {
        case "email":
          _result[prop] = !_detail.email
            ? `${t("Email")} ${t("message.cant-be-empty")}`
            : null;
          if (_detail.email && !REGEX_EMAIL.test(_detail.email))
            _result[prop] = `${t("message.invalid-email")}`;
          break;
        case "password":
          _result[prop] = !_detail.password
            ? `${t("Password")} ${t("message.cant-be-empty")}`
            : null;
          break;

        default:
          break;
      }
    });
    setValidate((prev) => ({
      ...prev,
      ..._result,
    }));

    for (const property in _result) {
      if (_result[property]) {
        _isValid = false;
        break;
      }
    }
    setIsValid(_isValid);
    return _isValid;
  };

  const onSubmit = async () => {
    try {
      const _isValid = performValidate([]);
      if (!captcha_status && captcha) {
        setIsVerifyCaptcha({
          show: true,
          verify: false,
        });
        return;
      } else {
        setIsVerifyCaptcha({
          show: false,
          verify: true,
        });
      }
      if (!_isValid) return;

      setLoading(true);
      const res: any = await AuthAPI.login(state);
      if (res) {
        if (res.require_2fa || res.require_email) {
          history.push(
            `${ROUTES.VERIFY_LOGIN}?twofa=${res.require_2fa}&&email=${res.require_email}&&token=${res.token}`
          );
        } else {
          if (query.redirect_url) {
            window.location.href =
              query.redirect_url + "?vdb_token=" + res.token;
          } else {
            localStorage.setItem("auth", res.token);
            if (window.location.pathname === "/share") {
              window.location.reload();
            } else {
              history.push("/");
            }
          }
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error?.errors === "USER_NOT_VERIFY") {
        history.push(`${ROUTES.VERIFY_EMAIL}?email=${state.email}`);
      } else {
        toast.error(t("Incorrect account or password."));
      }
    }
    setLoading(false);
  };

  return (
    <Card className="form-user col-md-6" isOpacity={true}>
      <HeaderForm />
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0 fs-2">{t("login")}</h1>
          <div className="cursor-pointer" onClick={handleLoginQr}>
            <img src="/assets/images/icons/login-qr-code.svg" alt="" />
          </div>
        </div>
        <p className="fs-12">{t("enter_email_password")}</p>
        <form className="mt-4">
          <div className="form-group">
            <VInput
              label={t("enter_email")}
              type="text"
              id="email"
              name="email"
              errMessage={validate.email}
              onChange={(e) => handleChange("email", e.target.value)}
            />
          </div>
          <div className="form-group">
            <VInput
              label={t("enter_password")}
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              onChange={(e) => handleChange("password", e.target.value)}
              errMessage={validate.password}
              suffix={
                <div
                  className="cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <VIcon name={showPassword ? "eye.svg" : "eye_off.svg"} />
                </div>
              }
            />
          </div>
          {captcha && (
            <VCapcha
              captchaId={captcha?.captcha_id}
              isVerify={isVerifyCaptcha}
            />
          )}
          <div className="d-flex justify-content-between mt-2 pt-1">
            <div className="form-check d-inline-block ">
              <input
                type="checkbox"
                className="form-check-input"
                id="customCheck1"
              />
              <label className="form-check-label" htmlFor="customCheck1">
                {t("save_login")}
              </label>
            </div>
            <div dir="rtl">
              <Link to={ROUTES.FORGOT_PASSWORD}>{t("Forgot password")}</Link>
            </div>
          </div>
          <div className="d-inline-block w-100">
            <div className="d-flex justify-content-between align-items-center mt-3">
              {/* <Link to={ROUTES.REGISTER}>{t("create_account")}</Link> */}
              <VButton onClick={handleFastRegister} loading={loading}>
                {t("Sign up now")}
              </VButton>
              <VButton
                loading={loading}
                onClick={onSubmit}
                // disabled={!isValid}
                // disabled={!isValid || !captcha_status}
              >
                {t("Login")}
              </VButton>
            </div>
          </div>
          <FooterForm type={"login"} />
        </form>
      </div>
    </Card>
  );
}

export default View;

/* eslint-disable */

export const coords = [
  {
    city: "Ho Chi Minh City",
    lat: "10.8167",
    lng: "106.6333",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "admin",
    population: "13312000",
    population_proper: "7431000",
  },
  {
    city: "Hanoi",
    lat: "21.0245",
    lng: "105.8412",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "primary",
    population: "7785000",
    population_proper: "7785000",
  },
  {
    city: "Haiphong",
    lat: "20.8000",
    lng: "106.6667",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Phòng",
    capital: "admin",
    population: "2103500",
    population_proper: "2103500",
  },
  {
    city: "Cần Thơ",
    lat: "10.0333",
    lng: "105.7833",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "admin",
    population: "1237300",
    population_proper: "1237300",
  },
  {
    city: "Biên Hòa",
    lat: "10.9277",
    lng: "106.8681",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "admin",
    population: "1104000",
    population_proper: "1104000",
  },
  {
    city: "Thủ Đức",
    lat: "10.8199",
    lng: "106.7909",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "1013795",
    population_proper: "1013795",
  },
  {
    city: "Quảng Hà",
    lat: "16.0600",
    lng: "108.2500",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "",
    population: "1000000",
    population_proper: "887069",
  },
  {
    city: "Bắc Ninh",
    lat: "21.1861",
    lng: "106.0763",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Ninh",
    capital: "admin",
    population: "520000",
    population_proper: "520000",
  },
  {
    city: "Hải Dương",
    lat: "20.9399",
    lng: "106.3309",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "admin",
    population: "507469",
    population_proper: "507469",
  },
  {
    city: "Vinh",
    lat: "18.6733",
    lng: "105.6922",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "admin",
    population: "490000",
    population_proper: "490000",
  },
  {
    city: "Huế",
    lat: "16.4637",
    lng: "107.5908",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thừa Thiên Huế",
    capital: "admin",
    population: "455230",
    population_proper: "455230",
  },
  {
    city: "Thanh Hóa",
    lat: "19.8075",
    lng: "105.7764",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "admin",
    population: "393294",
    population_proper: "393294",
  },
  {
    city: "Nha Trang",
    lat: "12.2495",
    lng: "109.1908",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "admin",
    population: "392279",
    population_proper: "392279",
  },
  {
    city: "Nam Định",
    lat: "20.4200",
    lng: "106.1683",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "admin",
    population: "352108",
    population_proper: "352108",
  },
  {
    city: "Buôn Ma Thuột",
    lat: "12.6667",
    lng: "108.0500",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "admin",
    population: "340000",
    population_proper: "340000",
  },
  {
    city: "Thái Nguyên",
    lat: "21.6000",
    lng: "105.8500",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Nguyên",
    capital: "admin",
    population: "330000",
    population_proper: "330000",
  },
  {
    city: "Vũng Tàu",
    lat: "10.4042",
    lng: "107.1417",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bà Rịa - Vũng Tàu",
    capital: "admin",
    population: "327000",
    population_proper: "327000",
  },
  {
    city: "Cà Mau",
    lat: "9.1833",
    lng: "105.1500",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "admin",
    population: "315270",
    population_proper: "315270",
  },
  {
    city: "Quy Nhơn",
    lat: "13.7696",
    lng: "109.2314",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "admin",
    population: "311000",
    population_proper: "311000",
  },
  {
    city: "Sóc Trăng",
    lat: "9.6000",
    lng: "105.9719",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "admin",
    population: "300000",
    population_proper: "173922",
  },
  {
    city: "Long Xuyên",
    lat: "10.3686",
    lng: "105.4234",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "admin",
    population: "278658",
    population_proper: "278658",
  },
  {
    city: "Việt Trì",
    lat: "21.3136",
    lng: "105.3947",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "admin",
    population: "277539",
    population_proper: "277539",
  },
  {
    city: "Thái Bình",
    lat: "20.4461",
    lng: "106.3422",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Bình",
    capital: "admin",
    population: "268167",
    population_proper: "268167",
  },
  {
    city: "Quảng Ngãi",
    lat: "15.1206",
    lng: "108.7922",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "admin",
    population: "260252",
    population_proper: "260252",
  },
  {
    city: "Ấp Đa Lợi",
    lat: "11.9304",
    lng: "108.4200",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "",
    population: "256019",
    population_proper: "131377",
  },
  {
    city: "Rạch Giá",
    lat: "10.0125",
    lng: "105.0808",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "admin",
    population: "250660",
    population_proper: "250660",
  },
  {
    city: "Thủ Dầu Một",
    lat: "11.0042",
    lng: "106.6583",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Dương",
    capital: "admin",
    population: "244277",
    population_proper: "244277",
  },
  {
    city: "Tuy Hòa",
    lat: "13.0869",
    lng: "109.3086",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Yên",
    capital: "admin",
    population: "242840",
    population_proper: "242840",
  },
  {
    city: "Bạc Liêu",
    lat: "9.2833",
    lng: "105.7167",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bạc Liêu",
    capital: "admin",
    population: "225000",
    population_proper: "150000",
  },
  {
    city: "Sa Đéc",
    lat: "10.3105",
    lng: "105.7397",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "minor",
    population: "213610",
    population_proper: "213610",
  },
  {
    city: "Phan Thiết",
    lat: "10.9375",
    lng: "108.1583",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Thuận",
    capital: "admin",
    population: "205333",
    population_proper: "205333",
  },
  {
    city: "Sơn Tây",
    lat: "21.1382",
    lng: "105.5050",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "189547",
    population_proper: "40636",
  },
  {
    city: "Phan Rang-Tháp Chàm",
    lat: "11.5643",
    lng: "108.9886",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Thuận",
    capital: "admin",
    population: "179773",
    population_proper: "91520",
  },
  {
    city: "Hạ Long",
    lat: "20.9514",
    lng: "107.0852",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ninh",
    capital: "admin",
    population: "172915",
    population_proper: "148066",
  },
  {
    city: "Hà Tĩnh",
    lat: "18.3431",
    lng: "105.9058",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "admin",
    population: "165396",
    population_proper: "165396",
  },
  {
    city: "Đồng Hới",
    lat: "17.4833",
    lng: "106.6000",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Bình",
    capital: "admin",
    population: "160325",
    population_proper: "160325",
  },
  {
    city: "Châu Đốc",
    lat: "10.7000",
    lng: "105.1167",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "",
    population: "157298",
    population_proper: "157298",
  },
  {
    city: "Cẩm Phả",
    lat: "21.0094",
    lng: "107.2728",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ninh",
    capital: "minor",
    population: "156000",
    population_proper: "156000",
  },
  {
    city: "Cao Lãnh",
    lat: "10.4603",
    lng: "105.6331",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "admin",
    population: "149837",
    population_proper: "149837",
  },
  {
    city: "Lạng Sơn",
    lat: "21.8478",
    lng: "106.7578",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "admin",
    population: "148000",
    population_proper: "148000",
  },
  {
    city: "Cam Ranh",
    lat: "11.9020",
    lng: "109.2207",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "",
    population: "146771",
    population_proper: "23883",
  },
  {
    city: "Pleiku",
    lat: "13.9715",
    lng: "108.0147",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "admin",
    population: "146073",
    population_proper: "146073",
  },
  {
    city: "Tân An",
    lat: "10.5322",
    lng: "106.4042",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "admin",
    population: "137498",
    population_proper: "64801",
  },
  {
    city: "Trà Vinh",
    lat: "9.9369",
    lng: "106.3411",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Trà Vinh",
    capital: "admin",
    population: "131360",
    population_proper: "131360",
  },
  {
    city: "Ninh Bình",
    lat: "20.2539",
    lng: "105.9750",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Bình",
    capital: "admin",
    population: "130517",
    population_proper: "130517",
  },
  {
    city: "Tây Ninh",
    lat: "11.3131",
    lng: "106.0963",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "admin",
    population: "126370",
    population_proper: "126370",
  },
  {
    city: "Cam Ranh",
    lat: "11.9136",
    lng: "109.1369",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "minor",
    population: "125311",
    population_proper: "125311",
  },
  {
    city: "Mỹ Tho",
    lat: "10.3500",
    lng: "106.3500",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tiền Giang",
    capital: "admin",
    population: "124143",
    population_proper: "122310",
  },
  {
    city: "Hội An",
    lat: "15.8777",
    lng: "108.3327",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "",
    population: "121716",
    population_proper: "121716",
  },
  {
    city: "Hòa Bình",
    lat: "20.8175",
    lng: "105.3375",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "admin",
    population: "121309",
    population_proper: "121309",
  },
  {
    city: "Mang La",
    lat: "14.3838",
    lng: "107.9833",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "",
    population: "105489",
    population_proper: "47409",
  },
  {
    city: "Vĩnh Long",
    lat: "10.2550",
    lng: "105.9753",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Long",
    capital: "admin",
    population: "103314",
    population_proper: "103314",
  },
  {
    city: "Vị Thanh",
    lat: "9.7833",
    lng: "105.4708",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hậu Giang",
    capital: "admin",
    population: "97200",
    population_proper: "97200",
  },
  {
    city: "Yên Bái",
    lat: "21.7000",
    lng: "104.8667",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Yên Bái",
    capital: "admin",
    population: "96540",
    population_proper: "96540",
  },
  {
    city: "Dĩ An",
    lat: "10.9039",
    lng: "106.7679",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Dương",
    capital: "minor",
    population: "73859",
    population_proper: "73859",
  },
  {
    city: "Quảng Trị",
    lat: "16.7504",
    lng: "107.2000",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "",
    population: "72722",
    population_proper: "72722",
  },
  {
    city: "Phú Quốc",
    lat: "10.2289",
    lng: "103.9572",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "70000",
    population_proper: "70000",
  },
  {
    city: "Lào Cai",
    lat: "22.4194",
    lng: "103.9950",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "admin",
    population: "67206",
    population_proper: "36502",
  },
  {
    city: "Bến Tre",
    lat: "10.2333",
    lng: "106.3833",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bến Tre",
    capital: "admin",
    population: "59442",
    population_proper: "59442",
  },
  {
    city: "Bắc Giang",
    lat: "21.2731",
    lng: "106.1947",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "admin",
    population: "53728",
    population_proper: "53728",
  },
  {
    city: "Hà Tiên",
    lat: "10.3833",
    lng: "104.4833",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "47039",
    population_proper: "47039",
  },
  {
    city: "Cao Bằng",
    lat: "22.6731",
    lng: "106.2500",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "admin",
    population: "41112",
    population_proper: "41112",
  },
  {
    city: "Bình Long",
    lat: "11.6504",
    lng: "106.6000",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Phước",
    capital: "",
    population: "40279",
    population_proper: "40279",
  },
  {
    city: "Hà Giang",
    lat: "22.8233",
    lng: "104.9836",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "admin",
    population: "38362",
    population_proper: "32690",
  },
  {
    city: "Tuyên Quang",
    lat: "21.8281",
    lng: "105.2156",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tuyên Quang",
    capital: "admin",
    population: "36430",
    population_proper: "36430",
  },
  {
    city: "Bắc Kạn",
    lat: "22.1514",
    lng: "105.8377",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Kạn",
    capital: "admin",
    population: "29227",
    population_proper: "29227",
  },
  {
    city: "Sơn La",
    lat: "21.3270",
    lng: "103.9141",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "admin",
    population: "19054",
    population_proper: "19054",
  },
  {
    city: "Đông Hà",
    lat: "16.8056",
    lng: "107.0906",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "admin",
    population: "17662",
    population_proper: "17662",
  },
  {
    city: "Quận 11",
    lat: "10.7638",
    lng: "106.6436",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 5",
    lat: "10.7557",
    lng: "106.6675",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 10",
    lat: "10.7682",
    lng: "106.6663",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận Phú Nhuận",
    lat: "10.7948",
    lng: "106.6763",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đống Đa",
    lat: "21.0201",
    lng: "105.8310",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 6",
    lat: "10.7468",
    lng: "106.6490",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hai Bà Trưng",
    lat: "21.0134",
    lng: "105.8477",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hoàn Kiếm",
    lat: "21.0286",
    lng: "105.8506",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 3",
    lat: "10.7749",
    lng: "106.6863",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 1",
    lat: "10.7807",
    lng: "106.6994",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cầu Giấy",
    lat: "21.0323",
    lng: "105.8007",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 4",
    lat: "10.7668",
    lng: "106.7057",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận Tân Phú",
    lat: "10.7838",
    lng: "106.6370",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận Bình Thạnh",
    lat: "10.8033",
    lng: "106.6967",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ba Đình",
    lat: "21.0341",
    lng: "105.8145",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Khê",
    lat: "16.0706",
    lng: "108.1910",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đà Nẵng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Xuân",
    lat: "20.9947",
    lng: "105.7998",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đà Nẵng",
    lat: "16.0748",
    lng: "108.2240",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đà Nẵng",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 7",
    lat: "10.7118",
    lng: "106.7364",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tây Hồ",
    lat: "21.0689",
    lng: "105.8105",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hà Đông",
    lat: "20.9714",
    lng: "105.7788",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lái Thiêu",
    lat: "10.9051",
    lng: "106.6995",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cầu Diễn",
    lat: "21.0393",
    lng: "105.7666",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hưng Yên",
    lat: "20.6464",
    lng: "106.0511",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 2",
    lat: "10.7916",
    lng: "106.7495",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hóc Môn",
    lat: "10.8891",
    lng: "106.5953",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cẩm Lệ",
    lat: "16.0177",
    lng: "108.2038",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đà Nẵng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Văn Điển",
    lat: "20.9463",
    lng: "105.8450",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quận 9",
    lat: "10.8397",
    lng: "106.7709",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sơn Trà",
    lat: "16.0607",
    lng: "108.2326",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đà Nẵng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trâu Quỳ",
    lat: "21.0195",
    lng: "105.9369",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phủ Lý",
    lat: "20.5453",
    lng: "105.9122",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nam",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Trôi",
    lat: "21.0695",
    lng: "105.7065",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phùng",
    lat: "21.0890",
    lng: "105.6590",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đông Anh",
    lat: "21.1396",
    lng: "105.8493",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Túc",
    lat: "10.6954",
    lng: "106.5913",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bình Thủy",
    lat: "10.0743",
    lng: "105.7397",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Yên",
    lat: "21.3100",
    lng: "105.5967",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Phúc",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Tam Kỳ",
    lat: "15.5667",
    lng: "108.4833",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Điện Biên Phủ",
    lat: "21.3833",
    lng: "103.0169",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Điện Biên",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Đồng Xoài",
    lat: "11.5349",
    lng: "106.8832",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Phước",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Đà Lạt",
    lat: "11.9359",
    lng: "108.4429",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Lai Châu",
    lat: "22.3991",
    lng: "103.4393",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lai Châu",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Kon Tum",
    lat: "14.3436",
    lng: "107.9789",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Gia Nghĩa",
    lat: "12.0042",
    lng: "107.6907",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Nông",
    capital: "admin",
    population: "",
    population_proper: "",
  },
  {
    city: "Ngũ Hành Sơn",
    lat: "16.0162",
    lng: "108.2534",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đà Nẵng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thường Tín",
    lat: "20.8687",
    lng: "105.8612",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Long Điền",
    lat: "10.4856",
    lng: "107.2123",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bà Rịa - Vũng Tàu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Liên Chiểu",
    lat: "16.0727",
    lng: "108.1581",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đà Nẵng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cái Răng",
    lat: "10.0009",
    lng: "105.7510",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hòa Thành",
    lat: "11.2870",
    lng: "106.1294",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lý Sơn",
    lat: "15.3720",
    lng: "109.1189",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phúc Thọ",
    lat: "21.1034",
    lng: "105.5455",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "An Dương",
    lat: "20.8638",
    lng: "106.6133",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Phòng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mê Linh",
    lat: "21.1831",
    lng: "105.7204",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Như Quỳnh",
    lat: "20.9817",
    lng: "105.9849",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nhà Bè",
    lat: "10.6967",
    lng: "106.7403",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Liên Quan",
    lat: "21.0572",
    lng: "105.5792",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Mỹ",
    lat: "20.8856",
    lng: "106.0356",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Khoái Châu",
    lat: "20.8391",
    lng: "105.9776",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Quý",
    lat: "10.5166",
    lng: "108.9329",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kim Bài",
    lat: "20.8526",
    lng: "105.7696",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Văn Giang",
    lat: "20.9430",
    lng: "105.9312",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chúc Sơn",
    lat: "20.9237",
    lng: "105.7015",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quốc Oai",
    lat: "20.9902",
    lng: "105.6409",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Xuân Trường",
    lat: "20.2923",
    lng: "106.3327",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hồ",
    lat: "21.0631",
    lng: "106.0857",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Núi Đèo",
    lat: "20.9157",
    lng: "106.6744",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Phòng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Lạc",
    lat: "21.2365",
    lng: "105.5730",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Phúc",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Tường",
    lat: "21.2165",
    lng: "105.5125",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Phúc",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Núi Đối",
    lat: "20.7508",
    lng: "106.6689",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Phòng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Xuyên",
    lat: "20.7455",
    lng: "105.9136",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "An Lão",
    lat: "20.8229",
    lng: "106.5558",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Phòng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cổ Lễ",
    lat: "20.3234",
    lng: "106.2658",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hưng Hà",
    lat: "20.5904",
    lng: "106.2245",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bẩn Yên Nhân",
    lat: "20.9365",
    lng: "106.0571",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lim",
    lat: "21.1439",
    lng: "106.0202",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ô Môn",
    lat: "10.1097",
    lng: "105.6232",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nam Giang",
    lat: "20.3332",
    lng: "106.1775",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vũ Thư",
    lat: "20.4367",
    lng: "106.2912",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lai Cách",
    lat: "20.9325",
    lng: "106.2476",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hậu Lộc",
    lat: "19.9144",
    lng: "105.8861",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đông Hưng",
    lat: "20.5565",
    lng: "106.3553",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chờ",
    lat: "21.1949",
    lng: "105.9547",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bút Sơn",
    lat: "19.8580",
    lng: "105.8548",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quảng Xương",
    lat: "19.7295",
    lng: "105.7827",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quỳnh Côi",
    lat: "20.6619",
    lng: "106.3274",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vân Đình",
    lat: "20.7351",
    lng: "105.7724",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lương Bằng",
    lat: "20.7400",
    lng: "106.0600",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gia Lộc",
    lat: "20.8678",
    lng: "106.3000",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thốt Nốt",
    lat: "10.2721",
    lng: "105.5338",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Định",
    lat: "20.2015",
    lng: "106.2958",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Thái",
    lat: "20.9628",
    lng: "106.5095",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kẻ Sặt",
    lat: "20.9101",
    lng: "106.1477",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sóc Sơn",
    lat: "21.2578",
    lng: "105.8489",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Nê",
    lat: "20.3881",
    lng: "106.4383",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ninh Giang",
    lat: "20.7308",
    lng: "106.3979",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thắng",
    lat: "21.3551",
    lng: "105.9834",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Trụ",
    lat: "20.5602",
    lng: "106.0281",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vạn Hà",
    lat: "19.8866",
    lng: "105.6788",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chợ Mới",
    lat: "10.5512",
    lng: "105.4044",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Bảo",
    lat: "20.6908",
    lng: "106.4806",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Phòng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ân Thi",
    lat: "20.8184",
    lng: "106.0912",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kinh Môn",
    lat: "20.9896",
    lng: "106.5551",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Miện",
    lat: "20.7871",
    lng: "106.2462",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đại Nghĩa",
    lat: "20.6867",
    lng: "105.7447",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Hà",
    lat: "20.8982",
    lng: "106.4261",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Châu",
    lat: "10.7983",
    lng: "105.2389",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bình Minh",
    lat: "10.0682",
    lng: "105.8222",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Long",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Củ Chi",
    lat: "10.9733",
    lng: "106.4932",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bích Động",
    lat: "21.2742",
    lng: "106.1007",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Ninh",
    lat: "20.1840",
    lng: "106.0612",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Điện Bàn",
    lat: "15.8885",
    lng: "108.2545",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thứa",
    lat: "21.0194",
    lng: "106.2004",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gia Bình",
    lat: "21.0594",
    lng: "106.1747",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Hiệp",
    lat: "10.4490",
    lng: "106.3439",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tiền Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Rừng Thông",
    lat: "19.8191",
    lng: "105.7328",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tứ Kỳ",
    lat: "20.8191",
    lng: "106.4066",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ngô Đồng",
    lat: "20.2830",
    lng: "106.4435",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mỹ Lộc",
    lat: "20.4411",
    lng: "106.1024",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bình Mỹ",
    lat: "20.4912",
    lng: "106.0095",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lâm",
    lat: "20.3277",
    lng: "106.0121",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hòa Mạc",
    lat: "20.6417",
    lng: "105.9890",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Diễn Châu",
    lat: "18.9765",
    lng: "105.6003",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phong Điền",
    lat: "9.9975",
    lng: "105.6673",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vương",
    lat: "20.7011",
    lng: "106.1175",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cần Đước",
    lat: "10.5097",
    lng: "106.6031",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Diêm Điền",
    lat: "20.5583",
    lng: "106.5688",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tiên Lãng",
    lat: "20.7246",
    lng: "106.5539",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Phòng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nam Sách",
    lat: "20.9921",
    lng: "106.3361",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nga Sơn",
    lat: "20.0072",
    lng: "105.9704",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phát Diệm",
    lat: "20.0905",
    lng: "106.0832",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cần Giuộc",
    lat: "10.6086",
    lng: "106.6713",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "An Phú",
    lat: "10.8159",
    lng: "105.0904",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trảng Bom",
    lat: "10.9536",
    lng: "107.0059",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Vang",
    lat: "16.4391",
    lng: "107.7144",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thừa Thiên Huế",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trần Cao",
    lat: "20.7295",
    lng: "106.1797",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hưng Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lấp Vò",
    lat: "10.3616",
    lng: "105.5192",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gôi",
    lat: "20.3334",
    lng: "106.0783",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hợp Hòa",
    lat: "21.3753",
    lng: "105.5426",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Phúc",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phố Mới",
    lat: "21.1533",
    lng: "106.1516",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vôi",
    lat: "21.3552",
    lng: "106.2615",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chợ Gạo",
    lat: "10.3498",
    lng: "106.4634",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tiền Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lâm Thao",
    lat: "21.3201",
    lng: "105.2916",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Liễu Đề",
    lat: "20.2209",
    lng: "106.1875",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nam Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tư Nghĩa",
    lat: "15.0845",
    lng: "108.8260",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Long Hồ",
    lat: "10.1928",
    lng: "106.0123",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Long",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Long Khánh",
    lat: "10.9265",
    lng: "107.2478",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cao Thượng",
    lat: "21.3871",
    lng: "106.1293",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Neo",
    lat: "21.2005",
    lng: "106.2474",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Châu Thành",
    lat: "10.3012",
    lng: "106.3570",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bến Tre",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "An Nhơn",
    lat: "13.8858",
    lng: "109.1082",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Mỹ",
    lat: "10.5924",
    lng: "105.3557",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chợ Lách",
    lat: "10.2603",
    lng: "106.1189",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bến Tre",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Thịnh",
    lat: "20.1625",
    lng: "106.0070",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phúc Yên",
    lat: "21.2370",
    lng: "105.7048",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Phúc",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tây Đằng",
    lat: "21.1981",
    lng: "105.4222",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nội",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lai Vung",
    lat: "10.2870",
    lng: "105.6617",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Thọ",
    lat: "21.3996",
    lng: "105.2222",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Lưu",
    lat: "20.4740",
    lng: "105.9527",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quán Lào",
    lat: "19.9715",
    lng: "105.6511",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thọ Xuân",
    lat: "19.9313",
    lng: "105.5218",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bảo Lộc",
    lat: "11.5480",
    lng: "107.8077",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thống Nhất",
    lat: "10.9505",
    lng: "107.1457",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hương Canh",
    lat: "21.2788",
    lng: "105.6470",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Phúc",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mỏ Cày",
    lat: "10.1331",
    lng: "106.3352",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bến Tre",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hưng Nguyên",
    lat: "18.6716",
    lng: "105.6288",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ngã Bảy",
    lat: "9.8137",
    lng: "105.8210",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hậu Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tầm Vu",
    lat: "10.4411",
    lng: "106.4679",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Bình",
    lat: "10.3450",
    lng: "106.5806",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tiền Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tuy Phước",
    lat: "13.8274",
    lng: "109.1665",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quế",
    lat: "20.5806",
    lng: "105.8727",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Triệu Sơn",
    lat: "19.8191",
    lng: "105.6039",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Me",
    lat: "20.3470",
    lng: "105.8361",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lập Thạch",
    lat: "21.4154",
    lng: "105.4608",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Phúc",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cái Tàu Hạ",
    lat: "10.2597",
    lng: "105.8704",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sịa",
    lat: "16.5745",
    lng: "107.5107",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thừa Thiên Huế",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thiên Tồn",
    lat: "20.2990",
    lng: "105.9520",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nông Cống",
    lat: "19.6240",
    lng: "105.6494",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Trụ",
    lat: "10.5127",
    lng: "106.5076",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Long Thành",
    lat: "10.7891",
    lng: "106.9503",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quảng Yên",
    lat: "20.9421",
    lng: "106.8027",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mộ Đức",
    lat: "14.9551",
    lng: "108.8859",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hương Trà",
    lat: "16.5254",
    lng: "107.4755",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thừa Thiên Huế",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cái Nhum",
    lat: "10.1741",
    lng: "106.1105",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Long",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cầu Giát",
    lat: "19.1460",
    lng: "105.6309",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Lộc",
    lat: "20.0544",
    lng: "105.6157",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sơn Tịnh",
    lat: "15.1586",
    lng: "108.7958",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Một Ngàn",
    lat: "9.9240",
    lng: "105.6296",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hậu Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ba Tri",
    lat: "10.0443",
    lng: "106.5902",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bến Tre",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Giồng Trôm",
    lat: "10.1502",
    lng: "106.5061",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bến Tre",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cái Dầu",
    lat: "10.5698",
    lng: "105.2295",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Hòa",
    lat: "10.3191",
    lng: "106.7113",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tiền Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Minh Lương",
    lat: "9.9025",
    lng: "105.1595",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hương Sơn",
    lat: "21.4601",
    lng: "105.9781",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Nguyên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vũng Liêm",
    lat: "10.0958",
    lng: "106.1855",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Long",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ngã Sáu",
    lat: "9.9230",
    lng: "105.8054",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hậu Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bến Lức",
    lat: "10.6364",
    lng: "106.4910",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tam Điệp",
    lat: "20.1487",
    lng: "105.8985",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đô Lương",
    lat: "18.8985",
    lng: "105.3080",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trà Ôn",
    lat: "9.9626",
    lng: "105.9217",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Long",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tam Bình",
    lat: "10.0481",
    lng: "106.0002",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Long",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nam Đàn",
    lat: "18.6980",
    lng: "105.4988",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hậu Nghĩa",
    lat: "10.8943",
    lng: "106.3969",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nhơn Trạch",
    lat: "10.7229",
    lng: "106.8834",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kế Sách",
    lat: "9.7674",
    lng: "105.9857",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Uyên",
    lat: "11.0609",
    lng: "106.7990",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đức Thọ",
    lat: "18.5396",
    lng: "105.5830",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gò Dầu",
    lat: "11.0885",
    lng: "106.2635",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quán Hành",
    lat: "18.7793",
    lng: "105.6460",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trà Cú",
    lat: "9.6977",
    lng: "106.2583",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Trà Vinh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thới Lai",
    lat: "10.0652",
    lng: "105.5586",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bồng Sơn",
    lat: "14.4291",
    lng: "109.0156",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cầu Kè",
    lat: "9.8724",
    lng: "106.0550",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Trà Vinh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cờ Đỏ",
    lat: "10.0938",
    lng: "105.4292",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tiểu Cần",
    lat: "9.8117",
    lng: "106.1907",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Trà Vinh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "An Châu",
    lat: "10.4456",
    lng: "105.3862",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Bình",
    lat: "10.5619",
    lng: "105.4841",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Thành",
    lat: "18.9986",
    lng: "105.4712",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trảng Bàng",
    lat: "11.0299",
    lng: "106.3575",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tĩnh Gia",
    lat: "19.4464",
    lng: "105.7786",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Long Phú",
    lat: "9.6102",
    lng: "106.1188",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Phú Mỹ",
    lat: "10.6036",
    lng: "107.0540",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bà Rịa - Vũng Tàu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nghi Xuân",
    lat: "18.6621",
    lng: "105.7557",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Châu Thành",
    lat: "9.8699",
    lng: "106.3494",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Trà Vinh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Châu Thành",
    lat: "9.7007",
    lng: "105.9020",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Thạnh",
    lat: "10.2226",
    lng: "105.3984",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cần Thơ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hà Lam",
    lat: "15.7387",
    lng: "108.3506",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Thủy",
    lat: "21.1755",
    lng: "105.2839",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mỹ Thọ",
    lat: "10.4427",
    lng: "105.6951",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Đất Đỏ",
    lat: "10.4909",
    lng: "107.2701",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bà Rịa - Vũng Tàu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nghèn",
    lat: "18.4512",
    lng: "105.7797",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Duy Xuyên",
    lat: "15.8290",
    lng: "108.2596",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Giá Rai",
    lat: "9.2379",
    lng: "105.4552",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bạc Liêu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phong Châu",
    lat: "21.4068",
    lng: "105.3123",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Diên Khánh",
    lat: "12.2549",
    lng: "109.0933",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nàng Mau",
    lat: "9.7513",
    lng: "105.5345",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hậu Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mỹ Xuyên",
    lat: "9.5601",
    lng: "105.9898",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Núi Sập",
    lat: "10.2648",
    lng: "105.2668",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nghĩa Hành",
    lat: "15.0457",
    lng: "108.7772",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đức Phổ",
    lat: "14.8136",
    lng: "108.9593",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thạch Hà",
    lat: "18.3679",
    lng: "105.8612",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Ngải Giao",
    lat: "10.6471",
    lng: "107.2458",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bà Rịa - Vũng Tàu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Ba",
    lat: "21.4988",
    lng: "105.1410",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bình Sơn",
    lat: "15.2978",
    lng: "108.7612",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Khánh Hải",
    lat: "11.5910",
    lng: "109.0405",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Châu",
    lat: "9.3244",
    lng: "105.9801",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bình Đại",
    lat: "10.1896",
    lng: "106.6915",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bến Tre",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sông Thao",
    lat: "21.4213",
    lng: "105.1337",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cù Lao Dung",
    lat: "9.6731",
    lng: "106.1487",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phụng Hiệp",
    lat: "9.7797",
    lng: "105.7338",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hậu Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Hiệp",
    lat: "10.1171",
    lng: "105.2858",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Giồng Riềng",
    lat: "9.9097",
    lng: "105.3143",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phước An",
    lat: "12.7130",
    lng: "108.3015",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Long Mỹ",
    lat: "9.6814",
    lng: "105.5723",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hậu Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nhà Bàng",
    lat: "10.6177",
    lng: "105.0034",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hưng Hóa",
    lat: "21.2549",
    lng: "105.2953",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ba Đồn",
    lat: "17.7535",
    lng: "106.4235",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thạnh Phú",
    lat: "9.9483",
    lng: "106.5163",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bến Tre",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cái Nước",
    lat: "8.9423",
    lng: "105.0141",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Hòa Bình",
    lat: "9.2847",
    lng: "105.6292",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bạc Liêu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gò Quao",
    lat: "9.7332",
    lng: "105.2739",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Ninh",
    lat: "15.5547",
    lng: "108.4114",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "An Biên",
    lat: "9.8153",
    lng: "105.0637",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đồi Ngô",
    lat: "21.3063",
    lng: "106.3867",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chí Thạnh",
    lat: "13.3084",
    lng: "109.2152",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quế Sơn",
    lat: "15.6736",
    lng: "108.2201",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phù Mỹ",
    lat: "14.1718",
    lng: "109.0489",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gia Ray",
    lat: "10.9261",
    lng: "107.4017",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Nho Quan",
    lat: "20.3228",
    lng: "105.7519",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Cao Lộc",
    lat: "21.8665",
    lng: "106.7683",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thủ Thừa",
    lat: "10.6023",
    lng: "106.4021",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Lộc",
    lat: "9.4284",
    lng: "105.7411",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cầu Gồ",
    lat: "21.4768",
    lng: "106.1265",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Phước Long",
    lat: "9.4375",
    lng: "105.4622",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bạc Liêu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Huỳnh Hữu Nghĩa",
    lat: "9.6354",
    lng: "105.8117",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sóc Trăng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sa Rài",
    lat: "10.8702",
    lng: "105.4658",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Gành Hào",
    lat: "9.0318",
    lng: "105.4211",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bạc Liêu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đu",
    lat: "21.7385",
    lng: "105.7039",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Nguyên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Núi Thành",
    lat: "15.4319",
    lng: "108.6595",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phù Cát",
    lat: "14.0032",
    lng: "109.0580",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ái Tử",
    lat: "16.7689",
    lng: "107.1650",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bến Cầu",
    lat: "11.1105",
    lng: "106.1792",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tam Đảo",
    lat: "21.3932",
    lng: "105.6144",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Vĩnh Phúc",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trần Văn Thời",
    lat: "9.0771",
    lng: "104.9791",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Ngan Dừa",
    lat: "9.5670",
    lng: "105.4502",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bạc Liêu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đại Lộc",
    lat: "15.8821",
    lng: "108.1209",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Duyên Hải",
    lat: "9.6350",
    lng: "106.4924",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Trà Vinh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ngọc Lặc",
    lat: "20.0919",
    lng: "105.3676",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Buôn Trấp",
    lat: "12.4848",
    lng: "108.0298",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cái Đôi Vàm",
    lat: "8.8571",
    lng: "104.8120",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vạn Giã",
    lat: "12.6920",
    lng: "109.2207",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Thuận",
    lat: "9.5080",
    lng: "105.2575",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Võ Xu",
    lat: "11.1869",
    lng: "107.5603",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Phước Bửu",
    lat: "10.5347",
    lng: "107.4020",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bà Rịa - Vũng Tàu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kim Tân",
    lat: "20.1305",
    lng: "105.6745",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phước Vĩnh",
    lat: "11.2947",
    lng: "106.7953",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chùa Hang",
    lat: "21.6238",
    lng: "105.8417",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Nguyên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cẩm Thủy",
    lat: "20.2087",
    lng: "105.4695",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Châu Thành",
    lat: "11.3117",
    lng: "106.0295",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Dương Minh Châu",
    lat: "11.3841",
    lng: "106.2308",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Dầu Tiếng",
    lat: "11.2741",
    lng: "106.3588",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Dương",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đoan Hùng",
    lat: "21.6225",
    lng: "105.1846",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Phú",
    lat: "11.2722",
    lng: "107.4365",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chợ Mới",
    lat: "21.8837",
    lng: "105.7728",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Kạn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đầm Dơi",
    lat: "8.9921",
    lng: "105.1960",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tràm Chim",
    lat: "10.6700",
    lng: "105.5647",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Tháp",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vụ Bản",
    lat: "20.4642",
    lng: "105.4435",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sơn Dương",
    lat: "21.6968",
    lng: "105.3990",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tuyên Quang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cẩm Xuyên",
    lat: "18.2503",
    lng: "106.0013",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Lộc",
    lat: "16.2804",
    lng: "107.8590",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thừa Thiên Huế",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tri Tôn",
    lat: "10.4202",
    lng: "105.0007",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "An Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ninh Hòa",
    lat: "12.4919",
    lng: "109.1250",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sông Cầu",
    lat: "13.4556",
    lng: "109.2235",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quảng Phú",
    lat: "12.8196",
    lng: "108.0774",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thới Bình",
    lat: "9.3558",
    lng: "105.0993",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Krông Năng",
    lat: "12.9517",
    lng: "108.3443",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phước Dân",
    lat: "11.5270",
    lng: "108.9300",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hàng Trạm",
    lat: "20.3947",
    lng: "105.6224",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thứ Mười Một",
    lat: "9.6114",
    lng: "104.9465",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hạ Hòa",
    lat: "21.5630",
    lng: "105.0051",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bo",
    lat: "20.6726",
    lng: "105.5362",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Móng Cái",
    lat: "21.5298",
    lng: "107.9680",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chũ",
    lat: "21.3738",
    lng: "106.5665",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đưc Trọng",
    lat: "11.7356",
    lng: "108.3733",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chơn Thành",
    lat: "11.4282",
    lng: "106.6354",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Phước",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Liên Hương",
    lat: "11.2276",
    lng: "108.7237",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lương Sơn",
    lat: "20.8715",
    lng: "105.5257",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Chương",
    lat: "18.7788",
    lng: "105.3336",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Thạnh",
    lat: "10.6067",
    lng: "106.0471",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hải Lăng",
    lat: "16.6935",
    lng: "107.2512",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hòa Vang",
    lat: "15.9876",
    lng: "108.1389",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đà Nẵng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Kỳ",
    lat: "19.0476",
    lng: "105.2681",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Phong",
    lat: "13.9096",
    lng: "108.9190",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chợ Chu",
    lat: "21.9072",
    lng: "105.6422",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thái Nguyên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kỳ Sơn",
    lat: "20.8867",
    lng: "105.3524",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chi Nê",
    lat: "20.4906",
    lng: "105.7780",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mỹ Phước",
    lat: "10.4771",
    lng: "106.1926",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tiền Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cam Lâm",
    lat: "12.0540",
    lng: "109.1600",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thạnh Mỹ",
    lat: "11.7617",
    lng: "108.4947",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hòn Đất",
    lat: "10.1846",
    lng: "104.9296",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cao Phong",
    lat: "20.7045",
    lng: "105.3206",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Anh Sơn",
    lat: "18.9325",
    lng: "105.0760",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kỳ Anh",
    lat: "18.0624",
    lng: "106.2983",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cần Giờ",
    lat: "10.4111",
    lng: "106.9547",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hồ Chí Minh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Gio Linh",
    lat: "16.9248",
    lng: "107.0844",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đắk Mil",
    lat: "12.4496",
    lng: "107.6224",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Nông",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bù Đốp",
    lat: "11.9549",
    lng: "106.8002",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Phước",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đồng Nai",
    lat: "11.5798",
    lng: "107.3652",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Đồng Văn",
    lat: "23.2784",
    lng: "105.3629",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đinh Văn",
    lat: "11.7862",
    lng: "108.2428",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mường Khến",
    lat: "20.6144",
    lng: "105.2795",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tiên Phước",
    lat: "15.4882",
    lng: "108.3074",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ea T’ling",
    lat: "12.5890",
    lng: "107.8958",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Nông",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ea Kar",
    lat: "12.8082",
    lng: "108.4490",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lộc Ninh",
    lat: "11.8448",
    lng: "106.5907",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Phước",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cát Bà",
    lat: "20.7278",
    lng: "107.0482",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hải Phòng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Năm Căn",
    lat: "8.7590",
    lng: "104.9929",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Phố Lu",
    lat: "22.3192",
    lng: "104.1872",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh An",
    lat: "11.0890",
    lng: "107.0352",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đồng Nai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đông Thành",
    lat: "10.8922",
    lng: "106.3061",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chư Sê",
    lat: "13.6915",
    lng: "108.0802",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "A Yun Pa",
    lat: "13.4003",
    lng: "108.4350",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bến Sung",
    lat: "19.6314",
    lng: "105.5782",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hữu Lũng",
    lat: "21.5056",
    lng: "106.3446",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hồ Xá",
    lat: "17.0684",
    lng: "107.0052",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Châu",
    lat: "11.5520",
    lng: "106.1725",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "U Minh",
    lat: "9.4129",
    lng: "104.9735",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Yên Thế",
    lat: "22.1107",
    lng: "104.7675",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Yên Bái",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Si Ma Cai",
    lat: "22.6951",
    lng: "104.2786",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Hưng",
    lat: "10.8907",
    lng: "105.7849",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Mèo Vạc",
    lat: "23.1641",
    lng: "105.4099",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cam Lộ",
    lat: "16.8080",
    lng: "106.9941",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ma Lâm",
    lat: "11.0730",
    lng: "108.1300",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ngọc Hiển",
    lat: "8.6572",
    lng: "105.0602",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cà Mau",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Tân Yên",
    lat: "22.0655",
    lng: "105.0345",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tuyên Quang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thanh Sơn",
    lat: "21.1987",
    lng: "105.1789",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sơn Thịnh",
    lat: "21.5625",
    lng: "104.5948",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Yên Bái",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quỳ Hợp",
    lat: "19.3255",
    lng: "105.1825",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Lập",
    lat: "21.3470",
    lng: "105.0574",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Thọ",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quảng Hà",
    lat: "21.4506",
    lng: "107.7559",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cổ Phúc",
    lat: "21.7558",
    lng: "104.8277",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Yên Bái",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cành Nàng",
    lat: "20.3480",
    lng: "105.2288",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thạnh Hóa",
    lat: "10.6574",
    lng: "106.1808",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đăk Đoa",
    lat: "13.9950",
    lng: "108.1121",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kiến Đức",
    lat: "11.9922",
    lng: "107.5109",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Nông",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Biên",
    lat: "11.5444",
    lng: "106.0116",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tây Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phong Thổ",
    lat: "22.5443",
    lng: "103.2978",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lai Châu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hoài Ân",
    lat: "14.3635",
    lng: "108.9648",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đức Phong",
    lat: "11.8135",
    lng: "107.2443",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Phước",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Yên Minh",
    lat: "23.1179",
    lng: "105.1416",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lũng Hồ",
    lat: "22.9848",
    lng: "105.2398",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hát Lót",
    lat: "21.1973",
    lng: "104.1060",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Vĩnh Tuy",
    lat: "22.2744",
    lng: "104.8909",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Việt Quang",
    lat: "22.4151",
    lng: "104.8068",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Thuận Châu",
    lat: "21.4385",
    lng: "103.6900",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đồng Mỏ",
    lat: "21.6570",
    lng: "106.5783",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Di Linh",
    lat: "11.5815",
    lng: "108.0764",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kiến Giang",
    lat: "17.2233",
    lng: "106.7901",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Quảng Uyên",
    lat: "22.6950",
    lng: "106.4424",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Trùng Khánh",
    lat: "22.8351",
    lng: "106.5250",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mường Ảng",
    lat: "21.5199",
    lng: "103.2229",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Điện Biên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phố Châu",
    lat: "18.5133",
    lng: "105.4241",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Vinh Quang",
    lat: "22.7408",
    lng: "104.6796",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Phú",
    lat: "11.4512",
    lng: "106.8681",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Phước",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Cô Tô",
    lat: "20.9727",
    lng: "107.7658",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Than Uyên",
    lat: "21.9591",
    lng: "103.8800",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lai Châu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chư Ty",
    lat: "13.8051",
    lng: "107.6961",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Hưng",
    lat: "10.8348",
    lng: "105.6614",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Long An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Văn Quan",
    lat: "21.8652",
    lng: "106.5393",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phù Yên",
    lat: "21.2633",
    lng: "104.6444",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ea Drăng",
    lat: "13.2045",
    lng: "108.2103",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mai Châu",
    lat: "20.6615",
    lng: "105.0824",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thuận Nam",
    lat: "10.8500",
    lng: "107.8786",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Nước Hai",
    lat: "22.7374",
    lng: "106.1522",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Sơn",
    lat: "11.7752",
    lng: "108.7848",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đắk Mâm",
    lat: "12.4579",
    lng: "107.8625",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Nông",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Vĩnh Lộc",
    lat: "22.1436",
    lng: "105.2719",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tuyên Quang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Huyện Chiêm Hóa",
    lat: "22.1441",
    lng: "105.2728",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tuyên Quang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phong Điền",
    lat: "16.5832",
    lng: "107.3644",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thừa Thiên Huế",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bắc Sơn",
    lat: "21.9000",
    lng: "106.3208",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Phố Ràng",
    lat: "22.2343",
    lng: "104.4764",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Mường Khương",
    lat: "22.7614",
    lng: "104.1216",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sơn Hà",
    lat: "15.0419",
    lng: "108.4697",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đạ Tẻh",
    lat: "11.5115",
    lng: "107.4909",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Tà Lùng",
    lat: "22.4979",
    lng: "106.5669",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tánh Linh",
    lat: "11.0842",
    lng: "107.6809",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Na Sầm",
    lat: "22.0564",
    lng: "106.6151",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Châu",
    lat: "21.0458",
    lng: "104.3010",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Cát",
    lat: "19.6633",
    lng: "105.4285",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Tam Sơn",
    lat: "23.0674",
    lng: "104.9893",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hoàn Lão",
    lat: "17.5844",
    lng: "106.5342",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đắk Song",
    lat: "12.2552",
    lng: "107.6092",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Nông",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đắk Hà",
    lat: "14.5135",
    lng: "107.9210",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đắk Tô",
    lat: "14.6609",
    lng: "107.8389",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tam Đường",
    lat: "22.3181",
    lng: "103.6262",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lai Châu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sông Mã",
    lat: "21.0509",
    lng: "103.7499",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mộc Châu",
    lat: "20.8520",
    lng: "104.6218",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Hùng Quốc",
    lat: "22.8290",
    lng: "106.3234",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Hòa",
    lat: "14.1000",
    lng: "107.9690",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lộc Thắng",
    lat: "11.6284",
    lng: "107.8351",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "An Châu",
    lat: "21.3346",
    lng: "106.8493",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Tủa Chùa",
    lat: "21.8577",
    lng: "103.3426",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Điện Biên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sa Pa",
    lat: "22.3356",
    lng: "103.8419",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lộc Bình",
    lat: "21.7587",
    lng: "106.9233",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Bình",
    lat: "22.4099",
    lng: "104.5795",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bắc Hà",
    lat: "22.5391",
    lng: "104.2931",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quán Hàu",
    lat: "17.4044",
    lng: "106.6407",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hiệp Đức",
    lat: "15.5822",
    lng: "108.1142",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Krông Kmar",
    lat: "12.5045",
    lng: "108.3329",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hương Khê",
    lat: "18.1767",
    lng: "105.7002",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lang Chánh",
    lat: "20.1561",
    lng: "105.2422",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Tuần Giáo",
    lat: "21.5899",
    lng: "103.4218",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Điện Biên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ma Đa Gui",
    lat: "11.3870",
    lng: "107.5351",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thường Xuân",
    lat: "19.9029",
    lng: "105.3472",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Xuân Hoà",
    lat: "22.9024",
    lng: "106.0774",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Việt Lâm",
    lat: "22.6249",
    lng: "104.9599",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Vị Xuyên",
    lat: "22.6676",
    lng: "104.9819",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ít Ong",
    lat: "21.5175",
    lng: "104.0270",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Minh Long",
    lat: "14.9322",
    lng: "108.7033",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đồng Lê",
    lat: "17.8839",
    lng: "106.0254",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Côn Đảo",
    lat: "8.6833",
    lng: "106.6063",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bà Rịa - Vũng Tàu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đà Bắc",
    lat: "20.8771",
    lng: "105.2542",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hòa Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trà Bồng",
    lat: "15.2557",
    lng: "108.5172",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chư Prông",
    lat: "13.7568",
    lng: "107.8862",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chợ Rã",
    lat: "22.4511",
    lng: "105.7191",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Kạn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bát Xát",
    lat: "22.5431",
    lng: "103.8899",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ia Pa",
    lat: "13.5336",
    lng: "108.4553",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Chợ Lầu",
    lat: "11.2216",
    lng: "108.5071",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Khe Sanh",
    lat: "16.6282",
    lng: "106.7388",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tô Hạp",
    lat: "12.0058",
    lng: "108.9487",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Thông Nông",
    lat: "22.7841",
    lng: "105.9832",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mường Chiên",
    lat: "21.8424",
    lng: "103.5661",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yên Phú",
    lat: "22.7322",
    lng: "105.3715",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Pác Miầu",
    lat: "22.8297",
    lng: "105.4933",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tân Việt",
    lat: "22.8641",
    lng: "105.4590",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Củng Sơn",
    lat: "13.0500",
    lng: "108.9789",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sa Thầy",
    lat: "14.4138",
    lng: "107.7934",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trới",
    lat: "21.0314",
    lng: "106.9910",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ninh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Plei Kần",
    lat: "14.7049",
    lng: "107.6862",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bộc Bố",
    lat: "22.6121",
    lng: "105.6671",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Kạn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bắc Yên",
    lat: "21.2463",
    lng: "104.4328",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đồng Xuân",
    lat: "13.3773",
    lng: "109.1081",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Thất Khê",
    lat: "22.2553",
    lng: "106.4736",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phủ Thông",
    lat: "22.2731",
    lng: "105.8794",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Kạn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Khánh Yên",
    lat: "22.0894",
    lng: "104.2515",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lào Cai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Thanh Nhật",
    lat: "22.6875",
    lng: "106.6700",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kon Dơng",
    lat: "14.0389",
    lng: "108.2501",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hai Riêng",
    lat: "12.9791",
    lng: "108.8973",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Phú Yên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bằng Lũng",
    lat: "22.1578",
    lng: "105.5956",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Kạn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Bảo Lạc",
    lat: "22.9492",
    lng: "105.6805",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Điện Biên Đông",
    lat: "21.2967",
    lng: "103.2201",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Điện Biên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "M’Đrăk",
    lat: "12.7462",
    lng: "108.7428",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lắk",
    lat: "12.4109",
    lng: "108.1768",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Sìn Hồ",
    lat: "22.3665",
    lng: "103.2344",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lai Châu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Phú Túc",
    lat: "13.1968",
    lng: "108.6904",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Tây Trà",
    lat: "15.1695",
    lng: "108.3600",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kiên Lương",
    lat: "10.2862",
    lng: "104.6462",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiến Giang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quỳ Châu",
    lat: "19.5521",
    lng: "105.0939",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bình Gia",
    lat: "21.9609",
    lng: "106.3645",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Nguyên Bình",
    lat: "22.6500",
    lng: "105.9598",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vũ Quang",
    lat: "18.3838",
    lng: "105.5059",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hà Tĩnh",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ba Tơ",
    lat: "14.7636",
    lng: "108.7322",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Ngãi",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Yến Lạc",
    lat: "22.2369",
    lng: "106.1815",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Kạn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Trà My",
    lat: "15.3397",
    lng: "108.2207",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Đông Khê",
    lat: "22.4294",
    lng: "106.4319",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Cao Bằng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vân Tùng",
    lat: "22.4308",
    lng: "105.9969",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bắc Kạn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quan Hóa",
    lat: "20.3848",
    lng: "105.1002",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mù Cang Chải",
    lat: "21.8513",
    lng: "104.0895",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Yên Bái",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Na Hang",
    lat: "22.3492",
    lng: "105.3824",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Tuyên Quang",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Lạc Dương",
    lat: "12.0041",
    lng: "108.4191",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lâm Đồng",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vĩnh Thạnh",
    lat: "14.1088",
    lng: "108.7838",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mường Lát",
    lat: "20.5292",
    lng: "104.6014",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "K Bang",
    lat: "14.1445",
    lng: "108.5991",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ia Kha",
    lat: "13.9624",
    lng: "107.8448",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Trạm Tấu",
    lat: "21.4666",
    lng: "104.3797",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Yên Bái",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quan Sơn",
    lat: "20.2591",
    lng: "104.9452",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thanh Hóa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Con Cuông",
    lat: "19.0500",
    lng: "104.8812",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Ea Súp",
    lat: "13.0662",
    lng: "107.8855",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Đắk Lắk",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Khe Tre",
    lat: "16.1691",
    lng: "107.7179",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thừa Thiên Huế",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "A Lưới",
    lat: "16.2723",
    lng: "107.2338",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Thừa Thiên Huế",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "An Lão",
    lat: "14.6124",
    lng: "108.8899",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Quy Đạt",
    lat: "17.8046",
    lng: "105.9704",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Bình",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kông Chro",
    lat: "13.7753",
    lng: "108.5256",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Gia Lai",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mường Chà",
    lat: "21.7617",
    lng: "103.0978",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Điện Biên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Krông Klang",
    lat: "16.6891",
    lng: "106.8874",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Trị",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mường Xén",
    lat: "19.3980",
    lng: "104.1524",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kim Sơn",
    lat: "19.6041",
    lng: "104.9190",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đắk Glei",
    lat: "15.0864",
    lng: "107.7358",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Vân Canh",
    lat: "13.6195",
    lng: "108.9981",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Bình Định",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Khánh Vĩnh",
    lat: "12.2835",
    lng: "108.9047",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Khánh Hòa",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Sốp Cộp",
    lat: "20.9388",
    lng: "103.5971",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Sơn La",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đắk Rve",
    lat: "14.5072",
    lng: "108.2473",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Prao",
    lat: "15.9244",
    lng: "107.6481",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hòa Bình",
    lat: "19.2639",
    lng: "104.4723",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Nghệ An",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Mường Nhé",
    lat: "22.1924",
    lng: "102.4579",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Điện Biên",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Bác Ái",
    lat: "11.8410",
    lng: "108.9032",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Ninh Thuận",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Đình Lập",
    lat: "21.5463",
    lng: "107.0981",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lạng Sơn",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Khâm Đức",
    lat: "15.4451",
    lng: "107.7934",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thị Trấn Mường Tè",
    lat: "22.3807",
    lng: "102.8201",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Lai Châu",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Kon Plông",
    lat: "14.6006",
    lng: "108.2909",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kon Tum",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Thạnh Mỹ",
    lat: "15.7489",
    lng: "107.8368",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Quảng Nam",
    capital: "minor",
    population: "",
    population_proper: "",
  },
  {
    city: "Hoà Bình",
    lat: "20.8275",
    lng: "105.3391",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Hoà Bình",
    capital: "admin",
    population: "250660",
    population_proper: "250660",
  },
  {
    city: "Kiên Giang",
    lat: "10.0215",
    lng: "105.0911",
    country: "Vietnam",
    iso2: "VN",
    admin_name: "Kiên Giang",
    capital: "admin",
    population: "250660",
    population_proper: "250660",
  },
];

export const DEFAULT_RADIUS = 20000;
export const DEFAULT_RADIUS_KM = DEFAULT_RADIUS / 1000;

// export default provinces;

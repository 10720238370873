/* eslint-disable indent */
import { useState, useEffect } from "react";
import VBannerPage from "components/v-teamplate/v-banner-page";
import React from "react";
import { Container, Row, Col, Accordion } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getQuotas } from "redux/reducers/quotas/quotas.selector";
import ComingSoon from "pages/coming-soon/comingsoon";
import VRightView from "components/v-right-view";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const Quotations = () => {
  const _quotas = useAppSelector(getQuotas);
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  const [state, setState] = useState([]);
  useEffect(() => {
    if (_quotas?.length > 0) {
      setState(_quotas);
    }
  }, [_quotas]);

  return (
    <Container>
      <Row>
        <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
          <VBannerPage position="banner_top_quota" />
          <div id="faqAccordion">
            {state?.length > 0 ? (
              <Row>
                <Col lg="12">
                  <Accordion id="accordionExample" defaultActiveKey="0">
                    {state.map((el, index) => (
                      <Accordion.Item
                        key={index}
                        className="mb-3"
                        eventKey={`${index}`}
                      >
                        <Accordion.Header id={`heading${index}`}>
                          {el.name}
                        </Accordion.Header>
                        <Accordion.Body>
                          {Array.isArray(el.content) &&
                            el.content?.map((item, index) => (
                              <p key={index}>{item.name}</p>
                            ))}
                        </Accordion.Body>
                      </Accordion.Item>
                    ))}
                  </Accordion>
                </Col>
              </Row>
            ) : (
              <ComingSoon />
            )}
          </div>
        </Col>
        {show_banner_right_on_container && (
          <Col lg={4} className={"d-none d-lg-block px-1"}>
            <VRightView
              menuKey={"tab_right_quota"}
              positionBanner={"banner_right_quota"}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Quotations;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import useTranslation from "hooks/useTranslation";
import { useCallback, useEffect, useRef, useState } from "react";
import { BankDepositUploadDragger, Ratio } from "./styles";
import { checkFileIsVideo, checkSrcIsVideo, getUrlImage } from "utils/common";
import Cropper from "react-easy-crop";
import VModal from "components/v-modal";
import VIcon from "components/v-icon";

const View = (props: any) => {
  const {
    width,
    index,
    height,
    ratio,
    typeUpload,
    id,
    idGlobal,
    sectionName,
    file,
    setFile,
    padding,
    title,
    requeried,
    src,
    isCrop,
    aspect = 1,
    cropShape,
    hideTextUpload = false,
  } = props;
  const fileRef = useRef<any>();
  const imgRef = useRef<any>();
  const [errUpload, setErrorUpload] = useState<boolean>(false);
  const [srcVideo, setSrcVideo] = useState("");
  const { t } = useTranslation();

  //state crop
  const [crop, onCropChange] = useState({ x: 0, y: 0 });
  const [zoom, onZoomChange] = useState(1);
  const [openCrop, setOpenCrop] = useState(false);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onUpload = async () => {
    const [f] = fileRef.current.files;
    if (!isCrop || checkFileIsVideo(f)) {
      setFile(f, id, sectionName, index, idGlobal);
    }

    if (checkFileIsVideo(f)) {
      setSrcVideo(URL.createObjectURL(f));
      imgRef.current.src = null;
    } else {
      setSrcVideo("");
      imgRef.current.src = URL?.createObjectURL(f);
      // setOpenCrop(true);
    }
    try {
      const formData = new FormData();
      formData.append("files", f);
      // const res: any = await APIs.UPLOAD.uploadFile({
      //   body: formData
      // })
      // if(res){
      //   setErrorUpload(false)
      //   setDataByKey("proof", res.filePath)
      // } else {
      //   setErrorUpload(true)
      // }
    } catch (error) {
      // console.log(error);
      setErrorUpload(true);
    }
  };

  useEffect(() => {
    if (src) {
      if (checkSrcIsVideo(src)) {
        setSrcVideo(src);
      } else {
        imgRef.current.src = getUrlImage(src);
      }
    }
  }, [src]);

  useEffect(() => {
    if (
      typeUpload &&
      sectionName &&
      file &&
      typeUpload !== "video" &&
      typeUpload !== "application/pdf"
    ) {
      imgRef.current.src = URL.createObjectURL(file);
    }
  }, [sectionName, file]);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
      image.src = url;
    });

  const getRadianAngle = (degreeValue) => {
    return (degreeValue * Math.PI) / 180;
  };

  /**
   * Returns the new bounding area of a rotated rectangle.
   */
  const rotateSize = (width, height, rotation) => {
    const rotRad = getRadianAngle(rotation);

    return {
      width:
        Math.abs(Math.cos(rotRad) * width) +
        Math.abs(Math.sin(rotRad) * height),
      height:
        Math.abs(Math.sin(rotRad) * width) +
        Math.abs(Math.cos(rotRad) * height),
    };
  };

  function dataURLtoFile(dataurl, filename, type) {
    var arr = dataurl.split(","),
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: type });
  }

  //Usage example:

  const getCroppedImg = async (
    imageSrc,
    pixelCrop,
    rotation = 0,
    flip = { horizontal: false, vertical: false }
  ) => {
    const image: any = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    if (!ctx) {
      return null;
    }

    const rotRad = getRadianAngle(rotation);

    // calculate bounding box of the rotated image
    const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
      image.width,
      image.height,
      rotation
    );

    // set canvas size to match the bounding box
    canvas.width = bBoxWidth;
    canvas.height = bBoxHeight;

    // translate canvas context to a central location to allow rotating and flipping around the center
    ctx.translate(bBoxWidth / 2, bBoxHeight / 2);
    ctx.rotate(rotRad);
    ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1);
    ctx.translate(-image.width / 2, -image.height / 2);

    // draw rotated image
    ctx.drawImage(image, 0, 0);

    const croppedCanvas = document.createElement("canvas");

    const croppedCtx = croppedCanvas.getContext("2d");

    if (!croppedCtx) {
      return null;
    }

    // Set the size of the cropped canvas
    croppedCanvas.width = pixelCrop.width;
    croppedCanvas.height = pixelCrop.height;

    // Draw the cropped image onto the new canvas
    croppedCtx.drawImage(
      canvas,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );

    // As Base64 string

    return croppedCanvas.toDataURL("image/jpeg");

    // As a blob
    // return new Promise((resolve, reject) => {
    //   croppedCanvas.toBlob((file) => {
    //     resolve(URL.createObjectURL(file));
    //   }, "image/jpeg");
    // });
  };

  const submitCrop = async () => {
    try {
      const croppedImage = await getCroppedImg(
        imgRef.current.src,
        croppedAreaPixels,
        rotation
      );
      const [f] = fileRef.current.files;
      const file = dataURLtoFile(croppedImage, f.name, f.type);
      setFile(file, id, sectionName, index, idGlobal);
      imgRef.current.src = croppedImage;
    } catch (e) {}
    setOpenCrop(false);
  };

  return (
    <>
      <BankDepositUploadDragger
        htmlFor={id}
        width={width}
        height={height}
        errUpload={errUpload || requeried}
        padding={padding}
      >
        {!Boolean(file) ? (
          <div hidden={src}>
            {!hideTextUpload && t("Upload")}
            <Ratio>{ratio}</Ratio>
            <VIcon className="d-flex justify-content-center" name="add_blue.svg" />
            {title}
          </div>
        ) : (
          ""
        )}
        {srcVideo ? (
          <video
            src={getUrlImage(srcVideo)}
            width="auto"
            height="100%"
            autoPlay
            loop
          >
            Your browser does not support HTML video.
          </video>
        ) : typeUpload === "application/pdf" && file ? (
          file.name
        ) : src && !checkSrcIsVideo(src) ? (
          <img ref={imgRef} alt={id} />
        ) : (
          <img hidden={!Boolean(file)} ref={imgRef} alt={id} />
        )}
      </BankDepositUploadDragger>
      <input
        hidden={true}
        id={id}
        ref={fileRef}
        type="file"
        className="custom-file-input"
        accept={`${typeUpload ? `${typeUpload}/*` : "video/*,image/*"}`}
        required
        onChange={onUpload}
      />
      {isCrop && (
        <VModal
          isOpen={openCrop}
          centered={true}
          classNameBody={"h-100vh"}
          onCancel={() => setOpenCrop(false)}
          onConfirm={submitCrop}
        >
          <div>
            <Cropper
              image={imgRef?.current?.src || getUrlImage(src)}
              crop={crop}
              zoom={zoom}
              rotation={rotation}
              cropShape={cropShape}
              onCropChange={onCropChange}
              onZoomChange={onZoomChange}
              aspect={aspect}
              objectFit={"cover"}
              // style={{
              //   containerStyle: {
              //     backgroundColor: "white",
              //     background: "transparent"
              //   }}
              // }
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              // onMediaLoaded={(mediaSize) => {
              //   // Adapt zoom based on media size to fit max height
              //   onZoomChange(5 / 3);
              // }}
            />
          </div>
        </VModal>
      )}
    </>
  );
};

export default View;

import { useAppSelector } from "redux/reducers/hook";
import LanguageAuth from "./language";
import { getGeneral } from "redux/reducers/settings/settings.selector";
import { useMemo } from "react";
import { getUrlImage } from "utils/common";

const HeaderForm = () => {
  const generalSeetting = useAppSelector(getGeneral);
  const logo = useMemo(() => {
    const _logo = generalSeetting?.site?.find(
      (site) => site?.key?.toLowerCase() === "login_logo"
    )?.site_media;
    if (_logo) {
      return _logo
    }
    return "";
  }, [generalSeetting]);

  return (
    <div className="d-flex justify-content-between">
      <div className="auth-logo">
        <img src={logo ? getUrlImage(logo) : "/assets/images/logo/logo-auth.png" } alt="" />
      </div>
      <LanguageAuth />
    </div>
  );
};

export default HeaderForm;

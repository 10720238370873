/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
// import { useHistory } from "react-router-dom";

import { getDepositBanks as getDepositBanksSelector } from "redux/reducers/banks/banks.selector";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

import Step1 from "./Step1";
import Step2 from "./Step2";
import Step3 from "./Step3";
import Step4 from "./Step4";
import HistoryP2P from "../history-p2p";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import useQuery from "hooks/useQuery";
import queryString from "query-string";
import { getDepositBanksAsync } from "redux/reducers/banks/banks.actions";
import PaymentService from "api/payments";

const titles = {
  0: "Select currency",
  1: "Select amount",
  2: "Select deposit methods",
  3: "Confirm deposit information",
  4: "Send and upload proof",
};
type IProps = {
  url: string;
  userId?: string;
  reloadBalance?: () => {};
};

const View = (props: IProps) => {
  const { url, userId, reloadBalance } = props;
  const history = useHistory();
  const { t } = useTranslation();
  const defaultData = {
    bank: null,
    amount: null,
    content: "",
    proof: "",
    currency: "",
    hash: null,
    network: "vzonex",
    stock: "",
  };

  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(1);
  const [dataPayment, setDataPayment] = useState(defaultData);
  const depositBanks = useSelector(getDepositBanksSelector);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const query = useQuery();

  useEffect(() => {
    if (dataPayment.stock) {
      dispatch(getDepositBanksAsync());
      getPayment();
    }
  }, [dataPayment.stock]);

  useEffect(() => {
    if (query.stock) {
      setDataPayment((prev) => ({
        ...prev,
        stock: query.stock,
      }));
    }
  }, [query]);

  const nextStep = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const backStep = () => {
    if (activeStep === 1) {
      const _query = {
        ...query,
        method: "deposit_stock",
      };
      history.push(`${url}/?${queryString.stringify(_query)}`);
    }
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const canclePayment = () => {
    setActiveStep(0);
    setDataPayment(defaultData);
  };

  const setDataByKey = (key, value) => {
    setDataPayment({
      ...dataPayment,
      [key]: value,
    });
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      await PaymentService.createPayment({
        body: {
          user_id: userId,
          type: "deposit",
          bank: dataPayment.bank,
          amount: dataPayment.amount,
          content: dataPayment.content,
          proof: dataPayment.proof,
          currency: dataPayment.currency,
          hash: dataPayment.hash,
          stock: dataPayment.stock,
          network: dataPayment.network,
        },
      });
      reloadBalance();
      setLoading(false);
      setActiveStep(1);
      getPayment();
      setDataPayment(defaultData);
      toast.success(t("Deposit successful!"));
    } catch (err) {
      // handle error
      toast.error(t(err.message));
    }
    setLoading(false);
  };

  const getPayment = async () => {
    try {
      const res: any = await PaymentService.getPaymentByUserId({
        userId: userId,
        type: "deposit",
        stock: dataPayment.stock,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Card isOpacity={true}>
      <div className="title-action-wallets">
        <p>{t(titles[activeStep])}</p>
        <div className="d-flex mt-2">
          <div className={`dot-step ${activeStep === 0 ? "active" : ""}`} />
          <div className={`dot-step ${activeStep === 1 ? "active" : ""}`} />
          <div className={`dot-step ${activeStep === 2 ? "active" : ""}`} />
          <div className={`dot-step ${activeStep === 3 ? "active" : ""}`} />
          <div className={`dot-step ${activeStep === 4 ? "active" : ""}`} />
        </div>
      </div>

      {activeStep === 1 && (
        <Step1
          dataPayment={dataPayment}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          backStep={backStep}
        />
      )}
      {activeStep === 2 && (
        <Step2
          dataPayment={dataPayment}
          nextStep={nextStep}
          setDataByKey={setDataByKey}
          depositBanks={depositBanks}
          backStep={backStep}
        />
      )}
      {activeStep === 3 && (
        <Step3
          nextStep={nextStep}
          dataPayment={dataPayment}
          setDataByKey={setDataByKey}
          backStep={backStep}
        />
      )}
      {activeStep === 4 && (
        <Step4
          canclePayment={canclePayment}
          setDataByKey={setDataByKey}
          dataPayment={dataPayment}
          handleSubmit={handleSubmit}
          loading={loading}
          backStep={backStep}
        />
      )}

      {hisDeposit.length ? (
        <HistoryP2P
          lists={hisDeposit}
          type="deposit"
          stock={dataPayment.stock}
        />
      ) : (
        <></>
      )}
    </Card>
  );
};

export default View;

import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const API_URL_PRO =
  process.env.REACT_APP_API_URL_PRO || "http://localhost:3001/api";

const getFilesByType = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`${API_URL_PRO}/post-files/${req.userId}?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getFilesAlbumByType = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`${API_URL_PRO}/posts/by-user/${req.userId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCountFilesAdv = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `${API_URL_PRO}/post-files/count/${req.userId}/adv?${qs.stringify(
          req.query
        )}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCountFilesByUser = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `${API_URL_PRO}/post-files/count/${req.userId}?${qs.stringify(
          req.query
        )}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const makeDefault = (req) =>
  new Promise((resolve, reject) => {
    request()
      .patch(`${API_URL_PRO}/post-files/make-default/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        resolve(data);
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const FilesService = {
  getFilesByType,
  getFilesAlbumByType,
  getCountFilesByUser,
  getCountFilesAdv,
  makeDefault,
};

export default FilesService;

/* eslint-disable indent */
import VIcon from "components/v-icon";
import { Form } from "react-bootstrap";
import useTranslation from "hooks/useTranslation";
import { clsx } from "clsx";

type IProps = {
  handleChange?: any;
  handleKeyDown?: any;
  className?: string;
  handleClick?: any;
};
const View = (props: IProps) => {
  const { handleChange, handleKeyDown, className, handleClick } = props;
  const { t } = useTranslation();
  return (
    <div
      className="v-search"
      onClick={(e) => {
        e.stopPropagation();
        handleClick();
      }}
    >
      <div
        className="searchbox"
        data-bs-toggle="modal"
        data-bs-target="#exampleModalFullscreenSm"
      >
        <div className="search-link">
          <VIcon width={16} name="search.svg" />
        </div>
        <Form.Control
          type="text"
          className={clsx(
            className ? className : "search-input bg-soft-primary"
          )}
          placeholder={
            className ? `${t("Tìm kiếm")}...` : `${t("Search here")}...`
          }
          onChange={(e) => handleChange(e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </div>
    </div>
  );
};

export default View;

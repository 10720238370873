/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import { getFollowSuggests } from "redux/reducers/friends/friends.selector";
import { getFollowSuggestsAsync } from "redux/reducers/friends/friends.action";
import { removeFollowSuggest } from "redux/reducers/friends/friends.reducer";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";
import VFriend from "components/v-objects/v-friend";
import { getDataAccessByOwnerAsync } from "redux/reducers/users/users.actions";
import { FRIEND_TYPE } from "config/enums";
import VInfiniteLoad from "components/v-infinite-load";

const View = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const friends: any = useAppSelector(getFollowSuggests);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = friends;

  useEffect(() => {
    dispatch(getDataAccessByOwnerAsync());
  }, []);

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    dispatch(
      getFollowSuggestsAsync({
        params: {
          type: "follow",
        },
        query: {
          page,
          limit,
        },
      })
    );

    setLoading(false);
  };

  const handleViewMore = () => {
    setLoading(true);
    getData({
      page: page + 1,
    });
  };

  const reload = ({ to_id }) => {
    dispatch(removeFollowSuggest(to_id));
    dispatch(getDataAccessByOwnerAsync());
  };

  return (
    friends.docs && (
      <div>
        <div className="header-title">
          <h4 className="card-title">{t("Follow")}</h4>
        </div>
        <VInfiniteLoad
          canLoadMore={page + 1 <= friends.totalPages}
          isLoadingMore={loading}
          onLoadMore={handleViewMore}
        >
          <div className="d-flex flex-wrap">
            {(friends.docs || []).map(
              (friend, index) =>
                friend && (
                  <div
                    key={index}
                    className={`d-flex justify-content-center p-1 col-6 col-lg-2 col-md-3`}
                  >
                    <div className="rps-container">
                      <VFriend
                        friend={{
                          to: friend,
                          to_id: friend._id,
                          profile: friend.profile
                        }}
                        dataAccessFriend={friend.data_access}
                        reload={reload}
                        type={FRIEND_TYPE.FOLLOW}
                      />
                    </div>
                  </div>
                )
            )}
          </div>
        </VInfiniteLoad>
      </div>
    )
  );
};

export default React.memo(View, (prev, next) => {
  return true;
});

import useTranslation from "hooks/useTranslation";
import Countdown from "react-countdown";

const View = ({ date, isShowLabel = true }) => {
  const { t } = useTranslation();

  const CountDownElement = (props) => {
    return (
      <div className="countdown-section">
        <div className="countdown-item">
          <span className="countdown-item-value">{props.days}</span>
          {isShowLabel && (
            <span className="countdown-item-label">{t("Days")}</span>
          )}
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.hours}</span>
          {isShowLabel && (
            <span className="countdown-item-label">{t("Hours")}</span>
          )}
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.minutes}</span>
          {isShowLabel && (
            <span className="countdown-item-label">{t("Minutes")}</span>
          )}
        </div>
        <div className="countdown-item">
          <span className="countdown-item-value">{props.seconds}</span>
          {isShowLabel && (
            <span className="countdown-item-label">{t("Seconds")}</span>
          )}
        </div>
      </div>
    );
  };

  const countdow_render = ({ days, hours, minutes, seconds }) => {
    return (
      <CountDownElement
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    );
  };

  return (
    <div className="v-countdown">
      <Countdown date={date} renderer={countdow_render} />
    </div>
  );
};

export default View;

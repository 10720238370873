/* eslint-disable max-len */
/* eslint-disable indent */
import { useEffect, useRef, useState } from "react";
import { Form } from "react-bootstrap";
import Dropdown from "components/v-dropdown";
import VInput from "components/v-input";
import { useAppSelector } from "redux/reducers/hook";
import { getLocations } from "redux/reducers/settings/settings.selector";
import useTranslation from "hooks/useTranslation";
import SettingService from "api/settings";
import VMap from "components/v-map";
import axios from "axios";
import { removeVietnameseTones } from "utils/common";
import VButton from "components/v-button";

type IAddress = {
  country?: string;
  province?: string;
  district?: string;
  ward?: string;
  detail?: string;
};

type IProps = {
  address: IAddress;
  isDisabled: boolean;
  styleProps?: any;
  hideLabel?: boolean;
  hideDetail?: boolean;
  onlyShowCountry?: boolean;
  required?: boolean;
  isShowMap?: boolean;
  setAddress: (data: IAddress) => void;
};

const View = (props: IProps) => {
  const {
    address,
    styleProps,
    hideLabel,
    hideDetail,
    isDisabled,
    onlyShowCountry,
    required,
    isShowMap = false,
    setAddress,
  } = props;
  const { t } = useTranslation();

  const [countries, setCountries] = useState<any>([]);
  const [provinces, setProvinces] = useState<any>([]);
  const [districts, setDistricts] = useState<any>([]);
  const [wards, setWards] = useState<any>([]);
  const locations = useAppSelector(getLocations);
  const firstLoad = useRef(false);
  const [openMap, setOpenMap] = useState(false);

  useEffect(() => {
    if (locations && locations.length > 0 && !firstLoad.current && address) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locations, address, firstLoad.current]);

  const fetchData = async () => {
    setCountries(locations?.filter((a) => a.type === "country"));
    if (address.country) {
      firstLoad.current = true;
      const _provinces = await getLocationsByParent(address.country);
      setProvinces(_provinces);
      if (address.province) {
        const _districts: any = await getLocationsByParent(address.province);
        setDistricts(_districts);
      }
      if (address.district) {
        const _wards: any = await getLocationsByParent(address.district);
        setWards(_wards);
      }
    }
  };

  const handleChange = (e: any, key: any) => {
    setAddress({
      ...address,
      [key]: e.target.value,
    });
  };

  const convertLocationId = async (data) => {
    const _country = locations?.filter((a) => a.type === "country");
    setCountries(_country);
    let _address = { ...address };
    if (!data) return;

    const convertStringLocation = (name: string) => {
      return removeVietnameseTones(String(name))
        ?.replaceAll("District", "")
        ?.replaceAll("City", "")
        ?.replaceAll("Ward", "")
        ?.replaceAll(" ", "")
        ?.toLowerCase();
    };
    if (data.country) {
      const _countryDetails = _country?.find((el) =>
        convertStringLocation(el.name)?.includes(
          convertStringLocation(data.country)
        )
      );
      _address = {
        ..._address,
        country: _countryDetails?._id,
      };

      const _provinces: any = await getLocationsByParent(_countryDetails?._id);
      if (data.province) {
        const _provincesDetails = _provinces?.find((el) =>
          convertStringLocation(el.name)?.includes(
            convertStringLocation(data.province)
          )
        );
        _address = {
          ..._address,
          province: _provincesDetails?._id,
        };
        const _districts: any = await getLocationsByParent(
          _provincesDetails?._id
        );

        if (data.district) {
          const _districtDetails = _districts?.find((el) =>
            convertStringLocation(el.name)?.includes(
              convertStringLocation(data.district)
            )
          );
          _address = {
            ..._address,
            district: _districtDetails?._id,
          };

          const _wards: any = await getLocationsByParent(_districtDetails?._id);

          if (data.ward) {
            const _wardDetails = _wards?.find((el) =>
              convertStringLocation(el.name)?.includes(
                convertStringLocation(data.ward)
              )
            );
            _address = {
              ..._address,
              ward: _wardDetails?._id,
            };
          }
        }
      }
    }
    setAddress({
      ..._address,
      detail: data?.detail || "",
    });
    firstLoad.current = false;
  };

  const onSelectRegionMaps = async (location) => {
    if (location) {
      const data: any = await axios.get(
        `https://geocode.maps.co/reverse?lat=${location.lat}&lon=${location.lng}`
      );
      const dataCity: any = await axios.get(
        `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${location.lat}&longitude=${location.lng}&localityLanguage=vi`
      );

      const dataDetails = data?.data?.address;
      const dataAddress = {
        country: dataCity?.data?.countryName,
        province: dataCity?.data?.city,
        district:
          data?.data?.address?.city_district || dataCity?.data?.locality,
        ward: dataDetails?.suburb,
        detail: `${
          dataDetails?.house_number ? `${dataDetails?.house_number} ,` : ""
        }${dataDetails?.quarter ? `${dataDetails?.quarter} ,` : ""}${
          dataDetails?.road ? `${dataDetails?.road} ` : ""
        }${
          dataDetails?.neighbourhood ? `${dataDetails?.neighbourhood} ,` : ""
        }`,
      };
      if (firstLoad.current) {
        convertLocationId(dataAddress);
      }
    }
  };

  const getLocationsByParent = async (parent) => {
    try {
      const locations = await SettingService.getLocations({
        query: {
          parent: parent,
        },
      });
      return locations;
    } catch (error) {
      return [];
    }
  };

  const setFilterCities = async (key: string, e: any) => {
    const code = e.target.value;
    if (key === "country") {
      setDistricts([]);
      setWards([]);
      if (code) {
        const _provices = await getLocationsByParent(code);
        if (_provices) {
          setProvinces(_provices);
          setAddress({
            country: code,
            province: null,
            district: null,
            ward: null,
          });
        } else {
          setAddress({
            country: null,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setAddress({
          country: null,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "province") {
      setWards([]);
      if (code) {
        const _districts = await getLocationsByParent(code);
        if (_districts) {
          setDistricts(_districts);
          setAddress({
            ...address,
            province: code,
            district: null,
            ward: null,
          });
        } else {
          setDistricts([]);
          setAddress({
            ...address,
            province: null,
            district: null,
            ward: null,
          });
        }
      } else {
        setDistricts([]);
        setAddress({
          ...address,
          province: null,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "district") {
      if (code) {
        const _wards = await getLocationsByParent(code);
        setWards(_wards);
        setAddress({
          ...address,
          district: code,
          ward: null,
        });
      } else {
        setWards([]);
        setAddress({
          ...address,
          district: null,
          ward: null,
        });
      }
    }
    if (key === "ward") {
      if (code) {
        setAddress({
          ...address,
          ward: code,
        });
      } else {
        setAddress({
          ...address,
          ward: null,
        });
      }
    }
  };

  return (
    <div className="align-items-center text-nowrap">
      <div className="row">
        <Form.Group
          className={`form-group mb-0 ${styleProps?.col || "col-md-6"}`}
        >
          <Dropdown
            label={!hideLabel && <div>{t("Country")}</div>}
            disabled={isDisabled || openMap}
            required={required}
            placeholder={t("Country")}
            value={String(address.country)}
            options={countries?.map((el: any) => {
              return {
                label: el?.name,
                value: String(el?._id),
              };
            })}
            onChange={(e: any) => setFilterCities("country", e)}
          />
        </Form.Group>
        {!onlyShowCountry && (
          <Form.Group
            className={`form-group mb-0 ${styleProps?.col || "col-md-6"}`}
          >
            <Dropdown
              label={!hideLabel && <div>{t("Province")}</div>}
              disabled={isDisabled || openMap}
              placeholder={t("Province")}
              value={address.province}
              options={provinces?.map((el: any) => {
                return {
                  label: el?.name,
                  value: el?._id,
                };
              })}
              onChange={(e: any) => setFilterCities("province", e)}
            />
          </Form.Group>
        )}
        {!onlyShowCountry && (
          <Form.Group
            className={`form-group mb-0 ${styleProps?.col || "col-md-6"}`}
          >
            <Dropdown
              label={!hideLabel && t("District")}
              disabled={isDisabled || openMap}
              placeholder={t("District")}
              value={address.district}
              options={districts?.map((el: any) => {
                return {
                  label: el?.name,
                  value: el?._id,
                };
              })}
              onChange={(e: any) => setFilterCities("district", e)}
            />
          </Form.Group>
        )}
        {!onlyShowCountry && (
          <Form.Group
            className={`form-group mb-0 ${styleProps?.col || "col-md-6"}`}
          >
            <Dropdown
              label={!hideLabel && t("Ward")}
              disabled={isDisabled || openMap}
              placeholder={t("Ward")}
              value={address.ward}
              options={wards?.map((el: any) => {
                return {
                  label: el?.name,
                  value: el?._id,
                };
              })}
              onChange={(e: any) => setFilterCities("ward", e)}
            />
          </Form.Group>
        )}

        {!hideDetail && !onlyShowCountry && (
          <Form.Group className="form-group mb-0 col-md-12">
            <VInput
              label={t("Address details")}
              type="text"
              id="detail"
              name="detail"
              disabled={isDisabled || openMap}
              defaultValue={address?.detail}
              onChange={(e: any) => handleChange(e, "detail")}
            />
          </Form.Group>
        )}
        {isShowMap && (
          <>
            <div className="py-2">
              <VButton
                className={`py-0 px-2 ${
                  openMap ? "bg-danger border-danger" : ""
                }`}
                disabled={isDisabled}
                onClick={() => setOpenMap(!openMap)}
              >
                {t(!openMap ? "Use map" : "Close map")}
              </VButton>
            </div>
            {openMap && (
              <div
                style={{
                  height: "300px",
                }}
              >
                <VMap
                  className={"h-100"}
                  onSelectRegionMaps={onSelectRegionMaps}
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default View;

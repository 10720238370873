/* eslint-disable jsx-a11y/anchor-is-valid */
import { ROUTES } from "config/routes";
import useTranslation from "hooks/useTranslation";
import { Link, useHistory } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import AuthAPI from "api/auth";
import useQuery from "hooks/useQuery";
import { useEffect, useState } from "react";
import VModal from "components/v-modal";
import { OBJECT_TYPE } from "config/enums";
import { Form } from "react-bootstrap";
import VInput from "components/v-input";
import { useAppSelector } from "redux/reducers/hook";
import { getObjects } from "redux/reducers/settings/settings.selector";
import { toast } from "react-toastify";
import { getUrlImage } from "utils/common";
// import FacebookLogin from "./login-facebook"

const FooterForm = (props) => {
  const { type, isHideGoogle } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const objectsSetting = useAppSelector(getObjects);

  const [openModal, setOpenModal] = useState(false);
  const [err, setErr] = useState("");
  const [state, setState] = useState({
    object_type: "",
    referred_by: "",
  });

  const [tokenResponse, setTokenResponse] = useState(null);

  const login = async (tokenResponse) => {
    try {
      const res: any = await AuthAPI.loginGoogle({
        type: "google",
        location: {
          country: "6296ee56b4382126ae3b148e",
          province: null,
          district: null,
          ward: null,
        },
        referred_by: query?.refer_code || state.referred_by || "",
        object_type: state.object_type,
        tokenResponse: tokenResponse,
      });
      if (res) {
        if (res.isRequireRegister) {
          setOpenModal(true);
          setTokenResponse(res.tokenResponse);
          setState((prev) => ({
            ...prev,
            object_type: OBJECT_TYPE.USER,
          }));
        } else {
          if (query.redirect_url) {
            window.location.href =
              query.redirect_url + "?vdb_token=" + res.token;
          } else {
            localStorage.setItem("auth", res.token);
            history.push("/");
          }
        }
      }
    } catch (error) {
      setErr(
        t("The code is not correct ! Please check again or leave it blank")
      );
    }
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        await login(tokenResponse);
      } catch (error) {
        if (error.errors === "DATA_LOGIN_INVALID") {
          toast.error(t(error.errors));
        }
      }
    },
    onError: (err: any) => {
      console.log("Login Failed", err);
    },
  });

  const handleRegisterGoogle = async () => {
    try {
      await login(tokenResponse);
    } catch (error) {}
  };

  const handleCancel = () => {
    setOpenModal(false);
    setState({
      object_type: "",
      referred_by: "",
    });
  };

  const handleChange = (value, name) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
    setErr("");
  };

  useEffect(() => {
    if (query?.refer_code) {
      setState((prev) => ({
        ...prev,
        referred_by: query?.refer_code,
      }));
    }
  }, [query?.refer_code]);

  const renderModalRegister = (
    <>
      <label>{t("Sign up for")}</label>
      <div className="d-flex justify-content-between mt-2 mb-3">
        {objectsSetting?.length &&
          ([...objectsSetting] || [])
            .sort(
              (a, b) => (Number(a.position) || 0) - (Number(b.position) || 0)
            )
            .filter((item) => item.is_show)
            .map((el, index) => (
              <div
                key={index}
                className="item-register"
                onClick={() => handleChange(el.key, "object_type")}
              >
                <div
                  className={`logo-register ${
                    state?.object_type === el.key ? "active-logo-register" : ""
                  }`}
                >
                  <img src={getUrlImage(el.logo)} alt={el.name} />
                </div>
                <span
                  className={
                    state?.object_type === el.key ? "active-name-register" : ""
                  }
                >
                  {t(el?.key?.toLowerCase())}
                </span>
              </div>
            ))}
      </div>
      <Form.Group className="form-group col-12">
        <VInput
          label={t("Referral code")}
          type="text"
          id="referred_by"
          name="referred_by"
          errMessage={err}
          disabled={Boolean(query?.refer_code)}
          value={state.referred_by}
          onChange={(e) => handleChange(e.target.value, "referred_by")}
        />
      </Form.Group>
    </>
  );

  return (
    <>
      <div className="sign-info">
        <span className="dark-color d-inline-block line-height-2">
          {t("or")}{" "}
          <Link to={type === "login" ? ROUTES.REGISTER : ROUTES.LOGIN}>
            {type === "login"
              ? t("Create account")
              : `${t("Login")} ${t("with")}`}
          </Link>
        </span>
        <ul className="iq-social-media">
          {!isHideGoogle && (
            <li onClick={() => loginGoogle()}>
              <a href="#">
                <img src="/assets/images/logo/gmail.svg" alt="" />
              </a>
            </li>
          )}
          {/* <li>
          <a href="#">
            <FacebookLogin />
          </a>
        </li> */}
          <li onClick={() => history.push(ROUTES.LOGIN_QR)}>
            <a href="#">
              <img src="/assets/images/logo/qr-code.svg" alt="" />
            </a>
          </li>
        </ul>
      </div>
      <VModal
        title={t("Sign up")}
        isOpen={openModal}
        centered={true}
        children={renderModalRegister}
        confirmLabel={t("Sign up")}
        onCancel={() => handleCancel()}
        onConfirm={() => handleRegisterGoogle()}
      ></VModal>
    </>
  );
};

export default FooterForm;

import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import configureStore from "./redux/configureStore";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import ThemeProvider from "./context/ThemeContext/View";
import "./i18n";
import { GoogleOAuthProvider } from "@react-oauth/google";
import "./index.css";
import { NewsFeedProvider } from "context/NewsFeedContext/View";
import { FlipBookImageProvider } from "context/FlipBookImageContext/View";
import 'react-tooltip/dist/react-tooltip.css'

// import { initFacebookSdk } from "_helpers";

// import 'react-toastify/dist/ReactToastify.css';
// import 'bootstrap/dist/css/bootstrap.min.css';
// import 'react-circular-progressbar/dist/styles.css';
// import 'react-modern-drawer/dist/index.css'

const store = configureStore();

// initFacebookSdk().then(startApp);

// function startApp() {
ReactDOM.render(
  <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
    <React.StrictMode>
      <Provider store={store}>
        <ThemeProvider>
          <NewsFeedProvider>
            <FlipBookImageProvider>
              <Router>
                <App />
              </Router>
            </FlipBookImageProvider>
          </NewsFeedProvider>
        </ThemeProvider>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
);
// }

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

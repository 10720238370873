/* eslint-disable indent */
import VButton from "components/v-button";
import VIcon from "components/v-icon";
import useTranslation from "hooks/useTranslation";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
// import { useAppSelector } from "redux/reducers/hook";
// import { getUserProfile } from "redux/reducers/users/users.selector";
import { useHistory } from "react-router-dom";
import { GROUP_ACTION } from "config/enums";
import GroupService from "api/groups";
import VDropdownModal from "components/v-dropdown-modal";

type IProps = {
  reload?: any;
  type: string;
  to_id?: string;
  group_id?: string;
  loading?: boolean;
  setLoading?: any;
  isShowIcon?: boolean;
  isShowText?: boolean;
  className?: string;
  groupType?: string;
};

const View = (props: IProps) => {
  const { reload, type, to_id, group_id, isShowIcon, isShowText, className } =
    props;
  const [loading, setLoading] = useState(false);
  const profile = useAppSelector(getUserProfile);
  const { t } = useTranslation();
  const from_id = profile?._id;
  const history = useHistory();

  const requestJoin = async () => {
    try {
      setLoading(true);
      props.setLoading && props.setLoading(true);
      await GroupService.requestJoin({
        body: {
          to_id,
        },
      });
      reload &&
        reload({
          to_id,
          type,
        });
      // toast.success(t("Success"));
      setLoading(false);
      props.setLoading && props.setLoading(false);
    } catch (error) {
      toast.error(t(error.errors));
      setLoading(false);
      props.setLoading && props.setLoading(false);
    }
  };

  const changeStatusGroup = async (status) => {
    try {
      setLoading(true);
      props.setLoading && props.setLoading(true);
      if (
        status === GROUP_ACTION.ACCEPT_MEMBER ||
        status === GROUP_ACTION.CANCEL_ACCEPT_MEMBER
      ) {
        await GroupService.changeStatus({
          body: {
            from_id: to_id,
            to_id: from_id,
            status,
            group_id,
          },
        });
      } else {
        await GroupService.changeStatus({
          body: {
            from_id: from_id,
            to_id: to_id,
            status,
            group_id,
          },
        });
      }
      reload &&
        reload({
          to_id,
          type,
        });
      // toast.success(t("Success"));
      setLoading(false);
      props.setLoading && props.setLoading(false);
    } catch (error) {
      toast.error(t(error.errors));
      setLoading(false);
      props.setLoading && props.setLoading(false);
    }
  };

  switch (type) {
    case GROUP_ACTION.REQUEST_JOIN:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon ? <VIcon name="make_friend.svg" /> : <></>}
          onClick={(_) => requestJoin()}
          className={className}
        >
          {isShowText && t("Join")}
        </VButton>
      );
    case GROUP_ACTION.CANCEL_REQUEST:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon ? <VIcon name="make_friend.svg" /> : <></>}
          onClick={(_) => changeStatusGroup(GROUP_ACTION.CANCEL_REQUEST)}
          className={`btn-danger ${className}`}
        >
          {isShowText && t("Cancel Request")}
        </VButton>
      );
    case GROUP_ACTION.VIEW_DETAIL:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon ? <VIcon name="make_friend.svg" /> : <></>}
          onClick={(_) => {
            history.push(`/group/${to_id}`);
          }}
          className={`${className}`}
        >
          {isShowText && t("View detail")}
        </VButton>
      );
    case GROUP_ACTION.LEAVE_GROUP:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon ? <VIcon name="make_friend.svg" /> : <></>}
          onClick={(_) => changeStatusGroup(GROUP_ACTION.LEAVE_GROUP)}
          className={className || `btn bg-danger border-danger`}
        >
          {isShowText && t("Leave")}
        </VButton>
      );

    case GROUP_ACTION.ACCEPT_MEMBER:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/confirm-white.svg" />}
          onClick={(_) => changeStatusGroup(GROUP_ACTION.ACCEPT_MEMBER)}
          className={className}
        >
          {isShowText && t("Accept")}
        </VButton>
      );

    case GROUP_ACTION.CANCEL_ACCEPT_MEMBER:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/delete.svg" />}
          onClick={(_) => changeStatusGroup(GROUP_ACTION.CANCEL_ACCEPT_MEMBER)}
          className={className || `btn bg-danger border-danger`}
        >
          {isShowText && t("Cancel request")}
        </VButton>
      );

    case GROUP_ACTION.REMOVE_MEMBER:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/delete.svg" />}
          onClick={(_) => changeStatusGroup(GROUP_ACTION.REMOVE_MEMBER)}
          className={className || `btn bg-danger border-danger`}
        >
          {isShowText && t("Remove")}
        </VButton>
      );

    case GROUP_ACTION.DELETE:
      return (
        <VButton
          variant="danger"
          loading={loading}
          disabled
          icon={isShowIcon && <VIcon name="delete.svg" />}
        >
          {isShowText && t("Delete")}
        </VButton>
      );
    case GROUP_ACTION.CHAT:
      return (
        <VButton
          loading={loading}
          disabled
          // onClick={(_) => changeStatusFriend("cancel_request_friend_receive")}
          icon={<VIcon name="friends/chat.svg" />}
        >
          {isShowText && t("Chat")}
        </VButton>
      );
    case GROUP_ACTION.REVOKE_INVITE:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/delete.svg" />}
          onClick={(_) => changeStatusGroup(GROUP_ACTION.REVOKE_INVITE)}
          className={`${className} bg-danger`}
        >
          {isShowText && t("Revoke invite")}
        </VButton>
      );
    case GROUP_ACTION.ACCEPT_INVITE:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/confirm-white.svg" />}
          onClick={(_) => changeStatusGroup(GROUP_ACTION.ACCEPT_INVITE)}
          className={`${className}`}
        >
          {isShowText && t("Accept invite")}
        </VButton>
      );
    case GROUP_ACTION.CANCEL_INVITE:
      return (
        <VButton
          variant="danger"
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/delete.svg" />}
          onClick={(_) => changeStatusGroup(GROUP_ACTION.CANCEL_INVITE)}
          className={`${className}`}
        >
          {isShowText && t("Delete")}
        </VButton>
      );
    case GROUP_ACTION.RESPONSE:
      return (
        <VDropdownModal
          label={
            <VButton
              loading={loading}
              icon={isShowIcon && <VIcon name="friends/delete.svg" />}
              className={`${className}`}
            >
              {isShowText && t("Response")}
            </VButton>
          }
          menu={[
            {
              key: t("Confirm"),
              icon: <VIcon name="friends/confirm.svg" />,
              onClick: (e) => changeStatusGroup(GROUP_ACTION.ACCEPT_INVITE),
            },
            {
              key: t("Delete"),
              icon: <VIcon name="friends/un_friend.svg" />,
              onClick: (e) => changeStatusGroup(GROUP_ACTION.CANCEL_INVITE),
            },
          ]}
        />
      );
    default:
      break;
  }
};

export default View;

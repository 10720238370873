/* eslint-disable indent */
import FriendService from "api/friends";
import VButton from "components/v-button";
import VDropdownModal from "components/v-dropdown-modal/";
import VIcon from "components/v-icon";
import { FRIEND_ACTION } from "config/enums";
import useTranslation from "hooks/useTranslation";
import { useState } from "react";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { useHistory } from "react-router-dom";
// import { ROUTES } from "config/routes";
import { HiOutlineChatAlt2 } from "react-icons/hi";
import { enableChat } from "utils/common";
import { useDispatch } from "react-redux";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";

type IProps = {
  reload?: any;
  type: string;
  to_id?: string;
  loading?: boolean;
  setLoading?: any;
  isShowIcon?: boolean;
  isShowText?: boolean;
  className?: string;
};

const View = (props: IProps) => {
  const {
    reload,
    type,
    to_id,
    isShowIcon,
    isShowText = true,
    className,
  } = props;
  const [loading, setLoading] = useState(false);
  const profile = useAppSelector(getUserProfile);
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();


  const from_id = profile?._id;

  const createFriend = async (type) => {
    try {
      setLoading(true);
      await FriendService.create({
        body: {
          to_id,
          type,
        },
      });
      reload &&
        reload({
          to_id,
        });
      setLoading(false);
    } catch (error) {
      toast.error(t(error.errors));
      setLoading(false);
    }
  };

  const changeStatusFriend = async (event, status) => {
    try {
      event.stopPropagation();
      setLoading(true);
      props.setLoading && props.setLoading(true);
      // accept or cancle
      if (
        status === FRIEND_ACTION.REFUSE ||
        status === FRIEND_ACTION.ACCEPT_FRIEND
      )
        await FriendService.update({
          body: {
            from_id: to_id,
            to_id: from_id,
            status,
          },
        });
      else
        await FriendService.update({
          body: {
            from_id: from_id,
            to_id: to_id,
            status,
          },
        });
      reload &&
        reload({
          to_id,
        });
      setLoading(false);
      props.setLoading && props.setLoading(false);
    } catch (error) {
      toast.error(t(error.errors));
      setLoading(false);
      props.setLoading && props.setLoading(false);
    }
  };

  const handleClick = async (id: string) => {
    if (!enableChat()) {
      toast.info(t("Sắp ra mắt!"));
      return;
    }

    dispatch(
      setOpenWebModulesPopupAction({
        openWebModuleKey: "mini_chat_detail",
        payload: {
          userIdMiniChat: id,
        },
      })
    );
    // history.push(`${ROUTES.CONVERSATION.replace(":id", id)}`);
  };

  // const handleChat = (chat_id) => {
  //   history.push(`${ROUTES.MESSAGE}?r=${chat_id}`);
  // };

  switch (type) {
    case FRIEND_ACTION.RESPONSE:
      return (
        <VDropdownModal
          label={
            <VButton
              loading={loading}
              icon={isShowIcon && <VIcon name="friends/delete.svg" />}
            >
              {isShowText && t("Response")}
            </VButton>
          }
          menu={[
            {
              key: t("Confirm"),
              icon: <VIcon name="friends/confirm.svg" />,
              onClick: (e) =>
                changeStatusFriend(e, FRIEND_ACTION.ACCEPT_FRIEND),
            },
            {
              key: t("Delete"),
              icon: <VIcon name="friends/un_friend.svg" />,
              onClick: (e) => changeStatusFriend(e, FRIEND_ACTION.REFUSE),
            },
          ]}
        />
      );
    case FRIEND_ACTION.ACCEPT_FRIEND:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/confirm-white.svg" />}
          onClick={(e) => changeStatusFriend(e, FRIEND_ACTION.ACCEPT_FRIEND)}
          className={"btn-action-request"}
        >
          {isShowText && <span>{t("Confirm")}</span>}
        </VButton>
      );
    case FRIEND_ACTION.CANCEL_REQUEST:
      return (
        <VButton
          variant="danger"
          loading={loading}
          onClick={(e) => changeStatusFriend(e, FRIEND_ACTION.CANCEL_REQUEST)}
          icon={isShowIcon && <VIcon name="friends/delete.svg" />}
          className={"btn-action-request"}
        >
          {isShowText && <span>{t("Cancel request")}</span>}
        </VButton>
      );
    case FRIEND_ACTION.REFUSE:
      return (
        <VButton
          variant="danger"
          loading={loading}
          onClick={(e) => changeStatusFriend(e, FRIEND_ACTION.REFUSE)}
          icon={isShowIcon && <VIcon name="friends/delete.svg" />}
          className={"btn-action-request"}
        >
          {isShowText && <span>{t("Delete")}</span>}
        </VButton>
      );
    case FRIEND_ACTION.UNFRIEND:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/un_friend.svg" />}
          onClick={(e) => changeStatusFriend(e, FRIEND_ACTION.UNFRIEND)}
          className={`btn-danger btn-action-request ${className}`}
        >
          {isShowText && <span>{t("Unfriend")}</span>}
        </VButton>
      );
    case FRIEND_ACTION.REQUEST_FRIEND:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/make_friend.svg" />}
          onClick={(e) => createFriend(FRIEND_ACTION.REQUEST_FRIEND)}
          className={"btn-action-request"}
        >
          {isShowText && <span>{t("Add friend")}</span>}
        </VButton>
      );

    // following
    case FRIEND_ACTION.UNFOLLOW:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/unfollow.svg" />}
          onClick={(e) => changeStatusFriend(e, FRIEND_ACTION.UNFOLLOW)}
          className={`btn-secondary btn-action-request ${className}`}
        >
          {isShowText && <span>{t("Unfollow")}</span>}
        </VButton>
      );
    case FRIEND_ACTION.FOLLOW:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon && <VIcon name="friends/follow.svg" />}
          onClick={(e) => createFriend(FRIEND_ACTION.FOLLOW)}
          className={`btn-action-request ${className}`}
        >
          {isShowText && <span>{t("Follow")}</span>}
        </VButton>
      );

    case FRIEND_ACTION.DELETE:
      return (
        <VButton
          variant="danger"
          loading={loading}
          disabled
          icon={isShowIcon && <VIcon name="delete.svg" />}
          className={"btn-action-request"}
        >
          {isShowText && <span>{t("Remove")}</span>}
        </VButton>
      );
    case FRIEND_ACTION.CHAT:
      return (
        <VButton
          loading={loading}
          onClick={(e) => handleClick(to_id)}
          icon={isShowIcon && <VIcon name="friends/chat.svg" />}
          className={"btn-action-request"}
        >
          {isShowText && <span>{t("Chat")}</span>}
        </VButton>
      );
    case FRIEND_ACTION.CONVERSATION:
      return (
        <VButton
          loading={loading}
          onClick={() => handleClick(to_id)}
          icon={isShowIcon && <HiOutlineChatAlt2 color="#fff" size={24} />}
          className={"btn-action-request"}
        >
          {isShowText && <span>{t("Chat")}</span>}
        </VButton>
      );
    case FRIEND_ACTION.VIEW_DETAIL_PAGE:
      return (
        <VButton
          loading={loading}
          icon={isShowIcon ? <VIcon name="make_friend.svg" /> : <></>}
          onClick={(e) => history.push(`/page/${to_id}`)}
          className={`btn-action-request ${className}`}
        >
          {isShowText && <span>{t("View detail")}</span>}
        </VButton>
      );
    default:
      break;
  }
};

export default View;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useTranslation from "hooks/useTranslation";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/reducers/hook";

import {
  getUserAsync,
  getUserByIdAsync,
} from "redux/reducers/users/users.actions";
import { getUrlImage } from "utils/common";
import UserService from "api/users";
import APIs from "api";

const EditFrame = (
  { dataUser, type, isMyProfile, setLoading, setOpenModal },
  ref
) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [frames, setFrames] = useState([]);
  const [state, setState] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit();
    },
  }));

  const handleChooseImage = (file: any) => {
    const isExist = state?.find((el) => el._id === file._id);
    if (isExist) {
      const newState = state?.filter((el) => el._id !== file._id);
      setState(newState);
    } else if (type === "avatar") {
      setState([file]);
    } else {
      setState((state || []).concat(file));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res = await UserService.editUser({
        body: {
          user_id: dataUser._id,
          frame_avatar_id: state[0]?._id,
        },
      });

      if (res) {
        if (isMyProfile) dispatch(getUserAsync());
        dispatch(
          getUserByIdAsync({
            params: {
              id: dataUser?._id,
            },
          })
        );
        // toast.success(t("success"));
        setOpenModal(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("Update failed"));
    }
  };

  const getFramesByUser = async () => {
    try {
      const res: any = await APIs.FRAMES.getFrames();
      if (res && res.docs) {
        const _frame = res?.docs?.reduce(function (acc, obj) {
          const data = obj?.childrens?.filter(
            (el) => el?.type === "free" && el?.frame_type === "frame_avatar"
          );
          if (data) {
            return (acc || []).concat(data);
          } else {
            return acc || [];
          }
        }, 0);
        setFrames(_frame || []);
      }
    } catch (error) {}
  };

  useEffect(() => {
    getFramesByUser();
  }, [type]);

  return (
    <div className="container">
      <Row>
        {frames?.length > 0 ? (
          frames.map((el, index) => (
            <Col
              key={index}
              className={`col-4 mb-3 d-flex align-items-center justify-content-center`}
            >
              <div className="user-images position-relative overflow-hidden">
                <div
                  className={`item-image ${
                    state?.find((file) => file._id === el._id)
                      ? "active-edit"
                      : ""
                  }`}
                  onClick={() => handleChooseImage(el)}
                >
                  <img
                    src={getUrlImage(el?.thumbnail || el.image, 300)}
                    className="img-fluid rounded"
                    alt="Responsive"
                  />
                </div>
              </div>
            </Col>
          ))
        ) : (
          <span className="w-100 text-center mt-3">
            {t("No content to display")}
          </span>
        )}
      </Row>
    </div>
  );
};

export default forwardRef(EditFrame);

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import { useEffect, useMemo, useRef, useState } from "react";
import { Dropdown, Card, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import reactStringReplace from "react-string-replace";

//image

import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import { getNotificationsClient } from "redux/reducers/notifications/notifications.selectors";
import { getNotificationsClientAsync } from "redux/reducers/notifications/notifications.actions";
import CustomToggle from "components/v-teamplate/dropdowns";
import { checkInDate, convertTimeAgo } from "utils/times";
import {
  setLazyParams,
  setNotification,
} from "redux/reducers/notifications/notifications.reducers";
import { useHistory } from "react-router-dom";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";
import { getUrlImage } from "utils/common";
import VIcon from "components/v-icon";
import NotificationService from "api/notifications";
// import { NOTIFICATION_TYPE } from "config/enums";
import { ROUTES } from "config/routes";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import VInfiniteLoad from "components/v-infinite-load";
import VNameVerify from "components/v-objects/v-name-verify";
import { LIST_TYPE_NOTICE_CHAT } from "config/constant";
import AcceptPairing from "components/v-objects/v-pairing/accept-pairing";

const View = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { menu } = props;

  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const notification: any = useAppSelector(getNotificationsClient);
  const { page, limit, docs } = notification;
  const currentPage = useRef(1);
  const [userIdPairing, setUserIdPairing] = useState(null);

  useEffect(() => {
    if (isLoggedIn && currentPage.current === page) {
      getData({ page, filter });
    }
  }, [page, isLoggedIn, currentPage.current]);

  const getData = async ({ page, filter }) => {
    setLoading(true);
    await dispatch(
      getNotificationsClientAsync({
        query: {
          page,
          limit,
          type: filter,
        },
      })
    );
    setLoading(false);
  };

  const handleViewMore = () => {
    currentPage.current = page + 1;
    dispatch(setLazyParams({ page: page + 1 }));
  };

  const onToggle = (e) => {
    if (e) {
      getData({ page: 1, filter });
    } else {
      const body = document.getElementById("card-body");
      if (body) {
        body.scrollTop = 0;
      }
    }
  };

  const onChangeFilter = (value: string) => {
    setFilter(value);
    getData({ page: 1, filter: value === "unread" ? "all" : value });
  };

  const amountUnread = (docs || []).filter(
    (n) => n && !n.isRead && checkInDate(n.createdAt)
  );

  const handleClick = (notice) => {
    const { url, extra, type } = notice.data;

    if (LIST_TYPE_NOTICE_CHAT.includes(type)) {
      dispatch(
        setOpenWebModulesPopupAction({
          openWebModuleKey: "mini_chat_detail",
          payload: {
            userIdMiniChat: notice.object_id,
          },
        })
      );
      return;
    }

    if (type === "REQUEST_PAIRING") {
      return setUserIdPairing(notice?.from_id);
    }

    if (url) {
      if (String(url).includes(".")) window.open(url);
      else {
        history.push(convertUrl(notice.data.url, notice));
      }
    }

    if (extra?.post_id) {
      dispatch(
        setOpenWebModulesPopupAction({
          openWebModuleKey: "post_detail",
          payload: {
            post: {
              _id: extra?.post_id,
            },
          },
        })
      );
    }

    if (!notice.isRead) {
      NotificationService.readNotification({
        params: {
          id: notice._id,
        },
      });
      dispatch(
        setNotification({
          ...notice,
          isRead: true,
        })
      );
    }
  };

  const _notifications = useMemo(() => {
    if (filter === "all") return notification.docs;
    else return notification.docs.filter((n) => !n.isRead);
  }, [notification, filter]);

  const convertBodyNotification = (notice) => {
    let content = notice?.data?.body;
    if (notice?.data?.body.includes("{from_name}")) {
      content = reactStringReplace(content, "{from_name}", (match, i) => (
        <strong className="d-inline-flex">
          <VNameVerify
            className={"mb-0 d-flex align-items-center"}
            dataUser={notice?.from}
            fontSizeIconVerify={12}
          />
        </strong>
      ));
    }
    if (notice?.data?.body.includes("{to_name}")) {
      content = reactStringReplace(content, "{to_name}", (match, i) => (
        <strong className="d-inline-flex">
          <VNameVerify
            className={"mb-0 d-flex align-items-center"}
            dataUser={notice?.to}
            fontSizeIconVerify={12}
          />
        </strong>
      ));
    }
    if (notice?.data?.body.includes("{object_name}")) {
      content = reactStringReplace(content, "{object_name}", (match, i) => (
        <b>{notice.data.extra?.object_name || ""}</b>
      ));
    }

    if (notice?.data?.body.includes("{object_id}")) {
      content = reactStringReplace(content, "{object_id}", (match, i) => (
        <strong className="d-inline-flex">
          <VNameVerify
            className={"mb-0 d-flex align-items-center"}
            dataUser={notice?.object}
            fontSizeIconVerify={12}
          />
        </strong>
      ));
    }

    if (notice?.data?.body.includes("{qty}")) {
      content = reactStringReplace(content, "{qty}", (match, i) => (
        <strong className="d-inline-flex">{notice?.data?.qty}</strong>
      ));
    }

    return <h6 className="mb-0">{content}</h6>;
  };

  const convertUrl = (url, notification) => {
    return String(url)
      .replace("{from_id}", notification.from_id)
      .replace("{to_id}", notification.to_id || "")
      .replace("{object_id}", notification.object_id || "");
  };

  const renderImage = (notice) => {
    // if (notice.type === NOTIFICATION_TYPE.COMMON) {
    //   return getUrlImage(notice?.data?.image, 200);
    // }
    return getUrlImage(notice?.from?.avatar, 200);
  };

  return (
    <div className="">
      <Dropdown as="li" className="nav-item noti-mobile" onToggle={onToggle}>
        <Dropdown.Toggle
          href="#"
          as={CustomToggle}
          variant="d-flex align-items-center"
        >
          {menu?.img_icon ? (
            <div className={`v-icon`}>
              <img
                src={getUrlImage(menu?.img_icon, 100)}
                width={24}
                height={24}
                alt=""
              />
              {amountUnread.length > 0 && (
                <span className="v-icon-badge">
                  {amountUnread && amountUnread.length > 10
                    ? "N"
                    : amountUnread.length}
                </span>
              )}
            </div>
          ) : (
            <VIcon
              width={24}
              height={24}
              name="notification.svg"
              badge={
                amountUnread && amountUnread.length > 10
                  ? "N"
                  : amountUnread.length
              }
            />
          )}
        </Dropdown.Toggle>

        <Dropdown.Menu className="sub-drop sub-drop-large notification-header">
          <Card className="shadow-none m-0">
            <Card.Header>
              <div className="header-title">
                <h5 className="title">{t("All notification")}</h5>
                <VIcon className="cursor-pointer" name="tree-dot.svg" />
              </div>
              <div className="filter-status">
                <div
                  className={`item-detail-filter ${
                    filter === "all" ? "filter-active" : ""
                  }`}
                  onClick={(_) => onChangeFilter("all")}
                >
                  {t("All")}
                </div>

                <div
                  className={`item-detail-filter ${
                    filter === "community" ? "filter-active" : ""
                  }`}
                  onClick={(_) => onChangeFilter("community")}
                >
                  {t("Community")}
                </div>

                <div
                  className={`item-detail-filter ${
                    filter === "unread" ? "filter-active" : ""
                  }`}
                  onClick={(_) => onChangeFilter("unread")}
                >
                  {t("Unread")}
                </div>
              </div>
            </Card.Header>
            <Card.Body className="p-0" id="card-body">
              <VInfiniteLoad
                canLoadMore={page + 1 <= notification.totalPages}
                isLoadingMore={loading}
                onLoadMore={handleViewMore}
              >
                <>
                  {_notifications.length > 0 && (
                    <div className="time-line-noti">
                      <h6>{t("Latest")}</h6>
                      <Dropdown.Item>
                        <Link to={ROUTES.NOTIFICATION}>{t("All")}</Link>
                      </Dropdown.Item>
                    </div>
                  )}
                  {(_notifications || []).map((notice, index) => (
                    <Dropdown.Item key={index}>
                      <div
                        className={`iq-sub-card ${
                          notice.isRead ? "" : "item-unread"
                        }`}
                        onClick={(_) => handleClick(notice)}
                      >
                        <div
                          className={`position-relative d-flex align-items-center`}
                        >
                          <div className="avatar-notification">
                            <Image
                              src={renderImage(notice)}
                              alt=""
                              loading="lazy"
                            />
                          </div>
                          <div className="content-notification">
                            {convertBodyNotification(notice)}
                            {/* <h6
                              className="mb-0 "
                              dangerouslySetInnerHTML={{
                                __html: convertBodyNotification(
                                  notice.data.body,
                                  notice.data.extra,
                                  notice.from
                                ),
                              }}
                            ></h6> */}
                            <div className="d-flex justify-content-between align-items-center">
                              <small className="float-right font-size-12">
                                {convertTimeAgo(
                                  new Date(notice.createdAt).getTime(),
                                  t
                                )}
                              </small>
                            </div>
                          </div>
                          {/* {!notice.isRead && <div className="unread"></div>} */}
                          <Image
                            src={getUrlImage(notice?.data?.image, 200)}
                            alt=""
                            loading="lazy"
                            style={{ width: "30px" }}
                          />
                        </div>
                      </div>
                    </Dropdown.Item>
                  ))}
                </>
              </VInfiniteLoad>
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
      <AcceptPairing userIdPairing={userIdPairing} setUserIdPairing={setUserIdPairing} />
    </div>
  );
};

export default View;

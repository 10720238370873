import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IRequestData } from "api/request";
import UserAPIs from "api/users";

import decode from "utils/decodeToken";
import {
  getAccountAddressAndCareerAsync,
  getDataAccessByOwnerAsync,
  getKycAsync,
  getPermissionAsync,
  getRelationAsync,
  getRelationRequestingAsync,
  getSalesStatisticsAsync,
  getSharingsAsync,
  getUserAsync,
  getUserByIdAsync,
  getAuthSessionsAsync,
  getUserOnlineAsync,
  getBalancesAsync,
} from "./users.actions";
import { IObject } from "types";
import { sortStringObject } from "utils/function";
export interface IUsersState {
  token: string | null;
  profile: any;
  user: any;
  isLoggedIn: boolean;
  connectSocket: boolean;
  balances: any;
  locked_balances: any;
  sharings: any;
  vzxWallet?: any;
  verify: any;
  is_leader?: boolean;
  sales_statistics: any;
  user_for_news: any;
  permissions: any;
  relation_requesting: any;
  relations: any;
  dataAccess: any;
  dataUser: any;
  authSessions: {
    docs: IObject[];
  };
  userOnline: IObject[];
}

export const editUser = createAsyncThunk(
  "users/editUser",
  async (req: IRequestData) => {
    const user = await UserAPIs.editUser(req);
    return user;
  }
);

const defaultUser: any = decode(localStorage.getItem("auth"));

const initialState = {
  token: null,
  profile: defaultUser || {},
  isLoggedIn: false,
  user: {},
  connectSocket: false,
  balances: {},
  locked_balances: {},
  sharings: [],
  verify: null,
  sales_statistics: [],
  user_for_news: [],
  permissions: {},
  relation_requesting: [],
  relations: [],
  dataAccess: {},
  dataUser: {},
  authSessions: {
    docs: [],
  },
  userOnline: [],
};

export const usersSlice = createSlice({
  name: "users",
  initialState,
  reducers: {
    setToken: (state: IUsersState, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
    setProfile: (state: IUsersState, action: PayloadAction<any>) => {
      state.profile = {
        ...(state.profile || {}),
        ...action.payload,
      };
    },
    setConnectSocket: (state: IUsersState, action: PayloadAction<boolean>) => {
      state.connectSocket = action.payload;
    },
    setBalances: (state, action) => {
      state.balances = action.payload.balances;
      state.locked_balances = action.payload.locked_balances;
    },
    setUserOnline: (state: IUsersState, action: PayloadAction<IObject>) => {
      const exist = state.userOnline.find((u) => u._id === action.payload._id);
      if (exist) {
        state.userOnline = state.userOnline
          .map((u) => (u._id === action.payload._id ? action.payload : u))
          .sort((a, b) => sortStringObject(b, a, "status"));
      } else {
        state.userOnline = [...state.userOnline, action.payload].sort((b, a) =>
          sortStringObject(a, b, "status")
        );
      }
    },
    logOut: (state: IUsersState, action) => {
      state.permissions = {};
      state.user = {};
      state.profile = {};
      state.isLoggedIn = false;
    },
  },
  extraReducers: {
    [`${getAuthSessionsAsync.pending}`]: (state: IUsersState) => {},
    [`${getAuthSessionsAsync.fulfilled}`]: (
      state: IUsersState,
      { payload }
    ) => { 
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.authSessions = payload;
      }
    },
    [`${getUserAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getUserAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors, user } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.permissions = user.permissions || {};
        state.user = user;
        state.profile = user;
        state.isLoggedIn = true;
      }
    },
    [`${getUserAsync.rejected}`]: (state: IUsersState, { payload }) => {},
    [`${getSharingsAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getSharingsAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors, commissions } = payload;
      if (errors) {
        return;
      } else {
        state.sharings = commissions;
      }
    },
    [`${getKycAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getKycAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors, kyc } = payload;
      if (errors) {
        return;
      } else {
        state.verify = kyc;
      }
    },
    [`${getSalesStatisticsAsync.pending}`]: (
      state: IUsersState,
      { payload }
    ) => {},
    [`${getSalesStatisticsAsync.fulfilled}`]: (
      state: IUsersState,
      { payload }
    ) => {
      const { errors, data } = payload;
      if (errors) {
        return;
      } else {
        state.sales_statistics = data;
      }
    },
    [`${getAccountAddressAndCareerAsync.pending}`]: (
      state: IUsersState,
      { payload }
    ) => {},
    [`${getAccountAddressAndCareerAsync.fulfilled}`]: (
      state: IUsersState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.user_for_news = payload;
      }
    },
    [`${getPermissionAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getPermissionAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.permissions = payload;
      }
    },
    [`${getRelationRequestingAsync.pending}`]: (
      state: IUsersState,
      { payload }
    ) => {},
    [`${getRelationRequestingAsync.fulfilled}`]: (
      state: IUsersState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.relation_requesting = payload;
      }
    },
    [`${getRelationAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getRelationAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.relations = payload;
      }
    },
    [`${getDataAccessByOwnerAsync.pending}`]: (
      state: IUsersState,
      { payload }
    ) => {},
    [`${getDataAccessByOwnerAsync.fulfilled}`]: (
      state: IUsersState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.dataAccess = payload[0] || {};
      }
    },
    [`${getUserByIdAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getUserByIdAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors, user } = payload;
      if (errors) {
        return;
      } else {
        state.dataUser = user;
      }
    },
    [`${getUserOnlineAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getUserOnlineAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.userOnline = payload.docs || [];
      }
    },
    [`${getBalancesAsync.pending}`]: (state: IUsersState, { payload }) => {},
    [`${getBalancesAsync.fulfilled}`]: (state: IUsersState, { payload }) => {
      const { errors ,} = payload;
      if (errors) {
        return;
      } else {
        state.balances = payload.balances || {};
        state.locked_balances = payload.locked_balances || {};
      }
    },
  },
});

export const {
  setToken,
  setProfile,
  setConnectSocket,
  setBalances,
  logOut,
  setUserOnline,
} = usersSlice.actions;

export default usersSlice.reducer;

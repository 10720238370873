/* eslint-disable no-unused-vars */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
// import VIcon from "components/v-icon";

import { ROUTES } from "config/routes";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { Link, useHistory } from "react-router-dom";
import { setOpenBanner } from "redux/reducers/advertisments/advertisments.reducers";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";

import {
  // getMenus,
  getMenusByKey,
} from "redux/reducers/settings/settings.selector";
// import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { convertUrl, getUrlImage } from "utils/common";

const Footer = ({ onClickMenu }: any) => {
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get("dark");
  // const menus = useAppSelector(getMenusByKey("footer_mobile"));
  const userData: any = useAppSelector(getUserProfile);
  const history = useHistory();
  const dispatch = useAppDispatch();

  if (mode !== null) {
    // document.body.classList = "";
    switch (mode) {
      case "true":
        // document.body.classList = "dark";
        break;
      case "false":
        // document.body.classList = "";
        break;
      default:
        // document.body.classList = "";
        break;
    }
  }
  const [hide, setHide] = useState(false);
  // window.onscroll = function (e) {
  //   if (this.oldScroll > this.scrollY) {
  //     setHide(false);
  //   } else {
  //     setHide(true);
  //   }
  //   this.oldScroll = this.scrollY;
  // };

  const handleClickMenu = (menu) => {
    if (menu?.key === "scan") return history.push(`/${menu.key}`);
    if (menu?.key?.toLowerCase() === "gift")
      return dispatch(setOpenBanner(true));
    switch (menu.type_view) {
      case "internal":
        history.push(convertUrl(menu.url, userData) || "/");
        break;
      case "link":
        window.open(convertUrl(menu.url), "_blank");
        break;
      case "webview":
        history.push(`/wv/${menu.key}`);
        break;
      default:
        break;
    }
  };

  const menusActions = {
    feed: {
      onClick: () => {
        if (onClickMenu) {
          history.push(ROUTES.HOME);
        }
      },
    },
    main: {
      onClick: () => {
        if (onClickMenu) {
          onClickMenu("home_v2");
        }
      },
    },
    gift: {
      onClick: () => {
        if (onClickMenu) {
          onClickMenu("gif");
        }
      },
    },
    shop: {
      onClick: () => {
        if (onClickMenu) {
          history.push(ROUTES.SHOP);
        }
      },
    },
    "post_type=video": {
      onClick: () => {
        if (onClickMenu) {
          history.push(ROUTES.HOME + "?tab=post_type=video");
        }
      },
    },
  };

  // const menus = [
  //   {
  //     key: "feed",
  //     text: "Home feed",
  //     icon: "/assets/images/home-v2/menu/home_feed.svg",
  //     onClick: () => {
  //       if (onClickMenu) {
  //         // onClickMenu('news_feed');
  //         history.push(ROUTES.HOME);
  //       }
  //     },
  //   },
  //   {
  //     key: "gift",
  //     text: "Quà tặng",
  //     icon: "/assets/images/home-v2/menu/gift.svg",
  //     onClick: () => {
  //       if (onClickMenu) {
  //         onClickMenu("gif");
  //       }
  //     },
  //   },
  //   {
  //     key: "main",
  //     icon: "/assets/images/home-v2/menu/main.svg",
  //     onClick: () => {
  //       if (onClickMenu) {
  //         onClickMenu("home_v2");
  //       }
  //     },
  //   },
  //   {
  //     key: "moments",
  //     text: "Khoảnh khắc",
  //     icon: "/assets/images/home-v2/menu/moment.svg",
  //     onClick: () => {
  //       if (onClickMenu) {
  //         // onClickMenu('news_feed');
  //         history.push(ROUTES.REELS);
  //       }
  //     },
  //   },
  //   {
  //     key: "shop",
  //     text: "Shop",
  //     icon: "/assets/images/home-v2/menu/shop.svg",
  //     onClick: () => {
  //       if (onClickMenu) {
  //         // onClickMenu('shop');
  //         history.push(ROUTES.SHOP);
  //       }
  //     },
  //   },
  // ];

  const menusFromAPI = useAppSelector(getMenusByKey("bottom_home_v2_menu"));

  return (
    <>
      {menusFromAPI?.length > 0 && (
        <footer
          className={`iq-footer bg-white ${hide ? "iq-footer-hide" : ""}`}
        >
          <ul className="menu-v2 navbar-nav navbar-list d-flex flex-row justify-content-around w-100 mt-1">
            {menusFromAPI?.slice(0, 5).map((el) => {
              const onClick = menusActions[el.key]?.onClick || (() => {});

              // const elMatch = ';';
              return el.key === "main" ? (
                <>
                  <Nav.Item
                    as="li"
                    className="center-menu"
                    onClick={onClick}
                  >
                    <Link
                      // target={el?.type_view === "link" ? "_blank" : ""}
                      className="d-flex align-items-center flex-column justify-content-center"
                      // onClick={() => handleClickMenu(el)}
                    >
                      <img
                        src={getUrlImage(el.img_icon)}
                        width={isMobile ? 35 : 45}
                        height={isMobile ? 35 : 45}
                      />
                      <span className="center-menu-text text-ellipsis short-1">
                        {el.name}
                      </span>
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="center-menu-fake"></Nav.Item>
                </>
              ) : (
                <Nav.Item as="li" onClick={onClick}>
                  <Link
                    // target={el?.type_view === "link" ? "_blank" : ""}
                    className="d-flex flex-column align-items-center justify-content-center"
                    // onClick={() => handleClickMenu(el)}
                  >
                    <img
                      src={getUrlImage(el.img_icon)}
                      width={25}
                      height={25}
                    />
                    <span
                      className="text-ellipsis short-1"
                      style={{ fontSize: "10px" }}
                    >
                      {el.name}
                    </span>
                  </Link>
                </Nav.Item>
              );
            })}
          </ul>
        </footer>
      )}
    </>
  );
};

export default Footer;

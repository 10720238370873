/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Spinner } from "react-bootstrap";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";

import { v4 as uuidv4 } from "uuid";
import { useDispatch } from "react-redux";
import { useAppSelector } from "redux/reducers/hook";
import { useTranslation } from "react-i18next";
import FriendService from "api/friends";
import {
  getUserProfile,
  getDataUser,
} from "redux/reducers/users/users.selector";
import { WEB_MODULE_URL } from "config/env";
import useQuery from "hooks/useQuery";
import { getIsApp } from "redux/reducers/settings/settings.selector";

export default function NewsFeed({
  type = "",
  userId,
}: any) {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const { reloadNewsFeedNumber } = useAppSelector(getUISettingState);
  const profileLoggedIn = useAppSelector(getUserProfile);
  const dataUser = useAppSelector(getDataUser);
  const query = useQuery();
  const isApp = useAppSelector(getIsApp)

  const [loadingNewsFeed, setLoadingNewsFeed] = useState(true);
  const [isCreatePost, setIsCreatePost] = useState(false);
  const [relation, setRelation] = useState({
    fromFriend: null,
    toFriend: null,
  });
  const getRelation = async () => {
    const res: any = await FriendService.getRelationWithToUser({
      params: {
        to_id: userId,
      },
    });
    setRelation(res);
  };

  const { fromFriend } = relation;
  const isFriend = Boolean(
    dataUser?.user_setting?.post?.post === "friend" &&
      fromFriend &&
      fromFriend?.type === "friend"
  );

  const isPublic = Boolean(dataUser?.user_setting?.post?.post === "public");

  const isMe =
    profileLoggedIn?._id &&
    String(dataUser._id) === String(profileLoggedIn?._id);

  useEffect(() => {
    if (isPublic || isFriend || isMe) {
      setIsCreatePost(true);
    } else {
      setIsCreatePost(false);
    }
  }, [isPublic, isFriend]);

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reloadNewsFeedNumber) {
      setLoadingNewsFeed(true);
    }
  }, [reloadNewsFeedNumber]);

  useEffect(() => {
    getRelation();
  }, []);

  const scroll = () => {
    document.getElementById("post_profile")?.scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  };

  useEffect(() => {
    if (query.post_directory) {
      setTimeout(() => {
        scroll();
      }, 100);
    }
  }, [query.post_directory]);
  
  return (
    <div key={reloadNewsFeedNumber} className="h-100 pb-2">
      {loadingNewsFeed && (
        <div className="d-flex justify-content-center p-3">
          <Spinner />
        </div>
      )}
      {userId && (
        <iframe
          className={`${isApp ? "iframe-is-app" : ""} iframe-newfeed-profile`}
          onLoad={() => {
            setLoadingNewsFeed(false);
          }}
          src={`${
            WEB_MODULE_URL
          }/profile/${userId}/?origin=${
            window.origin
          }&vdb_token=${localStorage.getItem(
            "auth"
          )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}${
            type ? `&type=${type}` : ""
          }&post_directory=${query?.post_directory ? `${query?.post_directory}` : ""}&lang=${
            i18n.language ? `${i18n.language}` : ""
          }&isCreatePost=${isCreatePost ? `${isCreatePost}` : ""}`}
          title="module_news_feed"
          allow="clipboard-read; clipboard-write;fullscreen;"
        />
      )}
    </div>
  );
}

/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";

import { useAppSelector } from "redux/reducers/hook";
import { getUIPTemplateState } from "redux/reducers/ui-settings/ui-settings.selector";

import { DesignFrame, Editor } from "vdiarybook-lidojs/editor";

import PreviewModal from "./components/preview-modal/View";

import { WEB_MODULE_URL } from "config/env";

const View = () => {
  const serialized = useAppSelector(getUIPTemplateState);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  useEffect(() => {
    setIsOpen(serialized.length > 0);
  }, [serialized]);

  const config = {
    assetPath: "/assets",
    frame: {
      defaultImage: {
        url: `${WEB_MODULE_URL}/assets/images/frame-placeholder.png`,
        width: 1200,
        height: 800,
      },
    },
  };

  const getFonts: any = () => [];

  return (
    <>
      {isOpen && (
        <Editor config={config} getFonts={getFonts}>
          <div className="d-none">
            <DesignFrame data={serialized} />
          </div>
          <PreviewModal serialized={serialized} />
        </Editor>
      )}
    </>
  );
};

export default View;

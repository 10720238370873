/* eslint-disable indent */
import { Form } from "react-bootstrap";
import Select, { components } from "react-select";
import { useSelector } from "react-redux";
import * as SettingSelector from "redux/reducers/setting-system/selectors";
import { getUrlImage } from "utils/common";

type IProps = {
  label?: any;
  onChange?: any;
  disabled?: boolean;
  [key: string]: any;
};

const View = (props: IProps) => {
  const {
    key,
    label,
    placeholder,
    options,
    disabled,
    isMulti = false,
    required,
    onChange,
    value,
    className,
    ...rest
  } = props;

  const themeScheme = useSelector(SettingSelector.theme_scheme);
  const { Option } = components;

  const handleChangeDropdown = (e: any) => {
    if (isMulti) {
      const value = e?.map((el) => el?.value);
      onChange(value);
    } else {
      onChange({
        target: e,
      });
    }
  };

  const colourStyles = {
    option: (styles, { isFocused, isDisabled }) => {
      return {
        ...styles,
        backgroundColor: isFocused
          ? "#c3e3f9"
          : themeScheme === "light"
          ? "#fff"
          : "#0f0f0f",
        color: isDisabled
          ? "#c0c0c1"
          : isFocused
          ? "#555770"
          : themeScheme === "light"
          ? "#555770"
          : "#c7c7c7",
      };
    },
    menu: (base) => ({
      ...base,
      background: themeScheme === "light" ? "#fff" : "#0f0f0f",
      zIndex: "9999",
    }),
    control: (base) => ({
      ...base,
      border: "none",
      outline: "none",
      minHeight: "32px",
      backgroundColor: "transparent",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (base) => ({
      ...base,
      padding: "4px",
    }),
    input: (base) => ({
      ...base,
      margin: "1.5px 2px",
    }),
  };

  const valueDefautl = options
    ?.filter((el) =>
      !isMulti
        ? String(el.value) === String(value)
        : value?.includes(String(el.value))
    )
    .map((item) => ({
      ...item,
      label: item.label,
      icon: item.icon && (
        <img
          className="me-2"
          src={getUrlImage(item.icon)}
          alt=""
          width={16}
          height={16}
        />
      ),
    }));

  const IconOption = (props) => (
    <Option {...props}>
      <div className="d-flex align-items-center">
        {props.data.icon}
        {props.data.label}
      </div>
    </Option>
  );

  const customSingleValue = ({ data }) => {
    return (
      <div className="select-single-value">
        {data.icon && data.icon}
        <span>{data.label}</span>
      </div>
    );
  };

  return (
    <div className={`v-dropdown-custom ${className ? className : ""}`}>
      <Form.Group className="form-group">
        {label && (
          <Form.Label className="d-flex align-items-center">
            {label} {required && <span className="required text-danger">*</span>}
          </Form.Label>
        )}
        <div className={`form-control ${disabled ? "opacity-50" : "opacity-100"}`}>
          <Select
            key={key}
            styles={colourStyles}
            closeMenuOnSelect={!isMulti}
            components={{ Option: IconOption, SingleValue: customSingleValue }}
            isMulti={isMulti}
            isDisabled={disabled}
            isSearchable={true}
            isClearable={isMulti}
            placeholder={placeholder}
            options={
              placeholder
                ? [
                    {
                      label: placeholder,
                      value: "",
                    },
                    ...options.map((el) => ({
                      ...el,
                      label: el.label,
                      icon: el.icon && (
                        <img
                          className="me-2"
                          src={getUrlImage(el.icon)}
                          alt=""
                          width={16}
                          height={16}
                        />
                      ),
                    })),
                  ]
                : [
                    ...options.map((el) => ({
                      ...el,
                      label: el.label,
                      icon: el.icon && (
                        <img
                          className="me-2"
                          src={getUrlImage(el.icon)}
                          alt=""
                          width={16}
                          height={16}
                        />
                      ),
                    })),
                  ]
            }
            value={valueDefautl}
            onChange={handleChangeDropdown}
            {...rest}
          />
        </div>
      </Form.Group>
    </div>
  );
};

export default View;

import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const getByGroup = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/categories/get-by-group?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCategoriesByShop = () =>
  new Promise(async (resolve, reject) => {
    request()
      .get(`categories/getCategoriesByShop`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SearchService = {
  getByGroup,
  getCategoriesByShop,
};

export default SearchService;

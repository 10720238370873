import useTranslation from "hooks/useTranslation";
import { IObject } from "types";
import Manager from "../manager";
import { useState } from "react";
import VButton from "components/v-button";
import VIcon from "components/v-icon";

type IProps = {
  dataUser: any;
  objectSetting: IObject;
  dataSetting: IObject;
  groupsRole?: IObject[];
  handleChangePermission?: (parent: string, name: string, value: any[]) => any;
  handleBack: () => void;
};

const View = (props: IProps) => {
  const { t } = useTranslation();
  const {
    groupsRole = [],
    dataUser,
    objectSetting,
    dataSetting,
    handleBack,
    handleChangePermission,
  } = props;

  const [managerSelected, setManagerSelected] = useState(null);

  const handleChange = async (name, value) => {
    try {
      await handleChangePermission(objectSetting.key, name, value);
    } catch (error) {}
  };

  const handleClickManager = (object: IObject) => {
    setManagerSelected(object);
  };

  return (
    <>
      {!managerSelected && (
        <div className="privacy-security-wrapper">
          <div className="card privacy-security-header py-2">
            <VButton icon={<VIcon name="back.svg" />} onClick={handleBack} />
            <div>{t(objectSetting.name)}</div>
          </div>
          <div className="card p-3 privacy">
            <div className="title">{t("Who can use it")}</div>
            <hr className="my-2" />
            <div className="content">
              {groupsRole.map((role) => (
                <div className="px-3">
                  <div
                    className="d-flex justify-content-between"
                    onClick={(_) => handleChange("apply", role.key)}
                  >
                    {role.name}
                    {(dataSetting?.apply || "everybody") === role.key && (
                      <div>
                        <VIcon name="verify-success.svg" />
                      </div>
                    )}
                  </div>
                  <hr className="my-1" />
                </div>
              ))}
            </div>
          </div>
          <div className="card p-3">
            <div className="title">{t("Special Users")}</div>
            <hr className="my-2" />
            <div className="content">
              <div className="px-3">
                <div
                  className="d-flex justify-content-between "
                  onClick={(_) =>
                    handleClickManager({
                      name: "Never Allow",
                      key: "never_allow",
                    })
                  }
                >
                  {t("Never Allow")}
                  <div>
                    {``}
                    <img src="/assets/images/icons/chevron-down.svg" alt="" />
                  </div>
                </div>
                <hr className="my-1" />
              </div>

              <div className="px-3">
                <div
                  className="d-flex justify-content-between "
                  onClick={(_) =>
                    handleClickManager({
                      name: "Always Allow",
                      key: "always_allow",
                    })
                  }
                >
                  {t("Always Allow")}
                  <div>
                    {``}
                    <img src="/assets/images/icons/chevron-down.svg" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {managerSelected && (
        <Manager
          dataUser={dataUser}
          objectSetting={managerSelected}
          userIds={dataSetting[managerSelected.key] || []}
          handleBack={() => setManagerSelected(null)}
          handleChangeMember={(name, value) => handleChange(name, value)}
        />
      )}
    </>
  );
};

export default View;

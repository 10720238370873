/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";

//*****//
import ProfileHeader from "../../object-details/profile-header";
import Error404 from "components/v-error-404";

import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { useParams, useHistory } from "react-router-dom";
// import Friends from "./friends";
import {
  getIsApp,
  getMenusByKey,
} from "redux/reducers/settings/settings.selector";
import useQuery from "hooks/useQuery";
import VTab from "components/v-tab";
import ComingSoon from "pages/coming-soon/comingsoon";
// import Timeline from "./timeline";
import About from "../../object-details/profile-about";
import ProfileLeftSidebar from "../../object-details/profile-left-sidebar";
import PostDirectorySettings from "pages/social/object-details/post-directory";
import { checkIsAuth, checkIsMyProfile, getUrlProfile } from "utils/common";
import { isChannel } from "utils/friends";
import {
  getDataUser,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import { getUserByIdAsync } from "redux/reducers/users/users.actions";
import { getPostDirectoryByUserIdAsync } from "redux/reducers/post-directory/post-directory.actions";
import { getPostDirectory } from "redux/reducers/post-directory/post-directory.selectors";
import VWebView from "components/v-web-view";
import { isMobile } from "react-device-detect";
import ProfileMedia from "pages/social/object-details/profile-media";
import ProfileQrCode from "pages/social/object-details/profile-qr-code";
import ChannelProfile from "pages/social/user/videos/profile-videos";
import ProfileTimeline from "pages/social/user/profile/profile-timeline";
import Members from "pages/social/pages/members";
import SettingObjects from "pages/social/object-details/settings";
import PageBackgrounds from "components/v-page-background";
import VBanner from "components/v-banner";
import TabPostDirectoryProfile from "pages/social/object-details/profile-tab-post-directory";

const View = () => {
  const isApp = useAppSelector(getIsApp);

  const dispatch = useAppDispatch();
  const params = useParams();
  const history = useHistory();
  const { id } = params;
  const [loading, setLoading] = useState(false);
  const [isNotFound, setIsNotFound] = useState(false);
  const profile = useAppSelector(getUserProfile);

  const menus = useAppSelector(getMenusByKey("tab_channel_profile"));
  const [tab, setTab] = useState(null);
  const query = useQuery();
  const postDirectory = useAppSelector(getPostDirectory);
  const dataUser = useAppSelector(getDataUser);
  const [loadMenu, setLoadMenu] = useState(false);

  const isAuth = checkIsAuth(dataUser, profile._id);
  const isMyProfile = checkIsMyProfile(profile, id);

  const menuRequiedOwner = ["kyc", "settings"];

  useEffect(() => {
    getUserById();
  }, [id]);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  useEffect(() => {
    if (dataUser) {
      if (isChannel(dataUser.object_type)) {
        setIsNotFound(false);
      } else {
        setIsNotFound(true);
      }
    }
  }, [dataUser]);

  const getUserById = async () => {
    setLoading(true);
    try {
      const res: any = await dispatch(
        getUserByIdAsync({
          params: {
            id: id,
          },
        })
      );
      if (res?.payload.user) {
        dispatch(
          getPostDirectoryByUserIdAsync({
            params: {
              userId: res?.payload.user._id,
            },
          })
        );
        if (res?.payload?.user?.special_name) {
          const search = window.location.search;
          history.push(`${getUrlProfile(res?.payload.user)}${search}`);
        }
      }
    } catch (error) {
      setIsNotFound(true);
    }
    setLoading(false);
  };

  const renderTab = () => {
    if (query?.setting === "post-directory") {
      return (
        <>
          {!isMobile && (
            <Col md={4}>
              <ProfileLeftSidebar
                isAuth={isAuth}
                dataUser={dataUser}
                dataFolder={postDirectory}
              />
            </Col>
          )}
          <Col className={!isMobile ? "ps-0" : ""} md={8}>
            <PostDirectorySettings
              isAuth={isAuth}
              dataUser={dataUser}
              dataFolder={postDirectory}
            />
          </Col>
        </>
      );
    }

    if (tab && tab.type_view === "internal") {
      if (tab.key === "timeline")
        return (
          <>
            {!isApp && (
              <Col md={4}>
                <ProfileLeftSidebar
                  isAuth={isAuth}
                  dataUser={dataUser}
                  dataFolder={postDirectory}
                />
              </Col>
            )}
            <Col id={"post_profile"} className="ps-0" md={8}>
              <div className="mb-2">
                <VBanner
                  position={"banner_top_feed_profile"}
                  isBannerPage={true}
                />
              </div>
              <ProfileTimeline userId={dataUser._id} />
              {/* <FollowSuggest /> */}
            </Col>
          </>
        );
      if (tab.key === "channel")
        return (
          <>
            {/* <Col md={4}>
              <ProfileLeftSidebar
                isAuth={isAuth}
                dataUser={dataUser}
                dataFolder={postDirectory}
              />
            </Col> */}
            <Col id="post_profile" md={12}>
              <ChannelProfile isChannelDetails={true} isAuth={isAuth} />
            </Col>
          </>
        );
      if (tab.key === "introduce")
        return (
          <>
            {!isMobile && (
              <Col md={4}>
                <ProfileLeftSidebar
                  isAuth={isAuth}
                  dataUser={dataUser}
                  dataFolder={postDirectory}
                />
              </Col>
            )}
            <Col className={!isMobile ? "ps-0" : ""} md={8}>
              <About
                isAuth={isAuth}
                dataUser={dataUser}
                isMyProfile={isMyProfile}
              />
            </Col>
          </>
        );
      if (tab.key === "follower") {
        return <Members isAuth={isAuth} dataUser={dataUser} />;
      }
      if (
        tab.key === "images" ||
        tab.key === "videos" ||
        tab.key === "mp3" ||
        tab.key === "files"
      )
        return (
          <>
            <Col>
              <ProfileMedia
                isAuth={isAuth}
                dataUser={dataUser}
                isMyProfile={isMyProfile}
                menus={menus}
                tab={tab}
                setTab={setTab}
              />
            </Col>
          </>
        );
      if (tab.key === "qr-code") return <ProfileQrCode dataUser={dataUser} />;
      if (tab.key === "settings") {
        return <SettingObjects dataUser={dataUser} />;
      }
    }
    if (tab && tab.type_view === "webview") {
      return (
        <VWebView
          url={tab.url}
          widthScrollbar={tab?.width_scrollbar}
          height="700px"
        />
      );
    }

    return (
      <>
        {!isApp && (
          <Col md={4}>
            <ProfileLeftSidebar
              isAuth={isAuth}
              dataUser={dataUser}
              dataFolder={postDirectory}
            />
          </Col>
        )}
        <Col md={8}>
          <ComingSoon />
        </Col>
      </>
    );
  };

  return isNotFound && !loading ? (
    <Error404 />
  ) : (
    !loading && (
      <div className="profile-wrapper">
        <PageBackgrounds
          page={"page_bg_profile"}
          data={
            dataUser?.profile?.background?.type
              ? dataUser?.profile?.background
              : null
          }
        />
        <Container>
          <Row>
            <Col sm={12}>
              {dataUser && !isApp && (
                <ProfileHeader dataUser={dataUser} isMyProfile={isAuth} />
              )}
            </Col>
            <Col sm={12}>
              <VTab
                hide={isApp}
                tab={tab}
                setTab={setTab}
                menus={
                  isAuth
                    ? menus
                    : menus.filter((el) => !menuRequiedOwner.includes(el.key))
                }
                activeBorder="top"
              />
              <TabPostDirectoryProfile
                isAuth={isAuth}
                postDirectory={postDirectory}
              />
              <Row>{renderTab()}</Row>
            </Col>
          </Row>
        </Container>
      </div>
    )
  );
};

export default View;

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISettingLanguage } from "types/settings.type";
import {
  getAdvertisementPositionsync,
  getChannelAsync,
  getDocumentsAsync,
  getNftsAsync,
  getProjectsAsync,
  getQAAsync,
  getSupportsAsync,
  getBannerAsync,
  getCitiesAsync,
  getRateUSDTToVNDAsync,
  getObjectsAsync,
  getLogoAsync,
  getLocationAsync,
  getSettingsAsync,
  getMenusAsync,
  getAllMenusAsync,
  getCareersAsync,
  getHobbiesAsync,
  getFieldsAsync,
  getNationsAsync,
  getLocaleAsync,
  getReligionsAsync,
  getFamiliesAsync,
  getDefaultObjectSettingsAsync,
  getUserSettingsAsync,
} from "./settings.action";
import { IObject } from "types";

export interface ISettingState {
  connectSocket: any;
  reward: any;
  adPostition: any;
  channel: any;
  documents: any;
  nfts: any;
  qa: any;
  projects: any;
  supports: any;
  banner: any;
  cities: any;
  usd_vnd: any;
  objects: any;
  logos: any;
  locations: any;
  frame_default: any;
  languages: ISettingLanguage[];
  banks: any;
  companions: any;
  menus: any;
  hobbies: any;
  careers: any;
  nations: any;
  religions: any;
  categoryField: any;
  icon_set: any;
  locales: any;
  utility_categories: any;
  weather: any;
  forecast: any;
  city: any;
  location: any;
  censor_policy: string;
  general: any;
  families: any;
  roles: IObject;
  tooltip: any;
  is_app: boolean;
  is_preview: boolean;
  user_settings: any;
}

const initialState: ISettingState = {
  connectSocket: null,
  reward: null,
  adPostition: [],
  channel: [],
  documents: [],
  nfts: [],
  qa: [],
  projects: [],
  supports: [],
  banner: [],
  cities: [],
  usd_vnd: 24000,
  objects: [],
  logos: {},
  locations: [],
  frame_default: [],
  languages: [],
  banks: [],
  companions: [],
  menus: [],
  hobbies: [],
  careers: [],
  nations: [],
  religions: [],
  categoryField: [],
  icon_set: null,
  locales: [],
  utility_categories: [],
  families: [],
  weather: null,
  city: null,
  forecast: null,
  location: null,
  censor_policy: null,
  general: null,
  roles: null,
  tooltip: [],
  is_app: false,
  is_preview: false,
  user_settings: {},
};

export const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {
    setConnectSocket: (
      state: ISettingState,
      action: PayloadAction<boolean>
    ) => {},
    setReward: (state: ISettingState, action: PayloadAction<boolean>) => {
      state.reward = action.payload;
    },
    setWeather: (state: ISettingState, action: PayloadAction) => {
      state.weather = action.payload;
    },
    setCity: (state: ISettingState, action: PayloadAction) => {
      state.city = action.payload;
    },
    setForecast: (state: ISettingState, action: PayloadAction) => {
      state.forecast = action.payload;
    },
    setLocation: (state: ISettingState, action: PayloadAction<any>) => {
      state.location = action.payload;
    },
    setIsApp: (state: ISettingState, action: PayloadAction<any>) => {
      state.is_app = action.payload;
    },
    setIsPreview: (state: ISettingState, action: PayloadAction<any>) => {
      state.is_preview = action.payload;
    },
  },
  extraReducers: {
    [`${getAdvertisementPositionsync.pending}`]: (
      state: ISettingState,
      { payload }
    ) => {},
    [`${getAdvertisementPositionsync.fulfilled}`]: (
      state: ISettingState,
      { payload }
    ) => {
      const { errors, setting } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.adPostition = setting["value"];
      }
    },
    [`${getChannelAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getChannelAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.channel = setting["value"];
      }
    },
    [`${getLogoAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getLogoAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        //handle error
        return;
      } else {
        state.logos = setting["value"];
      }
    },
    [`${getNftsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getNftsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.nfts = setting["value"];
      }
    },
    [`${getDocumentsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getDocumentsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.documents = setting["value"];
      }
    },
    [`${getQAAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getQAAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.qa = setting["value"];
      }
    },
    [`${getSupportsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getSupportsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.supports = setting["value"];
      }
    },
    [`${getProjectsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getProjectsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.projects = setting["value"];
      }
    },
    [`${getBannerAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getBannerAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.banner = setting["value"];
      }
    },
    [`${getCitiesAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getCitiesAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.cities = payload;
      }
    },
    // location
    [`${getLocationAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getLocationAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.locations = payload;
      }
    },
    // rate
    [`${getRateUSDTToVNDAsync.pending}`]: (
      state: ISettingState,
      { payload }
    ) => {},
    [`${getRateUSDTToVNDAsync.fulfilled}`]: (
      state: ISettingState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.usd_vnd = payload;
      }
    },
    [`${getObjectsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getObjectsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, setting } = payload;
      if (errors) {
        return;
      } else {
        state.objects = setting["value"];
      }
    },
    [`${getSettingsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getSettingsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors, settings } = payload;
      if (errors) {
        return;
      } else {
        const _data = (name) => {
          const result = settings.find((s) => name === s.name) || {};
          return result.value;
        };
        state.objects = _data("objects") || [];
        state.languages = _data("languages") || [];
        state.banks = _data("banks") || [];
        state.logos = _data("logos") || {};
        state.companions = _data("companions") || [];
        state.icon_set = _data("icon_set") || null;
        state.utility_categories = (_data("utility_categories") || []).sort(
          (a, b) => a.order - b.order
        );
        state.censor_policy = _data("censor_policy");
        state.general = _data("general");
        state.roles = _data("roles") || [];
        state.tooltip = _data("tooltip") || [];
      }
    },
    [`${getMenusAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getMenusAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.menus = payload;
      }
    },
    [`${getAllMenusAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getAllMenusAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.menus = payload;
      }
    },
    [`${getCareersAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getCareersAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.careers = payload;
      }
    },
    [`${getNationsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getNationsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.nations = payload;
      }
    },
    [`${getReligionsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getReligionsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.religions = payload;
      }
    },
    [`${getHobbiesAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getHobbiesAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.hobbies = payload;
      }
    },
    [`${getFieldsAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getFieldsAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.categoryField = payload;
      }
    },
    [`${getLocaleAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getLocaleAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.locales = payload;
      }
    },
    [`${getFamiliesAsync.pending}`]: (state: ISettingState, { payload }) => {},
    [`${getFamiliesAsync.fulfilled}`]: (state: ISettingState, { payload }) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.families = payload;
      }
    },
    [`${getDefaultObjectSettingsAsync.pending}`]: (
      state: ISettingState,
      { payload }
    ) => {},
    [`${getDefaultObjectSettingsAsync.fulfilled}`]: (
      state: ISettingState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.careers = payload.careers;
        state.families = payload.families;
        state.categoryField = payload.categoryField;
        state.hobbies = payload.hobbies;
        state.religions = payload.religions;
        state.nations = payload.nations;
        state.locations = payload.locations;
        state.frame_default = payload.frame_default?.docs;
      }
    },

    [`${getUserSettingsAsync.pending}`]: (
      state: ISettingState,
      { payload }
    ) => {},
    [`${getUserSettingsAsync.fulfilled}`]: (
      state: ISettingState,
      { payload }
    ) => {
      const { errors } = payload;
      if (errors) {
        return;
      } else {
        state.user_settings = payload;
      }
    },
  },
});

export const {
  setConnectSocket,
  setReward,
  setWeather,
  setCity,
  setForecast,
  setLocation,
  setIsApp,
  setIsPreview
} = settingsSlice.actions;

export default settingsSlice.reducer;

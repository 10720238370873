/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getQuotasAsync } from "./quotas.action";

export interface IQuotasState {
  quotas: any[];
}

const initialState: IQuotasState = {
  quotas: [],
};

export const quotasSlice = createSlice({
  name: "quotas",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuotasAsync.pending, (_state) => {})
      .addCase(getQuotasAsync.fulfilled, (state, action) => {
        const { error } = action.payload;
        if (error) {
          return;
        }
        state.quotas = action.payload;
      });
  },
});

// eslint-disable-next-line no-empty-pattern
export const {} = quotasSlice.actions;

export default quotasSlice.reducer;

/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Row, Col, Container } from "react-bootstrap";
import VRightView from "components/v-right-view";

import NewsFeedModule from "../home/newsfeed";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const View = (props: any) => {
  const { type, plugin } = props;
  const dispatch = useDispatch();
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  const [location, setLocation] = useState<any>();
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });
    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  useEffect(() => {
    if (!type) {
      navigator.geolocation.getCurrentPosition(function (position) {
        setLocation({
          lng: position.coords.longitude,
          lat: position.coords.latitude,
        });
      });
    }
  }, [type]);

  const renderTab = () => {
    return (
      <NewsFeedModule
        type={type}
        plugin={plugin}
        lat={location?.lat}
        lng={location?.lng}
        moduleSessionId={moduleSessionId}
        fromSessionUid={fromSessionUid}
      />
    );
  };

  return (
    <div className="videos-link-wrapper">
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            {(type || (!type && location)) && renderTab()}
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1 "}>
              <VRightView
                className="newfeed-rightview"
                positionBanner={"banner_right_home"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default View;

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
// import "./style.scss";
import VButton from "components/v-button";
import { convertTimestamptoDate } from "utils/times";
import { formatNumber } from "utils/common";
import InvestService from "api/invest";
import { toast } from "react-toastify";
import VCongratulationInvests from "../../../../components/v-congratulations";
import { useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { useHistory } from "react-router-dom";
import {
  getCryptosByObject,
  getStocksPrice,
} from "redux/reducers/cryptos/cryptos.selector";
import Input from "components/input";
import VModal from "components/v-modal";
import VTable from "components/v-table";
import { ROUTES } from "config/routes";
import VIcon from "components/v-icon";

const View = ({ data, setDetail }) => {
  const history = useHistory();

  const stocks = useAppSelector(getCryptosByObject);
  const prices = useAppSelector(getStocksPrice);

  // const [loading, setLoading] = useState(null);
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [openModalClaimed, setOpenModalClaimed] = useState(false);
  const [investSelected, setInvestSelected] = useState(null);
  const [historiesProfit, setHistoriesProfit] = useState(null);

  const [state, setState] = useState({
    amountFrom: null,
    amountTo: null,
  });

  const [openModalCongratulation, setOpenModalCongratulation] =
    useState<boolean>(false);
  // const [histories, setHistories] = useState([]);

  const isProfitClaiming = data.investment.profit.remaining === 0;
  const isPrincipalClaiming = data.investment.principal.remaining === 0;

  const columnsHistoriesProfit = [
    { key: "unlock", title: "Unlock", dataIndex: "unlock" },
    {
      key: "Date",
      title: "Date",
      render: (item) => {
        return convertTimestamptoDate(item.date);
      },
    },
    {
      key: "amount",
      title: "Amount",
      render: (item) => {
        return `${item.amount} (${String(item.stock).toUpperCase()})`;
      },
    },
    {
      key: "Hash",
      title: "Hash",
      render: (item) => (
        <a
          href={`https://block.vdiarybook.com/tx/${item.hash}`}
          target={"_blank"}
          rel="noreferrer"
        >
          {item.hash?.slice(0, 20)}...
        </a>
      ),
    },
  ];

  // const getHistories = async () => {
  //   try {
  //     const res: any = await InvestService.getHitories({
  //       query: {
  //         limit: 20,
  //         page: 1,
  //       },
  //     });
  //     if (res && res.docs) setHistories(res.docs);
  //   } catch (error) {}
  // };

  const handleClosed = () => {
    setOpenModal(false);
    setOpenModalCongratulation(false);
    setInvestSelected(null);
  };

  const handleChange = (type, value, price) => {
    let amountFrom = 0;
    let amountTo = 0;
    if (type === "from") {
      amountFrom = +value;
      amountTo = +value / +price;
    } else {
      amountTo = +value;
      amountFrom = +value * +price;
    }
    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };

  const handleClosedClaim = () => {
    setInvestSelected(null);
    setOpenModalClaimed(false);
    setState({
      ...state,
      amountFrom: 0,
      amountTo: 0,
    });
  };

  const handleClaim = async (type, amount?: number) => {
    try {
      if (type === "profit") setLoadingClaim(true);
      // else setLoading(true);
      await InvestService.claim({
        body: {
          type,
          purchase_id: data._id,
          amount,
        },
      });
      // getHistories();
      // setLoading(null);
      setLoadingClaim(false);
      handleClosedClaim();
      toast.success("Claim success!");
    } catch (error) {
      // setLoading(null);
      setLoadingClaim(false);
      toast.error(error.errors);
    }
  };

  const handleBack = () => {
    setDetail(false);
    return history.push(`${ROUTES.INVEST}?tab=history`);
  };

  const renderModal = () => {
    if (data) {
      return (
        <div>
          <VTable
            columns={columnsHistoriesProfit}
            dataSource={historiesProfit}
            bgTable={"black100"}
          ></VTable>
        </div>
      );
    }
  };

  const renderModalClaim = () => {
    if (data) {
      const from = data?.investment?.profit.stock;
      const to = data?.investment?.profit.stock_claim;
      const price = prices[from]["vnd"];
      return (
        <div className="package-modal">
          <div>
            <div className="image">
              <img src={stocks[from]["logo"]} alt="" />
            </div>
            <span>{from.toUpperCase()}</span>
            <Input
              name="amountFrom"
              // type="number"
              isCurrency={true}
              value={state.amountFrom}
              placeholder="0.00"
              onValueChange={(e) => handleChange("from", e, price)}
            />
          </div>
          <div className="text-center">
            <img src="/assets/images/icons/transfer.svg" alt="" />
          </div>
          <div>
            <div className="image">
              <img src={stocks[to]["logo"]} alt="" />
            </div>
            <span>{to.toUpperCase()}</span>
            <Input
              isCurrency={true}
              name="amountTo"
              value={state.amountTo}
              placeholder="0.00"
              onValueChange={(e) => handleChange("to", e, price)}
            />
          </div>
          <div>
            <span>Rate</span>
            <span>
              1 {String(to).toUpperCase()} = {price.toFixed(2)}{" "}
              {String(from).toUpperCase()}
            </span>
          </div>
          {/* <VButton
            onClick={() => handleClaim("profit", state.amountFrom)}
            loading={loadingClaim}
          >
            {t("Claim")}
          </VButton> */}
        </div>
      );
    }
  };
  const openModalHistoryProfit = async () => {
    try {
      setInvestSelected(data);
      setOpenModal(true);
      const res: any = await InvestService.getHitoriesProfit({
        params: {
          investId: data._id,
        },
        query: {
          limit: 20,
          page: 1,
        },
      });
      if (res && res.docs) setHistoriesProfit(res.docs);
    } catch (error) {}
  };

  const renderLabelHistory = (icon: any, label: string) => {
    return (
      <div className="_label-history d-flex gap-1 align-items-center">
        {icon && <VIcon name={icon} width={15} />}
        <span>{label}:</span>
      </div>
    );
  };
  return (
    <div className="package-history container p-3">
      {/* <VTable columns={columns} dataSource={histories}></VTable> */}

      {openModalClaimed && (
        <VModal
          title={data.package_detail.name}
          isOpen={openModalClaimed}
          loading={loadingClaim}
          onConfirm={(_) => handleClaim("profit", state.amountFrom)}
          children={renderModalClaim()}
          onCancel={handleClosedClaim}
          centered={true}
        />
      )}

      {openModal && (
        <VModal
          title="Reward history"
          isOpen={openModal}
          children={renderModal()}
          onCancel={handleClosed}
          centered={true}
        />
      )}
      <VCongratulationInvests
        type="investsSuccess"
        background={investSelected?.package_detail?.backgroundSuccess}
        userName={investSelected?.user?.full_name}
        avataUser={investSelected?.user?.avatar}
        logoProject={investSelected?.package_detail?.icon}
        logoLevel={investSelected?.package_detail?.logoLevel}
        amount={investSelected?.investment?.amount}
        symbol={investSelected?.package_detail?.invest?.stock}
        createdAt={investSelected?.createdAt}
        openModalCongratulation={openModalCongratulation}
        setOpenModalCongratulation={setOpenModalCongratulation}
      />
      <div className="border-bottom">
        <div>
          <div className="pb-2">
            <VIcon name="left-arrow.svg" onClick={handleBack} />
          </div>
        </div>
        <div>
          <div>
            <div className="d-flex align-items-center mb-3">
              {renderLabelHistory("help.svg", "Transaction Hash")}
              <div className="d-flex w-100 justify-content-between">
                <a
                  href={`
                    https://block.vdiarybook.com/tx/${data.investment.hash}`}
                  target={"_blank"}
                  rel="noreferrer"
                >
                  {data.investment.hash?.slice(0, 20)}...
                </a>
                <VIcon name="copy.svg" width={15} />
              </div>
            </div>
            <div className="d-flex align-items-center mb-3">
              {renderLabelHistory("help.svg", "Status")}
              <div className="text-success">{data.status}</div>
            </div>
            <div className="d-flex align-items-center mb-3">
              {renderLabelHistory("help.svg", "Timestamp")}
              <div>{convertTimestamptoDate(data.createdAt)}</div>
            </div>
          </div>
          <div>
            <div className="d-flex align-items-center mb-3">
              {renderLabelHistory("help.svg", "From")}
              <div>{convertTimestamptoDate(data.investment.from_date)}</div>
            </div>
            <div className="d-flex align-items-center mb-3">
              {renderLabelHistory("help.svg", "To")}
              <div className="d-flex w-100 justify-content-between">
                <span>{convertTimestamptoDate(data.investment.to_date)}</span>
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center mb-3">
            {renderLabelHistory("help.svg", "Amount")}
            <div>
              <span>{formatNumber(data.investment.amount)}</span>
              <span>
                {`(${String(data.package_detail.invest.stock).toUpperCase()})`}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="border-bottom mt-3">
        <div className="d-flex align-items-center mb-3 justify-content-between">
          {renderLabelHistory("help.svg", "Hóa đơn")}
          <div>
            <span></span>
            <a
              href={`/invoice/investment/${data.package_detail["name"]}/${data._id}`}
              target={"_blank"}
              rel="noreferrer"
            >
              <VButton>View</VButton>
            </a>
          </div>
        </div>
        <div className="d-flex align-items-center mb-3 justify-content-between">
          {renderLabelHistory("help.svg", "Đóng gói")}
          <div>
            <VButton
              onClick={() => {
                setOpenModalCongratulation(true);
                setInvestSelected(data);
              }}
            >
              View
            </VButton>
          </div>
        </div>
        <div className="d-flex align-items-center mb-3 justify-content-between">
          {renderLabelHistory("help.svg", "Lịch sử")}
          <div>
            <VButton onClick={openModalHistoryProfit}>View</VButton>
          </div>
        </div>
      </div>
      <div className="mt-3">
        <div className="d-flex justify-content-between align-items-start mb-3">
          {renderLabelHistory("", "Principal")}
          <div className="d-flex w-100 justify-content-between">
            <div className="d-flex flex-column">
              <span>{`Total: ${formatNumber(
                data.investment.principal.total
              )}(${String(
                data.investment.principal.stock
              ).toUpperCase()})`}</span>
              <span>{`Claimed: ${formatNumber(
                data.investment.principal.claimed
              )}(${String(
                data.investment.principal.stock
              ).toUpperCase()})`}</span>
              <span>{`Remaining: ${formatNumber(
                data.investment.principal.remaining
              )}(${String(
                data.investment.principal.stock
              ).toUpperCase()})`}</span>
            </div>
            <div>
              <VButton
                disabled={isPrincipalClaiming}
                onClick={() => handleClaim("principal")}
              >
                Claim
              </VButton>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between align-items-start mb-3">
          {renderLabelHistory("", "Profit")}
          <div className="d-flex w-100 justify-content-between">
            <div className="d-flex flex-column">
              <span>{`Total: ${formatNumber(
                data.investment.profit.total
              )}(${String(data.investment.profit.stock).toUpperCase()})`}</span>
              <span>{`Claimed: ${formatNumber(
                data.investment.profit.claimed
              )}(${String(data.investment.profit.stock).toUpperCase()})`}</span>
              <span>{`Remaining: ${formatNumber(
                data.investment.profit.remaining
              )}(${String(data.investment.profit.stock).toUpperCase()})`}</span>
            </div>
            <div>
              <VButton
                loading={isProfitClaiming}
                onClick={() => setOpenModalClaimed(true)}
              >
                Claim
              </VButton>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default View;

import useTranslation from "hooks/useTranslation";
import { copyText, getUrlImage } from "utils/common";
import DropdownModal from "components/v-dropdown-modal/View";
import VIcon from "components/v-icon";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import { useHistory } from "react-router-dom";
import VModal from "components/v-modal";
import { useState } from "react";

const View = ({ name, count, image, key_name, tab = "images" }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  // const query = useQuery();
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [title, setTitle] = useState("");

  const menuPostShare = useAppSelector(getMenusByKey("post_share"));
  const listActionShare = menuPostShare?.filter(
    (share) => !share?.parent_id && share?.key !== "send_to_message"
  );
  const menuPostOtherSharing = menuPostShare?.filter(
    (el) =>
      el?.parent_id ===
      menuPostShare?.find((t) => t.key === "other_sharing")?._id
  );

  const handleViewDetail = (album) => {
    history.push(`?tab=${tab}&album=${album}`);
  };

  const handlePostOtherSharing = (action: any) => {
    window.open(`${action?.url?.replace("{url}", `${window.location.href}`)}`);
  };

  const handleClickOption = (type: string, album: any, item?: any) => {
    if (type === "copy") {
      copyText(`${window.location.href}&album=${album}`);
      toast.success(t("Copied to clipboard!"));
    }
    if (type === "share") {
      if (item?.key === "other_sharing") {
        setOpenModal(true);
        setTitle(item?.name);
      } else {
        dispatch(
          setOpenWebModulesPopupAction({
            page: "create",
            openWebModuleKey: "share_drive",
            typeShare: item?.key,
            contentShare: `${window.location.href}&album=${album}`,
          })
        );
      }
    }
  };

  return (
    <>
      <div className="user-images position-relative">
        <div className="action-item-media">
          <DropdownModal
            label={
              <div className="item-action-corver">
                <VIcon width={12} height={10} name={"option.svg"}></VIcon>
              </div>
            }
            menu={[
              {
                key: t("Chia sẻ"),
                children: listActionShare,
                onClick: (_) => handleClickOption("share", key_name, _),
              },
              {
                key: t("Copy link"),
                onClick: (_) => handleClickOption("copy", key_name),
              },
            ]}
          />
        </div>
        <div className={`item-image ${count === 0 ? "item-no-image" : ""}`}>
          <img
            onClick={() => handleViewDetail(key_name)}
            src={
              image && count > 0
                ? getUrlImage(image, 150)
                : `/assets/icons/default/no-image.svg`
            }
            className="img-fluid rounded"
            alt="Responsive"
          />
        </div>
      </div>
      <div className="w-100 mt-2">
        <div className="fw-bold fs-5">{t(name)}</div>
        <span>
          {count} {t("item")}
        </span>
      </div>

      <VModal
        title={t(title)}
        isOpen={openModal}
        centered={true}
        // disableConfirm={!isPolicy}
        confirmLabel={t("Add")}
        classNameBody={"px-2"}
        children={
          <div className="d-flex justify-content-center flex-wrap my-3">
            {menuPostOtherSharing?.map((el, index) => (
              <div key={index} className="col-3 max-w-[100px]">
                <div
                  className="d-flex position-relative flex-column justify-content-center align-items-center cursor-pointer rounded-lg"
                  onClick={() => handlePostOtherSharing(el)}
                >
                  <div className="d-flex align-items-center justify-content-center p-2">
                    <img
                      width={30}
                      src={getUrlImage(el?.img_icon, 150)}
                      alt=""
                    />
                  </div>
                  <div className={`d-flex align-items-center text-xs mt-1`}>
                    {`${el.name}`}
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
};

export default View;

/* eslint-disable import/no-anonymous-default-export */
import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "querystring";

const getPackages = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/packages/?status=${req.query.status}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const investPackage = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/invests`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getHitories = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/invests/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getHitoriesProfit = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `/invests/getHistoriesProfit/${req.params.investId}?${qs.stringify(
          req.query
        )}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const claim = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/invests/claim`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInvoice = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/invests/invoice/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      });
  });

const InvestService = {
  getPackages,
  investPackage,
  getHitories,
  getHitoriesProfit,
  claim,
  getInvoice,
};

export default InvestService;

/* eslint-disable indent */
import Form from 'react-bootstrap/Form';

const View = (props) => {
	const { label, checked, onChange } = props;
	return (
		<Form>
			<Form.Check
				type="switch"
				id="custom-switch"
				checked={checked}
				label={label}
				onChange={(e) => onChange(e.target.checked)}
			/>
		</Form>
	);
}

export default View;
import { Modal } from "react-bootstrap";
import Button from "components/v-button";
import useTranslation from "hooks/useTranslation";

type Props = {
  isOpen?: any;
  title?: any;
  onCancel?: any;
  onConfirm?: any;
  cancelLabel?: any;
  confirmLabel?: any;
  loading?: any;
  centered?: any;
  fullscreen?: any;
  showImage?: any;
  children?: any;
  disableConfirm?: boolean;
  element?: any;
  onDelete?: any;
  deleteLabel?: any;
  showFooter?: boolean;
  [key: string]: any;
};

const View = ({ children, ...props }: Props) => {
  const { t } = useTranslation();

  const {
    isOpen,
    title,
    onCancel,
    disableConfirm,
    onConfirm,
    cancelLabel,
    confirmLabel,
    loading = false,
    centered = false,
    fullscreen = false,
    showImage = false,
    element,
    onDelete,
    deleteLabel,
    showFooter = true,
    className = "",
    classNameBody = "",
    size= "md",
  } = props;
  return (
    <>
      <Modal
        centered={centered}
        show={isOpen}
        fullscreen={fullscreen}
        showImage={showImage}
        onHide={onCancel}
        className={className}
        size={size}
      >
        {title && (
          <Modal.Header closeButton>
            <Modal.Title className="w-100 text-center text-primary fw-bold">{title}</Modal.Title>
          </Modal.Header>
        )}
        <Modal.Body className={classNameBody}>{children}</Modal.Body>
        {showFooter && (
          <Modal.Footer>
            {onCancel && (
              <Button className={"close"} disabled={loading} onClick={() => onCancel()}>
                {cancelLabel || t("Close")}
              </Button>
            )}

            {onDelete && (
              <Button
                disabled={loading}
                onClick={() => onDelete()}
                color={"red100"}
              >
                {deleteLabel || t("Delete")}
              </Button>
            )}

            {onConfirm && (
              <Button
                onClick={() => onConfirm()}
                loading={loading}
                disabled={disableConfirm}
              >
                {confirmLabel || t("Confirm")}
              </Button>
            )}

            {element && element}
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default View;

import { IRequestData, parseErrorResponse, request } from "./request";

const create = (req: IRequestData) => {
  return new Promise((resolve, reject) => {
    request()
      .post(`/register-receive-infos`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const update = (req: IRequestData) => {
  return new Promise((resolve, reject) => {
    request()
      .put(`/register-receive-infos/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const RegisterReceiveInfo = {
  create,
  update,
};

export default RegisterReceiveInfo;

/* eslint-disable indent */
import VIcon from "components/v-icon";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import { useHistory, useLocation } from "react-router-dom";

type IProps = {
  isMyProfile: boolean;
  dataUser: any;
};

const View = (props: IProps) => {
  const { isMyProfile, dataUser } = props;
  const history = useHistory();
  const location = useLocation();
  const { t } = useTranslation();

  const handleAction = (type) => {
    history.push(
      `${location.pathname}?tab=introduce${
        type === "edit" ? "&isEdit=true" : ""
      }`
    );
  };

  return (
    <Card>
      <Card.Header className={"px-2"}>
        <div className="w-100 d-flex justify-content-between">
          <div className="title-left-sidebar">{t("Introduce")}</div>
          {isMyProfile && (
            <div className="cursor-pointer">
              <VIcon
                name="edit-page.svg"
                width={16}
                onClick={() => handleAction("edit")}
              />
            </div>
          )}
        </div>
      </Card.Header>
      <Card.Body>
        <div className="text-center">
          <span>{dataUser?.profile?.family_data?.description}</span>
        </div>
        <div
          className="d-flex py-1 cursor-pointer"
          onClick={() => handleAction("view")}
        >
          <VIcon name="tree-dot.svg" className="me-2" width={16} />
          <span>{t("View more information")}</span>
        </div>
      </Card.Body>
    </Card>
  );
};

export default View;

/* eslint-disable max-len */
import {
  coords as provinceCoords,
} from "constants/provinces";

export const filterAddress = (
  key: string,
  data: any,
  code: number,
  setState: any
) => {
  const newData = data.find((el) => el.code === code);

  if (key === "provices") {
    setState(newData.districts);
  } else if (key === "districts") {
    setState(newData.wards);
  } else {
    // console.log();
  }
};

const getAddress = (locations, address) => {
  let ids = [];
  if (address.country) ids.push(address.country);

  const countryName =
    locations?.find((a) => a.type === "country" && a._id === address?.country)
      ?.name || "";
  let provinceName;
  let districtName;
  let wardName;

  const _provinces = locations?.filter(
    (pro: any) => pro.parent === address?.country
  );
  provinceName = _provinces.find((el) => el._id === address?.province)?.name;

  if (address?.province) {
    const _districts: any = locations?.filter(
      (pro: any) => pro.parent === address.province
    );
    districtName = _districts.find((el) => el._id === address.district)?.name;
  }
  if (address?.district) {
    const _wards: any = locations?.filter(
      (pro: any) => pro.parent === address.district
    );
    wardName = _wards.find((el) => el._id === address?.ward)?.name;
  }

  return `${address?.detail ? `${address?.detail}, ` : ""}${
    wardName ? `${wardName}, ` : ""
  }${districtName ? `${districtName}, ` : ""}${
    provinceName ? `${provinceName}, ` : ""
  }${countryName ? `${countryName}` : ""}`;
};

export const getLatLngByAddressName = (data: any, provinces: any) => {
  let loadedCoords: any;
  const {country, province, district} = data;

  if (country !== "6296ee56b4382126ae3b148e") return null;

  const districtCoord = provinceCoords.find(
    ({ city, admin_name }) =>
      provinces
        .find(({ code }) => code === province)
        ?.districts.find(({ code }) => code === district)
        ?.name.includes(city) &&
      provinces
        .find(({ code }) => code === province)
        ?.name.includes(admin_name)
  );
  if (districtCoord?.lat && districtCoord?.lng) {
    loadedCoords = {
      lat: Number(districtCoord.lat),
      lng: Number(districtCoord.lng),
    };
  } else {
    const provinceCoord = provinceCoords.find(
      ({ capital, admin_name }) =>
        (capital === "primary" || capital === "admin") &&
        provinces
          .find(({ code }) => code === province)
          ?.name.includes(admin_name)
    );
    if (provinceCoord?.lat && provinceCoord?.lng) {
      loadedCoords = {
        lat: Number(provinceCoord.lat),
        lng: Number(provinceCoord.lng),
      };
    }
  }

  return loadedCoords;
}

export { getAddress };

import {
  exportComponentAsJPEG,
  exportComponentAsPNG,
} from "react-component-export-image";
import { Download } from "./styles";

const DownloadImg = ({ imgRef, fileName }) => {
  return (
    <>
      <Download>
        <button
          onClick={() =>
            exportComponentAsPNG(imgRef, {
              fileName:
                `${fileName}_vdiarybook_${new Date().getTime()}` || "vzonex",
            })
          }
        >
          Download (PNG)
        </button>
        <button
          onClick={() =>
            exportComponentAsJPEG(imgRef, {
              fileName:
                `${fileName}_vdiarybook_${new Date().getTime()}` || "vzonex",
            })
          }
        >
          Download (JPEG)
        </button>
      </Download>
    </>
  );
};

export default DownloadImg;

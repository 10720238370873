import { IRootState } from "..";

export const getSuggestPages = (state: IRootState) => state.pages.suggests;

export const getDataAccesses = (state: IRootState) =>
  state.friends.dataAccesses;

export const getFollowingPages = (state: IRootState) => state.pages.followings;

export const getManagePages = (state: IRootState) => state.pages.manage;

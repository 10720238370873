import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getSuggests = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/channels/suggests?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getManage = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/channels/manage?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getFollowings = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/channels/followings?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getFollowers = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/channels/followers/${req.params.id}?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/channels/create`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ChannelService = {
  create,
  getSuggests,
  getManage,
  getFollowers,
  getFollowings,
};

export default ChannelService;

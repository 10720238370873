import { Card } from "react-bootstrap";
import { getFas } from "redux/reducers/faqs/faqs.selectors";
import { useAppSelector } from "redux/reducers/hook";

const RightAndObligation = () => {
  const qas = useAppSelector(getFas).find(
    (qa) => qa.key === "censor_right_and_obligation"
  );
  return (
    <Card className="right_and_obligation">
      <Card.Body>
        <div dangerouslySetInnerHTML={{ __html: qas?.content }} />
      </Card.Body>
    </Card>
  );
};

export default RightAndObligation;

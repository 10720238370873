import React from "react";
import { Switch, Route } from "react-router-dom";

//TransitionGroup
import { TransitionGroup, CSSTransition } from "react-transition-group";

import Faq from "../pages/social/faq/faq";
import TermsofService from "../pages/social/terms/terms-of-service";
import { ROUTES } from "config/routes";

import MyWallet from "pages/stocks/wallets";
import MySwap from "pages/stocks/swap";
import MyInvests from "pages/stocks/invests";
import MyTask from "pages/stocks/task";
import Campaign from "pages/stocks/campaign";
import Supports from "pages/social/supports";
import WelcomeModal from "components/welcome-modal";
import Settings from "pages/social/settings";
import UserPrivacySetting from "pages/social/user-privacy-setting";
import Friends from "pages/social/friends";
import FollowSuggest from "pages/social/friends/follow";
import FriendReqest from "pages/social/friends/request";
import UserProfile from "pages/social/user/profile";
import ProfileBadgesMascots from "pages/social/user/badges-mascots/profile-badges-mascots";
import ProfileImages from "pages/social/user/images/profile-images";
import Notification from "pages/social/notification/notification";
import Groups from "pages/social/groups";
import Calendar from "pages/social/calender/calendar";
import Birthday from "pages/social/birthday/birthday";
import Weather from "pages/social/weather";
import Maintenance from "pages/maintenance/maintenance";
import PrivacyPolicy from "pages/social/privacy-policy/privacy-policy";
import ProfileVideos from "pages/social/user/videos/profile-videos";
import ProfileQuotations from "pages/social/user/quotations/quotations";
import Home from "pages/social/home";
import WebView from "pages/social/web-view";
import Welcome from "pages/social/welcome";
import Utilities from "pages/utilities";
import Business from "pages/social/business";
import ProfileBusiness from "pages/social/business/profile";
import ProfileGroup from "pages/social/groups/profile";
import Pages from "pages/social/pages";
import ProfilePage from "pages/social/pages/profile";
import Search from "pages/social/search";
import FamilyTree from "pages/social/family-tree";
import Rankings from "pages/social/rankings";
import MyRankings from "pages/social/user/rankings";
import Suggests from "pages/social/suggests";
import ScanQR from "components/scanQR";
import Censor from "pages/social/censor";
import Advertisenment from "pages/social/advertisenment";
import Loans from "pages/social/loans";
import ProfileChannel from "pages/social/channel/profile";
import Channel from "pages/social/channel";
import MyChannel from "pages/social/channel/my-channel";
import VideoLink from "pages/social/video-link";
import VMedia from "pages/social/v-media";
import Events from "pages/social/events";
import Surveys from "pages/social/survey";
// import PostDetail from "pages/posts/post-detail";
import EventsV3 from "pages/social/event-v3";
import FeedByTypes from "./feed-by-type";
// import ProductDetail from "pages/stocks/shop/products/Detail";
import SettingsConversation from "pages/social/user/profile/settings-conversations";
import Pay from "pages/pay";
import Reels from "pages/reels";
import Question from "pages/question";
import PostDetail from "pages/posts/post-detail";
import Shop from "pages/social/shop";
import ShopByUserId from "pages/social/shop/user_id";
import RegisterReceiveInfo from "pages/social/register-receive-info";
import BookingManage from "pages/adv/booking-manage";
import ScreenManage from "pages/adv/screen-manage";

const MyRouter = () => {
  return (
    <>
      <WelcomeModal />
      <TransitionGroup>
        <CSSTransition classNames="fadeUp" timeout={300}>
          <Switch>
            <Route
              path={ROUTES.USER_PRIVACY_SETTING}
              component={UserPrivacySetting}
            />
            <Route path={ROUTES.WELCOME} component={Welcome} />

            <Route path={ROUTES.SEARCH} component={Search} />
            <Route path={ROUTES.SCAN} component={ScanQR} />
            {/* Friend */}
            <Route path={ROUTES.FRIENDS} component={Friends} />
            <Route path={ROUTES.FRIEND_FOLLOW} component={FollowSuggest} />
            <Route path={ROUTES.FRIEND_REQUEST} component={FriendReqest} />
            <Route path={ROUTES.SUGGESTS} component={Suggests} />

            {/* Profile */}
            <Route
              path={[ROUTES.PROFILE_BADGES, ROUTES.PROFILE_MASCOTS]}
              component={ProfileBadgesMascots}
            />
            <Route path={ROUTES.PROFILE_IMAGES} component={ProfileImages} />
            <Route path={ROUTES.PROFILE_VIDEOS} component={ProfileVideos} />
            <Route path={ROUTES.PROFILE_BUSINESS} component={ProfileBusiness} />
            <Route path={ROUTES.PROFILE_GROUP} component={ProfileGroup} />
            <Route path={ROUTES.PROFILE_PAGE} component={ProfilePage} />
            <Route path={ROUTES.PROFILE_CHANNEL} component={ProfileChannel} />

            <Route
              path={ROUTES.PROFILE_QUOTATIONS}
              component={ProfileQuotations}
            />
            <Route path={ROUTES.PROFILE} component={UserProfile} />
            <Route path={ROUTES.PROFILE_RANKING} component={MyRankings} />

            <Route path={ROUTES.NOTIFICATION} component={Notification} />
            <Route path={ROUTES.FILES} component={File} />
            <Route path={ROUTES.RANKING} component={Rankings} />

            {/* Business */}
            <Route path={ROUTES.BUSINESS} component={Business}></Route>

            {/* Group */}
            <Route path={ROUTES.GROUP} component={Groups}></Route>

            {/* Pages */}
            <Route path={ROUTES.PAGES} component={Pages}></Route>

            {/* Loans */}
            <Route path={ROUTES.FINANCIAL_NEED} component={Loans}></Route>

            {/* Channel */}
            <Route path={ROUTES.CHANNEL} component={Channel}></Route>
            <Route path={ROUTES.MY_CHANNEL} component={MyChannel}></Route>

            <Route path={ROUTES.FAMILY_TREE} component={FamilyTree}></Route>

            {/* <Route path={ROUTES.GROUP_CHAT} component={GroupChat}></Route> */}

            <Route path={ROUTES.CALENDAR} component={Calendar} />
            <Route path={ROUTES.BIRTHDAY} component={Birthday} />
            <Route path={ROUTES.WEATHER} component={Weather} />
            <Route path={ROUTES.REELS} component={Reels} />

            {/* vzonex */}
            {/* exchanges */}
            <Route path={ROUTES.MY_WALLETS} component={MyWallet} />
            <Route path={ROUTES.MY_SWAP} component={MySwap} />
            <Route path={ROUTES.CENSOR} component={Censor} />

            {/* products */}
            <Route path={ROUTES.INVEST} component={MyInvests} />
            <Route path={ROUTES.SHOP_USER_ID} component={ShopByUserId} />
            <Route path={ROUTES.SHOP} component={Shop} />
            <Route path={ROUTES.SHOP_LIST_USER_ID} component={ShopByUserId} />
            <Route path={ROUTES.SHOP_LIST} component={Shop} />
            <Route path={ROUTES.MY_TASK} component={MyTask} />

            {/* <Route path={ROUTES.PRODUCT_DETAIL} component={ProductDetail} /> */}
            <Route path={ROUTES.POST_DETAIL} component={PostDetail} />

            <Route path={ROUTES.CAMPAIGN} component={Campaign} />
            <Route path={ROUTES.SUPPORTS} component={Supports} />

            {/* user settings */}
            <Route path={ROUTES.SETTING} component={Settings} />

            <Route path={ROUTES.MAINTENACE} component={Maintenance} />

            {/* extrapages */}
            <Route path={ROUTES.FAQ} component={Faq} />
            <Route path={ROUTES.PRIVACY} component={PrivacyPolicy} />
            <Route path={ROUTES.TERMS} component={TermsofService} />
            <Route path={ROUTES.WEBVIEW} component={WebView} />

            <Route path={ROUTES.HOME} exact component={Home} />

            <Route path={ROUTES.UTILITIES} component={Utilities} />
            <Route path={ROUTES.TEAM} component={Utilities} />

            <Route path={ROUTES.ADVERTISEMENTS} component={Advertisenment} />
            <Route path={ROUTES.EVENTS_V3} component={EventsV3} />

            <Route path={ROUTES.VIDEO_LINK} component={VideoLink} />
            <Route path={ROUTES.V_MEDIA} component={VMedia} />

            <Route path={ROUTES.NEW_EVENTS} component={Events} />
            <Route path={ROUTES.SURVEYS} component={Surveys} />

            {/* <Route path={ROUTES.POST_DETAIL} component={PostDetail} /> */}
            <Route
              path={ROUTES.PROFILE_CHAT}
              component={SettingsConversation}
            />
            <Route path={ROUTES.PAY} component={Pay} />
            <Route path={ROUTES.QUESTION} component={Question} />
            <Route
              path={ROUTES.REGISTER_RECEIVE_INFO}
              component={RegisterReceiveInfo}
            />

            {/* adv */}
            <Route path={ROUTES.ADV.BOOKING_MANAGE} component={BookingManage} />
            <Route path={ROUTES.ADV.SCREEN_MANAGE} component={ScreenManage} />

            {/* feed by type */}
            <Route path="/" component={FeedByTypes} />
          </Switch>
        </CSSTransition>
      </TransitionGroup>
    </>
  );
};

export default MyRouter;

/* eslint-disable indent */
import TimeAgo from "javascript-time-ago";
// English.
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

export const convertTimeAgo = (timestamp: number, t?: any) => {
  const timeAgo = new TimeAgo("en-US");
  const ago = new Date().getTime() - +timestamp;
  const time = timeAgo.format(Date.now() - ago);
  const time_name = String(time).split(" ")[1];

  switch (time_name) {
    case "now":
      return time.replace("just now", t("just now"));
    case "second":
      return time.replace("second ago", t("second ago"));
    case "seconds":
      return time.replace("seconds ago", t("seconds ago"));
    case "minute":
      return time.replace("minute ago", t("minute ago"));
    case "minutes":
      return time.replace("minutes ago", t("minutes ago"));
    case "hour":
      return time.replace("hour ago", t("hour ago"));
    case "hours":
      return time.replace("hours ago", t("hours ago"));
    case "day":
      return time.replace("day ago", t("day ago"));
    case "days":
      return time.replace("days ago", t("days ago"));
    case "week":
      return time.replace("week ago", t("week ago"));
    case "weeks":
      return time.replace("weeks ago", t("weeks ago"));
    case "month":
      return time.replace("month ago", t("month ago"));
    case "months":
      return time.replace("months ago", t("months ago"));
    case "year":
      return time.replace("year ago", t("year ago"));
    case "years":
      return time.replace("years ago", t("years ago"));
    default:
      break;
  }
  // return timeAgo.format(Date.now() - ago);
};

export const convertTimestamptoDate = (timestamp: number) => {
  const date = new Date(timestamp);
  return date.toLocaleString();
};

export const convertDateToAgo = (date) => {
  const timestamp = new Date(date).getTime();
  const timeAgo = new TimeAgo("en-US");
  const ago = new Date().getTime() - +timestamp;
  return timeAgo.format(Date.now() - ago);
};

export const compareDateWithCur = (date) => {
  return new Date(date).getTime() - new Date().getTime();
};

export const compareDate = (date1, date2) => {
  return new Date(date1).getTime() - new Date(date2).getTime();
};

export const compareYearWithCur = (date) => {
  return new Date().getFullYear() - new Date(date).getFullYear();
};

export const renderDate = (date, option?: any) => {
  if (option && date) {
    switch (option?.key) {
      case "hide_year":
        return `${new Date(date).getDate()}/${new Date().getMonth() + 1}`;
      default:
        break;
    }
  }
  return date ? new Date(date).toLocaleDateString("en-GB") : "";
};

export const renderDateTime = (date) => {
  return date ? new Date(date).toLocaleString("en-GB") : "";
};

export const checkInDate = (date) => {
  const d = new Date();
  d.setHours(0, 0, 0, 0);
  if (new Date(date).getTime() - d.getTime() >= 0) {
    return true;
  }
  return false;
};

export const getNameWeekdays = (current_day: number) => {
  let day_name;
  switch (current_day) {
    case 0:
      day_name = "Sunday";
      break;
    case 1:
      day_name = "Monday";
      break;
    case 2:
      day_name = "Tuesday";
      break;
    case 3:
      day_name = "Wednesday";
      break;
    case 4:
      day_name = "Thursday";
      break;
    case 5:
      day_name = "Friday";
      break;
    case 6:
      day_name = "Saturday";
      break;
    default:
      day_name = "";
  }
  return day_name;
};

export const checkDateOfBirthCurrent = (date) => {
  if (
    new Date(date).getDate() === new Date().getDate() &&
    new Date(date).getMonth() === new Date().getMonth()
  ) {
    return true;
  }
  return false;
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import GroupsService from "api/groups";
import { IRequestData } from "api/request";

export const getGroupSuggestsAsync = createAsyncThunk(
  "groups/suggest",
  async (req: IRequestData) => {
    try {
      const response: any = await GroupsService.getSuggests(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getGroupJoinedAsync = createAsyncThunk(
  "groups/joined",
  async (req: IRequestData) => {
    try {
      const response: any = await GroupsService.getJoined(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getGroupManageAsync = createAsyncThunk(
  "groups/manage",
  async (req: IRequestData) => {
    try {
      const response: any = await GroupsService.getManage(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getGroupRequestAsync = createAsyncThunk(
  "groups/request",
  async (req: IRequestData) => {
    try {
      const response: any = await GroupsService.getRequestJoined(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getGroupInvitedAsync = createAsyncThunk(
  "groups/invited",
  async (req: IRequestData) => {
    try {
      const response: any = await GroupsService.getInvitedByUser(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

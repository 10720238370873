function appLocalStorage() {
  let lcs: any = null;

  if (typeof window !== "undefined") {
    // Perform localStorage action
    lcs = window.localStorage;
  }

  return {
    get: (key: string, dfValue?: string): string | null => {
      if (!lcs) {
        return null;
      }
      const data = lcs.getItem(key);
      if (data) {
        return data;
      }
      return dfValue || null;
    },
    set: (key: string, value: string): string | null => {
      if (!lcs) {
        return null;
      }

      lcs.setItem(key, value);
      return value;
    },
  };
}

export default appLocalStorage();

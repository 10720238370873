import React from "react";
import {
  ViewAndDown,
  ActionImage,
  ActionZoom,
  ContainerViewImage,
} from "./styles";

import { AiOutlineDownload, AiOutlineCloseCircle } from "react-icons/ai";
import { BsZoomIn, BsZoomOut } from "react-icons/bs";
import {
  TransformWrapper,
  TransformComponent,
} from "@kokarn/react-zoom-pan-pinch";
import Button from "components/v-button";

const View = (props) => {
  const { download, closeModal } = props;
  return (
    <>
      <ViewAndDown>
        <TransformWrapper
          initialScale={1}
          initialPositionX={0}
          initialPositionY={0}
        >
          {({ zoomIn, zoomOut, ...rest }) => (
            <React.Fragment>
              <ContainerViewImage>
                <ActionImage>
                  {/* <p>{srcImg?.split("/")?.[srcImg?.split("/").length - 1]}</p> */}
                </ActionImage>
                <TransformComponent wrapperStyle={{
                  margin: "0 auto"
                }}>{props.children}</TransformComponent>
                <ActionZoom>
                  <Button height={"36px"} onClick={download}>
                    <AiOutlineDownload />
                  </Button>
                  <Button height={"36px"} onClick={() => zoomIn()}>
                    <BsZoomIn />
                  </Button>
                  <Button height={"36px"} onClick={() => zoomOut()}>
                    <BsZoomOut />
                  </Button>
                  <Button height={"36px"} onClick={() => closeModal(false)}>
                    <AiOutlineCloseCircle />
                  </Button>
                </ActionZoom>
              </ContainerViewImage>
            </React.Fragment>
          )}
        </TransformWrapper>
      </ViewAndDown>
    </>
  );
};

export default View;

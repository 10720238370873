/* eslint-disable indent */
import React, { useRef, useState } from "react";
import Card from "components/v-teamplate/Card";
import { Col } from "react-bootstrap";

import VAvatar from "components/v-avatar";
import VActionGroup from "components/v-objects/v-actions-group";
import useTranslation from "hooks/useTranslation";
import LoadingBar from "react-top-loading-bar";
import PageBackgrounds from "components/v-page-background";
import VNameVerify from "../v-name-verify";
import { getUrlProfile } from "utils/common";
import { useHistory } from "react-router-dom";
import { OBJECT_TYPE } from "config/enums";
import { isMobile } from "react-device-detect";

type IProps = {
  group: {
    [key: string]: any;
  };
  type?: string;
  reload?: any;
  col_lg?: number;
  col_md?: number;
  count?: number;
};

const View = ({ group, type, reload, col_lg, col_md, count }: IProps) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const refLoad = useRef(null);
  const history = useHistory()

  const setLoading = (loading) => {
    if (refLoad.current)
      if (loading) {
        refLoad.current.continuousStart();
      } else {
        refLoad.current.complete();
      }
  };

  return (
    <Col md={col_md || 4} lg={col_lg || 4} className={"v-business-card col-6"}>
      <Card className="mb-0">
        <LoadingBar
          color="#f11946"
          progress={progress}
          onLoaderFinished={() => setProgress(0)}
          ref={refLoad}
        />

        <div className="top-bg-image">
          <div className="cover-image">
            <PageBackgrounds
              page={"page_bg_profile"}
              width="100%"
              height="100%"
              position="relative"
              zIndex={"0"}
              object_type={OBJECT_TYPE.GROUP}
              data={group?.profile?.covers}
            />
          </div>
        </div>
        <Card.Body className=" text-center">
          <div className="group-icon">
            <VAvatar
              isClick={true}
              userData={group}
              size={isMobile ? "60px" :"80px"}
              hideName={true}
            />
          </div>
          <div className="group-info py-2">
            <h5
              className="fw-bold mb-1 position-relative"
              onClick={(_) => history.push(getUrlProfile(group))}
            >
              <VNameVerify
                className={
                  "mb-0 d-flex align-items-center justify-content-center"
                }
                dataUser={group}
                fontSizeIconVerify={12}
              />
            </h5>
            {/* <h4>
              <Link to={`/group/${group._id}`}>{group.full_name}</Link>
            </h4> */}
            <p className="text-ellipsis short-2">
              {" "}
              {group.profile && group.profile.slogan
                ? group.profile.slogan
                : t("Share moments - Keep good memories")}
            </p>
          </div>
          <div className="group-details d-inline-block pb-3">
            <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
              <li className="pe-3 ps-3">
                <p className="mb-0">
                  <span className="me-1 fw-bold">{count || 0}</span>
                  {t("Member")}
                </p>
              </li>
            </ul>
          </div>
          {type && (
            <VActionGroup
              className="btn  d-block w-100"
              type={type}
              isShowText={true}
              to_id={group._id}
              reload={reload}
              groupType={group.group_type}
              setLoading={setLoading}
            />
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default View;

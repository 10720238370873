/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { getUrlImage } from "utils/common";
import FilesService from "api/files";
import { useEffect, useState } from "react";
import FsLightbox from "fslightbox-react";

const View = ({ dataUser }) => {
  const { t } = useTranslation();
  const [images, setImages] = useState<any>([]);

	const [imageController, setImageController] = useState({
		toggler: false,
		slide: 1,
	});

	const imageOnSlide = (number) => {
		setImageController({
			toggler: !imageController.toggler,
			slide: number,
		});
	}

  const getFiles = async () => {
    try {
      const image = await FilesService.getFilesByType({
        userId: dataUser._id,
        query: {
          file_type: "image",
          skip: 1,
          limit: 3
        },
      });
      setImages(image || []);
    } catch (error) {}
  };
  useEffect(() => {
    getFiles();
  }, []);

  return (
    <>
      <FsLightbox
        toggler={imageController.toggler}
        sources={images?.map((img) => getUrlImage(img?.post_file_path))}
        slide={imageController.slide}
      />
      <Card>
        <Card.Header className={"p-2"}>
          <div className="w-100 d-flex justify-content-between">
            <div className="title-left-sidebar">{t("Images")}</div>
            <Link to={"?tab=images"}>{t("View all")}</Link>
          </div>
        </Card.Header>
        <Card.Body className="py-2">
          <Row>
            {images?.length > 0 ? (
              images?.slice(0, 3).map((el, index) => (
                <Col xs={4} key={index} className={"p-0"}>
                  <div className="image-left-sibebar">
                    <div className="item-image" onClick={() => imageOnSlide(index + 1)}>
                      <img
                        src={
                          getUrlImage(el.post_file_path, 150) ||
                          "/assets/images/VDB-64.png"
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </Col>
              ))
            ) : (
              <span className="w-100 text-center">
                {t("No content to display")}
              </span>
            )}
          </Row>
        </Card.Body>
      </Card>
    </>
  );
};

export default View;

/* eslint-disable indent */
import VIcon from "components/v-icon";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import { useState } from "react";

const TabSelect = ({ menus, item, handleClick }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  const handleClickItem = (m) => {
    handleClick(m);
    setShow(false);
  };

  return (
    <div className="tab-select">
      <div className="title" onClick={() => setShow(!show)}>
        <span className="me-2">{t(item.name)}</span>
        <VIcon width={14} height={14} name="dropdown.svg" />
      </div>
      {show && (
        <Card className="menu-tab-select">
          {menus.filter((el) => el.parent_id === item._id)?.length > 0 ? (
            menus
              .filter((el) => el.parent_id === item._id)
              .map((m) => (
                <div className="item" onClick={() => handleClickItem(m)}>
                  {m?.noLocale ? m.name : t(m.name)}
                </div>
              ))
          ) : (
            <div className="item pe-none">{t("Không có trang web")}</div>
          )}
        </Card>
      )}
    </div>
  );
};

export default TabSelect;

import VTable from "components/v-table";
import Card from "components/v-teamplate/Card";
import { useEffect } from "react";
import { getHistorySwapAsync } from "redux/reducers/cryptos/cryptos.actions";
import { getHistorySwap } from "redux/reducers/cryptos/cryptos.selector";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { formatNumber } from "utils/common";
import { renderAmount } from "utils/render";

const columns = [
  {
    key: "Period",
    title: "Period",
    dataIndex: "_id",
  },
  {
    key: "User",
    title: "User",
    render: (item: any) => `${item.user_id && item.user_id["email"]}`,
  },
  {
    key: "From",
    title: "From",
    render: (item) => String(item.from).toUpperCase(),
  },

  {
    key: "To",
    title: "To",
    render: (item) => String(item.to).toUpperCase(),
  },
  {
    key: "Amount Send",
    title: "Amount Send",
    render: (item) => renderAmount(-item.amountSend),
  },
  {
    key: "Amount Locked",
    title: "Amount Locked",
    render: (item) => renderAmount(item.amountLocked),
  },
  {
    key: "Amount Received",
    title: "Amount Received",
    render: (item) => renderAmount(item.amountReceived),
  },
  {
    key: "Fee",
    title: "Fee",
    render: (item) =>
      item.fee
        ? formatNumber(item.fee.amount) +
          `(${String(item.fee.stock || "").toUpperCase()})`
        : "N/A",
  },
  {
    key: "Status",
    title: "Status",
    dataIndex: "status",
    render: (item: any) => <div className={item.status}>{item.status}</div>,
  },
];

const History = () => {
  const dispatch = useAppDispatch();
  const history = useAppSelector(getHistorySwap);

  useEffect(() => {
    dispatch(getHistorySwapAsync());
  }, [dispatch]);

  return (
    <Card isOpacity={true}>
      <div className="overflow-auto">
        <VTable columns={columns} dataSource={history || []}></VTable>
      </div>
    </Card>
  );
};

export default History;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo, useRef, useState } from "react";
import QuestionsService from "api/questions";
import VQuestionList from "components/v-question-form/v-question-form-list";
import VQuestionStep from "components/v-question-form/v-question-form-step";
import useQuery from "hooks/useQuery";
import { useAppSelector, useAppDispatch } from "redux/reducers/hook";
import { getProfile, getDataUser } from "redux/reducers/users/users.selector";
import { getUserByIdAsync } from "redux/reducers/users/users.actions";
import { checkIsAuth, getUrlImage } from "utils/common";
import ProfileHeader from "pages/social/object-details/profile-header";
import Error404 from "components/v-error-404";
import { QUESTION_TYPE } from "config/enums";
import { getIsApp } from "redux/reducers/settings/settings.selector";
import Countdown from "components/v-countdown";
// import BannersService from "api/banners";

const View = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();

  const users = useAppSelector(getProfile);
  const dataUser = useAppSelector(getDataUser);

  const isAuth = checkIsAuth(dataUser, users?._id);
  const isApp = useAppSelector(getIsApp);

  const [questionForm, setQuestionForm] = useState(null);
  const [questionId, setQuestionId] = useState("");
  const [notFound, setNotFound] = useState(false);
  const [isLive, setIsLive] = useState(false);
  // const [congratuation, setCongratuation] = useState([]);
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (query.id) {
      setQuestionId(query.id);
      getQuestionForm(query.id);
    }
    // getBannerCongratuation();

    if (query.live) {
      setIsLive(true);
      window.addEventListener("message", listenerMessage);
    }
  }, [query]);

  // const getBannerCongratuation = async () => {
  //   try {
  //     const res: any = await BannersService.getsByPosition({
  //       params: {
  //         position: "banner_bottom_new_home",
  //       },
  //     });
  //     if (res) {
  //       const images = res.reduce((prev, next) => {
  //         return [...prev, ...(next.images || [])];
  //       }, []);
  //       setCongratuation(images);
  //     }
  //   } catch (error) {}
  // };

  const getQuestionForm = async (question_id: string) => {
    try {
      const res: any = await QuestionsService.getQuestions(question_id);

      setQuestionForm(res);
      getUserById(res.owner_id);
    } catch (error) {
      setNotFound(true);
    }
  };

  const listenerMessage = (event) => {
    if (event.data && !event?.data?.source && !event?.data?.type) {
      const res = JSON.parse(event.data);
      const { owner_id } = res;

      if (owner_id) {
        setQuestionForm(res);
        getUserById(res.owner_id);
      }
    }
  };

  const getUserById = async (id: any) => {
    try {
      dispatch(
        getUserByIdAsync({
          params: {
            id: id,
          },
        })
      );
    } catch (error) {}
  };

  const countdownSeconds = useMemo(() => {
    const splited = questionForm?.countdown_value?.split(":");
    if (splited) {
      return (
        Number(splited[0]) * 3600 + Number(splited[1]) * 60 + Number(splited[2])
      );
    }
    return 0;
  }, [questionForm]);

  if (!users || (!questionId && !query.live) || notFound) return <Error404 />;

  return (
    <div ref={containerRef} id="question-form" className="container">
      {questionForm?.background && (
        <img
          className="question-form-background"
          src={getUrlImage(questionForm.background?.image)}
          alt=""
        />
      )}
      {questionForm && users && !isApp && (
        <ProfileHeader
          dataUser={dataUser}
          isMyProfile={isAuth}
          subBackground={questionForm?.cover?.image}
        />
      )}

      {questionForm?.has_countdown && questionForm?.countdown_value && (
        <div>
          <Countdown date={Date.now() + countdownSeconds * 1000} />
        </div>
      )}

      {questionForm?.question_type === QUESTION_TYPE.STEP ? (
        <VQuestionStep
          isLive={isLive}
          user_id={users._id}
          question_id={questionId}
          questionForm={questionForm}
          allowSubmit={query?.allowSubmit === "no"}
        />
      ) : (
        <VQuestionList
          isLive={isLive}
          user_id={users._id}
          question_id={questionId}
          questionForm={questionForm}
          allowSubmit={query?.allowSubmit === "no"}
        />
      )}
    </div>
  );
};

export default View;

import { request, parseErrorResponse } from "./request";

const login = (body, params?: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("/login", body, {
        params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const loginGoogle = (body, params?: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("/login-google", body, {
        params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const loginFacebook = (body, params?: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("/login-facebook", body, {
        params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const register = (body, params?: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("/register", body, {
        params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const fastRegister = (body, params?: any) =>
  new Promise((resolve, reject) => {
    request()
      .post("/fast-register", body, {
        params,
      })
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postVerifyEmail = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`verifyEmail/${req.params.token}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resendVerifyEmail = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("resend-verify-email", req.params.token)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postForgotPassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/forgot-password", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resetPassword = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/reset-password", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postVerify = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/verify-email", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postVerifyLogin = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/verify-login", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const resendCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/resend-otp", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkUserExists = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/users/check-user-exists", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateProfile = (body: any) =>
  new Promise((resolve, reject) => {
    request()
      .put("/auth/me", body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const AuthService = {
  login,
  loginGoogle,
  loginFacebook,
  register,
  postVerifyEmail,
  resendVerifyEmail,
  postForgotPassword,
  resetPassword,
  postVerify,
  postVerifyLogin,
  resendCode,
  checkUserExists,
  fastRegister,
  updateProfile,
};

export default AuthService;

/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "components/v-teamplate/Card";
import VBannerPage from "components/v-teamplate/v-banner-page";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import {
  getCityCurrent,
  getForecaseCurrent,
  getLocationCurrent,
  getWeatherCurrent,
} from "redux/reducers/settings/settings.selector";
import axios from "axios";
import { setForecast } from "redux/reducers/settings/settings.reducer";
import { convertTimestamptoDate } from "utils/times";
import useTranslation from "hooks/useTranslation";

// img
// import profilebg2 from "assets/images/page-img/profile-bg2.jpg";

const Weather = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const location = useAppSelector(getLocationCurrent);
  const city = useAppSelector(getCityCurrent);
  const forecast = useAppSelector(getForecaseCurrent);
  const weather = useAppSelector(getWeatherCurrent);
  useEffect(() => {
    if (location && location.long && !forecast) {
      getForecast();
    }
  }, [location, forecast]);

  const getForecast = async () => {
    try {
      const res = await axios.get(
        `https://api.openweathermap.org/data/2.5/forecast?lat=${
          location.lat
        }&lon=${location.long}&units=metric&appid=${
          process.env.REACT_APP_WEATHER_KEY ||
          "bd5e378503939ddaee76f12ad7a97608"
        }&cnt=40&lang=vi`
      );
      if (res && res.data) {
        dispatch(setForecast(res.data));
      }
    } catch (error) {}
  };

  return (
    <>
      <VBannerPage position="banner_top_weather" />
      <div className="v-weather-wrapper">
        <Container>
          <Row>
            {/* <Col lg="3">
              <Card className="card-block card-stretch card-height mt-3">
                <Card.Body>
                  <Form>
                    <Form.Group className="form-group">
                      <Form.Label
                        className="form-label"
                        htmlFor="country-and-timezone"
                      >
                        Country and Timezone
                      </Form.Label>
                      <select className="form-select" id="country-and-timezone">
                        <option defaultValue="">Country and Timezone</option>
                        <option>United States (UTC-8)</option>
                        <option>Chicago (UTC-5)</option>
                        <option>New York (UTC-4)</option>
                        <option>Anchorage (UTC-8)</option>
                        <option>Honolulu (UTC-10)</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label
                        className="form-label"
                        htmlFor="extended-days"
                      >
                        Extended Days
                      </Form.Label>
                      <select className="form-select" id="extended-days">
                        <option defaultValue="">Extended Days</option>
                        <option>Show Next Day</option>
                        <option>Show Next 7 Day</option>
                        <option>Show Next 10 Day</option>
                        <option>Show Next 20 Day</option>
                        <option>Show Next 30 Day</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="form-group">
                      <Form.Label
                        className="form-label"
                        htmlFor="temperature-unit"
                      >
                        Temperature Unit
                      </Form.Label>
                      <select className="form-select" id="temperature-unit">
                        <option defaultValue="">Temperature Unit</option>
                        <option>F° (Farenheit)</option>
                        <option>C° (Celsius)</option>
                      </select>
                    </Form.Group>
                    <Form.Group className="form-group mb-0">
                      <button
                        type="button"
                        className="btn  btn-primary d-block w-100"
                      >
                        Check wether
                      </button>
                    </Form.Group>
                  </Form>
                </Card.Body>
              </Card>
            </Col> */}
            <Col lg="3" sm="12">
              <Card className="card-block card-stretch card-height bg-primary rounded text-white mt-3 card-common">
                <Card.Body className="p-4">
                  {weather && (
                    <div className="text-center">
                      <div className="d-flex">
                        <div className="flex-shrink-0">
                          <i className="ri-cloud-line text-white h2"></i>
                        </div>
                        <div className="flex-grow-1 ms-3 text-start">
                          <h2 className="text-white">{city || "Viet Nam"}</h2>
                          <p className="mb-0">
                            {t(weather.weather[0]["description"])}
                          </p>
                        </div>
                      </div>
                      <div className="main-temp d-flex justify-content-center">
                        <h1 className="text-white display-1">
                          {parseInt(weather.main.temp)}
                        </h1>
                        <span>°</span>
                      </div>
                      <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0 weather-list">
                        <li>
                          <p className="mb-0">{t("Clouds")}</p>
                          <i className="ri-cloud-windy-line text-white h5"></i>
                          <h6 className="text-white">{weather.clouds.all}%</h6>
                        </li>
                        <li>
                          <p className="mb-0">{t("Temp")}</p>
                          <i className="ri-showers-line font-size-18"></i>
                          <h6 className="text-white">
                            {parseInt(weather.main.temp)} <span>&#8451;</span>
                          </h6>
                        </li>
                        <li>
                          <p className="mb-0">{t("Wind Speed")}</p>
                          <i className="ri-sun-cloudy-line font-size-18"></i>
                          <h6 className="text-white">
                            {weather.wind.speed}m/s
                          </h6>
                        </li>
                      </ul>
                    </div>
                  )}
                </Card.Body>
              </Card>
            </Col>
            {location && (
              <Col lg="9" className="d-none d-lg-block">
                <Card className="card-block card-stretch card-height mt-3 map-weather">
                  <Card.Body className="p-0 overflow-hidden">
                    <iframe
                      src={`https://openweathermap.org/weathermap?basemap=map&cities=true&layer=temperature&lat=${location.lat}&lon=${location.long}&zoom=5`}
                    ></iframe>
                  </Card.Body>
                </Card>
              </Col>
            )}

            <Col sm="12">
              <Card className="mt-3">
                <Card.Body className="bg-info rounded text-white overflow-auto">
                  <ul className="iq-week-data d-flex justify-content-between list-inline m-0 p-0 ">
                    {forecast &&
                      forecast?.list.map((item) => (
                        <li className="text-center py-2 px-4 weather-item">
                          <p className="mb-0">
                            {convertTimestamptoDate(item.dt * 1000)}
                          </p>
                          <div className="weather-icon mt-2 mb-2 font-size-20">
                            <img
                              src={`https://openweathermap.org/img/w/${item.weather[0]["icon"]}.png`}
                              alt=""
                              height={50}
                            />
                          </div>
                          <p className="mb-0">{item.main.temp}&deg;C</p>
                          <p className="mb-0">
                            {t(item.weather[0]["description"])}
                          </p>
                        </li>
                      ))}
                  </ul>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Weather;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import SearchService from "api/search";
import { BUSINESS_ACTION, OBJECT_TYPE } from "config/enums";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";
import { useCallback, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import debounce from "lodash.debounce";
import VBusinessCard from "components/v-objects/v-business-card";
import VInfiniteLoad from "components/v-infinite-load";

const Organization = () => {
  const query = useQuery();
  const { t } = useTranslation();
  const [lazyParams, setLazyParams] = useState({
    limit: 12,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  const getData = async (search, lazyParams) => {
    setLoading(true);
    const res: any = await SearchService.search({
      query: {
        ...lazyParams,
        search,
        objectType: OBJECT_TYPE.ORGANIZATION,
      },
    });
    if (res && res.docs) {
      if (res.page === 1) {
        setData(res.docs);
      } else {
        setData((prev) => prev.concat(res.docs));
      }
      setTotalPages(res.totalPages);
    }
    setLoading(false);
  };

  const onSearch = useCallback(debounce(getData, 500), []);

  useEffect(() => {
    if (query.search && query.search?.length > 1) {
      setLoading(true);
      setLazyParams((prev) => ({
        ...prev,
        page: 1,
      }));
      setTotalPages(1);
      onSearch(query.search, {
        page: 1,
        limit: 12,
      });
    } else {
      setData([]);
    }
  }, [query.search]);

  const handleViewMore = () => {
    setLoading(true);
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
    onSearch(query.search, {
      ...lazyParams,
      page: lazyParams.page + 1,
    });
  };

  return (
    <Card className="card-common p-3">
      <div className="container">
        <VInfiniteLoad
          onLoadMore={handleViewMore}
          canLoadMore={lazyParams.page < totalPages}
          isLoadingMore={loading}
        >
          <div className="row">
            {data?.length > 0
              ? data.map((item, index) => {
                  return (
                    <VBusinessCard
                      key={index}
                      col_lg={4}
                      col_md={4}
                      business={item}
                      type={BUSINESS_ACTION.VIEW_DETAIL}
                      count={item?.count?.count?.business}
                    />
                  );
                })
              : !loading && (
                  <span className="text-center w-100">
                    {t("No result is found")}
                  </span>
                )}
          </div>
        </VInfiniteLoad>
      </div>
    </Card>
  );
};

export default Organization;

import useTranslation from "hooks/useTranslation";
import WebModule from "modals/web-modules";
import { RiArrowDownSLine } from "react-icons/ri";


export default function FeedHome({ isExpaned, setExpaned }: any) {
  const { t } = useTranslation();

  return (
    <>
      <div className={`feed-home ${isExpaned ? "feed-home-expaned" : ""}`}>
        <button className="slider" onClick={() => setExpaned(!isExpaned)}>
          <div
            className="feed-home-narrow"
          >
            <div className="d-flex justify-content-center align-items-center">
              <RiArrowDownSLine size={16} />
              <span className="ms-1">
                {t("Cập nhật thông tin cuộc sống, giải trí, kinh doanh...")}
              </span>
            </div>
          </div>
        </button>

        <WebModule
          openWebModuleKey="home-feeds"
          page={"events"} // force to events because no need to modal
        />
      </div>
      {isExpaned && (
        <div className="home_v2_overlay" onClick={() => setExpaned(!isExpaned)}>
          {/* overlay */}
        </div>
      )}
    </>
  );
}

/* eslint-disable */
import { DEFAULT_RADIUS_KM } from "constants/provinces";
import { request, parseErrorResponse } from "./request";

const getObjectsUsers = (coords, params) =>
  new Promise((resolve, reject) => {
    const { lat, lng } = coords;
    const query = Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join("&");

    request()
      .get(
        `/users/user-by-coor-and-radius?lat=${lat}&lng=${lng}&r=${DEFAULT_RADIUS_KM}&${query}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const pairing = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/profiles/pairing`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  getObjectsUsers,
  pairing,
};

export default APIs;

import React from "react";
import PageBackgrounds from "components/v-page-background";
import { Link } from "react-router-dom";
import { useAppSelector } from "redux/reducers/hook";
import {
  getCompanions,
  getMenusByKey,
} from "redux/reducers/settings/settings.selector";
import useTranslation from "hooks/useTranslation";
import { getUrlImage } from "utils/common";

const View = (props: any) => {
  const { options } = props;
  const { t } = useTranslation();
  const companions = useAppSelector(getCompanions);
  const menus = useAppSelector(getMenusByKey("footer_preview"));

  return (
    <div className="auth-layout">
      <PageBackgrounds
        page={"page_bg_auth"}
        resize_image={1920}
        isAuth={false}
        optionsFilter={options}
      />
      <div className="container_form_auth">{props.children}</div>
      <div className="auth-footer">
        {/* <span className="text-center px-2">
          {t("descriptions_footer_auth_layout")}
        </span> */}
        <div className="logo-footer">
          {companions.length > 0 &&
            companions?.map((cp, index) => (
              <Link className="item" key={index} to={cp.link} target="_blank">
                <img src={getUrlImage(cp.icon)} alt="" />
              </Link>
            ))}
        </div>
        <div className="d-flex justify-content-center flex-wrap mt-2">
          {menus?.map((el) => (
            <a className="mx-2" href={el.url}>
              {t(el.name)}
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default View;

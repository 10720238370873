import styled from "styled-components";

export const Wrapper = styled.div``;

export const Download = styled.div`
  margin-top: 50px;
  text-align: center;
  button {
    margin: 5px;
    padding: 8px 10px;
    background: #4284f3;
    color: white;
    font-weight: 600;
  }
`;

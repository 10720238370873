/* eslint-disable indent */
import Table from "react-bootstrap/Table";
import { clsx } from "clsx";

type IProps = {
  columns: any;
  dataSource?: any;
  onChange?: any;
  bgTable?: any;
  bgHeader?: any;
  dataEdit?: any;
  isEdit?: boolean;
  onBlurText?: () => void;
};

const TableCustom = ({
  columns,
  dataSource,
  onChange,
  dataEdit,
  isEdit,
  onBlurText,
  ...props
}: IProps) => {
  const handleClickRow = (value: any) => {
    if (onChange) {
      onChange(value);
    }
  };

  return (
    <Table className={clsx("table-custom")}>
      <thead>
        <tr style={{ minWidth: "fit-content" }}>
          {columns?.map((cl: any, index: number) => (
            <th
              data-key={`${cl.key}_${index}`}
              contentEditable={isEdit}
              onBlur={onBlurText}
              key={cl.key}
            >
              {dataEdit?.[`${cl.key}_${index}`] || cl.title}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {dataSource &&
          dataSource.length > 0 &&
          dataSource?.map((item: any, indexx: number) => (
            <tr
              key={indexx}
              {...(onChange ? { onClick: () => handleClickRow(item) } : {})}
            >
              {columns?.map((cl: any, index: number) => (
                <td key={index}>
                  {cl.render
                    ? cl.render(item, indexx)
                    : cl.index
                    ? index + 1
                    : item[cl.dataIndex] || "N/A"}
                </td>
              ))}
            </tr>
          ))}
      </tbody>
    </Table>
  );
};

export default TableCustom;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getKycAsync } from "redux/reducers/users/users.actions";
import APIs from "api";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getUserProfile, getVerify } from "redux/reducers/users/users.selector";
import { getObjectsAsync } from "redux/reducers/settings/settings.action";
import useTranslation from "hooks/useTranslation";
import VForm from "components/v-form";
import VButton from "components/v-button";
// import SettingService from "api/settings";
// import UploadService from "api/uploads";
// import { getUrlImage } from "utils/common";
// import { IObject } from "types";
// import { OBJECT_TYPE } from "config/enums";

const KYC = ({ stepAction, formAdditionalData, setStepCurrent }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const kycData = useAppSelector(getVerify);
  const user = useAppSelector(getUserProfile);

  const [KYC, setKYC] = useState<any>({
    object: user?.object_type,
  });

  const [isSavingKYC, setIsSavingKYC] = useState(false);
  const [listFiles, setListFiles] = useState<any>({});

  const [additionalData, setAdditionalData] = useState({
    // "Số CCCD/BLX/Hộ chiếu trong ảnh": " ",
  });
  const [validate, setValidate] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);

  useEffect(() => {
    dispatch(getObjectsAsync());
  }, []);

  useEffect(() => {
    if (kycData) setKYC(kycData);
  }, [kycData]);

  useEffect(() => {
    const check = () => {
      const listRequied = formAdditionalData?.fields?.filter(
        (el) => el.required
      );

      for (let index = 0; index < listRequied.length; index++) {
        const item = listRequied[index];
        if (!additionalData[item.label] && !listFiles[item.label]) {
          return false;
        }
      }
      return true;
    };
    setIsValid((_) => check());
  }, [additionalData, listFiles]);

  const setFile = async (f: any, id: string) => {
    const field = formAdditionalData?.fields?.find((el) =>
      id.includes(el.label)
    );
    // if (id === "Ảnh CCCD/BLX/Hộ chiếu mặt trước") {
    //   try {
    //     const formData = new FormData();
    //     formData.append("folderPath", `kycs/${user?._id}`);
    //     formData.append("files", f);
    //     const resFile: IObject = await UploadService.uploadFile({
    //       body: formData,
    //     });
    //     const res: any = await SettingService.getInfoDocumentBase64({
    //       body: {
    //         url: getUrlImage(resFile.filePath),
    //       },
    //     });
    //     setAdditionalData((prev) => ({
    //       ...prev,
    //       "Số CCCD/BLX/Hộ chiếu trong ảnh": res,
    //     }));
    //   } catch (error) {
    //     setAdditionalData((prev) => ({
    //       ...prev,
    //       "Số CCCD/BLX/Hộ chiếu trong ảnh": " ",
    //     }));
    //     toast.error(error.errors);
    //   }
    // }
    setListFiles((prev) => ({
      ...prev,
      [id]:
        field?.description?.enable && field?.description?.key
          ? {
              key: field?.description?.key,
              value: f,
            }
          : f,
    }));
  };

  const handleSaveKYC = async () => {
    setIsSavingKYC(true);
    try {
      let listImg;
      // if (
      //   KYC.object === OBJECT_TYPE.USER &&
      //   additionalData["Loại"] !== "Hộ chiếu"
      // ) {
      //   if (
      //     additionalData["Số CCCD/BLX/Hộ chiếu trong ảnh"] !==
      //     additionalData["Số CCCD/BLX/Hộ chiếu"]
      //   ) {
      //     setIsSavingKYC(false);
      //     return toast.error("Please enter correct data ");
      //   }
      // }
      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${user?._id}`);

        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]?.value || listFiles[el]);
        });
        listImg = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
      }

      if (listImg && listImg?.length > 0) {
        let data = {
          object: KYC?.object,
          ...additionalData,
        };
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              data = {
                ...data,
                [item]: listFiles[item]?.key
                  ? {
                      key: listFiles[item]?.key,
                      value: el?.filePath,
                    }
                  : el?.filePath,
              };
            }
          });
        });
        try {
          const kyc: any = await APIs.KYC.createKyc({
            body: {
              ...data,
              method: stepAction,
            },
          });
          if (kyc) {
            toast.success(t("Successfull!"));
            setListFiles({});
            dispatch(getKycAsync());
            setStepCurrent(null);
          }
        } catch (err) {
          toast.error(t(err?.errors));
        }
      }
    } catch (err) {
      toast.error(t(err?.errors || "Something went wrong!"));
    }
    setIsSavingKYC(false);
  };

  return (
    <div className="upload-kyc">
      <div className="mb-3">
        <div className="d-flex">
          <label>{t("Object type")}:</label>
          <h5 className="ms-2">{t(user.object_type)}</h5>
        </div>
      </div>
      {formAdditionalData && formAdditionalData.fields?.length > 0 && (
        <div className="row">
          <VForm
            // colItem={"col-6"}
            fields={formAdditionalData?.fields}
            additionalData={additionalData}
            listFiles={listFiles}
            validate={validate}
            setListFiles={setListFiles}
            setValidate={setValidate}
            setIsValid={setIsValid}
            setFile={setFile}
            setAdditionalData={setAdditionalData}
          />
        </div>
      )}
      <div className="submit-kyc">
        <VButton
          onClick={handleSaveKYC}
          loading={isSavingKYC}
          disabled={!isValid}
        >
          {t("Save")}
        </VButton>
      </div>

      {formAdditionalData?.attachments?.enable && (
        <div
          className="mb-0 "
          dangerouslySetInnerHTML={{
            __html: formAdditionalData?.attachments?.guide,
          }}
        ></div>
      )}
    </div>
  );
};

export default KYC;

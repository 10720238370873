/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import Card from "components/v-teamplate/Card";
import { Row } from "react-bootstrap";
import useTranslation from "hooks/useTranslation";
import VNameVerify from "components/v-objects/v-name-verify";
import { getUrlProfile } from "utils/common";
import VButton from "components/v-button";
import { useState } from "react";
import ProfileService from "api/profiles";
import { toast } from "react-toastify";
import VModal from "components/v-modal";
import { getUserByIdAsync } from "redux/reducers/users/users.actions";
import { useAppDispatch } from "redux/reducers/hook";
import { useHistory } from "react-router-dom";

const View = ({ dataUser }) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  const handleClick = (url) => {
    history.push(url);
  };

  const handleRemove = () => {
    setOpenModal(true);
  };

  const submit = async () => {
    try {
      setLoading(true);
      const res = await ProfileService.pairing({
        body: {
          user_id: dataUser?.profile?.pairing?.user_id?._id,
          type: "cancel",
        },
      });
      if (res) {
        toast.success(t("SUCCESS"));
        setLoading(false);
        setOpenModal(false);
        dispatch(
          getUserByIdAsync({
            params: {
              id: dataUser?._id,
            },
          })
        );
      }
    } catch (error: any) {
      toast.error(t(error.errors));
      setLoading(false);
    }
  };

  return (
    <>
      {dataUser?.profile?.pairing?.user_id &&
        dataUser?.profile?.pairing?.status === "approved" && (
          <Card>
            <Card.Header className={"p-2"}>
              <div className="w-100 d-flex justify-content-between">
                <div className="title-left-sidebar text-ellipsis short-1">
                  {dataUser?.profile?.pairing?.data?.name || t("Pairing")}
                </div>
                <div>
                  <VButton
                    className="py-0 px-3"
                    loading={loading}
                    onClick={handleRemove}
                  >
                    {t("Remove")}
                  </VButton>
                </div>
              </div>
            </Card.Header>
            <Card.Body className="py-2">
              <Row>
                <div className="d-flex align-items-center">
                  <span className="me-2">{t("Đang ghép đôi với")}</span>
                  <h5
                    onClick={() =>
                      handleClick(
                        getUrlProfile(dataUser?.profile?.pairing?.user_id)
                      )
                    }
                  >
                    <VNameVerify
                      className={
                        "mb-0 d-flex align-items-center justify-content-center cursor-pointer"
                      }
                      dataUser={dataUser?.profile?.pairing?.user_id}
                    />
                  </h5>
                </div>
              </Row>
            </Card.Body>
          </Card>
        )}
      <VModal
        title={t("Remove pairing")}
        isOpen={openModal}
        loading={loading}
        centered={true}
        showFooter={false}
        children={
          <div>
            <h5 className="text-center">
              {t("Bạn chắc chắn muốn gỡ bỏ quan hệ này")}
            </h5>
            <div className="text-center mt-3">
              <VButton loading={loading} onClick={submit}>
                {t("Remove")}
              </VButton>
            </div>
          </div>
        }
        onCancel={() => setOpenModal(false)}
      />
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import VQRCode from "components/v-qr-code";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { setConnectSocket } from "redux/reducers/auth/auth.reducer";
import Card from "components/v-teamplate/Card";
import FooterForm from "../components/footer-form";
import HeaderForm from "../components/header-form";
import useTranslation from "hooks/useTranslation";
import { useHistory } from "react-router-dom";
import { ROUTES } from "config/routes";
import { isMobile } from "react-device-detect";
import { getQrCodeLogin } from "redux/reducers/auth/auth.selector";

const View = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const qrCodeLogin = useAppSelector(getQrCodeLogin);

  const handleLogin = () => {
    history.push(ROUTES.LOGIN);
  };
  useEffect(() => {
    dispatch(setConnectSocket(true));
  }, []);

  return (
    <Card className="form-user col-md-6" isOpacity={true}>
      <HeaderForm />
      <div className="mt-4">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0 fs-2">{t("login")}</h1>
          <div className="cursor-pointer" onClick={handleLogin}>
            <img src="/assets/images/icons/login-icon.svg" alt="" />
          </div>
        </div>
        <span>{t("Please use the mobile app to scan the code")}</span>
        <div className="d-flex justify-content-center mt-3">
          <VQRCode
            isDownload={false}
            isCopy={false}
            value={qrCodeLogin}
            size={isMobile ? 120 : 220}
          />
        </div>
        <FooterForm type={"login"} />
      </div>
    </Card>
  );
};

export default View;

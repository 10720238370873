import VIcon from "components/v-icon";
import VModal from "components/v-modal";
import VTable from "components/v-table";
import useTranslation from "hooks/useTranslation";
import { useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { getHistoryCampaigns } from "redux/reducers/tasks/tasks.selector";
import { IObject } from "types";
import { renderDateTime } from "utils/times";
import Level from "../components/Level";
import Uploads from "../components/Uploads";

const View = () => {
  const { t } = useTranslation();
  const histories = useAppSelector(getHistoryCampaigns);

  const [modalLevel, setModalLevel] = useState(false);
  const [modalInfo, setModalInfo] = useState(false);

  const [selected, setSelected] = useState(null);

  const columns = [
    {
      key: "campaign",
      title: "Campaign",
      render: (item: IObject) => `${item.object && item.object["name"]}`,
    },
    {
      key: "createdAt",
      title: "createdAt",
      render: (item: IObject) => renderDateTime(item.createdAt),
    },
    {
      key: "status",
      title: "Status",
      render: (item: IObject) => (
        <div className={item.status}>{item.status}</div>
      ),
    },
    {
      key: "upload",
      title: "Upload",
      render: (item: IObject) => (
        <VIcon
          onClick={(_) => {
            setSelected(item);
            setModalInfo(true);
          }}
          name="add_image.svg"
          width={20}
          height={20}
          className="mx-1"
        />
      ),
    },
    {
      key: "level",
      title: "Level",
      render: (item: IObject) => (
        <VIcon
          onClick={(_) => {
            setSelected(item);
            setModalLevel(true);
          }}
          name="campaign/level.png"
          width={20}
          height={20}
          className="mx-1"
        />
      ),
    },
  ];

  const renderLevel = <Level participant={selected} />;
  const renderInfo = (
    <Uploads participant={selected} setModalInfo={setModalInfo} />
  );

  return (
    <div className="overflow-auto">
      <VTable columns={columns} dataSource={histories}></VTable>
      <VModal
        title={t("Upload images")}
        isOpen={modalInfo}
        centered={true}
        children={renderInfo}
        onCancel={() => setModalInfo(false)}
      />
      <VModal
        title={t("Level detail")}
        isOpen={modalLevel}
        centered={true}
        children={renderLevel}
        onCancel={() => setModalLevel(false)}
      />
    </div>
  );
};

export default View;

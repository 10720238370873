/* eslint-disable indent */
import { useState } from "react";
import { toast } from "react-toastify";

import VButton from "components/v-button";
import VInput from "components/v-input";

// import UserAPIs from "api/users";
import AuthApi from "api/auth";
import useTranslation from "hooks/useTranslation";
import Card from "components/v-teamplate/Card";
import VIcon from "components/v-icon";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";

const View = () => {
  const { t } = useTranslation();
  const dataUser = useAppSelector(getUserProfile);
  const [token, setToken] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isSendCode, setIsSendCode] = useState(false);
  const [show, setShow] = useState<any>({
    current: false,
    new: false,
    confirm: false,
  });

  const [errMessage, setErrMessage] = useState<any>({
    new: "",
    confirm: "",
    otp: "",
  });

  const [password, setPassword] = useState<any>({
    newPassword: "",
    confirm: "",
    otp: "",
  });

  const handleGetCode = async () => {
    try {
      setLoading(true);
      const res: any = await AuthApi.postForgotPassword({
        body: {
          email: dataUser?.email,
        },
      });
      if (res) {
        setToken(res.token);
        setIsSendCode(true);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsSendCode(false);
      toast.error(t(error?.errors));
    }
  };

  const hanldeChangePassword = async () => {
    setLoading(true);
    if (password.newPassword && password.newPassword !== password.confirm) {
      setErrMessage({
        confirm: t(`Confirm password doesn't match`),
      });
      setLoading(false);
    } else {
      try {
        await AuthApi.resetPassword({
          body: {
            password: password.newPassword,
            otp: password.otp,
            token: token,
          },
        });
        setLoading(false);
        setIsSendCode(false)
        toast.success(t(`Change password success`));
      } catch (error) {
        toast.error(t(error?.errors));
        setLoading(false);
      }
    }
  };

  return (
    <Card isOpacity={true}>
      <div className="mt-3 p-3">
        <h3>{t("Change Password")}</h3>
        <div className="my-3">
          <VButton
            loading={loading}
            height="36px"
            className={"btn btn-primary"}
            onClick={() => handleGetCode()}
          >
            {t("Get the code")}
          </VButton>
        </div>
        <div className="mb-2">
          {isSendCode && (
            <span>
              {t("We have sent a verification code to your email. Please check and enter the code here")}
            </span>
          )}
        </div>

        <div className="mt-2">
          <VInput
            label={t("Code")}
            type="text"
            id="otp"
            name="otp"
            onChange={(e) =>
              setPassword({
                ...password,
                otp: e.target.value,
              })
            }
          />
        </div>
        <div className="mt-3">
          <VInput
            transparent={true}
            label={t("Enter new password")}
            type={show.new ? "text" : "password"}
            placeholder="New Password"
            errMessage={errMessage.new}
            suffix={
              <div
                className="cursor-pointer"
                onClick={() =>
                  setShow({
                    ...show,
                    new: !show.current,
                  })
                }
              >
                <VIcon name={show.current ? "eye.svg" : "eye_off.svg"} />
              </div>
            }
            onChange={(e) =>
              setPassword({
                ...password,
                newPassword: e.target.value,
              })
            }
          />
        </div>
        <div className="mt-3">
          <VInput
            transparent={true}
            label={t("Enter the password")}
            type={show.confirm ? "text" : "password"}
            placeholder="Confirm Password"
            errMessage={errMessage.confirm}
            suffix={
              <div
                className="cursor-pointer"
                onClick={() =>
                  setShow({
                    ...show,
                    confirm: !show.current,
                  })
                }
              >
                <VIcon name={show.current ? "eye.svg" : "eye_off.svg"} />
              </div>
            }
            onChange={(e) =>
              setPassword({
                ...password,
                confirm: e.target.value,
              })
            }
          />
        </div>
        <div className="pt-4 text-center">
          <VButton
            loading={loading}
            height="36px"
            className={"btn btn-primary"}
            onClick={() => hanldeChangePassword()}
          >
            {t("save")}
          </VButton>
        </div>
      </div>
    </Card>
  );
};

export default View;

/* eslint-disable indent */
import { Dropdown } from "react-bootstrap";
import CustomToggle from "components/v-teamplate/dropdowns";
import UploadFile from "components/uploadFile";

const DropdownModal = (props) => {
  const { label, menu } = props;

  return (
    <Dropdown className="v-dropdown-modal">
      <Dropdown.Toggle as={CustomToggle}>
        <div className="item-nav">{label}</div>
      </Dropdown.Toggle>
      <Dropdown.Menu
        className="dropdown-menu-right"
        aria-labelledby="profile-setting"
      >
        {menu &&
          menu.map((m, i) =>
            m?.isUpload ? (
              <div className="upload-dropdown-item">
                <label htmlFor="upload-background">{m.key}</label>
                <div hidden={true}>
                  <UploadFile
                    id="upload-background"
                    // typeUpload={"image"}
                    // isCrop={m?.name === "avatar"}
                    // cropShape={m?.name === "avatar" ? "round" : ""}
                    setFile={(file: any) => m?.onClick(file)}
                  />
                </div>
              </div>
            ) : m?.children?.length > 0 ? (
              <DropdownModal
                label={
                  <div
                    className="dropdown-item"
                    key={i}
                    // onClick={(e) => m?.onClick(e)}
                    // href="#"
                  >
                    <div className="d-flex align-items-center ">
                      {m.icon && <span className="me-2">{m.icon}</span>}
                      {m.key}
                    </div>
                  </div>
                }
                menu={m?.children?.map((chil: any) => ({
                  key: chil?.name,
                  onClick: (e) => m?.onClick(chil),
                }))}
              />
            ) : (
              <Dropdown.Item
                disabled={m?.disabled}
                key={i}
                onClick={(e) => m?.onClick(e)}
                href="#"
              >
                <div className="d-flex align-items-center ">
                  {m.icon && <span className="me-2">{m.icon}</span>}
                  {m.key}
                </div>
              </Dropdown.Item>
            )
          )}
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownModal;

import React from "react";
import { Space, Spin } from "antd";

const Spinner: React.FC<{ children?: React.ReactNode; loading?: boolean }> = ({
  children,
  loading = true,
}) => (
  <Space direction="vertical" style={{ width: "100%" }}>
    <Spin spinning={loading} tip="Loading...">
      {children}
    </Spin>
  </Space>
);

export default Spinner;

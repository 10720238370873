/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

//Sweet alert
// import FriendFollow from "pages/social/friends/follow";
// import FriendSuggest from "pages/social/friends/request/FriendSuggest";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import useQuery from "hooks/useQuery";
import VTab from "components/v-tab";
import VBannerPage from "components/v-teamplate/v-banner-page";
import ComingSoon from "pages/coming-soon/comingsoon";
import Card from "components/v-teamplate/Card";
import VRightView from "components/v-right-view";
import SuggestGroups from "../groups/suggest";
import SuggestPages from "../pages/suggest";
import SuggestBusiness from "../business/suggest";
import FriendRequestAndSuggest from "../friends/suggest";

const View = () => {
  const menus = useAppSelector(getMenusByKey("suggests"));
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  const query = useQuery();

  const [tab, setTab] = useState(null);

  useEffect(() => {
    if (query.tab) {
      if (menus && menus.length > 0) {
        const menu = menus.find((m) => m.key === query.tab);
        if (menu) {
          setTab(menu);
        } else setTab(menus[0]);
      }
    } else {
      setTab(menus[0]);
    }
  }, [query.tab]);

  const renderTab = () => {
    if (tab) {
      if (tab.key === "suggest_user") return <FriendRequestAndSuggest />;
      if (tab.key === "suggest_business") return <SuggestBusiness />;
      if (tab.key === "suggest_page") return <SuggestPages />;
      if (tab.key === "suggest_group") return <SuggestGroups />;
    }
    return <ComingSoon />;
  };

  return (
    <>
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <VBannerPage position="banner_top_suggest" />
            <Card className="p-3">
              <Col sm={12}>
                <VTab
                  tab={tab}
                  setTab={setTab}
                  menus={menus}
                  activeBorder="bottom"
                  noBackground={true}
                />
                {renderTab()}
              </Col>
            </Card>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_suggest"}
                positionBanner={"banner_right_suggest"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default View;

/* eslint-disable indent */
import { Link, useLocation } from "react-router-dom";

import Card from "components/v-teamplate/Card";
import { ROUTES } from "config/routes";
import useTranslation from "hooks/useTranslation";
import VIcon from "components/v-icon";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { getUrlImage } from "utils/common";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { isUser, isBusiness, isPage } from "utils/friends";

const SidebarSettings = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const menuAll = useAppSelector(getMenusByKey("tab_menu_setting_account"));
  const dataUser = useAppSelector(getUserProfile);

  const menuRequireKyC = ["special-name", "invoice", "digital-affiliate-bank"];
  const menus =
    dataUser?.status_kyc === "verified" || dataUser?.status_kyc === "kyc"
      ? menuAll
      : menuAll.filter((menu) => !menuRequireKyC.includes(menu.key));

  return (
    <Card>
      <div className="sidebar_settings">
        <Card.Body>
          {menus?.length > 0 &&
            menus.map((el) => (
              <div
                key={el._id}
                className="sidebar-item"
                hidden={
                  !isUser(dataUser.object_type) &&
                  !isBusiness(dataUser.object_type) &&
                  !isPage(dataUser.object_type) &&
                  el.key === "verification"
                }
              >
                <Link
                  className={`${
                    location.pathname === `${ROUTES.SETTING}/${el.key}`
                      ? "active"
                      : ""
                  } nav-link `}
                  aria-current="page"
                  to={`${ROUTES.SETTING}/${el.key}`}
                >
                  {el?.img_icon ? (
                    <img
                      width={20}
                      height={20}
                      src={getUrlImage(el?.img_icon)}
                      alt=""
                    />
                  ) : (
                    <VIcon name="" />
                  )}
                  <span className="item-name">{t(el.name)}</span>
                </Link>
              </div>
            ))}
        </Card.Body>
      </div>
    </Card>
  );
};
export default SidebarSettings;

import React, { useEffect } from "react";
// Import selectors & action from setting store
import * as SettingSelector from "redux/reducers/setting-system/selectors";

// Redux Selector / Action
import { useSelector } from "react-redux";

//components
import Verticalnav from "./verticalnav";
import Scrollbar from "smooth-scrollbar";

import PageBackgrounds from "components/v-page-background";
import { isMobile } from "react-device-detect";

const Sidebar = () => {
  const sidebarType = useSelector(SettingSelector.sidebar_type); // array
  const sidebarMenuStyle = useSelector(SettingSelector.sidebar_menu_style);

  useEffect(() => {
    Scrollbar.init(document.querySelector(".data-scrollbar"));
  });

  return (
    <>
      <aside
        className={`${sidebarType.join(
          " "
        )} ${sidebarMenuStyle} sidebar ${isMobile ? "" : "sidebar-mini"} sidebar-default sidebar-base navs-rounded-all `}
        id="first-tour"
        data-toggle="main-sidebar"
        data-sidebar="responsive"
      >
        <PageBackgrounds page={"page_bg_sidebar"} width="100%" height="100%" position={'absolute'} />
        <div className="sidebar-body pt-0 data-scrollbar">
          <div className="sidebar-list">
            <Verticalnav />
          </div>
        </div>
        <div className="sidebar-footer"></div>
      </aside>
    </>
  );
};

export default Sidebar;

import VIcon from "components/v-icon";
import { formatNumber } from "./common";
import { convertTimeAgo } from "./times";
// import { ROUTES } from "config/routes";

const renderId = (_id: String) => {
  return `${_id.slice(0, 15)}...`;
};

const renderTextInDay = () => {
  const hour = new Date().getHours();
  if (0 <= hour && hour <= 12) return "Good Morning";
  if (13 <= hour && hour <= 18) return "Good Afternoon";
  return "Good Evening";
};

const renderAmount = (amount: number) => {
  if (amount >= 0)
    return <span style={{ color: "#00AC11" }}> +{formatNumber(+amount)} </span>;
  else
    return (
      <span style={{ color: "#dc3545" }}>
        {" "}
        -{formatNumber(Math.abs(+amount))}{" "}
      </span>
    );
};

export const renderOnline = (
  user_id: string,
  status: string,
  last_time_online: number,
  t: any,
  history: any
) => {
  return status === "online" ? (
    <div className="action-online">
      <div className="text-online me-1">
        <VIcon width={15} height={15} name="status/online.gif" />
      </div>
      <div
        className="text-chat cursor-pointer"
      >
        <VIcon
          className="me-1"
          width={12}
          height={12}
          name="friends/chat-black.svg"
        />{" "}
        {t("Chat")}
      </div>
    </div>
  ) : (
    <div className="action-offline">
      <VIcon width={15} height={15} name="status/offline.gif" />{" "}
      <span> {convertTimeAgo(last_time_online, t)}</span>
    </div>
  );
};

export { renderId, renderTextInDay, renderAmount };

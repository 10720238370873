/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import FilesService from "api/files";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import CountAlbum from "./count-album";
import DetailAlbum from "./detail-album";
import useQuery from "hooks/useQuery";

const ProfileImages = (props) => {
  const { dataUser, col } = props;
  const query = useQuery();

  const [countFiles, setCountFiles] = useState({
    avatar: 0,
    cover: 0,
    normal: 0,
    adv: 0,
  });

  const [skip] = useState({
    avatar: 1,
    cover: 1,
    normal: 1,
    adv: 1,
  });

  const [imagesAvatar, setImagesAvatar] = useState<any>([]);
  const [imagesCovers, setImagesCovers] = useState<any>([]);
  const [imagesNormal, setImagesNormal] = useState<any>([]);
  const [imagesAdv, setImagesAdv] = useState<any>([]);

  const getFilesByCategoryType = async (type) => {
    try {
      const res: any = await FilesService.getFilesByType({
        userId: dataUser._id,
        query: {
          file_type: "image",
          file_category_type: type,
          skip: skip[type],
        },
      });

      if (res) {
        if (type === "avatar") {
          setImagesAvatar([...imagesAvatar, ...res]);
          skip.avatar = skip.avatar + 1;
        }
        if (type === "cover") {
          setImagesCovers([...imagesCovers, ...res]);
          skip.cover = skip.cover + 1;
        }
        if (type === "normal") {
          setImagesNormal([...imagesNormal, ...res]);
          skip.normal = skip.normal + 1;
        }
        if (type === "adv") {
          setImagesAdv([...imagesAdv, ...res]);
          skip.adv = skip.adv + 1;
        }
      }
    } catch (error) {}
  };

  const getCountFiles = async () => {
    try {
      const res: any = await FilesService.getCountFilesByUser({
        userId: dataUser._id,
        query: {
          file_type: "image",
        },
      });

      if (res) {
        setCountFiles(res);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (!query.album) {
      getFilesByCategoryType("avatar");
      getFilesByCategoryType("cover");
      getFilesByCategoryType("normal");
      getFilesByCategoryType("adv");
    }
    getCountFiles();
  }, [dataUser]);

  const images = () => {
    switch (query.album) {
      case "avatar":
        return imagesAvatar;
      case "cover":
        return imagesCovers;
      case "adv":
        return imagesAdv;
      default:
        return imagesNormal;
    }
  };

  return (
    <>
      {!query.album ? (
        <Row>
          <Col
            className={`${
              col || "col-sm-3 col-lg-2"
            } col-6 mb-3 d-flex align-items-center justify-content-center flex-wrap`}
          >
            <CountAlbum
              name={"Avatar"}
              count={countFiles.avatar}
              image={imagesAvatar[0]?.post_file_path}
              key_name={"avatar"}
            />
          </Col>
          <Col
            className={`${
              col || "col-sm-3 col-lg-2"
            } col-6 mb-3 d-flex align-items-center justify-content-center flex-wrap`}
          >
            <CountAlbum
              name={"Cover image"}
              count={countFiles.cover}
              image={imagesCovers[0]?.post_file_path}
              key_name={"cover"}
            />
          </Col>
          <Col
            className={`${
              col || "col-sm-3 col-lg-2"
            } col-6 mb-3 d-flex align-items-center justify-content-center flex-wrap`}
          >
            <CountAlbum
              name={"Diary"}
              count={countFiles.normal}
              image={imagesNormal[0]?.post_file_path}
              key_name={"normal"}
            />
          </Col>
          <Col
            className={`${
              col || "col-sm-3 col-lg-2"
            } col-6 mb-3 d-flex align-items-center justify-content-center flex-wrap`}
          >
            <CountAlbum
              name={"ADV"}
              count={countFiles.adv}
              image={imagesAdv[0]?.post_file_path}
              key_name={"adv"}
            />
          </Col>
        </Row>
      ) : (
        <DetailAlbum
          type={query.album}
          data={images()}
          getFiles={getFilesByCategoryType}
        />
      )}
    </>
  );
};

export default ProfileImages;

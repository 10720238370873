/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState } from "react";
import MyButton from "components/v-button";
import VInput from "components/v-input";
import Card from "components/v-teamplate/Card";
import HeaderForm from "../components/header-form";
import FooterForm from "../components/footer-form";

import useTranslation from "hooks/useTranslation";
import AuthApi from "api/auth";

import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { ROUTES } from "config/routes";

function View() {
  const history = useHistory();
  const { t } = useTranslation();

  const [email, setEmail] = useState<string>("")
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      const res: any = await AuthApi.postForgotPassword({
        body: {
          email
        }
      });
      if (res) {
        history.push(`${ROUTES.RESET_PASSWORD}?token=${res.token}`);
        setLoading(false);
      }
    } catch (error) {
      toast.error(t(error?.errors));
      setLoading(false);
    }
    setLoading(false);
  };

  const handleChange = (e) => {
    setEmail(e.target.value)
  }

  const handleBack = () => {
    history.push(ROUTES.LOGIN);
  }

  return (
    <Card className="form-user col-md-6" isOpacity={true}>
      <HeaderForm />
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0 fs-2">{t("Forgot password")}</h1>
        </div>
        <div className="form-group mt-3">
          <VInput
            label={t("Enter your email retrieve password")}
            type="text"
            id="name"
            name="name"
            placeholder={t("Enter email")}
            onChange={handleChange}
          />
        </div>
        <div className="d-inline-block w-100">
          <div className="d-flex justify-content-between align-items-center mt-3">
            <MyButton
              onClick={handleBack}
              height={"36px"}
              className="btn btn-primary"
              loading={loading}
            >
              {t("Back")}
            </MyButton>
            <MyButton
              onClick={() => handleSubmit()}
              height={"36px"}
              className="btn btn-primary"
              loading={loading}
            >
              {t("Confirm")}
            </MyButton>
          </div>
        </div>
        <FooterForm />
      </div>
    </Card>
  );
}

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
// import { Form } from "react-bootstrap";
// import Dropdown from "components/v-dropdown";
import { useEffect, useState } from "react";
import UserSettingsService from "api/user-settings";
// import VButton from "components/v-button";
// import { toast } from "react-toastify";
import useTranslation from "hooks/useTranslation";
import { useAppSelector } from "redux/reducers/hook";
import { getRoles } from "redux/reducers/settings/settings.selector";
import { IObject } from "types";
import Manager from "./components/manager";
import Permission from "./components/permissions";

const defaultRoleUse = [
  {
    key: "everybody",
    name: "Everybody",
  },
  {
    key: "myfriend",
    name: "My Friend",
  },
  {
    key: "nobody",
    name: "Nobody",
  },
];

const View = ({ dataUser = { _id: "", object_type: "USER" } }) => {
  const { t } = useTranslation();
  const [state, setState] = useState({
    managers: {},
    permissions: {},
  });
  const roles = useAppSelector(getRoles);
  const [managerSelected, setManagerSelected] = useState(null);
  const [permissionSelected, setPermissionSelected] = useState(null);
  const [groupsRole, setGroupsRole] = useState(defaultRoleUse);

  const objectType = dataUser.object_type;

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    if (
      roles &&
      objectType &&
      roles[objectType]?.groups &&
      roles[objectType]?.groups.length
    ) {
      setGroupsRole((prev) => {
        const _new = roles[objectType].groups.filter(
          (r) => !prev.some((r2) => r2.key === r.key)
        );

        return [...prev, ..._new];
      });
    }
  }, [roles, objectType]);

  const getData = async () => {
    try {
      const res: any = await UserSettingsService.getUserSettings({
        query: {
          user_id: dataUser?._id,
        },
      });
      if (res) {
        setState({
          managers: res.managers || {},
          permissions: res.permissions || {},
        });
      }
    } catch (error) {}
  };

  const handleChangeMemberManager = async (name, value) => {
    try {
      await UserSettingsService.updateUserSettings({
        query: {
          user_id: dataUser?._id,
        },
        body: {
          managers: {
            ...state.managers,
            [name]: value,
          },
        },
      });
      getData();
    } catch (error) {}
  };

  const handleChangePermission = async (parent, name, value) => {
    try {
      await UserSettingsService.updateUserSettings({
        query: {
          user_id: dataUser?._id,
        },
        body: {
          permissions: {
            ...state.permissions,
            [parent]: {
              ...(state.permissions[parent] || {}),
              [name]: value,
            },
          },
        },
      });
      getData();
    } catch (error) {}
  };

  const handleClickManager = (object: IObject) => {
    setManagerSelected(object);
  };

  const handleClickPermission = (object: IObject) => {
    setPermissionSelected(object);
  };

  const getNameRole = (key: string) => {
    return groupsRole.find((g) => g.key === key)?.name;
  };

  return (
    <>
      {managerSelected && (
        <Manager
          dataUser={dataUser}
          objectSetting={managerSelected}
          userIds={state.managers[managerSelected.key] || []}
          handleBack={() => setManagerSelected(null)}
          handleChangeMember={handleChangeMemberManager}
        />
      )}
      {permissionSelected && (
        <Permission
          dataUser={dataUser}
          groupsRole={groupsRole}
          objectSetting={permissionSelected}
          dataSetting={state.permissions[permissionSelected.key] || {}}
          handleChangePermission={handleChangePermission}
          handleBack={() => setPermissionSelected(null)}
        />
      )}
      {roles && !managerSelected && !permissionSelected && (
        <div className="privacy-security-wrapper">
          <div className="card privacy-security-header text-center py-2 ">
            <div>Privacy and Security</div>
          </div>

          <div className="card p-3 privacy">
            <div className="title">{t("Group mananger")}</div>
            <hr className="my-2" />
            <div className="content">
              {roles[objectType] &&
                roles[objectType].groups.map((r) => (
                  <div className="px-3">
                    <div
                      className="d-flex justify-content-between "
                      onClick={(_) => handleClickManager(r)}
                    >
                      {t(r.name)}
                      <div>
                        {state.managers[r.key]
                          ? state.managers[r.key].length
                          : 0}{" "}
                        {t("User")}
                        <img
                          src="/assets/images/icons/chevron-down.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <hr className="my-1" />
                  </div>
                ))}
            </div>
          </div>

          <div className="card p-3 managers">
            <div className="title">{t("Privacy")}</div>
            <hr className="my-2" />
            <div className="content">
              {roles[objectType] &&
                roles[objectType].permissions.map((r: IObject) => (
                  <div className="px-3">
                    <div
                      className="d-flex justify-content-between "
                      onClick={(_) => handleClickPermission(r)}
                    >
                      {r.name}
                      <div>
                        {getNameRole(
                          state.permissions[r.key] &&
                            state.permissions[r.key]?.apply
                            ? state.permissions[r.key]?.apply
                            : "everybody"
                        )}
                        <img
                          src="/assets/images/icons/chevron-down.svg"
                          alt=""
                        />
                      </div>
                    </div>
                    <hr className="my-1" />
                  </div>
                ))}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default View;

import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const API_URL_PRO =
  process.env.REACT_APP_API_URL_PRO || "http://localhost:3001/api";

const getPost = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`${API_URL_PRO}/posts?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createPost = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`${API_URL_PRO}/posts`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updatePostById = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/posts/${req.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getPostByUser = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `${API_URL_PRO}/posts/by-user/${req.user_id}?${qs.stringify(req.query)}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAgesForVideoPost = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/categories/get-by-group?group=age`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const sharedOldPost = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`${API_URL_PRO}/posts/get-old-post/to-share`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRankings = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/posts/ranks/top?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCategoryPostsForVideoPost = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/category-field?status=enable`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getByPostId = (req: any) =>
  new Promise((resolve, reject) => {
    let query: any = {};
    if (req?.ref_type) {
      query.ref_type = req.ref_type;
    }
    request()
      .get(`/posts/${req.id}?${qs.stringify(query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PostService = {
  createPost,
  updatePostById,
  getPostByUser,
  getPost,
  getAgesForVideoPost,
  sharedOldPost,
  getCategoryPostsForVideoPost,
  getRankings,
  getByPostId,
};

export default PostService;

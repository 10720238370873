/* eslint-disable array-callback-return */
import { useState } from "react";
import { Card } from "react-bootstrap";
import VQuestionInput from "components/v-question-form/v-question-input";
import VQuestionTextarea from "components/v-question-form/v-question-textarea";
import VQuestionDropdown from "components/v-question-form/v-question-dropdown";
import VQuestionDateTime from "components/v-question-form/v-question-datetime";
import VQuestionRadio from "components/v-question-form/v-question-radio";
import VQuestionCheckBoxs from "components/v-question-form/v-question-checkboxs";
import VQuestionCKEditor from "components/v-question-form/v-question-ckeditpr";
import VQuestionButton from "components/v-question-form/v-question-button";
import { Controller, useForm } from "react-hook-form";
import APIs from "api";
import { toast } from "react-toastify";
import useTranslation from "hooks/useTranslation";
import { Steps } from "antd";
import VQuestionAddress from "../v-question-address";
import VQuestionImage from "../v-question-image";
import VQuestionVideo from "../v-question-video";
import VQuestionMedia from "../v-question-media";

type Question = {
  id: string;
  label_name: string;
  field_type: string;
  required: boolean;
  has_other_answer: boolean;
  placeholder?: string;
  multi_choise_options?: MultiChoiseOption[];
  radio_options?: MultiChoiseOption[];
  checkbox_options?: MultiChoiseOption[];
  dropdown_options?: DropdownOption[];
  sub_image?: string;
};

type DropdownOption = {
  id: string;
  name: string;
  correct: boolean;
};

type MultiChoiseOption = {
  id: string;
  name: string;
  is_correct_answer: boolean;
};

type VQuestionForm = {
  title: string;
  attributes?: Question[];
};

type IProps = {
  user_id: string;
  isLive: boolean;
  question_id: string;
  questionForm: VQuestionForm;
  allowSubmit?: boolean;
};

const View = ({
  user_id,
  isLive,
  question_id,
  questionForm,
  allowSubmit = true,
}: IProps) => {
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(0);

  const { t } = useTranslation();

  const errorRequiredTxt = t("Thông tin này là bắt buộc.");

  const {
    reset,
    control,
    trigger,
    handleSubmit,
    formState: { errors },
  } = useForm<any>();

  const onNext = async () => {
    const keyName = questionForm?.attributes[step]?.id;
    const keyNameOther = questionForm?.attributes[step]?.id + "_other";
    const result = await trigger([keyName, keyNameOther]);

    if (result) {
      setStep(step + 1);
    }
  };

  const onSubmit = async (inputData: any) => {
    if (isLive) {
      toast.success("Thành công.");
      return;
    }

    setLoading(true);

    const data = {};
    Object.keys(inputData)?.map((key) => {
      if (!key.includes("_other")) {
        let keyOther = `${key}_other`;
        let item = {
          answer: inputData[keyOther] ? null : inputData[key] ?? null,
          other: inputData[keyOther] ?? null,
        };

        data[key] = item;
      }
    });

    const dataForm = {
      user_id,
      question_id,
      answers: data,
    };

    try {
      await APIs.QUESTIONS.answerQuestion({
        body: dataForm,
      });

      setStep(0);
      setLoading(false);
      toast.success("Thành công.");
      reset();
    } catch (error) {
      toast.error(t("Thất bại."));
      setLoading(false);
    }
  };

  if (!questionForm) return <></>;

  return (
    <Card border="primary" className="question_form_card step">
      <Card.Header className="bg-primary text-white text-center">
        {questionForm.title}
      </Card.Header>
      <Card.Body>
        <Steps
          current={step}
          responsive={false}
          style={{ display: "block" }}
          className="px-4 my-4 mb-8 question-step"
          items={questionForm?.attributes?.map(() => ({ title: "" }))}
        />
        <form onSubmit={handleSubmit(onSubmit)}>
          {questionForm?.attributes?.map((question, index) => {
            let name = `${question.id}` as any;

            if (question.field_type === "media") {
              return (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required: question.required }}
                  render={({ field }) => {
                    return (
                      <VQuestionMedia
                        id={`question_${name}`}
                        name={name}
                        label={question.label_name}
                        required={question.required}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (question.field_type === "link") {
              return (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required: question.required }}
                  render={({ field }) => {
                    return (
                      <VQuestionInput
                        id={`question_${name}`}
                        type="url"
                        required={question.required}
                        label={question.label_name}
                        image={question?.sub_image}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (question.field_type === "link_video") {
              return (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required: question.required }}
                  render={({ field }) => {
                    return (
                      <VQuestionInput
                        id={`question_${name}`}
                        type="url"
                        required={question.required}
                        label={question.label_name}
                        image={question?.sub_image}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "input") {
              return (
                <Controller
                  name={name}
                  control={control}
                  rules={{ required: question.required }}
                  render={({ field }) => {
                    return (
                      <VQuestionInput
                        id={`question_${name}`}
                        type="text"
                        required={question.required}
                        label={question.label_name}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "textarea") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionTextarea
                        id={`question_${name}`}
                        name={name}
                        required={question.required}
                        label={question.label_name}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "dropdown") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionDropdown
                        id={`question_${name}`}
                        label={question.label_name}
                        required={question.required}
                        error={errors[name] ? errorRequiredTxt : ""}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        options={question.dropdown_options?.map((el: any) => {
                          return {
                            label: el.name,
                            value: el.id,
                          };
                        })}
                        control={control}
                        nameOther={`${name}_other`}
                        hasOtherAnswer={question.has_other_answer}
                        errorOther={
                          errors[`${name}_other`] ? errorRequiredTxt : ""
                        }
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "multi_choise") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionDropdown
                        id={`question_${name}`}
                        isMulti={true}
                        label={question.label_name}
                        required={question.required}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        options={question.multi_choise_options?.map(
                          (el: any) => {
                            return {
                              label: el.name,
                              value: el.id,
                            };
                          }
                        )}
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "radio") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionRadio
                        id={`question_${name}`}
                        label={question.label_name}
                        required={question.required}
                        options={question.radio_options?.map((el: any) => {
                          return {
                            label: el.name,
                            value: el.id,
                          };
                        })}
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "image") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionImage
                        id={`question_${name}`}
                        name={name}
                        label={question.label_name}
                        required={question.required}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "video") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionVideo
                        id={`question_${name}`}
                        name={name}
                        label={question.label_name}
                        required={question.required}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "address") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionAddress
                        id={`question_${name}`}
                        name={name}
                        label={question.label_name}
                        required={question.required}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "date") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionDateTime
                        id={`question_${name}`}
                        name={name}
                        label={question.label_name}
                        required={question.required}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "date_time") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionDateTime
                        id={`question_${name}`}
                        name={name}
                        label={question.label_name}
                        required={question.required}
                        placeholder={
                          question.placeholder ?? question.label_name
                        }
                        format={"DD/MM/YYYY hh:mm a"}
                        showTime={{ use12Hours: true }}
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "checkboxes") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionCheckBoxs
                        id={`question_${name}`}
                        name={name}
                        required={question.required}
                        label={question.label_name}
                        options={question.checkbox_options?.map((el: any) => {
                          return {
                            label: el.name,
                            value: el.id,
                          };
                        })}
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }

            if (step === index && question.field_type === "text_editor") {
              return (
                <Controller
                  name={name}
                  rules={{ required: question.required }}
                  control={control}
                  render={({ field }) => {
                    return (
                      <VQuestionCKEditor
                        id={`question_${name}`}
                        label={question.label_name}
                        required={question.required}
                        error={errors[name] ? errorRequiredTxt : ""}
                        image={question?.sub_image}
                        field={field}
                      />
                    );
                  }}
                />
              );
            }
          })}

          {allowSubmit && (
            <VQuestionButton
              step={step}
              loading={loading}
              endStep={questionForm?.attributes?.length}
              onBack={() => setStep(step - 1)}
              onNext={onNext}
            />
          )}
        </form>
      </Card.Body>
    </Card>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";

import { BUSINESS_ACTION, FRIEND_TYPE } from "config/enums";
import VFriend from "components/v-objects/v-friend";
import { useAppDispatch } from "redux/reducers/hook";
import { getDataAccessByOwnerAsync } from "redux/reducers/users/users.actions";
import GroupService from "api/groups";
import VGroupMember from "components/v-objects/v-group-member";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  col?: string
};

const MembersJoined = (props: IProps, ref) => {
  const { dataUser, isAuth, col } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [members, setMembers] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    limit: 20,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(1);
  const dispatch = useAppDispatch();

  useImperativeHandle(ref, () => ({
    reloadData: () => {
      getData(1);
    },
  }));

  useEffect(() => {
    if (dataUser && dataUser._id) getData(lazyParams.page);
  }, [lazyParams]);

  const getData = async (page) => {
    setLoading(true);
    const res: any = await GroupService.getMemberJoined({
      params: {
        id: dataUser._id,
      },
      query: {
        ...lazyParams,
        page,
      },
    });

    if (res && res.docs) {
      if (res.page === 1) {
        setMembers(res.docs);
      } else {
        setMembers((prev) => prev.concat(res.docs));
      }
      setTotalPages(res.totalPages);
    }
    setLoading(false);
  };

  const handleViewMore = () => {
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const reload = ({ to_id }) => {
    if (isAuth) {
      setMembers((friends) => friends.filter((f) => f.to_id !== to_id));
    } else dispatch(getDataAccessByOwnerAsync());
  };

  return (
    <div className="d-flex flex-wrap">
      {" "}
      {members.map((member, index) => {
        return (
          <div key={index} className={`d-flex justify-content-center p-1 col-6 ${col ? col : "col-md-4"}`}>
            <div className="rps-container">
              {isAuth ? (
                <VGroupMember
                  group_id={dataUser._id}
                  member={{
                    ...member.to,
                    profile: member.profile
                  }}
                  reload={reload}
                  type={BUSINESS_ACTION.REMOVE_MEMBER}
                />
              ) : (
                <VFriend
                  friend={{
                    ...member,
                    profile: member.profile
                  }}
                  dataAccessFriend={member.data_access}
                  reload={reload}
                  type={FRIEND_TYPE.FRIEND}
                />
              )}
            </div>
          </div>
        );
      })}
      <div className="col-12">
        {lazyParams.page < totalPages && (
          <div className="text-center p-2">
            <VButton
              type="button"
              loading={loading}
              size={"small"}
              onClick={handleViewMore}
            >
              {" "}
              {t("View more")}
            </VButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default forwardRef(MembersJoined);

import { createAsyncThunk } from "@reduxjs/toolkit";
import API from "api";
import RelationShipService from "api/relationships";
import { IRequestData } from "api/request";
import UserService from "api/users";

export const getAuthSessionsAsync = createAsyncThunk(
  "users/auth-sessions",
  async () => {
    try {
      const response = await API.AUTHSESSIONS.my();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUserAsync = createAsyncThunk("users/get", async (data) => {
  try {
    const response = await API.USER.getUserAuth();
    return response;
  } catch (error) {
    return error;
  }
});

export const loginAsync = createAsyncThunk("auth/sign-in", async (data) => {
  try {
    const response = await API.AUTH.login(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const registerAsync = createAsyncThunk("register", async (data) => {
  try {
    const response = await API.AUTH.register(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const verifyEmailAsync = createAsyncThunk(
  "verify-email",
  async (data) => {
    try {
      const response = await API.AUTH.postVerifyEmail(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const resendVerifyEmailAsync = createAsyncThunk(
  "resend-verify-email",
  async (data) => {
    try {
      const response = await API.AUTH.resendVerifyEmail(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const forgotPasswordAsync = createAsyncThunk(
  "forgot-password",
  async (data) => {
    try {
      const response = await API.AUTH.postForgotPassword(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const resetPasswordAsync = createAsyncThunk(
  "reset-password",
  async (data) => {
    try {
      const response = await API.AUTH.resetPassword(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const changePasswordAsync = createAsyncThunk(
  "users/changePassword",
  async (data) => {
    try {
      const response = await API.USER.changePassword(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const checkPasswordAsync = createAsyncThunk(
  "users/checkpassword",
  async (data) => {
    try {
      const response = await API.USER.checkPassword(data);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const verify2FaAsync = createAsyncThunk("verify", async (data) => {
  try {
    const response = await API.AUTH.postVerify(data);
    return response;
  } catch (error) {
    return error;
  }
});

export const editUserAsync = createAsyncThunk(
  "user/edit",
  async (req: IRequestData) => {
    try {
      const response = await API.USER.editUser(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getKycAsync = createAsyncThunk("kyc/get", async (req) => {
  try {
    const response = await API.KYC.getKyc(req);
    return response;
  } catch (error) {
    return error;
  }
});

export const getNonceAsync = createAsyncThunk(
  "users/nonce",
  async (req: IRequestData) => {
    try {
      const response = await API.USER.getNonce(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const createKycAsync = createAsyncThunk("kyc/create", async (req) => {
  try {
    const response = await API.KYC.createKyc(req);
    return response;
  } catch (error) {
    return error;
  }
});

export const getSharingsAsync = createAsyncThunk(
  "sharings/get",
  async (req: any) => {
    try {
      const response = await API.USER.getSharings(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getBalancesAsync = createAsyncThunk(
  "users/balances",
  async (req: IRequestData) => {
    try {
      const response = await API.USER.getBalancesByUser(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getSalesStatisticsAsync = createAsyncThunk(
  "users/sales-statistics",
  async (req: any) => {
    try {
      const response = await API.USER.getSalesStatistics(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getAccountAddressAndCareerAsync = createAsyncThunk(
  "users/address-career",
  async () => {
    try {
      const response = await API.USER.getAccountAddressAndCareer();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPermissionAsync = createAsyncThunk(
  "users/permissions",
  async () => {
    try {
      const response = await API.USER.getPermissions();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getRelationRequestingAsync = createAsyncThunk(
  "users/relationships/requesting",
  async () => {
    try {
      const response = await RelationShipService.getRequest();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getRelationAsync = createAsyncThunk(
  "users/relationships/relation",
  async () => {
    try {
      const response = await RelationShipService.getRelation();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getDataAccessByOwnerAsync = createAsyncThunk(
  "users/dataAccessByOwner",
  async () => {
    try {
      const response = await UserService.getDataAccessByOwner();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUserByIdAsync = createAsyncThunk(
  "users/userById",
  async (req: any) => {
    try {
      const response = await UserService.getProfileById(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getUserOnlineAsync = createAsyncThunk(
  "users/onlines",
  async () => {
    try {
      const response = await UserService.getUserOnline({
        query: {
          limit: 50,
          page: 1,
        },
      });
      return response;
    } catch (error) {
      return error;
    }
  }
);

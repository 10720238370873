import { CKEditor } from "ckeditor4-react";
interface IProps {
  onChange?: any;
  label?: string;
  value?: any;
  errMessage?: string;
  [key: string]: any;
}

const View = ({
  className,
  ref,
  label,
  disabled = false,
  value = null,
  required = false,
  placeholder = "",
  defaultValue = null,
  onChange = () => {},
  ...props
}: IProps) => {
  const onEditorStateChange = (data) => {
    const value = data.editor.getData();
    if (value) {
      onChange({ html: value });
    }
  };

  return (
    <div className="v-editor">
      {
        <CKEditor
          initData={defaultValue}
          config={{
            toolbar: [
              [
                "Cut",
                "Copy",
                "Paste",
                "PasteText",
                "PasteFromWord",
                "-",
                "Undo",
                "Redo",
              ],
              [
                "Find",
                "Replace",
                "-",
                "SelectAll",
                "-",
                "wsc",
                "Scayt",
                "ACheck",
              ],
              ["Link", "Unlink", "Anchor", "Image", "LinkFile"],
              ["HorizontalRule", "Smiley", "SpecialChar", "PageBreak"],
              "/",
              [
                "Bold",
                "Italic",
                "Underline",
                "Strike",
                "-",
                "Subscript",
                "Superscript",
              ],
              [
                "NumberedList",
                "BulletedList",
                "-",
                "Outdent",
                "Indent",
                "Blockquote",
              ],
              ["JustifyLeft", "JustifyCenter", "JustifyRight", "JustifyBlock"],
              "/",
              ["Styles", "Format", "Font", "FontSize"],
              ["TextColor", "BGColor"],
              ["Maximize", "ShowBlocks"],
            ],
            extraPlugins: ['easyimage', 'colorbutton'],
          }}
          onChange={onEditorStateChange}
        />
      }
    </div>
  );
};

export default View;

// import { useEffect, useState } from "react";
// import { useAppSelector } from "redux/reducers/hook";
// import { getLogo } from "redux/reducers/settings/settings.selector";
// import { getUrlImage } from "utils/common";

const View = () => {
  // const logo = useAppSelector(getLogo);
  
  return (
    <div className="v-loading-layout">
      <img
        src={"https://file.vdiarybook.com/api/files/admin/20240507/d85dc2b3-9c73-4f8a-b72d-6bab4db54114--1000.png"}
        alt=""
      />
    </div>
  );
};

export default View;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import useTranslation from "hooks/useTranslation";
import VAvatar from "components/v-avatar";
import { useHistory } from "react-router-dom";
import { getUrlImage, getUrlProfile } from "utils/common";
import { getMutualFriends, getStatusFriend, isUser } from "utils/friends";
import { useAppSelector } from "redux/reducers/hook";
import {
  getDataAccessOwner,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import { FRIEND_ACTION, OBJECT_TYPE } from "config/enums";
import VActionFriend from "../v-actions-friend";
import VNameVerify from "../v-name-verify";
import VIcon from "components/v-icon";
import DropdownModal from "components/v-dropdown-modal/View";
import VModal from "components/v-modal";
import DepartmentService from "api/department";
import VNickname from "../v-nickname";
import PageBackgrounds from "components/v-page-background";
import { isMobile } from "react-device-detect";
import VButton from "components/v-button";
import RequestPairing from "../v-pairing/request-pairing";

type IProps = {
  friend: {
    to_id: string;
    to: any;
    profile?: any;
  };
  dataAccessFriend: any;
  reload: any;
  type?: any;
};

const defaultTree = [
  {
    title: "Deparment",
    children: [],
    _id: "",
    expanded: false,
  },
];

const View = ({ friend, dataAccessFriend, reload, type }: IProps) => {
  const dataAccessOwner = useAppSelector(getDataAccessOwner);
  const profile = useAppSelector(getUserProfile);
  const { t } = useTranslation();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);
  const [treeData, setTreeData] = useState(defaultTree);
  const [infoData, setInfoData] = useState({
    color: null,
  });
  const [nodeSelected, setNodeSelected] = useState(null);
  const [limitShow, setLimitShow] = useState(5);
  const [loading, setLoading] = useState(false);
  const [dataDepartment, setDataDepartment] = useState([]);
  const [openModalNickname, setOpenModalNickname] = useState(false);
  const [openModalPairing, setOpenModalPairing] = useState(false);

  const handleClickItem = (friend) => {
    history.push(getUrlProfile(friend.to));
  };

  const handleClickOption = (type: string) => {
    if (type === "add_member") {
      getDepartment();
      setOpenModal(true);
    }
    if (type === "nick_name") {
      setOpenModalNickname(true);
    }
    if (type === "pairing") {
      setOpenModalPairing(true);
    }
  };

  const handleClick = (id: string) => {
    setDataDepartment((prev) =>
      prev.map((el) =>
        el._id === id
          ? {
              ...el,
              expanded: !Boolean(el?.expanded),
            }
          : el
      )
    );
  };

  useEffect(() => {
    const getItems = (mapList, parentId) => {
      let items = mapList.filter((e: any) => e.parent_id === parentId);
      items.forEach((el: any) => {
        const items = getItems(mapList, el._id);
        if (items && items != null && items.length > 0) {
          el.children = items;
        }
      });
      return items;
    };

    let tree = dataDepartment?.filter(
      (e: any) => !e.parent_id || e?.parent_id == null
    );

    tree.forEach((el: any) => {
      const items = getItems(dataDepartment, el._id);
      if (items && items != null && items.length > 0) {
        el.children = items;
      }
    });
    setTreeData(tree);
  }, [dataDepartment]);

  const getDepartment = async () => {
    const _res: any = await DepartmentService.getDepartmentByUserId({
      params: {
        user_id: profile._id,
      },
    });
    if (_res && _res.value) {
      if (_res.value.length > 0) {
        setDataDepartment(_res.value);
      } else setTreeData([]);
      if (_res.info) setInfoData(_res.info);
    } else {
      setTreeData([]);
    }
  };

  const handleAddUser = async () => {
    try {
      if (profile && friend.to_id && nodeSelected) {
        setLoading(true);
        await DepartmentService.addMemberToDepartment({
          body: {
            member_id: friend.to_id,
            department_id: nodeSelected._id,
            user_id: profile._id,
          },
        });
        setLoading(false);
        setOpenModal(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const renderPostDirectory = (el) => {
    return (
      <>
        {el.title && (
          <>
            <div
              className={`d-flex justify-content-between p-2 ${
                el?._id === nodeSelected?._id
                  ? "active-items-modal rounded"
                  : ""
              }`}
            >
              <div
                className={`d-flex w-100`}
                onClick={() => setNodeSelected(el)}
              >
                {el.icon ? (
                  <div className="icon-post-directory rounded-circle overflow-hidden">
                    <img src={getUrlImage(el.icon, 50)} alt="" />
                  </div>
                ) : (
                  <VIcon name={"list-folder.svg"} width={16} className="me-2" />
                )}
                {/* {el.status === DIRECTORY_STATUS.PRIVATE && (
                  <VIcon name={"lock.svg"} width={16} className="me-2" />
                )} */}
                <span
                  className="fw-bold"
                  style={{
                    color:
                      infoData?.color?.[
                        treeData?.find((item: any) => item._id === el?._id)
                          ? "parent"
                          : "child"
                      ],
                  }}
                >
                  {el.title}
                </span>
              </div>
              {el?.children?.filter((item) => item.title)?.length > 0 && (
                <div
                  className={`${
                    el.expanded ? "" : "drop-list-folder"
                  } icon-right-directory`}
                >
                  <VIcon
                    name="dropdown.svg"
                    width={16}
                    onClick={() => handleClick(el._id)}
                  />
                </div>
              )}
            </div>
            {el.expanded && (
              <ul className="m-0">
                {el?.children?.map((item) => renderPostDirectory(item))}
              </ul>
            )}
          </>
        )}
      </>
    );
  };

  const renderContentModal = () => {
    const handleRedirect = () => {
      history.push(`?setting=department&isSetting=true`);
    };
    return (
      <div>
        <div className="d-flex justify-content-end">
          <VButton
            height={"36px"}
            className="btn btn-primary mx-1"
            onClick={handleRedirect}
          >
            {t("Settings")}
          </VButton>
        </div>
        {treeData &&
          treeData?.slice(0, limitShow)?.map((el) => (
            <div key={el._id} className="cursor-pointer">
              {renderPostDirectory(el)}
            </div>
          ))}
        {treeData?.length > 5 && (
          <div className="text-center">
            {limitShow > 5 ? (
              <div
                className="cursor-pointer text-primary"
                onClick={() => setLimitShow(5)}
              >
                {t("Collapse")}
              </div>
            ) : (
              <div
                className="cursor-pointer text-primary"
                onClick={() => setLimitShow(treeData?.length)}
              >
                {t("See more")}
              </div>
            )}
          </div>
        )}
      </div>
    );
  };

  const renderAction = () => {
    if (profile._id === friend.to_id) {
      return <></>;
    }
    const status = getStatusFriend(
      dataAccessOwner,
      dataAccessFriend,
      profile,
      friend.to,
      type
    );

    switch (status) {
      case FRIEND_ACTION.UNFRIEND:
        return (
          <div className="action-member gap-1">
            <VActionFriend
              type={FRIEND_ACTION.UNFRIEND}
              to_id={friend?.to_id}
              reload={reload}
              isShowIcon={false}
              isShowText={true}
            />
            <VActionFriend
              type={FRIEND_ACTION.CHAT}
              to_id={friend?.to_id}
              reload={reload}
            />
          </div>
        );
      case FRIEND_ACTION.UNFOLLOW:
        return (
          <div className="action-member gap-1">
            <VActionFriend
              type={FRIEND_ACTION.UNFOLLOW}
              to_id={friend?.to_id}
              reload={reload}
              isShowIcon={false}
              isShowText={true}
            />
            <VActionFriend
              type={FRIEND_ACTION.CHAT}
              to_id={friend?.to_id}
              reload={reload}
            />
          </div>
        );
      case FRIEND_ACTION.CANCEL_REQUEST:
        return (
          <div className="action-member gap-1">
            <VActionFriend
              type={FRIEND_ACTION.CANCEL_REQUEST}
              to_id={friend?.to_id}
              reload={reload}
              isShowIcon={false}
              isShowText={true}
            />
            <VActionFriend
              type={FRIEND_ACTION.DELETE}
              isShowIcon={false}
              isShowText={true}
            />
          </div>
        );
      case FRIEND_ACTION.RESPONSE:
        return (
          <div className="action-member gap-1">
            <VActionFriend
              type={FRIEND_ACTION.ACCEPT_FRIEND}
              to_id={friend?.to_id}
              isShowIcon={true}
              isShowText={true}
              reload={reload}
            />
            <VActionFriend
              type={FRIEND_ACTION.REFUSE}
              to_id={friend?.to_id}
              isShowIcon={false}
              isShowText={true}
              reload={reload}
            />
          </div>
        );

      case FRIEND_ACTION.REQUEST_FRIEND:
        return (
          <div className="action-member gap-1">
            <VActionFriend
              type={FRIEND_ACTION.REQUEST_FRIEND}
              to_id={friend?.to_id}
              reload={reload}
              isShowIcon={true}
              isShowText={true}
            />
            <VActionFriend
              type={FRIEND_ACTION.DELETE}
              isShowIcon={false}
              isShowText={true}
            />
          </div>
        );
      case FRIEND_ACTION.FOLLOW:
        return (
          <div className="action-member gap-1">
            <VActionFriend
              type={FRIEND_ACTION.FOLLOW}
              to_id={friend?.to_id}
              reload={reload}
              isShowIcon={true}
              isShowText={true}
            />
            <VActionFriend
              type={FRIEND_ACTION.DELETE}
              isShowIcon={false}
              isShowText={true}
            />
          </div>
        );

      default:
    }
  };

  return (
    <>
      <div className="v-friend">
        <div className="iq-friendlist-block rounded position-relative pb-2">
          {/* <img
            className="position-absolute top-0 end-0 p-2"
            src="/assets/icons/default/option.svg"
            alt="option button"
          /> */}
          <div className="top-bg-image">
            <div className="cover-image">
              <PageBackgrounds
                page={"page_bg_profile"}
                width="100%"
                height="100%"
                position="relative"
                zIndex={"0"}
                object_type={OBJECT_TYPE.USER}
                data={friend.profile?.covers}
              />
            </div>
          </div>
          <div className="action-item-media">
            <div className="rounded ">
              <DropdownModal
                label={
                  <div className="item-action-corver">
                    <VIcon width={12} height={10} name={"option.svg"}></VIcon>
                  </div>
                }
                menu={[
                  {
                    key: t("Add to folder"),
                    disabled: !dataAccessFriend?.friends?.includes(
                      profile?._id
                    ),
                    onClick: (_) => handleClickOption("add_member"),
                  },
                  {
                    key: t("Nick name"),
                    disabled: !dataAccessFriend?.friends?.includes(
                      profile?._id
                    ),
                    onClick: (_) => handleClickOption("nick_name"),
                  },
                  {
                    key: t("Pairing"),
                    disabled: !dataAccessFriend?.friends?.includes(
                      profile?._id
                    ),
                    onClick: () => handleClickOption("pairing"),
                  },
                ]}
              />
            </div>
            {/* <VIcon width={14} height={14} name={"option.svg"} /> */}
          </div>
          <div
            className="d-flex flex-column align-items-center justify-content-between p-2"
            style={{ marginTop: "-3.375rem" }}
          >
            <div className="d-flex flex-column align-items-center text-center mb-2">
              <Link className="mb-1" onClick={(_) => handleClickItem(friend)}>
                <VAvatar
                  size={isMobile ? "60px" : "80px"}
                  userData={{
                    ...friend.to,
                    profile: friend.profile,
                  }}
                  hideName={true}
                />
              </Link>
              <div className="info-user m-0">
                <h5
                  className="fw-bold mb-1 position-relative"
                  onClick={(_) => handleClickItem(friend)}
                >
                  <VNameVerify
                    className={
                      "mb-0 d-flex align-items-center justify-content-center"
                    }
                    dataUser={friend.to}
                    fontSizeIconVerify={12}
                  />
                </h5>
                <p className="my-1">
                  {!isUser(friend.to.object_type) ? (
                    friend.to.object_type
                  ) : (
                    <>
                      {getMutualFriends(dataAccessOwner, dataAccessFriend)}{" "}
                      {t("Mutual friends")}
                    </>
                  )}
                </p>
              </div>
            </div>
            <div className="card-header-toolbar d-flex align-items-center mb-1">
              {renderAction()}
            </div>
          </div>
        </div>
      </div>
      <VModal
        title={t("Add to folder")}
        isOpen={openModal}
        loading={loading}
        centered={true}
        // disableConfirm={!isPolicy}
        confirmLabel={t("Add")}
        classNameBody={"px-2"}
        children={renderContentModal()}
        onCancel={() => setOpenModal(false)}
        onConfirm={handleAddUser}
      />
      <VNickname
        openModal={openModalNickname}
        dataUser={{
          ...friend.to,
          profile: friend.profile,
        }}
        onLoad={reload}
        setOpenModal={setOpenModalNickname}
      />
      <RequestPairing
        openModal={openModalPairing}
        dataUser={{
          ...friend.to,
          profile: friend.profile,
        }}
        onLoad={reload}
        setOpenModal={setOpenModalPairing}
      />
    </>
  );
};

export default React.memo(View);

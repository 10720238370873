import UploadFile from "components/uploadFile";
import VButton from "components/v-button";

import { v4 as uuidv4 } from "uuid";

/* eslint-disable indent */
const VUpload = (props) => {
  const {
    typeUpload,
    isMulti,
    listFile,
    setListFile,
    ratio,
    maxFile = 5,
    isCrop = false,
    aspect,
  } = props;

  const setListSlide = () => {
    setListFile(
      [
        {
          id: uuidv4(),
          src: "",
        },
      ].concat([...listFile])
    );
  };

  const removeChil = (uuid: string) => {
    const newArr = listFile.filter((el: any) => el.id !== uuid);
    setListFile(newArr);
  };

  const handleMoveChil = (index: number, key: string) => {
    const newArr = listFile;
    const el = newArr[index];

    if (key === "upward") {
      newArr[index] = newArr[index - 1];
      newArr[index - 1] = el;
    } else {
      newArr[index] = newArr[index + 1];
      newArr[index + 1] = el;
    }
    setListFile([...newArr]);
  };

  const setFile = (f: any, id: string) => {
    setListFile((prev) =>
      prev.map((el: any) =>
        el.id === id
          ? {
              ...el,
              file: f,
              isNew: true,
            }
          : el
      )
    );
  };

  return (
    <div className="upload-container">
      {isMulti && listFile.length < maxFile && (
        <div className="add_image" onClick={() => setListSlide()}>
          +
        </div>
      )}
      {listFile?.length > 0 &&
        listFile.map((sl: any, index: number) => (
          <div className="list-upload" key={sl.id}>
            {isMulti && (
              <div className="action-list">
                <div onClick={() => removeChil(sl.id)}>-</div>
              </div>
            )}
            <div className="move-item">
              {index !== 0 && (
                <VButton onClick={() => handleMoveChil(index, "upward")}>
                  <span className="d-flex align-items-center"> {"<"}</span>
                </VButton>
              )}
              {index !== listFile.length - 1 && (
                <VButton onClick={() => handleMoveChil(index, "downward")}>
                  <span className="d-flex align-items-center">{">"}</span>
                </VButton>
              )}
            </div>
            <div className="upload-file">
              <UploadFile
                id={sl.id}
                ratio={ratio}
                sectionName={typeUpload}
                typeUpload={typeUpload}
                file={sl.file}
                src={sl.src}
                isCrop={isCrop}
                aspect={aspect}
                setFile={setFile}
              />
            </div>
          </div>
        ))}
    </div>
  );
};

export default VUpload;

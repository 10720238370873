/* eslint-disable indent */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from "query-string";
import decode from "utils/decodeToken";
import useCountdown from "hooks/useCountdown";
import HeaderForm from "../components/header-form";
import FooterForm from "../components/footer-form";

import { Spinner } from "reactstrap";

import Input from "components/v-input";
import Button from "components/v-button";
import AuthAPI from "api/auth";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const location = useLocation();
  const { t } = useTranslation();
  const { time, isCountdown, loadingResend, resendCode } = useCountdown();

  const query = qs.parse(location.search);
  const token: any = decode(query.token);

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [errMessage, setErrMessage] = useState<any>({
    twoFa: "",
    otp: "",
  });
  const [state, setState] = useState({
    otp: "",
    twoFa: "",
  });

  const handleChange = (value: string, key) => {
    setState((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const confirmLogin = async (data) => {
    try {
      setErrMessage("");
      setLoading(true);
      const res: any = await AuthAPI.postVerifyLogin({
        body: { ...state, token: query.token },
      });
      if (res) {
        localStorage.setItem("auth", res.token);
        setLoading(false);
        history.push("/");
      }
    } catch (error) {
      if (error?.errors["twoFa"]) {
        setErrMessage({
          twoFa: error?.errors["twoFa"],
        });
      } else {
        setErrMessage({
          otp: error?.errors,
        });
      }
      setLoading(false);
    }
  };

  return (
    <Card className="form-user col-md-6" isOpacity={true}>
      <HeaderForm />
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0">{t("Account verify")}</h1>
        </div>
        <p className="fs-12">{t("Send mail")}</p>
        <form className="mt-4">
          <div className="form-group">
            {query.email === "true" && (
              <Input
                label={t("OTP")}
                errMessage={errMessage.otp}
                suffix={
                  <div className="send_mail_suffix">
                    {loadingResend ? (
                      <Spinner animation="border" />
                    ) : (
                      <span onClick={() => resendCode(token.email)}>
                        {isCountdown ? time : t("Resend")}
                      </span>
                    )}
                  </div>
                }
                onChange={(e) => handleChange(e.target.value, "otp")}
              />
            )}
            {query.twoFa === "true" && (
              <div className="mt-4">
                <Input
                  label={t("Two-Fa")}
                  errMessage={errMessage.twoFa}
                  onChange={(e) => handleChange(e.target.value, "twoFa")}
                />
              </div>
            )}
          </div>
          <div className="d-inline-block w-100">
            <div className="d-flex justify-content-center align-items-center mt-3">
              <Button
                type="button"
                className="btn btn-primary"
                loading={loading}
                onClick={confirmLogin}
              >
                {t("Veriy code")}
              </Button>
            </div>
          </div>
          <FooterForm />
        </form>
      </div>
    </Card>
  );
}

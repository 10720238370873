import USER from "./users";
import AUTH from "./auth";
import CRYPTO from "./cryptos";
import UPLOAD from "./uploads";
import KYC from "./kycs";
import TRANSACTION from "./transactions";
import SETTING from "./settings";
import WALLET from "./wallets";
import PURCHASE from "./purchases";
import PAYMENT from "./payments";
import PRODUCT from "./products";
import BANK from "./banks";
import YOURESTOCK from "./yourEstock";
import PROJECT from "./projects";
import TASK from "./tasks";
import ADVERTISEMENT from "./advertisements";
import NOTIFICATION from "./notifications";
import INVEST from "./invest";
import SUPPORT from "./supports";
import EVENT from "./events";
import REVIEW from "./review-page";
import PAGEBACKGROUNDS from "./page-backgrounds";
import QUOTAS from "./quotas";
import BANNERS from "./banners";
import AUTHSESSIONS from "./auth-sessions";
import LOANS from "./loans";
import SOSPOSTS from "./sos-posts";
import CATEGORIES from "./categories";
import POSTS from "./posts";
import DEPARTMENT from "./department";
import QUESTIONS from "./questions";
import FRAMES from "./frames";
import CAPTCHA from "./captcha";

const APIs = {
  SOSPOSTS,
  USER,
  CATEGORIES,
  AUTH,
  CRYPTO,
  UPLOAD,
  KYC,
  TRANSACTION,
  SETTING,
  WALLET,
  PURCHASE,
  PAYMENT,
  PRODUCT,
  BANK,
  YOURESTOCK,
  PROJECT,
  TASK,
  ADVERTISEMENT,
  NOTIFICATION,
  INVEST,
  SUPPORT,
  EVENT,
  REVIEW,
  PAGEBACKGROUNDS,
  QUOTAS,
  BANNERS,
  AUTHSESSIONS,
  LOANS,
  POSTS,
  DEPARTMENT,
  QUESTIONS,
  FRAMES,
  CAPTCHA,
};

export default APIs;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import VIcon from "components/v-icon";
import Card from "components/v-teamplate/Card";
import { Row } from "react-bootstrap";
// import VWebView from "components/v-web-view";
import { Link } from "react-router-dom";
import useTranslation from "hooks/useTranslation";

const View = ({ dataUser }) => {
  const { t } = useTranslation();

  const handleClick = (url) => {
    window.open(url);
  };

  return (
    <>
      {dataUser.location_detail && (
        <Card>
          <Card.Header className={"p-2"}>
            <div className="w-100 d-flex justify-content-between">
              <div className="title-left-sidebar">{t("Location")}</div>
            </div>
          </Card.Header>
          <Card.Body className="py-2">
            <Row>
              <div className="d-flex">
                <VIcon name="search/location.svg" className="me-2" width={16} />
                <Link
                  onClick={() =>
                    handleClick(
                      `https://maps.google.com/maps?q=${encodeURIComponent(
                        dataUser.location_detail
                      )}&t={{2}}&z=14&ie=UTF8`
                    )
                  }
                  target={"_blank"}
                >
                  {dataUser.location_detail}
                </Link>
              </div>
              {/* <VWebView
                className="iframe-webview-home"
                url={`https://maps.google.com/maps?q=${encodeURIComponent(
                  dataUser.location_detail
                )}&t={{2}}&z=14&ie=UTF8&iwloc=&output=embed`}
                widthScrollbar={"0px"}
                height={"250px"}
              /> */}
            </Row>
          </Card.Body>
        </Card>
      )}
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";

import { GROUP_ACTION } from "config/enums";
import GroupService from "api/groups";
import VGroupMember from "components/v-objects/v-group-member";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  col?: string;
};

const MembersInvite = (props: IProps, ref) => {
  const { dataUser, col } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [members, setMembers] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    limit: 18,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(1);

  useImperativeHandle(ref, () => ({
    reloadData: () => {
      getData();
    },
  }));

  useEffect(() => {
    if (dataUser && dataUser._id) getData();
  }, [lazyParams]);

  const getData = async () => {
    setLoading(true);
    const res: any = await GroupService.getInviteMembers({
      body: {
        groupId: dataUser._id,
      },
      query: {
        ...lazyParams,
      },
    });

    if (res && res.docs) {
      if (res.page === 1) {
        setMembers(res.docs);
      } else {
        setMembers((prev) => prev.concat(res.docs));
      }
      setTotalPages(res.totalPages);
    }
    setLoading(false);
  };

  const handleViewMore = () => {
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const reload = ({ to_id }) => {
    setMembers((members) => members.filter((f) => f.to_id !== to_id));
  };

  return (
    <div className="d-flex flex-wrap">
      {" "}
      {members.map((member, index) => {
        return (
          <div
            key={index}
            className={`d-flex justify-content-center p-1 col-6 ${
              col || "col-md-4"
            }`}
          >
            <div className="rps-container">
              <VGroupMember
                group_id={dataUser._id}
                member={member.to}
                reload={reload}
                type={GROUP_ACTION.REVOKE_INVITE}
              />
            </div>
          </div>
        );
      })}
      <div className="col-12">
        {lazyParams.page < totalPages && (
          <div className="text-center p-2">
            <VButton
              type="button"
              loading={loading}
              size={"small"}
              onClick={handleViewMore}
            >
              {" "}
              {t("View more")}
            </VButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default forwardRef(MembersInvite);

import { Spinner } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getIsApp } from "redux/reducers/settings/settings.selector";

interface IProps {
  url: string;
  className?: string;
  hidden?: boolean;
  title?: string;
  onLoad?: () => void;
  loading?: boolean;
  id?: string;
}

const WebModulesIframe = (props: IProps) => {
  const { url, className, title, onLoad, loading, id } = props;
  
  const isApp = useAppSelector(getIsApp)

  return (
    <>
      {loading && (
        <div className="loading-icon d-flex justify-content-center p-3 w-full">
          <Spinner />
        </div>
      )}
      {url && (
        <iframe
          id={id}
          onLoad={onLoad}
          className={`${isApp ? "iframe-is-app" : ""} ${className}`}
          src={url}
          title={title}
          allow="clipboard-read; clipboard-write; microphone; geolocation; camera; display-capture; fullscreen; autoplay;"
        ></iframe>
      )}
    </>
  );
};
export default WebModulesIframe;

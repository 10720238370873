/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import LoansService from "api/loans";
// import VPanigation from "components/v-panigation";
import VTable from "components/v-table";
import { LOANS_TYPE } from "config/enums";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { formatNumber } from "utils/common";

const Users = () => {
  const { t } = useTranslation();

  const [loans, setLoans] = useState([]);

  const columns = [
    {
      key: "Type",
      title: t("Type"),
      render: (item: any) => {
        return <span>{item.type === LOANS_TYPE.NEED_A_LOAN ? "Need a loan" : "Loan"}</span>;
      },
    },
    {
      key: "amount",
      title: t("Amount"),
      render: (item) => {
        return `${formatNumber(item?.value_loans?.amount || 0)}`;
      },
    },
    {
      key: "Status",
      title: t("Status"),
      render: (item) => {
        return (
          <span
            className={`${
              item.status === "approved"
                ? "success"
                : item.status === "pending"
                ? "pending"
                : "declined"
            }`}
          >
            {item.status}
          </span>
        );
      },
    },
  ];

  const getLoans = async () => {
    try {
      const res: any = await LoansService.getLoansByUser();
      if (res) setLoans(res.loan);
    } catch (error) {}
  };

  useEffect(() => {
    getLoans();
  }, []);

  return (
    <div className="forum-content">
      <div className="table-container-forum">
        <VTable columns={columns} dataSource={loans}></VTable>
      </div>
      {/* <VPanigation {...ranking} getData={getRankings} /> */}
    </div>
  );
};

export default Users;

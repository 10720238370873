import React, { useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "components/v-teamplate/Card";

//Sweet alert
import useTranslation from "hooks/useTranslation";
import MembersJoined from "./Followers";

type IProps = {
  isAuth: boolean;
  dataUser: any;
};

const View = (props: IProps) => {
  const { isAuth, dataUser } = props;
  const { t } = useTranslation();

  const refJoined = useRef(null);

  return (
    <>
      <Container>
        <Row>
          <Col sm="12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">{t("Followers")}</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <MembersJoined
                  ref={refJoined}
                  isAuth={isAuth}
                  dataUser={dataUser}
                  col={"col-md-2"}
                />
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default React.memo(View);

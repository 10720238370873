/* eslint-disable react-hooks/exhaustive-deps */
import SearchService from "api/search";
import VButton from "components/v-button";
import VFriend from "components/v-objects/v-friend";
import { OBJECT_TYPE } from "config/enums";
import useQuery from "hooks/useQuery";
import useTranslation from "hooks/useTranslation";
import { useCallback, useEffect, useState } from "react";
import { Card, Col } from "react-bootstrap";
import { useAppDispatch } from "redux/reducers/hook";
import { getDataAccessByOwnerAsync } from "redux/reducers/users/users.actions";
import debounce from "lodash.debounce";
import VLoading from "components/v-loading";

const CEO = () => {
  const query = useQuery();
  const { t } = useTranslation();
  const [lazyParams, setLazyParams] = useState({
    limit: 12,
    page: 1,
  });
  const [data, setData] = useState([]);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const getData = async (search, lazyParams) => {
    setLoading(true);
    const res: any = await SearchService.search({
      query: {
        ...lazyParams,
        search,
        objectType: OBJECT_TYPE.CEO,
      },
    });
    if (res && res.docs) {
      if (res.page === 1) {
        setData(res.docs);
      } else {
        setData((prev) => prev.concat(res.docs));
      }
      setTotalPages(res.totalPages);
    }
    setLoading(false);
  };

  const onSearch = useCallback(debounce(getData, 500), []);

  useEffect(() => {
    if (query.search && query.search?.length > 1) {
      setLoading(true);
      setLazyParams((prev) => ({
        ...prev,
        page: 1,
      }));
      setTotalPages(1);
      onSearch(query.search, {
        page: 1,
        limit: 12,
      });
    } else {
      setData([]);
    }
  }, [query.search]);

  const reload = ({ to_id }) => {
    dispatch(getDataAccessByOwnerAsync());
  };

  const handleViewMore = () => {
    setLoading(true);
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
    onSearch(query.search, {
      ...lazyParams,
      page: lazyParams.page + 1,
    });
  };

  return (
    <Card className="card-common py-3 px-2">
      <div className="d-flex flex-wrap">
        {
          data?.length > 0 ? data.map((item, index) => {
            return (
              <Col
                key={index}
                md={4}
                className={"col-6 p-1 d-flex justify-content-center"}
              >
                <div className="rps-container">
                  <VFriend
                    reload={reload}
                    friend={{
                      to: item,
                      to_id: item._id,
                      profile: item.profile
                    }}
                    dataAccessFriend={item.data_access}
                  />
                </div>
              </Col>
            );
          }) : !loading && <span className="text-center w-100">{t("No result is found")}</span>
        }
      </div>
      {loading && <VLoading />}
      {lazyParams.page < totalPages && (
        <div className="text-center p-2">
          <VButton type="button" size={"small"} onClick={handleViewMore}>
            {" "}
            {t("View more")}
          </VButton>
        </div>
      )}
    </Card>
  );
};

export default CEO;

import { useState } from "react";
import { toast } from "react-toastify";
import { useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import MyButton from "components/v-button";

import AuthApi from "api/auth";
import useTranslation from "hooks/useTranslation";

import Card from "components/v-teamplate/Card";
import FooterForm from "../components/footer-form";
import VInput from "components/v-input";
import HeaderForm from "../components/header-form";
// import { copyText } from "utils/common";
import { ROUTES } from "config/routes";
import VIcon from "components/v-icon";

const View = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const { token } = queryString.parse(location.search);

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showCfPassword, setShowCfPassword] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);

  const [state, setState] = useState({
    newPassword: "",
    cfPassword: "",
    otp: "",
  });

  const hanldeChangePassword = async () => {
    setLoading(true);
    if (state.newPassword && state.cfPassword !== state.newPassword) {
      toast.warning(t("Confirm password doesn't match"));
      setLoading(false);
    } else {
      try {
        await AuthApi.resetPassword({
          body: {
            password: state.newPassword,
            otp: state.otp,
            token: token,
          },
        });
        setLoading(false);
        toast.success(t(`Change password success`));
        history.push("/auth/sign-in");
      } catch (error) {
        setLoading(false);
        toast.error(t(error.errors || "Error!"));
      }
    }
  };

  const handleChangeState = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  // const handleCopy = () => {
  //   if (state.otp) {
  //     copyText(state.otp);
  //     toast.success(t("copied"));
  //   } else {
  //   }
  // };

  const handleBack = () => {
    history.push(ROUTES.FORGOT_PASSWORD);
  };

  return (
    <Card className="form-user col-md-6" isOpacity={true}>
      <HeaderForm />
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0 fs-2">{t("Forgot password")}</h1>
        </div>
        <span>{t("Enter your password")}</span>
        <form className="mt-3">
          <div className="form-group">
            <VInput
              label={t("Code")}
              type="text"
              id="otp"
              name="otp"
              // suffix={
              //   <VIcon
              //     onClick={() =>
              //       handleCopy(`${domain}${getUrlProfile(dataUser)}`)
              //     }
              //     className="cursor-pointer"
              //     name="copy.svg"
              //   />
              // }
              onChange={(e) => handleChangeState("otp", e.target.value)}
            />
          </div>
          <div className="form-group">
            <VInput
              label={t("Enter new password")}
              type={showPassword ? "text" : "password"}
              id="password"
              name="password"
              suffix={
                <div
                  className="cursor-pointer"
                  onClick={() => setShowPassword(!showPassword)}
                >
                  <VIcon name={showPassword ? "eye.svg" : "eye_off.svg"} />
                </div>
              }
              onChange={(e) => handleChangeState("newPassword", e.target.value)}
            />
          </div>
          <div className="form-group">
            <VInput
              label={t("Enter the password")}
              type={showCfPassword ? "text" : "password"}
              id="cf_assword"
              name="cf_assword"
              suffix={
                <div
                  className="cursor-pointer"
                  onClick={() => setShowCfPassword(!showPassword)}
                >
                  <VIcon name={showCfPassword ? "eye.svg" : "eye_off.svg"} />
                </div>
              }
              onChange={(e) => handleChangeState("cfPassword", e.target.value)}
            />
          </div>
          <div className="d-inline-block w-100">
            <div className="d-flex justify-content-between align-items-center mt-3">
              <MyButton
                onClick={handleBack}
                height={"36px"}
                className="btn btn-primary"
                loading={loading}
              >
                {t("back")}
              </MyButton>
              <MyButton
                onClick={hanldeChangePassword}
                height={"36px"}
                className="btn btn-primary"
                loading={loading}
              >
                {t("send")}
              </MyButton>
            </div>
          </div>
          <FooterForm />
        </form>
      </div>
    </Card>
  );
};

export default View;

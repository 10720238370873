import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const search = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/searches?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getBirthday = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/searches/birthdays`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const searchFriendInvite = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/searches/invites?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const trending = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/trendings/search?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getUserByIds = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/searches/users`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const searchFriends = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/searches/friends?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
const SearchService = {
  search,
  getBirthday,
  searchFriendInvite,
  trending,
  getUserByIds,
  searchFriends
};

export default SearchService;

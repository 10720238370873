/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Container, Card } from "react-bootstrap";
import VBannerPage from "components/v-teamplate/v-banner-page";
import PageBackgrounds from "components/v-page-background";
import VRightView from "components/v-right-view";
import WebModule from "modals/web-modules";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const PostEventV3 = (props: any) => {
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  return (
    <div className="profile-wrapper">
      <PageBackgrounds page={"banner_top_event"} />
      <Container>
        <Row className="justify-content-center">
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1 newfeed-container"}>
            <VBannerPage position="banner_top_event" />
            <Card className="p-3">
              <Col sm={12}>
                <div className="post-new-wrapper">
                  <WebModule
                    openWebModuleKey="events"
                    extraQuery={{
                      preview: true,
                    }}
                    page={"events"}
                  />
                </div>
              </Col>
            </Card>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_event"}
                positionBanner={"banner_right_event"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default PostEventV3;

import { Button } from "react-bootstrap";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function TriggerExample(props) {
  const { content, key, children, placement, height } = props;

  const renderTooltip = (props) => (
    <Tooltip id={`content-tooltip-${key}`} {...props}>
      {content}
    </Tooltip>
  );

  return (
    <OverlayTrigger placement={placement || "left"} overlay={renderTooltip}>
      <Button className="btn-popover-trigger" style={{ height: height || 0 }}>
        {children}
      </Button>
    </OverlayTrigger>
  );
}

export default TriggerExample;

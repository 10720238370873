/* eslint-disable react-hooks/exhaustive-deps */
import LocaleSevice from "api/locales";
import i18n from "i18next";
import { useAppSelector } from "redux/reducers/hook";
import { getLocales } from "redux/reducers/settings/settings.selector";

export default function useTranslation() {
  const locales = useAppSelector(getLocales);
  const t = (text: any) => {
    if (process.env.NODE_ENV === "development" && locales.length > 0 && text) {
      const check = locales.find((l) => String(l.key) === String(text));
      if (!check) {
        LocaleSevice.create({
          body: {
            status: "not_translated",
            key: text,
            name: text,
            value: {
              en: text,
            },
          },
        });
      }
    }
    return i18n.t(text);
  };

  return {
    t,
    lang: i18n.language
  };
}

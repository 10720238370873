/* eslint-disable react-hooks/exhaustive-deps */
import WebModulesIframe from "components/iframe-web-module";
import { WEB_MODULE_URL } from "config/env";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
// import VBannerPage from "components/v-teamplate/v-banner-page";
// import { Col, Container, Row } from "react-bootstrap";
// import VRightView from "components/v-right-view";
import { useParams } from "react-router-dom";
import withAuth from "HOCs/withAuth";

const InvoiceCertificate = () => {
  const [loading, setLoading] = useState<boolean>(true);

  const { id } = useParams();

  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);

  const dispatch = useDispatch();
  const { i18n } = useTranslation();

  const getIframeUrl = (key: string) => {
    const pathKey = {
      invoice_detail: `/invoice/certificate/${id}`,
    };

    if (pathKey[key] && moduleSessionId && fromSessionUid) {
      return `${WEB_MODULE_URL}${pathKey[key] || "/"}?origin=${
        window.origin
      }&vdb_token=${localStorage.getItem(
        "auth"
      )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&lang=${
        i18n.language ? `${i18n.language}` : ""
      }`;
    }
    return null;
  };

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  return (
    <WebModulesIframe
      id="reels-iframe"
      url={getIframeUrl("invoice_detail")}
      className="w-100 h-100vh"
      title="web modules modal"
      loading={loading}
      onLoad={() => setLoading(false)}
    />
  );
};

export default withAuth(InvoiceCertificate);

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";

import { useSelector } from "react-redux";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";

import { v4 as uuidv4 } from "uuid";
import WebModulesIframe from "components/iframe-web-module";
// import { WebModulesIframeStyled } from "components/iframe-web-module/styles";
import qs from "qs";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
// import VRightView from "components/v-right-view";
import VBannerPage from "components/v-teamplate/v-banner-page";
import { WEB_MODULE_URL } from "config/env";
import { useAppDispatch } from "redux/reducers/hook";
// import PostService from "api/posts";

type IProps = {
  openWebModuleKey?: string;
  postDetail?: {
    _id: string;
  };
  extraQuery?: any;
  postShare?: any;
  page?: string;
};

export default function PostDetail(props: IProps) {
  const { id } = useParams();

  const {
    openWebModuleKey: openWebModuleKeyRedux,
    postDetail: postDetailRedux,
    postCreateUserId: postCreateUserIdRedux,
    postShare: postShareIdRedux,
    page: postPageRedux,
  } = useSelector(getUISettingState);

  const openWebModuleKey = "post_detail" || openWebModuleKeyRedux;

  const postDetail =
    {
      _id: id,
    } || postDetailRedux;
  const postShare = props.postShare || postShareIdRedux;
  const page = props.page || postPageRedux;
  const { i18n } = useTranslation();

  const [loading, setLoading] = useState(false);
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);

  const dispatch = useAppDispatch();
  const getIframeUrl = (key: string) => {
    const pathKey = {
      post_create: "/posts/create",
      post_detail: `/posts/${postDetail?._id}`,
      news_feed: "/",
      news_post_ads: "/advertisement",
      post_share: "/posts/post-share",
    };

    if (pathKey[key] && moduleSessionId && fromSessionUid) {
      return `${WEB_MODULE_URL}${pathKey[key] || "/"}?origin=${
        window.origin
      }&vdb_token=${localStorage.getItem(
        "auth"
      )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&postShareId=${
        postShare._id
      }${props.extraQuery ? "&" + qs.stringify(props.extraQuery || {}) : ""}${
        postCreateUserIdRedux ? `&userId=${postCreateUserIdRedux}` : ""
      }}&preview=true&lang=${
        i18n.language ? `${i18n.language}` : ""
      }&page=${page}&view_type=page`;
    }
    return null;
  };

  const onLoadIframeOk = () => {
    setLoading(false);
  };

  useEffect(() => {
    setLoading(true);
  }, [openWebModuleKey]);

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  return (
    <Container>
      <Row>
        <Col md={12}>
          <VBannerPage position="banner_top_post_detail" />
          <div className="iframe-webview-post-detail">
            <WebModulesIframe
              url={getIframeUrl(openWebModuleKey)}
              className="iframe h-full"
              title="web modules modal"
              onLoad={() => onLoadIframeOk()}
              loading={loading}
            />
          </div>
        </Col>
        {/* <Col lg={4} className={"d-none d-lg-block px-1"}>
          <VRightView
            menuKey={"tab_right_group"}
            positionBanner={"banner_right_post_detail"}
          />
        </Col> */}
      </Row>
    </Container>
  );
}

import { createAsyncThunk } from "@reduxjs/toolkit";
import PostDirectory from "api/post-directory";
import { IRequestData } from "api/request";

export const getPostDirectoryByUserIdAsync = createAsyncThunk(
  "post-directory/list",
  async (req: IRequestData) => {
    try {
      const response: any = await PostDirectory.getPostDirectoryByUserId(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

/* eslint-disable no-unused-vars */
export enum RequestParams {
  USERID = "userId",
}

export enum MAIL_TEMPLATE {
  REGISTER = "REGISTER",
  FORGOT_PASSWORD = "FORGOT_PASSWORD",
  SEND_CODE = "SEND_CODE",
  VERIFY_LOGIN = "VERIFY_LOGIN",
}

export enum ROLE {
  ADMIN = "ADMIN",
  USER = "USER",
}

export enum LOCATION_TYPES {
  COUNTRY = "country",
  PROVINCE = "province",
  DISTRICT = "district",
  WARD = "ward",
}

export enum USER_STATUS {
  PENDING = "pending",
  CONFIRMED = "confirmed",
  BLOCKED = "blocked",
}

export enum USER_GENDER {
  MALE = "MALE",
  FEMALE = "FEMALE",
  PRIVATE = "PRIVATE"
}

export enum OBJECT_TYPE {
  USER = "USER",
  UNDER_18 = "UNDER_18",
  CEO = "CEO",
  ORGANIZATION = "ORGANIZATION",
  PAGE = "PAGE",
  GROUP = "GROUP",
  BUSINESS = "BUSINESS",
  COMMUNITY = "COMMUNITY",
  CHANNEL="CHANNEL",
  CHAT="CHAT"
}

export enum ACCOUNT_TYPE {
  PROVINCE = "province",
  COUNTRY = "country",
  CAREER = "career",
  NATION = "nation",
  RELIGION = "religion",
  NORMAL = "normal",
}

export enum CAREER_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum CATEGORY_FIELD_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum POSITION_STATUS {
  ACTIVE = "active",
  DISABLE = "disable",
}

export enum MENU_STATUS {
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum MENU_TYPE {
  MENU = "menu",
  SUBMENU = "submenu",
  TAB = "tab",
}

export enum MENU_TYPE_VIEW {
  LINK = "link",
  INTERNAL = "internal",
  WEBVIEW = "webview",
  SELECT = "select",
}

export enum CORVER_TYPE {
  IMAGE = "image",
  VIDEO = "video",
  LINK = "link",
  SLIDE = "slide",
}

export enum BACKGROUND_HOME_V2_TYPE {
  IMAGE = "image",
  VIDEO = "video",
  LINK = "link",
  SLIDE = "slide",
}

export enum BACKGROUND_PROFILE_TYPE {
  IMAGE = "image",
  VIDEO = "video",
  SLIDE = "slide",
}

export enum POSITION_CAREER {
  CEO = "ceo",
  MANAGER = "manager",
  LEADER = "leader",
}

export enum CATEGORY_POST {
  BACKGROUND = "background",
  FRAME = "frame",
  STICKER = "sticker",
}

export enum ACTION_POST_TYPE {
  FREE = "free",
  FEE = "fee",
}

export enum RELATIONSHIP_STATUS {
  PENDING = "pending",
  ACCEPT = "accept",
  DECLINED = "declined",
}

export enum RELATIONSHIP_TYPE {
  SPOUSES = "spouses",
  SIBLINGS = "siblings",
  PARENTS = "parents",
  CHILDREN = "children",
}

export enum FRIEND_TYPE {
  FRIEND = "friend",
  REQUEST_FRIEND = "request_friend",
  FOLLOW = "follow",
  LIKE = "like",
  REQUEST_BUSINESS = "request_business",
  REQUEST_GROUP = "request_group",
  GROUP = "group",
  BUSINESS = "business",
  INVITE_BUSINESS = "invite_business",
  INVITE_GROUP = "invite_group",
  MUTUAL_FRIENDS= "mutual_friends"
}

export enum NOTIFICATION_TYPE {
  USER = "user",
  BUSINESS = "business",
  GROUP = "group",
  PAGE = "page",
  COMMON = "common",
}

export enum NOTIFICATION_STATUS {
  READ = "read",
  UNREAD = "unread",
}

export enum BUSINESS_ACTION {
  REQUEST_JOIN = "request_join",
  CANCEL_REQUEST = "cancel_request",
  LEAVE_GROUP = "leave_group",
  REMOVE_MEMBER = "remove_member",
  ACCEPT_MEMBER = "accept_member",
  CANCEL_ACCEPT_MEMBER = "cancel_accept_member",
  VIEW_DETAIL = "view_detail",
  DELETE = "delete",
  CHAT = "chat",
  INVITE_MEMBER = "invite_member",
  REVOKE_INVITE = "revoke_invite",
  ACCEPT_INVITE = "accept_invite",
  CANCEL_INVITE = "cancel_invite",
  RESPONSE = "response",
}

export enum GROUP_ACTION {
  REQUEST_JOIN = "request_join",
  CANCEL_REQUEST = "cancel_request",
  LEAVE_GROUP = "leave_group",
  REMOVE_MEMBER = "remove_member",
  ACCEPT_MEMBER = "accept_member",
  CANCEL_ACCEPT_MEMBER = "cancel_accept_member",
  VIEW_DETAIL = "view_detail",
  DELETE = "delete",
  CHAT = "chat",
  INVITE_MEMBER = "invite_member",
  REVOKE_INVITE = "revoke_invite",
  ACCEPT_INVITE = "accept_invite",
  CANCEL_INVITE = "cancel_invite",
  RESPONSE = "response",
}

export enum FRIEND_ACTION {
  RESPONSE = "response",
  ACCEPT_FRIEND = "accept_friend",
  CANCEL_REQUEST = "cancel_request",
  REFUSE = "refuse",
  UNFRIEND = "unfriend",
  REQUEST_FRIEND = "request_friend",
  FOLLOW = "follow",
  UNFOLLOW = "unfollow",
  DELETE = "delete",
  CHAT = "chat",
  CONVERSATION = "conversation",
  VIEW_DETAIL_PAGE = "view_detail_page",
}

export enum PRODUCT_STATUS {
  PENDING = "pending",
  ENABLE = "enable",
  DISABLE = "disable",
}

export enum PRODUCT_TYPE {
  CODE_INTERNAL = "code_internal",
  CODE_EXTERNAL = "code_external",
  STICKER = "sticker",
  FRAME = "frame",
  ACTION_POST = "action_post",
  POST_BACKGROUD = "post_background",
  NORMAL="normal"
}

export enum GROUP_TYPE {
  PUBLIC = "public",
  PRIVATE = "private",
  // FAMILY = "family",
  VIP = "vip",
}

export enum PAGE_TYPE {
  PUBLIC = "public",
  PRIVATE = "private",
  // FAMILY = "family",
  VIP = "vip",
}

export enum CHANNEL_TYPE {
  PUBLIC = "public",
  PRIVATE = "private",
  VIP = "vip",
}

export enum PARTICIPANT_TYPE {
  TASK = "task",
  CAMPAIGN = "campaign",
}

export enum LEVEL_CALCULATE {
  f1_amount = "f1_amount",
  trust_amount = "trust_amount",
  post_amount = "post_amount",
  like_amount = "like_amount",
  feel_amount = "feel_amount",
  comment_amount = "comment_amount",
  like_receive_amount = "like_receive_amount",
  feel_receive_amount = "feel_receive_amount",
  share_amount = "share_amount",
  image_amount = "image_amount",
}

export enum LEVEL_CONDITION {
  f1_enable = "f1_enable",
  trust_enable = "trust_enable",
  post_enable = "post_enable",
  like_enable = "like_enable",
  feel_enable = "feel_enable",
  comment_enable = "comment_enable",
  like_receive_enable = "like_receive_enable",
  feel_receive_enable = "feel_receive_enable",
  share_enable = "share_enable",
  image_enable = "image_enable",
}

export enum PARTICIPANT_STATUS {
  COMPLETED = "completed",
  PENDING = "pending",
  ERROR = "ERROR",
}

export enum LEVEL_STATUS {
  APPROVED = "approved",
  PENDING = "pending",
  CANCEL = "cancel",
}

export enum DIRECTORY_STATUS {
  PUBLIC = "public",
  PRIVATE = "private",
  FRIEND = "friend",
  VIP = "vip",
}

export enum LOANS_TYPE {
  NEED_A_LOAN = "need_a_loan",
  LOANS = "loans",
}

export enum TYPE_KYC {
  ACCOUNT = "account",
  CAREER = "career"
}

export enum POST_PLUGIN {
  EVENT = "event",
  SURVEY = "survey",
  LOCATIONS = "checkin",
  FILE = "file",
  IMAGE = "image",
  VIDEO = "video"
}

export enum POST_TYPE {
  FILE = "file",
  AUDIO = "audio",
  PRODUCT = "product"
}

export enum PERMISSION_POTING_DIRECTORY {
  ALL_MEMBER_OR_FRIENDS = "all_member_or_priends",
  ALL_MEMBER_OR_FRIENDS_EXCEPT = "all_member_or_priends_except",
  ONLY_MEMBER_OR_FRIENDS = "only_member_or_priends",
  ONLY_ME = "only_me"
}

export enum QUESTION_TYPE {
  LIST = "list",
  STEP = "step",
}

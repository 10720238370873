/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import queryString from "query-string";

import Input from "components/v-input";
import Button from "components/v-button";
import { Controller, useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { formatNumber } from "utils/common";
import Verify from "../verify";
import APIs from "api";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import { IObject } from "types";
import useQuery from "hooks/useQuery";

const EmailWithdraw = ({
  userBalance,
  control,
  errors,
  setMaxAmount,
  tokenName,
  errorAmount,
  amount,
  handleChange,
}) => {
  const { t } = useTranslation();
  const stockName = String(tokenName).toUpperCase();

  return (
    <div className="detais-rigth-view">
      <div className="mt-3">
        <Input
          transparent={true}
          label={`${t("Stock")} ${stockName} ${t("Amount")}`}
          placeholder="0.00"
          isCurrency={true}
          type="text"
          value={amount}
          hasError={amount > +userBalance[tokenName] || errorAmount}
          suffix={
            <div className="cursor-pointer" onClick={setMaxAmount}>
              {t("MAX")}
            </div>
          }
          onChange={(e) => handleChange(e.target.value)}
        />
      </div>

      <p className="mt-3">
        {t("Maximum")} {stockName} {t("Available")}:{" "}
        <span className="declined">{userBalance[tokenName]}</span> {stockName}
      </p>
      <p>
        {t("You can")} <span className="success">{t("buy")}</span> {t("or")}{" "}
        <span className="orange">{t("deposit")}</span> {stockName}
      </p>
      <div className="mt-3">
        <Controller
          name="note"
          control={control}
          render={({ field }) => {
            return (
              <Input
                type={"textarea"}
                height="100px"
                transparent={true}
                label={t("Message")}
                placeholder={`Welcome to ${stockName} Stock`}
                hasError={errors.note}
                {...field}
              />
            );
          }}
        />
      </div>
      <div className="text-description-warning mt-3">
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          {t("Please update the correct VZONEXID to the correct profile at")}
          <a href="https://qrmart.vn/" target={"_blank"} rel="noreferrer">
            {" "}
            https://qrmart.vn/
          </a>
        </p>
      </div>
      <div className="text-description-warning">
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>{t("Estimated time to complete: less than a minute")}</p>
      </div>
    </div>
  );
};

type IProps = {
  url: string;
  userId?: string;
  balances: IObject;
  reloadBalance: () => {};
};

const View = (props: IProps) => {
  const { url, userId, balances, reloadBalance } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();
  const { address, amountStock } = query;

  const [loading, setLoading] = useState(false);
  const [stock, setStock] = useState(null);
  const cryptos = useAppSelector(getCryptos);
  const [showVerify, setShowVerify] = useState(false);
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });

  const userBalance = balances;
  const tokenName = query.stock;

  const [amount, setAmount] = useState<number>(null);
  const [errorAmount, setErrorAmount] = useState<boolean>(false);

  const {
    control,
    handleSubmit,
    setValue,
    // watch,
    formState: { errors },
  } = useForm();

  const handleChange = (e) => {
    setAmount(e);
  };

  const handleSubmitWithdraw = async (data) => {
    const body = {
      note: data.note,
      to: data.to,
      stock: tokenName,
      amount: Number(amount),
      network: "vzonex",
      verify,
    };
    try {
      if (amount <= 0 || !amount) {
        setErrorAmount(true);
      } else {
        if (!showVerify) {
          setShowVerify(true);
          return;
        }
        setLoading(true);
        await APIs.TRANSACTION.withdrawQRMart({
          body: {
            ...body,
            user_id: userId,
          },
        });
        setLoading(false);
        setShowVerify(false);
        reloadBalance();
        const _query = {
          ...query,
          method: "withdraw_stock",
        };
        history.push(`${url}/?${queryString.stringify(_query)}`);
        toast.success(t("Withdraw successful!"));
        setVerify({
          email_code: "",
          google_code: "",
        });
      }
    } catch (err) {
      setLoading(false);
      toast.error(t(err.errors || "Withdraw failed!"));
    }
  };

  const setMaxAmount = () => {
    setAmount(userBalance[tokenName]);
  };

  const feeTransfer =
    stock && stock.fee_transfer
      ? stock.fee_transfer
      : { stock: tokenName, percent: 0 };
  // const amount = watch("amount");
  const amountFee = formatNumber(((+amount || 0) * feeTransfer.percent) / 100);

  const formProps = {
    userBalance,
    control,
    errors,
    tokenName,
    fee: {
      amount: amountFee,
      stock: feeTransfer.stock,
    },
    setMaxAmount,
  };

  useEffect(() => {
    if (cryptos) setStock(cryptos.find((c) => c.symbol === tokenName));
  }, [cryptos, tokenName]);

  useEffect(() => {
    if (Number(amountStock) > 0) {
      setAmount(Number(amountStock));
    }
    if (address) {
      setValue("to", address);
    }
  }, []);

  return (
    <Card isOpacity={true}>
      <div className="detais-rigth-view">
        <div className="title-action-wallets">
          <p>
            {t("Transfer Stock")} ({String(tokenName).toUpperCase()})
          </p>
        </div>

        <form onSubmit={handleSubmit(handleSubmitWithdraw)}>
          {showVerify ? (
            <Verify verify={verify} setVerify={setVerify} />
          ) : (
            <EmailWithdraw
              {...formProps}
              amount={amount}
              errorAmount={errorAmount}
              handleChange={handleChange}
            />
          )}

          <div className="submit-step mt-3">
            <Button type="submit" height="40px" loading={loading}>
              {t("Submit")}
            </Button>
          </div>
        </form>
      </div>
    </Card>
  );
};

export default View;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { IRequestData } from "api/request";
import TaskService from "api/tasks";

export const getTasksAsync = createAsyncThunk(
  "task",
  async (req: IRequestData) => {
    try {
      const response: any = await TaskService.getTasks();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getCampaignsAsync = createAsyncThunk("campaigns", async () => {
  try {
    const response: any = await TaskService.getCampaigns();
    return response;
  } catch (error) {
    return error;
  }
});

export const getTaskByIdAsync = createAsyncThunk(
  "task/id",
  async (req: IRequestData) => {
    try {
      const response: any = await TaskService.getTaskById(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";

//Sweet alert
import useTranslation from "hooks/useTranslation";
import VSelectUser from "components/v-select-user";
import debounce from "lodash.debounce";
import SearchService from "api/search";
import VSearch from "components/v-search";
import VLoading from "components/v-loading";
import VList from "components/v-list-member";
import ModalInvite from "pages/social/object-details/modal-invite";
import VIcon from "components/v-icon";
import { IObject } from "types";
import VButton from "components/v-button";
import { OBJECT_TYPE } from "config/enums";

type IProps = {
  dataUser: any;
  objectSetting: IObject;
  userIds?: any[];
  handleBack: () => void;
  handleChangeMember: (name: string, value: any[]) => any;
};

const View = (props: IProps) => {
  const { t } = useTranslation();
  const { userIds, dataUser, objectSetting, handleBack, handleChangeMember } =
    props;
  const listUsersRef = useRef(null);

  const [openModal, setOpenModal] = useState(false);
  const [users, setUsers] = useState([]);

  // search
  const [usersSearch, setUsersSearch] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isShowListUsers, setIsShowListUser] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [loadingConfirm, setLoadingConfirm] = useState(false);

  useEffect(() => {
    setUsersSearch((state) =>
      state.filter((user) => !selectedUsers.some((sU) => sU._id === user._id))
    );
  }, [isShowListUsers, isSearching]);

  useEffect(() => {
    if (userIds) {
      getUsers(userIds);
    }
  }, [userIds]);

  const getUsers = async (ids) => {
    try {
      const res: any = await SearchService.getUserByIds({
        body: {
          ids,
        },
      });
      if (res) {
        setUsers(res);
      }
    } catch (error) {}
  };

  const handleRemoveMember = async (value) => {
    try {
      await handleChangeMember(
        objectSetting.key,
        users.map((u) => u._id).filter((u) => u !== value._id)
      );
    } catch (error) {}
  };

  const handleAddMember = async () => {
    try {
      if (selectedUsers.length > 0) {
        setLoadingConfirm(true);
        await handleChangeMember(objectSetting.key, [
          ...selectedUsers.map((u) => u._id),
          ...users.map((u) => u._id),
        ]);
        setOpenModal(false);
        setUsersSearch([]);
        setSelectedUsers([]);
        setLoadingConfirm(false);
      }
    } catch (error) {
      setLoadingConfirm(false);
    }
  };

  // SEARCH
  const handleSearchMembers = async (value: string) => {
    setIsSearching(true);
    const res: any = await SearchService.searchFriendInvite({
      query: {
        page: 1,
        limit: 20,
        search: value,
        object_type: dataUser.object_type,
        object_id: dataUser._id,
        nids: userIds,
        type: [OBJECT_TYPE.GROUP, OBJECT_TYPE.BUSINESS].includes(
          dataUser.object_type
        )
          ? "member"
          : "friend",
      },
    });
    if (res && res.docs) {
      setIsSearching(false);
      setIsShowListUser(true);
      setLoading(false);
      return setUsersSearch(res.docs);
    }
    return;
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleSearchMembers, 1000),
    []
  );

  const onClickOutSide = (e) => {
    const { target } = e;
    if (listUsersRef?.current === null) {
      setIsShowListUser(false);
      return;
    }
    if (listUsersRef?.current === undefined) {
      setIsShowListUser(false);
      return;
    }
    if (!listUsersRef?.current?.contains(target)) {
      setIsShowListUser(false);
      return;
    }
  };

  const handleSelectMembers = (value: any) => {
    if (selectedUsers.some((item) => item._id === value._id)) {
      return setSelectedUsers(
        selectedUsers.filter((item) => item._id !== value._id)
      );
    }

    return setSelectedUsers((prev) => [...prev, value]);
  };

  const handleDeleteSelectedUser = (value) => {
    if (value) {
      setSelectedUsers(selectedUsers.filter((item) => item !== value));
    }
  };

  const renderChildrenAddMember = () => (
    <div className="members-body-wrapper" onClick={onClickOutSide}>
      <div className="members-search-wrapper select-none">
        <VSearch
          handleChange={debouncedChangeHandler}
          className="add-members-search-input"
          handleKeyDown={() => {
            setLoading(true);
            setUsersSearch([]);
          }}
          handleClick={() => setIsShowListUser(true)}
        />
        {isShowListUsers && (
          <div
            ref={listUsersRef}
            className="members-selection-users"
            onClick={(e) => e.stopPropagation()}
          >
            <VIcon
              name="close.svg"
              onClick={() => {
                setIsShowListUser(false);
              }}
            />
            {loading && <VLoading />}
            {usersSearch.map((item, index) => (
              <VSelectUser
                data={item}
                key={index}
                onChange={handleSelectMembers}
                className="px-4"
                selectedUsers={selectedUsers}
              />
            ))}
          </div>
        )}
      </div>
      <div className="members-list-selected">
        <VList data={selectedUsers} onDelete={handleDeleteSelectedUser} />
      </div>
    </div>
  );

  return (
    <div className="privacy-security-wrapper">
      <div className="card privacy-security-header py-2">
        <VButton icon={<VIcon name="back.svg" />} onClick={handleBack} />
        <div>{t(objectSetting.name)}</div>
      </div>

      <div className="card">
        <VButton className={"add-users"} onClick={() => setOpenModal(true)}>
          {t("Add Users")}
        </VButton>
        <div className="list-user">
          <VList
            isShowSeleted={false}
            data={users}
            onDelete={handleRemoveMember}
          />
        </div>

        <ModalInvite
          isOpen={openModal}
          onCancel={() => {
            setOpenModal(false);
            setUsersSearch([]);
            setSelectedUsers([]);
            setIsShowListUser(false);
            setLoading(false);
          }}
          loading={loadingConfirm}
          centered={true}
          title={<span className="member-tilte">{t("Add member")}</span>}
          children={renderChildrenAddMember()}
          onConfirm={handleAddMember}
        />
      </div>
    </div>
  );
};

export default View;

import { MapContext } from "pages/sos/maps/MapsContext";
import { useContext } from "react";

const GeolocationRequest = () => {
  const mapContext = useContext(MapContext);
  const { geolocationPermission } = mapContext;

  return (
    <div className="card">
      <div className="p-3">
        <div className="text-center">Chia sẻ vị trí của bạn hoặc chọn thành phố, tỉnh thành.</div>
        {geolocationPermission === "denied" && (
          <>
            <div className="py-3">
              Chia sẻ vị trí đã bị từ chối, hãy kiểm tra lại cài đặt hoặc trình
              duyệt của bạn.
            </div>
            <img
              src="assets/images/icons/location-permission.jpg"
              alt="permission"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default GeolocationRequest;

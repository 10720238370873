/* eslint-disable no-unused-vars */
import React from "react";
import { useAppSelector } from "redux/reducers/hook";
import {
  getRandomTemplateCategoryState,
  getRandomTemplateImageState,
} from "redux/reducers/ui-settings/ui-settings.selector";

import Modal from "components/v-modal";

const RandomTemplate = () => {
  const category = useAppSelector(getRandomTemplateCategoryState);
  const imageUrl = useAppSelector(getRandomTemplateImageState);

  return (
    <div>
      {/* <Modal
        isOpen={!!category}
        centered={true}
        title={""}
        // onCancel={() => setOpenModal(false)}
      ></Modal> */}
    </div>
  );
};

export default RandomTemplate;

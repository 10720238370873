/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable indent */
import { Link, useLocation, useHistory } from "react-router-dom";
import { Row, Col } from "reactstrap";
import queryString from "query-string";

import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import { getCryptosAsync } from "redux/reducers/cryptos/cryptos.actions";

import { formatNumber, getUrlImage } from "utils/common";

// import { getProjects } from "redux/reducers/projects/projects.selector";
import { getProjectsAsync } from "redux/reducers/projects/projects.action";

import { IoMdContract } from "react-icons/io";
import { TbWorld } from "react-icons/tb";
import { AiOutlineHistory } from "react-icons/ai";
import { BsCash } from "react-icons/bs";

import VButton from "components/v-button";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import useQuery from "hooks/useQuery";

import DepositVnd from "./components/deposit-vnd";

import WithdrawVND from "./components/withdraw-vnd";
import DepositStock from "./components/deposit-stock";
import WithdrawStock from "./components/withdraw-stock";
import HistoryStock from "./components/history-stock";
import WithdrawCV from "./components/withdraw-cv";
import WithdrawQrMart from "./components/withdraw-qrmart";
import DepositP2P from "./components/p2p/deposit-p2p";
import DepositSpot from "./components/spot/deposit-spot";
import WithdrawP2P from "./components/p2p/withdraw-p2p";
import WithdrawSpot from "./components/spot/withdraw-spot";
import UserService from "api/users";
import { IObject } from "types";

type IProps = {
  url: string;
  userId: string;
};

const Wallet = (props: IProps) => {
  const { url, userId } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location: any = useLocation();
  const query = useQuery();

  const address = queryString.parse(location.search)?.address;
  const amountStock = queryString.parse(location.search)?.amountStock;
  const { method } = query;

  const cryptos = useAppSelector(getCryptos);
  const [balances, setBalances] = useState({
    total_vnex: 0,
    total_vnd: 0,
  });
  const [lockedBalances, setLockedBalances] = useState({});
  //   const projects = useAppSelector(getProjects);

  const [isQrScanWithdraw, setIsQrScanWithdraw] = useState<boolean>(
    Boolean(amountStock && address)
  );
  //   const [subWallets, setSubWallets] = useState([]);
  const [wallets, setListWallets] = useState([]);
  const [isShowAll, setIsShowAll] = useState(false);
  const [isShowAllSub, setIsShowAllSub] = useState(false);

  useEffect(() => {
    if (userId) {
      getDataBalance();
    }
  }, [userId]);

  useEffect(() => {
    if (cryptos && cryptos.length > 0 && balances) {
      const _wallets = cryptos.map((token) => ({
        ...token,
        balance: balances[token.symbol] ? Number(balances[token.symbol]) : 0,
        locked_balances: lockedBalances[token.symbol]
          ? Number(lockedBalances[token.symbol])
          : 0,
      }));

      const _walletVnex = _wallets.find(
        (w) => w.symbol === process.env.REACT_APP_STOCK_DEFAULT
      );
      const _walletsNormal = _wallets.filter(
        (w) =>
          w.symbol !== process.env.REACT_APP_STOCK_DEFAULT &&
          (isShowAll ? true : +w.balance > 0)
      );
      setListWallets([_walletVnex, ..._walletsNormal]);
    }
  }, [cryptos, balances, lockedBalances, isShowAll]);

  //   useEffect(() => {
  //     if (projects.length > 0 && balances) {
  //       const _wallets = projects
  //         .filter(
  //           (p) =>
  //             ["closed", "opening"].includes(p.status) &&
  //             !cryptos.some((c) => c.symbol === p.symbol)
  //         )
  //         .map((token) => ({
  //           ...token,
  //           balance: balances[token.symbol] ? Number(balances[token.symbol]) : 0,
  //           locked_balances: locked_balances[token.symbol]
  //             ? Number(locked_balances[token.symbol])
  //             : 0,
  //         }));
  //       const _walletsNormal = _wallets.filter((w) =>
  //         isShowAllSub ? true : +w.balance > 0
  //       );
  //       setSubWallets(_walletsNormal);
  //     }
  //   }, [projects, cryptos, balances, locked_balances, isShowAllSub]);

  useEffect(() => {
    dispatch(getCryptosAsync());
    dispatch(getProjectsAsync());
  }, [dispatch]);

  const getDataBalance = async () => {
    try {
      const res: IObject = await UserService.getBalancesByUser({
        params: {
          id: userId,
        },
      });
      if (res) {
        setBalances(res.balances);
        setLockedBalances(res.locked_balances);
      }
    } catch (error) {}
  };

  const handleClick = (method: string, stock: string) => {
    let _method = method;
    if (method !== "history") {
      if (stock === process.env.REACT_APP_STOCK_DEFAULT)
        _method = `${method}_vnd`;
      else _method = `${method}_stock`;
    }
    const _query = {
      ...query,
      method: _method,
      stock,
    };
    history.push(`${url}/?${queryString.stringify(_query)}`);
  };

  return (
    <>
      <div className="container wallets">
        {address && (
          <div
            className={`show-left-webview ${!isQrScanWithdraw ? "isShow" : ""}`}
            onClick={() => setIsQrScanWithdraw(!isQrScanWithdraw)}
          >
            {isQrScanWithdraw ? "Show" : "Hide"} {t("my assets")}
          </div>
        )}
        {!isQrScanWithdraw && (
          <>
            {/* <div className="top-header">
              <h3>{t("Tổng quan về tài khoản")}</h3>
              <div className="action-wallets">
                <Link to={"#"}>
                  <VButton>{t("Mua")}</VButton>
                </Link>
                <Link to={"#"}>
                  <VButton>{t("Nạp")}</VButton>
                </Link>
                <Link to={"#"}>
                  <VButton>{t("Rút")}</VButton>
                </Link>
                <Link to={"#"}>
                  <VButton>{t("Chuyển tiền")}</VButton>
                </Link>
              </div>
            </div> */}
            {/* <Card className={"mt-3"} isOpacity={true}>
              <div className="statistical">
                <div className="item-statistical">
                  <h6 className="mb-1">{t("Ước tính tổng tài sản")}</h6>
                  <div className="ps-3">
                    <h3 className="fs-3">
                      {formatNumber(balances.total_vnex || 0)}{" "}
                      {getSymbolStock(process.env.REACT_APP_STOCK_DEFAULT)}
                    </h3>
                    <div className="fw-bold fs-4">
                      ={" "}
                      <span className="opacity-50 ">
                        {" "}
                        {formatNumber(balances.total_vnd || 0)} VND
                      </span>
                    </div>
                    <div>{t("Sổ tài sản ước tính")}</div>
                  </div>
                </div>
                <div className="item-statistical">
                  <h6 className="mb-1">{t("Phân tích tài sản")}</h6>
                  <div className="ps-3">
                    <h3 className="fs-3">
                      0 {getSymbolStock(process.env.REACT_APP_STOCK_DEFAULT)}
                    </h3>
                    <div className="fw-bold fs-4">
                      = <span className="opacity-50 ">0 VND</span>
                    </div>
                    <div>{t("Chia sẻ áp phích")}</div>
                  </div>
                </div>
              </div>
            </Card> */}
          </>
        )}
        <Row>
          {!isQrScanWithdraw && (
            <Col col lg={6} xl={5}>
              <div>
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold">{t("e.NFT Cổ phiếu")}</h5>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={"manager_wallet"}
                      name={"position"}
                      checked={isShowAll}
                      onChange={(e) => setIsShowAll(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"manager_wallet"}
                    >
                      {t("Show all stock")}
                    </label>
                  </div>
                </div>
                <div>
                  {wallets.map(
                    (item: any, index: number) =>
                      item && (
                        <Card key={index} isOpacity={true} className={"mb-1"}>
                          <div className="content">
                            <Row lg={12} md={12}>
                              <Col xs={9} lg={8}>
                                <div className="estock">
                                  <h6 className="d-flex align-items-center">
                                    <div className="logo-estock">
                                      <img
                                        src={
                                          getUrlImage(item.logo) ||
                                          "/assets/images/coins/btc.svg"
                                        }
                                        alt={item.symbol}
                                      />
                                    </div>
                                    <div className="ms-1">
                                      <div>
                                        {item.name}{" "}
                                        {item.symbol !==
                                          process.env
                                            .REACT_APP_STOCK_DEFAULT && (
                                          <span>
                                            {item.symbol.toUpperCase()}
                                          </span>
                                        )}
                                      </div>
                                      <h6>
                                        <span>
                                          {formatNumber(+item.balance)}{" "}
                                        </span>{" "}
                                        {item.symbol.toUpperCase()}
                                      </h6>
                                    </div>
                                  </h6>
                                  {+item.locked_balances > 0 && (
                                    <div className="hold">
                                      Hold:{" "}
                                      <span>
                                        {formatNumber(+item.locked_balances)}{" "}
                                      </span>{" "}
                                      {item.symbol.toUpperCase()}
                                    </div>
                                  )}
                                </div>
                              </Col>
                              <Col xs={3} lg={4} className="p-0">
                                <div className="action-stock">
                                  <a className={"row-1"}>
                                    <VButton
                                      className={"bg-primary"}
                                      onClick={(_) =>
                                        handleClick("deposit", item.symbol)
                                      }
                                      disabled={!item.is_deposit}
                                    >
                                      {t("Deposit")}
                                    </VButton>
                                  </a>

                                  <Link className={"row-1"}>
                                    <VButton
                                      className={"btn-orange"}
                                      onClick={(_) =>
                                        handleClick("withdraw", item.symbol)
                                      }
                                      disabled={!item.is_withdraw}
                                    >
                                      {t("Withdraw")}
                                    </VButton>
                                  </Link>
                                </div>
                                <div className="action-stock">
                                  <Link className={"row-2"}>
                                    <VButton
                                      className={"btn-mainnet"}
                                      disabled={true}
                                    >
                                      <div>
                                        {t("Mainnet")}
                                        <div className="text-fs px-8">
                                          (12/12/2023)
                                        </div>
                                      </div>
                                    </VButton>
                                  </Link>
                                </div>
                              </Col>
                            </Row>
                            <div>
                              <div className="action-history">
                                <Link>
                                  <div
                                    className="item"
                                    style={{
                                      color:
                                        location?.pathname?.includes(
                                          `${item.symbol}/history`
                                        ) && "orange100",
                                    }}
                                    onClick={(_) =>
                                      handleClick("history", item.symbol)
                                    }
                                  >
                                    <AiOutlineHistory />
                                    {t("History")}
                                  </div>
                                </Link>

                                <Link to={`#`}>
                                  <div className="item">
                                    <BsCash />
                                    {t("Sell to cash")}
                                  </div>
                                </Link>
                                <div className="item">
                                  <IoMdContract />
                                  <a
                                    href={item.contract}
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {t("Contract")}
                                  </a>
                                </div>
                                <div className="item">
                                  <TbWorld />
                                  <a
                                    href={item.web}
                                    target={"_blank"}
                                    rel="noreferrer"
                                  >
                                    {t("Website")}
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card>
                      )
                  )}
                </div>
              </div>
              <div className="my-3">
                <div className="d-flex justify-content-between align-items-center">
                  <h5 className="fw-bold">{t("Các dự án được triển khai")}</h5>
                  <div className="form-check">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      id={"manager_sub_wallet"}
                      name={"position"}
                      checked={isShowAllSub}
                      onChange={(e) => setIsShowAllSub(e.target.checked)}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"manager_sub_wallet"}
                    >
                      {t("Show all stock")}
                    </label>
                  </div>
                </div>
                {/* <div>
                  {subWallets.map((item: any, index: number) => (
                    <Card key={index} isOpacity={true} className={"mb-1"}>
                      <div className="content">
                        <Row lg={12} md={12}>
                          <Col xs={9} lg={8}>
                            <div className="estock">
                              <h6 className="d-flex align-items-center">
                                <div className="logo-estock">
                                  <img
                                    src={
                                      getUrlImage(item.logo) ||
                                      "/assets/images/coins/btc.svg"
                                    }
                                    alt={item.symbol}
                                  />
                                </div>
                                <div className="ms-1">
                                  <div>
                                    {item.name}{" "}
                                    {item.symbol !==
                                      process.env.REACT_APP_STOCK_DEFAULT && (
                                      <span>{item.symbol.toUpperCase()}</span>
                                    )}
                                  </div>
                                  <h6>
                                    <span>{formatNumber(+item.balance)} </span>{" "}
                                    {item.symbol.toUpperCase()}
                                  </h6>
                                </div>
                              </h6>
                              {+item.locked_balances > 0 && (
                                <div className="hold">
                                  {t("Hold")}:{" "}
                                  <span>
                                    {formatNumber(+item.locked_balances)}{" "}
                                  </span>{" "}
                                  {item.symbol.toUpperCase()}
                                </div>
                              )}
                            </div>
                          </Col>
                          <Col xs={3} lg={4} className="p-0">
                            <div className="action-stock">
                              <Link
                                className={"row-1"}
                                to={
                                  item.is_deposit &&
                                  `/my/wallets/${item.symbol}/deposit`
                                }
                              >
                                <VButton
                                  className={"btn-primary"}
                                  onClick={scroll}
                                  disabled={!item.is_deposit}
                                >
                                  {t("Deposit")}
                                </VButton>
                              </Link>
                              <Link
                                className={"row-1"}
                                to={
                                  item.is_withdraw &&
                                  `/my/wallets/${item.symbol}/withdraw`
                                }
                              >
                                <VButton
                                  className={"btn-orange"}
                                  onClick={scroll}
                                  disabled={!item.is_withdraw}
                                >
                                  {t("Withdraw")}
                                </VButton>
                              </Link>
                            </div>
                          </Col>
                        </Row>
                        <div>
                          <div className="action-history">
                            <Link to={`/my/wallets/${item.symbol}/history`}>
                              <div
                                className="item"
                                style={{
                                  color:
                                    location?.pathname?.includes(
                                      `${item.symbol}/history`
                                    ) && "orange100",
                                }}
                                onClick={scroll}
                              >
                                <AiOutlineHistory />
                                {t("History")}
                              </div>
                            </Link>

                            <Link to={`#`}>
                              <div className="item" onClick={scroll}>
                                <BsCash />
                                {t("Sell to cash")}
                              </div>
                            </Link>
                            <div className="item" onClick={scroll}>
                              <IoMdContract />
                              <a
                                href={item.contract}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {t("Contract")}
                              </a>
                            </div>
                            <div className="item" onClick={scroll}>
                              <TbWorld />
                              <a
                                href={item.web}
                                target={"_blank"}
                                rel="noreferrer"
                              >
                                {t("Website")}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Card>
                  ))}
                </div> */}
              </div>
            </Col>
          )}
          <Col col lg={6} xl={7} className="ps-0">
            <div id="right" className="right-wiew">
              {/* VND */}
              {method === "deposit_vnd" && (
                <DepositVnd
                  url={url}
                  userId={userId}
                  reloadBalance={getDataBalance}
                />
              )}

              {method === "withdraw_vnd" && (
                <WithdrawVND
                  userId={userId}
                  reloadBalance={getDataBalance}
                  balances={balances}
                />
              )}

              {/* STOCK */}
              {method === "deposit_stock" && (
                <DepositStock
                  url={url}
                  userId={userId}
                  reloadBalance={getDataBalance}
                />
              )}
              {method === "withdraw_stock" && <WithdrawStock url={url} />}

              {/* P2P */}
              {method === "deposit_p2p" && (
                <DepositP2P
                  url={url}
                  userId={userId}
                  reloadBalance={getDataBalance}
                />
              )}
              {method === "withdraw_p2p" && (
                <WithdrawP2P
                  url={url}
                  balances={balances}
                  userId={userId}
                  reloadBalance={getDataBalance}
                />
              )}

              {/* SPOT */}
              {method === "deposit_spot" && (
                <DepositSpot
                  url={url}
                  userId={userId}
                  reloadBalance={getDataBalance}
                />
              )}
              {method === "withdraw_spot" && (
                <WithdrawSpot
                  url={url}
                  balances={balances}
                  userId={userId}
                  reloadBalance={getDataBalance}
                />
              )}

              {/* OUT */}
              {method === "withdraw_cv" && (
                <WithdrawCV
                  url={url}
                  balances={balances}
                  userId={userId}
                  reloadBalance={getDataBalance}
                />
              )}
              {method === "withdraw_qr-mart" && (
                <WithdrawQrMart
                  url={url}
                  balances={balances}
                  userId={userId}
                  reloadBalance={getDataBalance}
                />
              )}

              {/* HISTORY */}
              {method === "history" && <HistoryStock />}
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Wallet;

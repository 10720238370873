/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getPostDirectoryByUserIdAsync } from "./post-directory.actions";

export interface IPostDirectoryState {
  postDirectory: any;
}

const initialState: IPostDirectoryState = {
  postDirectory: {},
};

export const PostDirectorySlice = createSlice({
  name: "posts-directory",
  initialState,
  reducers: {},
  extraReducers: {
    [`${getPostDirectoryByUserIdAsync.pending}`]: (
      state: IPostDirectoryState,
      { payload }
    ) => {},
    [`${getPostDirectoryByUserIdAsync.fulfilled}`]: (
      state: IPostDirectoryState,
      { payload }
    ) => {
      state.postDirectory = payload || [];
    },
  },
});

export const {} = PostDirectorySlice.actions;

export default PostDirectorySlice.reducer;

import VModal from "components/v-modal";
import { ReactElement } from "react";

interface IProps {
  isOpen: boolean;
  loading: boolean;
  children: ReactElement;
  title: ReactElement;
  centered: boolean;
  onCancel: (e?: any) => void;
  onConfirm: () => void;
}

const View = ({
  isOpen,
  loading,
  children,
  title,
  centered,
  onCancel,
  onConfirm,
}: IProps) => {
  return (
    <VModal
      isOpen={isOpen}
      loading={loading}
      centered={centered}
      title={title}
      children={children}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};

export default View;

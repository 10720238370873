import Card from "components/v-teamplate/Card";
import useQuery from "hooks/useQuery";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getUrlImage } from "utils/common";

/* eslint-disable indent */
const QA = (props) => {
  const { categoryFaqs } = props;

  const query = useQuery();
  const history = useHistory();

  const handleClick = (data) => {
    history.push(
      `${window.location.pathname}${window.location.search}&helpref=${data.key}`
    );
  };
  
  useEffect(() => {
    if (!categoryFaqs.length) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.tab]);

  return (
    <div className="d-flex flex-wrap text-center">
      {categoryFaqs &&
        categoryFaqs.map((ct, index) => (
          <div
            key={index}
            className="col-6 col-md-3 p-1"
            onClick={() => handleClick(ct)}
          >
            <Card className={"item"}>
              <div className="bg-qa top"></div>
              <div className="bg-qa bottom"></div>
              <div className="h-50">
                <img className="d-block m-auto py-3" src={getUrlImage(ct.icon)} alt="icon" />
              </div>
              <div className="h-50">
                <h6 className="fs-5 fw-bold">{ct.name}</h6>
                <p className="fs-6 mt-2">{ct.description}</p>
              </div>
            </Card>
          </div>
        ))}
    </div>
  );
};
export default QA;

/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getDepositBanksAsync } from "./banks.actions";

export interface IBanksState {
  depositBanks: any[];
  myBanks: any[];
}

const initialState: IBanksState = {
  depositBanks: [],
  myBanks: [],
};

export const banksSlice = createSlice({
  name: "banks",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDepositBanksAsync.fulfilled, (state, action) => {
      state.depositBanks = action.payload;
    });
  },
});

export const {} = banksSlice.actions;

export default banksSlice.reducer;

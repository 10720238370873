/* eslint-disable react-hooks/exhaustive-deps */
import VAvatar from "components/v-avatar";
import VModal from "components/v-modal";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import VNameVerify from "../../v-name-verify";
import ProfileService from "api/profiles";
import VButton from "components/v-button";
import UserService from "api/users";
import { useHistory } from "react-router-dom";
import { getUrlProfile } from "utils/common";

const View = (props) => {
  const { userIdPairing, setUserIdPairing } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [user, setUser] = useState(null);

  const getProfileUser = async () => {
    try {
      const res: any = await UserService.getProfileById({
        params: { id: userIdPairing },
      });
      if (res) {
        setOpenModal(true);
        setUser(res?.user);
      }
    } catch (error) {}
  };

  const submit = async () => {
    try {
      setLoading(true);
      const res = await ProfileService.pairing({
        body: {
          user_id: user?._id,
          type: "accept",
        },
      });
      if (res) {
        toast.success(t("SUCCESS"));
        setOpenModal(false);
        setLoading(false);
        history.push(`${getUrlProfile(user)}`);
      }
    } catch (error: any) {
      toast.error(t(error.errors));
      setLoading(false);
    }
  };

  useEffect(() => {
    getProfileUser();
  }, [userIdPairing]);

  return (
    <VModal
      title={t("Pairing")}
      isOpen={openModal}
      loading={loading}
      centered={true}
      showFooter={false}
      children={
        <div>
          <div className="profile-img">
            <div className="img-fluid d-flex justify-content-center">
              <VAvatar
                hideName={true}
                size={"70px"}
                userData={user}
                // handleClickType={() => handleClick("choose_image", "avatar")}
                // handleUpload={handleUploadAvatar}
              />
            </div>
          </div>
          <div className="text-center w-100 fs-5 d-flex flex-wrap justify-content-center align-items-center mt-2">
            <div className="profile-header">
              <div className="user-detail position-relative mt-0 mb-0">
                <div className="profile-detail">
                  <h4
                    style={{
                      maxWidth: user?.full_name?.length > 20 ? "250px" : "100%",
                      margin: "auto",
                    }}
                  >
                    <VNameVerify
                      dataUser={user}
                      fontSize={"16px"}
                      className={"username"}
                    />
                  </h4>
                </div>
              </div>
            </div>
            <span>{t("muốn ghép đôi với bạn")}</span>
          </div>
          <div className="text-center">
            <strong className="fs-4">
              {user?.profile?.pairing?.data?.name || ""}
            </strong>
          </div>

          <div className="text-center mt-3">
            <VButton className="mx-1" loading={loading} onClick={submit}>
              {t("Đồng ý")}
            </VButton>
            <VButton
              className="mx-1 bg-secondary border-secondary"
              loading={loading}
              onClick={() => {
                setOpenModal(false);
                setUserIdPairing(null);
              }}
            >
              {t("Cancel")}
            </VButton>
          </div>
        </div>
      }
      onCancel={() => {
        setOpenModal(false);
        setUserIdPairing(null);
      }}
      onConfirm={submit}
    />
  );
};

export default View;

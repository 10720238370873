import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getPurchase = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/purchases/${req.params.type}/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getOrderByCode = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/purchases/code/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const buyProduct = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/purchases/buy/product`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const buyIBO = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/purchases/buy/project`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInvoice = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/purchases/invoice/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      });
  });

const getPurchaseByPixelId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/purchases/by-pixel/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PurchaseService = {
  getPurchase,
  buyProduct,
  getOrderByCode,
  buyIBO,
  getInvoice,
  getPurchaseByPixelId,
};

export default PurchaseService;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-is-valid */
import SearchService from "api/search";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import VAvatar from "components/v-avatar";
import VIcon from "components/v-icon";
import { useAppSelector } from "redux/reducers/hook";
import { getDataAccessOwner } from "redux/reducers/users/users.selector";
import {
  getMutualFriends,
  isBusiness,
  isGroup,
  isPage,
  isUser,
} from "utils/friends";
import VButton from "components/v-button";

const View = ({ handleClose, search, setSearch }) => {
  const dataAccessOwner = useAppSelector(getDataAccessOwner);
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    limit: 10,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
        if (search) getData(search);
    else setData([]);
  }, [lazyParams]);

  useEffect(() => {
        setLazyParams({
      limit: 10,
      page: 1,
    });
  }, [search]);

  const getData = async (search) => {
    setLoading(true);
    const res: any = await SearchService.search({
      query: {
        ...lazyParams,
        search,
        objectType: "all",
      },
    });

    if (res && res.docs) {
      if (res.page === 1) {
        setData(res.docs);
      } else {
        setData((prev) => prev.concat(res.docs));
      }
      setTotalPages(res.totalPages);
    }
    setLoading(false);
  };

  const getAdditionalData = (user) => {
    const count =
      user.count && user.count.count
        ? user.count.count
        : {
            post: 0,
            follower: 0,
            friend: 0,
            following: 0,
            group: 0,
            business: 0,
          };
    if (isUser(user.object_type)) {
      return `${getMutualFriends(dataAccessOwner, user.data_access)} ${t(
        "Mutual friends"
      )}`;
    }
    if (isPage(user.object_type)) {
      return `${count.follower || 0} ${t("Follower")}`;
    }
    if (isGroup(user.object_type)) {
      return `${count.group || 0} ${t("Member")}`;
    }
    if (isBusiness(user.object_type)) {
      return `${count.business || 0} ${t("Member")}`;
    }
  };

  const handleViewMore = () => {
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const handleClick = () => {
    handleClose();
  };

  return (
    <div className="modal-search">
      <div className="p-0">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="title d-flex justify-content-between m-2">
                <p>{t("Recent search")}</p>
                <a onClick={handleClose}>{t("Close")}</a>
              </div>
            </div>
          </div>
          <div className="content d-flex align-items-center search-hover py-2 px-3 row">
            {data.map((item) => {
              return (
                <div className="col-12 d-flex justify-content-between align-items-center mt-2">
                  <VAvatar
                    size={"40px"}
                    userData={item}
                    additionalData={getAdditionalData(item)}
                    isClick={true}
                    onClick={(_) => handleClick()}
                  />
                  <VIcon name="close.svg" />
                </div>
              );
            })}
          </div>

          {lazyParams.page < totalPages && (
            <div className="text-center p-2">
              <VButton
                type="button"
                loading={loading}
                size={"small"}
                onClick={handleViewMore}
              >
                {" "}
                {t("View more")}
              </VButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default View;

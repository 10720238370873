/* eslint-disable indent */

import { useAppSelector } from "redux/reducers/hook";
import {
  getGeneral,
  getObjects,
} from "redux/reducers/settings/settings.selector";
import { getUrlImage } from "utils/common";
import { useEffect, useState } from "react";
import Marquee from "react-fast-marquee";
import VIcon from "components/v-icon";
import VNickname from "../v-nickname";

type IProps = {
  dataUser: any;
  className?: string;
  fontSize?: string;
  fontWeight?: string;
  sizeAvatar?: string;
  fontSizeIconVerify?: number;
  isEditNickname?: boolean;
  relation?: any;
  onLoad?: () => void;
  onClickName?: () => void;
};
const View = (props: IProps) => {
  const {
    dataUser,
    className,
    fontSize,
    fontWeight,
    sizeAvatar,
    fontSizeIconVerify,
    relation,
    isEditNickname = false,
    onLoad,
    onClickName,
  } = props;

  const objectsSetting = useAppSelector(getObjects);
  const general = useAppSelector(getGeneral);

  const [openModal, setOpenModal] = useState(false);
  const [nickName, setNickName] = useState("");

  const ob = objectsSetting.find(
    (el) => el?.key?.toLowerCase() === dataUser?.object_type?.toLowerCase()
  );
  const [iconCensor, setIconCensor] = useState(null);

  useEffect(() => {
    if (dataUser?.object_type === "COMMUNITY" && general) {
      const icon = general.site.find((item) => item.key === "icon_censor");
      setIconCensor(icon);
    }
  }, [dataUser?.object_type, general]);

  useEffect(() => {
    if (relation?.fromFriend) {
      setNickName(relation?.fromFriend?.nickname || null);
    }
  }, [relation?.fromFriend]);

  return (
    <div
      onClick={(_) => onClickName && onClickName()}
      className={className}
      style={{
        color:
          dataUser?.status_kyc === "kyc" || dataUser?.status_kyc === "verified"
            ? `#${ob?.text_color}`
            : "",
      }}
    >
      <span
        className="username-verify-clamp-1 cursor-pointer"
        style={{
          maxWidth: `calc(100% - ${sizeAvatar})`,
          fontSize: `${fontSize}`,
          fontWeight: `${fontWeight}`,
        }}
      >
        {dataUser?.full_name?.length > 20 ? (
          <Marquee speed={50} gradient={false}>
            <span className="w-100 px-2">
              {nickName || dataUser?.full_name}
            </span>
          </Marquee>
        ) : (
          nickName || dataUser?.full_name
        )}
      </span>
      <div className="shield-medal">
        {(dataUser?.status_kyc === "kyc" ||
          dataUser?.status_kyc === "verified") && (
          <div className="">
            {ob?.logo_verify && (
              <img
                width={fontSizeIconVerify || 18}
                src={
                  getUrlImage(ob?.logo_verify) ||
                  "/assets/icons/default/logo-verify.svg"
                }
                alt=""
              />
            )}
          </div>
        )}
        {dataUser?.object_type === "COMMUNITY" && (
          <div className="">
            {iconCensor && iconCensor?.site_media && (
              <img
                width={fontSizeIconVerify || 18}
                src={
                  getUrlImage(iconCensor.site_media) ||
                  "/assets/icons/default/logo-verify.svg"
                }
                alt=""
              />
            )}
          </div>
        )}
        {isEditNickname && (
          <div className="edit-nickname" onClick={() => setOpenModal(true)}>
            <VIcon width={14} name="edit-page.svg" />
          </div>
        )}
      </div>
      <VNickname
        openModal={openModal}
        dataUser={dataUser}
        nickNameData={nickName}
        onLoad={onLoad}
        setOpenModal={setOpenModal}
      />
    </div>
  );
};

export default View;

import { Col, Row } from "reactstrap";
import {
  Container,
  Image,
  Campaign,
  Action,
  Item,
  Text,
  ContainImage,
  ContainItem,
  Tabs,
  Tab,
  Introduction,
  Content,
  Wrapper,
  ModalWrapper,
} from "./styles";
import Button from "components/v-button";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { useEffect, useState } from "react";
import { getTaskByIdAsync } from "redux/reducers/tasks/tasks.actions";
import { getTaskById } from "redux/reducers/tasks/tasks.selector";
import draftToHtml from "draftjs-to-html";
import APIs from "api";
import { toast } from "react-toastify";
import Modal from "components/v-modal";
import { compareDate } from "utils/times";
import { getUrlImage } from "utils/common";

const View = ({ id }) => {
  const [tab, setTab] = useState<string>("introduction");
  const task = useAppSelector(getTaskById);
  const dispatch = useAppDispatch();
  // const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const handlePerform = async () => {
    try {
      const res: any = await APIs.TASK.postPerform({
        params: {
          id,
        },
      });
      if (res) {
        window.open(task.url_task);
        toast.success(res);
      }
      setOpenModal(false);
    } catch (error) {
      window.open(task.url_task);
      setOpenModal(false);
      toast.warning(error.errors);
      // history.push("/my/task");
    }
  };

  const handleClick = () => {
    if (task.type === "click_link") {
      setOpenModal(true);
    } else {
      window.open(task.url_task);
    }
  };

  useEffect(() => {
    dispatch(
      getTaskByIdAsync({
        params: {
          id: id,
        },
      })
    );
  }, [dispatch, id]);

  useEffect(() => {
    if (tab && task) {
      const content = task[tab];
      const html = draftToHtml(content);
      const element = document.getElementById("content");
      if (element) element.innerHTML = html;
    }
  }, [tab, task]);

  const renderModal = () => {
    return (
      <Modal
        isOpen={openModal}
        centered={true}
        onCancel={() => setOpenModal(false)}
      >
        <ModalWrapper>
          <h4>Click on the link below : </h4>
          <p onClick={handlePerform}>{task.url_task}</p>
        </ModalWrapper>
      </Modal>
    );
  };

  return (
    <div className="container">
      <Container>
        {renderModal()}
        {task && task.reward && (
          <>
            <Wrapper>
              <Row>
                <Col xs={12} lg={2}>
                  <Image>
                    <img src={getUrlImage(task.banner)} alt="" />
                  </Image>
                </Col>

                <Col xs={12} lg={10}>
                  <Campaign style={{}}>
                    <span>{task.label}</span>
                  </Campaign>
                  <h4>{task.name}</h4>
                  <Action>
                    <Button disabled>Share link</Button>
                    <Button onClick={() => handleClick()}>Perform</Button>
                  </Action>
                </Col>
              </Row>

              <ContainItem>
                <Row>
                  <Col xs={12} md={6}>
                    <Item>
                      <ContainImage>
                        <img src="/assets/images/icons/money.svg" alt="" />
                      </ContainImage>
                      <Text>
                        <strong>
                          <label>Stocks :</label>
                          {task.reward["stocks"].map((s) => (
                            <div>
                              <span>
                                +{s.amount} ({s.symbol})
                              </span>
                            </div>
                          ))}
                          <hr />
                          <label>Vouchers :</label>
                          {task.reward["vouchers"].map((s) => (
                            <div>
                              <span>
                                +{s.amount} ({s.id})
                              </span>
                            </div>
                          ))}
                          <hr />
                          <label>Tickets :</label>
                          {task.reward["tickets"].map((s) => (
                            <div>
                              <span>
                                +{s.amount} ({s.id})
                              </span>
                            </div>
                          ))}
                          <hr />
                          <label>Codes :</label>
                          {task.reward["codes"].map((s) => (
                            <div>
                              <span>
                                +{s.codes} ({s.symbol})
                              </span>
                            </div>
                          ))}
                          <hr></hr>
                        </strong>

                        <label>
                          Commission you will get for successfully completing
                          the campaign
                        </label>
                      </Text>
                    </Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Item>
                      <ContainImage>
                        <img src="/assets/images/icons/clock.svg" alt="" />
                      </ContainImage>
                      <Text>
                        <b>
                          {Math.round(
                            compareDate(
                              task.time.end_date,
                              task.time.start_date
                            ) / 86400000
                          )}{" "}
                          days
                        </b>
                        <label>Due date for approval</label>
                      </Text>
                    </Item>
                  </Col>
                </Row>

                <Row>
                  <Col xs={12} md={6}>
                    <Item>
                      {" "}
                      <ContainImage>
                        <img src="/assets/images/icons/down.svg" alt="" />
                      </ContainImage>
                      <Text>
                        <b>Right Away</b>
                        <label>Due date for payment</label>
                      </Text>
                    </Item>
                  </Col>
                  <Col xs={12} md={6}>
                    <Item>
                      <ContainImage>
                        <img src="/assets/images/icons/clock.svg" alt="" />
                      </ContainImage>
                      <Text>
                        <b>30 days</b>
                        <label>The timeout of cookie on your browser</label>
                      </Text>
                    </Item>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} md={6}>
                    <Item>
                      <ContainImage>
                        <img src="/assets/images/icons/world.svg" alt="" />
                      </ContainImage>
                      <Text>
                        <a
                          href="https://vzonex.com/"
                          target={"_blank"}
                          rel="noreferrer"
                        >
                          {" "}
                          <b>{task.website || "https://vzonex.com/"}</b>
                        </a>
                        <label>Campaign's news source</label>
                      </Text>
                    </Item>
                  </Col>
                </Row>
              </ContainItem>
            </Wrapper>
            <Introduction>
              <Tabs>
                <Tab
                  isActive={tab === "introduction" ? true : false}
                  onClick={() => setTab("introduction")}
                >
                  Introduction
                </Tab>
                <Tab
                  isActive={tab === "accecptance_condition" ? true : false}
                  onClick={() => setTab("accecptance_condition")}
                >
                  Acceptance conditions
                </Tab>
                <Tab
                  isActive={tab === "rejection_condition" ? true : false}
                  onClick={() => setTab("rejection_condition")}
                >
                  Rejection conditions
                </Tab>
                <Tab
                  isActive={tab === "other_rules" ? true : false}
                  onClick={() => setTab("other_rules")}
                >
                  Other rules
                </Tab>
              </Tabs>

              <Content>
                <div id="content"></div>
              </Content>
            </Introduction>
          </>
        )}
      </Container>
    </div>
  );
};

export default View;

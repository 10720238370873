import { request, parseErrorResponse, IRequestData } from "./request";

const getUsersMedalsByUserId = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/users-medals/${req.params.id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UsersMedalsService = { getUsersMedalsByUserId };

export default UsersMedalsService;

import ProfileImages from "pages/social/object-details/profile-media/components/profile-images";
import { Container, Row, Col } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import VBannerPage from "components/v-teamplate/v-banner-page";
import VRightView from "components/v-right-view";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const View = () => {
  const dataUser = useAppSelector(getUserProfile);
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  return (
    <>
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <VBannerPage position="banner_top_profile_images" />
            <ProfileImages
              dataUser={dataUser}
              isMyProfile={true}
              col={"col-sm-3 col-lg-3"}
            />
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_profile_images"}
                positionBanner={"banner_right_profile_images"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default View;

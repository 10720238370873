/* eslint-disable indent */
import React, { useEffect, useRef, useState } from "react";
import Card from "components/v-teamplate/Card";
// import { Link } from "react-router-dom";

// images

import useTranslation from "hooks/useTranslation";
import VModal from "components/v-modal";
import Avatar from "components/v-avatar";

import DropdownModal from "components/v-dropdown-modal";
import PageBackgrounds from "components/v-page-background";
import { useAppDispatch } from "redux/reducers/hook";

import APIs from "api";
import { toast } from "react-toastify";
import EditCover from "./components/EditCover";
import VIcon from "components/v-icon";
import {
  getUserAsync,
  getUserByIdAsync,
} from "redux/reducers/users/users.actions";
import UserService from "api/users";

const View = (props) => {
  const { dataUser, isMyProfile } = props;
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [action, setAction] = useState("");
  const [loading, setLoading] = useState(false);
  const coverRef = useRef(null);
  const [familyData, setFamilyData] = useState({
    name: "",
    covers: null,
  });

  const { t } = useTranslation();

  useEffect(() => {
    if (dataUser?.profile?.family_data) {
      setFamilyData(dataUser.profile.family_data);
    } else {
      setFamilyData({
        name: "",
        covers: null,
      });
    }
  }, [dataUser.profile]);

  const handleClick = (name: string) => {
    setAction(name);
    setOpenModal(true);
  };

  const renderContentModal = (action) => {
    switch (action) {
      case "update_cover":
        return (
          <EditCover
            ref={coverRef}
            dataUser={dataUser}
            setLoading={setLoading}
            setOpenModal={setOpenModal}
          />
        );
      default:
        break;
    }
  };

  const submit = () => {
    coverRef.current.submit();
  };

  const handleUploadAvatar = async (file: any) => {
    try {
      if (file) {
        const formData = new FormData();
        formData.append("files", file);
        formData.append("folderPath", `users/${dataUser?._id}`);
        const imgRes: any = await APIs.UPLOAD.uploadFile({
          body: formData,
        });
        if (imgRes) {
          const res: any = await UserService.updateProfile({
            body: {
              family_data: {
                ...familyData,
                avatar: imgRes.filePath,
              },
            },
          });
          if (res) {
            if (isMyProfile) dispatch(getUserAsync());
            dispatch(
              getUserByIdAsync({
                params: {
                  id: dataUser._id,
                },
              })
            );
          }
        }
      }
    } catch (error: any) {
      toast.success(t(error?.errors));
    }
  };

  return (
    <Card>
      <Card.Body className=" profile-page p-0">
        <div className="profile-header">
          <div className="container-cover">
            <div className="cover-image cursor-pointer">
              <PageBackgrounds
                width="100%"
                height="100%"
                position="relative"
                zIndex={"0"}
                data={familyData.covers}
                resize_image={1000}
              />
            </div>

            {isMyProfile && (
              <ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0 profile-action">
                <li className="rounded pointer text-center">
                  <DropdownModal
                    label={
                      <div className="me-2">
                        <VIcon name="edit-page.svg" />
                      </div>
                    }
                    menu={[
                      {
                        key: t("update_cover"),
                        onClick: (_) => handleClick("update_cover"),
                      },
                    ]}
                  />
                </li>
              </ul>
            )}
          </div>

          <div className="user-detail text-center mb-3">
            <div className="profile-img">
              <div className="img-fluid d-flex justify-content-center">
                <Avatar
                  isEdit={isMyProfile}
                  hideName={true}
                  size={"100px"}
                  userData={familyData}
                  handleUpload={handleUploadAvatar}
                />
              </div>
            </div>
            <div className="profile-detail">
              <h3>
                <span>{familyData?.name}</span>
              </h3>
            </div>
          </div>

          {/* <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
            <div className="social-links">
              <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                <li className="text-center pe-3">
                  <Link to="#">
                    <img
                      loading="lazy"
                      src={img3}
                      className="img-fluid rounded"
                      alt="facebook"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
        </div>
      </Card.Body>

      <VModal
        title={t(action)}
        isOpen={openModal}
        loading={loading}
        centered={true}
        children={renderContentModal(action)}
        onCancel={() => setOpenModal(false)}
        onConfirm={submit}
      />
    </Card>
  );
};

export default View;

import VButton from "components/v-button";
import VInput from "components/v-input";
import useTranslation from "hooks/useTranslation";
import { Form } from "react-bootstrap";
import { v4 as uuidv4 } from "uuid";

const WebsiteSetting = ({ state, setState }) => {
  const { t } = useTranslation();

  const handleChangeSecondarySite = (value, name, id) => {
    setState((prev) => ({
      ...prev,
      secondary_site: [...prev?.secondary_site]?.map((el, idx) =>
        el?.id === id ? { ...el, [name]: value } : el
      ),
    }));
  };

  const handleRemoveWebsite = (id) => {
    setState((prev) => ({
      ...prev,
      secondary_site: [...prev?.secondary_site]?.filter((el) => el?.id !== id),
    }));
  };

  const handleAddWebsite = () => {
    setState((prev) => ({
      ...prev,
      secondary_site: [
        ...(prev?.secondary_site || []),
        { id: uuidv4(), name_website: "", value: "" },
      ],
    }));
  };

  return (
    <>
      {state?.secondary_site?.length > 0 &&
        state.secondary_site.map((web) => (
          <div key={web?.id} className="d-flex flex-wrap">
            <div className="col-12 d-flex justify-content-end mb-1">
              <VButton
                className="btn btn-primary py-0"
                onClick={() => handleRemoveWebsite(web?.id)}
              >
                {t("Remove")}
              </VButton>
            </div>
            <Form.Group className="form-group col-12 col-md-4">
              <VInput
                label={`${t("Name Website")}`}
                type="text"
                id="name_website"
                name="name_website"
                defaultValue={web?.name_website}
                onChange={(e) =>
                  handleChangeSecondarySite(
                    e.target.value,
                    "name_website",
                    web?.id
                  )
                }
              />
            </Form.Group>
            <Form.Group className="form-group col-12 col-md-8 ps-md-2">
              <VInput
                label={`${t("Website")}`}
                type="text"
                id="website"
                name="website"
                defaultValue={web?.value}
                onChange={(e) =>
                  handleChangeSecondarySite(e.target.value, "value", web?.id)
                }
              />
            </Form.Group>
          </div>
        ))}
      <Form.Group className="form-group col-12">
        <VButton className={`py-0 px-2`} onClick={() => handleAddWebsite()}>
          {t("Add website")}
        </VButton>
      </Form.Group>
    </>
  );
};

export default WebsiteSetting;

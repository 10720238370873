import React from "react";
import { Route, Switch } from "react-router-dom";
import Login from "pages/auth/login";
import Register from "pages/auth/register";
import VerifyEmail from "pages/auth/verify-email";
import VerifyLogin from "pages/auth/verify-login";
import ForgotPassword from "pages/auth/forgot-password";
import ResetPassword from "pages/auth/reset-password";
import FastRegister from "pages/auth/fast-register";
import LoginQr from "pages/auth/login-qr";

import { ROUTES } from "config/routes";


function AuthRouter({
  setOptions
}) {
  return (
    <Switch>
      <Route path={[ROUTES.LOGIN_CALLBACK, ROUTES.LOGIN]} component={Login} />
      <Route
        path={[ROUTES.REGISTER_CALLBACK, ROUTES.REGISTER]}
      >
        <Register setOptions={setOptions} />
      </Route>
      <Route path={ROUTES.VERIFY_EMAIL} component={VerifyEmail} />
      <Route path={ROUTES.VERIFY_LOGIN} component={VerifyLogin} />
      <Route path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
      <Route path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
      <Route path={ROUTES.FAST_REGISTER} component={FastRegister} />
      <Route path={ROUTES.LOGIN_QR} component={LoginQr} />
    </Switch>
  );
}

export default AuthRouter;

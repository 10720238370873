/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
// import UploadFile from "components/uploadFile";
import VIcon from "components/v-icon";
import VNameVerify from "components/v-objects/v-name-verify";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getFrameUseDefault,
  getObjects,
} from "redux/reducers/settings/settings.selector";
import {
  checkIsAuth,
  checkSrcIsVideo,
  getUrlImage,
  getUrlProfile,
} from "utils/common";
import FsLightbox from "fslightbox-react";
import DropdownModal from "components/v-dropdown-modal/View";
import useTranslation from "hooks/useTranslation";

import { useAppSelector } from "redux/reducers/hook";
import { getLocations } from "redux/reducers/settings/settings.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";

type IProps = {
  className?: string;
  isEdit?: boolean;
  isPreview?: boolean;
  hideName?: boolean;
  userData?: any;
  additionalData?: any;
  size?: string;
  fontSize?: string;
  fontWeight?: string;
  spacingImgWithName?: string;
  isClick?: boolean;
  iconVerify?: any;
  fontSizeIconVerify?: number;
  show_name_custom?: string;
  rezise?: number;
  statusOnline?: string;
  onClick?: (_?: any) => any;
  onClickAvatar?: (_?: any) => any;
  onClickName?: (_?: any) => any;
  handleClickType?: (e?: any) => any;
  handleUpload?: (e?: any) => any;
};

const View = (props: IProps) => {
  const {
    className,
    isEdit,
    isPreview,
    hideName,
    userData,
    additionalData,
    size,
    fontSize,
    fontWeight,
    isClick,
    iconVerify,
    fontSizeIconVerify,
    show_name_custom,
    rezise,
    spacingImgWithName = "15px",
    handleUpload,
    handleClickType,
    onClickName,
    onClickAvatar,
  } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const objects = useSelector(getObjects);
  const locations = useAppSelector(getLocations);
  const frameDefault = useAppSelector(getFrameUseDefault);
  const profile = useAppSelector(getUserProfile);
  const isAuth = checkIsAuth(userData, profile._id);

  const frameAvatarDefault = useMemo(() => {
    const frameAvatar = frameDefault?.find(
      (el) => el.frame_type === "frame_avatar"
    );
    if (frameAvatar) {
      return frameAvatar.image;
    }
    return null;
  }, [frameDefault]);

  const [listLightbox, setListLightbox] = useState({
    toggler: true,
    slide: 1,
    list: [],
  });

  const handleClick = () => {
    if (userData && userData._id && isClick) {
      history.push(getUrlProfile(userData));
      props.onClick && props.onClick();
    }
  };

  const handlePreview = (data: any[]) => {
    if (isPreview)
      setListLightbox({
        ...listLightbox,
        toggler: !listLightbox.toggler,
        list: data,
      });
  };

  const handleEditByType = (type: string) => {
    handleClickType(type);
  };

  const avatar = useMemo(() => {
    if (userData) {
      if (userData.avatar) {
        return userData.avatar;
      } else {
        const object = objects.find(
          (o: { key: any }) => o.key === userData.object_type
        );
        if (object) return object.avatar;
      }
    }
    return "";
  }, [userData]);

  const country = useMemo(() => {
    if (userData && userData.profile) {
      const _country = userData.profile.location?.country;
      if (_country) {
        return locations?.find(
          (location: { _id: any }) => location._id === _country
        );
      }
      return locations?.find(
        (location: { default: boolean }) => location.default
      );
    }
    return null;
  }, [userData, locations]);

  return (
    <>
      <FsLightbox
        toggler={listLightbox.toggler}
        sources={listLightbox?.list?.map((img) => getUrlImage(img))}
        slide={listLightbox.slide}
      />
      <div className={`header-title ${className ? className : `m-auto`}`}>
        <div className="user-acc-header">
          <div
            className="avatar"
            style={{
              width: size || "100%",
              height: size || "100%",
            }}
          >
            <div
              className="avatar-detail d-flex align-items-center justify-content-center m-0"
              onClick={(_) => {
                handleClick();
                onClickAvatar && onClickAvatar();
              }}
            >
              <div
                className="avatar-img-pluid"
                onClick={() => handlePreview([avatar])}
              >
                {checkSrcIsVideo(avatar) ? (
                  <video
                    className="rounded"
                    src={getUrlImage(avatar)}
                    width="auto"
                    height="100%"
                    muted
                    autoPlay={true}
                  >
                    Your browser does not support HTML video.
                  </video>
                ) : (
                  <img
                    src={
                      getUrlImage(avatar, rezise || 150) ||
                      "/assets/images/VDB-64.png"
                    }
                    alt=""
                  />
                )}
              </div>
              {frameAvatarDefault ? (
                <div
                  className="avatar-img-pluid"
                  onClick={() => handlePreview([avatar])}
                >
                  <img
                    src={getUrlImage(frameAvatarDefault, rezise || 150)}
                    alt=""
                  />
                </div>
              ) : (
                userData?.frame && (
                  <div
                    className="avatar-img-pluid"
                    onClick={() => handlePreview([avatar])}
                  >
                    <img
                      src={getUrlImage(userData?.frame?.image, rezise || 150)}
                      alt=""
                    />
                  </div>
                )
              )}
            </div>
            {country && (
              <img
                className="national_flag"
                src={getUrlImage(country?.icon, 300)}
                alt=""
              />
            )}
            {userData?.profile?.feelings && (
              <img
                className="_feelings"
                src={getUrlImage(userData?.profile?.feelings?.image, 50)}
                alt=""
              />
            )}
            {iconVerify && <div className="icon-verify">{iconVerify}</div>}
            {isEdit && (
              <div className="avatar-upload">
                <DropdownModal
                  label={
                    <div className="avatar-upload">
                      <label>
                        <VIcon name="camera.svg" width={22} />
                      </label>
                    </div>
                  }
                  menu={[
                    {
                      key: t("Upload photos"),
                      isUpload: true,
                      name: "avatar",
                      onClick: (e: any) => handleUpload(e),
                    },
                    {
                      key: t("Choose a photo"),
                      onClick: (_: any) => handleEditByType("choose_image"),
                    },
                    {
                      key: t("Avatar frame"),
                      onClick: (_: any) => handleEditByType("edit_frame"),
                    },
                  ]}
                />
              </div>
              // <div className="avatar-upload">
              //   <label htmlFor="upload-background">
              //     <VIcon name="camera.svg" width={22} />
              //   </label>
              //   <div hidden={true}>
              //     <UploadFile
              //       id="upload-background"
              //       typeUpload={"image"}
              //       setFile={(file: any) => handleUpload(file)}
              //     />
              //   </div>
              // </div>
            )}
            {isAuth && profile?.dataKyc?.key === "image_avatar" && (
              <img
                className="_img_kyc_avatar"
                src={getUrlImage(profile?.dataKyc?.value, 50)}
                alt=""
              />
            )}
          </div>
          {(!hideName || additionalData) && (
            <div
              className={`info-user position-relative ${
                isClick ? "cursor-pointer" : ""
              }`}
              style={{
                maxWidth: `calc(100% - ${size} - ${spacingImgWithName})`,
                marginLeft: spacingImgWithName,
              }}
              onClick={(_) => {
                handleClick();
              }}
            >
              {!hideName && (
                <h3 className="mb-0 d-inline-block">
                  {show_name_custom ? (
                    show_name_custom
                  ) : (
                    <VNameVerify
                      onClickName={onClickName}
                      className={`mb-0 d-flex align-items-center ${
                        onClickName ? "cursor-pointer" : ""
                      }`}
                      fontSize={fontSize}
                      fontWeight={fontWeight}
                      fontSizeIconVerify={fontSizeIconVerify}
                      dataUser={userData}
                    />
                  )}
                </h3>
              )}
              {additionalData && <div className="mt-0">{additionalData}</div>}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default View;

import { Radio, RadioChangeEvent } from "antd";

interface IProps {
  onChange?: any;
  label?: string;
  value?: any;
  options?: any;
  [key: string]: any;
}

const View = (props: IProps) => {
  const { options, className, defaultValue, value, onChange } = props;
  
  const onChangeRadio = (e: RadioChangeEvent) => {
    onChange(e.target.value);
  };

  return (
    <Radio.Group
      className={className || ""}
      name="radiogroup"
      defaultValue={defaultValue}
      value={value}
      onChange={onChangeRadio}
    >
      {options?.map((el: any, index: number) => (
        <Radio key={index} value={el?.value} disabled={el?.disabled}>
          {el.label}
        </Radio>
      ))}
    </Radio.Group>
  );
};

export default View;

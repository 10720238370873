/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { Row, Col, Container, Nav, Tab } from "react-bootstrap";
import Card from "components/v-teamplate/Card";

import VBannerPage from "components/v-teamplate/v-banner-page";
import useTranslation from "hooks/useTranslation";
import Users from "./Users";
import VDropdown from "components/v-dropdown";
import VInput from "components/v-input";
import VIcon from "components/v-icon";
import debounce from "lodash.debounce";

const View = () => {
  const { t } = useTranslation();

  const [sortBy, setSortBy] = useState("");
  const [search, setSearch] = useState("");

  const handleSearch = (e) => {
    setSearch(e.target.value);
  };

  const debouncedChangeHandler = useCallback(debounce(handleSearch, 1000), []);

  return (
    <>
      <VBannerPage position="banner_top_rankings" />
      <Container>
        <Row>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Col lg={12}>
              <Card>
                <Card.Body className="p-0">
                  <div className="user-tabing p-3">
                    <div className="d-flex flex-wrap align-items-center justify-content-between">
                      <Nav
                        variant="pills"
                        className="col-md-6 d-flex align-items-center text-center profile-forum-items p-0 mb-3"
                      >
                        <Col lg={3} className=" p-0">
                          <Nav.Link eventKey="first" role="button">
                            {t("User")}
                          </Nav.Link>
                        </Col>
                        <Col lg={3} className=" p-0">
                          <Nav.Link eventKey="second" role="button">
                            {t("Post")}
                          </Nav.Link>
                        </Col>
                      </Nav>
                      <div className="col-md-3 pe-2">
                        <div className="form-group">
                          <VInput
                            label={t("Search by code")}
                            type="text"
                            id="code_vdb"
                            name="code_vdb"
                            onChange={debouncedChangeHandler}
                            suffix={<VIcon width={16} name="search.svg" />}
                          />
                        </div>
                      </div>
                      <div className="col-md-3">
                        <VDropdown
                          placeholder={t("SortBy")}
                          value={sortBy}
                          options={[
                            { label: t("Refer"), value: "refers" },
                            { label: t("Friend"), value: "friend" },
                            { label: t("Follow"), value: "follower" },
                          ]}
                          onChange={(e: any) => setSortBy(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col sm={12}>
              <Tab.Content className="forum-content">
                <Tab.Pane eventKey="first">
                  <Card>
                    <Card.Body className="p-0">
                      <Users sortBy={sortBy} search={search} />
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
        </Row>
      </Container>
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { useAppSelector } from "redux/reducers/hook";
// import { getMenus } from "redux/reducers/settings/settings.selector";
import VWebView from "components/v-web-view";
import { getLocationCurrent, getMenus } from "redux/reducers/settings/settings.selector";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";

/* eslint-disable jsx-a11y/iframe-has-title */
const View = (props) => {
  const { minHeight } = props;
  const { key } = useParams();
  const menus = useAppSelector(getMenus);
  const location_current = useAppSelector(getLocationCurrent);

  const [url, setUrl] = useState("");
  const [widthScrollbar, setWidthScrollbar] = useState(0);

  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (key) {
      const menu = menus.find((m) => m.key === key);
      if (menu) {
        let url = menu.url;

        if (menu.key.includes('map')  && moduleSessionId && fromSessionUid) {
          const lat = location_current?.lat ?? '';
          const lng = location_current?.long ?? '';
          url += `&query_lat=${lat}&query_lng=${lng}&origin=${window.origin}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}`;
        }

        // setUrl(menu?.url.concat(`&vdb_token=${localStorage.getItem("auth")}`));
        // setWidthScrollbar(menu?.width_scrollbar || 0);
        setUrl(url);
        setWidthScrollbar(menu.width_scrollbar || 0);
      }
    }
  }, [key, menus, moduleSessionId, fromSessionUid]);

  return (
    <Container>
      {url && (
        <Row>
          <Col lg={12}>
            <VWebView
              className="iframe-webview-home"
              url={url}
              widthScrollbar={widthScrollbar}
              height={minHeight || "calc(100vh - 66px)"}
            />
          </Col>
        </Row>
      )}
    </Container>
  );
};

export default View;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import APIs from "api";
import { useAppDispatch } from "redux/reducers/hook";
import { getObjectsAsync } from "redux/reducers/settings/settings.action";
import useTranslation from "hooks/useTranslation";
import VForm from "components/v-form";
import VButton from "components/v-button";
import FromTemplateService from "api/form-templates";
import Card from "components/v-teamplate/Card";
import VTimeline from "components/v-timeline";
import { Container } from "react-bootstrap";

const defaultState = {
  status: "",
  note: "",
};

const KYC = ({ dataUser }) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const [kyc, setKYC] = useState<any>(defaultState);

  const [isSavingKYC, setIsSavingKYC] = useState(false);
  const [listFiles, setListFiles] = useState<any>({});

  const [additionalData, setAdditionalData] = useState({});
  const [validate, setValidate] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);
  const [formAdditionalData, setFormAdditionalDataKYC] = useState<any>({});
  const [status, setStatus] = useState("init");
  const [isPolicy, setIsPolicy] = useState(false);

  useEffect(() => {
    dispatch(getObjectsAsync());
  }, []);

  useEffect(() => {
    if (dataUser) {
      getKyc();
      getFormTemplateKyc(dataUser.object_type);
    }
  }, [dataUser]);

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const getKyc = async () => {
    try {
      const res: any = await APIs.KYC.getKycByObject({
        user_id: dataUser?._id,
      });
      if (res) {
        setKYC(res.kyc);
        setStatus(res.kyc.status);
      }
    } catch (error) {}
  };

  const getFormTemplateKyc = async (objetc) => {
    try {
      const res: any = await FromTemplateService.getForm({
        query: {
          type: "kyc",
          object_type: objetc,
        },
      });
      if (res) {
        setFormAdditionalDataKYC(res);
      }
    } catch (error) {
      setFormAdditionalDataKYC(null);
    }
  };

  useEffect(() => {
    const check = () => {
      const listRequied = formAdditionalData?.fields?.filter(
        (el) => el.required
      );

      for (let index = 0; index < listRequied?.length; index++) {
        const item = listRequied[index];
        if (!additionalData[item.label] && !listFiles[item.label]) {
          return false;
        }
      }
      return true;
    };
    setIsValid((_) => check());
  }, [additionalData, listFiles]);

  const handleSaveKYC = async () => {
    setIsSavingKYC(true);

    try {
      let listImg;
      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${dataUser?._id}`);

        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
      }
      let data = {
        object: dataUser?.object_type,
        ...additionalData,
      };

      if (listImg && listImg?.length > 0) {
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              data = {
                ...data,
                [item]: el?.filePath,
              };
            }
          });
        });
      }

      try {
        const kyc: any = await APIs.KYC.createKycByObject({
          user_id: dataUser?._id,
          body: {
            ...data,
            method: "kyc",
          },
        });
        if (kyc) {
          toast.success(t("Successfull!"));
          setListFiles({});
          getKyc();
        }
      } catch (err) {
        toast.error(t(err?.errors));
      }
    } catch (err) {
      toast.error(t(err?.errors || "Something went wrong!"));
    }
    setIsSavingKYC(false);
  };

  const renderListRequest = (form) => {
    return (
      form &&
      form.fields?.length > 0 &&
      form.fields?.map((el, index) => <li key={index}>{t(el.label)}</li>)
    );
  };

  const getInfoStep = (step) => {
    switch (step) {
      case 0:
        return {
          title: t("Verified"),
          bgColorTime: "#3498db33",
          colorTime: "#4284F3",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">
                    {t("Update personal information")}
                  </h6>
                  <span className="fw-600">- {t("Verified")}:</span>
                  <ul>
                    <li>{t("Personal information")}</li>
                    <li>{t("Personal address")}</li>
                    <li>{`${t("Personal")} ${t("Business")}`}</li>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      case 1:
        return {
          title: t("Verified"),
          bgColorTime: "#3498db33",
          colorTime: "#4284F3",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">
                    {t("Verify email and phone number")}
                  </h6>
                  <span className="fw-600">- {t("Verified")}:</span>
                  <ul>
                    <li>{t("Number phone")}</li>
                    <li>{t("Email")}</li>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      case 2:
        const isKyc =
          status !== "init" &&
          status !== "pending_kyc" &&
          status !== "declined";
        return {
          title: isKyc ? t("Verified") : t("Not verified"),
          bgColorTime: isKyc ? "#3498db33" : "",
          colorTime: isKyc ? "#4284F3" : "",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">{t("kyc_information")}</h6>
                  <span className="fw-600">
                    - {status === "kyc" ? t("Verified") : t("Request")}:
                  </span>
                  <ul>{renderListRequest(formAdditionalData)}</ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      default:
        return {
          title: "",
          children: <></>,
        };
    }
  };
  const getColorVerify = (type, status) => {
    if (type === "verify")
      switch (status) {
        case "pending_verified":
          return "pending";
        case "declined_verified":
          return "declined";
        case "verified":
          return "success";
        default:
          return "gray";
      }
    else
      switch (status) {
        case "pending_kyc":
          return "pending";
        case "kyc":
          return "success";
        case "pending_verified":
          return "success";
        case "verified":
          return "success";
        case "declined_verified":
          return "success";
        default:
          return "gray";
      }
  };

  return (
    <Container>
      <Card>
        <div className="row p-4">
          <div className="upload-kyc col-12 col-md-8">
            {status === "pending_kyc" ? (
              <div className="text-center">
                {t("Your request is being approved")}
              </div>
            ) : status === "kyc" ? (
              <div className="text-center">{t("Congratulations verify")}</div>
            ) : (
              <>
                {formAdditionalData &&
                  formAdditionalData.fields?.length > 0 && (
                    <div className="row">
                      <VForm
                        // colItem={"col-6"}
                        fields={formAdditionalData?.fields}
                        additionalData={additionalData}
                        listFiles={listFiles}
                        validate={validate}
                        setListFiles={setListFiles}
                        setValidate={setValidate}
                        setIsValid={setIsValid}
                        setFile={setFile}
                        setAdditionalData={setAdditionalData}
                      />
                    </div>
                  )}
                {kyc?.status === "declined" && kyc?.note && (
                  <div className="note border rounded p-2 mb-2">
                    {t("Note")}:
                    <div
                      className="mb-0"
                      dangerouslySetInnerHTML={{
                        __html: kyc?.note,
                      }}
                    ></div>
                  </div>
                )}
                <div className="submit-kyc mb-3">
                  <VButton
                    onClick={handleSaveKYC}
                    loading={isSavingKYC}
                    disabled={!isValid || !isPolicy}
                  >
                    {t("Save")}
                  </VButton>
                </div>
                <div className="d-flex">
                  {formAdditionalData?.attachments?.enable && (
                    <>
                      <div>
                        <input
                          className="form-check-input me-2"
                          type="checkbox"
                          onChange={(e) => setIsPolicy(e.target.checked)}
                        />
                      </div>
                      <div
                        className="mb-0 "
                        dangerouslySetInnerHTML={{
                          __html: formAdditionalData?.attachments?.guide,
                        }}
                      ></div>
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          <div className="col-12 col-md-4">
            <VTimeline
              data={Array(3)
                .fill(0)
                .map((el, index) => ({
                  icon: (
                    <img
                      src={`/assets/icons/default/verify-${
                        index === 0 || index === 1
                          ? "success"
                          : getColorVerify(
                              index === 2 ? "kyc" : "verify",
                              status
                            )
                      }.svg`}
                      alt=""
                    />
                  ),
                  ...getInfoStep(index),
                }))}
            />
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default KYC;

import { ROUTES } from "config/routes";
import Wallet from "./Wallet";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import VBannerPage from "components/v-teamplate/v-banner-page";
import PageBackgrounds from "components/v-page-background";

const View = () => {
  const auth = useAppSelector(getUserProfile);

  return (
    <>
      <VBannerPage position="banner_top_wallets" />
      <PageBackgrounds page={"page_bg_wallets"} />
      <Wallet url={ROUTES.MY_WALLETS} userId={auth._id} />
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import useQuery from "hooks/useQuery";
import React, { useState, useEffect } from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import VWebView from "components/v-web-view";
import ComingSoon from "pages/coming-soon/comingsoon";
import VTab from "components/v-tab";

import ModalCreate from "pages/social/object-details/modal-create";
import { OBJECT_TYPE } from "config/enums";
import VBannerPage from "components/v-teamplate/v-banner-page";
import PageBackgrounds from "components/v-page-background";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";
import VInput from "components/v-input";
import VIcon from "components/v-icon";
import VRightView from "components/v-right-view";
import ManageChannel from "./manage";
import { getManageChannels } from "redux/reducers/channels/channels.selector";
import FollowingsChannel from "./followings";
import SuggestChannel from "./suggest";

const View = () => {
  const { t } = useTranslation();
  const menus = useAppSelector(getMenusByKey("tab_page"));
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null

  const [tab, setTab] = useState(null);
  const query = useQuery();
  const [loadMenu, setLoadMenu] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const channel: any = useAppSelector(getManageChannels);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  const renderTab = () => {
    if (tab && tab.type_view === "internal") {
      if (tab.key === "manage") return <ManageChannel />;
      if (tab.key === "suggest") return <SuggestChannel />;
      if (tab.key === "followings") return <FollowingsChannel />;
    }
    if (tab && tab.type_view === "webview") {
      return (
        <VWebView
          url={tab.url}
          widthScrollbar={tab?.width_scrollbar}
          height="700px"
        />
      );
    }
    return <ComingSoon />;
  };

  return (
    <div className="profile-wrapper">
      <ModalCreate
        objectType={OBJECT_TYPE.CHANNEL}
        openModal={openModal}
        setOpenModal={setOpenModal}
        // onSuccess={(res) => {
        //   setOpenModalInvite(true);
        //   setCreatedBusiness(res);
        // }}
      />
      <PageBackgrounds page={"page_bg_business"} />
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <VBannerPage position="banner_top_businesses" />
            <Card className="p-3">
              <Col sm={12}>
                <div className="mb-3 d-flex justify-content-between">
                  <Col sm={12} md={5}>
                    <VInput
                      transparent={true}
                      label={t("Channel")}
                      suffix={<VIcon width={16} name="search.svg" />}
                    />
                  </Col>
                  <VButton
                    disabled={channel?.docs?.length > 2}
                    onClick={() => setOpenModal(true)}
                  >
                    {t("Create")}
                  </VButton>
                </div>
                <VTab
                  tab={tab}
                  setTab={setTab}
                  menus={menus}
                  activeBorder="bottom"
                  noBackground={true}
                />
                {renderTab()}
              </Col>
            </Card>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_business"}
                positionBanner={"banner_right_business"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default View;

/* eslint-disable indent */
import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const search = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post("special-name/search-key", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const activated = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`special-name/activated?${qs.stringify(req?.query)}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSpeciallName = () =>
  new Promise((resolve, reject) => {
    request()
      .get("special-name/")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSpeciallNameByUserId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`special-name/own?${qs.stringify(req?.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SpecialNameService = {
  search,
	activated,
	getSpeciallName,
  getSpeciallNameByUserId,
};

export default SpecialNameService;

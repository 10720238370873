/* eslint-disable indent */
import { Route } from "react-router-dom";
import { ROUTES } from "config/routes";
import { POST_PLUGIN } from "config/enums";
import FeedsByPlugin from "./components/SearchFeedsByPlugin";

const FeedByPlugins = ({ component: Component, ...rest }) => {
  const feedTypes = [
    { path: ROUTES.SEARCH_IMAGE, plugin: POST_PLUGIN.IMAGE, type: "" },
    { path: ROUTES.SEARCH_FILE, plugin: POST_PLUGIN.FILE },
    { path: ROUTES.SEARCH_LOCATION, plugin: POST_PLUGIN.LOCATIONS },
    { path: ROUTES.SEARCH_EVENT, plugin: POST_PLUGIN.EVENT },
  ];

  const getType = (path: string) => {
    return feedTypes.find((e) => e.path === path)?.type || "";
  };

  const getPlugin = (path: string) => {
    return feedTypes.find((e) => e.path === path)?.plugin || "";
  };
  return (
    <Route
      {...rest}
      render={(props) => {
        return (
          <FeedsByPlugin
            type={getType(rest?.location?.pathname)}
            plugin={getPlugin(rest?.location?.pathname)}
          />
        );
      }}
    />
  );
};

export default FeedByPlugins;

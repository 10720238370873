/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React, { useState, useEffect, useContext, useMemo } from "react";
import {
  Dropdown,
  Nav,
  Card,
  Container,
  Offcanvas,
  CloseButton,
  // Form,
  Col,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Avatar from "components/v-avatar";

//Componets
import CustomToggle from "components/v-teamplate/dropdowns";
import VModal from "components/v-modal";
import { ROUTES } from "config/routes";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { theme_scheme } from "redux/reducers/setting-system/selectors";
import {
  getCityCurrent,
  getLocationCurrent,
  getLocations,
  getLogo,
  getMenus,
  getMenusByKey,
  getWeatherCurrent,
} from "redux/reducers/settings/settings.selector";
import PageBackgrounds from "components/v-page-background";
import {
  getUserAddressAndCareer,
  getUserProfile,
} from "redux/reducers/users/users.selector";
import useTranslation from "hooks/useTranslation";
import UserService from "api/users";
import Notification from "layouts/Notification";
import { logOut } from "redux/reducers/users/users.reducer";
import { isMobile } from "react-device-detect";
import FriendRequest from "layouts/FriendRequest";
import { enableChat, getUrlImage, getUrlProfile } from "utils/common";
import VIcon from "components/v-icon";
import Search from "layouts/Search";
import useQuery from "hooks/useQuery";
// import Quota from "components/v-quota";
import SwitchAccount from "./switch-account";
import axios from "axios";
import {
  setCity,
  setLocation,
  setWeather,
} from "redux/reducers/settings/settings.reducer";
import { isCommunity } from "utils/friends";
import { ACCOUNT_TYPE } from "config/enums";
import SearchService from "api/search";
import NewsFeedContext from "context/NewsFeedContext/View";
import { toast } from "react-toastify";
import PostService from "api/posts";
import SearchLocation from "./components/SearchLocation";
import { getGeneral } from "redux/reducers/settings/settings.selector";
import { useLocation } from "react-router-dom";
import Marquee from "react-fast-marquee";
import PageBackgroundService from "api/page-backgrounds";
import { getIframeUrlChat } from "utilities/getUrlChat";
import ConversationsService from "api/conversations";

const Header = () => {
  const { t, lang } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const theme = useAppSelector(theme_scheme);
  const logo = useAppSelector(getLogo);
  const userData = useAppSelector(getUserProfile);
  const query = useQuery();
  const news = useAppSelector(getUserAddressAndCareer);
  const [openModalSwitch, setOpenModalSwitch] = useState(false);
  const minisidebar = () => {
    document
      .getElementsByTagName("ASIDE")[0]
      ?.classList?.toggle("sidebar-mini");
  };
  const [show1, setShow1] = useState(false);
  const [show, setShow] = useState(false);
  const [showSearchMobile, setShowSearchMobile] = useState(false);
  const [search, setSearch] = useState("");
  const location = useAppSelector(getLocationCurrent);
  const locationAll = useAppSelector(getLocations);

  const _logo = logo[`logo_${theme}`];
  const _logoMobile = logo[`logo_mobile_${theme}`];

  const weather = useAppSelector(getWeatherCurrent);
  const city = useAppSelector(getCityCurrent);
  const [topTrending, setTopTrending] = useState([]);
  const searchMenus = useAppSelector(getMenus)?.filter(
    (e) => e?.position === "search_option"
  );
  const _menuHeader = useAppSelector(getMenusByKey("mobile_header_top"));
  const locations = useLocation();
  const isConversation = String(locations?.pathname)?.includes("conversations");
  const [googleKey, setGoogleKey] = useState("");
  const generals = useAppSelector(getGeneral);
  const [background, setBackground] = useState([]);
  const [unreadMessage, setUnreadMessage] = useState(0);

  const country = useMemo(() => {
    if (userData && userData.profile) {
      const _country = userData.profile.location?.country;
      if (_country) {
        return locationAll?.find(
          (location: { _id: any }) => location._id === _country
        );
      }
      return locationAll.find(
        (location: { default: boolean }) => location.default
      );
    }
    return null;
  }, [userData, locationAll]);

  useEffect(() => {
    setGoogleKey(
      generals?.site?.find((el: any) => el.key === "google_map_api_key")
        ?.content
    );
  }, [generals]);

  const [isSearchLocation, setSearchLocation] = useState(false);
  const newsFeedContext = useContext(NewsFeedContext);

  const [showDropdown, setShowDropdown] = useState(false);
  const [showDropdownOption, setShowDropdownOption] = useState(false);
  const [selectedSearchOption, setSelectedSearchOption] = useState(
    searchMenus[0] || {
      name: "Everybody",
      url: ROUTES.SEARCH_EVERYBODY,
    }
  );

  const [textSearch, setTextSearch] = useState(query?.search);

  const getMyConversationUnread = async () => {
    try {
      const res: any = await ConversationsService.getMyConversationUnread();

      if (res?.total) {
        setUnreadMessage(res?.total);
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (query.search && query.search?.length > 1) {
      setTextSearch(query.search);
    } else {
      // setTextSearch("");
      // toastMinLength();
    }
  }, [query.search]);

  useEffect(() => {
    if (
      window.location.pathname.includes("/search") &&
      !window.location.pathname.includes(ROUTES.SEARCH_LOCATION) &&
      !window.location.pathname.includes(ROUTES.SEARCH_HASHTAG) &&
      selectedSearchOption?.url
    ) {
      history.push(`${selectedSearchOption?.url}?search=${query.search || ""}`);
    }
  }, [selectedSearchOption]);

  useEffect(() => {
    try {
      navigator.geolocation.getCurrentPosition(function (position) {
        const userCoordinate = userData.profile?.coordinate;
        dispatch(
          setLocation({
            long: position.coords.longitude,
            lat: position.coords.latitude,
          })
        );
        if (
          !userCoordinate ||
          (Number(userCoordinate.lat) !== position.coords.latitude &&
            Number(userCoordinate.lng) !== position.coords.longitude)
        ) {
          UserService.updateCoordinate({
            body: {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            },
          });
        }
      });

      //get post 1 year anniversary
      PostService.sharedOldPost();
    } catch (error) {}
    getBackground();
    getMyConversationUnread();
    if (isMobile) {
      minisidebar();
    } else {
      setShowSearchMobile(false);
    }
  }, []);

  const viewSearchOption = [...searchMenus];

  const getTopTrending = async (key) => {
    const res: any = await SearchService.trending({
      query: {
        key,
      },
    });

    if (res && res.data) {
      setTopTrending([...res.data]);
    }
  };
  const getBackground = async () => {
    try {
      const res: any = await PageBackgroundService.getPageBackgrounds({
        params: {
          position: "page_bg_header",
        },
      });
      if (res) setBackground(res);
    } catch (error) {}
  };

  const redirectToSearch = (key) => {
    if (key && key?.includes("#")) {
      return `/search/hashtag?search=${key?.substr(1)}`;
    } else {
      return `${selectedSearchOption?.url}?search=${key}`;
    }
  };

  useEffect(() => {
    if (location && location.long > 0 && !weather) {
      getWeather();
    }
  }, [location, weather]);

  useEffect(() => {
    // if (window.location.pathname === "/") {
    //   PostService.sharedOldPost();
    // }
    if (
      window.location.pathname.includes("/search") &&
      searchMenus
      // !window.location.pathname.includes(ROUTES.SEARCH_LOCATION) &&
      // !selectedSearchOption?.url.includes(ROUTES.SEARCH_LOCATION)
    ) {
      if (
        !selectedSearchOption?.url &&
        !window.location.pathname.includes(ROUTES.SEARCH_LOCATION)
      ) {
        setSelectedSearchOption(searchMenus[0]);
      }
      let findSelectOption = searchMenus?.find(
        (e) => e?.url === window.location.pathname
      );
      if (findSelectOption?.url) {
        setSelectedSearchOption(findSelectOption);
      }
    }
    if (
      textSearch &&
      !window.location.pathname.includes(ROUTES.SEARCH_LOCATION)
    ) {
      history.push(`?search=${textSearch}`);
    }
    setHiddenDDL();
  }, [window.location.pathname]);

  const setHiddenDDL = () => {
    setShowDropdown(false);
    setShowDropdownOption(false);
  };

  const getWeather = async () => {
    try {
      const res = await axios.get(
        `https://api.openweathermap.org/data/2.5/weather?lat=${
          location.lat
        }&lon=${location.long}&units=metric&appid=${
          process.env.REACT_APP_WEATHER_KEY ||
          "bd5e378503939ddaee76f12ad7a97608"
        }&lang=vi`
      );
      if (res && res.data) {
        const result = res.data;
        if (result && result.weather) {
          dispatch(setWeather(result));
          try {
            const resCity = await axios.get(
              `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${location.lat}&longitude=${location.long}&localityLanguage=en`
            );
            if (resCity && resCity.data) {
              dispatch(setCity(resCity.data.city));
            }
          } catch (error) {}
        }
      }
    } catch (error) {}
  };

  const logoutUser = () => {
    UserService.deleteToken({
      body: {
        user_id: userData._id,
        token: localStorage.getItem("firebaseToken"),
      },
    });
    localStorage.removeItem("auth");
    dispatch(logOut({}));
    history.push(ROUTES.LOGIN);
    window.location.reload();
  };

  useEffect(() => {
    setSearchLocation(
      selectedSearchOption?.url?.includes(ROUTES.SEARCH_LOCATION)
    );
  }, [selectedSearchOption]);

  useEffect(() => {
    if (showDropdown) {
      getTopTrending(textSearch || "");
    }
  }, [showDropdown]);

  const handleClickItem = (url) => {
    history.push(url);
  };

  const redirectToHome = () => {
    newsFeedContext.setTimeReloaded(Date.now());
    history.push(ROUTES.HOME);
    // window.location.href = ROUTES.HOME;
  };

  const handleClose = () => setShow(false);

  // const onFilter = (value) => {
  //   setSearch(value);
  // };

  // const onSearch = useCallback(debounce(onFilter, 1000), []);

  const toastMinLength = () => {
    toast.error(t("Please enter more than 2 character to search!"));
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      if (textSearch?.length > 1) {
        history.push(`?search=${textSearch}`);
      } else {
        toastMinLength();
        return;
      }
      if (window.location.pathname.includes(ROUTES.SEARCH_TREND)) {
        getTopTrending(event.target.value);
      }
      if (!window.location.pathname.includes("/search")) {
        history.push(`${selectedSearchOption?.url}?search=${textSearch || ""}`);
      }
      setHiddenDDL();
    }
  };

  const setDropdown = () => {
    setShowDropdown(!showDropdown);
    if (showDropdownOption) {
      setShowDropdownOption(false);
    }
  };

  const setDropdownOption = () => {
    setShowDropdownOption(!showDropdownOption);
    if (showDropdown) {
      setShowDropdown(false);
    }
  };

  const selectLocation = (location) => {
    // setTextSearch(placeOption?.label);
    // let location = placeOption?.placeDetail?.geometry?.location?.toJSON();
    history.push(
      `/search/location?lat=${JSON.stringify(
        location?.lat
      )}&lg=${JSON.stringify(location?.lng)}`
    );
  };

  const renderProfileHeader = (isMobile?: boolean, sizeAvatar?: string) => {
    return (
      <Dropdown
        as="li"
        className={`nav-item  ${isMobile ? "d-lg-none" : "d-none d-lg-block"}`}
      >
        <Dropdown.Toggle
          href="#"
          as={CustomToggle}
          variant="d-flex align-items-center"
        >
          <Avatar
            size={sizeAvatar || "40px"}
            fontSize={"12px"}
            userData={userData}
            rezise={50}
            hideName={true}
          />
        </Dropdown.Toggle>
        <Dropdown.Menu className="sub-drop caption-menu">
          <Card className="shadow-none m-0">
            <Card.Header className="p-0">
              <Dropdown.Item>
                <Link
                  onClick={() => handleClickItem(getUrlProfile(userData))}
                  className="d-flex align-items-center iq-sub-card border-0"
                >
                  <Avatar
                    className="m-0"
                    size={sizeAvatar || "30px"}
                    fontSize={"14px"}
                    rezise={50}
                    userData={userData}
                  />
                </Link>
              </Dropdown.Item>
            </Card.Header>
            <Card.Body className="p-0 ">
              <Dropdown.Item>
                {" "}
                <Link
                  onClick={() => handleClickItem(ROUTES.SETTING_GENERAL)}
                  className="mb-0 h6"
                >
                  <div className="d-flex align-items-center iq-sub-card border-0">
                    <VIcon name="setting.svg" />
                    <div className="ms-3">{t("Account settings")}</div>
                  </div>
                </Link>
              </Dropdown.Item>
              <Dropdown.Item>
                <div
                  className="d-flex align-items-center iq-sub-card"
                  onClick={() => setOpenModalSwitch(true)}
                >
                  <VIcon name="switch.svg" />
                  <div className="ms-3">
                    <a className="mb-0 h6">{t("Account switch")}</a>
                  </div>
                </div>
              </Dropdown.Item>
              <Dropdown.Item>
                {" "}
                <Link
                  onClick={() =>
                    handleClickItem(`${ROUTES.SUPPORTS}?form_support=true`)
                  }
                  className="mb-0 h6"
                >
                  <div className="d-flex align-items-center iq-sub-card border-0">
                    <VIcon name="support.svg" />
                    <div className="ms-3">{t("Support center")}</div>
                  </div>
                </Link>
              </Dropdown.Item>
              {userData &&
                isCommunity(userData.object_type) &&
                [ACCOUNT_TYPE.COUNTRY, ACCOUNT_TYPE.PROVINCE].includes(
                  userData.account_type
                ) && (
                  <Dropdown.Item>
                    <div
                      className="d-flex align-items-center iq-sub-card"
                      onClick={() => {
                        window.open(
                          `${
                            process.env.REACT_APP_CENSOR_URL ||
                            "https://censor.vdiarybook.com"
                          }/login?token=${localStorage.getItem("auth")}`
                        );
                      }}
                    >
                      <VIcon name="settings/dashboard.svg" />
                      <div className="ms-3">
                        <a className="mb-0 h6">{t("Dashboard censor")}</a>
                      </div>
                    </div>
                  </Dropdown.Item>
                )}
              <Dropdown.Item>
                <div
                  className="d-flex align-items-center iq-sub-card"
                  onClick={logoutUser}
                >
                  <VIcon name="logout.svg" />
                  <div className="ms-3">
                    <a className="mb-0 h6">{t("Sign out")}</a>
                  </div>
                </div>
              </Dropdown.Item>
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    );
  };

  const renderDDLItem = (menu: any) => {
    return (
      <>
        <Dropdown.Item
          onClick={() => {
            setSelectedSearchOption(menu);
            setDropdownOption();
          }}
        >
          <div className="d-flex align-items-center">
            <div className="d-flex me-2 align-items-center text-center">
              <img
                width={20}
                height={20}
                src={
                  (menu?.img_icon && getUrlImage(menu?.img_icon, 50)) ||
                  "/assets/icons/default/search/people.svg"
                }
                alt=""
              />
            </div>
            <span className="d-flex item-name">{t(menu?.name)}</span>
          </div>
        </Dropdown.Item>
      </>
    );
  };

  // const renderSearchBox = () => {
  //   return (
  //     !isSearchLocation && (
  //       <Dropdown
  //         show={showDropdown}
  //         as="li"
  //         className="w-100"
  //         // style={{
  //         //   maxWidth: "24rem",
  //         //   width: `${isMobile ? "16rem" : "24rem"} `,
  //         //   minWidth: "13rem",
  //         // }}
  //       >
  //         <Dropdown.Toggle
  //           href="#"
  //           as={CustomToggle}
  //           variant=""
  //           onClick={setDropdown}
  //         >
  //           <Form.Control
  //             type="text"
  //             className={`text search-text ${
  //               isMobile ? "d-lg-none w-100" : "d-none d-lg-block"
  //             } `}
  //             placeholder={`${t("Search here")}...`}
  //             onChange={(e) => handleChange(e.target.value)}
  //             onKeyDown={handleKeyDown}
  //             value={textSearch}
  //           />
  //         </Dropdown.Toggle>

  //         <Dropdown.Menu className="trending-menu">
  //           <Card className="shadow-none m-0">
  //             <Card.Body className="p-0 trending">
  //               {!selectedSearchOption?.url?.includes("location") &&
  //                 topTrending &&
  //                 topTrending?.map((e) => {
  //                   return (
  //                     <>
  //                       <Dropdown.Item
  //                         onClick={() => {
  //                           if (textSearch && textSearch?.length < 2) {
  //                             toastMinLength();
  //                             return;
  //                           } else {
  //                             setShowDropdown(false);
  //                           }
  //                         }}
  //                       >
  //                         {" "}
  //                         <Link
  //                           to={redirectToSearch(e?._id)}
  //                           className="mb-0 h6"
  //                         >
  //                           <div className="d-flex align-items-center iq-sub-card border-0">
  //                             <div className="avatar-img-pluid mb-1">
  //                               <img
  //                                 width={36}
  //                                 height={36}
  //                                 src={
  //                                   (e?.icon && getUrlImage(e?.icon, 50)) ||
  //                                   "/assets/icons/default/search/trend.svg"
  //                                 }
  //                                 alt=""
  //                               />
  //                             </div>
  //                             {"  "}
  //                             <div className="trending-value cursor-pointer">
  //                               <div className="fw-bold key">
  //                                 {e?.key || e?._id}
  //                               </div>
  //                               <div className="number">
  //                                 {e?.count} {"search"}
  //                               </div>
  //                             </div>
  //                           </div>
  //                         </Link>
  //                       </Dropdown.Item>
  //                     </>
  //                   );
  //                 })}
  //             </Card.Body>
  //           </Card>
  //         </Dropdown.Menu>
  //       </Dropdown>
  //     )
  //   );
  // };

  const renderSearchHeader = () => {
    return (
      <div
        className={`search-input ${
          isMobile && "w-100 me-3 square rounded-pill border border-color"
        } d-flex`}
      >
        <Dropdown
          show={showDropdownOption}
          className="d-flex"
          as="li"
          // style={{
          //   maxWidth: "10rem",
          //   width: `${isMobile ? "10rem" : "10rem"} `,
          // }}
        >
          <Dropdown.Toggle
            onClick={setDropdownOption}
            className="d-flex align-items-center search-option bg-transparent"
          >
            <div className="d-flex align-items-center">
              <div className="d-flex align-items-center text-center">
                <img
                  width={20}
                  height={20}
                  src={
                    (selectedSearchOption?.img_icon &&
                      getUrlImage(selectedSearchOption?.img_icon, 50)) ||
                    "/assets/icons/default/search/people.svg"
                  }
                  alt=""
                />
              </div>
              <span className="d-flex ms-1 item-name">
                {t(selectedSearchOption?.name)}
              </span>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="search-menu">
            <Col className="d-flex">
              <Card className="shadow-none m-0 w-50">
                <Card.Body className="p-0">
                  {viewSearchOption
                    ?.splice(0, Math.ceil(searchMenus?.length / 2))
                    .map((menu) => {
                      return renderDDLItem(menu);
                    })}
                </Card.Body>
              </Card>
              <Card className="shadow-none m-0 w-50">
                <Card.Body className="p-0">
                  {viewSearchOption?.map((menu) => {
                    return renderDDLItem(menu);
                  })}
                </Card.Body>
              </Card>
            </Col>
          </Dropdown.Menu>
        </Dropdown>
        {/* {isSearchLocation ? ( */}
        {googleKey && (
          <SearchLocation
            isSearchLocation={isSearchLocation}
            googleKey={googleKey}
            // selectedPlace={selectedPlace}
            // setSelectedPlace={setSelectedPlace}
            selectLocation={selectLocation}
            // textSearch={textSearch}
            setTextSearch={setTextSearch}
            handleKeyDown={handleKeyDown}
            redirectToSearch={redirectToSearch}
            showDropdown={showDropdown}
            setShowDropdown={setShowDropdown}
            setDropdown={setDropdown}
            topTrending={topTrending}
            selectedSearchOption={selectedSearchOption}
          />
        )}
        {/* ) : (
          <SearchTextBox
            CustomToggle={CustomToggle}
            textSearch={textSearch}
            handleKeyDown={handleKeyDown}
            handleChange={handleChange}
            setDropdown={setDropdown}
            setShowDropdown={setShowDropdown}
            topTrending={topTrending}
          />
        )} */}

        {showSearchMobile && isMobile && (
          <div
            className="search-link d-flex align-items-center mx-2"
            onClick={() => handleKeyDown({ key: "Enter" })}
          >
            <VIcon width={16} name="search.svg" />
          </div>
        )}
      </div>
    );
  };

  const renderMenuItem = (item: any) => {
    const key = item?.key;
    switch (key) {
      case "friend_add":
        return <FriendRequest menu={item} />;
      case "message":
        return enableChat() ? (
          <Nav.Item as="li">
            <a
              href={getIframeUrlChat("conversations", lang)}
              rel="noreferrer"
              className="d-flex align-items-center"
            >
              {item?.img_icon ? (
                <div className={`v-icon`}>
                  <img
                    src={getUrlImage(item?.img_icon, 100)}
                    width={24}
                    height={24}
                    alt=""
                  />
                  {unreadMessage > 0 && (
                    <span className="v-icon-badge">{unreadMessage}</span>
                  )}
                </div>
              ) : (
                <VIcon
                  width={24}
                  height={24}
                  name="chat.svg"
                  badge={unreadMessage}
                />
              )}
            </a>
          </Nav.Item>
        ) : (
          <Nav.Item as="li">
            <Link
              to={`${ROUTES.MESSAGE}`}
              className="d-flex align-items-center"
            >
              {item?.img_icon ? (
                <div className={`v-icon`}>
                  <img
                    src={getUrlImage(item?.img_icon, 100)}
                    width={24}
                    height={24}
                    alt=""
                  />
                  <span className="v-icon-badge">{0}</span>
                </div>
              ) : (
                <VIcon width={24} height={24} name="chat.svg" badge={0} />
              )}
            </Link>
          </Nav.Item>
        );
      case "sos":
        return (
          <Nav.Item as="li">
            <Link
              to={item?.url || `${ROUTES.COMINGSOON}`}
              className="d-flex align-items-center"
            >
              <img
                src={getUrlImage(item?.img_icon, 100)}
                width={24}
                height={24}
                alt=""
              />
            </Link>
          </Nav.Item>
        );
      case "home":
        return (
          !isMobile && (
            <Nav.Item as="li" className="link-to-home-v2-icon">
              <Link
                to="/home-v2"
                className="d-flex align-items-center justify-content-center"
              >
                {item?.img_icon ? (
                  <>
                    <img
                      src={getUrlImage(item?.img_icon, 100)}
                      width={24}
                      height={24}
                      alt=""
                    />
                    <span>{item?.description}</span>
                  </>
                ) : (
                  <>
                    <VIcon width={20} height={20} name="home2.svg" />
                    <span>new</span>
                  </>
                )}
              </Link>
            </Nav.Item>
          )
        );
      case "menu":
        return (
          isMobile && (
            <Nav.Item as="li">
              <Link
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
              >
                {item?.img_icon ? (
                  <img
                    src={getUrlImage(item?.img_icon, 100)}
                    width={24}
                    height={24}
                    alt=""
                  />
                ) : (
                  <VIcon width={24} height={24} name="menu.svg" />
                )}
              </Link>
            </Nav.Item>
          )
        );
      case "search":
        return (
          !isConversation &&
          isMobile && (
            <Nav.Item as="li">
              <Link onClick={() => setShowSearchMobile(true)}>
                {item?.img_icon ? (
                  <img
                    src={getUrlImage(item?.img_icon, 100)}
                    width={24}
                    height={24}
                    alt=""
                  />
                ) : (
                  <VIcon width={20} height={20} name="search.svg" />
                )}
              </Link>
            </Nav.Item>
          )
        );
      case "notification":
        return <Notification menu={item} />;
      default:
        return (
          <Nav.Item as="li">
            <Link to={item?.url || ""} className="d-flex align-items-center">
              <img
                src={getUrlImage(item?.img_icon, 100)}
                width={24}
                height={24}
                alt=""
              />
            </Link>
          </Nav.Item>
        );
    }
  };

  return (
    <>
      <div className="iq-top-navbar">
        <Nav
          // expand="lg"
          variant="light"
          className="nav navbar navbar-expand-lg navbar-light iq-navbar p-lg-0"
        >
          {showSearchMobile ? (
            <div className="d-flex align-items-center w-100">
              <div
                className="mx-3"
                style={{ width: "3%" }}
                onClick={() => setShowSearchMobile(false)}
              >
                <VIcon name="left-arrow.svg" height={16} />
              </div>
              {/* <Form.Control
                type="text"
                className="text search-input form-control bg-soft-primary"
                placeholder={`${t("Search here")}...`}
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={handleKeyDown}
              /> */}
              {showSearchMobile && isMobile && renderSearchHeader()}
            </div>
          ) : (
            <>
              <Container
                fluid
                className={`navbar-inner header-mobile position-relative`}
              >
                <PageBackgrounds
                  page={"page_bg_header"}
                  width="100%"
                  height="100%"
                  position={"absolute"}
                />
                {/* logo desktop */}
                <div className="d-none d-lg-flex align-items-center gap-3">
                  <div>
                    <span
                      className="d-flex align-items-center gap-2 iq-header-logo d-none d-sm-flex iq-logo-none cursor-pointer"
                      onClick={redirectToHome}
                    >
                      <img src={getUrlImage(_logo, 100)} alt="" />
                      {country && (
                        <div className="flag-icon-logo">
                          <img
                            className="national_flag"
                            src={getUrlImage(
                              country?.logo || country?.icon,
                              100
                            )}
                            alt=""
                          />
                        </div>
                      )}
                    </span>
                  </div>
                  <Link
                    className="sidebar-toggle"
                    data-toggle="sidebar"
                    data-active="true"
                    onClick={minisidebar}
                  >
                    <VIcon width={24} height={24} name="menu.svg" />
                  </Link>
                  {weather && (
                    <div className="d-none d-lg-block">
                      <div
                        className="d-flex align-items-center v-weather"
                        onClick={(_) => history.push(`${ROUTES.WEATHER}`)}
                      >
                        <img
                          src={`https://openweathermap.org/img/w/${weather.weather[0]["icon"]}.png`}
                          alt=""
                          height={50}
                        />
                        <div className="content">
                          <span className="temp">
                            {city || "VietNam"}, {weather.main.temp}&deg;C
                          </span>
                          <div>{t(weather.weather[0]["description"])}</div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                <div className="d-block d-lg-none">
                  <a
                    className="d-flex align-items-center gap-2 iq-header-logo position-relative"
                    onClick={() => redirectToHome()}
                  >
                    <img
                      className="d-sm-none"
                      src={getUrlImage(_logoMobile, 100)}
                      alt=""
                      height={50}
                    />
                    <img
                      className="d-none d-sm-block"
                      src={getUrlImage(_logo, 100)}
                      alt=""
                      height={50}
                    />
                    {country && (
                      <div className="flag-icon-logo">
                        <img
                          className="national_flag"
                          src={getUrlImage(country?.logo || country?.icon, 100)}
                          alt=""
                        />
                      </div>
                    )}
                  </a>
                </div>

                {/* <div className="d-sm-none w-100">
                  <Quota />
                </div> */}
                {isMobile && background[0]?.description?.length > 0 && (
                  <div className="w-100 d-flex justify-content-center align-items-center px-2">
                    <Marquee speed={50} gradient={false}>
                      <span
                        className="w-100 px-2 fw-bold fs-4"
                        style={{ color: background[0]?.text_color }}
                      >
                        {background[0]?.description}
                      </span>
                    </Marquee>
                  </div>
                )}

                <div className="iq-search-bar device-search position-relative">
                  <div
                    className="searchbox d-flex align-items-center"
                    // onClick={handleShow}
                    data-bs-toggle="modal"
                    data-bs-target="#exampleModalFullscreenSm"
                  >
                    <div
                      className="search-link d-none d-lg-block"
                      onClick={() => handleKeyDown({ key: "Enter" })}
                    >
                      <VIcon width={16} name="search.svg" />
                    </div>
                    {!isMobile && renderSearchHeader()}
                  </div>

                  {news?.address_current && (
                    <Nav.Item
                      as="li"
                      className="noti-mobile d-lg-none position-relative"
                    >
                      <Link
                        to={`${ROUTES.PROFILE.replace(
                          ":id",
                          news?.address_current._id
                        )}`}
                        className="d-flex align-items-center"
                      >
                        <img
                          src={getUrlImage(news?.address_current.avatar, 150)}
                          width={32}
                        />
                      </Link>
                      {weather && (
                        <div className="d-block d-lg-none">
                          <div className="v-weather d-flex align-items-center ">
                            <img
                              src={`https://openweathermap.org/img/w/${weather.weather[0].icon}.png`}
                              alt=""
                            />
                            <span className="temp">
                              {" "}
                              {weather.main.temp}&deg;C
                            </span>
                          </div>
                        </div>
                      )}
                    </Nav.Item>
                  )}
                  {/* {renderProfileHeader(true)} */}
                  {/* <div className="d-flex align-items-center d-sm-none">
                  <Nav.Item as="li" className="me-2">
                    <div onClick={() => setShowSearchMobile(true)} className="d-flex align-items-center">
                      <VIcon name="search.svg" />
                    </div>
                  </Nav.Item>
                  <Nav.Item as="li">
                    <Link
                      className="sidebar-toggle"
                      data-toggle="sidebar"
                      data-active="true"
                      onClick={minisidebar}
                    >
                      <VIcon name="menu.svg" />
                    </Link>
                  </Nav.Item>
                </div> */}
                  <div className={`search-modal`}>
                    <div className={`${show ? "show" : "hidden"}`}>
                      {" "}
                      <div className="modal-fullscreen-lg-down">
                        <Search
                          handleClose={handleClose}
                          search={search}
                          setSearch={setSearch}
                        />
                      </div>
                    </div>
                  </div>
                </div>

                {!isMobile && (
                  <div
                    className={`offcanvas offcanvas-end iq-profile-menu-responsive ${
                      show1 === true ? "show" : ""
                    } `}
                    tabIndex={-1}
                    id="offcanvasBottom"
                    style={{
                      visibility: `${show1 === true ? "visible" : "hidden"}`,
                    }}
                  >
                    <Offcanvas.Header>
                      <h5 id="offcanvasRightLabel">General Setting</h5>
                      <CloseButton onClick={() => setShow1(false)} />
                      {/* <Button type="button" className="btn-close text-reset"></Button> */}
                    </Offcanvas.Header>
                    <Offcanvas.Body className="pt-0">
                      <ul className="navbar-nav navbar-list">
                        {_menuHeader?.length > 0 &&
                          _menuHeader?.map((el) => renderMenuItem(el))}
                        {renderProfileHeader(false)}
                        <Nav.Item as="li" className="d-lg-none"></Nav.Item>
                      </ul>
                    </Offcanvas.Body>
                  </div>
                )}
              </Container>

              {!isConversation && isMobile && (
                <ul className="navbar-nav navbar-list  d-flex d-lg-none flex-row justify-content-around align-items-center w-100 mt-2">
                  {_menuHeader?.length > 0 &&
                    _menuHeader?.map((el) => renderMenuItem(el))}
                  {renderProfileHeader(true, "24px")}
                </ul>
              )}
            </>
          )}
        </Nav>
        <VModal
          title={t("Sign in as another")}
          isOpen={openModalSwitch}
          centered={true}
          children={<SwitchAccount setOpenModalSwitch={setOpenModalSwitch} />}
          showFooter={false}
          onCancel={() => setOpenModalSwitch(false)}
        />
      </div>
    </>
  );
};

export default Header;

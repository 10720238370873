import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getForm = (req: any) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`form-tempalte/get-form-by-options?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const Service = {
  getForm,
};

export default Service;

/* eslint-disable indent */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import VButton from "components/v-button";
import VIcon from "components/v-icon";
import VReward from "components/v-reward";
import VTooltip from "components/v-tooltip";
import { LEVEL_CALCULATE } from "config/enums";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import Stepper from "react-stepper-horizontal";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { formatNumber, getFullNameByNumber, getUrlImage } from "utils/common";

const Level = ({ participant }) => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(-1);
  const [levelCurrent, setLevelCurrent] = useState(null);
  const [levelNext, setLevelNext] = useState(null);
  const [levels, setLevels] = useState([]);
  const profile = useAppSelector(getUserProfile);

  useEffect(() => {
    if (participant) {
      const { object, data } = participant;
      const { levels } = object;
      const { level } = data || {};
      if (levels) {
        const _index = levels.findIndex((l) => l.id === level);
        setLevels(levels);
        setActiveStep(_index);
        setLevelCurrent(levels[_index]);
        setLevelNext(levels[_index + 1]);
      }
    }
  }, [participant]);

  const renderAction = (level) => {
    const renderAffilate = (
      <div>
        {Object.keys(level.affiliates || {}).map((k) => {
          return (
            <div>
              Level {String(k).toUpperCase()} : <br />
              <VReward reward={level.affiliates[k]} />
            </div>
          );
        })}
      </div>
    );

    return (
      <span className="">
        {level.affiliates && (
          <VTooltip
            key={`${level.id}_affilate`}
            content={renderAffilate}
            placement={"top"}
          >
            <VIcon
              name="common/affilate.png"
              width={20}
              height={20}
              className="mx-1"
            />
          </VTooltip>
        )}
        {level.reward &&
          (level.reward.codes.length > 0 ||
            level.reward.stocks.length > 0 ||
            level.reward.tickets.length > 0 ||
            level.reward.vouchers.length > 0) && (
            <VTooltip
              placement={"top"}
              key={`${level.id}_gift`}
              content={<VReward reward={level.reward} />}
            >
              <VIcon
                name="gift-card.svg"
                className="mx-1"
                width={20}
                height={20}
              />
            </VTooltip>
          )}
      </span>
    );
  };

  const steps = levels?.map((level, index) => {
    return {
      title: (
        <div className="step-title">
          <div
            className={`avatar-current-step ${
              activeStep === index ? "step-active" : ""
            }`}
          >
            <img src={getUrlImage(profile.avatar)} alt="_avt" width={30} />
          </div>
          <div
            className={`level-detail ${activeStep === index ? "active" : ""}`}
          >
            <img src={getUrlImage(level.image)}></img>
            <p className="name">{getFullNameByNumber(level.name, 10)}</p>
            <div className="action"> {renderAction(level)}</div>
          </div>
        </div>
      ),
      // icon: getUrlImage(level.image),
    };
  });

  const renderInfo = () => {
    if (participant && participant.data) {
      return (
        <div className="result-info">
          <h5 className="text-center">
            {t("Parameters achieved in the campaign")}
          </h5>
          {Object.keys(participant.data).map((key) => {
            if (LEVEL_CALCULATE[key])
              return (
                <div>
                  - {t(key)} : {formatNumber(participant.data[key], 0)}
                </div>
              );
            else return <></>;
          })}
        </div>
      );
    } else return <></>;
  };

  const renderLevelInfo = () => {
    if (participant) {
      return (
        <div className="level-info">
          <h5 className="text-center">{t("Levels Infomation")}</h5>
          {levels.map((level) => (
            <>
              <div className="title">{level.name}: </div>
              {Object.keys(level).map((key) => {
                if (
                  LEVEL_CALCULATE[key] &&
                  level[`${String(key).split("_amount")[0]}_enable`]
                )
                  return (
                    <div>
                      - {t(key)} : {formatNumber(level[key], 0)}
                    </div>
                  );
                else return <></>;
              })}
            </>
          ))}
        </div>
      );
    } else return <></>;
  };

  return (
    <div className="level-wrapper">
      <div className="level-stepper">
        <Stepper
          steps={steps}
          activeStep={activeStep}
          // circleFontSize={12}
          // activeColor="#FF9900"
          // defaultBarColor="white"
          // completeColor="white"
          // completeBarColor="#FF9900"
          // defaultBorderWidth={20}
        />
      </div>
      <hr />
      {renderInfo()}
      <hr />
      {renderLevelInfo()}

      {levelNext && (
        <div>
          <hr />
          <h5 className="text-center">
            {t("Instructions for the next level")}
          </h5>
          {levelNext.description}
        </div>
      )}
      {levelCurrent && (
        <>
          <hr />
          <div>
            <VButton disabled>Congratulations</VButton>
          </div>
        </>
      )}
    </div>
  );
};

export default Level;

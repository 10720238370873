/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from "react";
import MyButton from "components/v-button";
import SelectRegions from "components/v-location";
import VInput from "components/v-input";
import BirthdatSelect from "components/v-birthday-select";
import { OBJECT_TYPE } from "config/enums";
import useTranslation from "hooks/useTranslation";
import useQuery from "hooks/useQuery";
import { useAppSelector } from "redux/reducers/hook";
import {
  getFamilies,
  getMenusByKey,
} from "redux/reducers/settings/settings.selector";
import VIcon from "components/v-icon";
import VDropdown from "components/v-dropdown";

function View(props) {
  const {
    role,
    userBy,
    loading,
    setStep,
    isValid,
    setIsValid,
    validate,
    handleChangeState,
    state,
    setOptions,
  } = props;
  const { t } = useTranslation();
  const query = useQuery();
  const menus: any = useAppSelector(getMenusByKey("signup_policy"));
  const families = useAppSelector(getFamilies);

  const [stepBusiness, setStepBusiness] = useState<number>(0);
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [address, setAddress] = useState<any>({});
  const [homeTown, setHomeTown] = useState<any>({});
  const [position, setPosition] = useState<any>({});
  const [birthday, setBirthday] = useState(null);
  const [isPolicy, setIsPolicy] = useState(false);

  useEffect(() => {
    const listener = (event) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        handleSubmit();
        event.preventDefault();
        // callMyFunction();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  const handleBack = () => {
    if (stepBusiness === 0) {
      setStep(0);
    } else {
      setStepBusiness(0);
    }
    setOptions((prev) => ({
      ...prev,
      location: null,
    }));
  };

  const handleSubmit = () => {
    try {
      if (stepBusiness === 0) {
        setStepBusiness(1);
      } else {
        props.handleRegister({
          location: address,
          home_town: homeTown,
          position: Object.keys(position)
            .map((k) => (position[k] ? k : null))
            .filter((a) => a),
          birthday: birthday,
        });
      }
    } catch (error) {}
  };

  const handleChangeCheckbox = (name, checked) => {
    setPosition((p) => ({
      ...p,
      [name]: checked,
    }));
  };

  useEffect(() => {
    if (address?.country) {
      setIsValid(true);
    }
    setOptions((prev) => ({
      ...prev,
      location: address,
    }));
  }, [address, homeTown]);

  useEffect(() => {
    if (query.refer_code) {
      handleChangeState("referred_by", query.refer_code);
    }
  }, [query.refer_code]);

  useEffect(() => {
    setIsValid(false);
  }, []);
  return (
    <>
      {role !== OBJECT_TYPE.USER && (
        <span>
          {t(
            role === OBJECT_TYPE.CEO
              ? "For CEOs"
              : "For people under 18 years old"
          )}
        </span>
      )}
      {userBy && (
        <span>
          {t("You are registering an account via the invitation of")}{" "}
          <b>{userBy?.full_name}</b>
        </span>
      )}
      <div>
        <form className="mt-3">
          <div className="form-group">
            {stepBusiness === 0 && (
              <>
                <h5 className="fw-bold mb-2">{t("Where is your hometown")}</h5>
                <SelectRegions
                  hideLabel={true}
                  hideDetail={true}
                  isDisabled={false}
                  address={homeTown}
                  setAddress={setHomeTown}
                />
                <h5 className="fw-bold mb-2">{t("Place to live")}</h5>
                <SelectRegions
                  hideLabel={true}
                  hideDetail={true}
                  isDisabled={false}
                  address={address}
                  setAddress={setAddress}
                />
              </>
            )}
            {stepBusiness === 1 && (
              <>
                <div className="d-flex justify-content-between">
                  <div className="w-50 me-1">
                    <VDropdown
                      // label={t("Family")}
                      disabled={false}
                      placeholder={t("Family")}
                      options={families.map((el: any) => {
                        return {
                          icon: el.logo,
                          label: t(el.name),
                          value: String(el._id),
                        };
                      })}
                      value={String(state?.family_id)}
                      onChange={(e) =>
                        handleChangeState("family_id", e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group w-50 ms-1">
                    <VInput
                      label={t("Genus family")}
                      type="text"
                      id="genus_family"
                      name="genus_family"
                      value={state.genus_family}
                      onChange={(e) =>
                        handleChangeState("genus_family", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group w-50 me-1">
                    <VInput
                      label={t("Last name")}
                      type="text"
                      id="name"
                      name="last_name"
                      errMessage={validate.last_name}
                      onChange={(e) =>
                        handleChangeState("last_name", e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group w-50 ms-1">
                    <VInput
                      label={t("First name")}
                      type="text"
                      id="first_name"
                      name="first_name"
                      errMessage={validate.first_name}
                      onChange={(e) =>
                        handleChangeState("first_name", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="d-flex justify-content-between">
                  <div className="form-group w-50 me-1">
                    <VInput
                      label={t("Enter email")}
                      type="email"
                      id="email"
                      name="email"
                      errMessage={validate.email}
                      onChange={(e) =>
                        handleChangeState("email", e.target.value)
                      }
                    />
                  </div>
                  <div className="form-group w-50 ms-1">
                    <VInput
                      label={t("Phone")}
                      type={"number"}
                      id="phone"
                      name="phone"
                      errMessage={validate.phone}
                      onChange={(e) =>
                        handleChangeState("phone", e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="form-group">
                  <VInput
                    label={t("Password")}
                    type={showPassword ? "text" : "password"}
                    id="password"
                    name="password"
                    errMessage={validate.password}
                    onChange={(e) =>
                      handleChangeState("password", e.target.value)
                    }
                    suffix={
                      <div
                        className="cursor-pointer"
                        onClick={() => setShowPassword(!showPassword)}
                      >
                        <VIcon
                          name={showPassword ? "eye.svg" : "eye_off.svg"}
                        />
                      </div>
                    }
                  />
                </div>
                {role !== OBJECT_TYPE.UNDER_18 && (
                  <div className="form-group">
                    <VInput
                      label={t("Referral code")}
                      type="text"
                      id="referred_by"
                      name="referred_by"
                      errMessage={validate.referred_by}
                      disabled={Boolean(query.refer_code)}
                      value={state.referred_by}
                      onChange={(e) =>
                        handleChangeState("referred_by", e.target.value)
                      }
                    />
                  </div>
                )}

                <div className="mt-2">
                  <h5 className="fw-bold mb-1">{t("Birthday")}</h5>
                  <BirthdatSelect
                    date={birthday}
                    setIsValid={setIsValid}
                    onChange={setBirthday}
                  />
                </div>
                <div className="mt-2">
                  <h5 className="fw-bold mb-1">{t("Gender")}</h5>
                  <div className="d-flex">
                    <div className="form-check form-control item-checked">
                      <label className="form-check-label" htmlFor={"male"}>
                        {t("Male")}
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name={"gender"}
                        id={"male"}
                        onChange={(_) => handleChangeState("gender", "MALE")}
                        defaultChecked={state.gender === "MALE"}
                      />
                    </div>
                    <div className="form-check form-control item-checked">
                      <label className="form-check-label" htmlFor={"famale"}>
                        {t("Female")}
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name={"gender"}
                        id={"female"}
                        onChange={(_) => handleChangeState("gender", "FEMALE")}
                        defaultChecked={state.gender === "FEMALE"}
                      />
                    </div>
                    <div className="form-check form-control item-checked">
                      <label className="form-check-label" htmlFor={"private"}>
                        {t("Private")}
                      </label>
                      <input
                        className="form-check-input"
                        type="radio"
                        name={"gender"}
                        id={"private"}
                        onChange={(_) => handleChangeState("gender", "PRIVATE")}
                        defaultChecked={state.gender === "PRIVATE"}
                      />
                    </div>
                  </div>
                </div>

                {role === OBJECT_TYPE.CEO && (
                  <div className="mt-2">
                    <h5 className="fw-bold mb-1">{t("position")}</h5>
                    <div className="d-flex flex-wrap">
                      <div className="form-check form-control item-checked">
                        <label className="form-check-label" htmlFor={"ceo"}>
                          Ceo
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"ceo"}
                          name={"position"}
                          onChange={(e) =>
                            handleChangeCheckbox("ceo", e.target.checked)
                          }
                        />
                      </div>
                      <div className="form-check form-control item-checked">
                        <label className="form-check-label" htmlFor={"manager"}>
                          {t("manager")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"manager"}
                          name={"position"}
                          onChange={(e) =>
                            handleChangeCheckbox("manager", e.target.checked)
                          }
                        />
                      </div>
                      <div className="form-check form-control item-checked">
                        <label className="form-check-label" htmlFor={"leader"}>
                          {t("leader")}
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={""}
                          id={"leader"}
                          name={"position"}
                          onChange={(e) =>
                            handleChangeCheckbox("leader", e.target.checked)
                          }
                        />
                      </div>
                    </div>
                  </div>
                )}
                {role === OBJECT_TYPE.UNDER_18 && (
                  <div className="form-group mt-2">
                    <VInput
                      label={t("guardian")}
                      type="text"
                      id="referred_by"
                      name="referred_by"
                      errMessage={validate.referred_by}
                      disabled={Boolean(query.refer_code)}
                      value={state.referred_by}
                      onChange={(e) =>
                        handleChangeState("referred_by", e.target.value)
                      }
                    />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="d-inline-block w-100">
            <div className="d-flex">
              <div>
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  onChange={(e) => setIsPolicy(e.target.checked)}
                />
              </div>
              <label className="form-check-label" htmlFor="customCheck1">
                {t(menus[0]?.description)}{" "}
                <a href={menus[0]?.url} target="_blank" rel="noreferrer">
                  {t(menus[0]?.name)}
                </a>
              </label>
            </div>
            <div className="d-flex justify-content-between align-items-center mt-2">
              <MyButton
                onClick={handleBack}
                height={"36px"}
                className="btn btn-primary"
                loading={loading}
              >
                {t("back")}
              </MyButton>
              <MyButton
                onClick={handleSubmit}
                height={"36px"}
                className="btn btn-primary"
                loading={loading}
                disabled={!isValid || !isPolicy}
              >
                {stepBusiness === 0 ? t("next") : t("register")}
              </MyButton>
            </div>
          </div>
        </form>
      </div>
    </>
  );
}

export default View;

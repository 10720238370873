/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getCampaignsAsync } from "redux/reducers/tasks/tasks.actions";
import {
  getCampaigns,
  getHistoryCampaigns,
} from "redux/reducers/tasks/tasks.selector";
import VBannerPage from "components/v-teamplate/v-banner-page";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import VTab from "components/v-tab";
import useQuery from "hooks/useQuery";
import VInput from "components/v-input";
import { useTranslation } from "react-i18next";
import VIcon from "components/v-icon";
import VButton from "components/v-button";
import { Card, Col, Container, Row } from "react-bootstrap";
import VRightView from "components/v-right-view";
import HistoryTasks from "./history";
import CampaignItem from "./components/Item";
import { PARTICIPANT_STATUS } from "config/enums";

const View = () => {
  const dispatch = useAppDispatch();
  const campaigns = useAppSelector(getCampaigns);
  const historyCampaigns = useAppSelector(getHistoryCampaigns);
  const [tab, setTab] = useState(null);
  const menus = useAppSelector(getMenusByKey("tab_campaign"));
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  const [loadMenu, setLoadMenu] = useState(false);
  const query = useQuery();
  const { t } = useTranslation();

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  useEffect(() => {
    dispatch(getCampaignsAsync());
  }, [dispatch]);

  return (
    <div className="container task-wrapper">
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <VBannerPage position="banner_top_task" />
            <div>
              <Card className="p-3">
                <Col sm={12}>
                  <div className="mb-3 d-flex justify-content-between">
                    <Col sm={12} md={5}>
                      <VInput
                        transparent={true}
                        label={t("Campaign")}
                        suffix={<VIcon width={16} name="search.svg" />}
                      />
                    </Col>
                    <VButton disabled onClick={() => {}}>
                      {t("Create")}
                    </VButton>
                  </div>
                  <VTab
                    tab={tab}
                    setTab={setTab}
                    menus={menus}
                    activeBorder="bottom"
                    noBackground={true}
                  />
                  {tab && tab.key === "campaigns" && (
                    <Row>
                      {campaigns
                        .filter(
                          (t) =>
                            !historyCampaigns.some(
                              (h) =>
                                h.object_id === t._id &&
                                h.status === PARTICIPANT_STATUS.COMPLETED
                            )
                        )
                        .map((campaign) => (
                          <Col md={6} lg={6} className="task-item">
                            <CampaignItem
                              campaign={campaign}
                              setTab={setTab}
                              menus={menus}
                            />
                          </Col>
                        ))}
                    </Row>
                  )}
                  {tab && tab.key === "history" && <HistoryTasks />}
                </Col>
              </Card>
            </div>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_page"}
                positionBanner={"banner_right_page"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default View;

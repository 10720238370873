/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import VButton from "components/v-button";
import InvoicesService from "api/invoices";
import { toast } from "react-toastify";
import Dropdown from "components/v-dropdown";
import SettingApi from "api/settings";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import APIs from "api";
import VForm from "components/v-form";

const View = () => {
  const { t } = useTranslation();
  const userData = useAppSelector(getUserProfile);
  const [loading, setLoading] = useState(false);
  const [listFiles, setListFiles] = useState<any>({});
  const [type, setType] = useState("");
  const [listInvoiceSetting, setListInvoiceSetting] = useState([]);
  const [formAdditionalData, setFormAdditionalData] = useState<any>({});
  const [additionalData, setAdditionalData] = useState({});
  const [validate, setValidate] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);

  const handleChange = (value) => {
    setType(value);
    const formCustom = listInvoiceSetting.find((el) => el.key === value);
    setFormAdditionalData(formCustom);
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let listImg;
      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${userData?._id}`);

        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await APIs.UPLOAD.uploadMultiFile({
          body: formData,
        });
      }
      let data = {
        type: type,
        info: additionalData,
      };

      if (listImg && listImg?.length > 0) {
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              data = {
                ...data,
                info: {
                  ...data.info,
                  [item]: el?.filePath,
                },
              };
            }
          });
        });
      }

      const res = await InvoicesService.createInvoices({
        body: {
          ...data,
        },
      });
      if (res) {
        setLoading(false);
        setType("");
        setFormAdditionalData({});
        setListFiles({});
        setAdditionalData({});
        toast.success(t("Success"));
      }
    } catch (error) {
      setLoading(false);
      toast.success(t(error.errors));
    }
  };

  const getInvoiceSetting = async () => {
    try {
      const res: any = await SettingApi.getSettingByName("invoice");
      if (res) {
        const { setting } = res;
        if (setting) {
          const value = setting["value"] || [];
          setListInvoiceSetting(value);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    const check = () => {
      const listRequied = formAdditionalData?.fields?.filter(
        (el) => el.required
      );

      for (let index = 0; index < listRequied?.length; index++) {
        const item = listRequied[index];
        if (!additionalData[item.label] && !listFiles[item.label]) {
          return false;
        }
      }
      return true;
    };
    setIsValid((_) => check());
  }, [additionalData, listFiles, type]);

  useEffect(() => {
    getInvoiceSetting();
  }, []);

  return (
    <div className="wrapper-notification-setting">
      <Form>
        <div className="row align-items-end">
          <div className="col-md-6">
            <Form.Label>{t("Choose service")}</Form.Label>
            <Dropdown
              id="type"
              options={listInvoiceSetting
                ?.sort((prev, next) => (prev.order || 0) - (next.order || 0))
                ?.map((el: any) => {
                  return {
                    icon: el.icon,
                    label: t(el.name),
                    value: el.key,
                    isDisabled: type === el.key,
                  };
                })}
              placeholder={t("Choose service")}
              value={type}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
          {formAdditionalData && formAdditionalData?.fields?.length > 0 && (
            <div className="row">
              <VForm
                // colItem={"col-6"}
                fields={formAdditionalData?.fields}
                additionalData={additionalData}
                listFiles={listFiles}
                validate={validate}
                setListFiles={setListFiles}
                setValidate={setValidate}
                setIsValid={setIsValid}
                setFile={setFile}
                setAdditionalData={setAdditionalData}
              />
            </div>
          )}
          {type && (
            <div className="d-flex mb-3">
              <div>
                <input
                  className="form-check-input me-2"
                  type="checkbox"
                  onChange={(e) => setIsPolicy(e.target.checked)}
                />
              </div>
              <label className="form-check-label" htmlFor="customCheck1">
                {t(
                  "Tôi đồng ý và mong muốn sử dụng các tiện ích này để ủng hộ cho Dự Án Xây dựng Mạng xã hội Nhật ký cuộc sống"
                )}{" "}
                <a href={"/"} target="_blank" rel="noreferrer">
                  www.vdiarybook.com
                </a>
                {t(
                  "( Made By Việt Nam) và Nhà Sáng lập Lê Trung Hiếu cùng đội ngũ phát triển."
                )}
              </label>
            </div>
          )}
        </div>

        <VButton
          loading={loading}
          height={"36px"}
          disabled={!isValid || !isPolicy}
          className="btn btn-primary me-2"
          onClick={handleSubmit}
        >
          {t("send")}
        </VButton>
      </Form>
      {formAdditionalData?.attachments?.enable && (
        <div
          className="my-3"
          dangerouslySetInnerHTML={{
            __html: formAdditionalData?.attachments?.guide,
          }}
        ></div>
      )}
    </div>
  );
};

export default View;

import { request, parseErrorResponse } from "./request";
import qs from 'query-string';

const createInvoices = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/invoices`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInvoicesByUser = (req) =>
  new Promise((resolve, reject) => {
    const query = qs.stringify(req.query);
    request()
      .get(`/invoices?${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  createInvoices,
  getInvoicesByUser
};

export default APIs;

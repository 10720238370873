import RelationShipService from "api/relationships";
import VButton from "components/v-button";
import VTable from "components/v-table";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import { useEffect } from "react";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getRelationRequestingAsync } from "redux/reducers/users/users.actions";
import { getRelationRequesting } from "redux/reducers/users/users.selector";
import { formatDate } from "utils/common";

const Request = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const relations = useAppSelector(getRelationRequesting);

  useEffect(() => {
    dispatch(getRelationRequestingAsync());
  }, [dispatch]);

  const changeStatus = async (item, status) => {
    try {
      await RelationShipService.update({
        body: {
          status,
          relationshipId: item._id,
        },
      });
      dispatch(getRelationRequestingAsync());
    } catch (error) {
      toast(error.errors);
    }
  };

  const columns = [
    {
      key: "from",
      title: t("From"),
      render: (item) => String(item.from?.full_name).slice(0, 20),
    },
    {
      key: "Relation",
      title: t("Relation"),
      dataIndex: "relation",
    },
    {
      key: "Date",
      title: t("Date"),
      render: (item) => formatDate(item.createdAt),
    },
    {
      key: "Action",
      title: t("Action"),
      render: (item: any) => (
        <div className="action d-flex">
          <VButton onClick={(_) => changeStatus(item, "accept")}>
            {t("Confirm")}
          </VButton>
          <VButton className={"btn-danger ms-1"} onClick={(_) => changeStatus(item, "declined")}>
            {t("Decline")}
          </VButton>
        </div>
      ),
    },
  ];

  return (
    <Card className="p-2">
      <div className="request">
        <div className="overflow-auto">
          <VTable columns={columns} dataSource={relations}></VTable>
        </div>
      </div>
    </Card>
  );
};

export default Request;

/* eslint-disable max-len */
import React, { useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import qs from 'query-string';
import useCountdown from 'hooks/useCountdown';
import { Spinner } from "reactstrap";
import { toast } from "react-toastify";

import AuthAPI from "api/auth";
import Card from "components/v-teamplate/Card";
import FooterForm from "../components/footer-form";
import VButton from "components/v-button";
import VInput from "components/v-input";
import useTranslation from "hooks/useTranslation";
import HeaderForm from "../components/header-form";
interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const { t } = useTranslation();
  const location = useLocation()
  const { time, isCountdown, loadingResend, resendCode } = useCountdown()

  const query = qs.parse(location.search);
  const email = String(query.email).replace(" ", "+");

  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState<string>(null);
  const handleChange = (value: string) => {
    setOtp(value);
  };

  const confirmLogin = async (data) => {
    try {
      setLoading(true);
      const res: any = await AuthAPI.postVerify({ body: { otp: otp, email: email } });
      if (res) {
        localStorage.setItem("auth", res.token);
        setLoading(false);
        history.push("/");
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(error?.errors));
    }
  };

  return (
    <Card className="form-user col-md-6" isOpacity={true}>
      <HeaderForm />
      <div className="">
        <div className="d-flex justify-content-between align-items-center">
          <h1 className="mb-0 fs-2">{t("Account verify")}</h1>
        </div>
        <p className="fs-12">{t("Send mail")}</p>
        <form className="mt-4">
          <div className="form-group">
            <VInput
              label={t("Code")}
              type="text"
              id="otp"
              name="otp"
              // placeholder="Code"
              suffix={
                <div className="send_mail_suffix">
                  {
                    loadingResend ? <Spinner animation="border" /> :
                      <span onClick={() => resendCode(email)}>{isCountdown ? time : t("Resend")}</span>
                  }
                </div>
              }
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
          <div className="d-inline-block w-100">
            <div className="d-flex justify-content-center align-items-center mt-3">
              <VButton
                type="button"
                className="btn btn-primary"
                loading={loading}
                onClick={confirmLogin}
              >
                {t("account_verify")}
              </VButton>
            </div>
          </div>
          <FooterForm />
        </form>
      </div>
    </Card>
  );
}

import styled from "styled-components";

export const Container = styled.div`
  border-radius: 8px;
  img {
    cursor: pointer;
  }
  @media screen and (max-width: 500px) {
    padding: 0 10px 10px 10px;
  }
`;
export const Wrapper = styled.div`
  padding: 30px;
  border-radius: 8px;
  background: ${(props) => props.theme.colors["black100"]};
  overflow: hidden;
  background: ${(props) => props.theme.colors["black100"]};
  h4 {
    margin: 15px 0;
  }
  @media screen and (max-width: 500px) {
    padding: 10px;
  }
`;
export const Image = styled.div`
  img {
    width: 100%;
    max-width: 175px;
    object-fit: cover;
  }
  @media screen and (max-width: 500px) {
    text-align: center;
  }
`;

export const Campaign = styled.div`
  background: linear-gradient(89.86deg, #1166e3 4.51%, #733abc 99.94%);
  border-radius: 6px;
  width: fit-content;
  span {
    padding: 3px;
    font-size: 12px;
  }
`;
export const Action = styled.div`
  margin-bottom: 35px;

  button {
    margin-right: 20px;
    font-size: 18px;
    font-weight: 600;
  }
  button:first-child {
    background: none;
    border: 2px solid green;
    color: ${(props) => props.theme.colors["green100"]};
  }
`;
export const Item = styled.div`
  display: flex;
  margin: 5px 0;
  label {
    font-size: 15px;
  }
`;
export const Text = styled.div`
  margin-left: 10px;
  strong {
    display: block;
    color: #ff9900;
  }
  label {
    color: #696d74;
  }
  b {
    display: block;
  }
`;

export const ContainImage = styled.div``;
export const ContainItem = styled.div`
  margin-top: 25px;
`;

export const Tabs = styled.div`
  display: flex;
  margin: 10px 0;
  overflow: auto;
  p {
    margin: 15px;
  }
`;

export const Tab = styled.p<{ isActive?: boolean }>`
  cursor: pointer;
  color: ${(props) => (props.isActive ? "#fff" : "grey")};
  border-bottom: ${(props) => (props.isActive ? "1px solid #fff" : "")};
`;

export const Introduction = styled.div`
  background: ${(props) => props.theme.colors["black100"]};
  min-height: 200px;
  padding-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
`;

export const Content = styled.div`
  margin: 0 20px;
  p,
  span {
    color: white !important;
    background: transparent !important;
  }
`;

export const ModalWrapper = styled.div`
  text-align: center;
  padding: 50px;
  p {
    color: ${(props) => props.theme.colors["green100"]};
    font-size: 20px;
  }
  p:hover {
    opacity: 0.5;
  }
`;

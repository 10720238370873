/* eslint-disable indent */
/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getPurchasesAsync } from "./purchases.actions";

export interface IPruchasesState {
  projects: [];
  products: any;
  packages: [];
}

const initialState: IPruchasesState = {
  products: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 10,
    totalPages: 1,
  },
  packages: [],
  projects: [],
};

export const PurchaseSlice = createSlice({
  name: "order",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPurchasesAsync.pending, (state) => {})
      .addCase(getPurchasesAsync.fulfilled, (state, action) => {
        const { type, docs, errors } = action.payload;
        if (errors) {
          return;
        }
        switch (type) {
          case "project":
            state.projects = docs;
            break;
          case "product":
            state.products = action.payload;
            break;
          case "package":
            state.packages = docs;
            break;
        }
      });
  },
});

export const {} = PurchaseSlice.actions;

export default PurchaseSlice.reducer;

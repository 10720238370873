import appLocalStorage from "utils/appLocalStorage";

export const getURL = (type: string) => {
  return (
    process.env.REACT_APP_FILE
      ? process.env.REACT_APP_FILE
      : "http://localhost:3003"
  ).concat(type === "upload" ? "/api/file-basic/" : "/api/files/");
};

export const fileReqHeader = {
  "X-Access-Token": appLocalStorage.get("s3-auth-service"),
};

/* eslint-disable react-hooks/exhaustive-deps */
import VTable from "components/v-table";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getAuthSessionsAsync } from "redux/reducers/users/users.actions";
import { getAuthSessions } from "redux/reducers/users/users.selector";
import { IObject } from "types";
import { formatDate } from "utils/common";

const View = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const authSessions = useAppSelector(getAuthSessions);

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      key: "Date_login",
      title: t("Date Login"),
      render: (item: IObject) => formatDate(item.createdAt),
    },

    {
      key: "IP",
      title: t("IP Address"),
      render: (item: IObject) => item.ip || "N/A",
    },
    // {
    //   key: "City",
    //   title: t("City"),
    //   render: (item: IObject) =>
    //     item.detail && item.detail.city ? item.detail.city : "N/A",
    // },
    {
      key: "UserAgent",
      title: t("User agent"),
      render: (item: IObject) => item.user_agent,
    },
  ];

  const getData = () => {
    dispatch(getAuthSessionsAsync());
  };

  return (
    <Card isOpacity={true}>
      <div className="p-3">
        <h3>{t("Auth Sessions")}</h3>
        <div className="overflow-auto">
          <VTable columns={columns} dataSource={authSessions.docs} />
        </div>
      </div>
    </Card>
  );
};

export default View;

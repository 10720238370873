import VInput from "components/v-input";
import Button from "components/v-button";
import { toast } from "react-toastify";
import Network from "../networks";
import useTranslation from "hooks/useTranslation";

const Step1 = ({ dataPayment, nextStep, setDataByKey, backStep }) => {
  const { t } = useTranslation();
  const handleChange = (e) => {
    setDataByKey("amount", e);
  };

  const handleClick = () => {
    if (dataPayment.currency === "vnd" && +dataPayment.amount < 10000)
      return toast.error("Minimum deposit is 10000vnd!");
    if (dataPayment.currency === "usdt" && +dataPayment.amount <= 0)
      return toast.error("Amount must be greater 0!");
    nextStep();
  };

  return (
    <div className="content-right-view">
      {dataPayment.currency === "usdt" && (
        <Network
          network={dataPayment.network}
          setNetwork={(data) => {
            setDataByKey("network", data);
          }}
        />
      )}
      <VInput
        isCurrency={true}
        label={`${t(`Amount`)} (${String(dataPayment.currency || "").toUpperCase()}) *`}
        value={dataPayment.amount}
        onChange={(e) => handleChange(e.target.value)}
      />
      <div className="text-description-warning">
        <img src="/assets/images/icons/warning.svg" alt="warning" />
        <p>
          {t(`${dataPayment.currency.toUpperCase()} deposit is free. Vzonex does not
          collect ${dataPayment.currency.toUpperCase()} deposit fee, however, in
          some instances, you may have to bear a small transaction fee charged
          directly by the processing bank or network.`)}
        </p>
      </div>
      <div className="submit-step">
        <Button onClick={handleClick}>{t("Continue")}</Button>
        <Button transparent={true} onClick={() => backStep()}>
          {t("Back")}
        </Button>
      </div>
    </div>
  );
};

export default Step1;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Row, Col } from "react-bootstrap";
import {
  formatNumber,
  getBase64Image,
  getFullNameByNumber,
  getUrlImage,
} from "utils/common";
import VTable from "components/v-table";
import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import DownloadImg from "components/download/View";
// import APIs from "api";
import useTranslation from "hooks/useTranslation";
import InvestService from "api/invest";

const Invoice = () => {
  const { id } = useParams();
  const [invoice, setInvoice] = useState(null);
  const { t } = useTranslation();
  const history = useHistory();
  const imgRef = useRef(null);
  const [logo, setLogo] = useState(null);

  const columns = [
    {
      key: "Logo",
      title: <span style={{ fontWeight: 700 }}>{t("Hình ảnh")}</span>,
      render: () => (
        <img
          className="logo"
          src={logo || invoice.package_detail.icon}
          alt="logo"
        />
      ),
    },
    {
      key: "Product",
      title: <span style={{ fontWeight: 700 }}>Tên sản phẩm</span>,
      render: () => `${invoice.package_detail.name || "N/A"}`,
    },
    {
      key: "Amount",
      title: <span style={{ fontWeight: 700 }}>Số lượng</span>,
      render: () =>
        `${formatNumber(invoice.investment.principal.total)} (${
          invoice.package_detail
            ? String(invoice.investment.principal.stock).toUpperCase()
            : "N/A"
        })`,
    },
    {
      key: "Reward",
      title: <span style={{ fontWeight: 700 }}>Phần thưởng</span>,
      render: () =>
        `${formatNumber(invoice.investment.principal.total)} (${
          invoice.package_detail
            ? String(invoice.investment.principal.stock).toUpperCase()
            : "N/A"
        })`,
    },

    {
      key: "Time",
      title: <span style={{ fontWeight: 700 }}>Thời gian</span>,
      render: () =>
        `${formatNumber(invoice.investment.time_lock)} (${
          `${String(invoice.investment.type_lock)}s` || "N/A"
        })`,
    },
  ];

  useEffect(() => {
    if (id) {
      getData();
    }
  }, [history, id]);

  useEffect(() => {
    const getImages = async () => {
      if (invoice && invoice.package_detail) {
        const res = getUrlImage(invoice.package_detail.logoLevel);
        setLogo(res);
      }
    };
    getImages();
  }, [invoice]);

  const getData = async () => {
    try {
      const res = await InvestService.getInvoice({
        params: {
          id,
        },
      });
      setInvoice(res);
    } catch (error) {}
  };

  return (
    <>
      <div ref={imgRef} className="container-invoice pt-0 pl-0">
        <div className="thumbnail">
          <img src="/assets/images/invoice-thumbnail.png" alt="" />
        </div>
        <div className="logo-top">
          <img src="/assets/images/logo.png" alt="logo" />
        </div>

        {invoice && (
          <>
            <div>
              <div className="invoice-title">
                <img src="/assets/images/invoice-header.png" alt="" />
                <span>{t("HÓA ĐƠN")}</span>
              </div>

              <Row>
                <Col xs={4}>
                  <p className="heading-bold">{t("Thông tin")}</p>
                  <div className="row-item">
                    <span className="label">{t("Mã số")}:</span>
                    <span className="value">
                      #{new Date(invoice.createdAt).getTime()}
                    </span>
                  </div>
                  <div className="row-item">
                    <span className="label">{t("Địa chỉ ví")}:</span>

                    <a
                      href={`https://block.vdiarybook.com/tx/${invoice.investment.hash}`}
                      className="value"
                    >
                      {getFullNameByNumber(invoice.investment.hash, 20)}
                    </a>
                  </div>

                  <div className="row-item">
                    <span className="label">{t("Cổ phiếu")}:</span>
                    <span className="value">
                      #{new Date(invoice.createdAt).getTime()}
                    </span>
                  </div>

                  <div className="row-item">
                    <span className="label">{t("Ngày")}:</span>
                    <span className="value">
                      {new Date(invoice.createdAt).toLocaleDateString()}
                    </span>
                  </div>
                </Col>
                <Col xs={4}>
                  <p className="heading-normal">{t("Người gửi")}</p>
                  <div className="row-item">
                    <span className="label">support@vdiarybook.com</span>
                  </div>
                  <div className="row-item">
                    <span className="label">{t("Địa chỉ")}:</span>
                    <span className="value">
                      Số 6-A12, KĐT Lê Trọng Tấn - Gleximco, An Khánh, Hoài Đức,
                      Hà Nội.
                    </span>
                  </div>
                </Col>
                <Col xs={4}>
                  <p className="heading-bold">{t("Người nhận")}</p>
                  <div className="row-item">
                    <span className="label">{invoice.user.full_name}</span>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="content">
              <VTable columns={columns} dataSource={[invoice]}></VTable>
            </div>

            <Row>
              <Col>
                <div className="invoice-payment-info">
                  <img src="/assets/images/invoice-qr.png" alt="" />
                  <div>
                    <div>
                      <img
                        src="/assets/images/PaymentBg.png"
                        alt=""
                        className="payment-info-img"
                      />
                      <div>
                        <div>
                          <div className="payment-row-item">
                            <div>
                              <p>SubTotal:</p>
                              <span>{`${formatNumber(
                                invoice.investment.principal.total
                              )} (${
                                invoice.package_id
                                  ? String(
                                      invoice.investment.principal.stock
                                    ).toUpperCase()
                                  : "N/A"
                              })`}</span>
                            </div>
                            <div>
                              <p>{`Tax(VAT):`} </p>
                              <span>0</span>
                            </div>
                            <div>
                              <p>Discount(0):</p>
                              <span>{`${formatNumber(
                                invoice.investment.principal.total
                              )} (${
                                String(
                                  invoice.investment.principal.stock
                                ).toUpperCase() || "N/A"
                              })`}</span>
                            </div>
                            <div>
                              <p>Total due:</p>
                              <span>{`${formatNumber(
                                invoice.investment.principal.total
                              )} (${
                                invoice.package_id
                                  ? String(
                                      invoice.investment.principal.stock
                                    ).toUpperCase()
                                  : "N/A"
                              })`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="invoice-footer">
              <span>
                {t(
                  "Mọi thắc mắc liên quan đến tài liệu này vui lòng liên hệ với chúng tôi"
                )}
              </span>
              <p>{t("Cảm ơn bạn đã tin tưởng chúng tôi!")}</p>
            </div>
          </>
        )}
      </div>

      {invoice && (
        <DownloadImg
          imgRef={imgRef}
          fileName={`invoice_${invoice.package_detail.token}`}
        />
      )}
    </>
  );
};

export default Invoice;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import VBannerPage from "components/v-teamplate/v-banner-page";

const View = () => {
  return (
    <div className="v-welcome container">
      <VBannerPage position="banner_welcome"/>
    </div>
  );
};

export default View;

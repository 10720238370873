import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Card from "components/v-teamplate/Card";

//Sweet alert
import FriendRequest from "./FriendRequest";
import useTranslation from "hooks/useTranslation";
import FriendSuggest from "./FriendSuggest";
import VRightView from "components/v-right-view";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const FriendRequestAndSuggest = () => {
  const { t } = useTranslation();
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null

  // const questionAlert = () => {
  //   const swalWithBootstrapButtons = Swal.mixin({
  //     customClass: {
  //       cancelButton: "btn btn-outline-primary btn-lg ms-2",
  //       confirmButton: "btn btn-primary btn-lg",
  //     },
  //     buttonsStyling: false,
  //   });

  //   swalWithBootstrapButtons
  //     .fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       cancelButtonText: "cancel",
  //       confirmButtonText: "Yes, delete it!",

  //       reverseButtons: false,
  //       showClass: {
  //         popup: "animate__animated animate__zoomIn",
  //       },
  //       hideClass: {
  //         popup: "animate__animated animate__zoomOut",
  //       },
  //     })
  //     .then((result) => {
  //       if (result.isConfirmed) {
  //         swalWithBootstrapButtons.fire({
  //           title: "Deleted!",
  //           text: "Your Request has been deleted.",
  //           icon: "success",
  //           showClass: {
  //             popup: "animate__animated animate__zoomIn",
  //           },
  //           hideClass: {
  //             popup: "animate__animated animate__zoomOut",
  //           },
  //         });
  //       } else if (
  //         /* Read more about handling dismissals below */
  //         result.dismiss === Swal.DismissReason.cancel
  //       ) {
  //         swalWithBootstrapButtons.fire({
  //           title: "Your Request is safe!",
  //           showClass: {
  //             popup: "animate__animated animate__zoomIn",
  //           },
  //           hideClass: {
  //             popup: "animate__animated animate__zoomOut",
  //           },
  //         });
  //       }
  //     });
  // };

  return (
    <>
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">{t("Friend request")}</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <FriendRequest />
              </Card.Body>
            </Card>

            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div className="header-title">
                  <h4 className="card-title">{t("People you may now")}</h4>
                </div>
              </Card.Header>
              <Card.Body>
                <FriendSuggest />
              </Card.Body>
            </Card>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_request"}
                positionBanner={"banner_right_request"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </>
  );
};

export default FriendRequestAndSuggest;

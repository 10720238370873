/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import useTranslation from "hooks/useTranslation";
import Card from "components/v-teamplate/Card";
import VAvatar from "components/v-avatar";
import VButton from "components/v-button";
import VTimeline from "components/v-timeline";
import FromTemplateService from "api/form-templates";
import Register from "./Register";
import { BsFillInfoCircleFill } from "react-icons/bs";
import useQuery from "hooks/useQuery";

const Verification = () => {
  const { category, type } = useQuery();
  const user = useAppSelector(getUserProfile);
  const [status, setStatus] = useState("init");
  const [percent, setPercent] = useState(50);
  const [formAdditionalData, setFormAdditionalData] = useState<any>(null);
  const [showRegisterForm, setShowRegisterForm] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (type === "register" && formAdditionalData) {
      setShowRegisterForm(true);
    }
  }, [type, formAdditionalData]);

  const getFormTemplateKyc = async () => {
    try {
      const res: any = await FromTemplateService.getForm({
        query: {
          type: "register_receive_info",
        },
      });
      if (res) {
        setFormAdditionalData(res);
      }
    } catch (error) {
      setFormAdditionalData(null);
    }
  };

  useEffect(() => {
    if (user.object_type === "COMMUNITY") {
      setStatus("verified");
      setPercent(100);
    }
    getFormTemplateKyc();
  }, [user.object_type]);

  const getColorVerify = (type, status) => {
    if (type === "verify")
      switch (status) {
        case "pending_verified":
          return "pending";
        case "declined_verified":
          return "declined";
        case "verified":
          return "success";
        default:
          return "gray";
      }
    else
      switch (status) {
        case "pending_kyc":
          return "pending";
        case "kyc":
          return "success";
        case "pending_verified":
          return "success";
        case "verified":
          return "success";
        case "declined_verified":
          return "success";
        default:
          return "gray";
      }
  };

  const renderListRequest = (form) => {
    return (
      form &&
      form.fields?.length > 0 &&
      form.fields?.map((el, index) => <li key={index}>{t(el.label)}</li>)
    );
  };

  const getInfoStep = (step) => {
    const isRegistered =
      user?.register_receive_info &&
      user.register_receive_info.status === "verified";
    // && user.object_type === "COMMUNITY";

    switch (step) {
      case 0:
        return {
          title: t("Verified"),
          bgColorTime: "#3498db33",
          colorTime: "#4284F3",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">
                    {t("Update personal information")}
                  </h6>
                  <span className="fw-600">- {t("Verified")}:</span>
                  <ul>
                    <li>{t("Country, province, district or ward")}</li>
                    <li>{`${t("Personal description")}`}</li>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      case 1:
        return {
          title: isRegistered ? t("Verified") : t("Not verified"),
          bgColorTime: isRegistered ? "#3498db33" : "",
          colorTime: isRegistered ? "#4284F3" : "",
          children: (
            <Card>
              <Card.Body>
                <div>
                  <h6 className="fw-bold">{t("Verify censor information")}</h6>
                  <span className="fw-600">- {t("Verified")}:</span>
                  <ul>
                    <ul>{renderListRequest(formAdditionalData)}</ul>
                  </ul>
                </div>
              </Card.Body>
            </Card>
          ),
        };
      default:
        return {
          title: "",
          children: <></>,
        };
    }
  };

  return (
    <div>
      {showRegisterForm ? (
        <Register
          formAdditionalData={formAdditionalData}
          onHide={() => {
            setShowRegisterForm(false);
          }}
          category={category}
        />
      ) : (
        <>
          <Card.Header className="card-header d-flex justify-content-between information-header-setting">
            <div className="information-user">
              <VAvatar
                className="m-0"
                size={"70px"}
                fontSize={"22px"}
                fontWeight={"600"}
                userData={user}
                isEdit={false}
                additionalData={
                  <div className="status-verify-account">
                    <span className="me-2">{t(status)}</span>
                    <img
                      src={`/assets/icons/default/verify-${getColorVerify(
                        status !== "pending_verified" &&
                          status !== "declined_verified" &&
                          status !== "verified"
                          ? "kyc"
                          : "verify",
                        status
                      )}.svg`}
                      alt=""
                    />
                  </div>
                }
              />
            </div>
            <div
              className="title-circular"
              style={{ width: "70px", height: "100%" }}
            >
              <CircularProgressbar
                value={percent}
                text={`${percent}%`}
                styles={buildStyles({
                  textColor: percent === 100 ? "#00AC11" : "#FFE600",
                  pathColor: "#4284F3",
                  textSize: "20px",
                })}
              />
            </div>
          </Card.Header>
          <Card.Body>
            <div className="verification-wrapper">
              <div className="d-flex flex-wrap justify-content-between">
                <div className="col-12 col-md-6 mb-4 content-kyc-request">
                  <Card className="right-left p-2">
                    <p className="censor">{t("register receive info")}</p>
                    <p>{t("register receive info description")}</p>
                    {user.register_receive_info?.status !== "verified" && (
                      <div className="button-wrapper">
                        <VButton
                          className="btn-censor-register"
                          onClick={() => setShowRegisterForm(true)}
                        >
                          {t("register receive info")}
                        </VButton>
                      </div>
                    )}
                  </Card>
                  {user?.register_receive_info &&
                    user.register_receive_info.status === "denied" && (
                      <>
                        <>
                          <BsFillInfoCircleFill color="yellow" />{" "}
                          <span>{t("note")}:</span>
                        </>
                        <div
                          dangerouslySetInnerHTML={{ __html: user.censor.note }}
                        />
                      </>
                    )}
                </div>
                <div className="px-1 col-12 col-md-6">
                  <VTimeline
                    data={Array(2)
                      .fill(0)
                      .map((el, index) => ({
                        icon: (
                          <img
                            src={`/assets/icons/default/verify-${
                              index === 0 || index === 1
                                ? "success"
                                : getColorVerify(
                                    index === 2 ? "kyc" : "verify",
                                    status
                                  )
                            }.svg`}
                            alt=""
                          />
                        ),
                        ...getInfoStep(index),
                      }))}
                  />
                </div>
              </div>
            </div>
          </Card.Body>
        </>
      )}
    </div>
  );
};

export default Verification;

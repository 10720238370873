import { Button, Spinner } from "react-bootstrap";

type IProps = {
  children?: any;
  transparent?: any;
  color?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e?: any) => any;
  className?: any;
  variant?: string;
  icon?: any;
  [key: string]: any;
};

const View = ({
  children,
  transparent = false,
  color = "",
  loading = false,
  variant = "primary",
  icon,
  disabled,
  onClick,
  ...props
}: IProps) => {
  return (
    <Button
      color={color}
      disabled={loading || disabled}
      variant={variant}
      onClick={!loading ? onClick : null}
      {...props}
      className={`v-button ${props.className || ""}`}
    >
      <div className="children">
        <div
          className="icon"
          style={{
            marginRight: ((icon && children) || loading) ? "5px" : "0px",
          }}
        >
          {loading ? (
            <Spinner
              style={{
                borderRadius: "50%",
              }}
              size="sm"
            ></Spinner>
          ) : (
            icon && icon
          )}
        </div>
        {children}
      </div>
    </Button>
  );
};

export default View;

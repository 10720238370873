import { request, parseErrorResponse } from "./s3-request";

/**
 *
 * return data: {
 *    filePath,
 *    Key
 * }
 */

const getUploadVzonexS3AccessToken = (force = false) =>
  new Promise((resolve, reject) => {
    const cToken = localStorage.getItem("s3-auth-service");
    if (!cToken || force === true) {
      request()
        .get(`/api/auth/request-access-token`)
        .then((res) => {
          const { token } = res.data;
          if (token) {
            localStorage.setItem("s3-auth-service", token);
          }
        })
        .catch(parseErrorResponse)
        .then(() => {
          resolve(null);
        });
    } else {
      resolve(null);
    }
  });

getUploadVzonexS3AccessToken(true).catch((err) => {});

const uploadFilesVzonexS3 = (formData, isFull = false) =>
  new Promise((resolve, reject) => {
    request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })
      .post(`/api/files`, formData)
      .then((res) => {
        const { files } = res.data;

        const data = files.map((file, idx) => ({
          ...(isFull ? file : {}),
          filePath: file.filePath,
          id: idx,
        }));

        resolve(data);
      })
      .catch(parseErrorResponse)
      .then((err) => {
        getUploadVzonexS3AccessToken(true);
        reject(err);
      });
  });

const uploadFile = (data, isFull = false) =>
  new Promise((resolve, reject) => {
    if (!data.body.get("folderPath")) {
      data.body.append("folderPath", "users");
    }

    uploadFilesVzonexS3(data.body, isFull)
      .then((files) => {
        resolve(files[0]);
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

/**
 *
 * return data: [{
 *    filePath,
 *    Key
 *    id
 * }]
 */

const uploadMultiFile = (data) =>
  new Promise((resolve, reject) => {
    if (!data.body.get("folderPath")) {
      data.body.append("folderPath", "unknown");
    }
    uploadFilesVzonexS3(data.body)
      .then((files) => {
        resolve(files);
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UploadService = {
  getUploadVzonexS3AccessToken,
  uploadFile,
  uploadMultiFile,
};

export default UploadService;

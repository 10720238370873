import { parseErrorResponse, request } from "./request";

const getQuotas = () => {
  return new Promise((resolve, reject) => {
    request()
      .get(`/quotas?type=quotas`)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const getSpecialDate = () => {
  return new Promise((resolve, reject) => {
    request()
      .get(`/quotas/get-special-date`)
      .then((res) => {
        const { data } = res.data;
        if (data) {
          resolve(data);
        } else {
          reject(new Error("Something went error!"));
        }
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
};

const QuotasService = {
  getQuotas,
  getSpecialDate
};

export default QuotasService;

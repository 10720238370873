import React from "react";
import VBanner from "components/v-banner";

type IProps = {
  position?: string;
};

const VBannerPage = (props: IProps) => {
  return (
    <>
      <div className="header-for-bg">
        <div className="background-header position-relative">
          {/* <div className="container px-0"> */}
          <VBanner position={props.position} isBannerPage={true} />
          {/* </div> */}
        </div>
      </div>
    </>
  );
};
export default VBannerPage;

/* eslint-disable react-hooks/exhaustive-deps */
import PostService from "api/posts";
import VAvatar from "components/v-avatar";
// import VPanigation from "components/v-panigation";
import VTable from "components/v-table";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";

const Post = () => {
  const { t } = useTranslation();

  const [ranking, setRanking] = useState([]);

  const columns = [
    {
      key: "ratings",
      title: t("Position"),
      render: (item: any, index) => {
        return <span>{index + 1}</span>;
      },
    },
    {
      key: "User",
      title: t("User"),
      render: (item: any) => {
        return (
          <div className="d-flex justify-content-between cursor-pointer">
            <VAvatar
              className="m-1"
              isClick={true}
              size="25px"
              fontSizeIconVerify={12}
              userData={{
                ...item.profile,
                ...item.user,
              }}
            />
          </div>
        );
      },
    },
    {
      key: "total",
      title: t("Total posts"),
      render: (item) => {
        return item.count;
      },
    },
  ];

  useEffect(() => {
    getRankings(1);
  }, []);

  const getRankings = async (page) => {
    const res: any = await PostService.getRankings({
      query: {
        limit: 10,
        page,
      },
    });
    if (res) setRanking(res);
  };

  return (
    <div className="forum-content">
      <div className="table-container-forum">
        <VTable columns={columns} dataSource={ranking}></VTable>
      </div>
    </div>
  );
};

export default Post;

/* eslint-disable indent */
import React, { useRef, useState } from "react";
import Card from "components/v-teamplate/Card";
import VAvatar from "components/v-avatar";
import VActionBusiness from "../v-actions-business";
import { Col } from "react-bootstrap";
import LoadingBar from "react-top-loading-bar";
import useTranslation from "hooks/useTranslation";
import PageBackgrounds from "components/v-page-background";
import { OBJECT_TYPE } from "config/enums";
import VNameVerify from "../v-name-verify";
import { useHistory } from "react-router-dom";
import { isMobile } from "react-device-detect";

type IProps = {
  business: {
    [key: string]: any;
  };
  type?: string;
  reload?: any;
  col_lg?: number;
  col_md?: number;
  className?: string;
  count?: number
};

const View = ({
  business,
  type,
  reload,
  col_lg,
  col_md,
  className,
  count
}: IProps) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [progress, setProgress] = useState(0);
  const refLoad = useRef(null);

  const handleClick = (url) => {
    history.push(url);
  };
  const setLoading = (loading) => {
    if (refLoad.current)
      if (loading) {
        refLoad.current.continuousStart();
      } else {
        refLoad.current.complete();
      }
  };

  // const count =
  //   business.count && business.count.count
  //     ? business.count.count
  //     : {
  //         post: 0,
  //         follower: 0,
  //         business: 0,
  //       };

  return (
    <Col
      md={col_md || 4}
      lg={col_lg || 4}
      className={`${className ? className : ""} v-business-card col-6`}
    >
      <LoadingBar
        color="#f11946"
        progress={progress}
        onLoaderFinished={() => setProgress(0)}
        ref={refLoad}
      />

      <Card className="mb-0">
        <div className="top-bg-image">
          <div className="cover-image">
            <PageBackgrounds
              page={"page_bg_profile"}
              width="100%"
              height="100%"
              position="relative"
              zIndex={"0"}
              object_type={OBJECT_TYPE.BUSINESS}
              data={business?.profile?.covers}
            />
          </div>
        </div>
        <Card.Body className=" text-center">
          <div className="group-icon">
            <VAvatar
              isClick={true}
              userData={business}
              size={isMobile ? "60px" :"80px"}
              hideName={true}
            />
          </div>
          <div className="group-info py-2">
            <h4 onClick={() => handleClick(`/business/${business._id}`)}>
              <VNameVerify
                className={
                  "mb-0 d-flex align-items-center justify-content-center cursor-pointer"
                }
                dataUser={business}
              />
            </h4>
            <p className="mb-0 text-ellipsis short-2">
              {" "}
              {business.profile && business.profile.slogan
                ? business.profile.slogan
                : t("Share moments - Keep good memories")}
            </p>
          </div>
          <div className="group-details d-inline-block pb-3">
            <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0">
              <li className="pe-3 ps-3">
                <p className="mb-0">
                  <span className="me-1 fw-bold">{count || 0}</span>
                  {t("Member")}
                </p>
              </li>
            </ul>
          </div>
          {type && (
            <VActionBusiness
              className="btn  d-block w-100"
              type={type}
              isShowText={true}
              to_id={business._id}
              reload={reload}
              setLoading={setLoading}
            />
          )}
        </Card.Body>
      </Card>
    </Col>
  );
};

export default View;

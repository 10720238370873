import { IRootState } from "..";

export const getAdvertisements = (state: IRootState) =>
  state.advertisements.advertisements;
export const getAdvertisement = (state: IRootState) =>
  state.advertisements.advertisement;
export const getAccessAdvertisment = (state: IRootState) =>
  state.advertisements.access;
export const getIsOpenBanner = (state: IRootState) =>
  state.advertisements.isOpenBanner;

import useTranslation from "hooks/useTranslation";
import { copyText } from "utils/common";
import { toast } from "react-toastify";
import VIcon from "components/v-icon";

/* eslint-disable indent */
const ReferralCode = ({ dataUser }) => {
  const { t } = useTranslation();

  const handleCopy = (value) => {
    copyText(value);
    toast.success(t("Copied"));
  };
  return (
    <div>
      <span className="d-flex justify-content-center">
        <span className="me-2 text-danger">*</span>
        {t("Referral code shared with new members")}
      </span>
      <div className="business-code-profile">
        <div className="code">{dataUser?.refer_code}</div>
        <VIcon
          className="cursor-pointer ms-2"
          name="copy.svg"
          onClick={() => handleCopy(dataUser?.refer_code)}
        />
      </div>
    </div>
  );
};

export default ReferralCode;

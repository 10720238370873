/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, { useEffect, useMemo, useRef, useState } from "react";
import Card from "components/v-teamplate/Card";
import { Link } from "react-router-dom";

import useTranslation from "hooks/useTranslation";
import VModal from "components/v-modal";
import Avatar from "components/v-avatar";

import DropdownModal from "components/v-dropdown-modal";
import PageBackgrounds from "components/v-page-background";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";

import APIs from "api";
import { toast } from "react-toastify";
import EditCover from "./components/EditCover";
import VIcon from "components/v-icon";
import {
  getUserAsync,
  getUserByIdAsync,
} from "redux/reducers/users/users.actions";
import VNameVerify from "components/v-objects/v-name-verify";
import { checkSizeFileUpload, getUrlImage } from "utils/common";
import { ROUTES } from "config/routes";
import VTooltip from "components/v-tooltip";
import ReusePhotos from "./components/ReusePhotos";
import VBannerPage from "components/v-teamplate/v-banner-page";
import Marquee from "react-fast-marquee";
import { isMobile } from "react-device-detect";
import UsersMedalsService from "api/users-medals";
import WelcomeModal from "components/welcome-modal";
import SocialInfoProfile from "./components/InfoProfile";
import SocialActionProfile from "./components/ActionButton";
import FriendService from "api/friends";
import EditFrame from "./components/EditFrame";
import ProductService from "api/products";
import { getFrameUseDefault } from "redux/reducers/settings/settings.selector";

const View = (props) => {
  const { dataUser, isMyProfile, isChannel, subBackground } = props;
  const dispatch = useAppDispatch();

  const [openModal, setOpenModal] = useState(false);
  const [action, setAction] = useState("");
  const [typeEdit, setTypeEdit] = useState("");
  const [loading, setLoading] = useState(false);
  const [userMedals, setUserMedals] = useState<any>([]);
  const [mascots, setMascots] = useState<any[]>([]);
  const [relation, setRelation] = useState({
    fromFollow: null,
    fromFriend: null,
    toFriend: null,
    fromObject: null,
    toObject: null,
  });

  const [tradeMarks, setTradeMarks] = useState([]);

  const coverRef = useRef(null);

  const { t } = useTranslation();

  useEffect(() => {
    if (dataUser && dataUser._id) {
      getUserMedalsByUserId(dataUser._id);
      getMascots(dataUser._id);
      getTradeMark();
      if (!isMyProfile) {
        getRelation();
      }
    }
  }, [dataUser, isMyProfile]);

  const getRelation = async () => {
    const res: any = await FriendService.getRelationWithToUser({
      params: {
        to_id: dataUser._id,
      },
    });
    setRelation(res);
  };

  const getUserMedalsByUserId = async (userId: string) => {
    try {
      const res: any = await UsersMedalsService.getUsersMedalsByUserId({
        params: {
          id: userId,
        },
      });
      if (res) {
        setUserMedals(res);
      }
    } catch (error) {}
  };

  const getMascots = async (userId: string) => {
    try {
      const res: any = await APIs.PRODUCT.getProductInStorageByType({
        query: {
          type: "mascot",
          user_id: userId,
        },
      });
      if (res && res?.docs) {
        setMascots(res.docs);
      }
    } catch (error) {}
  };

  const getTradeMark = async () => {
    try {
      const res: any = await ProductService.getCategoriesByTradeMark({
        query: {
          type_trademark: "charity",
        },
      });
      if (res) {
        const trade_mark = dataUser.count?.trade_mark || {};
        const data = res
          .map((i) => ({
            ...i,
            amount: trade_mark[i._id] || 0,
          }))
          .sort((a, b) => (a.amount - b.amount < 0 ? 1 : -1));
        setTradeMarks(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleClick = (name: string, typeEdit?: string) => {
    setAction(name);
    setOpenModal(true);
    if (typeEdit) {
      setTypeEdit(typeEdit);
    }
  };

  const submit = () => {
    coverRef.current.submit();
  };

  const handleUploadAvatar = async (file: any) => {
    try {
      if (file) {
        if (checkSizeFileUpload(file) > 20)
          return toast.error(t("File size must not exceed 20Mb"));
        const formData = new FormData();
        formData.append("files", file);
        formData.append("folderPath", `users/${dataUser._id}`);
        const imgRes: any = await APIs.UPLOAD.uploadFile(
          {
            body: formData,
          },
          true
        );
        if (imgRes) {
          const res: any = await APIs.USER.editUser({
            body: {
              user_id: dataUser._id,
              avatar: imgRes.filePath,
            },
          });
          if (res) {
            if (isMyProfile) dispatch(getUserAsync());
            dispatch(
              getUserByIdAsync({
                params: {
                  id: dataUser._id,
                },
              })
            );
          }
        }
      }
    } catch (error: any) {
      toast.success(t(error?.errors));
    }
  };
  const frameDefault = useAppSelector(getFrameUseDefault);

  const frameCoverDefault = useMemo(() => {
    const frameCoverDefault = frameDefault?.find(
      (el) => el.frame_type === "frame_background"
    );
    if (frameCoverDefault) {
      return frameCoverDefault.image;
    }
    return null;
  }, [frameDefault]);

  const renderContentModal = (action) => {
    switch (action) {
      case "update_cover":
        return (
          <EditCover
            ref={coverRef}
            action={action}
            dataUser={dataUser}
            setLoading={setLoading}
            setOpenModal={setOpenModal}
          />
        );
      case "update_background_profile":
        return (
          <EditCover
            ref={coverRef}
            action={action}
            dataUser={dataUser}
            setLoading={setLoading}
            setOpenModal={setOpenModal}
          />
        );
      case "popup_welcome":
        return (
          <EditCover
            ref={coverRef}
            action={action}
            dataUser={dataUser}
            setLoading={setLoading}
            setOpenModal={setOpenModal}
          />
        );
      case "choose_image":
        return (
          <ReusePhotos
            ref={coverRef}
            type={typeEdit}
            dataUser={dataUser}
            isMyProfile={isMyProfile}
            setLoading={setLoading}
            setOpenModal={setOpenModal}
          />
        );
      case "edit_frame":
        return (
          <EditFrame
            ref={coverRef}
            type={typeEdit}
            dataUser={dataUser}
            isMyProfile={isMyProfile}
            setLoading={setLoading}
            setOpenModal={setOpenModal}
          />
        );
      default:
        break;
    }
  };

  const renderMascots = (position) => {
    if (position === "left")
      return (
        <ul className="social-data-block d-flex align-items-center justify-content-start flex-wrap list-inline p-0 m-0">
          {mascots?.length > 0 &&
            mascots.slice(0, 3).map((el: any) => (
              <li className="text-center px-1" key={el._id}>
                <div className="users-mascost">
                  <Link to={`${ROUTES.PROFILE_MASCOTS}?detail=${el?._id}`}>
                    <VTooltip
                      key={`${el._id}_mascot`}
                      height="100%"
                      placement="top"
                      content={<span>{el?.name}</span>}
                    >
                      <img
                        loading="lazy"
                        src={getUrlImage(el?.thumbnail, 150)}
                        className="img-fluid rounded"
                        alt={el?.name}
                      />
                    </VTooltip>
                  </Link>
                </div>
              </li>
            ))}
        </ul>
      );

    if (position === "right")
      return (
        <ul className="social-data-block d-flex align-items-center justify-content-end flex-wrap list-inline p-0 m-0">
          {tradeMarks?.length > 0 &&
            tradeMarks.slice(0, 3).map((el: any) => (
              <li className="text-center px-1" key={el._id}>
                <div className="users-trade-marks p-1">
                  <Link to={`/my/shop?category=_${el?._id}_`}>
                    <VTooltip
                      key={`${el._id}_trademark`}
                      height="100%"
                      placement="top"
                      content={<span>{el?.name}</span>}
                    >
                      <img
                        loading="lazy"
                        src={getUrlImage(el?.icon, 50)}
                        className="img-fluid rounded"
                        alt={el?.name}
                      />
                    </VTooltip>
                  </Link>
                </div>
                <span>{el.amount}</span>
              </li>
            ))}
        </ul>
      );
  };
  return (
    <>
      <WelcomeModal
        isPopupProfile={dataUser?.profile?.popup_welcome?.status === "enable"}
        data={
          dataUser?.profile?.popup_welcome?.status === "enable"
            ? dataUser?.profile?.popup_welcome
            : null
        }
      />
      <Card>
        <Card.Body className=" profile-page p-0">
          <div className="profile-header">
            <div className="container-cover">
              {frameCoverDefault && (
                <div className="frame-cover-profile">
                  <img src={getUrlImage(frameCoverDefault, 1920)} alt="" />
                </div>
              )}

              <div className="cover-image cursor-pointer">
                {isChannel && !dataUser?.profile?.covers?.type ? (
                  <VBannerPage position="banner_top_profile_videos" />
                ) : (
                  <PageBackgrounds
                    page={"page_bg_profile"}
                    width="100%"
                    height="100%"
                    position="relative"
                    zIndex={"0"}
                    data={dataUser?.profile?.covers}
                    object_type={dataUser.object_type}
                    resize_image={1000}
                    subBackground={subBackground}
                  />
                )}
              </div>

              {isMyProfile && (
                <ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0 profile-action">
                  <li className="rounded pointer text-center">
                    {/* <div
                      className="item-action-corver"
                      onClick={(_) => handleClick("update_cover")}
                    >
                      <VIcon name="edit-page.svg" />
                    </div> */}
                    <DropdownModal
                      label={
                        <div className="item-action-corver">
                          <VIcon name="edit-page.svg" />
                        </div>
                      }
                      menu={[
                        {
                          key: t("Update cover"),
                          disabled: !(
                            dataUser?.status_kyc === "kyc" ||
                            dataUser?.status_kyc === "verified"
                          ),
                          onClick: (_) => handleClick("update_cover"),
                        },
                        {
                          key: t("Background profile"),
                          disabled: !(
                            dataUser?.status_kyc === "kyc" ||
                            dataUser?.status_kyc === "verified"
                          ),
                          onClick: (_) =>
                            handleClick("update_background_profile"),
                        },
                        // {
                        //   key: t("Choose a photo"),
                        //   disabled: !(
                        //     dataUser?.status_kyc === "kyc" ||
                        //     dataUser?.status_kyc === "verified"
                        //   ),
                        //   onClick: (_) => handleClick("choose_image", "cover"),
                        // },
                        {
                          key: t("Popup welcome"),
                          disabled: !(
                            dataUser?.status_kyc === "kyc" ||
                            dataUser?.status_kyc === "verified"
                          ),
                          onClick: (_) => handleClick("popup_welcome"),
                        },
                      ]}
                    />
                  </li>
                </ul>
              )}
              {dataUser?.profile?.covers?.banners?.content?.length > 0 && (
                <div className="banner-cover-profile">
                  <div
                    className="container-banner"
                    style={{
                      color: dataUser?.profile?.covers?.banners?.text_color,
                      backgroundColor:
                        dataUser?.profile?.covers?.banners?.background_color,
                    }}
                  >
                    <Marquee speed={isMobile ? 50 : 100} gradient={false}>
                      {dataUser?.profile?.covers?.banners?.content?.map(
                        (el, index) => (
                          <div
                            className="item-marquee-content"
                            onClick={() => window?.open(el.link)}
                          >
                            {el.icon && (
                              <img src={getUrlImage(el.icon, 150)} alt="" />
                            )}
                            {el.value && (
                              <span key={index}>{el.value.toUpperCase()}</span>
                            )}
                          </div>
                        )
                      )}
                    </Marquee>
                  </div>
                </div>
              )}
            </div>
            {!isChannel && (
              <>
                <div className="profile-info d-flex justify-content-between p-2">
                  <div className="col-4 item-mascots-mobile start-0 d-flex flex-column justify-content-between">
                    {renderMascots("left")}
                    <ul className="social-data-block align-items-center list-inline p-0 m-0 d-none d-lg-flex mt-2">
                      <SocialInfoProfile t={t} dataUser={dataUser} />
                    </ul>
                  </div>
                  <div className="col-12 col-lg-4 user-detail text-center mb-0">
                    <div className="profile-img">
                      <div className="img-fluid d-flex justify-content-center">
                        <div className="d-flex">
                          {dataUser?.profile?.pairing?.user_id &&
                            dataUser?.profile?.pairing?.status ===
                              "approved" && (
                              <div style={{ marginRight: "-50px" }}>
                                <Avatar
                                  isPreview={true}
                                  hideName={true}
                                  size={"100px"}
                                  userData={dataUser?.profile?.pairing?.user_id}
                                  handleClickType={(e) =>
                                    handleClick(e, "avatar")
                                  }
                                  handleUpload={handleUploadAvatar}
                                />
                              </div>
                            )}
                          <div>
                            <Avatar
                              isEdit={isMyProfile}
                              isPreview={true}
                              hideName={true}
                              size={"100px"}
                              userData={dataUser}
                              handleClickType={(e) => handleClick(e, "avatar")}
                              handleUpload={handleUploadAvatar}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="profile-detail">
                      <h3
                        style={{
                          maxWidth:
                            dataUser?.full_name?.length > 20 ? "250px" : "100%",
                          margin: "auto",
                        }}
                      >
                        <VNameVerify
                          dataUser={dataUser}
                          isEditNickname={!isMyProfile && relation?.fromFriend}
                          relation={relation}
                          onLoad={getRelation}
                          className={"username"}
                        />
                      </h3>
                    </div>
                    <div className="d-flex justify-content-center pb-1">
                      <span className="_slogan-profile text-ellipsis short-1">
                        {dataUser?.profile?.slogan}
                      </span>
                    </div>
                    <div>
                      <ul className="social-data-block d-flex align-items-center justify-content-center flex-wrap list-inline p-0 m-0">
                        {userMedals?.length > 0 &&
                          userMedals
                            ?.sort(
                              (prev, next) =>
                                (next.count || 0) - (prev.count || 0)
                            )
                            ?.slice(0, 5)
                            .map((el: any) => (
                              <li className="text-center px-1" key={el._id}>
                                <div className="users-medals">
                                  <Link
                                    to={`${ROUTES.PROFILE_BADGES}?detail=${el?.feel_id?._id}`}
                                  >
                                    <VTooltip
                                      key={`${el._id}_feel`}
                                      height="100%"
                                      placement="top"
                                      content={<span>{el?.feel_id?.name}</span>}
                                    >
                                      <img
                                        loading="lazy"
                                        src={getUrlImage(
                                          el?.feel_id?.image,
                                          50
                                        )}
                                        className="img-fluid rounded"
                                        alt={el?.feel_id?.name}
                                      />
                                    </VTooltip>
                                  </Link>
                                </div>
                                <span>{el.count}</span>
                              </li>
                            ))}
                      </ul>
                    </div>
                  </div>
                  <div className="col-4 item-mascots-mobile end-0 d-flex flex-column justify-content-between">
                    {renderMascots("right")}
                    <div className="d-none d-lg-block mt-2">
                      <SocialActionProfile
                        t={t}
                        dataUser={dataUser}
                        isMyProfile={isMyProfile}
                        relationData={relation}
                      />
                    </div>
                  </div>
                </div>
                <div className="profile-info d-lg-none d-flex justify-content-center align-items-center flex-column">
                  <SocialActionProfile
                    t={t}
                    dataUser={dataUser}
                    isMyProfile={isMyProfile}
                    relationData={relation}
                  />
                  <ul className="social-data-block d-flex align-items-center list-inline p-0 m-0">
                    <SocialInfoProfile t={t} dataUser={dataUser} />
                  </ul>
                </div>
              </>
            )}
          </div>
        </Card.Body>

        <VModal
          // title={t(
          //   action === "update_cover"
          //     ? ""
          //     : action === "update_background_profile"
          //     ? "Background profile"
          //     : "Popup welcome"
          // )}
          isOpen={openModal}
          loading={loading}
          centered={true}
          children={renderContentModal(action)}
          onCancel={() => setOpenModal(false)}
          onConfirm={submit}
        />
      </Card>
    </>
  );
};

export default View;

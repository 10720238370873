/* eslint-disable indent */
/* eslint-disable no-unused-vars */
import CensorService from "api/censor";
import VButton from "components/v-button";
import VForm from "components/v-form";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { toast } from "react-toastify";
import { setProfile } from "redux/reducers/users/users.reducer";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";

const Register = ({
  formAdditionalData,
  onHide,
}: {
  formAdditionalData: any;
  onHide: () => void;
}) => {
  const [listFiles, setListFiles] = useState<any>({});
  const [additionalData, setAdditionalData] = useState({});
  const [validate, setValidate] = useState<any>(null);
  const [isValid, setIsValid] = useState(false);
  const [isPolicy, setIsPolicy] = useState(false);
  const { t } = useTranslation();
  const user = useAppSelector(getUserProfile);
  const dispatch = useAppDispatch();
  const menus: any = useAppSelector(getMenusByKey("censor_register_policy"));

  useEffect(() => {
    const check = () => {
      const listRequied = formAdditionalData?.fields?.filter(
        (el) => el.required
      );

      for (let index = 0; index < listRequied?.length; index++) {
        const item = listRequied[index];
        if (!additionalData[item.label] && !listFiles[item.label]) {
          return false;
        }
      }
      return true;
    };
    setIsValid((_) => check());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalData, listFiles]);

  useEffect(() => {
    if (user.censor) {
      setAdditionalData({
        location: user.censor?.location,
        description: user.censor?.description,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user?._id]);

  const setFile = (f: any, id: string, type?: string, idFile?: string) => {
    if (type === "mutil") {
      return setListFiles((prev) => ({
        ...prev,
        [id]: {
          ...prev?.[id],
          [idFile]: f,
        },
      }));
    }
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const censorRegisterHandler = async () => {
    if (isPolicy) {
      try {
        let response: string;
        if (
          user.censor &&
          (user.censor.status === "pending" || user.censor.status === "denied")
        ) {
          response = await CensorService.update({
            params: { id: user.censor._id },
            body: additionalData,
          });
          dispatch(
            setProfile({ censor: { ...user.censor, ...additionalData } })
          );
        } else {
          const res = await CensorService.create({ body: additionalData });
          dispatch(setProfile({ censor: res }));
          response = "Success";
        }
        toast.success(t(t(response)));
      } catch (error) {
        toast.error(t(t("Something wen't wrong.")));
      }
    }
  };

  return (
    <div className="form-register">
      {formAdditionalData && formAdditionalData.fields?.length > 0 && (
        <div className="row">
          <VForm
            fields={formAdditionalData?.fields}
            additionalData={additionalData}
            listFiles={listFiles}
            validate={validate}
            setListFiles={setListFiles}
            setValidate={setValidate}
            setIsValid={setIsValid}
            setFile={setFile}
            setAdditionalData={setAdditionalData}
          />
        </div>
      )}
      <div>
        <p style={{ margin: 0 }}>
          <b>{t("Danh mục niêm yết")}</b>
        </p>
        <p>
          {t("Chi phí/lượt thông báo:")} <span></span>
        </p>
      </div>
      <div className="d-flex">
        <div>
          <input
            className="form-check-input me-2"
            type="checkbox"
            onChange={(e) => setIsPolicy(e.target.checked)}
          />
        </div>
        <label className="form-check-label" htmlFor="customCheck1">
          {t(menus[0]?.description)}{" "}
          <a href={menus[0]?.url} target="_blank" rel="noreferrer">
            {t(menus[0]?.name)}
          </a>
        </label>
      </div>
      <div className="submit-register">
        {user.object_type !== "COMMUNITY" ? (
          <div className="d-flex gap-3 mt-3">
            <VButton className="cancel" onClick={onHide} loading={false}>
              {t("Cancel")}
            </VButton>
            <VButton
              className="send"
              onClick={censorRegisterHandler}
              loading={false}
              disabled={!isValid || !isPolicy}
            >
              {t("Send")}
            </VButton>
          </div>
        ) : (
          <VButton onClick={onHide}>{t("Back")}</VButton>
        )}
      </div>
    </div>
  );
};

export default Register;

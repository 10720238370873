/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";

import Button from "components/v-button";
import { FormGroup, Label } from "reactstrap";
import Modal from "components/v-modal";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import {
  getHistorySwapAsync,
  getStocksPriceAsync,
  getStocksSwapAsync,
  getStockProjetsSwapAsync,
} from "redux/reducers/cryptos/cryptos.actions";
import {
  getStocksPrice,
  getStocksSwap,
  getStocksProjectSwap,
} from "redux/reducers/cryptos/cryptos.selector";
import { toast } from "react-toastify";
import APIs from "api";
import History from "./History";
import { getBalances } from "redux/reducers/users/users.selector";
import { formatNumber, getSymbolStock, getUrlImage } from "utils/common";
import Progress from "components/progress";
import Input from "components/v-input";
import { BsFillArrowDownCircleFill } from "react-icons/bs";
import PageBackgrounds from "components/v-page-background";
import Card from "components/v-teamplate/Card";
import VBannerPage from "components/v-teamplate/v-banner-page";

const View = () => {
  const dispatch = useAppDispatch();

  const [state, setState] = useState({
    isOpenFrom: false,
    isOpenTo: false,
    from: null,
    to: null,
    listFrom: [],
    listTo: [],
    amountFrom: null,
    amountTo: null,
    fee: 0,
    oneWay: true,
  });

  const [active, setActive] = useState<string>("market");
  const [loading, setLoading] = useState(false);
  const [valueProgress, setValueProgress] = useState(0);

  const stocksProjectSwap = useAppSelector(getStocksProjectSwap);
  const stocksPrice = useAppSelector(getStocksPrice);
  const stocksSwap = useAppSelector(getStocksSwap);
  const balances = useAppSelector(getBalances);

  const init = () => {
    if (stocksSwap) {
      const fromSelected = stocksSwap.find(
        (s) => s.symbol === process.env.REACT_APP_STOCK_DEFAULT
      );
      const listTo = stocksSwap.filter(
        (s) => (fromSelected.pairs || {})[s.symbol]
      );
      setState({
        ...state,
        from: fromSelected,
        to: listTo[0],
        listFrom: stocksSwap,
        listTo,
      });
    }
  };

  const handleTabs = (tab: string) => {
    if (tab === "projects") {
      setState({
        ...state,
        from: stocksProjectSwap[0],
        to: stocksProjectSwap[1],
      });
    } else {
      init();
    }
    setActive(tab);
  };

  const handleToggleModal = (type) => {
    if (type === "from")
      setState({
        ...state,
        isOpenFrom: !state.isOpenFrom,
      });
    else
      setState({
        ...state,
        isOpenTo: !state.isOpenTo,
      });
  };

  const handleChoose = (type, stock) => {
    if (active === "projects") {
      if (type === "from") {
        setState({
          ...state,
          from: stock,
          isOpenFrom: false,
        });
      } else
        setState({
          ...state,
          to: stock,
          isOpenTo: false,
        });
    } else {
      let from;
      let to;
      let oneWay = true;
      let listFrom = [];
      let listTo = [];
      if (type === "from") {
        listFrom = stocksSwap;
        listTo = stocksSwap.filter((s) => (stock.pairs || {})[s.symbol]);
        from = stock;
        to = listTo[0];
      } else {
        listFrom = stocksSwap.filter((s) => (s.pairs || {})[stock.symbol]);
        listTo = state.listTo;
        from = state.from;
        to = stock;
      }

      if (
        from &&
        to &&
        (from.pairs || {})[to.symbol] &&
        (to.pairs || {})[from.symbol]
      ) {
        oneWay = false;
      }
      setState({
        ...state,
        from,
        to,
        listFrom,
        listTo,
        isOpenFrom: false,
        oneWay,
      });
    }
  };

  const handleChange = (type, value) => {
    let amountFrom = 0;
    let amountTo = 0;
    if (type === "from") {
      amountFrom = +value;
      if (active === "market") {
        amountTo =
          +value *
          (+stocksPrice[state.from.symbol]["vnd"] /
            +stocksPrice[state.to.symbol]["vnd"]);
      } else {
        amountTo =
          +value *
          ((+stocksPrice[state.from.stock_to_buy]["vnd"] *
            state.from.stock_price) /
            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
              state.to.stock_price));
      }
    } else {
      amountTo = +value;
      if (active === "market") {
        amountFrom =
          +value *
          (+stocksPrice[state.to.symbol]["vnd"] /
            +stocksPrice[state.from.symbol]["vnd"]);
      } else {
        amountFrom =
          +value *
          ((+stocksPrice[state.to.stock_to_buy]["vnd"] * state.to.stock_price) /
            (+stocksPrice[state.from.stock_to_buy]["vnd"] *
              state.from.stock_price));
      }
    }
    setState({
      ...state,
      amountFrom,
      amountTo,
    });
  };

  const handleSwap = async () => {
    try {
      setLoading(true);
      // await new Promise((res) => setTimeout(() => res(""), 3000));
      let res;
      if (active === "market") {
        res = await APIs.CRYPTO.swap({
          body: {
            from: state.from.symbol,
            to: state.to.symbol,
            amount: state.amountFrom,
          },
        });
      } else {
        res = await APIs.CRYPTO.swapStocksProjectAsync({
          body: {
            from: state.from.symbol,
            to: state.to.symbol,
            amount: state.amountFrom,
          },
        });
      }

      if (res) {
        setState({
          ...state,
          amountFrom: null,
          amountTo: null,
        });
        dispatch(getHistorySwapAsync());
        toast.success("Swap success!");
      }
      setLoading(false);
    } catch (error) {
      toast.error(error.errors);
      setLoading(false);
    }
  };

  const handleSwapStocks = () => {
    setState((state) => {
      return {
        ...state,
        from: state.to,
        to: state.from,
      };
    });
  };

  const handleChangeProgress = (value) => {
    setValueProgress(value);
  };

  const renderModalFrom = () => {
    return (
      <div className="position-relative p-2">
        <h5 className="mb-2">Top stocks: </h5>
        <div className="item-modal-swap">
          {(active === "market" ? state.listFrom : stocksProjectSwap).map(
            (s, key) => (
              <div
                key={key}
                className={`element-swap ${
                  s.symbol === state.from?.symbol ? "active" : ""
                }`}
                onClick={() => handleChoose("from", s)}
              >
                <img
                  src={
                    s.logo
                      ? getUrlImage(s.logo)
                      : "/assets/images/coins/dollar.png"
                  }
                  alt=""
                />
                <span>{s.symbol.toUpperCase()}</span>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  const renderModalTo = () => {
    return (
      <div className="position-relative p-2">
        <h5 className="mb-2">Top stocks: </h5>
        <div className="item-modal-swap">
          {(active === "market" ? state.listTo : stocksProjectSwap).map(
            (s, key) => (
              <div
                key={key}
                className={`element-swap ${
                  s.symbol === state.to?.symbol ? "active" : ""
                }`}
                onClick={() => handleChoose("to", s)}
              >
                <img
                  src={
                    s.logo
                      ? getUrlImage(s.logo)
                      : "/assets/images/coins/dollar.png"
                  }
                  alt=""
                />
                <span>{s.symbol.toUpperCase()}</span>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (balances && state.from)
      setState({
        ...state,
        amountFrom: ((balances[state.from.symbol] || 0) * valueProgress) / 100,
      });
  }, [valueProgress]);

  useEffect(() => {
    init();
  }, [stocksSwap]);

  useEffect(() => {
    if (state.from && state.to) {
      const amountFrom = state.amountFrom || 0;
      let amountTo;
      if (active === "market") {
        amountTo =
          amountFrom *
          (+stocksPrice[state.from.symbol]["vnd"] /
            +stocksPrice[state.to.symbol]["vnd"]);
      } else {
        amountTo =
          amountFrom *
          ((+stocksPrice[state.from.stock_to_buy]["vnd"] *
            state.from.stock_price) /
            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
              state.to.stock_price));
      }
      const fee_swap = state.from.fee_swap || {
        stock: process.env.REACT_APP_STOCK_DEFAULT,
        percent: 1,
      };
      const fee = (amountFrom * +fee_swap.percent) / 100;
      setState({
        ...state,
        amountFrom,
        amountTo,
        fee,
      });
    }
  }, [state.amountFrom, state.amountTo]);

  useEffect(() => {
    if (state.from && state.to) {
      setValueProgress(0);
      setState({
        ...state,
        amountFrom: null,
        amountTo: null,
      });
    }
  }, [state.from, state.to]);

  useEffect(() => {
    dispatch(getStocksPriceAsync());
    dispatch(getStocksSwapAsync());
    dispatch(getStockProjetsSwapAsync());
  }, [dispatch]);

  return (
    <>
      <VBannerPage position="banner_top_swap" />
      <PageBackgrounds page={"page_bg_swap"} />
      <div className="wrapper container">
        <div className="row">
          <div className="col-12 col-lg-6">
            <Card isOpacity={true} className={"container"}>
              <div className="tabs-swap">
                <div
                  className={`tab ${active === "market" ? "active" : ""}`}
                  onClick={() => handleTabs("market")}
                >
                  Market place
                </div>
                <div
                  className={`tab ${active === "yourprice" ? "active" : ""}`}
                >
                  Your price
                </div>
                <div
                  className={`tab ${active === "projects" ? "active" : ""}`}
                  onClick={() => handleTabs("projects")}
                >
                  IBO Stock Projects
                </div>
              </div>
              <div className="p-3">
                <div className="form-swap">
                  <div className="item">
                    <FormGroup>
                      <Label for="exampleEmail">
                        Availability
                        {/* Amount of {state.from ? state.from.symbol.toUpperCase() : "N/A"} */}
                        {state.from && (
                          <div className="">
                            {formatNumber(balances[state.from.symbol])}-
                            {state.from.symbol.toUpperCase()}
                          </div>
                        )}
                      </Label>
                      <Input
                        isCurrency={true}
                        plaintext
                        name="amountFrom"
                        value={state.amountFrom}
                        placeholder="0.00"
                        onChange={(e) => handleChange("from", e.target.value)}
                        // disabled={state.from && state.to ? false : true}
                        suffix={
                          <div
                            onClick={() => handleToggleModal("from")}
                            className="select-item"
                          >
                            <img
                              src={getUrlImage(
                                state.from
                                  ? state.from.logo
                                  : "/assets/images/coins/dollar.png"
                              )}
                              alt=""
                            />
                            <p>
                              {state.from
                                ? state.from.symbol.toUpperCase()
                                : "N/A"}
                            </p>
                            <img
                              src="/assets/images/icons/dropdown.svg"
                              alt=""
                            />
                          </div>
                        }
                      />
                    </FormGroup>
                    <Progress
                      value={valueProgress}
                      handleChange={handleChangeProgress}
                    />
                    <div className="transfer">
                      {state.oneWay ? (
                        <BsFillArrowDownCircleFill size={35} />
                      ) : (
                        <img
                          onClick={handleSwapStocks}
                          src="/assets/images/icons/transfer.svg"
                          alt=""
                        />
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-swap">
                  <div className="item">
                    <FormGroup>
                      <Label for="exampleEmail">
                        {" "}
                        Amount of{" "}
                        {state.to ? state.to.symbol.toUpperCase() : "N/A"}
                      </Label>
                      <Input
                        name="amountTo"
                        isCurrency={true}
                        plaintext
                        value={state.amountTo}
                        placeholder="0.00"
                        onChange={(e) => handleChange("to", e.target.value)}
                        disabled={state.from && state.to ? false : true}
                        suffix={
                          <div
                            className="select-item"
                            onClick={() => handleToggleModal("to")}
                          >
                            <img
                              src={getUrlImage(
                                state.to
                                  ? state.to.logo
                                  : "/assets/images/coins/dollar.png"
                              )}
                              alt=""
                            />
                            <p>
                              {state.to ? state.to.symbol.toUpperCase() : "N/A"}
                            </p>
                            <img
                              src="/assets/images/icons/dropdown.svg"
                              alt=""
                            />
                          </div>
                        }
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className="footer-form">
                  {state.from && state.to && (
                    <p>
                      <span>1 </span>
                      {state.from.symbol.toUpperCase()} ={" "}
                      <span>
                        {active === "market"
                          ? +stocksPrice[state.from.symbol]?.["vnd"] /
                            stocksPrice[state.to.symbol]?.["vnd"]
                          : (+stocksPrice[state.from.stock_to_buy]["vnd"] *
                              state.from.stock_price) /
                            (+stocksPrice[state.to.stock_to_buy]["vnd"] *
                              state.to.stock_price)}
                      </span>{" "}
                      {state.to.symbol.toUpperCase()}
                    </p>
                  )}
                  <p>
                    {" "}
                    Fee : <span>{formatNumber(state.fee)}</span>{" "}
                    {state.from && state.from.fee_swap
                      ? state.from.fee_swap.stock &&
                        state.from.fee_swap.stock.toUpperCase()
                      : getSymbolStock(process.env.REACT_APP_STOCK_DEFAULT)}
                  </p>
                  <div className="descriptions">
                    <span>!</span>
                    <span>
                      The final {state.to && state.to.symbol.toUpperCase()}{" "}
                      amount you receive many by slightly different due to
                      market volatility
                    </span>
                  </div>
                  <div className="submit">
                    <Button
                      onClick={handleSwap}
                      loading={loading}
                      disabled={
                        !loading && state.from && state.to ? false : true
                      }
                    >
                      Claim
                    </Button>
                  </div>
                </div>
              </div>
            </Card>
          </div>
          <div className="col-12 col-lg-6">
            <History />
          </div>
        </div>

        <Modal
          centered={true}
          isOpen={state.isOpenFrom}
          title="Swap from"
          children={renderModalFrom()}
          onCancel={() => handleToggleModal("from")}
        />
        <Modal
          centered={true}
          isOpen={state.isOpenTo}
          title="Swap to"
          children={renderModalTo()}
          onCancel={() => handleToggleModal("to")}
        />
      </div>
    </>
  );
};

export default View;

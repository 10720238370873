import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";
import React from "react";
import {
  Container,
  Row,
  Col,
  // Button,
  // Form,
  Image,
  Card,
} from "react-bootstrap";
// import { Link } from "react-router-dom";

//image
// import logo from "assets/images/logo.png";
import { useHistory } from "react-router-dom"

const ComingSoon = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Container className="p-0">
        <Card className="pb-3">
          <Row className="no-gutters height-self-center">
            <Col sm="12" className="text-center align-self-center">
              <div className="iq-maintenance position-relative mt-5">
                <Image
                  src={"/assets/images/maintenance-404/comingsoon.jpg"}
                  className="img-fluid iq-error-img"
                  alt=""
                />
                <h2 className="mb-0 text-center">
                  {t("ComingSoon")}
                </h2>
                <p className="text-center">
                  {t("Stay tunned, we're launching very soon")}
                </p>
                <VButton
                  className="mt-3 btn btn-primary"
                  onClick={() => history.push("/")}
                >
                  {t("Back to Home")}
                </VButton>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
  // useEffect(() => {
  //   function getTimeRemaining(endtime) {
  //     const total = Date.parse(endtime) - Date.parse(new Date().toString());
  //     const seconds = Math.floor((total / 1000) % 60);
  //     const minutes = Math.floor((total / 1000 / 60) % 60);
  //     const hours = Math.floor((total / (1000 * 60 * 60)) % 24);
  //     const days = Math.floor(total / (1000 * 60 * 60 * 24));

  //     return {
  //       total,
  //       days,
  //       hours,
  //       minutes,
  //       seconds,
  //     };
  //   }

  //   function initializeClock(elem, endtime) {
  //     const clock = document.querySelector(elem);
  //     const daysSpan = clock.querySelector("[data-days]");
  //     const hoursSpan = clock.querySelector("[data-hours]");
  //     const minutesSpan = clock.querySelector("[data-minutes]");
  //     const secondsSpan = clock.querySelector("[data-seconds]");

  //     function updateClock() {
  //       const t = getTimeRemaining(endtime);

  //       daysSpan.innerHTML = t.days;
  //       hoursSpan.innerHTML = ("0" + t.hours).slice(-2);
  //       minutesSpan.innerHTML = ("0" + t.minutes).slice(-2);
  //       secondsSpan.innerHTML = ("0" + t.seconds).slice(-2);

  //       if (t.total <= 0) {
  //         clearInterval(timeinterval);
  //       }
  //     }
  //     const timeinterval = setInterval(updateClock, 1000);
  //     updateClock();
  //   }

  //   let time: any = document
  //     .querySelector(".countdown")
  //     .getAttribute("data-date");
  //   if (time === undefined) {
  //     time = Date.parse(new Date().toString()) + 15 * 24 * 60 * 60 * 1000;
  //   }

  //   const deadline = new Date(time);
  //   initializeClock(".countdown", deadline);
  // });
  // return (
  //   <>
  //     <div className="iq-comingsoon pt-5">
  //       <Container>
  //         <Row className="justify-content-center">
  //           <Col md="12" className="text-center p-0">
  //             <div className="iq-comingsoon-info">
  //               <Link
  //                 className="sign-in-logo mb-5 d-flex align-items-center justify-content-center gap-2 iq-header-logo d-none d-lg-flex"
  //                 to="#"
  //               >
  //                 <Image src={logo} className="img-fluid" alt="logo" />
  //                 <h3
  //                   className="logo-title d-none d-sm-block"
  //                   data-setting="app_name"
  //                 >
  //                   Vdiarybook
  //                 </h3>
  //               </Link>
  //               <h2 className="mt-4 mb-1">
  //                 Stay tunned, we're launching very soon
  //               </h2>
  //               <p>
  //                 We are working very hard to give you the best experience
  //                 possible!
  //               </p>
  //               <ul
  //                 className="countdown row list-inline d-flex align-items-center"
  //                 data-date="jun 27 2023 20:20:22"
  //               >
  //                 <Col as="li" md="6" xl="3">
  //                   <Card>
  //                     <Card.Body>
  //                       <span className="text-center" data-days>
  //                         0
  //                       </span>
  //                       Days
  //                     </Card.Body>
  //                   </Card>
  //                 </Col>
  //                 <Col as="li" md="6" xl="3">
  //                   <Card>
  //                     <Card.Body>
  //                       <span data-hours>0</span>Hours
  //                     </Card.Body>
  //                   </Card>
  //                 </Col>
  //                 <Col as="li" md="6" xl="3">
  //                   <Card>
  //                     <Card.Body>
  //                       <span data-minutes>0</span>Minutes
  //                     </Card.Body>
  //                   </Card>
  //                 </Col>
  //                 <Col as="li" md="6" xl="3">
  //                   <Card>
  //                     <Card.Body>
  //                       <span data-seconds>0</span>Seconds
  //                     </Card.Body>
  //                   </Card>
  //                 </Col>
  //               </ul>
  //             </div>
  //           </Col>
  //         </Row>
  //         <Row className="justify-content-center">
  //           <Col lg="6">
  //             <Form className="iq-comingsoon-form mt-5">
  //               <Form.Group className="form-group">
  //                 <Form.Control
  //                   type="email"
  //                   className="comming mb-0"
  //                   id="exampleInputEmail1"
  //                   placeholder="Enter email address"
  //                 />
  //                 <Button
  //                   type="submit"
  //                   className="btn-primary"
  //                   onClick={(e) => e.preventDefault()}
  //                 >
  //                   Subscribe
  //                 </Button>
  //               </Form.Group>
  //             </Form>
  //           </Col>
  //         </Row>
  //       </Container>
  //     </div>
  //   </>
  // );
};

export default ComingSoon;

/* eslint-disable react-hooks/exhaustive-deps */
import { ROUTES } from "config/routes";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import Loading from "components/v-loading-layout";

import { getUserAsync } from "redux/reducers/users/users.actions";
import UserService from "api/users";
import { useLocation } from "react-router-dom";
import SettingAction from "redux/reducers/setting-system/actions";
import useQuery from "hooks/useQuery";

const withAuth = (Component: any, options?: any) => {
  const AuthenticatedComponent: React.FC = (props) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const location = useLocation();
    const query = useQuery();
    const { noRedirect } = options || {};

    useEffect(() => {
      async function getMe() {
        try {
          setIsLoading(true);
          const res: any = await dispatch(getUserAsync());
          setIsLoading(false);
          if (res.payload && res.payload.user) {
            if (localStorage.getItem("firebaseToken"))
              UserService.createToken({
                body: {
                  token: localStorage.getItem("firebaseToken"),
                  user_id: res.payload.user._id,
                },
              });

            if (query.redirect_url) {
              window.location.href =
                query.redirect_url +
                "?vdb_token=" +
                localStorage.getItem("auth");
              return;
            } else {
              if (location?.pathname.includes("auth/")) {
                history.push(ROUTES.HOME);
              }
              if (res.payload.user.theme) {
                dispatch(SettingAction["theme_scheme"](res.payload.user.theme));
              }
            }
          } else {
            localStorage.removeItem("auth");
            if (
              location?.pathname.includes("auth/") ||
              location?.pathname.includes("/share")
            ) {
              return;
            }
            if (!noRedirect) {
              history.push(ROUTES.LOGIN);
            }
          }
        } catch (error) {
          localStorage.removeItem("auth");
          setIsLoading(false);
          if (location?.pathname.includes("auth/")) {
            return;
          }
          history.push(ROUTES.LOGIN);
        }
      }
      getMe();
    }, [dispatch, history, query?.redirect_url]);

    if (isLoading) {
      return <Loading />;
    }

    return (
      <div>
        <Component {...props} />
      </div>
    );
  };

  return AuthenticatedComponent;
};

// export const withoutAuth = (Component: any) => {
//   return function HOC(props: any) {
//     // const auth = useSelector(getAuth)
//     // return auth === 'GUEST' ? <Component {...props} /> : <Redirect to="/" />
//   };
// };

export default withAuth;

import { createAsyncThunk } from "@reduxjs/toolkit";
import Faqs from "api/faqs";

export const getCategoryFaqsAsync = createAsyncThunk(
  "faqs/category-faqs/list",
  async () => {
    try {
      const response: any = await Faqs.getAllCategory();
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getFasAsync = createAsyncThunk(
  "faqs/qas/list",
  async () => {
    try {
      const response: any = await Faqs.getAllQa();
      return response;
    } catch (error) {
      return error;
    }
  }
);
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { useState, useEffect, useRef } from "react";
import { Card, CardBody, UncontrolledCollapse } from "reactstrap";
import { getCategoryFaqs } from "redux/reducers/faqs/faqs.selectors";
import { useAppSelector, useAppDispatch } from "redux/reducers/hook";
import { getFas } from "redux/reducers/faqs/faqs.selectors";
import { Form } from "react-bootstrap";
import VInput from "components/v-input";
import useTranslation from "hooks/useTranslation";
import VDropdown from "components/v-dropdown";
import { getSupportsAsync } from "redux/reducers/settings/settings.action";
import { toast } from "react-toastify";
import * as SettingSelector from "redux/reducers/setting-system/selectors";
import useQuery from "hooks/useQuery";
import UploadFile from "components/uploadFile";
import VButton from "components/v-button";
import { v4 as uuidv4 } from "uuid";
import VTextarea from "components/v-textarea";
import UploadService from "api/uploads";
import SupportService from "api/supports";
import { getUserProfile } from "redux/reducers/users/users.selector";

const Details = (props) => {
  const { helpref } = props;
  const { t } = useTranslation();
  const query = useQuery();
  const categoryFaqs: any = useAppSelector(getCategoryFaqs);
  const themeScheme = useAppSelector(SettingSelector.theme_scheme);
  const qas: any = useAppSelector(getFas);
  const dataUser = useAppSelector(getUserProfile);

  const [data, setData] = useState([]);

  useEffect(() => {
    const category = categoryFaqs?.find((el) => el.key === helpref);
    const qaByCategory = qas.filter(
      (el) => el.category_faq_id === category?._id
    );
    setData(qaByCategory);
  }, [categoryFaqs, qas, helpref]);

  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const [state, setState] = useState([]);
  const [dataQuestion, setDataQuestion] = useState([]);

  const formRef: any = useRef();

  const handleSubmitForm = async (id: string) => {
    try {
      setLoading(true);
      const send = state.find((st) => st._id === id);
      const data = dataQuestion.find((el) => el.qa_id === id);

      const newData = send.listFiles.filter((list) => list.file);

      if (newData.length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${dataUser?._id}`);
        newData.map((stNew) => {
          formData.append("files", stNew.file);
          return stNew;
        });

        const res: any = await UploadService.uploadMultiFile({
          body: formData,
        });

        if (res) {
          const listAttachments = res.map((el) => el.filePath);
          await SupportService.sendSupport({
            title: send.title,
            qa_id: id,
            value: { ...data, attachments: listAttachments },
          });
          formRef.current.reset();
          setState((prev) =>
            prev.map((el) =>
              el._id === id
                ? {
                    ...el,
                    listFiles: [],
                  }
                : el
            )
          );
        }
      } else {
        await SupportService.sendSupport({
          title: send.title,
          qa_id: id,
          value: data,
        });
        formRef.current.reset();
      }
      toast.success(t(`Send support success!`));
      setLoading(false);
    } catch (error) {
      toast.error(t(`Error!`));
      setLoading(false);
    }
  };

  const removeChil = (id: string, index: number) => {
    setState((prev) =>
      prev.map((st) =>
        st._id === id
          ? {
              ...st,
              listFiles: st.listFiles.filter(
                (el, idx: number) => index !== idx
              ),
            }
          : st
      )
    );
  };

  const setFile = (f: any, id: string, key: string) => {
    setState((prev) =>
      prev.map((st) =>
        st._id === key
          ? {
              ...st,
              listFiles: st.listFiles.map((el) =>
                el.id === id
                  ? {
                      ...el,
                      file: f,
                    }
                  : el
              ),
            }
          : st
      )
    );
  };

  const handleAddAttachment = (id: string, name: string) => {
    setState((prev) =>
      prev.map((st) =>
        st._id === id
          ? {
              ...st,
              listFiles: [
                ...st.listFiles,
                {
                  id: uuidv4(),
                  file: null,
                  name: name,
                },
              ],
            }
          : st
      )
    );
  };

  const handleChangeFields = (e: any, id: string, key: string) => {
    console.log("22113", e.target.value);

    const qs = dataQuestion.find((el) => el.qa_id === id);
    if (qs) {
      setDataQuestion((prev) =>
        prev.map((el) =>
          el.qa_id === id
            ? {
                ...el,
                [key]: e.target.value,
              }
            : el
        )
      );
    } else {
      setDataQuestion(
        dataQuestion.concat({
          qa_id: id,
          [key]: e.target.value,
        })
      );
    }
  };

  const check = (formAdditionalData, additionalData, listFiles) => {
    const checkValidate = () => {
      const listRequied = formAdditionalData?.fields?.filter(
        (el) => el.required
      );
      for (let index = 0; index < listRequied.length; index++) {
        const item = listRequied[index];
        if (
          !additionalData ||
          (!additionalData?.[item?.label] &&
            !listFiles.find((f) => f.name === item?.label && f.file))
        ) {
          return false;
        }
      }
      return true;
    };
    return checkValidate();
  };

  useEffect(() => {
    dispatch(getSupportsAsync());
  }, []);

  useEffect(() => {
    if (data)
      setState(
        (data || []).map((q) => ({
          ...q,
          isOpen: false,
          listFiles: [],
        }))
      );
  }, [data]);
  console.log("state", state);

  return (
    <div className="support px-1">
      <Form ref={formRef}>
        {data && state && (
          <div className="dream-fap-area">
            {state.map((item, index) => (
              <div key={index} className={"element"}>
                <div>
                  <Card className="m-0">
                    <div className="d-flex justify-content-between flex-row align-items-center">
                      <button
                        className={`name ${
                          themeScheme === "dark" ? "text-light" : ""
                        }`}
                        id={`toggler_${item._id}`}
                        data-aos="fade-up"
                        data-aos-delay="200"
                      >
                        {item.name}
                        <div>
                          <img
                            src="/assets/icons/default/dropdown.svg"
                            alt=""
                          />
                        </div>
                      </button>
                    </div>
                    <UncontrolledCollapse
                      defaultOpen={
                        (index === 0 && query?.openDefault === "true") ||
                        helpref === "quyenriengtucuaban"
                      }
                      toggler={`toggler_${item._id}`}
                    >
                      <CardBody>
                        <div>
                          {item.description && (
                            <span className="description">
                              {item.description}
                            </span>
                          )}
                          <div
                            className="content"
                            dangerouslySetInnerHTML={{
                              __html: item.content,
                            }}
                          ></div>
                        </div>

                        {item.additionalDataForm?.fields?.length > 0 && (
                          <>
                            {item.additionalDataForm.fields
                              .filter((field) => field.status !== "disable")
                              .sort(
                                (prev, next) =>
                                  (prev.order || 0) - (next.order || 0)
                              )
                              .map((el: any, i) => (
                                <div key={i}>
                                  {el.typeField === "input" && (
                                    <Form.Group className="form-group col-12 mt-4">
                                      <VInput
                                        label={
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: el.label,
                                            }}
                                          ></div>
                                        }
                                        id={el.name}
                                        name={el.name}
                                        required={el.required}
                                        disabled={
                                          el.label?.toLowerCase() === "email"
                                        }
                                        value={
                                          el.label?.toLowerCase() === "email"
                                            ? dataUser.email
                                            : ""
                                        }
                                        onChange={(e) =>
                                          handleChangeFields(
                                            e,
                                            item._id,
                                            el.label
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  )}
                                  {el.typeField === "dropdown" && (
                                    <Form.Group className="form-group">
                                      <VDropdown
                                        label={
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: el.label,
                                            }}
                                          ></div>
                                        }
                                        required={el.required}
                                        placeholder={el.placeholder}
                                        options={el?.options?.map((el: any) => {
                                          return {
                                            label: el?.name,
                                            value: el?.name,
                                          };
                                        })}
                                        value={
                                          dataQuestion.find(
                                            (el) => el?.qa_id === item._id
                                          )?.[el.label]
                                        }
                                        onChange={(e) =>
                                          handleChangeFields(
                                            e,
                                            item._id,
                                            el.label
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  )}
                                  {el.typeField === "textarea" && (
                                    <Form.Group className="form-group col-12 mt-4">
                                      <VTextarea
                                        label={
                                          <div
                                            dangerouslySetInnerHTML={{
                                              __html: el.label,
                                            }}
                                          ></div>
                                        }
                                        // placeholder={el.placeholder}
                                        required={el.required}
                                        onChange={(e) =>
                                          handleChangeFields(
                                            e,
                                            item._id,
                                            el.label
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  )}
                                  {el.typeField === "date" && (
                                    <Form.Group
                                      className={`form-group ${`col-12 ${
                                        el.col
                                          ? `col-md-${Number(12 / el?.col)}`
                                          : ""
                                      }`}`}
                                    >
                                      <VInput
                                        type={"date"}
                                        label={t(el.label)}
                                        id={el.name}
                                        name={el.name}
                                        required={el.required}
                                        value={
                                          dataQuestion.find(
                                            (el) => el?.qa_id === item._id
                                          )?.[el.label]
                                        }
                                        // value={additionalData[el.label]}
                                        // onChange={(e) =>
                                        //   handleChangeFields(e, el)
                                        // }
                                        onChange={(e) =>
                                          handleChangeFields(
                                            e,
                                            item._id,
                                            el.label
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  )}

                                  {el.typeField === "date-time" && (
                                    <Form.Group
                                      className={`form-group ${`col-12 ${
                                        el.col
                                          ? `col-md-${Number(12 / el?.col)}`
                                          : ""
                                      }`}`}
                                    >
                                      <VInput
                                        type={"datetime-local"}
                                        label={t(el.label)}
                                        id={el.name}
                                        name={el.name}
                                        required={el.required}
                                        // value={additionalData[el.label]}
                                        value={
                                          dataQuestion.find(
                                            (el) => el?.qa_id === item._id
                                          )?.[el.label]
                                        }
                                        // onChange={(e) =>
                                        //   handleChangeFields(e, el)
                                        // }
                                        onChange={(e) =>
                                          handleChangeFields(
                                            e,
                                            item._id,
                                            el.label
                                          )
                                        }
                                      />
                                    </Form.Group>
                                  )}
                                  {el.typeField === "media" && (
                                    <>
                                      <Form.Label className="d-flex align-items-center">
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: el.label,
                                          }}
                                        ></div>
                                        {el.required && (
                                          <span className="required text-danger">
                                            *
                                          </span>
                                        )}
                                      </Form.Label>
                                      <div
                                        className={
                                          "form-group d-flex flex-wrap align-items-center"
                                        }
                                      >
                                        {item?.listFiles?.length > 0 &&
                                          item?.listFiles
                                            ?.filter((f) => f.name === el.label)
                                            ?.map((at: any, index: number) => (
                                              <div
                                                key={index}
                                                className="item-file"
                                              >
                                                <div className="remove-item">
                                                  <VButton
                                                    onClick={() =>
                                                      removeChil(
                                                        item._id,
                                                        index
                                                      )
                                                    }
                                                  >
                                                    -
                                                  </VButton>
                                                </div>
                                                <div className="upload-file">
                                                  <UploadFile
                                                    id={at.id}
                                                    index={index}
                                                    sectionName={item._id}
                                                    ratio="340x340"
                                                    file={at.file}
                                                    setFile={setFile}
                                                  />
                                                </div>
                                              </div>
                                            ))}
                                        {item?.listFiles?.length <
                                          el?.limit_file && (
                                          <div
                                            className="add-upload"
                                            onClick={() =>
                                              handleAddAttachment(
                                                item._id,
                                                el.label
                                              )
                                            }
                                          >
                                            +
                                          </div>
                                        )}
                                      </div>
                                    </>
                                  )}
                                </div>
                              ))}
                            <div className="submit">
                              <VButton
                                loading={loading}
                                disabled={
                                  !check(
                                    item.additionalDataForm,
                                    dataQuestion.find(
                                      (el) => el?.qa_id === item._id
                                    ),
                                    item?.listFiles
                                  )
                                }
                                onClick={() => handleSubmitForm(item._id)}
                              >
                                {t("Submit")}
                              </VButton>
                            </div>
                          </>
                        )}
                      </CardBody>
                    </UncontrolledCollapse>
                  </Card>
                </div>
              </div>
            ))}
          </div>
        )}
      </Form>
    </div>
  );
};

export default Details;

/* eslint-disable react-hooks/exhaustive-deps */
//router
import IndexRouters from "router/index";

//scss
import "./assets/scss/index.scss";

// Redux Selector / Action
import { useDispatch, useSelector } from "react-redux";
import { setSetting } from "redux/reducers/setting-system/actions";
import { useEffect, useMemo } from "react";

import {
  getCryptosAsync,
  getStocksPriceAsync,
} from "redux/reducers/cryptos/cryptos.actions";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import {
  getAllMenusAsync,
  getSettingsAsync,
  getLocaleAsync,
  getDefaultObjectSettingsAsync,
} from "redux/reducers/settings/settings.action";
import { useTranslation } from "react-i18next";
import Notification from "firebases/Notifications";

import WebModulesModal from "modals/web-modules";
import GaleryImages from "modals/galery-images";
import TemplatePreview from "modals/template-preview";
import StoryPreview from "modals/story-preview";
import PdfViewer from "modals/pdf-viewer";
import DisconnectToast from "components/disconnect-network";
import {
  getIsConnectionError,
  getUISettingState,
} from "redux/reducers/ui-settings/ui-settings.selector";
import useQuery from "hooks/useQuery";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";
import VTooltipQ from "components/v-tooltip-social";
import { useHistory } from "react-router-dom";
import Snowfall from "react-snowfall";
import {
  setIsApp,
  setIsPreview,
} from "redux/reducers/settings/settings.reducer";
import RandomTemplate from "modals/random-template/View";

function App() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const query = useQuery();
  const isConnectionError = useSelector(getIsConnectionError);
  const general = useAppSelector(getGeneral);
  const isApp = query.is_app === 1;
  const { postId } = useSelector(getUISettingState);

  useEffect(() => {
    if (postId) {
      history.push(`/posts/${postId}`);
    }
  }, [postId]);

  useEffect(() => {
    if (query.token) {
      localStorage.setItem("auth", query.token);
    }
  }, [query.token]);

  useEffect(() => {
    if (query.is_app && String(query.is_app) === "1") {
      dispatch(setIsApp(true));
    }
  }, [query.is_app]);

  useEffect(() => {
    if (query.is_preview && String(query.is_preview) === "1") {
      dispatch(setIsPreview(true));
    }
  }, [query.is_preview]);

  useEffect(() => {
    if (!query.starting && !query.notLoadDefault) {
      const _language = localStorage.getItem("language");
      const isLogin = localStorage.getItem("auth");
      if (_language) {
        i18n.changeLanguage(_language);
      }
      if (!isLogin) {
        dispatch(getAllMenusAsync());
      }
      process.env.NODE_ENV === "development" && dispatch(getLocaleAsync());
      dispatch(setSetting());
      dispatch(getStocksPriceAsync());
      dispatch(getCryptosAsync());
      dispatch(getDefaultObjectSettingsAsync());
    }
  }, [query]);

  useEffect(() => {
    dispatch(getSettingsAsync());
    const setHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    setHeight();
  }, []);

  const upgrading = useMemo(() => {
    if (general && general.site && !window.origin.includes("vdiarybook.vn")) {
      const _upgrading = general.site.find((g) => g.key === "upgrading");
      if (_upgrading && _upgrading.site_media) {
        return _upgrading;
      }
    }
    return null;
  }, [general]);

  const snowFallSetting = useMemo(() => {
    if (general && general.site) {
      const data = general?.site?.find((e) => e.key === "snow_fall_setting");

      return {
        isShow: data?.content,
        setting: data?.description ? JSON.parse(data?.description) : {},
      };
    }
    return null;
  }, [general]);

  const snowFallImages = useMemo(() => {
    if (snowFallSetting?.setting?.icon_url) {
      const snowflake = document.createElement("img");
      snowflake.src = snowFallSetting?.setting?.icon_url;

      return [snowflake];
    }

    return [];
  }, [snowFallSetting]);

  return (
    general &&
    (upgrading && !isApp ? (
      <img
        style={{
          position: "fixed",
        }}
        className="w-full h-full"
        src={`${process.env.REACT_APP_FILE}/api/files/${upgrading.site_media}`}
        alt=""
      />
    ) : (
      <div className="App">
        {!!snowFallSetting?.isShow && (
          <Snowfall
            rotationSpeed={
              snowFallSetting?.setting?.rotationSpeed ?? [-1.0, 1.0]
            }
            color={snowFallSetting?.setting?.color ?? "#fff"}
            speed={snowFallSetting?.setting?.speed ?? [1.0, 3.0]}
            radius={snowFallSetting?.setting?.radius ?? [0.5, 3.0]}
            snowflakeCount={snowFallSetting?.setting?.snowflakeCount ?? 200}
            style={{
              position: "fixed",
              width: "100vw",
              height: "100vh",
              zIndex: 1112,
              transform: `rotate(${snowFallSetting?.setting?.rotate ?? 0}deg)`,
            }}
            images={snowFallImages}
          />
        )}
        <Notification />
        <IndexRouters />
        <ToastContainer position="top-right" />

        <WebModulesModal />
        <GaleryImages />
        <TemplatePreview />
        <RandomTemplate />
        <StoryPreview />
        <PdfViewer />
        {isConnectionError ? <DisconnectToast /> : <></>}
        <VTooltipQ />
      </div>
    ))
  );
}

export default App;

import { createAsyncThunk } from "@reduxjs/toolkit";
import SettingService from "api/settings";

export const getDepositBanksAsync = createAsyncThunk(
  "banks/list-deposit",
  async () => {
    try {
      const response: any = await SettingService.getSettingByName("banks");
      return response?.setting?.value;
    } catch (error) {
      return error;
    }
  }
);

/* eslint-disable indent */
import { Col } from "react-bootstrap";
import Card from "components/v-teamplate/Card";

import Avatar from "components/v-avatar";
import useTranslation from "hooks/useTranslation";
// import VIcon from "components/v-icon";
import { compareYearWithCur, renderDate } from "utils/times";
import { useHistory } from "react-router-dom";
import { getUrlImage, getUrlProfile } from "utils/common";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";

const View = ({ userData, tab }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const listMenuIcon: any = useAppSelector(getMenusByKey("icon-birthday"));
  const { profile } = userData;

  const handleClick = () => {
    history.push(getUrlProfile(userData));
  };

  const handleClickIcon = (action: any) => {
    const url = action?.url?.replace("{user_id}", userData?._id);
    history.push(String(url));
  };

  return (
    <Col md="6" className="px-1">
      <Card>
        <Card.Body>
          <div>
            <div
              // className="d-flex align-items-center cursor-pointer"
              onClick={() => handleClick()}
            >
              <Avatar
                size={"50px"}
                fontSize={"14px"}
                fontSizeIconVerify={12}
                additionalData={
                  tab === "birthday"
                    ? `${renderDate(profile.birthday, {
                        key: "hide_year",
                      })} - (${compareYearWithCur(
                        profile.birthday || new Date()
                      )} ${t("year old")})`
                    : new Date().toLocaleDateString()
                }
                userData={userData}
              />
            </div>
            <div className="action-item-birthday">
              {listMenuIcon?.length > 0 &&
                listMenuIcon?.map((el) => (
                  <div
                    key={el?._id}
                    className="item"
                    onClick={() => handleClickIcon(el)}
                  >
                    <img src={getUrlImage(el?.img_icon, 100)} alt="" />
                  </div>
                ))}
              {/* <div className="item">
                <VIcon name="wall.svg" />
              </div>
              <div className="item">
                <VIcon name="send.svg" />
              </div>
              <div className="item">
                <VIcon name="gift-card.svg" />
              </div>
              <div className="item">
                <VIcon name="common/gift.svg" />
              </div> */}
            </div>
          </div>
        </Card.Body>
      </Card>
    </Col>
  );
};

export default View;

import React from "react";
import { Select, Space } from "antd";
import AppImage from "components/core/image";

type IDropdownProps = {
  label?: string;
  placeholder?: string;
  options: any[];
  defaultValue?: any;
  disabled?: boolean;
  onChange: (e: string) => void;
  value?: any;
  type?: string;
  width?: any;
  className?: string;
  setRequiredValue?: (e: string) => void;
  showSearch?: boolean;
};

export default function Dropdown(props: IDropdownProps) {
  const {
    label,
    placeholder,
    options,
    defaultValue,
    onChange,
    value,
    className,
    setRequiredValue,
    showSearch = false,
  } = props;

  const { Option } = Select;

  const handleChangeDropdown = (e: string) => {
    if (setRequiredValue) {
      setRequiredValue(e);
    }
    onChange(e);
  };

  const _renderOption = (
    {
      value,
      icon,
      label,
      image,
      disabled,
    }: {
      idx: number;
      value: string;
      label: string;
      icon: any;
      image?: any;
      disabled?: boolean;
    },
    index: number
  ) => (
    <Option
      value={value}
      label={label}
      icon={icon}
      key={index}
      disabled={disabled}
    >
      <Space>
        {image && (
          <div style={{width: "1.75rem", height: "1.75rem"}}>
            <AppImage path={image} className="w-full h-full object-cover" />
          </div>
        )}

        {label}
      </Space>
    </Option>
  );
  return (
    <>
      {label && <span className="text-xs">{label}</span>}
      <Select
        showSearch={showSearch}
        filterOption={(input, option) =>
          String(option?.label ?? "")
            .toLowerCase()
            .includes(input.toLowerCase())
        }
        className={`${className ? className : ""} w-full`}
        {...(defaultValue ? { defaultValue } : {})}
        {...(value ? { value } : {})}
        onChange={(e) => handleChangeDropdown(e)}
        placeholder={placeholder || "Chọn"}
      >
        {options &&
          options.length > 0 &&
          options.map((option: any, index: any) =>
            _renderOption(option, index)
          )}
      </Select>
    </>
  );
}

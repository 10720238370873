import { request, parseErrorResponse } from "./request";

const getRequest = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/relationships/requesting`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRelation = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/relationships/relation`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/relationships/create-relation`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/relationships/update-status`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const RelationShipService = {
  create,
  update,
  getRequest,
  getRelation,
};

export default RelationShipService;

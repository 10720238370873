/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";

//profile-header
import VBannerPage from "components/v-teamplate/v-banner-page";
import VTab from "components/v-tab";
import useQuery from "hooks/useQuery";
import ComingSoon from "pages/coming-soon/comingsoon";
import ListBirthday from "./components/list-birthday";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import VRightView from "components/v-right-view";

const Birthday = () => {
  const query = useQuery();
  const menus = useAppSelector(getMenusByKey("birthday_feed"));
  const general = useAppSelector(getGeneral);

  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  const [tab, setTab] = useState(null);
  const [loadMenu, setLoadMenu] = useState(false);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  const renderTab = () => {
    if (tab && tab.key === "birthday") {
      return <ListBirthday tab={"birthday"} />;
    }
    return <ComingSoon />;
  };

  return (
    <Container>
      <Row>
        <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
          <VBannerPage position="banner_top_birthday" />
          <div>
            <VTab tab={tab} setTab={setTab} menus={menus} />
          </div>
          <Row>
            <Col sm="12">{renderTab()}</Col>
          </Row>
        </Col>
        {show_banner_right_on_container && (
          <Col lg={4} className={"d-none d-lg-block px-1"}>
            <VRightView
              menuKey={"tab_right_birthday"}
              positionBanner={"banner_right_birthday"}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};
export default Birthday;

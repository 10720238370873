import { useEffect, useState } from "react";
import { OverlayTrigger, Popover, Button } from "react-bootstrap";
import { getUrlImage } from "utils/common";
import FormCreate from "../../pages/social/home/form-create";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";
import UserService from "api/users";

type IProps = {
  setIsOpen: any;
  isOpen: boolean;
  keyGroup?: string;
  onClickCreate?: (el: any) => void;
};

const View = ({setIsOpen, isOpen, onClickCreate, keyGroup = ""}: IProps) => {
  const general = useAppSelector(getGeneral);
  const user = useAppSelector(getUserProfile);
  const menus = useAppSelector(getMenusByKey(keyGroup));

  const isKyc = user?.status_kyc === "kyc" || user?.status_kyc === "verified";
  
  const iconPencil = general?.site?.find(
    (site: any) => site?.key === "icon_messages_pencil"
  );

  const [counter, setCounter] = useState(null);
  const [isLimitPostCreate, setLimitPostCreate] = useState(false);

  useEffect(() => {
    if (!isKyc) {
      getCounter();
    }
  }, [isKyc]);

  const getCounter = async () => {
    const res: any = await UserService.counter();
    setCounter(res);
  };

  useEffect(() => {
    if (counter) {
      let limitPost = general?.site?.find(
        (e) => e.key === "limit_posts_not_kyc"
      )?.content;
      setLimitPostCreate((limitPost && counter?.posts >= limitPost) || false);
    }
  }, [counter]);

  if (keyGroup && +menus?.length === 0) return null;

  return (
    <OverlayTrigger
      trigger="click"
      placement="top-end"
      rootClose
      rootCloseEvent="click"
      onToggle={(isShow) => {
        setIsOpen(isShow);
      }}
      overlay={
        <Popover id="popover-basic" className="v-create-popover">
          {isOpen && (
            <Popover.Body>
              <FormCreate
                menus={keyGroup ? menus : []}
                onClickCreate={onClickCreate}
                setIsOpen={setIsOpen}
                isKyc={isKyc}
                isLimitCreatePost={isLimitPostCreate}
              />
            </Popover.Body>
          )}
        </Popover>
      }
    >
      {!isOpen ? (
        <div className={`btn-create-post`}>
          <Button className="btn-popover-create">
            {iconPencil ? (
              <img
                src={getUrlImage(iconPencil?.site_media)}
                className="wiggle"
              />
            ) : (
              <img src="/assets/images/pens.svg" className="wiggle" />
            )}
          </Button>
        </div>
      ) : (
        <div className={`btn-create-post rotate-45deg`}>
          <Button className="btn-popover-create">
            <img
              src="/assets/images/icons/quill_add.svg"
              className="wiggle"
            />
          </Button>
        </div>
      )}
    </OverlayTrigger>
  )
}

export default View;
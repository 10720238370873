/* eslint-disable react-hooks/exhaustive-deps */
import { FormGroup } from "reactstrap";
import Button from "components/v-button";
import Input from "components/v-input";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getSharingsAsync } from "redux/reducers/users/users.actions";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import Card from "components/v-teamplate/Card";
import { AiOutlineShareAlt } from "react-icons/ai";
import { MdOutgoingMail } from "react-icons/md";
import VTextarea from "components/v-textarea";
import useTranslation from "hooks/useTranslation";
import VQRCode from "components/v-qr-code";
import { Col } from "react-bootstrap";

const View = (props) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { dataUser } = props;
  const [state, setState] = useState({
    contact_link: "",
  });

  const userAuth = useAppSelector(getUserProfile);

  const user = dataUser || userAuth;

  useEffect(() => {
    if (user && user._id) {
      dispatch(getSharingsAsync({}));
      setState({
        ...state,
        contact_link: `https://${window.location.host}/auth/register?refer_code=${user.refer_code}`,
      });
    }
  }, []);

  const handleCopy = () => {
    navigator.clipboard.writeText(state.contact_link);
    toast.success("Copied to clipboard!");
  };

  return (
    <Card isOpacity={true}>
      <div className="referral-setting">
        <div>
          <div className="content">
            {user.sponsor && (
              <div className="contain-user">
                <h4>{t("Sponsor")}</h4>
                <span>
                  {t("Username")}: {user.sponsor.username}
                </span>
                <span>
                  {t("Email")}: {user.sponsor.email}
                </span>
                <span>
                  {t("Refercode")}: {user.sponsor.refer_code}
                </span>
              </div>
            )}
            <div className="d-md-flex justify-content-between">
              <div>
                <h4>{t("invite_your_contact")}</h4>
                <div className="d-flex align-items-center">
                  <AiOutlineShareAlt size={24} />
                  <span className="ms-1">
                    {t("Share this link to your contact")}
                  </span>
                </div>

                <p>
                  {t(
                    "Clicking this button will copy the URL to the_user's clipboard"
                  )}
                </p>
              </div>
              <Col className="d-flex justify-content-center">
                <VQRCode value={state.contact_link} size={120} />
              </Col>
            </div>
            <div className="d-flex align-items-center flex-wrap">
              <Col className="col-12">
                <div className="contract">
                  <Button onClick={handleCopy}>{t("Copy Url")}</Button>
                  <Input value={state.contact_link} />
                </div>
              </Col>
            </div>
          </div>
          <hr></hr>
          <div className="mt-3 d-flex">
            <div className="d-flex align-items-center me-2">
              <AiOutlineShareAlt size={24} />
              <span className="ms-1">{t("Send")}</span>
            </div>
            <div className="affiliate">
              <div className="affiliate-item">
                <img alt="" src="/assets/images/icons/facebook.svg" />
                <span>Facebook</span>
              </div>
              <div
                className="affiliate-item"
                style={{
                  backgroundColor: "#37c3ff",
                }}
              >
                <img alt="" src="/assets/images/icons/twitter.svg" />
                <span>Twitter</span>
              </div>
              {/* <div
                className="affiliate-item"
                style={{
                  backgroundColor: "rgb(14, 88, 219)",
                }}
              >
                <img alt="" src="/assets/images/social/vdiarybook.png" />
                <a
                  href="https://vdiarybook.com/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  Vdiary Book
                </a>
              </div> */}
            </div>
          </div>
          <hr></hr>
          <div className="referral-bottom">
            <div className="d-flex align-items-center">
              <MdOutgoingMail size={24} />
              <span className="ms-1">
                {t("Invite your friends by your mail")}
              </span>
            </div>
            <p>{t("Enter one email by line and click send")}</p>
            <FormGroup>
              <VTextarea id="text" name="text" />
            </FormGroup>
            <div className="submit">
              <Button>{t("send")}</Button>
            </div>
          </div>
        </div>

        {/* <History>
        <Title bgColor={"grey200"}>My Earning</Title>
        <Table
          columns={columns}
          dataSource={commissions}
          bgTable={"black100"}
          bgHeader={"black100"}
        ></Table>

        <Title bgColor={"grey200"}>My Sharing</Title>
        <Table
          columns={columns}
          dataSource={sharings}
          bgTable={"black100"}
          bgHeader={"black100"}
        ></Table>
      </History> */}
      </div>
    </Card>
  );
};

export default View;

import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getSuggests = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/businesses/suggests?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getManage = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/businesses/manage?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRequestJoined = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/businesses/request-join/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getJoined = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/businesses/joined?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRequestMember = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `/businesses/request-member/${req.params.id}?${qs.stringify(req.query)}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMemberJoined = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/businesses/members/${req.params.id}?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/businesses/create`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const update = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/businesses/update-status`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const requestJoin = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/businesses/request-join`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const changeStatus = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/businesses/change-status`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const addMembers = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/businesses/add-members`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInviteMembers = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/businesses/invite-members?${qs.stringify(req.query)}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInvitedByUser = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/businesses/invited?${qs.stringify(req.query)}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const BusinessService = {
  create,
  update,
  getSuggests,
  getJoined,
  getManage,
  getRequestJoined,
  getRequestMember,
  requestJoin,
  changeStatus,
  getMemberJoined,
  addMembers,
  getInviteMembers,
  getInvitedByUser,
};

export default BusinessService;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Col, Row } from "react-bootstrap";
import useTranslation from "hooks/useTranslation";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/reducers/hook";

import { getUserAsync, getUserByIdAsync } from "redux/reducers/users/users.actions";
import FilesService from "api/files";
import { getUrlImage } from "utils/common";
import UserService from "api/users";
import { CORVER_TYPE } from "config/enums";
import { v4 as uuidv4 } from "uuid";
import VIcon from "components/v-icon";
import VTab from "components/v-tab";
import PageBackgroundService from "api/page-backgrounds";

const menus = [
  {
    key: "image",
    name: "Image",
  },
  {
    key: "video",
    name: "Video",
  },
];

interface Iprops {
  dataUser : any, 
  type: string,
  setLoading: any, 
  setOpenModal: any,
  isMyProfile?: boolean
}
const ReusePhotos = ({ dataUser, type, isMyProfile, setLoading, setOpenModal }: Iprops, ref) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const [tab, setTab] = useState({
    key: "image",
    name: "Image",
  });
  const [filesImage, setFilesImage] = useState([]);
  const [filesVideo, setFilesVideo] = useState([]);

  const [state, setState] = useState([]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      handleSubmit();
    },
  }));

  const handleChooseImage = (file: any) => {
    const isExist = state?.find((el) => el._id === file._id);
    if (isExist) {
      const newState = state?.filter((el) => el._id !== file._id);
      setState(newState);
    } else if (type === "avatar") {
      setState([file]);
    } else {
      setState((state || []).concat(file));
    }
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let listUpload = state.map((st) => ({
        file: {},
        id: uuidv4(),
        isNew: true,
        src: st.post_file_path,
      }));
      const data = {
        type:
          tab?.key === "video"
            ? tab?.key
            : state?.length > 1
            ? CORVER_TYPE.SLIDE
            : CORVER_TYPE.IMAGE,
        url:
          state[0]?.post_file_path || state[0]?.image || state[0]?.video || "",
        slide: state?.length > 1 ? listUpload : [],
      };
      
      let res;
      if (type === "update_cover" || type === "update_background_profile") {
        const keyEdit = type;
        res = await UserService.updateCover({
          query: {
            key: keyEdit,
          },
          body: {
            ...data,
            user_id: dataUser?._id,
          },
        });
      }
      if (type === "avatar") {
        res = await UserService.editUser({
          body: {
            user_id: dataUser._id,
            avatar: listUpload[0]?.src,
          },
        });
      }

      if (res) {
        if (isMyProfile) dispatch(getUserAsync());
        dispatch(
          getUserByIdAsync({
            params: {
              id: dataUser?._id,
            },
          })
        );
        // toast.success(t("success"));
        setOpenModal(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t("Update failed"));
    }
  };

  const getAllFileUser = async () => {
    try {
      const res: any = await FilesService.getFilesByType({
        userId: dataUser._id,
        query: {
          file_type: "image",
        },
      });
      if (res) {
        setFilesImage(res || []);
      }
    } catch (error) {}
  };

  const getFileBackgroundProfile = async () => {
    try {
      const res: any = await PageBackgroundService.getBackgroundProfile({
        query: {
          type_of_use: "profile",
        },
      });
      if (res) {
        const images = res.filter((el) => el.type_file === "image");
        const videos = res.filter((el) => el.type_file === "video");
        setFilesImage(images || []);
        setFilesVideo(videos || []);
      }
    } catch (error) {}
  };

  const getVideoFileUser = async () => {
    try {
      const res: any = await FilesService.getFilesByType({
        userId: dataUser._id,
        query: {
          file_type: "video",
        },
      });
      if (res) {
        setFilesVideo(res || []);
      }
    } catch (error) {}
  };

  useEffect(() => {
    setState([]);
  }, [tab.key]);

  useEffect(() => {
    if (type === "update_background_profile") {
      getFileBackgroundProfile();
    } else {
      getAllFileUser();
      getVideoFileUser();
    }
  }, [type]);

  const renderTab = () => {
    if (tab?.key === "image") {
      return (
        <>
          {filesImage?.length > 0 ? (
            filesImage.map((el, index) => (
              <Col
                key={index}
                className={`col-4 mb-3 d-flex align-items-center justify-content-center`}
              >
                <div className="user-images position-relative overflow-hidden">
                  <div
                    className={`item-image ${
                      state?.find((file) => file._id === el._id)
                        ? "active-edit"
                        : ""
                    }`}
                    onClick={() => handleChooseImage(el)}
                  >
                    <img
                      src={getUrlImage(el?.post_file_path || el.image, 300)}
                      className="img-fluid rounded"
                      alt="Responsive"
                    />
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <span className="w-100 text-center mt-3">
              {t("No content to display")}
            </span>
          )}
        </>
      );
    }
    if (tab?.key === "video") {
      return (
        <>
          {filesVideo?.length > 0 ? (
            filesVideo.map((el, index) => (
              <Col
                key={index}
                className={`col-4 mb-3 d-flex align-items-center justify-content-center`}
              >
                <div className="user-images position-relative overflow-hidden">
                  <div
                    className={`item-image ${
                      state?.find((file) => file._id === el._id)
                        ? "active-edit"
                        : ""
                    }`}
                    onClick={() => handleChooseImage(el)}
                  >
                    {el?.thumbnail ? (
                      <img
                        src={getUrlImage(el?.thumbnail, 150)}
                        className="img-fluid rounded"
                        alt="Responsive"
                      />
                    ) : (
                      <video className="img-fluid rounded">
                        <source
                          src={getUrlImage(el.post_file_path || el?.video)}
                          type={el?.post_file_type || el?.type_file}
                        />
                        Your browser does not support the video tag.
                      </video>
                    )}
                  </div>
                  <div className="btn-play-video">
                    <VIcon name="play.svg" />
                  </div>
                </div>
              </Col>
            ))
          ) : (
            <span className="w-100 text-center mt-3">
              {t("No content to display")}
            </span>
          )}
        </>
      );
    }
  };

  return (
    <div className="container">
      {(type === "update_cover" || type === "update_background_profile") && (
        <VTab
          tab={tab}
          setTab={setTab}
          menus={menus}
          activeBorder="bottom"
          noBackground={true}
        />
      )}
      <Row>{renderTab()}</Row>
    </div>
  );
};

export default forwardRef(ReusePhotos);

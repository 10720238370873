/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from "react";
import FriendService from "api/friends";
import VFriend from "components/v-objects/v-friend";
import { useAppDispatch } from "redux/reducers/hook";
import { getDataAccessByOwnerAsync } from "redux/reducers/users/users.actions";
import VInfiniteLoad from "components/v-infinite-load";
import VSearch from "components/v-search";
import SearchService from "api/search";
import debounce from "lodash.debounce";
import { FRIEND_TYPE } from "config/enums";

const Friend = (props: any) => {
  const dispatch = useAppDispatch();
  const { dataUser, type, col } = props;
  const [loading, setLoading] = useState(false);
  const [friends, setFriends] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    limit: 18,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(1);
  const [valueSearch, setValueSearch] = useState("");

  useEffect(() => {
    if (dataUser && dataUser._id) getData();
  }, [lazyParams, type]);

  const getData = async () => {
    if (valueSearch) return;
    if (type === FRIEND_TYPE.MUTUAL_FRIENDS) {
      const res: any = await FriendService.getFriendMutual({
        body: {
          userIds: [dataUser?._id],
        },
      });
      if (res && res[0]?.users) {
        setFriends(
          res[0]?.users?.map((el) => ({
            to: el,
          }))
        );
      }
    } else {
      const res: any = await FriendService.getFriends({
        params: {
          user_id: dataUser._id,
        },
        query: {
          ...lazyParams,
          type,
        },
      });
      if (res && res.docs) {
        if (res.page === 1) {
          setFriends(res.docs);
        } else {
          setFriends((prev) => prev.concat(res.docs));
        }
        setTotalPages(res.totalPages);
      }
    }
    setLoading(false);
  };

  const handleViewMore = () => {
    setLoading(true);
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const reload = ({ to_id }) => {
    dispatch(getDataAccessByOwnerAsync());
    setFriends((friends) => friends.filter((f) => f.to_id !== to_id));
  };

  const handleSearchMembers = async (value: string) => {
    setValueSearch(value);
    if (!value) return getData();
    setLoading(true);
    const res: any = await SearchService.searchFriends({
      query: {
        page: 1,
        limit: 20,
        search: value,
        userId: dataUser._id,
      },
    });
    if (res && res.docs) {
      setLoading(false);
      const dataSearch = res.docs?.map((el) => ({
        to: el,
      }));
      setLoading(false);
      return setFriends(dataSearch);
    }
    return;
  };

  const debouncedChangeHandler = useCallback(
    debounce(handleSearchMembers, 1000),
    []
  );

  return (
    <div>
      <div className="col-12">
        <div className="mb-3 col-md-6">
          <VSearch
            handleChange={debouncedChangeHandler}
            className="add-members-search-input"
            handleKeyDown={() => {
              setLoading(true);
            }}
          />
        </div>
        <VInfiniteLoad
          canLoadMore={lazyParams.page + 1 <= totalPages}
          isLoadingMore={loading}
          onLoadMore={handleViewMore}
        >
          <div className="d-flex flex-wrap">
            {friends.map((friend, index) => {
              return (
                <div
                  key={index}
                  className={`d-flex justify-content-center p-1 col-6 ${
                    col || "col-md-4"
                  }`}
                >
                  <div className={`rps-container`}>
                    <VFriend
                      friend={friend}
                      dataAccessFriend={friend.data_access}
                      reload={reload}
                      type={type}
                    />
                  </div>
                </div>
              );
            })}
          </div>
        </VInfiniteLoad>
      </div>
    </div>
  );
};

export default Friend;

/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import {
  getFollowSuggestsAsync,
  getFriendRequestsAsync,
  getFriendSuggestsAsync,
} from "./friends.action";

export interface IFriendState {
  requests: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  suggests: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  followSuggests: {
    docs: any[];
    totalDocs: number;
    page: number;
    limit: number;
  };
  dataAccesses: any;
}

const initialState: IFriendState = {
  requests: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  suggests: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  followSuggests: {
    docs: [],
    totalDocs: 0,
    page: 1,
    limit: 12,
  },
  dataAccesses: {},
};

export const FriendSlice = createSlice({
  name: "friends",
  initialState,
  reducers: {
    setFriendRequest: (state: IFriendState, action: any) => {
      const { _id } = action.payload;
      const { docs } = state.requests;
      if (!docs.some((d) => d._id === _id)) {
        state.requests.docs = [action.payload, ...docs];
        state.requests.totalDocs = state.requests.totalDocs + 1;
      }
    },
    removeFriendRequest: (state: IFriendState, action: any) => {
      const { docs } = state.requests;
      state.requests.docs = docs.filter((f) => f.from_id !== action.payload);
      state.requests.totalDocs = state.requests.totalDocs - 1;
    },
    removeFriendSuggest: (state: IFriendState, action: any) => {
      const { docs } = state.suggests;
      state.suggests.docs = docs.filter((f) => f.user_id !== action.payload);
      state.suggests.totalDocs = state.suggests.totalDocs - 1;
    },
    removeFollowSuggest: (state: IFriendState, action: any) => {
      const { docs } = state.followSuggests;
      state.followSuggests.docs = docs.filter(
        (f) => f.user_id !== action.payload
      );
      state.followSuggests.totalDocs = state.followSuggests.totalDocs - 1;
    },
    setPageRequest: (state: IFriendState, action) => {
      const { page } = action.payload;
      state.requests.page = page;
    },
    setPageSuggest: (state: IFriendState, action) => {
      const { page } = action.payload;
      state.suggests.page = page;
    },
    setDataAccesses: (state: IFriendState, action) => {
      const data = action.payload;
      data.forEach((item) => {
        state.dataAccesses[item.user_id] = item;
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFriendRequestsAsync.pending, (state) => {})
      .addCase(getFriendRequestsAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.requests = action.payload || {};
        } else {
          state.requests = {
            ...state.requests,
            page,
            docs: state.requests.docs.concat(docs),
          };
        }
      })
      .addCase(getFriendSuggestsAsync.pending, (state) => {})
      .addCase(getFriendSuggestsAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.suggests = action.payload || {};
        } else {
          state.suggests = {
            ...state.suggests,
            page,
            docs: state.suggests.docs.concat(docs),
          };
        }
      })
      .addCase(getFollowSuggestsAsync.pending, (state) => {})
      .addCase(getFollowSuggestsAsync.fulfilled, (state, action) => {
        const { page, docs, errors } = action.payload;
        if (errors) return;
        if (page === 1) {
          state.followSuggests = action.payload || {};
        } else {
          state.followSuggests = {
            ...state.suggests,
            page,
            docs: state.followSuggests.docs.concat(docs),
          };
        }
      });
  },
});

export const {
  setFriendRequest,
  setPageRequest,
  setPageSuggest,
  removeFriendRequest,
  setDataAccesses,
  removeFriendSuggest,
  removeFollowSuggest,
} = FriendSlice.actions;

export default FriendSlice.reducer;

import { useAppSelector } from "redux/reducers/hook";
import { getUserSettings } from "redux/reducers/settings/settings.selector";

export const key_permissions: any = {
  allow_posted_wall: "my_post_who_create",
  post_comment: "my_post_comment",
  message: "message",
  my_info_view: "my_info_view",
};

const roleNotAllowed = ["friend_except"];

export const checkMyPermissions = (
  settings: any,
  userLoggined: string,
  key_permission: string,
  relation: any
) => {
  if (Object.keys(settings?.permissions || {}).length > 0) {
    const myRoleInGroup = Object.keys(settings?.managers || {}).filter(
      (key) => settings.managers[key].includes(userLoggined) && key !== "all"
    );

    const permissionCheck =
      settings.permissions[key_permissions[key_permission]]?.apply;

    const { fromFriend } = relation;
    if (permissionCheck) {
      if (permissionCheck?.length > 0) {
        return permissionCheck?.some(
          (v: string) =>
            (roleNotAllowed.includes(v) && !myRoleInGroup.includes(v)) ||
            (myRoleInGroup.includes(v) && !roleNotAllowed.includes(v)) ||
            v === "everybody" ||
            (v === "friend" && fromFriend?.type === "friend")
        );
      } else {
        return false;
      }
    } else {
      return true;
    }
  }
  return true;
};

const PermissionsGate = ({
  children,
  userLoggined,
  key_permission,
  opacity = 0,
  relation,
}: {
  children: React.ReactElement;
  userLoggined: string;
  key_permission: string;
  opacity?: number;
  relation: any;
}) => {
  // const { checkMyPermissionsInGroupChat } = useContext(ConversationContext);

  const settings: any = useAppSelector(getUserSettings);

  if (checkMyPermissions(settings, userLoggined, key_permission, relation)) {
    return <>{children}</>;
  } else {
    return (
      <>
        {opacity === 0 ? (
          <></>
        ) : (
          <div
            style={{
              opacity: opacity,
              pointerEvents: "none",
            }}
          >
            {children}
          </div>
        )}
      </>
    );
  }
};

export default PermissionsGate;

/* eslint-disable indent */

import useTranslation from "hooks/useTranslation";
import VButton from "components/v-button";
import { getUrlImage } from "utils/common";

type IProps = {
  menus?: any[];
  onClickCreate?: (el: any) => void;
  setIsOpen: any;
  isKyc: boolean;
  isLimitCreatePost: boolean;
};
const View = ({
  menus = [],
  onClickCreate,
  setIsOpen,
  isKyc,
  isLimitCreatePost,
}: IProps) => {
  const { t } = useTranslation();

  const renderDefaultMenu = () => (
    <>
      <VButton
        disabled={isLimitCreatePost}
        className="btn-action"
        onClick={() => {
          onClickCreate("home");
          setIsOpen(false);
        }}
      >
        <img src={"/assets/images/post-create/diary.svg"} alt="" />
        <div className="v-create">{t("Tạo Nhật ký")}</div>
      </VButton>
      <VButton
        disabled={isLimitCreatePost}
        className="btn-action"
        onClick={() => {
          onClickCreate("product");
          setIsOpen(false);
        }}
      >
        <img src="/assets/images/post-create/shopping-cart.svg" alt="" />
        <div className="v-create">
          <span>{t("Tạo niêm yết")}</span>
        </div>
      </VButton>
      <VButton
        disabled={isLimitCreatePost}
        className="btn-action"
        onClick={() => onClickCreate("event")}
      >
        <img src={"/assets/images/post-create/event.svg"} alt="" />
        <div className="v-create">{t("Tạo sự kiện")}</div>
      </VButton>
      <VButton
        disabled={isLimitCreatePost}
        className="btn-action"
        onClick={() => onClickCreate("survey")}
      >
        <img src={"/assets/images/post-create/survey.svg"} alt="" />
        <div className="v-create">{t("Tạo khảo sát")}</div>
      </VButton>
      <VButton
        disabled={isLimitCreatePost}
        className="btn-action"
        onClick={() => onClickCreate("template")}
      >
        <img src={"/assets/images/post-create/images.svg"} alt="" />
        <div className="v-create">{t("Tạo khung ảnh")}</div>
      </VButton>
      <VButton
        disabled={!isKyc}
        className="btn-action"
        onClick={() => {
          if (!isKyc) return;
          onClickCreate("contribute");
        }}
      >
        <img src={"/assets/images/post-create/contribute.svg"} alt="" />
        <div className="v-create">{t("Tạo đóng góp")}</div>
      </VButton>
    </>
  );

  if (+menus.length === 0) {
    return renderDefaultMenu();
  }    

  return (
    <>
      {
        menus?.map((menu: any) => (
          <VButton
            className="btn-action"
            onClick={() => {
              onClickCreate(menu);
              setIsOpen(false);
            }}
          >
            <img src={getUrlImage(menu?.img_icon, 25)} width="14" height="14" alt="" />
            <div className="v-create">{menu.name}</div>
          </VButton>
        ))
      }
    </>
  )
};

export default View;

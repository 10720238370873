/* eslint-disable react-hooks/exhaustive-deps */
import { Carousel } from "react-bootstrap";
import * as SettingSelector from "redux/reducers/setting-system/selectors";
import { useEffect, useMemo, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getQuotas } from "redux/reducers/quotas/quotas.selector";
import { getQuotasAsync } from "redux/reducers/quotas/quotas.action";
import { getUrlImage } from "utils/common";
import { isMobile } from "react-device-detect";
import VIcon from "components/v-icon";
import Card from "components/v-teamplate/Card";

const View = () => {
  const _quotas = useAppSelector(getQuotas);
  const dispatch = useAppDispatch();
  const loaded = useRef(false);
  const [quotaShow, setQuotaShow] = useState<any>({});

  useEffect(() => {
    dispatch(getQuotasAsync());
  }, []);

  const themeScheme = useAppSelector(SettingSelector.theme_scheme);
  const carouselRef = useRef(null);

  const handleClose = () => {
    carouselRef.current.next();
  };

  const quotas = useMemo(() => {
    if (_quotas && _quotas.length > 0) {
      const prioritizes = _quotas.filter((q) => q.is_prioritize);
      if (prioritizes.length > 0) return prioritizes;
      else return _quotas;
    }
    return [];
  }, [_quotas]);

  const randomQuota = (quotas) => {
    const index = Math.floor(Math.random() * quotas?.length);
    return quotas[index];
  };

  useEffect(() => {
    if (quotas.length > 0 && !loaded.current) {
      loaded.current = true;
      setQuotaShow(quotas.length <= 1 ? quotas[0] : randomQuota(quotas));
    }
  }, [quotas]);

  if (!quotaShow) {
    return <></>;
  }

  if (!quotas || quotas?.length === 0) return <></>;

  return (
    <Card className="overflow-hidden">
      <div
        className={`${quotas?.length > 0 ? "quota-container" : ""} v-quote`}
        style={{
          backgroundImage: `url(${
            isMobile
              ? quotaShow?.background_image_mobile &&
                getUrlImage(quotaShow?.background_image_mobile, 700)
              : quotaShow?.background_image &&
                getUrlImage(quotaShow?.background_image, 700)
          })`,
          backgroundSize: "cover",
        }}
      >
        {quotaShow?.content && (
          <Carousel ref={carouselRef} interval={6000}>
            {Array(5)
              .fill(0)
              ?.map((item, index) => {
                const el = randomQuota(quotaShow?.content || []);
                return (
                  <Carousel.Item key={index}>
                    <a
                      target="_blank"
                      rel="noopener noreferrer"
                      href={quotaShow?.link}
                      className={`quota ${themeScheme}`}
                    >
                      <div className="quota-left">
                        {/* <h3 className="title d-none d-sm-block">{quota.name}</h3> */}
                        <p
                          className="content"
                          style={{
                            color: isMobile
                              ? quotaShow?.color_mobile
                              : quotaShow?.color,
                          }}
                        >
                          {el?.name}
                        </p>
                      </div>
                      <div className="quota-right d-none d-sm-flex align-items-center">
                        <img
                          className="rounded-circle"
                          src={getUrlImage(quotaShow?.image, 50)}
                          alt="icon"
                        />
                      </div>
                    </a>
                    <button
                      className="quota-close"
                      onClick={() => handleClose()}
                    >
                      <VIcon width={14} name="close.svg" />
                    </button>
                  </Carousel.Item>
                );
              })}
          </Carousel>
        )}
      </div>
    </Card>
  );
};

export default View;

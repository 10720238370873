/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
// import useTranslation from "hooks/useTranslation";s
import { getIsLoggedIn } from "redux/reducers/users/users.selector";

// import VButton from "components/v-button";
import { getBusinessSuggestsAsync } from "redux/reducers/businesses/businesses.action";
import { getSuggestBusinesses } from "redux/reducers/businesses/businesses.selector";
import { BUSINESS_ACTION } from "config/enums";
import { removeBusinessSuggest } from "redux/reducers/businesses/businesses.reducer";
import VBusinessCard from "components/v-objects/v-business-card";
import VInfiniteLoad from "components/v-infinite-load";

const View = () => {
  // const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const businesses: any = useAppSelector(getSuggestBusinesses);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = businesses;

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getBusinessSuggestsAsync({
        query: {
          page,
          limit,
        },
      })
    );
    setLoading(false);
  };

  const handleViewMore = () => {
    getData({
      page: page + 1,
    });
  };

  const reload = ({ to_id }) => {
    dispatch(removeBusinessSuggest(to_id));
  };

  return (
    <>
      <VInfiniteLoad
        canLoadMore={page + 1 <= businesses.totalPages}
        isLoadingMore={loading}
        onLoadMore={handleViewMore}
      >
        <div>
          <div className="row">
            {businesses &&
              (businesses.docs || []).map((business, index) => (
                <VBusinessCard
                  count={business?.count?.count?.business}
                  key={index}
                  col_lg={3}
                  col_md={4}
                  business={business}
                  type={BUSINESS_ACTION.REQUEST_JOIN}
                  reload={reload}
                />
              ))}
          </div>
          {/* <div className="col-12">
            {page < businesses.totalPages && (
              <div className="text-center p-2">
                <VButton
                  type="button"
                  loading={loading}
                  size={"small"}
                  onClick={handleViewMore}
                >
                  {" "}
                  {t("View more")}
                </VButton>
              </div>
            )}
          </div> */}
        </div>
      </VInfiniteLoad>
    </>
  );
};

export default View;

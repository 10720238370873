import React from "react";
import useTranslation from "hooks/useTranslation";
import { Container, Row, Col, Card, Image } from "react-bootstrap";

const Maintenance = () => {
  const { t } = useTranslation();
  return (
    <Container className="p-0">
      <Card className="pb-3">
        <div className="mt-5 iq-maintenance">
          <Row className="no-gutters">
            <Col sm="12" className="text-center">
              <div className="iq-maintenance">
                <Image
                  src={"/assets/images/maintenance-404/maintenance.png"}
                  className="img-fluid"
                  alt=""
                />
                <h5 className="mb-0 content-maintenance">
                  {t("content_maintenance")}
                </h5>
              </div>
            </Col>
          </Row>
          {/* <Container className="mt-3">
            <Row>
              <Col lg="4">
                <Card className="text-center">
                  <Card.Body>
                    <i className="ri-window-line ri-4x line-height text-primary"></i>
                    <h5 className="card-title mt-1 text-center">
                      Why is the Site Down?
                    </h5>
                    <p className="mb-0 text-center">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="text-center">
                  <Card.Body>
                    <i className="ri-time-line ri-4x line-height text-primary"></i>
                    <h5 className="card-title mt-1 text-center">
                      What is the Downtime?
                    </h5>
                    <p className="mb-0 text-center">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
              <Col lg="4">
                <Card className="text-center">
                  <Card.Body>
                    <i className="ri-information-line ri-4x line-height text-primary"></i>
                    <h5 className="card-title mt-1 text-center">
                      Do you need Support?
                    </h5>
                    <p className="mb-0 text-center">
                      It is a long established fact that a reader will be
                      distracted by the readable content of a page when looking
                      at its layout.
                    </p>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container> */}
        </div>
      </Card>
    </Container>
  );
};

export default Maintenance;

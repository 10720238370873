/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
// import VButton from "components/v-button";
import { getRequestFriends } from "redux/reducers/friends/friends.selector";
import { getFriendRequestsAsync } from "redux/reducers/friends/friends.action";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";
import VFriend from "components/v-objects/v-friend";
import {
  removeFriendRequest,
  setDataAccesses,
} from "redux/reducers/friends/friends.reducer";
import UserService from "api/users";
import { getDataAccessByOwnerAsync } from "redux/reducers/users/users.actions";
import { Container } from "react-bootstrap";
import VInfiniteLoad from "components/v-infinite-load";

const FriendSuggest = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const friends: any = useAppSelector(getRequestFriends);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = friends;

  useEffect(() => {
    dispatch(getDataAccessByOwnerAsync());
  }, []);

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    const res: any = await dispatch(
      getFriendRequestsAsync({
        query: {
          page,
          limit,
        },
      })
    );
    if (res.payload && res.payload.docs) {
      _getDataAccesses(res.payload.docs.map((f) => f.from_id));
    }

    setLoading(false);
  };

  const handleViewMore = () => {
    getData({
      page: page + 1,
    });
  };

  const _getDataAccesses = async (userIds) => {
    const res: any = await UserService.getDataAccessByUsers({
      query: {
        userIds,
      },
    });
    if (res && res.length > 0) {
      dispatch(setDataAccesses(res));
    }
  };

  const reload = ({ to_id }) => {
    dispatch(removeFriendRequest(to_id));
    dispatch(getDataAccessByOwnerAsync());
  };

  return friends.docs?.length > 0 ? (
    <VInfiniteLoad
      canLoadMore={page + 1 <= friends.totalPages}
      isLoadingMore={loading}
      onLoadMore={handleViewMore}
    >
      <div>
        <Container>
          <div className="d-flex flex-wrap">
            {(friends.docs || []).map(
              (friend, index) =>
                friend && (
                  <div
                    key={index}
                    className={`d-flex justify-content-center p-1 col-6 col-lg-2 col-md-3`}
                  >
                    <div className="rps-container">
                      <VFriend
                        friend={{
                          to: friend.from,
                          to_id: friend.from_id,
                          profile: friend.profile,
                        }}
                        dataAccessFriend={friend.data_access}
                        reload={reload}
                      />
                    </div>
                  </div>
                )
            )}
          </div>
        </Container>

        {/* <div className="col-12">
          {page < friends.totalPages && (
            <div className="text-center p-2">
              <VButton
                type="button"
                loading={loading}
                size={"small"}
                onClick={handleViewMore}
              >
                {" "}
                {t("View more")}
              </VButton>
            </div>
          )}
        </div> */}
      </div>
    </VInfiniteLoad>
  ) : (
    <div className="text-center w-100">{t("No invitations")}</div>
  );
};

export default React.memo(FriendSuggest, (prev, next) => {
  return true;
});

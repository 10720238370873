/* eslint-disable indent */

import { Col } from "react-bootstrap";

import SidebarSettingObjects from "./sidebar";
import useQuery from "hooks/useQuery";
import SettingsUser from "pages/social/settings/components/settings";
import VerificationObject from "../verification";
import Affiliates from "pages/social/settings/components/affiliate-settings/components/referral";
import SpecialNameSetting from "pages/social/settings/components/special-name-settings";
import PrivacySecurity from "pages/social/settings/components/privacy-security";
import AccountSetting from "pages/social/settings/components/account-settings/View";

import DeleteAccountSetting from "pages/social/settings/components/detete-account-settings";
import VisitCard from "pages/social/settings/components/visit-card";
import About from "../profile-about";
import { checkIsMyProfile } from "utils/common";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";

const View = (props) => {
  const { dataUser } = props;
  const query = useQuery();
  
  const profile = useAppSelector(getUserProfile);
  const isMyProfile = checkIsMyProfile(profile, dataUser?._id);
  
  const renderSetting = () => {
    switch (query?.key) {
      case "general":
        return <SettingsUser dataUser={dataUser} />;
      case "verification":
        return <VerificationObject dataUser={dataUser} />;
      case "affiliate":
        return <Affiliates dataUser={dataUser} />;
      case "special-name":
        return <SpecialNameSetting dataUser={dataUser} />;
      case "privacy_security":
        return <PrivacySecurity dataUser={dataUser} />;
      case "profile":
        return (
          <About
            isAuth={true}
            dataUser={dataUser}
            isMyProfile={isMyProfile}
          />
        );
      case "account":
        return <AccountSetting dataUser={dataUser} />;
      case "visit-card":
        return <VisitCard dataUser={dataUser} />;
      case "delete-account":
        return <DeleteAccountSetting dataUser={dataUser} />;
      default:
        break;
    }
  };
  return (
    <div className="justify-content-center d-flex flex-wrap flex-md-nowrap">
      <Col md={3} className={"pe-md-1 col-12"}>
        <SidebarSettingObjects dataUser={dataUser} />
      </Col>
      <Col md={9} className={"col-12 ps-md-1 verification-wrapper"}>
        {renderSetting()}
      </Col>
    </div>
  );
};

export default View;

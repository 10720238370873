import React from "react";

//router
import { Switch, Route } from "react-router-dom";

import Auth from "pages/auth";
import { ROUTES } from "config/routes";
// import BusinessLandingPage from "components/business-landing-page";
import Invoice from "pages/invoice/invoice-ico";
import InvoicePayment from "pages/invoice/invoice-payment";
import CertificateHoldStocks from "pages/certificate/hold-stocks/View";
import InvoiceInvest from "pages/invoice/invoice-invest";
import InvoiceProduct from "pages/invoice/invoice-product";
// import ComingSoon from "pages/coming-soon/comingsoon";

import Dashboard from "./dashboard";
import EmbedPost from "pages/embed/post";
import Message from "pages/social/message";
import SOS from "pages/sos/maps";
import Conversations from "pages/social/user/profile/conversations/index";
import ConversationPlugins from "pages/social/user/profile/conversations/plugins";
import Banner from "pages/banner";
import QuestionForm from "pages/question-form";
import ChatDemo from "pages/chat-demo";
import InvoiceCertificate from "pages/invoice/invoice-product-image";
import ChatPreview from "pages/chat-demo/preview";

import HomeV2 from "pages/home-v2";
import ShareUrl from "pages/share";
import VeriticationWebView from "pages/social/web-view/veritication";
import SpecialNameWebView from "pages/social/web-view/special-name";

const IndexRouters = () => {
  return (
    <>
      <Switch>
        <Route path="/auth" component={Auth}></Route>
        <Route path="/share" component={ShareUrl}></Route>

        {/* <Route
          path={ROUTES.LANGDINGPAGE_PROJECT}
          component={BusinessLandingPage}
        /> */}
        <Route path={ROUTES.INVOICE} component={Invoice} />
        <Route path={ROUTES.INVOICE_PAYMENT} component={InvoicePayment} />
        <Route path={ROUTES.INVOICE_INVEST} component={InvoiceInvest} />
        <Route path={ROUTES.INVOICE_PRODUCT} component={InvoiceProduct} />
        <Route
          path={ROUTES.INVOICE_CERTIFICATE}
          component={InvoiceCertificate}
        />
        <Route
          path={ROUTES.CERTIFICATE_HOLD_STOCK}
          component={CertificateHoldStocks}
        />

        <Route path={ROUTES.EMBED_POST} component={EmbedPost} />

        <Route path={ROUTES.MESSAGE} component={Message} />
        <Route path={ROUTES.CHAT_DEMO} component={ChatDemo} />
        <Route path={ROUTES.CHAT_JOIN_ROOM} component={ChatPreview} />

        <Route
          path={ROUTES.CONVERSATION_INVITE}
          component={ConversationPlugins}
        />
        <Route exact path={ROUTES.CONVERSATION} component={Conversations} />
        <Route path={ROUTES.CONVERSATIONS} component={Conversations} />

        {/* sos map */}
        <Route path={ROUTES.SOS_MAP} component={SOS} />
        <Route path={ROUTES.BANNER} component={Banner} />
        <Route path={ROUTES.QUESTION_FORM} component={QuestionForm} />

        <Route path="/home-v2" component={HomeV2} />
        <Route path="/verification" component={VeriticationWebView} />
        <Route path="/special-name" component={SpecialNameWebView} />
        
        <Dashboard path="/" component={Dashboard} />
      </Switch>
    </>
  );
};

export default IndexRouters;

import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getConversation = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/conversation?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
const getConversationsByUserId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/conversation/by-user/${req.params.user_id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });
const getConversationDetailById = (id: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/conversation/${id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const checkChatExistV2 = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/v2/conversations/check/${req.params.user_id}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMyConversationUnread = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/v2/conversations/unread`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const ConversationsService = {
  getConversationDetailById,
  getConversationsByUserId,
  getConversation,
  checkChatExistV2,
  getMyConversationUnread
};

export default ConversationsService;

/* eslint-disable react-hooks/exhaustive-deps */
import { createContext, useEffect, useState } from "react";
import BannerApi from "api/banners";
import { getUrlImage } from "utils/common";
import { isMobile } from "react-device-detect";

const FlipBookImageContext = createContext<any>(0);

export const FlipBookImageProvider = ({ children }) => {
  const [flipBookImage, setFlipBookImage] = useState<any>(null);

  const getMetaSetting = async () => {
    try {
      let imageFlip = { ...flipBookImage };

      const flipBookCoverPc: any = await BannerApi.getsByPosition({
        params: {
          position: "flipbook_cover_pc",
        },
      });

      let flipBookCoverMobile: any;
      if (isMobile) {
        flipBookCoverMobile = await BannerApi.getsByPosition({
          params: {
            position: "flipbook_cover_mobile",
          },
        });
      }

      const imageAt = (index: number) =>
        (flipBookCoverMobile || flipBookCoverPc)?.[0]?.images?.[index]?.link ||
        getUrlImage(
          (flipBookCoverMobile || flipBookCoverPc)?.[0]?.images?.[index]?.src
        );

      imageFlip = {
        ...imageFlip,
        name: "",
        flipbook_cover_first: imageAt(0),
        flipbook_cover_end: imageAt(1),
        flipbook_cover_background: imageAt(2),
      };

      setFlipBookImage(imageFlip);
    } catch (error) {}
  };

  useEffect(() => {
    if (!flipBookImage) {
      getMetaSetting();
    }
  }, []);

  return (
    <FlipBookImageContext.Provider value={{ flipBookImage, setFlipBookImage }}>
      {children}
    </FlipBookImageContext.Provider>
  );
};

export default FlipBookImageContext;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
/* eslint-disable jsx-a11y/iframe-has-title */
import {
  // Row,
  Col,
  Container,
} from "react-bootstrap";

// import VBannerPage from "components/v-teamplate/v-banner-page";
import PageBackgrounds from "components/v-page-background";
import Card from "components/v-teamplate/Card";
import useQuery from "hooks/useQuery";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";
import { setOpenShopPopupAction, setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import { useAppSelector } from "redux/reducers/hook";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { getIsApp } from "redux/reducers/settings/settings.selector";
import VCreatePopover from "components/v-create-popover";
import { convertUrl } from "utils/common";
import NewsFeedContext from "context/NewsFeedContext/View";

const Shop = () => {
  const query = useQuery();
  const { i18n } = useTranslation();

  const [isOpen, setIsOpen] = useState(false);

  const pathname = window.location.pathname;
  const newsFeedContext = useContext(NewsFeedContext);
  const userData: any = useAppSelector(getUserProfile);

  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const dispatch = useDispatch();
  const { openShopKey } = useSelector(getUISettingState);
  const history = useHistory();
  const user = useAppSelector(getUserProfile);
  const isApp = useAppSelector(getIsApp);

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (openShopKey === "open-user-profile-to-create-product") {
      dispatch(
        setOpenShopPopupAction({
          openShopKey: "open-create-product",
        })
      );
      history.push(`/profile/${user?._id}?tab=products`);
    }
  }, [openShopKey]);

  const handleClickMenu = (menu: any) => {
    if (isMobile) {
      document
        .getElementsByTagName("ASIDE")[0]
        ?.classList.toggle("sidebar-mini");
    }
    switch (menu.type_view) {
      case "internal":
        if (menu.key === "news_feed")
          newsFeedContext.setTimeReloaded(Date.now());
        history.push(convertUrl(menu.url, userData) || "/");
        break;
      case "link":
        window.open(convertUrl(menu.url), "_blank");
        break;
      case "webview":
        history.push(`/wv/${menu.key}`);
        break;
      default:
        break;
    }
  };

  const onClickCreate = (page: any) => {
    if (typeof page !== 'string') {
      handleClickMenu(page);
      return;
    }

    let key = "post_create";
    if (page === "product") {
      key = "open-create-product-quick";
    }
    setIsOpen(false);
    dispatch(
      setOpenWebModulesPopupAction({
        page: page,
        ...(page === "product" && { userId: user?._id }),
        openWebModuleKey: key,
      })
    );
  };

  return (
    <div className={`product-wrapper ${pathname.includes('shop-list') ? 'list': 'normal'}`}>
      <PageBackgrounds page={"page_bg_shop"} />
      <Container className="p-0 product-container">
        <Col sm={12} className="d-flex">
          <Col sm={12} className={isMobile ? "w-full" : ""}>
            {/* {!isMobile && <VBannerPage position="banner_top_shop" />} */}
            <Col sm={12} className={"mt-2 "}>
              <Card className="shop-view" isOpacity={true}>
                <Col sm={12}>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    <iframe
                      className={`${
                        isApp ? "iframe-is-app" : ""
                      } iframe-product-list`}
                      allow="clipboard-read; clipboard-write"
                      src={`${
                        process.env.REACT_APP_WEB_MODULE_URL
                      }/${pathname}?vdb_token=${localStorage.getItem(
                        "auth"
                      )}&${qs.stringify(query)}&origin=${window.origin}
                      &moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&lang=${
                        i18n.language ? `${i18n.language}` : ""
                      }&back=${window.origin}/${pathname}`}
                    >
                      <p>Your browser does not support iframes.</p>
                    </iframe>
                  </div>
                </Col>
              </Card>
            </Col>
          </Col>
        </Col>
        <VCreatePopover 
          setIsOpen = {setIsOpen}
          isOpen = {isOpen}
          keyGroup="shop_dashboard"
          onClickCreate = {onClickCreate}
        />
      </Container>
    </div>
  );
};

export default Shop;

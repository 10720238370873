import { combineReducers } from "redux";
import Crypto, { ICryptoState } from "./cryptos/cryptos.reducer";
import Banks, { IBanksState } from "./banks/banks.reducer";

import User, { IUsersState } from "./users/users.reducer";
import Wallet, { IWalletState } from "./wallets/wallets.reducer";
import Auth, { IAuthState } from "./auth/auth.reducer";
import Security, { ISecurityState } from "./securities/securities.reduce";
import ProjectSlice, { IProjectState } from "./projects/projects.reducer";
import OrdersSlice, { IPruchasesState } from "./purchases/purchases.reducer";
import TasksSlice, { ITasksState } from "./tasks/tasks.reducer";
import advertismentsReducers, {
  IAdvertisementState,
} from "./advertisments/advertisments.reducers";
import settingsReducer, { ISettingState } from "./settings/settings.reducer";
import NotificationSlice, {
  INotificationState,
} from "./notifications/notifications.reducers";
import SettingSlice from "./setting-system/reducers";
import Faqs, { IFaqsState } from "./faqs/faqs.reducers";
import Friends, { IFriendState } from "./friends/friends.reducer";
import Quotas, { IQuotasState } from "./quotas/quotas.reduce";
import Business, { IBusinessState } from "./businesses/businesses.reducer";
import UISettings, { IUISettingState } from "./ui-settings/ui-settings.reducer";
import PostDirectory, {
  IPostDirectoryState,
} from "./post-directory/post-directory.reducers";
import Group, { IGroupState } from "./groups/groups.reducer";
import Page, { IPageState } from "./pages/pages.reducer";
import Channel, { IChannelState } from "./channels/channels.reducer";
import Categories, { ICategoriessState } from "./categories/categories.reducers";
export interface IRootState {
  categories: ICategoriessState;
  users: IUsersState;
  wallets: IWalletState;
  cryptos: ICryptoState;
  banks: IBanksState;
  auth: IAuthState;
  securities: ISecurityState;
  projects: IProjectState;
  purchases: IPruchasesState;
  tasks: ITasksState;
  advertisements: IAdvertisementState;
  settings: ISettingState;
  notifications: INotificationState;
  setting: any;
  faqs: IFaqsState;
  friends: IFriendState;
  quotas: IQuotasState;
  businesses: IBusinessState;
  UISettings: IUISettingState;
  postDirectory: IPostDirectoryState;
  groups: IGroupState;
  pages: IPageState;
  channels: IChannelState;
}

export default combineReducers<IRootState>({
  categories: Categories,
  users: User,
  wallets: Wallet,
  cryptos: Crypto,
  banks: Banks,
  auth: Auth,
  securities: Security,
  projects: ProjectSlice,
  purchases: OrdersSlice,
  tasks: TasksSlice,
  advertisements: advertismentsReducers,
  settings: settingsReducer,
  notifications: NotificationSlice,
  setting: SettingSlice,
  faqs: Faqs,
  friends: Friends,
  quotas: Quotas,
  businesses: Business,
  UISettings: UISettings,
  postDirectory: PostDirectory,
  groups: Group,
  pages: Page,
  channels: Channel
});

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
import { renderDate } from "utils/times";
import { getHobbies } from "redux/reducers/settings/settings.selector";
import { useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import { getUrlImage, isValidURL } from "utils/common";
import VButton from "components/v-button";
import VerificationOptions from "../../verification-options";
import { useState } from "react";
import { TYPE_KYC } from "config/enums";
import VIcon from "components/v-icon";
import { Link } from "react-router-dom";

const View = ({ userData, isAuth = false }) => {
  const { t } = useTranslation();
  const hobbies = useAppSelector(getHobbies);
  const { profile } = userData;

  const [openModal, setOpenModal] = useState(false);
  const handleClick = (url) => {
    window.open(url);
  };

  return (
    <>
      <div className="row-account">
        <div className="label">{t("Family")}</div>
        <div className="content">
          <div className="d-flex align-items-center">
            {profile?.family?.logo && (
              <img
                className="me-2"
                width={16}
                height={16}
                src={getUrlImage(profile?.family.logo)}
                alt=""
              />
            )}
            {t(profile?.family?.name)}
          </div>
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Genus family")}</div>
        <div className="content">{profile?.genus_family}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("First name")}</div>
        <div className="content">{userData?.first_name}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("Last name")}</div>
        <div className="content">{userData?.last_name}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("Phone")}</div>
        <div className="content">{userData?.phone}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("Email")}</div>
        <div className="content">{userData?.email}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("Gender")}</div>
        <div className="content">{t(profile?.gender)}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("Birthday")}</div>
        <div className="content">{renderDate(profile?.birthday)}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("Hobbies")}</div>
        <div className="content">
          {hobbies
            ?.filter((el) => profile?.hobbies?.includes(String(el._id)))
            ?.map((item) => (
              <div key={item._id} className="d-inline-block me-1">
                <div className="d-flex align-items-center">
                  {item.logo && (
                    <img
                      className="me-2"
                      width={16}
                      height={16}
                      src={getUrlImage(item.logo)}
                      alt=""
                    />
                  )}
                  {t(item.name)},
                </div>
              </div>
            ))}
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Nation")}</div>
        <div className="content">
          <div className="d-flex align-items-center">
            {profile?.nation?.logo && (
              <img
                className="me-2"
                width={16}
                height={16}
                src={getUrlImage(profile?.nation.logo)}
                alt=""
              />
            )}
            {t(profile?.nation?.name)}
          </div>
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Religion")}</div>
        <div className="content">
          <div className="d-flex align-items-center">
            {profile?.religion?.logo && (
              <img
                className="me-2"
                width={16}
                height={16}
                src={getUrlImage(profile?.religion.logo)}
                alt=""
              />
            )}
            {t(profile?.religion?.name)}
          </div>
        </div>
      </div>
      <div className="row-account">
        <div className="label">
          <div className="d-flex">
            {t("Job")}{" "}
            {userData?.status_kyc_career === "kyc" && (
              <VIcon className="ms-2" name="logo-verify.svg" width={10} />
            )}
          </div>
          {isAuth &&
            userData?.profile?.career &&
            userData?.status_kyc_career !== "kyc" && (
              <VButton
                className={"py-0 px-2"}
                onClick={() => setOpenModal(true)}
              >
                {t("Verification")}
              </VButton>
            )}
        </div>
        <div className="content">
          <div className="d-flex align-items-center">
            {profile?.career?.logo && (
              <img
                className="me-2"
                width={16}
                height={16}
                src={getUrlImage(profile?.career.logo)}
                alt=""
              />
            )}
            {t(profile?.career?.name)}
          </div>
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Category field")}</div>
        <div className="content">
          <div className="d-flex align-items-center">
            {profile?.field?.logo && (
              <img
                className="me-2"
                width={16}
                height={16}
                src={getUrlImage(profile?.field.logo)}
                alt=""
              />
            )}
            {t(profile?.field?.name)}
          </div>
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Slogan")}</div>
        <div className="content">{profile?.slogan}</div>
      </div>
      <div className="row-account">
        <div className="label">{t("Address")}</div>
        <div className="content">
          <Link
            onClick={() =>
              handleClick(
                `https://maps.google.com/maps?q=${encodeURIComponent(
                  userData?.location_detail
                )}&t={{2}}&z=14&ie=UTF8`
              )
            }
            target={"_blank"}
          >
            {userData?.location_detail || ""}
          </Link>
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Hometown")}</div>
        <div className="content">
          <Link
            onClick={() =>
              handleClick(
                `https://maps.google.com/maps?q=${encodeURIComponent(
                  userData?.home_town_detail
                )}&t={{2}}&z=14&ie=UTF8`
              )
            }
            target={"_blank"}
          >
            {userData?.home_town_detail || ""}
          </Link>
        </div>
      </div>
      <div className="row-account">
        <div className="label">{t("Website")}</div>
        <div className="content">
          {isValidURL(profile?.website) ? (
            <a href={profile?.website} target={"_blank"} rel="noreferrer" >
              {profile?.website}
            </a>
          ) : (
            profile?.website
          )}
        </div>
      </div>
      {profile?.secondary_site?.length > 0 &&
        profile?.secondary_site.map((el) => (
          <div key={el?.id} className="row-account">
            <div className="label">{el?.name_website}</div>
            <div className="content">
              {isValidURL(el?.value) ? (
                <a href={el?.value} target={"_blank"} rel="noreferrer">
                  {el?.value}
                </a>
              ) : (
                el?.value
              )}
            </div>
          </div>
        ))}
      <div className="row-account">
        <div className="label">{t("Descriptions")}</div>
        <div className="content">{profile?.description}</div>
      </div>
      <VerificationOptions
        userData={userData}
        type={TYPE_KYC.CAREER}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
    </>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import Card from "components/v-teamplate/Card";
import { OBJECT_TYPE } from "config/enums";
import FooterForm from "pages/auth/components/footer-form";
import HeaderForm from "pages/auth/components/header-form";
import useTranslation from "hooks/useTranslation";
import FormRegisterBusiness from "./components/form-business";
import FormRegisterOrther from "./components/form-orther";
import useQuery from "hooks/useQuery";
import UserService from "api/users";
import { useEffect, useState } from "react";
const Step1 = (props) => {
  const {
    role,
    setStep,
    isValid,
    validate,
    setIsValid,
    handleChangeState,
    setLoading,
    loading,
    state,
    handleRegister,
    setOptions
  } = props;
  const query = useQuery();
  const { t } = useTranslation();

  const roleBusinessOrOrgan = role === OBJECT_TYPE.BUSINESS || role === OBJECT_TYPE.ORGANIZATION
  const [userBy, seUserBy] = useState()
  
  const getUserByReferCode = async () => {
    try {
      const res: any = await UserService.getUserByReferCode({
        refer_code: query.refer_code
      })
      seUserBy(res.user)
    } catch (error) {
      
    }
  }

  useEffect(() => {
    if(query.refer_code){
      getUserByReferCode()
    }
  }, [query.refer_code]);

  return (
    <Card className="form-user col-md-6" isOpacity={true}>
      <HeaderForm />
      <div className="d-flex justify-content-between align-items-center">
        <h1 className="mb-0 fs-2">{t("register")}</h1>
      </div>
      {roleBusinessOrOrgan && (
        <FormRegisterBusiness
          userBy={userBy}
          setStep={setStep}
          loading={loading}
          role={role}
          state={state}
          isValid={isValid}
          setIsValid={setIsValid}
          validate={validate}
          setLoading={setLoading}
          setOptions={setOptions}
          handleRegister={handleRegister}
          handleChangeState={handleChangeState}
        />
      )}
      {!roleBusinessOrOrgan && (
        <FormRegisterOrther
          userBy={userBy}
          role={role}
          state={state}
          isValid={isValid}
          setIsValid={setIsValid}
          validate={validate}
          setStep={setStep}
          setOptions={setOptions}
          loading={loading}
          setLoading={setLoading}
          handleRegister={handleRegister}
          handleChangeState={handleChangeState}
        />
      )}
      <FooterForm 
        isHideGoogle={true}
      />
    </Card>
  );
};

export default Step1;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Tab } from "react-bootstrap";
import Card from "components/v-teamplate/Card";
import VTab from "components/v-tab";
import useTranslation from "hooks/useTranslation";
import { useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import Friends from "./Friends";
import { FRIEND_TYPE } from "config/enums";
import { checkIsAuth } from "utils/common";
import { getUserProfile } from "redux/reducers/users/users.selector";

const View = (props: any) => {
  const { dataUser, isMyProfile } = props;
  const { t } = useTranslation();
  const profile = useAppSelector(getUserProfile);

  const isAuth = checkIsAuth(dataUser, profile?._id);

  const menus = useAppSelector(getMenusByKey("tab_profile_friend"));
  const [tab, setTab] = useState(null);
  const [loadMenu, setLoadMenu] = useState(false);

  useEffect(() => {
    setTab(menus[0]);
  }, [loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  return (
    <div className="v-profile-friend">
      <Card>
        <Card.Body>
          <h2>{t("Friend")}</h2>
          <div className="friend-list-tab mt-2">
            <VTab
              noRedirect={true}
              tab={tab}
              setTab={setTab}
              menus={menus?.filter((el) =>
                isAuth ? el.key !== "mutual_friends" : el
              )}
              activeBorder="bottom"
              noBackground={true}
            />
            <Tab.Content>
              {tab && tab.key === "all_friends" && (
                <Friends
                  dataUser={dataUser}
                  type={FRIEND_TYPE.FRIEND}
                  isMyProfile={isMyProfile}
                  col={"col-md-2"}
                />
              )}
              {tab && tab.key === "following" && (
                <Friends
                  dataUser={dataUser}
                  type={FRIEND_TYPE.FOLLOW}
                  isMyProfile={isMyProfile}
                  col={"col-md-2"}
                />
              )}
              {tab && tab.key === "mutual_friends" && (
                <Friends
                  dataUser={dataUser}
                  type={FRIEND_TYPE.MUTUAL_FRIENDS}
                  isMyProfile={isMyProfile}
                  col={"col-md-2"}
                />
              )}
            </Tab.Content>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default React.memo(View);

import { Autocomplete, Libraries, LoadScript } from "@react-google-maps/api";
import { Card, Dropdown, Form } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { ALOW_COUNTRY_LIST, libs } from "config/constant";
import { getUrlImage } from "utils/common";
import CustomToggle from "components/v-teamplate/dropdowns";
import useQuery from "hooks/useQuery";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";

const SearchLocation = (props: any) => {
  const { t } = useTranslation();
  const query = useQuery();
  const [selectedPlace, setSelectedPlace] = useState(null);
  const [textSearch, setTextSearchThis] = useState(query?.search);
  const {
    googleKey,
    selectLocation,
    handleKeyDown,
    isSearchLocation,
    topTrending,
    showDropdown,
    setDropdown,
    setShowDropdown,
    selectedSearchOption,
    setTextSearch,
  } = props;

  const handlePlaceChanged = () => {
    const place = selectedPlace.getPlace();
    const location = {
      lat: place.geometry.location.lat(),
      lng: place.geometry.location.lng(),
    };
    selectLocation(location);
  };

  useEffect(() => {
    if (query.search && query.search?.length > 1) {
      setTextSearchThis(query.search);
    } else {
      // setTextSearch("");
      // toastMinLength();
    }
  }, [query.search]);

  const toastMinLength = () => {
    toast.error(t("Please enter more than 2 character to search!"));
  };

  const redirectToSearch = (key) => {
    if (key && key?.includes("#")) {
      return `/search/hashtag?search=${key?.substr(1)}`;
    } else {
      return `${selectedSearchOption?.url}?search=${key}`;
    }
  };

  const handleChange = (value) => {
    setTextSearch(value);
    setTextSearchThis(value);
  };

  const lib: Libraries = ["places"];

  return (
    googleKey && (
      <div className="w-100">
        <LoadScript googleMapsApiKey={googleKey} libraries={libs || lib}>
          {isSearchLocation ? (
            <Autocomplete
              onLoad={(autocomplete) => setSelectedPlace(autocomplete)}
              onPlaceChanged={handlePlaceChanged}
              options={{
                componentRestrictions: { country: ALOW_COUNTRY_LIST },
              }}
            >
              <Form.Control
                type="text"
                className={`text search-text bg-transparent ${
                  isMobile ? "d-lg-none w-100" : "d-none d-lg-block"
                } `}
                placeholder={`${t("Search here")}...`}
                onChange={(e) => handleChange(e.target.value)}
                onKeyDown={handleKeyDown}
                //   value={textSearch}
              />
            </Autocomplete>
          ) : (
            <Dropdown
              show={showDropdown}
              as="li"
              className="w-100"
              // style={{
              //   maxWidth: "24rem",
              //   width: `${isMobile ? "16rem" : "24rem"} `,
              //   minWidth: "13rem",
              // }}
            >
              <Dropdown.Toggle
                href="#"
                as={CustomToggle}
                variant=""
                onClick={setDropdown}
              >
                <Form.Control
                  type="text"
                  className={`text search-text bg-transparent ${
                    isMobile ? "d-lg-none w-100" : "d-none d-lg-block"
                  } `}
                  placeholder={`${t("Search here")}...`}
                  onChange={(e) => handleChange(e.target.value)}
                  onKeyDown={handleKeyDown}
                  value={textSearch}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu className="trending-menu">
                <Card className="shadow-none m-0">
                  <Card.Body className="p-0 trending">
                    {topTrending &&
                      topTrending?.map((e) => {
                        return (
                          <>
                            <Dropdown.Item
                              onClick={() => {
                                if (textSearch && textSearch?.length < 2) {
                                  toastMinLength();
                                  return;
                                } else {
                                  setShowDropdown(false);
                                }
                              }}
                            >
                              {" "}
                              <Link
                                to={redirectToSearch(e?._id)}
                                className="mb-0 h6"
                              >
                                <div className="d-flex align-items-center iq-sub-card border-0">
                                  <div className="avatar-img-pluid mb-1">
                                    <img
                                      width={36}
                                      height={36}
                                      src={
                                        (e?.icon && getUrlImage(e?.icon, 50)) ||
                                        "/assets/icons/default/search/trend.svg"
                                      }
                                      alt=""
                                    />
                                  </div>
                                  {"  "}
                                  <div className="trending-value cursor-pointer">
                                    <div className="fw-bold key">
                                      {e?.key || e?._id}
                                    </div>
                                    <div className="number">
                                      {e?.count} {"search"}
                                    </div>
                                  </div>
                                </div>
                              </Link>
                            </Dropdown.Item>
                          </>
                        );
                      })}
                  </Card.Body>
                </Card>
              </Dropdown.Menu>
            </Dropdown>
          )}
        </LoadScript>
      </div>
    )
  );
};

export default SearchLocation;

import VIcon from "components/v-icon";
import { DIRECTORY_STATUS } from "config/enums";
import { getUrlImage } from "utils/common";
import { useHistory } from "react-router-dom";

const View = ({ isAuth, postDirectory }) => {
  const history = useHistory();

  const handlFilterByDirectory = (id: string) => {
    history.push(`?tab=timeline&post_directory=${id}`);
  };

  const handleAction = () => {
    history.push("?setting=post-directory");
  };

  if (
    !postDirectory?.infoDirectory?.display_type ||
    postDirectory?.infoDirectory?.display_type === "vertical"
  ) {
    return <></>;
  }

  return (
    <div className="d-flex overflow-auto mb-2 rounded align-items-center position-relative">
      {postDirectory?.value
        ?.filter((directory) =>
          isAuth ? {} : directory.status !== DIRECTORY_STATUS.PRIVATE
        )
        ?.map((el) => (
          <div
            key={el?.id}
            className="tab-menus-post-directory"
            onClick={() => handlFilterByDirectory(el?.id)}
          >
            {el.icon ? (
              <div className="icon-post-directory">
                <img src={getUrlImage(el.icon, 50)} alt="" />
              </div>
            ) : (
              <VIcon name={"list-folder.svg"} width={16} className="me-2" />
            )}
            {el.status === DIRECTORY_STATUS.PRIVATE && (
              <VIcon name={"lock.svg"} width={16} className="me-2" />
            )}
            <span className="text-nowrap">{el.name}</span>
          </div>
        ))}
      {isAuth && (
        <div
          className="cursor-pointer position-sticky py-1 px-2 rounded end-0"
          style={{
            backgroundColor: "#e9ecef"
          }}
          onClick={handleAction}
        >
          <VIcon name="edit-page.svg" width={16}/>
        </div>
      )}
    </div>
  );
};

export default View;

/* eslint-disable max-len */

/* eslint-disable no-useless-escape */
export const convertIdVideo = (link: any) => {
  const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/
  const match = link?.match(regExp)
  if (match && match[2].length === 11) {
    return match[2]
  } else {
    //
  }
}

export const checkValidUrlFB = (url: string) => {
  var pattern = /^(https?:\/\/)?((w{3}\.)?)facebook.com\/.*/i;
  
  if(pattern.test(url)) {
    return true
  }

  if(url && url.indexOf('fb.watch')) {
    return true
  }

  else {
    return false
  }
}

export const getLinks = (content: string) => {
  try {
    var regex =
      /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/gi;
    const matches = content?.match(regex);

    if (matches) {
      return matches;
    }
    return [];
  } catch {
    return [];
  }
};

export const getLinksYoutube = (content: string) => {
  try {
    const regexYoutube =
      /(?:https?:\/\/|www\.|m\.|^)youtu(?:be\.com\/watch\?(?:.*?&(?:amp;)?)?v=|\.be\/)([\w‌​\-]+)(?:&(?:amp;)?[\w\?=]*)?/;

    const matches = content?.match(regexYoutube);
    if (matches && matches[0]) {
      const results: string[] = [];
      results.push(matches[0]);

      const wholeLink: string | undefined = content
        .split(" ")
        .find((element) => element.indexOf(results[0]) > -1);

      if (wholeLink) {
        results.push(wholeLink);
      }
      return results;
    }

    return [];
  } catch {
    return [];
  }
};

export const getLinksFaceBook = (content: string) => {
  try {
    const regexYoutube =
      /(?:https?:\/\/)?(?:www.|web.|m.)?(facebook|fb).(com|watch)\/(?:video.php\?v=\d+|(\S+)|photo.php\?v=\d+|\?v=\d+)|\S+\/videos\/((\S+)\/(\d+)|(\d+))\/?/;
    const matches = content?.match(regexYoutube);
    if (matches && (matches[0].includes("videos") || matches[0].includes("watch"))) {
      return matches;
    }
    return [];
  } catch {
    return [];
  }
};


export const getIDFromProfileLink = (url: string) => {
  try { 
    console.log(url, 'url');
    
    const res = url.match(new RegExp(`${window.location.origin}/.+/(.+)`))
    const ID = res[1];
    return ID || "";
  } catch (error) {
    return ""
  }
}
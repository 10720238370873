/* eslint-disable react-hooks/exhaustive-deps */
import { WEB_MODULE_URL } from "config/env";
import useQuery from "hooks/useQuery";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import { Col, Container } from "react-bootstrap";
import VPageBackgrounds from "components/v-page-background";
import { isMobile } from "react-device-detect";
import VBannerPage from "components/v-teamplate/v-banner-page";
import Card from "components/v-teamplate/Card";
import { getIsApp } from "redux/reducers/settings/settings.selector";

const ShopByUserId = () => {
  const { userId } = useParams();
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const { i18n } = useTranslation();
  const query = useQuery();
  const dispatch = useAppDispatch();
  const isApp = useAppSelector(getIsApp)
  const pathname = window.location.pathname;

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
  }, []);

  return (
    <div className={`product-wrapper ${pathname.includes('shop-list') ? 'list': 'normal'}`}>
      <VPageBackgrounds page={"page_bg_shop_user"} />
      <Container className="product-container">
        <Col sm={12} className="d-flex">
          <Col sm={12} className={isMobile ? "w-full" : ""}>
            {<VBannerPage position="banner_top_shop" />}
            <Col sm={12} className={"mt-2 "}>
              <Card className="shop-view" isOpacity={true}>
                <Col sm={12}>
                  <div
                    style={{
                      width: "100%",
                      overflow: "hidden",
                    }}
                  >
                    {userId && (
                      <iframe
                        className={`${
                          isApp ? "iframe-is-app" : ""
                        } iframe-product-list`}
                        onLoad={() => {}}
                        src={`${WEB_MODULE_URL}/${pathname}/?origin=${
                          window.origin
                        }&vdb_token=${localStorage.getItem(
                          "auth"
                        )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&post_directory=${
                          query?.post_directory
                            ? `${query?.post_directory}`
                            : ""
                        }&lang=${
                          i18n.language ? `${i18n.language}` : ""
                        }&where=shop-personal`}
                        title="module_shop"
                        allow="clipboard-read; clipboard-write;fullscreen;"
                      />
                    )}
                  </div>
                </Col>
              </Card>
            </Col>
          </Col>
        </Col>
      </Container>
    </div>
  );
};

export default ShopByUserId;

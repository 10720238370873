import { useRef } from "react";
import Container, {
  Logo,
  AvataUser,
  LogoLevel,
  QRInvests,
  NameUser,
  AmountInvests,
  DateActive,
} from "./styles";

import QRCode from "react-qr-code";

import { formatCurrency, getUrlImage } from "utils/common";

import { isMobile } from "react-device-detect";

// import Modal from "components/v-modal";
import MasterModal from "components/v-modal-master";

import PreviewAndDownload from "components/view-download";
import { exportComponentAsJPEG } from "react-component-export-image";

const View = (props: any) => {
  const imgRef = useRef();
  const {
    type,
    background,
    userName,
    avataUser,
    logoProject,
    logoLevel,
    amount,
    symbol,
    createdAt,
    openModalCongratulation,
    setOpenModalCongratulation,
    levelName,
  } = props;


  const downloadImage = async () => {
    exportComponentAsJPEG(imgRef, {
      fileName: `Invests_vdiarybook_${new Date().getTime()}` || "vdiarybook",
    });
  };

  const renderModalCongratulation = () => {
    if (props) {
      return (
        <PreviewAndDownload
          srcImg={getUrlImage(background)}
          download={downloadImage}
          closeModal={setOpenModalCongratulation}
        >
          <>
            <Container ref={imgRef}>
              <img src={getUrlImage(background)} alt="bg" />
              <Logo type={type}>
                <img src={getUrlImage(logoProject)} alt="logoProject" />
              </Logo>
              <AvataUser type={type}>
                <img src={getUrlImage(avataUser)} alt="avataUser" />
              </AvataUser>
              <LogoLevel type={type}>
                <img src={getUrlImage(logoLevel)} alt="logoLevel" />
              </LogoLevel>
              <NameUser type={type}>{userName}</NameUser>
              {type !== "level-up" ? (
                <AmountInvests type={type}>
                  <div className="_container_amount">
                    {formatCurrency(amount)} {symbol?.toUpperCase()}
                  </div>
                </AmountInvests>
              ) : (
                <AmountInvests type={type}>
                  <div className="_container_amount">{levelName}</div>
                </AmountInvests>
              )}
              <QRInvests type={type}>
                <QRCode
                  value={"https://vdiarybook.com"}
                  size={isMobile ? 46 : 94}
                />
              </QRInvests>
              <DateActive>
                {new Date(createdAt).toLocaleString("en-GB")}
              </DateActive>
            </Container>
          </>
        </PreviewAndDownload>
      );
    }
  };

  return (
    <>
      <MasterModal
        isOpen={openModalCongratulation}
        fullscreen={true}
        showImage={true}
        children={renderModalCongratulation()}
      />
    </>
  );
};

export default View;

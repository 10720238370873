/* eslint-disable indent */
// import ProfileIntroduce from "./introduce";
import FolderPosts from "./folder-posts";
import Images from "./images";
import Friends from "./friends";
import { OBJECT_TYPE } from "config/enums";
import MapUser from "./map-user";
import PlatformsSocialNetwork from "./platforms-social-network";
import { useState } from "react";
import { isMobile } from "react-device-detect";
import Card from "components/v-teamplate/Card";
import VIcon from "components/v-icon";
import useTranslation from "hooks/useTranslation";
import PairingLeftProfile from "./pairing";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  dataFolder: any;
};

const View = (props: IProps) => {
  const { t } = useTranslation();
  const { isAuth, dataUser, dataFolder } = props;
  const [showInfo, setShowInfo] = useState(false);

  return (
    <div className="profile-left-sidebar">
      {/* <ProfileIntroduce isAuth={isAuth} dataUser={dataUser} /> */}
      <FolderPosts
        isAuth={isAuth}
        dataUser={dataUser}
        dataFolder={dataFolder}
      />
      {isMobile && (
        <Card>
          <div
            className="d-flex align-items-center justify-content-between p-2"
            onClick={() => setShowInfo(!showInfo)}
          >
            <div>{t("Xem thêm thông tin")}</div>
            <div
              style={{
                transform: showInfo ? `rotate(-180deg)` : "",
              }}
            >
              <VIcon name="dropdown.svg" width={16} />
            </div>
          </div>
        </Card>
      )}

      <div
        style={{
          height: !showInfo && isMobile ? 0 : "",
          overflow: !showInfo && isMobile ? "hidden" : "",
        }}
      >
        {(!dataUser ||
          (dataUser.object_type !== OBJECT_TYPE.PAGE &&
            dataUser.object_type !== OBJECT_TYPE.CHANNEL)) && (
          <Friends isAuth={isAuth} dataUser={dataUser} />
        )}
        {dataUser.object_type !== OBJECT_TYPE.CHANNEL && (
          <Images dataUser={dataUser} />
        )}
        <MapUser dataUser={dataUser} />
      </div>
      <PlatformsSocialNetwork dataUser={dataUser} />
      <PairingLeftProfile dataUser={dataUser} />
    </div>
  );
};

export default View;

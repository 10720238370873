import { request, parseErrorResponse } from "./request";
import queryString from "query-string";

interface IRoomDataPost {
  owner: string;
  people: string[];
  type: "chat_group" | "chat_friend";
  createdById: string;
}

export interface IRoomDataPut {
  owner?: string;
  people?: string[];
  type?: "chat_group" | "chat_friend";
  createdById?: string;
  updatedById?: string;
  lastUpdate?: Date;
  lastAuthor?: string;
  lastMessage?: string;
  unread?: {
    user?: string;
    count?: number;
    draft_message?: {
      content?: string;
      createdAt?: Date;
    };
  }[];
}

const getRoom = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/rooms")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRooms = (query: { page: number; limit: number }) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/rooms/list?${queryString.stringify(query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getRoomByRoomId = (roomId: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/rooms/${roomId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const search = (query: IRoomDataPut) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/rooms/search?${queryString.stringify(query)}`)
      .then((res) => {
        const { data } = res.data;
        resolve(data);
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createRoom = (data: IRoomDataPost) =>
  new Promise((resolve, reject) => {
    request()
      .post("/rooms", data)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateRoomById = (roomId: string, data: IRoomDataPut) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/rooms/${roomId}`, data)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const RoomService = {
  getRoom,
  getRooms,
  getRoomByRoomId,
  createRoom,
  updateRoomById,
  search,
};

export default RoomService;

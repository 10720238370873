/* eslint-disable react-hooks/exhaustive-deps */
// import React, { useEffect } from "react";

// import { useAppDispatch } from "redux/reducers/hook";
// import { setConnectSocket } from "redux/reducers/users/users.reducer";
// import { Route, Switch } from "react-router-dom";
// import Profile from "./profile";
// import BuyAndSell from "./buy-and-sell";
// import Swap from "./swap";
// import Wallets from "./wallets";
// // import Referral from "./profile/referral";
// import Shop from "./shop";
// import IDO from "./ibo-project";
// import IBO from "./my-project";
// import TASK from "./task";
// import TASK_DETAIL from "./task/detail";
// import withAuth from "HOCs/withAuth";
// import UserLayout from "../../layouts/UserLayout";
// import { ROUTES } from "config/routes";
// import VzoneBank from "./vzone-bank/";
// import PetaVerse from "./petaverse";
// import { getKycAsync } from "redux/reducers/users/users.actions";
// import Invests from "./invests";
// import Reward from "./rewards";
// import Channel from "./channel";
// import Statis from "./statis";
// import QA from "./q&a";
// import Campaign from "./campaign";
// import CampaignDetail from "./campaign/Details";
// import Supports from "./supports";
// import MyEvent from "../events/my-events";
// import Dashboard from "./dashboard";
// import Rounds from "./ibo-project/Rounds";

// function MyRoute() {
//   const dispatch = useAppDispatch();

//   useEffect(() => {
//     const auth = localStorage.getItem("auth");
//     if (auth) {
//       dispatch(setConnectSocket(true));
//       dispatch(getKycAsync());
//     }
//   }, [dispatch]);

//   return (
//     <UserLayout>
//       <Switch>
//         <Route path={ROUTES.MY_PROFILE} component={Profile} />
//         <Route path={ROUTES.MY_BY_SELL} component={BuyAndSell} />
//         <Route path={ROUTES.MY_SWAP} component={Swap} />
//         <Route path={ROUTES.MY_WALLETS} component={Wallets} />
//         {/* <Route path={ROUTES.MY_REFERRALS} component={Referral} /> */}
//         <Route path={ROUTES.SALESTATISTICS} component={Statis} />
//         <Route path={ROUTES.MY_SHOP} component={Shop} />
//         <Route path={ROUTES.MY_IBO_ROUND} component={Rounds} />
//         <Route path={ROUTES.MY_IDO} component={IDO} />
//         <Route path={ROUTES.MY_IBO} component={IBO} />
//         <Route path={ROUTES.MY_TASK_DETAIL} component={TASK_DETAIL} />
//         <Route path={ROUTES.MY_TASK} component={TASK} />
//         <Route path={ROUTES.VZONE_BANK} component={VzoneBank} />
//         <Route path={ROUTES.BORROW_LEND} component={VzoneBank} />
//         <Route path={ROUTES.BLOCKCHAIN} component={VzoneBank} />
//         <Route path={ROUTES.PETAVERSE} component={PetaVerse} />
//         <Route path={ROUTES.INVEST} component={Invests} />
//         <Route path={ROUTES.REWARD} component={Reward} />
//         <Route path={ROUTES.CHANNEL} component={Channel} />
//         <Route path={ROUTES.QA} component={QA} />
//         <Route path={ROUTES.CAMPAIGNDETAIL} component={CampaignDetail} />
//         <Route path={ROUTES.CAMPAIGN} component={Campaign} />
//         <Route path={ROUTES.SUPPORTS} component={Supports} />
//         <Route path={ROUTES.MY_EVENT} component={MyEvent} />
//         <Route path={ROUTES.DASHBOARD} component={Dashboard} />
//       </Switch>
//     </UserLayout>
//   );
// }

// export default withAuth(MyRoute);

import React, { useEffect, useState } from "react";

// //header
import Header from "layouts/dashboard/HeaderStyle/header";

// //sidebar
import RightSidebar from "layouts/dashboard/SidebarStyle/rightsidebar";

// //sidebar
import Sidebar from "layouts/dashboard/SidebarStyle/sidebar";

// //footer
import Footer from "layouts/dashboard/FooterStyle/footer";

// //default
import MyRouter from "router/my-router";

// // share-offcanvas
// // import ShareOffcanvas from '../../components/share-offcanvas'

// //settingoffCanvas
// import SettingOffCanvas from "components/setting/SettingOffCanvas";
import withAuth from "HOCs/withAuth";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { setConnectSocket } from "redux/reducers/users/users.reducer";
import {
  getAccountAddressAndCareerAsync,
  getBalancesAsync,
  getDataAccessByOwnerAsync,
  getUserOnlineAsync,
} from "redux/reducers/users/users.actions";
import {
  getIsLoggedIn,
  getUserProfile,
  getDataUser,
} from "redux/reducers/users/users.selector";
// import Contribute from "components/setting/Contribute";
import Maintenance from "pages/maintenance/maintenance";
import { OBJECT_TYPE } from "config/enums";
import { getMenusAsync } from "redux/reducers/settings/settings.action";
import { isMobile } from "react-device-detect";
import BannerApp from "layouts/Banner";
import { useLocation } from "react-router-dom";
import {
  getGeneral,
  getIsApp,
  getIsPreview,
} from "redux/reducers/settings/settings.selector";

const View = () => {
  const dispatch = useAppDispatch();
  const isLogined = useAppSelector(getIsLoggedIn);
  const profile = useAppSelector(getUserProfile);
  const dataUser = useAppSelector(getDataUser);
  const location = useLocation();
  const general = useAppSelector(getGeneral);

  const isConversation =
    String(location?.pathname)?.includes("profile-chat") ||
    String(location?.search)?.includes("?isChat") ||
    dataUser.object_type === "CHAT";
  const [maintenance, setMaintenance] = useState(false);

  const isApp = useAppSelector(getIsApp);
  const isPreview = useAppSelector(getIsPreview);

  const getMetaSetting = async () => {
    try {
      if (general) {
        if (profile.object_type !== OBJECT_TYPE.COMMUNITY) {
          setMaintenance(general?.maintenance || false);
        } else {
          setMaintenance(false);
        }
      }
    } catch (error) {}
  };

  useEffect(() => {
    getMetaSetting();
  }, [general, isLogined]);

  useEffect(() => {
    if (isLogined && profile && profile._id) {
      dispatch(getMenusAsync());
      dispatch(setConnectSocket(true));
      dispatch(getAccountAddressAndCareerAsync());
      dispatch(getDataAccessByOwnerAsync());
      dispatch(getUserOnlineAsync());
      dispatch(
        getBalancesAsync({
          params: {
            id: profile._id,
          },
        })
      );
    }
  }, [isLogined]);

  return (
    <>
      {!isApp && !isConversation && !isPreview && <Sidebar />}
      {!isApp && !isConversation && !isPreview && <Header />}
      <div className="main-content">
        <div id="content-page" className="content-page">
          {maintenance ? <Maintenance /> : <MyRouter />}
        </div>
      </div>
      {!isApp && !isPreview && <RightSidebar />}
      {!isApp && !isConversation && <Footer />}
      {isMobile && <BannerApp />}
      {/* <SettingOffCanvas /> */}
      {/* <Contribute /> */}
    </>
  );
};

export default withAuth(View);

import { request, parseErrorResponse, IRequestData } from "./request";
import qs from "query-string";

const API_URL_PRO =
  process.env.REACT_APP_API_URL_PRO || "http://localhost:3001/api";

// const API_URL_PRO = "http://localhost:3001/api";

const API_URL_AI = "https://ai.vdiarybook.com/api";

const getSettings = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`${API_URL_PRO}/settings`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getSettingByName = (name: string) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/settings/${name}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getLocations = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/locations/getAll?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getLocationByIds = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/locations/getByIds/?${qs.stringify(req.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMenusByPosition = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .get(
        `${API_URL_PRO}/menus/position/${req.params.position}?${qs.stringify(
          req.query
        )}`
      )
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getMenus = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`${API_URL_PRO}/menus?client=web`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getAllMenus = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`${API_URL_PRO}/menus/all?client=web`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCareers = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/careers`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getFamilies = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/families`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCategoryFields = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/category-field`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getNations = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/nations`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getReligions = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/religions`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getHobbies = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/hobbies`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getInfoDocumentBase64 = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post(`${API_URL_AI}/kycs/info`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getObjectFiles = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/settings/objects`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getDefaultSettingsObject = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`/default`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const SettingService = {
  getSettingByName,
  getLocations,
  getSettings,
  getMenus,
  getLocationByIds,
  getAllMenus,
  getCareers,
  getFamilies,
  getCategoryFields,
  getNations,
  getReligions,
  getHobbies,
  getInfoDocumentBase64,
  getObjectFiles,
  getMenusByPosition,
  getDefaultSettingsObject
};

export default SettingService;

import React, { createContext, useState } from 'react'

const NewsFeedContext = createContext<any>(0);

export const NewsFeedProvider = ({ children }) => {
  const [timeReloaded, setTimeReloaded] = useState<any>(null);
  
  return (
    <NewsFeedContext.Provider value={{ timeReloaded, setTimeReloaded }}>
      {children}
    </NewsFeedContext.Provider>
  )
}

export default NewsFeedContext;
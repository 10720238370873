/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import Card from "components/v-teamplate/Card";
import VisitOrther from "./components/visit-orther";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { useAppSelector } from "redux/reducers/hook";
import {
  getNations,
  getReligions,
  getCareers,
} from "redux/reducers/settings/settings.selector";
import { useEffect, useRef, useState } from "react";
import useQuery from "hooks/useQuery";
import VTab from "components/v-tab";
import useTranslation from "hooks/useTranslation";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";

const View = (props) => {
  const userProfile: any = useAppSelector(getUserProfile);
  const nations = useAppSelector(getNations);
  const religions = useAppSelector(getReligions);
  const careers = useAppSelector(getCareers);
  const menus = useAppSelector(getMenusByKey("visit_card"));

  const dataUser = props.dataUser || userProfile
  
  const [loadMenu, setLoadMenu] = useState(false);

  const query = useQuery();
  const { t } = useTranslation();

  const refFrontVisit1 = useRef(null);
  const refBackVisit1 = useRef(null);
  const refFrontVisit2 = useRef(null);
  const refBackVisit2 = useRef(null);
  const refFrontVisit3 = useRef(null);
  const refBackVisit3 = useRef(null);
  const refFrontVisit4 = useRef(null);
  const refBackVisit4 = useRef(null);

  const { profile, nation, religion, career } =
    dataUser?.profile?.visit_card || {};

  const [tab, setTab] = useState(null);

  const getBackgroundCardVisit = (type) => {
    let data;
    switch (type) {
      case "nation":
        data = nations?.find((el) => el._id === dataUser?.profile?.nation_id);
        break;
      case "religion":
        data = religions?.find(
          (el) => el._id === dataUser?.profile?.religion_id
        );
        break;
      case "career":
        data = careers?.find((el) => el._id === dataUser?.profile?.career_id);
        break;
      default:
        break;
    }
    return data?.background_card_visit;
  };

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  const renderTab = () => {
    if (tab) {
      if (tab.key === menus[0].key)
        return (
          <div>
            <VisitOrther
              refFront={refFrontVisit1}
              refBack={refBackVisit1}
              type={"profile"}
              data={profile}
              dataUser={dataUser}
              isSetting={true}
            />
          </div>
        );
      if (tab.key === menus[1].key && dataUser?.profile?.nation)
        return (
          <div>
            <VisitOrther
              refFront={refFrontVisit2}
              refBack={refBackVisit2}
              type={"nation"}
              data={nation}
              isSetting={true}
              background={getBackgroundCardVisit("nation")}
            />
          </div>
        );
      if (tab.key === menus[2].key && dataUser?.profile?.religion)
        return (
          <div>
            <VisitOrther
              refFront={refFrontVisit3}
              refBack={refBackVisit3}
              type={"religion"}
              data={religion}
              isSetting={true}
              background={getBackgroundCardVisit("religion")}
            />
          </div>
        );
      if (tab.key === menus[3].key && dataUser?.profile?.career)
        return (
          <div>
            <VisitOrther
              refFront={refFrontVisit4}
              refBack={refBackVisit4}
              type={"career"}
              data={career}
              isSetting={true}
              background={getBackgroundCardVisit("career")}
            />
          </div>
        );
    }
    return (
      <div className="p-5 text-center">
        {t("Please install in profile setting")}
      </div>
    );
  };
  return (
    <>
      <VTab tab={tab} setTab={setTab} menus={menus} />
      <Card isOpacity={true}>{renderTab()}</Card>
    </>
  );
};

export default View;

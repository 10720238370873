/* eslint-disable indent */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
import VIcon from "components/v-icon";
import { Nav, Tab } from "react-bootstrap";
import useTranslation from "hooks/useTranslation";
import { useHistory } from "react-router-dom";
import { convertUrl } from "utils/common";
import { useAppSelector } from "redux/reducers/hook";
import * as SettingSelector from "redux/reducers/setting-system/selectors";
import Card from "components/v-teamplate/Card";
import { MENU_TYPE_VIEW } from "config/enums";
import TabSelect from "./components/tab-select";
type IProps = {
  tab: any;
  menus: any;
  hide?: boolean;
  showName?: boolean;
  activeBorder?: "top" | "bottom";
  noRedirect?: boolean;
  noBackground?: boolean;
  showKey?: boolean;
  isSelect?: boolean;
  urlDefautl?: string;
  menuTabCustomSetting?: any;
  setTab?: (e) => any;
};

const View = (props: IProps) => {
  const { t } = useTranslation();
  const {
    menus,
    tab,
    hide,
    noBackground,
    isSelect,
    menuTabCustomSetting,
    setTab,
  } = props;
  const activeBorder = props.activeBorder || "bottom";
  const noRedirect = props.noRedirect;

  const history = useHistory();
  const themeScheme = useAppSelector(SettingSelector.theme_scheme);

  const handleClick = (menu) => {
    if (menu.type_view === "link") {
      window.open(convertUrl(menu.url || props?.urlDefautl), "_blank");
    }
    !noRedirect && !menu?.isWebsite && history.push(`?tab=${menu.key}`);
    setTab(menu);
  };

  const handleWidth = () => {
    if (menus.length <= 4) {
      return `col-${12 / menus.length}`;
    }
    if (menus.length > 4) {
      return "w-fit-content";
    }
  };

  if (hide) {
    return <></>;
  }

  return (
    (menus || [])?.length > 0 && (
      <Tab.Container id="v-tabs">
        <Card
          className={`p-0 ${noBackground ? "shadow-none bg-transparent" : ""}`}
          isOpacity={true}
        >
          <Card.Body className="p-0">
            <div className={`v-tabs`}>
              <Nav
                as="ul"
                variant="pills"
                className={`rounded profile-feed-items ${
                  noBackground ? "tabs-no-bg" : ""
                }`}
              >
                {(menus || [])
                  ?.filter((el) => !el.parent_id || isSelect)
                  .map((menu, k) =>
                    menu.type_view === MENU_TYPE_VIEW.SELECT ? (
                      <TabSelect
                        menus={[
                          ...(menus || []),
                          ...(menuTabCustomSetting?.key === menu.key &&
                          menuTabCustomSetting?.data?.length > 0
                            ? menuTabCustomSetting?.data?.map((me, index) => ({
                                key: me?.name_website || me?._id,
                                name:
                                  me?.name_website ||
                                  `${t(menuTabCustomSetting?.key)} ${
                                    index + 1
                                  }`,
                                parent_id: menu._id,
                                type: "tab",
                                type_view:
                                  menuTabCustomSetting?.type_view || "webview",
                                noLocale: me?.name_website !== "Website",
                                url: me?.value,
                                isWebsite: true,
                              }))
                            : []),
                        ]}
                        item={menu}
                        handleClick={(_) => handleClick(_)}
                      />
                    ) : (
                      <Nav.Item
                        as="li"
                        key={k}
                        className={`${handleWidth()} p-0 flex-fill ${
                          tab?.key === menu.key && activeBorder
                        }`}
                        onClick={(_) => handleClick(menu)}
                      >
                        <Nav.Link
                          href={menu.link}
                          role="button"
                          className={`text-center p-2 ${themeScheme} ${
                            tab?.key === menu.key && "active"
                          }`}
                        >
                          <>{menu.icon && <VIcon name={menu.icon} />}</>
                          <div className="text-nowrap">{t(menu.name)}</div>
                        </Nav.Link>
                      </Nav.Item>
                    )
                  )}
              </Nav>
            </div>
          </Card.Body>
        </Card>
      </Tab.Container>
    )
  );
};

export default View;

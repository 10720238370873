import { Card } from "react-bootstrap";
import VTextarea from "components/v-textarea";
import { getUrlImage } from "utils/common";

type IProps = {
  id: string;
  label?: string;
  name?: string;
  type?: string;
  placeholder?: string;
  error?: string;
  value?: any;
  required?: boolean;
  field?: any;
  image?: string;
};

const View = ({
  id = "",
  label = "",
  name = "",
  value = "",
  placeholder = "",
  error = "",
  required = false,
  field,
  image,
}: IProps) => {
  const { onChange, value: fieldValue } = field;
  return (
    <Card border="success" className="mb-4 question_item_card" id={id}>
      {label && (
        <p className="text-sm mb-2">
          <b>{label}</b> {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div>
        {image && (
          <img
            style={{
              height: "200px",
              width: "auto",
              marginBottom: "10px",
              borderRadius: "8px",
            }}
            src={getUrlImage(image, 1000)}
            alt="sub"
          />
        )}
      </div>
      <VTextarea
        name={name}
        defaultValue={fieldValue ?? value}
        placeholder={placeholder}
        onChange={onChange}
        {...field}
      />
      {error && <p className="_error mb-0">{error}</p>}
    </Card>
  );
};

export default View;

import useTranslation from "hooks/useTranslation";
import { getNameWeekdays } from "utils/times";

const WorkTimePreview = ({ work_time }) => {
  const { t } = useTranslation();

  return (
    <>
      {work_time?.value?.length > 0 &&
        work_time?.value?.map((el) => (
          <div key={el?.id} className="mb-2 border-bottom">
            <b className="me-2">{el?.name}</b>
            <div>{`${getNameWeekdays(el?.from_day)}${
              getNameWeekdays(el?.to_day) && ` - ${getNameWeekdays(el?.to_day)}`
            }`}</div>
            <div className="ms-2 fw-bold">
              {el?.from}
              {el?.to && ` - ${el?.to}`}
            </div>
            <div>
              <label className="me-2">{t("Break time")}:</label>
              {el?.break_time?.from}
              {el?.break_time?.to && ` - ${el?.to}`}
            </div>
            <div>{t("Status")}: {el?.status}</div>
          </div>
        ))}
    </>
  );
};

export default WorkTimePreview;

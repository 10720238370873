import { request, parseErrorResponse } from "./request";

const API_URL_PRO = "https://api.vdiarybook.net/api";

const getAll = () =>
  new Promise((resolve, reject) => {
    request()
      .get(`${API_URL_PRO}/locales`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const create = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`${API_URL_PRO}/locales`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const LocaleSevice = {
  getAll,
  create,
};

export default LocaleSevice;

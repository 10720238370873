import { request, parseErrorResponse } from "./request";
import qs from "query-string";

const getUserSettings = (req?: any) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/user-settings?${qs.stringify(req?.query)}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateUserSettings = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/user-settings?${qs.stringify(req?.query)}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const updateUserBusiness = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/user-settings/business`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const UserSettingsService = {
  getUserSettings,
  updateUserSettings,
  updateUserBusiness,
};

export default UserSettingsService;

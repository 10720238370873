/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Container, Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { Spinner } from "react-bootstrap";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { getUISettingState } from "redux/reducers/ui-settings/ui-settings.selector";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import VBannerPage from "components/v-teamplate/v-banner-page";
import PageBackgrounds from "components/v-page-background";
// import VRightView from "components/v-right-view";
import { WEB_MODULE_URL } from "config/env";

const PostAds = ({ className = "", type = "" }: any) => {
  const [loadingNewsFeed, setLoadingNewsFeed] = useState(true);
  const [moduleSessionId, setWebModuleUid] = useState(null);
  const [fromSessionUid, setFromSessionUid] = useState(null);
  const { reloadNewsFeedNumber } = useAppSelector(getUISettingState);
  const dispatch = useDispatch();

  const { i18n } = useTranslation();
  useEffect(() => {
    if (reloadNewsFeedNumber) {
      setLoadingNewsFeed(true);
    }
  }, [reloadNewsFeedNumber]);

  useEffect(() => {
    dispatch({
      type: "vdb_socket/connect_socket",
      payload: {
        cb: (STATE) => {
          setWebModuleUid(uuidv4());
          setFromSessionUid(STATE.sessionUid);
        },
      },
    });

    return () => {
      dispatch({
        type: "vdb_socket/disconnect_socket",
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="profile-wrapper">
      <PageBackgrounds page={"banner_top_ads"} />
      <Container>
        <Row>
          <Col lg={12} className={"px-1"}>
            <VBannerPage position="banner_top_ads" />
            <Card className="p-3">
              <Col sm={12}>
                <div className="post-new-wrapper" key={reloadNewsFeedNumber}>
                  {loadingNewsFeed && (
                    <div className="d-flex justify-content-center p-3">
                      <Spinner />
                    </div>
                  )}
                  <iframe
                    className={`${className || "iframe-webview-home"}`}
                    onLoad={() => {
                      setLoadingNewsFeed(false);
                    }}
                    src={`${
                      WEB_MODULE_URL
                    }/advertisement/?origin=${
                      window.origin
                    }&vdb_token=${localStorage.getItem(
                      "auth"
                    )}&moduleSessionId=${moduleSessionId}&fromSessionUid=${fromSessionUid}&lang=${
                      i18n.language ? `${i18n.language}` : ""
                    }`}
                    title="module_posts_ads"
                  />
                </div>
              </Col>
            </Card>
          </Col>
          {/* <Col lg={4} className={"d-none d-lg-block px-1"}>
            <VRightView
              menuKey={"tab_right_ads"}
              positionBanner={"banner_right_ads"}
            />
          </Col> */}
        </Row>
      </Container>
    </div>
  );
};

export default PostAds;

/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { Row, Col } from "reactstrap";
import { toast } from "react-toastify";

import { useAppSelector } from "redux/reducers/hook";
import Button from "components/v-button";
import Input from "components/v-input";
import History from "../history-vnd";
import { formatNumber, getSymbolStock, randString } from "utils/common";

import APIs from "api";
import BankAccountForm from "./components/BankAccountForm";
import Verify from "../verify";
// import { Step0Wrapper } from "../deposit-vnd/styles";
import { getRateUSDTToVND } from "utils/currency";
import Network from "../networks";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import BankSevice from "api/banks";
import { IObject } from "types";

type IProps = {
  url?: string;
  userId: string;
  balances: IObject;
  reloadBalance?: () => {};
};

const currencies = [
  {
    name: "vnd",
    icon: "/assets/images/coins/vnd.png",
  },
  {
    name: "usdb",
    icon: "/assets/images/coins/usdt.png",
  },
];

const View = (props: IProps) => {
  const { userId, balances, reloadBalance } = props;
  const { t } = useTranslation();

  const cryptos = useAppSelector(getCryptos);

  const [isOpenBankAccountModal, setOpenBankAccountModal] = useState(false);
  const [step, setStep] = useState(0); // 1 : choose bank ,2 : amount,3 : verify
  const [selectedBank, setSelectBank] = useState(null);
  const [amount, setAmount] = useState<number>(null);
  const [hisDeposit, setHisDeposit] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [errMessage, setErrMessage] = useState<string>("");
  const [currency, setCurrency] = useState(null);
  const [address, setAddress] = useState(null);
  const [network, setNetwork] = useState("bsc");
  const [verify, setVerify] = useState({
    email_code: "",
    google_code: "",
  });
  const [rate, setRate] = useState();
  const [myBanks, setMyBanks] = useState([]);

  const stockVnex = cryptos.find(
    (el) => el.symbol === process.env.REACT_APP_STOCK_DEFAULT
  );

  useEffect(() => {
    getMyBanks();
    getPayment();
    getRate();
  }, []);

  const getMyBanks = async () => {
    try {
      const res: any = await BankSevice.getBanks({
        params: {
          userId: userId,
        },
      });
      setMyBanks(res);
    } catch (error) {}
  };

  const getPayment = async () => {
    try {
      const res: any = await APIs.PAYMENT.getPaymentByUserId({
        userId: userId,
        type: "withdraw",
        stock: process.env.REACT_APP_STOCK_DEFAULT,
      });
      if (res.payments?.length > 0) {
        setHisDeposit(res.payments);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getRate = async () => {
    const rate = await getRateUSDTToVND();
    setRate(rate);
  };

  const handleChange = (e) => {
    if (+e < 0) {
      setAmount(0);
    } else if (+e === 0) {
      setAmount(null);
    } else {
      setAmount(+e);
    }
  };

  const handleClickButtonTop = async () => {
    setErrMessage("");
    if (step !== 3) {
      if (step === 0 && currency !== "vnd") {
        setStep(step + 2);
      } else setStep(step + 1);
    } else {
      try {
        if (amount > 0) {
          setLoading(true);
          await APIs.PAYMENT.withdraw({
            body: {
              user_id: userId,
              verify,
              amount: amount,
              bank: selectedBank,
              content: randString(false, 10),
              currency,
              address,
              network,
              stock: process.env.REACT_APP_STOCK_DEFAULT,
            },
          });
          reloadBalance();
          getPayment();
          toast.success(t("Withdraw successful!"));
          setStep(0);
          setLoading(false);
        } else {
          setErrMessage("Please enter amount");
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        toast.error(t(error?.errors));
      }
    }
  };

  const addBank = () => {
    setOpenBankAccountModal(true);
  };

  const backStep = () => {
    if (step === 2 && currency !== "vnd") setStep(step - 2);
    else setStep(step - 1);
  };

  const handleCurrency = (name) => {
    setCurrency(name);
  };

  return (
    <Card isOpacity={true}>
      <div className="detais-rigth-view">
        <div className="title-action-wallets">
          <p>
            {t("Withdraw")}{" "}
            {getSymbolStock(process.env.REACT_APP_STOCK_DEFAULT)}
          </p>
        </div>
        {step === 0 && (
          <div className="step-0">
            {currencies.map((c, index) => (
              <Button
                key={index}
                onClick={() => {
                  handleCurrency(c.name);
                  setNetwork(c.name === "vnd" ? "bank" : "bsc");
                }}
                className={`btn-currency ${currency === c.name && "active"}`}
              >
                <img className="icon-currenct" src={c.icon} />
                {c.name.toUpperCase()}
              </Button>
            ))}
          </div>
        )}
        {step === 1 && (
          <div className="content-right-view">
            <div className="py-2 px-4">
              {myBanks.map((b) => {
                return (
                  <div
                    className={`bank-item ${
                      String(b._id) === String(selectedBank?._id)
                        ? "selected"
                        : ""
                    }`}
                    key={b._id}
                    onClick={setSelectBank.bind(null, b)}
                  >
                    <Row lg={12} key={b._id}>
                      <Col lg={6}>
                        <div className="details">
                          <h5>{b.bank_name}</h5>
                          <div>
                            <p>{b.account_name}</p>
                            <p>{b.account_number}</p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={6}>
                        <div className="amount">
                          <img src="/assets/images/icons/del.svg" alt="del" />
                        </div>
                      </Col>
                    </Row>
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {step === 2 && (
          <div className="content-right-view">
            {currency !== "vnd" && (
              <div className="mb-2">
                <Network network={network} setNetwork={setNetwork} />
                <Input
                  transparent={true}
                  label={t("Address")}
                  placeholder="0x9f0F7601b9143ACc36aE838800153805698e8f08"
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
            )}
            <Input
              isCurrency={true}
              transparent={true}
              label={`${t("Amount")} (${currency.toUpperCase()})*`}
              value={amount}
              hasError={errMessage ? true : false}
              errMessage={t(errMessage)}
              suffix={
                <div
                  className="cursor-pointer"
                  onClick={() =>
                    setAmount(
                      (+balances[process.env.REACT_APP_STOCK_DEFAULT] * 1000) /
                        (currency === "usdb" ? rate : 1)
                    )
                  }
                >
                  {t("MAX")}
                </div>
              }
              onChange={(e) => handleChange(e.target.value)}
            />
            {currency === "vnd" && (
              <>
                <div className="text-description-warning">
                  <img src="/assets/images/icons/warning.svg" alt="warning" />
                  <p>
                    VAT:{" "}
                    <span>
                      {formatNumber((amount * (stockVnex?.VAT || 0)) / 100)}{" "}
                      {String(currency).toUpperCase()}
                    </span>
                  </p>
                </div>
                <div className="text-description-warning">
                  <img src="/assets/images/icons/warning.svg" alt="warning" />
                  <p>
                    {t("Amount Received")}:{" "}
                    <span>
                      {formatNumber(
                        amount - (amount * (stockVnex?.VAT || 0)) / 100
                      )}{" "}
                      {String(currency).toUpperCase()}
                    </span>
                  </p>
                </div>
              </>
            )}
            <div className="text-description-warning">
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                {t("Maximum")} {String(currency).toUpperCase()}{" "}
                {t("Withdrawal")}:{" "}
                <span>
                  {formatNumber(
                    (+balances[process.env.REACT_APP_STOCK_DEFAULT] * 1000) /
                      (currency === "usdb" ? rate : 1)
                  )}{" "}
                  {String(currency).toUpperCase()}
                </span>
              </p>
            </div>
            <div className="text-description-warning">
              <img src="/assets/images/icons/warning.svg" alt="warning" />
              <p>
                {t(`${String(
                  currency
                ).toUpperCase()} deposit is free. Vzonex does
                not collect ${String(currency).toUpperCase()} deposit fee,
                however, in some instances, you may have to bear a small
                transaction fee charged directly by the processing bank or
                network.`)}
              </p>
            </div>
          </div>
        )}
        {step === 3 && <Verify verify={verify} setVerify={setVerify} />}
      </div>
      <div className="submit-step">
        {step === 1 && (
          <Button transparent={true} height="40px" onClick={() => addBank()}>
            + {t("Add new account")}
          </Button>
        )}

        <Button
          loading={loading}
          height="40px"
          onClick={handleClickButtonTop}
          disabled={!currency || (step === 1 && !selectedBank)}
        >
          {step === 3 ? "Confirm" : "Continue"}
        </Button>
        {step !== 0 && (
          <Button transparent={true} height="40px" onClick={() => backStep()}>
            {t("Back")}
          </Button>
        )}
      </div>
      {hisDeposit.length ? (
        <History
          lists={hisDeposit}
          type="withdraw"
          stock={process.env.REACT_APP_STOCK_DEFAULT}
        />
      ) : (
        <></>
      )}
      <BankAccountForm
        userId={userId}
        isOpen={isOpenBankAccountModal}
        setIsOpen={setOpenBankAccountModal}
      />
    </Card>
  );
};

export default View;

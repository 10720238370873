import { parseErrorResponse, request } from "./request";
import { IRequestData } from "./s3-request";
import qs from 'query-string'

const getByPosition = (req) => {
  return new Promise((resolve, reject) => {
    request()
      .get(`/banners/${req.params.position}`)
      .then(res => {
        const { data } = res.data
        if (data) {
          resolve(data)
        } else {
          reject(new Error('Something went error!'))
        }
      })
      .catch(parseErrorResponse)
      .then(reject)
  })
}

const getsByPosition= (req : IRequestData) => {
  return new Promise((resolve, reject) => {
    request()
      .get(`/banners/${req.params.position}?${qs.stringify(req.query)}`)
      .then(res => {
        const { data } = res.data

        if (data) {
          resolve(data)
        } else {
          reject(new Error('Something went error!'))
        }
      })
      .catch(parseErrorResponse)
      .then(reject)
  })
}

const BannersService = {
  getByPosition,
  getsByPosition
}

export default BannersService
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Card } from "react-bootstrap";
import { Link } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import CustomToggle from "components/v-teamplate/dropdowns";
import { convertTimeAgo } from "utils/times";
// import VButton from "components/v-button";
// import VIcon from "components/v-icon";
import { getRequestFriends } from "redux/reducers/friends/friends.selector";
import { getFriendRequestsAsync } from "redux/reducers/friends/friends.action";
import {
  removeFriendRequest,
  setPageRequest,
} from "redux/reducers/friends/friends.reducer";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";
import VAvatar from "components/v-avatar";
import { FRIEND_ACTION } from "config/enums";
import { getDataAccessByOwnerAsync } from "redux/reducers/users/users.actions";
import VActionFriend from "components/v-objects/v-actions-friend";
import VIcon from "components/v-icon";
import { ROUTES } from "config/routes";
import VInfiniteLoad from "components/v-infinite-load";
import { getUrlImage } from "utils/common";

const View = (props) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { menu } = props;

  const [loading, setLoading] = useState(false);
  const friends: any = useAppSelector(getRequestFriends);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = friends;

  // useEffect(() => {
  //   dispatch(getDataAccessByOwnerAsync());
  // }, []);

  useEffect(() => {
    if (page && isLoggedIn)
      getData({
        page,
      });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getFriendRequestsAsync({
        query: {
          page,
          limit,
        },
      })
    );
    setLoading(false);
  };

  const handleViewMore = () => {
    dispatch(setPageRequest({ page: page + 1 }));
  };

  const onToggle = (e) => {
    if (e) {
      getData({ page: 1 });
    }
  };

  const reload = ({ to_id }) => {
    dispatch(removeFriendRequest(to_id));
    dispatch(getDataAccessByOwnerAsync());
  };

  return (
    <>
      <Dropdown as="li" className="nav-item noti-mobile" onToggle={onToggle}>
        <Dropdown.Toggle
          href="/"
          as={CustomToggle}
          variant="d-flex align-items-center"
        >
          {menu?.img_icon ? (
            <div className={`v-icon`}>
              <img
                src={getUrlImage(menu?.img_icon, 100)}
                width={24}
                height={24}
                alt=""
              />
              <span className="v-icon-badge">{friends.totalDocs}</span>
            </div>
          ) : (
            <VIcon
              name={"friend.svg"}
              width={24}
              height={24}
              badge={friends.totalDocs}
            />
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="sub-drop sub-drop-large notification-header">
          <Card className="shadow-none m-0">
            <Card.Header>
              <div className="header-title">
                <div className="d-flex">
                  <h5 className="title">{t("Friend request")}</h5>
                  <small style={{color: "red"}} className="badge bg-light ms-2">
                    {friends.totalDocs}
                  </small>
                </div>
                <VIcon className="cursor-pointer" name="tree-dot.svg" />
              </div>
            </Card.Header>
            <Card.Body className="p-0">
              {friends?.docs?.length > 0 && (
                <div className="time-line-noti">
                  <h6>{t("Latest")}</h6>
                  <Dropdown.Item>
                    <Link to={`${ROUTES.FRIENDS}?tab=friend-request`}>
                      {t("all")}
                    </Link>
                  </Dropdown.Item>
                </div>
              )}
              <VInfiniteLoad
                canLoadMore={page + 1 <= friends.totalPages}
                isLoadingMore={loading}
                onLoadMore={handleViewMore}
              >
                {(friends.docs || []).map(
                  (friend, index) =>
                    friend && (
                      <Dropdown.Item key={index}>
                        <div className="iq-friend-request">
                          <div className="iq-sub-card iq-sub-card-big">
                            <div className="d-flex align-items-center justify-content-between">
                              <div className="d-flex align-items-center col-7 col-sm-7">
                                <VAvatar
                                  userData={{
                                    ...friend.from,
                                    profile: friend?.profile,
                                  }}
                                  additionalData={convertTimeAgo(
                                    new Date(friend.createdAt).getTime(),
                                    t
                                  )}
                                  size={"50px"}
                                  isClick={true}
                                />
                              </div>
                              <div className="action-member col-5 col-sm-5">
                                <VActionFriend
                                  type={FRIEND_ACTION.ACCEPT_FRIEND}
                                  to_id={friend.from_id}
                                  reload={reload}
                                  isShowIcon={false}
                                />{" "}
                                <VActionFriend
                                  type={FRIEND_ACTION.REFUSE}
                                  to_id={friend.from_id}
                                  reload={reload}
                                  isShowIcon={false}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </Dropdown.Item>
                    )
                )}
              </VInfiniteLoad>
              {/* {page < friends.totalPages && (
                <div className="text-center p-2">
                  <VButton
                    type="button"
                    loading={loading}
                    size={"small"}
                    onClick={handleViewMore}
                  >
                    {" "}
                    {t("View more")}
                  </VButton>
                </div>
              )} */}
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default View;

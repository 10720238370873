import { request, parseErrorResponse, IRequestData } from "./request";

const activeCode = (req: IRequestData) =>
  new Promise((resolve, reject) => {
    request()
      .post("vips/active-code", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const activeCodeForProductPost = (req: any) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/vips/active-code-post`, req)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then((err) => {
        resolve(err?.errors);
      });
  });

const VipsService = {
  activeCode,
  activeCodeForProductPost
};

export default VipsService;

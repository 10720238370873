/* eslint-disable no-empty-pattern */
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

// import {} from "./ui-settings.actions";

export interface IUISettingState {
  openWebModuleKey: string | null;
  openWebModulePaymentKey: string | null;
  reloadNewsFeedNumber: number;
  postDetail: any;
  product: any;
  userIdMiniChat: any;
  posts: {
    openPostCreatePopup: boolean;
  };
  postCreateUserId?: string;
  plugin?: string;
  isOpenGaleryImage: boolean;
  galeryImages: any[];
  audioSrc: string;
  musicMetadata: any;
  usernamePost: string;
  isConnectionError: boolean;
  postShare?: any;
  page?: string;
  pdfSrc?: string;
  isOpenPdfViewer: boolean;
  serialized: any;
  curStory: any;
  stories: any[];
  isOpenStoryPreview: boolean;
  isProfile;
  userId;
  isOpenTemplatePreview: boolean;
  showProduct: string;
  postType: string;
  openShopKey: string | null;
  pagination: { limit: number; page: number };
  payProductDetail: any;
  typeShare: string;
  contentShare: any;
  category: string;
  captcha_status: boolean;
  postId: string;
  isCloseCaptchaMidway: boolean;
  captchaId: string;
  randomCategory: any;
  randomTemplateImage: any;
  tab?: string;
  redirectUrl: string;
  idLive: string
}

const initialState: IUISettingState = {
  openWebModuleKey: null,
  openWebModulePaymentKey: null,
  postDetail: null,
  product: null,
  userIdMiniChat: "",
  reloadNewsFeedNumber: 0,
  posts: {
    openPostCreatePopup: false,
  },
  isOpenGaleryImage: false,
  galeryImages: [],
  audioSrc: "",
  musicMetadata: null,
  usernamePost: "",
  isConnectionError: false,
  postShare: [],
  page: null,
  pdfSrc: "",
  isOpenPdfViewer: false,
  serialized: [],
  isOpenTemplatePreview: false,
  curStory: null,
  stories: [],
  isProfile: false,
  userId: "",
  isOpenStoryPreview: false,
  showProduct: "",
  postType: "",
  openShopKey: "",
  pagination: { limit: 0, page: 0 },
  payProductDetail: null,
  typeShare: "",
  contentShare: null,
  category: "",
  captcha_status: false,
  postId: null,
  isCloseCaptchaMidway: false,
  captchaId: null,
  randomCategory: null,
  randomTemplateImage: null,
  redirectUrl: null,
  idLive: ""
};

export const tasksSlice = createSlice({
  name: "tasks",
  initialState,
  reducers: {
    changeProductDetail: (state, action) => {
      // window.location.href = `${process.env.REACT_APP_CLIENT_DOMAIN}/posts/${action.payload.id}`;
      state.postId = action.payload.id;
    },
    openPostCreatePopupAction: (
      state,
      action: PayloadAction<{ isOpen: boolean }>
    ) => {
      state.openWebModuleKey = action.payload.isOpen ? "post_create" : null;
    },
    setOpenWebModulesPopupAction: (
      state,
      action: PayloadAction<{
        openWebModuleKey?: string | null;
        openWebModulePaymentKey?: string | null;
        payload?: any;
        page?: string;
        typeShare?: string;
        contentShare?: any;
        captchaId?: string;
      }>
    ) => {
      state.openWebModuleKey = action.payload.openWebModuleKey;
      state.openWebModulePaymentKey = action.payload.openWebModulePaymentKey;
      state.page = action.payload.page;
      state.postType = action.payload.payload?.postType;
      state.captchaId = action.payload.captchaId;

      if (action.payload.payload?.captcha_type === "captcha") {
        state.captcha_status = action.payload.payload.captcha_status;
        state.isCloseCaptchaMidway = action.payload.payload.is_close_midway;
      }

      if (
        ["open-advanced-filter", "open-create-product-quick"].includes(
          action.payload.openWebModuleKey
        )
      ) {
        state.category = action.payload.payload?.category;
        if (action.payload.payload?.tab) {
          state.tab = action.payload.payload?.tab;
        }
      }

      if (
        action.payload.openWebModuleKey === "open-buy-product" ||
        action.payload.openWebModulePaymentKey === "open-buy-product" ||
        action.payload.openWebModuleKey === "open-donate-modal"
      ) {
        state.postDetail = action.payload.payload.post;
      }

      if (action.payload.openWebModuleKey === "open-pay-product") {
        state.payProductDetail = action.payload?.payload?.data;
      }

      if (action.payload.openWebModuleKey === "post_detail") {
        state.postDetail = action.payload.payload.post;
      }
      if (action.payload.openWebModuleKey === "post_create") {
        state.postCreateUserId = action.payload.payload?.userId;
        state.page = action.payload?.page || action?.payload?.payload?.page;
        state.plugin = action.payload.payload?.plugin;
        state.postType = action.payload.payload?.postType;
      }
      if (action.payload.openWebModuleKey === "post_share") {
        state.postShare = action.payload.payload?.post;
      }

      if (action.payload.openWebModuleKey === "mini_chat_detail") {
        state.userIdMiniChat = action.payload.payload.userIdMiniChat;
      }

      if (action.payload.openWebModuleKey === "share_drive") {
        state.postCreateUserId = action.payload.payload?.userId;
        state.page = action.payload?.page || action?.payload?.payload?.page;
        state.plugin = action.payload.payload?.plugin;
        state.postType = action.payload.payload?.postType;
        state.typeShare = action.payload.typeShare;
        state.contentShare = action.payload.contentShare;
      }
      if (action.payload.openWebModuleKey === "live") {
        state.idLive = action?.payload?.payload?.idLive
      }
    },
    setOpenWebModulesPaymentAction: (
      state,
      action: PayloadAction<{
        openWebModulePaymentKey?: string | null;
        payload?: any;
      }>
    ) => {
      state.openWebModulePaymentKey = action.payload.openWebModulePaymentKey;
      if (action.payload.openWebModulePaymentKey === "open-buy-product") {
        state.product = action.payload.payload.post;
      }
    },
    setReloadNewsFeed: (state) => {
      state.reloadNewsFeedNumber = Math.random() * 9000000;
    },
    setPostGaleryImages: (
      state,
      action: PayloadAction<{
        galeryImages: any[];
        audioSrc?: string;
        musicMetadata?: any;
        usernamePost?: string;
        isOpenGaleryImage?: boolean;
      }>
    ) => {
      state.galeryImages = action.payload.galeryImages;
      state.audioSrc = action.payload.audioSrc || "";
      state.musicMetadata = action.payload.musicMetadata;
      state.usernamePost = action.payload.usernamePost || "";
      state.isOpenGaleryImage = action.payload.isOpenGaleryImage || false;
    },
    setPostViewPdf: (
      state,
      action: PayloadAction<{
        pdfSrc?: string;
        isOpenPdfViewer?: boolean;
      }>
    ) => {
      state.pdfSrc = action.payload.pdfSrc || "";
      state.isOpenPdfViewer = action.payload.isOpenPdfViewer || false;
    },
    setPostViewTemplate: (
      state,
      action: PayloadAction<{
        serialized: any;
        isOpenTemplatePreview: boolean;
      }>
    ) => {
      state.serialized = action.payload.serialized || "";
      state.isOpenTemplatePreview =
        action.payload.isOpenTemplatePreview || false;
    },
    setRandomTemplate: (
      state,
      action: PayloadAction<{
        randomCategory: any;
        randomTemplateImage: string;
      }>
    ) => {
      state.randomCategory = action.payload.randomCategory || null;
      state.randomTemplateImage = action.payload.randomTemplateImage || null;
    },
    setStoryView: (
      state,
      action: PayloadAction<{
        curStory: any;
        stories: any[];
        isOpenStoryPreview: boolean;
        isProfile: boolean;
        userId: string;
        pagination: { limit: number; page: number };
        postType: string;
      }>
    ) => {
      state.curStory = action.payload.curStory || "";
      state.stories = action.payload.stories || [];
      state.isOpenStoryPreview = action.payload.isOpenStoryPreview || false;
      state.isProfile = action.payload.isProfile;
      state.userId = action.payload.userId;
      state.pagination = action.payload.pagination;
      state.postType = action.payload.postType;
    },
    setRedirectUrl: (
      state,
      action: PayloadAction<{
        url: string;
      }>
    ) => {
      state.redirectUrl = action.payload.url || "";
    },
    connectionError: (state, action: PayloadAction<{ isError: boolean }>) => {
      state.isConnectionError = action.payload.isError || false;
    },
    setOpenShopPopupAction: (
      state,
      action: PayloadAction<{
        openShopKey: string | null;
        payload?: any;
        page?: string;
      }>
    ) => {
      state.openShopKey = action.payload?.openShopKey;
      state.page = action.payload?.page;
      if (action.payload?.payload) {
        window.open(
          `${process.env.REACT_APP_URL_SHOP}${action.payload.payload}`,
          "_blank"
        );
      }
    },
    update: (state, action: PayloadAction<{ name: string; value: any }>) => {
      state[action.payload.name] = action.payload.value;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(getTasksAsync.pending, (state) => {})
  // },
});

export const {
  openPostCreatePopupAction,
  setOpenWebModulesPopupAction,
  setReloadNewsFeed,
  setPostGaleryImages,
  connectionError,
  setPostViewPdf,
  setRandomTemplate,
  setPostViewTemplate,
  setStoryView,
  changeProductDetail,
  setOpenShopPopupAction,
  setOpenWebModulesPaymentAction,
  update,
  setRedirectUrl,
} = tasksSlice.actions;

export default tasksSlice.reducer;

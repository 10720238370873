/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import { useEffect } from "react";
import Verification from "./components/Verification";
import { getKycAsync } from "redux/reducers/users/users.actions";
import { useAppDispatch } from "redux/reducers/hook";

const View = () => {
	const dispatch = useAppDispatch();
	
	useEffect(() => {
		dispatch(getKycAsync());
	},[])

	return <Verification />
}

export default View;
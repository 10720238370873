/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";

import BusinessService from "api/businesses";
import { BUSINESS_ACTION } from "config/enums";
import VBusinessMember from "components/v-objects/v-business-member";

type IProps = {
  isAuth: boolean;
  dataUser: any;
  reloadData: any;
  col?: string;
};

const MembersRequest = (props: IProps) => {
  const { dataUser, reloadData, col } = props;
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [members, setMembers] = useState([]);
  const [lazyParams, setLazyParams] = useState({
    limit: 20,
    page: 1,
  });
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    if (dataUser && dataUser._id) getData();
  }, [lazyParams]);

  const getData = async () => {
    setLoading(true);
    const res: any = await BusinessService.getRequestMember({
      params: {
        id: dataUser._id,
      },
      query: {
        ...lazyParams,
      },
    });

    if (res && res.docs) {
      if (res.page === 1) {
        setMembers(res.docs);
      } else {
        setMembers((prev) => prev.concat(res.docs));
      }
      setTotalPages(res.totalPages);
    }
    setLoading(false);
  };

  const handleViewMore = () => {
    setLazyParams((prev) => ({
      ...prev,
      page: prev.page + 1,
    }));
  };

  const reload = ({ to_id, type }) => {
    if (type === BUSINESS_ACTION.ACCEPT_MEMBER) {
      reloadData();
    }
    setMembers((friends) => friends.filter((f) => f.from_id !== to_id));
  };
  
  return (
    <div className="d-flex flex-wrap">
      {" "}
      {members.map((member, index) => {
        return (
          <div key={index} className={`d-flex justify-content-center p-1 col-6 ${col || "col-md-4"}`}>
            <div className="rps-container">
              <VBusinessMember
                business_id={dataUser._id}
                member={{
                  ...member.from,
                  profile: member.profile
                }}
                reload={reload}
                type={BUSINESS_ACTION.ACCEPT_MEMBER}
              />
            </div>
          </div>
        );
      })}
      <div className="col-12">
        {lazyParams.page < totalPages && (
          <div className="text-center p-2">
            <VButton
              type="button"
              loading={loading}
              size={"small"}
              onClick={handleViewMore}
            >
              {" "}
              {t("View more")}
            </VButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default MembersRequest;

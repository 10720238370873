import VIcon from "components/v-icon";
import QRCode from "react-qr-code";
import { useRef } from "react";
import { toPng } from "html-to-image";
import { toast } from "react-toastify";
import useTranslation from "hooks/useTranslation";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";
import { getUrlImage } from "utils/common";
interface IProps {
  value: any;
  size?: number;
  isDownload?: boolean;
  isCopy?: boolean;
}
const View = ({
  value = "",
  size = 100,
  isDownload = true,
  isCopy = true,
  ...props
}: IProps) => {
  const qrRef = useRef();
  const { t } = useTranslation();
  const general = useAppSelector(getGeneral);

  const handleDownloadQr = () => {
    toPng(qrRef.current, { cacheBust: true })
      .then((dataUrl) => {
        const qr = document.createElement("a");
        qr.download = `QR_vdiarybook_${new Date().getTime()}.png`;
        qr.href = dataUrl;
        qr.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const QrCodeCustom = (size, ref?: any) => {
    return (
      <div className="qr-code" ref={ref}>
        <QRCode value={value} size={size} level={"M"} fgColor={"#4284F3"} />
        <div
          className="icon_qr"
          style={{
            top: `calc(50% - ${size / 12}px)`,
            left: `calc(50% - ${size / 12}px)`,
          }}
        >
          <img
            style={{
              width: `${size / 6}px`,
              height: `${size / 6}px`,
              margin: "auto",
            }}
            src={getUrlImage(general?.site_logo, 100)}
            alt=""
          />
        </div>
      </div>
    );
  };

  return (
    <div className="d-flex">
      {QrCodeCustom(size)}
      <div>
        {isDownload && (
          <>
            <VIcon
              name="common/download.svg"
              className="container-download-qrcode mb-2"
              width={20}
              height={20}
              onClick={() => handleDownloadQr()}
            />
            <div className="qr-code-download">{QrCodeCustom(350, qrRef)}</div>
          </>
        )}
        {isCopy && (
          <VIcon
            onClick={() => {
              navigator.clipboard.writeText(value);
              toast.success(t("Đã sao chép đường dẫn chia sẻ"));
            }}
            className="container-download-qrcode"
            name="copy.svg"
          />
        )}
      </div>
    </div>
  );
};

export default View;

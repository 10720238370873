/* eslint-disable jsx-a11y/iframe-has-title */
import VWebView from "components/v-web-view";
interface IProps {
  url: string;
  widthScrollbar?: string;
  minHeight?: string;
  width?: string;
}

const WebViewTab = (props: IProps) => {
  const { url , widthScrollbar, minHeight, ...rest} = props;
  return (
    <VWebView
      url={url}
      height={minHeight}
      widthScrollbar={widthScrollbar}
      {...rest}
    />
  );
};

export default WebViewTab;

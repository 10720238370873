// import { setAdvertisement } from "redux/reducers/advertisments/advertisments.reducers";
// import { setReward } from "redux/reducers/settings/settings.reducer";
// import { getKycAsync } from "redux/reducers/users/users.actions";
// import { setBalances } from "redux/reducers/users/users.reducer";
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import io from "socket.io-client";
import { v4 as uuidv4 } from "uuid";
import APIs from "api";

import {
  // openPostCreatePopupAction,
  setOpenWebModulesPopupAction,
  setReloadNewsFeed,
  setPostGaleryImages,
  connectionError,
  setPostViewPdf,
  setPostViewTemplate,
  changeProductDetail,
  setStoryView,
  setOpenShopPopupAction,
  setOpenWebModulesPaymentAction,
  setRandomTemplate,
  setRedirectUrl,
} from "redux/reducers/ui-settings/ui-settings.reducer";
import { isVnOrigin } from "utils/common";
import { ROUTES } from "config/routes";

const socketMiddleware = (store: any) => (next: any) => {
  const STATE = {
    connectedNewsFeed: false,
    socket: null,
    sessionUid: uuidv4(),
  };

  const askDispatchActions = (data) => {
    STATE.socket.emit("ask_dispatch_actions", {
      type: data.type,
      payload: data.payload,
      toSessionUid: data.toSessionUid,
    });
  };

  const connectSocket = (payload) => {
    const token = localStorage.getItem("auth");

    if (!STATE.connectedNewsFeed) {
      STATE.connectedNewsFeed = true;
      STATE.socket = io(
        `${
          isVnOrigin()
            ? process.env.REACT_APP_MODULE_SOCKET_URL_VN
            : process.env.REACT_APP_MODULE_SOCKET_URL
        }/news_feed`,
        {
          query: {
            token: token || "not-auth",
            sessionUid: STATE.sessionUid,
          },
        }
      );

      payload?.cb(STATE);

      STATE.socket.on("dispatch_actions", (data) => {
        if (data?.type === "product/show-detail") {
          store.dispatch(
            changeProductDetail({
              id: data.payload.id,
            })
          );
        }

        if (data?.type === "posts/close-post-create-modal") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: null,
            })
          );
        }

        if (data?.type === "posts/post-create-success") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: null,
            })
          );

          store.dispatch(setReloadNewsFeed());
        }

        if (data?.type === "posts/open-post-detail") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "post_detail",
              payload: data.payload,
            })
          );
        }

        if (data?.type === "app/redirect-to-page") {
          store.dispatch(
            setRedirectUrl({
              url: data.payload.url,
            })
          );
        }

        if (data?.type === "posts/close-post-detail-modal") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: null,
            })
          );
        }

        if (data?.type === "posts/open-post-create-modal") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "post_create",
              payload: {
                ...(data?.payload?.userId && { userId: data?.payload?.userId }),
                ...(data?.payload?.plugin && { plugin: data?.payload?.plugin }),
                ...(data?.payload?.postType && {
                  postType: data?.payload?.postType,
                }),
                ...(data?.payload?.page && { page: data?.payload?.page }),
              },
            })
          );
        }

        if (data?.type === "posts/open-post-share-detail") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "post_share",
              payload: data.payload,
            })
          );
        }

        if (data?.type === "posts/close-post-share-detail-modal") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: null,
            })
          );
        }

        if (data?.type === "home-feed") {
          window.location.href = process.env.REACT_APP_CLIENT_DOMAIN;
        }

        if (data?.type === "posts/close-drive-share-modal") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: null,
            })
          );
        }

        if (data?.type === "friends/view-more-suggests") {
          window.location.href = `${process.env.REACT_APP_CLIENT_DOMAIN}/friends?tab=friend-suggest`;
        }

        if (data?.type === "chats/open-chat-detail") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "mini_chat_detail",
              payload: data.payload,
            })
          );
        }

        if (data?.type === "chats/close-chat-detail") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: null,
            })
          );
        }

        if (data?.type === "users/open-user-profile") {
          if (data?.payload?.user?._id) {
            window.location.href = data?.payload?.user?.linkProfile;
          }
        }

        if (data?.type === "users/open-user-profile-to-create-product") {
          store.dispatch(
            setOpenShopPopupAction({
              openShopKey: "open-user-profile-to-create-product",
            })
          );
        }

        if (data?.type === "posts/search-hashtags") {
          if (data?.payload?.hashtags) {
            window.location.href = `${window.location.origin}/search/hashtag?search=${data?.payload?.hashtags}`;
          }
        }

        if (data?.type === "posts/events") {
          window.location.href = `${window.location.origin}/events-v3`;
        }

        if (data.type === "posts/open-galery-images") {
          if (data?.payload?.postId) {
            if (data?.payload?.galeryImages.length > 0) {
              store.dispatch(
                setPostGaleryImages({
                  galeryImages: data?.payload?.galeryImages,
                  audioSrc: data?.payload?.audioSrc,
                  musicMetadata: data?.payload?.musicMetadata,
                  usernamePost: data?.payload?.usernamePost,
                  isOpenGaleryImage: true,
                })
              );
            }
          }
        }

        if (data.type === "posts/open-pdf-viewer") {
          if (data?.payload?.postId) {
            if (data?.payload?.pdfSrc && data?.payload?.pdfSrc?.length > 4) {
              store.dispatch(
                setPostViewPdf({
                  pdfSrc: data?.payload?.pdfSrc,
                  isOpenPdfViewer: true,
                })
              );
            }
          }
        }

        if (data?.type === "posts/give-a-sticker") {
          let sendNotification = {
            userFrom: {
              _id: data?.payload?.userFrom?._id,
              full_name: data?.payload?.userFrom?.full_name,
            },
            userTo: {
              _id: data?.payload?.userTo,
            },
            object_id: data?.payload?.object_id,
            object_name: null,
          };
          APIs.NOTIFICATION.sendNotification({
            body: {
              ...sendNotification,
            },
            params: {
              id: "GIVE_STICKER",
            },
          });
        }

        if (data.type === "posts/open-template-preview") {
          const { serialized }: any = data?.payload ?? {};
          store.dispatch(
            setPostViewTemplate({ serialized, isOpenTemplatePreview: true })
          );
        }

        if (data.type === "posts/open-random-template") {
          const { randomCategory, randomTemplateImage }: any =
            data?.payload ?? {};
          store.dispatch(
            setRandomTemplate({ randomCategory, randomTemplateImage })
          );
        }

        if (data.type === "story/preview") {
          const payload: any = data?.payload ?? {};
          store.dispatch(setStoryView(payload));
        }

        //product
        if (data?.type === "products/open-buy-product") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "open-buy-product",
              payload: data.payload,
            })
          );
        }

        if (data?.type === "products/open-buy-product-from-detail") {
          store.dispatch(
            setOpenWebModulesPaymentAction({
              openWebModulePaymentKey: "open-buy-product",
              openWebModuleKey: "post_detail",
              payload: data.payload,
            })
          );
        }

        if (data?.type === "actions/back") {
          window.location.href = data.payload?.back;
        }

        if (data?.type === "shop/open-advanced-filter") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "open-advanced-filter",
              payload: {
                ...(data?.payload?.category && {
                  category: data?.payload?.category,
                }),
                ...(data?.payload?.tab && {
                  tab: data?.payload?.tab,
                }),
              },
            })
          );
        }

        if (data?.type === "shop/pay-product") {
          store.dispatch(
            setOpenShopPopupAction({
              openShopKey: "pay-product",
              payload: data?.payload,
            })
          );
        }

        if (data?.type === "shop/pay-product-verify") {
          store.dispatch(
            setOpenShopPopupAction({
              openShopKey: "pay-product-verify",
            })
          );
        }

        if (data?.type === "shop/redirect-to-shop") {
          const category_id = data?.payload?.category_id;
          window.location.href = `${process.env.REACT_APP_CLIENT_DOMAIN}${
            ROUTES.SHOP
          }${category_id ? "?category=" + category_id : ""}`;
        }

        if (data?.type === "products/open-conversation-product-owner") {
          window.location.href = `${process.env.REACT_APP_CLIENT_DOMAIN}${ROUTES.CONVERSATIONS}/${data.payload?.user_id}`;
        }

        if (data?.type === "shop/open-create-product") {
          store.dispatch(
            setOpenShopPopupAction({
              openShopKey: "open-create-product",
            })
          );
        }

        if (data?.type === "shop/open-close-create-product") {
          store.dispatch(
            setOpenShopPopupAction({
              openShopKey: null,
            })
          );
        }

        if (data?.type === "posts/open-donate-modal") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "open-donate-modal",
              payload: data.payload,
            })
          );
        }

        if (data?.type === "products/open-pay-product") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "open-pay-product",
              payload: data.payload,
            })
          );
        }

        if (data?.type === "products/open-create-product-quick") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "open-create-product-quick",
              payload: {
                ...(data?.payload?.userId && { userId: data?.payload?.userId }),
                ...(data?.payload?.page && { page: data?.payload?.page }),
                ...(data?.payload?.category && {
                  category: data?.payload?.category,
                }),
              },
            })
          );
        }

        if (data?.type === "mini_chat_detail") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: "mini_chat_detail",
              payload: {
                userIdMiniChat: data.payload?.user_id,
              },
            })
          );
        }

        if (data?.type === "captcha/close-captcha") {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: null,
              payload: {
                captcha_status: data.payload?.captcha_status,
                captcha_type: data.payload?.captcha_type,
                is_close_midway: data.payload?.is_close_midway,
              },
            })
          );
        }

        if (
          [
            "posts/close-donate-modal",
            "products/close-buy-product",
            "shop/close-advanced-filter",
          ].includes(data?.type)
        ) {
          store.dispatch(
            setOpenWebModulesPopupAction({
              openWebModuleKey: null,
              payload: null,
            })
          );
        }
      });

      STATE.socket.on("connect", () => {
        store.dispatch(
          connectionError({
            isError: false,
          })
        );
      });

      STATE.socket.on("disconnect", () => {
        store.dispatch(
          connectionError({
            isError: true,
          })
        );
      });
    } else if (STATE.connectedNewsFeed) {
      payload?.cb(STATE);
    } else {
      payload?.cb(STATE);
    }
  };

  // const disconnectSocket = () => {
  //   if (STATE.socket) {
  //     STATE.connectedNewsFeed = false;
  //     STATE.socket.disconnect();
  //     STATE.socket = null;
  //   }
  // };

  return (action: any) => {
    switch (action.type) {
      case "vdb_socket/connect_socket": {
        connectSocket(action.payload);
        return next(action);
      }

      case "vdb_socket/disconnect_socket": {
        // disconnectSocket();
        return next(action);
      }

      case "vdb_socket/ask_dispatch_actions": {
        askDispatchActions(action.payload);
        return next(action);
      }

      default:
        return next(action);
    }
  };
};

export default socketMiddleware;

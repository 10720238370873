import Card from "components/v-teamplate/Card";
import FooterForm from "pages/auth/components/footer-form";
import LanguageAuth from "pages/auth/components/language";
import useTranslation from "hooks/useTranslation";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral, getObjects } from "redux/reducers/settings/settings.selector";
import { getUrlImage } from "utils/common";
import { useMemo } from "react";

/* eslint-disable indent */
const Step0 = (props) => {
  const { setRole, setStep } = props;
  const { t } = useTranslation();

  const objectsSetting = useAppSelector(getObjects);
  const generalSeetting = useAppSelector(getGeneral)
  const handleNextStep = (role: string) => {
    setStep(1);
    setRole(role);
  };

  const logo = useMemo(() => {
    const _logo = generalSeetting?.site?.find(
      (site) => site.key.toLowerCase() === "login_logo"
    )?.site_media;
    if (_logo) {
      return _logo
    }
    return "";
  }, [generalSeetting]);
  return (
    <Card className="form-user form-role col-md-6" isOpacity={true}>
      <div className="d-flex">
        <LanguageAuth />
      </div>
      <div className="m-auto auth-logo">
        <img src={logo ? getUrlImage(logo) : "/assets/images/logo/logo-auth.png" } alt="" />
      </div>
      <p className="fs-12 text-center">{t("welcome_register")}</p>
      <div className="d-flex justify-content-between">
        {objectsSetting?.length &&
          ([...objectsSetting] || [])
            .sort(
              (a, b) => (Number(a.position) || 0) - (Number(b.position) || 0)
            )
            .filter((item) => item.is_show)
            .map((el, index) => (
              <div
                key={index}
                className="item-register"
                onClick={() => handleNextStep(el.key)}
              >
                <div className="logo-register">
                  <img src={getUrlImage(el.logo)} alt={el.name} />
                </div>
                <span>{t(el?.key?.toLowerCase())}</span>
              </div>
            ))}
      </div>
      <FooterForm />
    </Card>
  );
};

export default Step0;

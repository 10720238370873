/* eslint-disable react-hooks/exhaustive-deps */
import SettingService from "api/settings";
import VRightView from "components/v-right-view";
import useQuery from "hooks/useQuery";
import { useEffect, useState } from "react";

const View = (props) => {
  const query = useQuery();
  const [menuKey, setMenuKey] = useState(props?.menuKey ?? "");
  const [positionBanner, setPositionBanner] = useState(props?.positionBanner ?? "");
  const [menuTabs, setMenuTabs] = useState(props?.menuTabs ?? []);
  const [userId, setUserId] = useState(props?.userId ?? "");

  useEffect(() => {
    if (query.menuKey) {
      setMenuKey(query.menuKey);
    }
    if (query.positionBanner) {
      setPositionBanner(query.positionBanner);
    }
    if (query.userId) {
      setUserId(query.userId);
    }
    if (query.userId && query.menuKey) {
      getMenuTabs(query.menuKey, query.userId);
    }
  }, [query]);

  const getMenuTabs = async (position: string, userId: string) => {
    try {
      const res: any = await SettingService.getMenusByPosition({
        params: {
          position,
        },
        query: {
          userId,
        },
      });
      if (res) setMenuTabs(res);
    } catch (error) {}
  };

  return (
    <div className={props?.className ?? "banner_container"}>
      {positionBanner && (
        <VRightView
          userId={userId}
          menuTabs={menuTabs}
          isApp={true}
          menuKey={menuKey}
          positionBanner={positionBanner}
        />
      )}
    </div>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */

import Card from "components/v-teamplate/Card";
import { useEffect, useState } from "react";
import DepartmentService from "api/department";
import VWebView from "components/v-web-view";
import { Col, Row } from "react-bootstrap";
import { getUrlImage } from "utils/common";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";
import { useHistory } from "react-router-dom";
import useQuery from "hooks/useQuery";
import MemberDepartment from "./member-department";

const View = ({ isAuth, dataUser }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const query = useQuery();

  const [data, setData] = useState([]);
  const [treeData, setTreeData] = useState([]);
  const [infoData, setInfoData] = useState({
    color: null,
  });

  const isSetting = query?.isSetting;
  const urlWebview = isAuth
    ? `${process.env.REACT_APP_FAMILY}/department?userId=${
        dataUser._id
      }&token=${localStorage.getItem("auth")}`
    : `${process.env.REACT_APP_FAMILY}/preview?userId={${dataUser._id}}`;

  useEffect(() => {
    getDepartment();
  }, []);

  useEffect(() => {
    if (query?.department) {
      const dataRender = data.filter(
        (el) => el?.parent_id === query?.department
      );
      setTreeData(dataRender);
    }
  }, [query?.department]);

  const getDepartment = async () => {
    const _res: any = await DepartmentService.getDepartmentByUserId({
      params: {
        user_id: dataUser._id,
      },
    });
    if (_res && _res.value) {
      if (_res.value.length > 0) {
        setData(_res.value);
        setTreeData(_res.value?.filter((el) => !el?.parent_id));
        // const getItems = (mapList, parentId) => {
        //   let items = mapList.filter((e: any) => e.parent_id === parentId);
        //   items.forEach((el: any) => {
        //     const items = getItems(mapList, el._id);
        //     if (items && items != null && items.length > 0) {
        //       el.children = items;
        //     }
        //   });
        //   return items;
        // };

        // let tree = _res.value?.filter(
        //   (e: any) => !e.parent_id || e?.parent_id == null
        // );

        // tree.forEach((el: any) => {
        //   const items = getItems(_res.value, el._id);
        //   if (items && items != null && items.length > 0) {
        //     el.children = items;
        //   }
        // });
        // setTreeData(tree);
      } else setTreeData([]);
      if (_res.info) setInfoData(_res.info);
    } else {
      setTreeData([]);
    }
  };

  const handleSetting = () => {
    const search = new URLSearchParams(window.location.search);

    if (isSetting) {
      search.delete("isSetting");
      history.push(`?${search}`);
    } else {
      history.push(`?${search}&isSetting=true`);
    }
  };

  const handleBack = () => {
    const search = new URLSearchParams(window.location.search);
    if (isSetting) {
      search.delete("isSetting");
    } else {
      const departmentParent = data.find((el) => el._id === query?.department);
      if (departmentParent?.parent_id) {
        search.set("department", departmentParent?.parent_id);
      } else {
        search.delete("department");
        const dataRender = data.filter((el) => !el?.parent_id);
        setTreeData(dataRender);
      }
    }
    history.push(`?${search}`);
  };

  const handleDetail = (id) => {
    const search = new URLSearchParams(window.location.search);
    search.set("department", id);
    history.push(`?${search}`);
  };

  const renderContentDepartment = (el) => {
    return (
      <>
        <div className="user-images position-relative overflow-hidden">
          <div className={`item-image ${!el.icon ? "item-no-image" : ""}`}>
            <img
              src={
                el.icon
                  ? getUrlImage(el.icon, 300)
                  : `/assets/icons/default/no-image.svg`
              }
              className="img-fluid rounded"
              alt="Responsive"
            />
          </div>
        </div>
        <div className="w-100 mt-2">
          <div
            className="fw-bold fs-5"
            style={{
              color: infoData?.color?.[el?.parent_id ? "child" : "parent"],
            }}
          >
            {el.title}
          </div>
          {/* <span>{"item"}</span> */}
        </div>
      </>
    );
  };

  return (
    <div>
      <Card>
        <div className="w-100 d-flex justify-content-between p-3">
          {(query?.department || isSetting) && (
            <VButton
              height={"36px"}
              className="btn btn-primary mx-1"
              onClick={handleBack}
            >
              {t("Back")}
            </VButton>
          )}
          {!isSetting && (
            <VButton
              height={"36px"}
              className="btn btn-primary mx-1"
              onClick={handleSetting}
            >
              {t("Settings")}
            </VButton>
          )}
        </div>
        {!isSetting && (
          <>
            <Row className="p-3">
              {treeData &&
                treeData?.map((el) => (
                  <Col
                    className={`${"col-sm-3 col-lg-2"} col-6 mb-3 d-flex align-items-center justify-content-center flex-wrap`}
                    onClick={() => handleDetail(el?._id)}
                  >
                    {renderContentDepartment(el)}
                  </Col>
                ))}
            </Row>
            <Card.Body>
              <MemberDepartment
                departmentId={query?.department}
                col={"col-md-2"}
                type={"friend"}
              />
            </Card.Body>
          </>
        )}

        {isSetting === "true" && (
          <VWebView url={urlWebview} widthScrollbar={"0px"} height="700px" />
        )}
      </Card>
    </div>
  );
};

export default View;

/* eslint-disable import/no-anonymous-default-export */
import { request, parseErrorResponse } from "./request";

const getTasks = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/tasks")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getTaskById = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get("/tasks/" + req.params.id)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const postPerform = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/tasks/" + req.params.id, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const getCampaigns = () =>
  new Promise((resolve, reject) => {
    request()
      .get("/campaigns/getByUser")
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const uploadFile = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post("/campaigns/uploadFile", req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const TaskService = {
  getTasks,
  getTaskById,
  postPerform,
  getCampaigns,
  uploadFile,
};

export default TaskService;

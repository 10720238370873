/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
import VDropdown from "components/v-dropdown";
import VInput from "components/v-input";
import { Form } from "react-bootstrap";
import VTextarea from "components/v-textarea";
// import DatePickerCustom from 'components/date-picker/View';
import useTranslation from "hooks/useTranslation";
import UploadFile from "components/uploadFile";
import VButton from "components/v-button";
// import { v4 as uuidv4 } from "uuid";
import SelectRegions from "components/v-location";
import { getUrlImage } from "utils/common";
import VEditor from "components/v-editor";
import { useEffect } from "react";

const View = (props: any) => {
  const {
    fields,
    // colItem,
    additionalData,
    listFiles,
    setListFiles,
    setFile,
    setAdditionalData,
    setIsValid,
  } = props;

  const { t } = useTranslation();

  const handleAddMedia = (label) => {
    setListFiles((prev) => ({
      ...prev,
      [`${label}`]: null,
    }));
  };

  const handleChangeFields = (e: any, field: any, type?: string) => {
    const name = field?.label;
    const isKeyImportion =
      field?.description?.enable && field?.description?.key;
    if (type === "address" || type === "editor") {
      return setAdditionalData((prev) => ({
        ...prev,
        [name]: isKeyImportion
          ? {
              key: field?.description?.key,
              value: e || {},
            }
          : e || {},
      }));
    }
    if (type === "media") {
      return setAdditionalData((prev) => ({
        ...prev,
        [name]: isKeyImportion
          ? {
              key: field?.description?.key,
              value: {
                ...prev?.[name],
                link: e.target.value,
              },
            }
          : {
              ...prev?.[name],
              link: e.target.value,
            },
      }));
    }

    setAdditionalData((prev) => ({
      ...prev,
      [name]: isKeyImportion
        ? {
            key: field?.description?.key,
            value: e?.country || e?.target?.value || "",
          }
        : e?.country || e?.target?.value || "",
    }));
  };

  const checkValidate = () => {
    const check = () => {
      const listRequied = fields?.filter((el) => el.required);

      for (let index = 0; index < listRequied?.length; index++) {
        const item = listRequied[index];
        if (!additionalData[item.label] && !listFiles[item.label]) {
          return false;
        }
      }
      return true;
    };
    setIsValid((_) => check());
  };

  useEffect(() => {
    checkValidate();
  }, [additionalData, listFiles]);

  useEffect(() => {
    checkValidate();
  }, []);
  return (
    <div className="v-form-additional row px-0 mx-0">
      {fields?.length > 0 &&
        fields
          .filter((field) => field.status !== "disable")
          .sort((prev, next) => (prev.order || 0) - (next.order || 0))
          .map((el, index) => {
            if (el.typeField === "nationality")
              return (
                <Form.Group key={index} className={`col-12`}>
                  <SelectRegions
                    styleProps={{
                      col: `col-12 ${
                        el.col ? `col-md-${Number(12 / el?.col)}` : ""
                      }`,
                    }}
                    required={el.required}
                    hideLabel={true}
                    onlyShowCountry={true}
                    isDisabled={false}
                    address={{
                      country: additionalData[el.label],
                    }}
                    setAddress={(e) => handleChangeFields(e, el)}
                  />
                </Form.Group>
              );
            if (el.typeField === "location")
              return (
                <Form.Group key={index} className="form-group col-12">
                  <Form.Label>
                    {t(el.label)}&nbsp;
                    {el.required && <span style={{ color: "#ff9b8a" }}>*</span>}
                  </Form.Label>
                  <SelectRegions
                    styleProps={{
                      col: `col-12 ${
                        el.col ? `col-md-${Number(12 / el?.col)}` : ""
                      }`,
                    }}
                    required={el.required}
                    hideLabel={true}
                    isDisabled={false}
                    hideDetail={true}
                    address={{
                      country: additionalData[el.label]?.country,
                      province: additionalData[el.label]?.province,
                      district: additionalData[el.label]?.district,
                      ward: additionalData[el.label]?.ward,
                      detail: additionalData[el.label]?.detail,
                    }}
                    setAddress={(e) => handleChangeFields(e, el, "address")}
                  />
                </Form.Group>
              );
            if (el.typeField === "input")
              return (
                <Form.Group
                  className={`form-group ${`col-12 ${
                    el.col ? `col-md-${Number(12 / el?.col)}` : ""
                  }`}`}
                >
                  <VInput
                    label={<span
                      dangerouslySetInnerHTML={{
                        __html: el.label,
                      }}
                    ></span>}
                    id={el.name}
                    disabled={el.disabled}
                    required={el.required}
                    name={el.name}
                    value={
                      additionalData[el.label]?.key
                        ? additionalData[el.label]?.value
                        : additionalData[el.label]
                    }
                    onChange={(e) => handleChangeFields(e, el)}
                  />
                </Form.Group>
              );

            if (el.typeField === "date")
              return (
                <Form.Group
                  className={`form-group ${`col-12 ${
                    el.col ? `col-md-${Number(12 / el?.col)}` : ""
                  }`}`}
                >
                  <VInput
                    type={"date"}
                    label={t(el.label)}
                    id={el.name}
                    name={el.name}
                    required={el.required}
                    value={additionalData[el.label]}
                    onChange={(e) => handleChangeFields(e, el)}
                  />
                </Form.Group>
              );

            if (el.typeField === "date-time")
              return (
                <Form.Group
                  className={`form-group ${`col-12 ${
                    el.col ? `col-md-${Number(12 / el?.col)}` : ""
                  }`}`}
                >
                  <VInput
                    type={"datetime-local"}
                    label={t(el.label)}
                    id={el.name}
                    name={el.name}
                    required={el.required}
                    value={additionalData[el.label]}
                    onChange={(e) => handleChangeFields(e, el)}
                  />
                </Form.Group>
              );

            if (el.typeField === "dropdown")
              return (
                <Form.Group
                  className={`form-group ${`col-12 ${
                    el.col ? `col-md-${Number(12 / el?.col)}` : ""
                  }`}`}
                >
                  <VDropdown
                    // label={el.label}
                    placeholder={t(el.label)}
                    required={el.required}
                    options={el?.options?.map((el: any) => {
                      return {
                        icon: el?.icon,
                        label: t(el?.name),
                        value: el?.name,
                      };
                    })}
                    value={additionalData[el.label]}
                    onChange={(e) => handleChangeFields(e, el)}
                  />
                </Form.Group>
              );

            if (el.typeField === "textarea")
              return (
                <Form.Group
                  className={`form-group ${`col-12 ${
                    el.col ? `col-md-${Number(12 / el?.col)}` : ""
                  }`}`}
                >
                  <VTextarea
                    label={el.label}
                    required={el.required}
                    defaultValue={additionalData[el.label]}
                    onChange={(e) => handleChangeFields(e, el)}
                  />
                </Form.Group>
              );

            if (el.typeField === "media")
              return (
                <div
                  className={`mb-3 d-flex flex-wrap align-items-end ${`col-12 ${
                    el.col && (!el.limit_file || el.limit_file < 2)
                      ? `col-md-${Number(12 / el?.col)}`
                      : ""
                  }`}`}
                >
                  <div className={`item-file w-100`}>
                    <Form.Label>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: el.label,
                        }}
                      ></span>
                      {/* {t(el.label)}&nbsp; */}
                      {el.required && (
                        <span style={{ color: "#ff9b8a" }}>*</span>
                      )}
                    </Form.Label>
                    <div className="form-group d-flex flex-wrap align-items-center justify-content-center">
                      <div
                        className={`upload-file d-flex justify-content-center p-1 ${`col-12 ${
                          el.col && el.limit_file && el.limit_file > 1
                            ? `col-md-${Number(12 / el?.col)}`
                            : ""
                        }`}`}
                      >
                        <div className="item-upload">
                          <UploadFile
                            id={el.label}
                            src={
                              additionalData[el.label]
                                ? getUrlImage(
                                    additionalData[el.label]?.value ||
                                      additionalData[el.label]
                                  )
                                : ""
                            }
                            ratio="340x340"
                            file={
                              listFiles[el.label]?.value || listFiles[el.label]
                            }
                            setFile={setFile}
                          />
                        </div>
                      </div>
                      {Object.keys(listFiles)?.filter((chi: string) =>
                        chi.includes(`${el.label}_shortcut_`)
                      )?.length > 0 &&
                        Object.keys(listFiles)
                          ?.filter((chi: string) =>
                            chi.includes(`${el.label}_shortcut_`)
                          )
                          ?.map((a, index) => (
                            <div
                              key={a}
                              className={`upload-file d-flex justify-content-center p-1 ${`col-12 ${
                                el.col ? `col-md-${Number(12 / el?.col)}` : ""
                              }`}`}
                            >
                              <div className="item-upload">
                                <UploadFile
                                  id={`${el.label}_shortcut_${index + 1}`}
                                  src={
                                    additionalData[
                                      `${el.label}_shortcut_${index}`
                                    ]
                                      ? getUrlImage(
                                          additionalData[
                                            `${el.label}_shortcut_${index + 1}`
                                          ]
                                        )
                                      : ""
                                  }
                                  ratio="340x340"
                                  file={
                                    listFiles[
                                      `${el.label}_shortcut_${index + 1}`
                                    ]
                                  }
                                  setFile={setFile}
                                />
                              </div>
                            </div>
                          ))}
                      {el.limit_file && el.limit_file > 1 && (
                        <div className="d-flex justify-content-center p-1 col-md-6">
                          <VButton
                            onClick={() =>
                              handleAddMedia(
                                `${el.label}_shortcut_${
                                  Object.keys(listFiles)?.filter(
                                    (chi: string) =>
                                      chi.includes(`${el.label}_shortcut_`)
                                  )?.length + 1
                                }`
                              )
                            }
                            disabled={
                              Object.keys(listFiles)?.filter((chi: string) =>
                                chi.includes(`${el.label}_shortcut_`)
                              )?.length >=
                              el.limit_file - 1
                            }
                          >
                            +
                          </VButton>
                        </div>
                      )}
                      {el?.link && (
                        <div className="form-group mt-2">
                          <VInput
                            label={t(el.label)}
                            id={el.name}
                            name={el.name}
                            required={el.required}
                            value={additionalData[el.label]}
                            onChange={(e) => handleChangeFields(e, el, "media")}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              );
            if (el.typeField === "editor")
              return (
                <div className={"mb-3 d-flex flex-wrap align-items-end col-12"}>
                  <div className={`item-file w-100`}>
                    <Form.Label>
                      {t(el.label)}&nbsp;
                      {el.required && (
                        <span style={{ color: "#ff9b8a" }}>*</span>
                      )}
                    </Form.Label>
                    <div className={""}>
                      <VEditor
                        defaultValue={additionalData[el.label]?.html}
                        onChange={(e) => handleChangeFields(e, el, "editor")}
                      />
                    </div>
                  </div>
                </div>
              );
          })}
    </div>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
/* eslint-disable indent */
import { useEffect, useState } from "react";
import { Form, Row } from "react-bootstrap";
import { useHistory } from "react-router-dom";

// import VInput from "components/v-input";
import VButton from "components/v-button";
import Dropdown from "components/v-dropdown";
import BirthdatSelect from "components/v-birthday-select";
import SelectRegions from "components/v-location";
import VInput from "components/v-input";
import VTextarea from "components/v-textarea";

import useTranslation from "hooks/useTranslation";
import APIsUser from "api/users";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { OBJECT_TYPE, USER_GENDER, POSITION_CAREER } from "config/enums";
import {
  getCareers,
  getFamilies,
  getFields,
  getHobbies,
  getNations,
  getReligions,
} from "redux/reducers/settings/settings.selector";
import {
  getUserAsync,
  getUserByIdAsync,
} from "redux/reducers/users/users.actions";

import FromTemplateService from "api/form-templates";
import VForm from "components/v-form";
import UploadService from "api/uploads";
import WebsiteSetting from "../utils/Website-settings";

const gender = Object.keys(USER_GENDER).map((k) => ({
  name: String(k).toLowerCase(),
  value: USER_GENDER[k],
}));

const position = Object.keys(POSITION_CAREER).map((k) => ({
  name: k,
  value: POSITION_CAREER[k],
}));

const FormProfileSettings = (props) => {
  const { data, isMyProfile } = props;
  const { t } = useTranslation();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const hobbies = useAppSelector(getHobbies);
  const careers = useAppSelector(getCareers);
  const nations = useAppSelector(getNations);
  const religions = useAppSelector(getReligions);
  const categoryField = useAppSelector(getFields);
  const families = useAppSelector(getFamilies);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(data?.profile);
  const [address, setAddress] = useState<any>({});
  const [homeTown, setHomeTown] = useState<any>({});

  const [isValid, setIsValid] = useState(false);
  const [fieldAdditionalData, setFieldAdditionalData] = useState(null);
  const [additionalData, setAdditionalData] = useState({});
  const [listFiles, setListFiles] = useState<any>({});
  const [validate, setValidate] = useState<any>(null);

  const disabledField = ["kyc", "verified"].includes(data?.status_kyc);

  const handleCancel = () => {
    const search = new URLSearchParams(window.location.search);
    search.delete("isEdit");
    history.push(`?${search}`);
  };

  const handleChange = (value, name) => {
    setState((prev) => ({
      ...prev,
      [name]: value || null,
    }));
  };

  const setFile = (f: any, id: string) => {
    setListFiles((prev) => ({
      ...prev,
      [id]: f,
    }));
  };

  const handleEdit = async () => {
    try {
      if (!isValid) return;
      setLoading(true);

      let listImg;
      let newDataAddtional = { ...additionalData };
      if (Object.keys(listFiles).length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${data?._id}`);
        Object.keys(listFiles).map((el: any) => {
          formData.append("files", listFiles[el]);
        });
        listImg = await UploadService.uploadMultiFile({
          body: formData,
        });
      }

      if (listImg && listImg?.length > 0) {
        listImg?.map((el: any) => {
          Object.keys(listFiles || {}).map((item: any, indexKey: number) => {
            if (el.id === indexKey) {
              newDataAddtional = {
                ...newDataAddtional,
                [item]: el?.filePath,
              };
            }
          });
        });
      }

      const newData = {
        location: address,
        home_town: homeTown,
        birthday: state.birthday,
        gender: state.gender,
        description: state.description,
        slogan: state.slogan,
        position: state.position,
        hobbies: state.hobbies,
        career_id: state.career_id,
        category_field_id: state.category_field_id,
        nation_id: state.nation_id,
        religion_id: state.religion_id,
        website: state.website,
        additionalData: newDataAddtional,
        family_id: state.family_id,
        genus_family: state.genus_family,
        secondary_site: state.secondary_site,
      };
      const res: any = await APIsUser.updateProfile({
        body: {
          ...newData,
        },
      });
      if (res) {
        if (isMyProfile) dispatch(getUserAsync());
        dispatch(
          getUserByIdAsync({
            params: {
              id: data._id,
              user_id: data._id,
            },
          })
        );
        handleCancel();
        toast.success(t(`Success`));
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(`${error?.errors}`));
    }
  };

  const getFormTemplateProfile = async () => {
    try {
      const res: any = await FromTemplateService.getForm({
        query: {
          type: "profile_setting",
          object_type: data?.object_type,
        },
      });
      if (res) {
        setFieldAdditionalData(res);
      }
    } catch (error) {
      setFieldAdditionalData([]);
    }
  };

  useEffect(() => {
    if (data?.profile?.location) {
      setAddress(data.profile.location);
    }
    if (data?.profile?.home_town) {
      setHomeTown(data.profile.home_town);
    }
    if (data?.profile?.additionalData) {
      setAdditionalData(data.profile.additionalData);
    }
  }, [data.profile]);

  useEffect(() => {
    getFormTemplateProfile();
  }, [data.object_type]);

  return (
    <Form>
      {data.object_type === OBJECT_TYPE.BUSINESS ? (
        <Form.Group className="form-group col-6 me-1">
          <VInput
            label={t("Fullname")}
            type="text"
            id="full_name"
            name="full_name"
            disabled={true}
            defaultValue={data.full_name}
          />
        </Form.Group>
      ) : (
        <>
          <Form.Group className="form-group col-12 col-md-12">
            <Dropdown
              label={t("Family")}
              disabled={false}
              placeholder={t("Family")}
              options={families.map((el: any) => {
                return {
                  icon: el.logo,
                  label: t(el.name),
                  value: String(el._id),
                };
              })}
              value={String(state?.family_id)}
              onChange={(e) => handleChange(e.target.value, "family_id")}
            />
          </Form.Group>

          <Form.Group className="form-group col-12">
            <VInput
              label={t("Genus family")}
              type="text"
              id="genus_family"
              name="genus_family"
              defaultValue={state.genus_family}
              onChange={(e) => handleChange(e.target.value, "genus_family")}
            />
          </Form.Group>

          <div className="d-flex mb-2">
            <Form.Group className="form-group col-6 me-1">
              <VInput
                label={t("First name")}
                type="text"
                id="first_name"
                name="first_name"
                disabled={true}
                defaultValue={data.first_name}
              />
            </Form.Group>
            <Form.Group className="form-group col-6 ms-1">
              <VInput
                label={t("Last name")}
                type="text"
                id="last_name"
                name="last_name"
                disabled={true}
                defaultValue={data.last_name}
              />
            </Form.Group>
          </div>
        </>
      )}

      <div className="d-flex mb-2">
        <Form.Group className="form-group col-6 me-1">
          <VInput
            label={t("Enter email")}
            type="email"
            id="email"
            name="email"
            disabled={true}
            defaultValue={data.email}
          />
        </Form.Group>
        <Form.Group className="form-group col-6 ms-1">
          <VInput
            label={t("Phone")}
            type="number"
            id="phone"
            name="phone"
            disabled={true}
            defaultValue={data.phone}
          />
        </Form.Group>
      </div>
      <div className="mb-2">
        <VTextarea
          label={t("Slogan")}
          id="slogan"
          name="slogan"
          defaultValue={state?.slogan}
          onChange={(e) => handleChange(e.target.value, "slogan")}
        />
      </div>
      <div className="row mb-1">
        <div className="form-group col-12 col-md-6 mb-2">
          <Form.Label>{t("Birthday")}</Form.Label>
          <BirthdatSelect
            date={state?.birthday}
            // disabled={disabledField}
            setIsValid={setIsValid}
            onChange={(e) => handleChange(e, "birthday")}
          />
        </div>
        <div className="form-group col-12 col-md-6 mb-2">
          <Dropdown
            label={t("Gender")}
            disabled={disabledField}
            placeholder={t("Gender")}
            options={gender.map((el: any) => {
              return {
                label: t(el.name),
                value: el.value,
              };
            })}
            value={state?.gender}
            onChange={(e) => handleChange(e.target.value, "gender")}
          />
        </div>
      </div>
      <div className="mb-2">
        <Form.Label>{t("Address")}</Form.Label>
        <SelectRegions
          hideLabel={true}
          hideDetail={false}
          isDisabled={false}
          address={address}
          isShowMap={true}
          setAddress={setAddress}
        />
      </div>
      {data.object_type !== OBJECT_TYPE.BUSINESS && (
        <div className="mb-2">
          <Form.Label>{t("Hometown")}</Form.Label>
          <SelectRegions
            hideLabel={true}
            isDisabled={disabledField}
            hideDetail={false}
            isShowMap={true}
            address={homeTown}
            setAddress={setHomeTown}
          />
        </div>
      )}
      {data.object_type === OBJECT_TYPE.CEO && (
        <div className="mb-2">
          <Form.Label>{t("Position")}</Form.Label>
          <Dropdown
            id="position"
            placeholder={t("Position")}
            isMulti={true}
            options={position.map((el: any) => {
              return {
                icon: el.logo,
                label: t(el.name),
                value: el.value,
              };
            })}
            value={state?.position}
            onChange={(value) => handleChange(value, "position")}
          />
        </div>
      )}
      <div className="mb-2">
        <Form.Label>{t("Hobbies")}</Form.Label>
        <Dropdown
          key={"hobbies"}
          placeholder={t("Hobbies")}
          isMulti={true}
          options={hobbies.map((el: any) => {
            return {
              icon: el.logo,
              label: t(el.name),
              value: String(el._id),
            };
          })}
          value={state?.hobbies}
          onChange={(value) => handleChange(value, "hobbies")}
        />
      </div>
      <Row>
        <Form.Group className="form-group col-12 col-md-6">
          <Dropdown
            label={t("Nation")}
            disabled={false}
            placeholder={t("Nation")}
            options={nations.map((el: any) => {
              return {
                icon: el.logo,
                label: t(el.name),
                value: String(el._id),
              };
            })}
            value={String(state?.nation_id)}
            onChange={(e) => handleChange(e.target.value, "nation_id")}
          />
        </Form.Group>
        <Form.Group className="form-group col-12 col-md-6">
          <Dropdown
            label={t("Religion")}
            disabled={false}
            placeholder={t("Religion")}
            options={religions.map((el: any) => {
              return {
                icon: el.logo,
                label: t(el.name),
                value: String(el._id),
              };
            })}
            value={String(state?.religion_id)}
            onChange={(e) => handleChange(e.target.value, "religion_id")}
          />
        </Form.Group>
      </Row>
      <Row>
        <Form.Group className="form-group col-12 col-md-6">
          <Dropdown
            label={t("Job")}
            disabled={data.status_kyc_career === "kyc"}
            placeholder={t("Job")}
            options={careers.map((el: any) => {
              return {
                icon: el.logo,
                label: t(el.name),
                value: String(el._id),
              };
            })}
            value={String(state?.career_id)}
            onChange={(e) => handleChange(e.target.value, "career_id")}
          />
        </Form.Group>
        <Form.Group className="form-group col-12 col-md-6">
          <Dropdown
            label={t("Category field")}
            disabled={false}
            placeholder={t("Category field")}
            options={categoryField.map((el: any) => {
              return {
                icon: el.logo,
                label: t(el.name),
                value: String(el._id),
              };
            })}
            value={String(state?.category_field_id)}
            onChange={(e) => handleChange(e.target.value, "category_field_id")}
          />
        </Form.Group>
      </Row>
      <Form.Group className="form-group col-12">
        <VInput
          label={t("Website")}
          type="text"
          id="website"
          name="website"
          defaultValue={state.website}
          onChange={(e) => handleChange(e.target.value, "website")}
        />
      </Form.Group>

      {/* setting web site */}
      <WebsiteSetting state={state} setState={setState} />

      <div className="mb-2">
        <VTextarea
          label={t("Descriptions")}
          id="description"
          name="description"
          defaultValue={state?.description}
          onChange={(e) => handleChange(e.target.value, "description")}
        />
      </div>
      {fieldAdditionalData?.fields?.length > 0 && (
        <div className="row">
          <VForm
            // colItem={"col-6"}
            fields={fieldAdditionalData?.fields}
            additionalData={additionalData}
            listFiles={listFiles}
            validate={validate}
            setListFiles={setListFiles}
            setValidate={setValidate}
            setIsValid={setIsValid}
            setFile={setFile}
            setAdditionalData={setAdditionalData}
          />
        </div>
      )}
      <VButton
        loading={loading}
        height={"36px"}
        className="btn btn-primary me-2"
        disabled={!isValid}
        onClick={handleEdit}
      >
        {t("save")}
      </VButton>
      <VButton
        loading={loading}
        onClick={handleCancel}
        height={"36px"}
        className="btn bg-danger border-danger"
      >
        {t("Cancel")}
      </VButton>
    </Form>
  );
};

export default FormProfileSettings;

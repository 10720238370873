import { clsx } from "clsx";
import VAvatar from "components/v-avatar";

type IProps = {
  data: any;
  className?: string;
  onChange: any;
  checked?: boolean;
  selectedUsers?: any;
};

const View = ({ data, className, onChange, selectedUsers }: IProps) => {
  return (
    <div className={clsx(className, "radio-wrapper")}>
      <div className="radio-row align-items-center d-flex">
        <input
          className="form-check-input radio-input m-0"
          type="radio"
          name={data["_id"]}
          id={data["_id"]}
          value={data}
          onChange={() => {}}
          onClick={() => onChange(data)}
          checked={selectedUsers?.includes(data) ? true : false}
        />
        <VAvatar size={"30px"} userData={data} rezise={50} className="ms-3" />
      </div>
    </div>
  );
};

export default View;

/* eslint-disable react-hooks/exhaustive-deps */
import RelationShipService from "api/relationships";
import VButton from "components/v-button";
import VDropdown from "components/v-dropdown";
import VModal from "components/v-modal";
import VTable from "components/v-table";
import { RELATIONSHIP_STATUS, RELATIONSHIP_TYPE } from "config/enums";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { toast } from "react-toastify";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getRelationAsync } from "redux/reducers/users/users.actions";
import { getRelations } from "redux/reducers/users/users.selector";
import { formatDate } from "utils/common";
import FriendService from "api/friends";
import { IObject } from "types";
import Card from "components/v-teamplate/Card";

const Relation = ({ dataUser }) => {
  const defaultState = {
    to_id: "",
    relation: "",
  };
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState(defaultState);
  const relations = useAppSelector(getRelations);
  const [friends, setFriends] = useState([]);

  useEffect(() => {
    dispatch(getRelationAsync());
    getFriends();
  }, []);

  const getFriends = async () => {
    const res: IObject = await FriendService.getFriends({
      params: {
        user_id: dataUser._id,
      },
      query: {
        page: 1,
        limit: 50,
        type: "friend",
      },
    });
    if (res && res.docs) {
      setFriends(res.docs);
    }
  };

  const onChange = (name, value) => {
    setState((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleSubmit = async () => {
    try {
      if (!state.to_id || !state.relation) return toast.error(t("Please fill in all the information."))
      setLoading(true);
      const res = await RelationShipService.create({
        body: state,
      });
      if (res) {
        dispatch(getRelationAsync());
        setOpenModal(false);
        setState(defaultState);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(t(error.errors));
    }
  };

  const renderContentModal = () => {
    return (
      <div>
        <Form className="form-group">
          <Form.Group className="form-group">
            <VDropdown
              label={<div>{t("Friend")}</div>}
              placeholder={t("Choose Friend")}
              value={state.to_id}
              options={friends.map((f) => ({
                icon: f.to?.avatar,
                label: f.to?.full_name,
                value: f.to?._id,
              }))}
              onChange={(e: any) => onChange("to_id", e.target.value)}
            />
          </Form.Group>
          <Form.Group className="form-group">
            <VDropdown
              label={<div>{t("Relation type")}</div>}
              placeholder={t("Relation type")}
              value={state.relation}
              options={Object.keys(RELATIONSHIP_TYPE).map((el: any) => {
                return {
                  label: t(RELATIONSHIP_TYPE[el]),
                  value: RELATIONSHIP_TYPE[el],
                };
              })}
              onChange={(e: any) => onChange("relation", e.target.value)}
            />
          </Form.Group>
        </Form>
      </div>
    );
  };

  const changeStatus = async (item, status) => {
    try {
      await RelationShipService.update({
        body: {
          status,
          relationshipId: item._id,
        },
      });
      dispatch(getRelationAsync());
    } catch (error) {
      toast(error.errors);
    }
  };
  const columns = [
    {
      key: "to",
      title: t("To"),
      render: (item) => String(item.to?.full_name).slice(0, 20),
    },
    {
      key: "Relation",
      title: t("Relation"),
      dataIndex: "relation",
    },
    {
      key: "Date",
      title: t("Date"),
      render: (item) => formatDate(item.createdAt),
    },
    {
      key: "Status",
      title: t("Status"),
      render: (item: any) => <div className={item.status}>{item.status}</div>,
    },
    {
      key: "Action",
      title: t("Action"),
      render: (item: any) => (
        <div className="action d-flex">
          {item.status !== RELATIONSHIP_STATUS.DECLINED && (
            <VButton
              className={"btn-danger ms-1 py-0"}
              onClick={(_) => changeStatus(item, "declined")}
            >
              {t("Cancel")}
            </VButton>
          )}
        </div>
      ),
    },
  ];

  return (
    <Card className="p-2">
      <div className="request">
        <div className="text-end p-2">
          <VButton
            type="button"
            className="btn btn-primary"
            onClick={(_) => setOpenModal(true)}
          >
            {t("Add relation")}
          </VButton>
        </div>
        <div className="overflow-auto">
          <VTable columns={columns} dataSource={relations} />
        </div>
        <VModal
          title={t("Add relation")}
          isOpen={openModal}
          loading={loading}
          centered={true}
          children={renderContentModal()}
          onCancel={() => setOpenModal(false)}
          onConfirm={handleSubmit}
        />
      </div>
    </Card>
  );
};

export default Relation;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import Button from "components/v-button";
import { useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import { useAppSelector } from "redux/reducers/hook";
import { getStocks } from "redux/reducers/cryptos/cryptos.selector";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import useQuery from "hooks/useQuery";
import queryString from "query-string";

const currencies = [
  {
    name: "p2p",
    icon: "/assets/images/coins/p2p.png",
    disable: false,
    open: "is_p2p",
  },
  {
    name: "spot",
    icon: "/assets/images/coins/spot.png",
    disable: false,
    open: "is_spot",
  },
  {
    name: "qr-mart",
    icon: "/assets/images/coins/spot.png",
    disable: false,
    open: "is_qrmart",
  },
  {
    name: "cv",
    icon: "/assets/images/coins/spot.png",
    disable: false,
    open: "is_cv",
  },
];

type IProps = {
  url: string;
  userId?: string;
};

const View = (props: IProps) => {
  const { url } = props;
  const { t } = useTranslation();

  const history = useHistory();
  const [method, setMethod] = useState("");
  const query = useQuery();
  const { stock } = query;

  const stocks = useAppSelector(getStocks);

  const handleClick = () => {
    const _query = {
      ...query,
      method: `withdraw_${method}`,
    };
    history.push(`${url}/?${queryString.stringify(_query)}`);
  };

  const _currencies = useMemo(() => {
    if (stocks) {
      const _stock = stocks.find((s) => s.symbol === stock);
      if (_stock) return currencies.filter((c) => _stock[c.open]);
      return [];
    }
    return [];
  }, [stocks, stock]);

  return (
    <Card isOpacity={true}>
      <div className="step-0">
        <h5>
          {String(stock || "").toUpperCase()} -{" "}
          {t("Please select the method you want to withdraw")}{" "}
        </h5>
        {_currencies.map((c, index) => (
          <Button
            key={index}
            className={`btn-currency  ${method === c.name ? "active" : ""}`}
            onClick={() => setMethod(c.name)}
            disabled={c.disable}
          >
            <img className="icon-currenct" src={c.icon} />
            {c.name.toUpperCase()}
          </Button>
        ))}
        <div className="submit-step">
          <Button onClick={handleClick} disabled={!method}>
            {t("Continue")}
          </Button>
        </div>
      </div>
    </Card>
  );
};
export default View;

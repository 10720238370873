/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Container } from "react-bootstrap";
import Card from "components/v-teamplate/Card";

import VBannerPage from "components/v-teamplate/v-banner-page";
import VRightView from "components/v-right-view";

import { useEffect, useMemo, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import { getNotificationsClient } from "redux/reducers/notifications/notifications.selectors";
import { getNotificationsClientAsync } from "redux/reducers/notifications/notifications.actions";
import { convertTimeAgo } from "utils/times";
import {
  setLazyParams,
  setNotification,
} from "redux/reducers/notifications/notifications.reducers";
import { useHistory } from "react-router-dom";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";
import { getUrlImage } from "utils/common";
import NotificationService from "api/notifications";
import { NOTIFICATION_TYPE } from "config/enums";
import VIcon from "components/v-icon";
import { setOpenWebModulesPopupAction } from "redux/reducers/ui-settings/ui-settings.reducer";
import VInfiniteLoad from "components/v-infinite-load";
import VNameVerify from "components/v-objects/v-name-verify";
import reactStringReplace from "react-string-replace";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const Notification = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null

  const [filter, setFilter] = useState("all");
  const [loading, setLoading] = useState(false);
  const notification: any = useAppSelector(getNotificationsClient);
  const { page, limit } = notification;

  useEffect(() => {
    if (isLoggedIn) getData({ page });
  }, [page, isLoggedIn]);

  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getNotificationsClientAsync({
        query: {
          page,
          limit,
        },
      })
    );
    setLoading(false);
  };

  const handleViewMore = () => {
    dispatch(setLazyParams({ page: page + 1 }));
  };

  const handleClick = (notice) => {
    const { url, extra } = notice.data;
    if (url) {
      if (String(url).includes(".")) window.open(url);
      else {
        history.push(convertUrl(notice.data.url, notice));
      }
    }

    if (extra?.post_id) {
      dispatch(
        setOpenWebModulesPopupAction({
          openWebModuleKey: "post_detail",
          payload: {
            post: {
              _id: extra?.post_id,
            },
          },
        })
      );
    }

    if (!notice.isRead) {
      NotificationService.readNotification({
        params: {
          id: notice._id,
        },
      });
      dispatch(
        setNotification({
          ...notice,
          isRead: true,
        })
      );
    }
  };

  const _notifications = useMemo(() => {
    if (filter === "all") return notification.docs;
    else return notification.docs.filter((n) => !n.isRead);
  }, [notification, filter]);

  const convertBodyNotification = (notice) => {
    let content = notice?.data?.body;
    if (notice?.data?.body.includes("{from_name}")) {
      content = reactStringReplace(content, "{from_name}", (match, i) => (
        <strong className="d-inline-flex">
          <VNameVerify
            className={"mb-0 d-flex align-items-center"}
            dataUser={notice?.from}
            fontSizeIconVerify={12}
          />
        </strong>
      ));
    }
    if (notice?.data?.body.includes("{to_name}")) {
      content = reactStringReplace(content, "{to_name}", (match, i) => (
        <strong className="d-inline-flex">
          <VNameVerify
            className={"mb-0 d-flex align-items-center"}
            dataUser={notice?.to}
            fontSizeIconVerify={12}
          />
        </strong>
      ));
    }
    if (notice?.data?.body.includes("{object_name}")) {
      content = reactStringReplace(content, "{object_name}", (match, i) => (
        <b>{notice.data.extra?.object_name || ""}</b>
      ));
    }

    if (notice?.data?.body.includes("{object_id}")) {
      content = reactStringReplace(content, "{object_id}", (match, i) => (
        <strong className="d-inline-flex">
          <VNameVerify
            className={"mb-0 d-flex align-items-center"}
            dataUser={notice?.object}
            fontSizeIconVerify={12}
          />
        </strong>
      ));
    }

    return <h6 className="mb-0">{content}</h6>;
  };

  const convertUrl = (url, notification) => {
    return String(url)
      .replace("{from_id}", notification.from_id)
      .replace("{to_id}", notification.to_id || "")
      .replace("{object_id}", notification.object_id || "");
  };

  const renderImage = (notice) => {
    if (notice.type === NOTIFICATION_TYPE.COMMON) {
      return getUrlImage(notice?.data?.image);
    }
    return getUrlImage(notice?.from?.avatar);
  };
  return (
    <div className="notification-list">
      <Container>
        <Row>
          <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
            <VBannerPage position="banner_top_notification" />
            <div className="header-title">
              <h5 className="title">{t("All notification")}</h5>
              <VIcon className="cursor-pointer" name="tree-dot.svg" />
            </div>
            <VInfiniteLoad
              onLoadMore={handleViewMore}
              canLoadMore={page < notification.totalPages}
              isLoadingMore={loading}
            >
              <>
                <div className="filter-status">
                  <div
                    className={`item-detail-filter ${
                      filter === "all" ? "filter-active" : ""
                    }`}
                    onClick={(_) => setFilter("all")}
                  >
                    {t("All")}
                  </div>
                  <div
                    className={`item-detail-filter ${
                      filter === "unread" ? "filter-active" : ""
                    }`}
                    onClick={(_) => setFilter("unread")}
                  >
                    {t("Unread")}
                  </div>
                </div>
                {(_notifications || []).map((notice, index) => (
                  <div
                    className={`notification-item mt-2 ${
                      notice.isRead ? "" : "item-unread"
                    }`}
                    key={index}
                    onClick={(_) => handleClick(notice)}
                  >
                    <Card>
                      <Card.Body>
                        <ul className="m-0 p-0">
                          <li className="d-flex align-items-center align-items-center">
                            <div className="avatar-notification">
                              <img src={renderImage(notice)} alt="story-img" />
                            </div>
                            <div className="content-notification">
                              <div className="d-flex align-items-center">
                                <div className="">
                                  {convertBodyNotification(notice)}
                                  <p className="mb-0">
                                    {convertTimeAgo(
                                      new Date(notice.createdAt).getTime(),
                                      t
                                    )}
                                  </p>
                                </div>
                                <div className="d-flex align-items-center">
                                  {!notice.isRead && (
                                    <div className="unread"></div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </Card.Body>
                    </Card>
                  </div>
                ))}
              </>
            </VInfiniteLoad>
          </Col>
          {show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1"}>
              <VRightView
                menuKey={"tab_right_notification"}
                positionBanner={"banner_right_notification"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Notification;

/* eslint-disable indent */
import { Row, Col, Card } from "react-bootstrap";
import Verification from "./components/Verification";
import RightAndObligation from "./components/RightAndObligation";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { useEffect, useState } from "react";
import { getFasAsync } from "redux/reducers/faqs/faqs.actions";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import VBannerPage from "components/v-teamplate/v-banner-page";
import VTab from "components/v-tab";
import useQuery from "hooks/useQuery";
import ComingSoon from "pages/coming-soon/comingsoon";
import VRightView from "components/v-right-view";

const View = () => {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const tabs = useAppSelector(getMenusByKey("censor_register"));
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  const [tab, setTab] = useState(null);
  const [loadMenu, setLoadMenu] = useState(false);

  useEffect(() => {
    dispatch(getFasAsync());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (tabs && tabs.length > 0) {
          const menu = tabs.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(tabs[0]);
        }
      } else {
        setTab(tabs[0]);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      setLoadMenu(true);
    }
  }, [tabs]);

  const renderTab = () => {
    if (tab && tab.type_view === "internal") {
      switch (tab.key) {
        case "censor_register":
          return <Verification />;
        case "censor_right_and_obligation":
          return <RightAndObligation />;
        default:
          return <ComingSoon />;
      }
    }
  };

  return (
    <div className="moderator-page">
      <div className="container">
        <Row>
          <Col sm={12} md={show_banner_right_on_container ? 8 : 12}>
            <VBannerPage position="banner_top_censor" />
            <Card className="p-3">
              <Col sm={12}>
                <VTab
                  tab={tab}
                  setTab={setTab}
                  menus={tabs}
                  activeBorder="bottom"
                  noBackground={true}
                />
                {renderTab()}
              </Col>
            </Card>
          </Col>
          {show_banner_right_on_container && (
            <Col sm={12} md={4}>
              <VRightView
                menuKey="tab_right_censor"
                positionBanner="banner_right_censor"
              />
            </Col>
          )}
        </Row>
      </div>
    </div>
  );
};

export default View;

import useTranslation from "hooks/useTranslation";

const DisconnectToast = () => {
  const { t } = useTranslation();
  const reload = () => {
    window.location.reload();
  };
  return (
    <>
      <div
        className="toast-disconect"
        role="alert"
        aria-live="assertive"
        aria-atomic="true"
      >
        <div className="toast-body">{t("Bạn đang offline.")}</div>
        <span onClick={reload}>{t("Làm mới")}</span>
      </div>
    </>
  );
};

export default DisconnectToast;

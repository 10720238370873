import { DEFAULT_RADIUS_KM } from "constants/provinces";
import { request, parseErrorResponse } from "./request";

const getSosPostsByCoordinates = (coords: {lat: number, lng: number}, params: any) =>
  new Promise((resolve, reject) => {
    const {lat, lng} = coords;
    const query = Object.keys(params).map((key) => `${key}=${params[key]}`).join("&");

    request()
      .get(`/posts?plugins=checkin&lat=${lat}&lng=${lng}&page=1&radius=${DEFAULT_RADIUS_KM}&${query}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const APIs = {
  getSosPostsByCoordinates,
};

export default APIs;

import { Container } from "react-bootstrap";
/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import useTranslation from "hooks/useTranslation";
import { getIsLoggedIn } from "redux/reducers/users/users.selector";

import VButton from "components/v-button";
import { BUSINESS_ACTION } from "config/enums";
import { getInvitedGroups } from "redux/reducers/groups/groups.selector";
import { getGroupInvitedAsync } from "redux/reducers/groups/groups.action";
import VGroupCard from "../../../../components/v-objects/v-group-card/index";
import { removeGroupInvited } from "redux/reducers/groups/groups.reducer";

const View = (props: any) => {
  const { is_chat } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);
  const group: any = useAppSelector(getInvitedGroups);
  const isLoggedIn = useAppSelector(getIsLoggedIn);
  const { page, limit } = group;

  useEffect(() => {
    if (page === 1 && isLoggedIn)
      getData({
        page: 1,
      });
  }, [page, isLoggedIn]);
  const getData = async ({ page }) => {
    setLoading(true);
    await dispatch(
      getGroupInvitedAsync({
        query: {
          page,
          limit,
          ...(is_chat && {
            is_chat: true,
          }),
        },
      })
    );

    setLoading(false);
  };

  const handleViewMore = () => {
    getData({
      page: page + 1,
    });
  };

  const reload = ({ to_id }) => {
    dispatch(removeGroupInvited(to_id));
  };

  return (
    <>
      <div>
        <Container>
          <div className="row">
            {group &&
              (group.docs || []).map((group, index) => (
                <VGroupCard
                  key={index}
                  col_lg={3}
                  col_md={4}
                  group={{
                    ...group,
                    ...group.from,
                  }}
                  type={BUSINESS_ACTION.RESPONSE}
                  reload={reload}
                />
              ))}
          </div>
        </Container>

        <div className="col-12">
          {page < group.totalPages && (
            <div className="text-center p-2">
              <VButton
                type="button"
                loading={loading}
                size={"small"}
                onClick={handleViewMore}
              >
                {" "}
                {t("View more")}
              </VButton>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default View;

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import { Row, Col, Card } from "react-bootstrap";
import WebModule from "modals/web-modules";
import VBannerPage from "components/v-teamplate/v-banner-page";
import PageBackgrounds from "components/v-page-background";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import Header from "layouts/dashboard/HeaderStyle/header";

const Conversation = (props: any) => {
  const location: any = useLocation();
  const userProfileId = location?.pathname.split("/conversations/");

  if (isMobile) {
    return (
      <div className="page-conversations">
        <WebModule
          userProfileId={userProfileId[1]}
          openWebModuleKey="conversations"
          extraQuery={{
            preview: true,
          }}
          page="conversation"
        />
      </div>
    );
  }

  return (
    <>
      <Header />
      <PageBackgrounds page={"page_bg_group"} />
      <Row>
        <Col lg={12} className={"px-1"}>
          <VBannerPage position="banner_top_converstaions" />
          <Card>
            <Col sm={12}>
              <div className="page-conversations">
                <WebModule
                  userProfileId={userProfileId[1]}
                  openWebModuleKey="conversation_plugins"
                  extraQuery={{
                    preview: true,
                  }}
                  page="conversation"
                />
              </div>
            </Col>
          </Card>
        </Col>
      </Row>
    </>
  );
};

export default Conversation;

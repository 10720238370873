/* eslint-disable indent */
import { Switch, Route } from "react-router-dom";
import { ROUTES } from "config/routes";
import PreviewLayout from "layouts/preview-layout";

import Supports from "pages/social/supports";

//profile
import UserProfile from "pages/social/user/profile";
import UserPreview from "pages/social/user/preview";
import ProfileBusiness from "pages/social/business/profile";
import ProfileGroup from "pages/social/groups/profile";
import ProfilePage from "pages/social/pages/profile";
// import ShopDetail from "pages/stocks/shop/products/Detail";
import Channel from "pages/social/channel";
import ProfileChannel from "pages/social/channel/profile";
import ProfileVideos from "pages/social/user/videos/profile-videos";
import VideoLink from "pages/social/video-link";
import VMedia from "pages/social/v-media";
import Events from "pages/social/events";
import Surveys from "pages/social/survey";
import FeedByTypes from "router/feed-by-type";
import PostDetail from "pages/posts/post-detail";
import Shop from "pages/social/shop";
import ShopByUserId from "pages/social/shop/user_id";

const View = (props) => {
  return (
    <PreviewLayout>
      <Switch>
        <Route path={ROUTES.SUPPORTS} component={Supports} />
        <Route path={ROUTES.PROFILE_BUSINESS} component={ProfileBusiness} />
        <Route path={ROUTES.PROFILE_GROUP} component={ProfileGroup} />
        <Route path={ROUTES.PROFILE_PAGE} component={ProfilePage} />
        <Route path={ROUTES.PROFILE} component={UserProfile} />
        <Route path={ROUTES.VIDEO_LINK} component={VideoLink} />
        <Route path={ROUTES.PROFILE_CHANNEL} component={ProfileChannel} />
        <Route path={ROUTES.PROFILE_PREVIEW} component={UserPreview} />
        {/* <Route path={ROUTES.PRODUCT_DETAIL} component={ShopDetail} /> */}
        <Route path={ROUTES.PROFILE_VIDEOS} component={ProfileVideos} />
        <Route path={ROUTES.CHANNEL} component={Channel}></Route>
        <Route path={ROUTES.V_MEDIA} component={VMedia} />

        <Route path={ROUTES.NEW_EVENTS} component={Events} />
        <Route path={ROUTES.SURVEYS} component={Surveys} />
        <Route path={ROUTES.SHOP_USER_ID} component={ShopByUserId} />
        <Route path={ROUTES.SHOP} component={Shop} />
        <Route path={ROUTES.POST_DETAIL} component={PostDetail} />

        <Route path="/" component={FeedByTypes} />
      </Switch>
    </PreviewLayout>
  );
};

export default View;

/* eslint-disable no-empty-pattern */
import { createSlice } from "@reduxjs/toolkit";
import { getCategoryFaqsAsync, getFasAsync } from "./faqs.actions";

export interface IFaqsState {
  categoryFaqs: any[];
  qa: any[];
}

const initialState: IFaqsState = {
  categoryFaqs: [],
  qa: [],
};

export const FaqsSlice = createSlice({
  name: "faqs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategoryFaqsAsync.pending, (state) => {})
      .addCase(getCategoryFaqsAsync.fulfilled, (state, action) => {
        // const { notifications } = action.payload;
        state.categoryFaqs = action.payload;
      })
      .addCase(getFasAsync.pending, (state) => {})
      .addCase(getFasAsync.fulfilled, (state, action) => {
        state.qa = action.payload;
      });
  },
});

export const {} = FaqsSlice.actions;

export default FaqsSlice.reducer;

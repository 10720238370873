/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import PostService from "api/posts";
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { getUrlImage } from "utils/common";
import FsLightbox from "fslightbox-react";
import useTranslation from "hooks/useTranslation";
import VIcon from "components/v-icon";
import VTimeline from "components/v-timeline";
import { renderDate } from "utils/times";
import useQuery from "hooks/useQuery";
import { useHistory } from "react-router-dom";
import VChannel from "components/v-channel";

const ProfileVideos = (props) => {
  const { dataUser } = props;
  const { t } = useTranslation();
  const query = useQuery();
  const history = useHistory();
  const [postVideo, setPostVideo] = useState([]);
  const [videos, setVideos] = useState<any>([]);
  const [mediaTimeline, setMediaTimeline] = useState([]);
  const [pageCurrent, setPageCurrent] = useState(1);

  const [videoController, setVideoController] = useState({
    toggler: false,
    slide: 1,
  });

  const videoOnSlide = (number) => {
    setVideoController({
      toggler: !videoController.toggler,
      slide: number,
    });
  };

  const handleView = (id) => {
    if(window.location.search){
      history.push(`${window.location.search}&view=${id}`);
    } else {
      history.push(`?view=${id}`);
    }
  };

  const custumValueTimeline = (data) => {
    if (data?.length > 0) {
      const checkDate = (date1, date2) => {
        const a = new Date(date1);
        const b = new Date(date2);
        if (
          a.getDate() === b.getDate() &&
          a.getMonth() === b.getMonth() &&
          a.getFullYear() === b.getFullYear()
        ) {
          return true;
        }
        return false;
      };

      let value = [];
      for (let index = 0; index < data.length; index++) {
        const element = data[index];
        if (value.find((el) => checkDate(el.createdAt, element.createdAt))) {
          value = value.map((item) =>
            checkDate(item.createdAt, element.createdAt)
              ? {
                  ...item,
                  content: (item?.content || []).concat(element),
                }
              : item
          );
        } else {
          value = value.concat({
            createdAt: element.createdAt,
            content: [element],
          });
        }
      }
      setMediaTimeline(value);
    }
  };

  const getFiles = async () => {
    try {
      const res: any = await PostService.getPostByUser({
        user_id: dataUser._id,
        query: {
          post_type: "video",
          limit: 20,
          page: pageCurrent,
        }
      });
      if (res) {
        custumValueTimeline([
          ...postVideo,
          ...res
        ]);
        setPostVideo([
          ...postVideo,
          ...res
        ]);

        let listVideo = [];
        for (let index = 0; index < res.length; index++) {
          const element = res[index];
          listVideo = listVideo.concat(element.post_files);
        }
        setVideos([
          ...videos,
          ...listVideo
        ]);
        setPageCurrent(pageCurrent + 1);
      }
    } catch (error) {}
  };

  window.onscroll = function () {
    if (Number(window.innerHeight + window.pageYOffset) >= Number(document.body.offsetHeight - 10)) {
      getFiles()
    }
  };

  useEffect(() => {
    getFiles();
  }, [dataUser]);
  
  return (
    <>
      <FsLightbox
        toggler={videoController.toggler}
        sources={videos?.map((img) => getUrlImage(img.post_file_path))}
        slide={videoController.slide}
      />
      {query?.view ? (
        <VChannel type="video" data={postVideo} />
      ) : (
        <>
          {videos.length > 0 ? (
            <VTimeline
              data={mediaTimeline?.map((media, index) => ({
                icon: (
                  <img src={`/assets/icons/default/book-diary.svg`} alt="" />
                ),
                title: renderDate(media?.createdAt),
                bgColorTime: "#3498db33",
                colorTime: "#4284F3",
                children: (
                  <Row>
                    <div className="list-video-group col-4">
                      <Row onClick={() => handleView(media?.content[0]._id)}>
                        {media?.content?.slice(0, 4)?.map((el) => (
                          <Col className="col-6 p-1">
                            <div className="user-images position-relative overflow-hidden">
                              <div
                                className="item-image"
                                onClick={() => videoOnSlide(index + 1)}
                              >
                                <video className="img-fluid rounded">
                                  <source
                                    src={getUrlImage(
                                      el?.post_files[0]?.post_file_path
                                    )}
                                    type={el?.post_file_type}
                                  />
                                  Your browser does not support the video tag.
                                </video>
                              </div>
                              <div className="btn-play-video">
                                <VIcon
                                  className="bg-secondary rounded-circle"
                                  name="play.svg"
                                />
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  </Row>
                ),
              }))}
            />
          ) : (
            <span className="w-100 text-center mt-3">
              {t("No content to display")}
            </span>
          )}
        </>
      )}
    </>
  );
};

export default ProfileVideos;

/* eslint-disable indent */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/iframe-has-title */
import VIcon from "components/v-icon";
import VWebView from "components/v-web-view";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { setOpenBanner } from "redux/reducers/advertisments/advertisments.reducers";
import { getIsOpenBanner } from "redux/reducers/advertisments/advertisments.selectors";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { useLocation } from "react-router-dom";
import { useMemo } from "react";

const View = () => {
  const dispatch = useAppDispatch();
  const isOpen = useAppSelector(getIsOpenBanner);
  const user = useAppSelector(getUserProfile);
  const location = useLocation();

  const menuKey = useMemo(() => {
    if (location.pathname) {
      const _pathname = String(location.pathname);
      if (_pathname.includes("home")) return "tab_right_home";
      if (_pathname.includes("group")) return "tab_right_group";
      if (_pathname.includes("page")) return "tab_right_page";
      if (_pathname.includes("business")) return "tab_right_business";
      if (_pathname.includes("follow")) return "tab_right_follow";
      if (_pathname.includes("utility")) return "tab_right_utility";
      if (_pathname.includes("shop")) return "tab_right_shop";
      if (_pathname.includes("quota")) return "tab_right_quota";
      if (_pathname.includes("chanel")) return "tab_right_chanel";
      if (_pathname.includes("censor")) return "tab_right_censor";
      if (_pathname.includes("friends")) return "tab_right_friends";
      if (_pathname.includes("notification")) return "tab_right_notification";
    }
    return "tab_right_home";
  }, [location.pathname]);

  const positionBanner = useMemo(() => {
    if (location.pathname) {
      const _pathname = String(location.pathname);
      if (_pathname.includes("home")) return "banner_right_home";
      if (_pathname.includes("group")) return "banner_right_group";
      if (_pathname.includes("page")) return "banner_right_page";
      if (_pathname.includes("business")) return "banner_right_business";
      if (_pathname.includes("follow")) return "banner_right_follow";
      if (_pathname.includes("utility")) return "banner_right_utility";
      if (_pathname.includes("shop")) return "banner_right_shop";
      if (_pathname.includes("quota")) return "banner_right_quota";
      if (_pathname.includes("chanel")) return "banner_right_chanel";
      if (_pathname.includes("censor")) return "banner_right_censor";
      if (_pathname.includes("friends")) return "banner_right_friends";
      if (_pathname.includes("notification"))
        return "banner_right_notification";
      if (_pathname.includes("profile")) return "banner_right_profile";
      if (_pathname.includes("team")) return "banner_right_teams";
      if (_pathname.includes("birthday")) return "banner_right_birthday";
    }

    return "banner_right_home";
  }, [location.pathname]);

  const close = () => {
    dispatch(setOpenBanner(false));
  };

  return (
    <div className="banner-app-wrapper">
      <Drawer
        open={isOpen}
        onClose={close}
        direction="right"
        className="drawer-banner"
      >
        <div>
          <div className="btn-close-banner" onClick={() => close()}>
            <VIcon width={18} height={18} name="right-arrow.svg" />
          </div>
          <VWebView
            url={`${process.env.REACT_APP_CLIENT_DOMAIN}/banners?menuKey=${menuKey}&positionBanner=${positionBanner}&userId=${user._id}&notLoadDefault=true`}
            widthScrollbar={"16px"}
            height="100vh"
          />
        </div>
      </Drawer>
    </div>
  );
};

export default View;

/* eslint-disable indent */
/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import TaskService from "api/tasks";
import UploadService from "api/uploads";
import VButton from "components/v-button";
import VUpload from "components/v-upload";
import { LEVEL_CALCULATE, LEVEL_CONDITION } from "config/enums";
import useTranslation from "hooks/useTranslation";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useAppSelector } from "redux/reducers/hook";
import { getCampaignsAsync } from "redux/reducers/tasks/tasks.actions";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { getUrlImage } from "utils/common";
import { v4 as uuidv4 } from "uuid";

const defaultStateUpload = [
  {
    id: uuidv4(),
    src: "",
    isNew: true,
    file: null,
  },
];

const Uploads = ({ participant, setModalInfo }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [levelNext, setLevelNext] = useState(null);
  const [listFile, setListFile] = useState(defaultStateUpload);
  const [listApproved, setListApproved] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useAppSelector(getUserProfile);



  useEffect(() => {
    if (participant) {
      const { object, data } = participant;
      const { levels } = object;
      const { level } = data || {};
      if (levels) {
        const _index = levels.findIndex((l) => l.id === level);
        setLevelNext(levels[_index + 1]);
      }
    }
  }, [participant]);

  useEffect(() => {
    if (participant && participant.campaign_attachment) {
      setListApproved(participant.campaign_attachment.approved || []);
      setListFile(
        (participant.campaign_attachment.uploads || []).map((f) => ({
          ...f,
          isNew: false,
          file: null,
        }))
      );
    }
  }, [participant]);

  const handleSubmit = async () => {
    try {
      setLoading(true);
      let listUpload = listFile;
      if (listFile && listFile.length > 0) {
        const formData = new FormData();
        formData.append("folderPath", `users/${user?._id}/campaign`);
        const statesNew = listFile
          .filter((st) => st.isNew)
          .map((stNew) => {
            formData.append("files", stNew.file);
            return stNew;
          });

        const listImg: any = await UploadService.uploadMultiFile({
          body: formData,
        });
        listImg.forEach((el) => {
          statesNew[el.id] = {
            ...statesNew[el.id],
            src: el?.filePath,
          };
        });
        listUpload = listUpload.map((st) => {
          const newStFound = statesNew.find((c) => c.id === st.id);
          if (newStFound) {
            return {
              ...st,
              ...newStFound,
            };
          }
          return st;
        });
        await TaskService.uploadFile({
          body: {
            files: listUpload,
            campaignId: participant.object_id,
          },
        });
        dispatch(getCampaignsAsync());
        toast.success(t("Upload success"));
        setModalInfo(false);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const isUpload =
    levelNext && levelNext[LEVEL_CONDITION.image_enable] ? true : false;

  return (
    <div className="campaign-upload-wrapper">
      <div>
        <h5>{t("Approved")}</h5>
        {listApproved.map((file) => (
          <img src={getUrlImage(file.src)} alt="" width={50} />
        ))}
      </div>
      <hr />
      {isUpload && (
        <div>
          <h5 className="mb-2">
            {t("Request to upload images")}:{" "}
            {levelNext[LEVEL_CALCULATE.image_amount]}
          </h5>
          <VUpload
            isMulti={true}
            maxFile={levelNext[LEVEL_CALCULATE.image_amount]}
            listFile={listFile}
            setListFile={setListFile}
          />
          {listFile.find((f) => f.isNew) && (
            <div className={"text-center"}>
              <VButton loading={loading} onClick={handleSubmit}>
                {t("Submit")}
              </VButton>
            </div>
          )}
        </div>
      )}
      {levelNext && (
        <div>
          <hr />
          <h5 className="text-center">
            {t("Instructions for the next level")}
          </h5>
          {levelNext.description}
        </div>
      )}
    </div>
  );
};

export default Uploads;

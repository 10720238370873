/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Packages from "./packages";
import History from "./history";
// import ExpectValue from "./expect-value";
import VBannerPage from "components/v-teamplate/v-banner-page";
import { Card, Col, Container, Row } from "react-bootstrap";
import { getGeneral, getMenusByKey } from "redux/reducers/settings/settings.selector";
import { useAppSelector } from "redux/reducers/hook";
import useQuery from "hooks/useQuery";
import VTab from "components/v-tab";
import VWebView from "components/v-web-view";
import ComingSoon from "pages/coming-soon/comingsoon";
import VRightView from "components/v-right-view";

interface IProps {
  [key: string]: any;
}

export default function View(props: IProps) {
  const menus = useAppSelector(getMenusByKey("tab_package"));
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container = general?.banner_right_layout !== "show_on_right_bar" || null
  
  const [tab, setTab] = useState(null);
  const [loadMenu, setLoadMenu] = useState(false);
  const query = useQuery();

  useEffect(() => {
    if (loadMenu)
      if (query.tab) {
        if (menus && menus.length > 0) {
          const menu = menus.find((m) => m.key === query.tab);
          if (menu) {
            setTab(menu);
          } else setTab(menus[0]);
        }
      } else {
        setTab(menus[0]);
      }
  }, [query.tab, loadMenu]);

  useEffect(() => {
    if (menus && menus.length > 0) {
      setLoadMenu(true);
    }
  }, [menus]);

  const renderTab = () => {
    if (tab && tab.type_view === "internal") {
      if (tab.key === "package") return <Packages />;
      if (tab.key === "history") return <History />;
      // if (tab.key === "expect") return <ExpectValue />;
    }
    if (tab && tab.type_view === "webview") {
      return (
        <VWebView
          url={tab.url}
          widthScrollbar={tab?.width_scrollbar}
          height="100%"
        />
      );
    }
    return <ComingSoon />;
  };

  return (
    <Container>
      <Row>
        <Col lg={show_banner_right_on_container ? 8 : 12} className={"px-1"}>
          <VBannerPage position="banner_top_package" />
          <Card>
            <Col sm={12}>
              <VTab
                tab={tab}
                setTab={setTab}
                menus={menus}
                activeBorder="bottom"
                noBackground={true}
              />
              {renderTab()}
            </Col>
          </Card>
        </Col>
        {show_banner_right_on_container && (
          <Col lg={4} className={"d-none d-lg-block px-1"}>
            <VRightView
              menuKey={"tab_right_home"}
              positionBanner={"banner_right_package"}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
}

/* eslint-disable indent */
/* eslint-disable react-hooks/exhaustive-deps */
import VQRCode from "components/v-qr-code";

import Button from "components/v-button";
import { useSelector } from "react-redux";

import { getWalletSelected } from "redux/reducers/wallets/wallets.selector";

import { copyText, formatCurrency } from "utils/common";
import { toast } from "react-toastify";
import { useEffect, useRef, useState } from "react";
import Input from "components/v-input";
import Modal from "components/v-modal";
import useTranslation from "hooks/useTranslation";
import Network from "../../networks";

import { exportComponentAsJPEG } from "react-component-export-image";
import Card from "components/v-teamplate/Card";
import { useAppDispatch } from "redux/reducers/hook";
import { getWalletByOptionAsync } from "redux/reducers/wallets/wallets.actions";
import useQuery from "hooks/useQuery";

type IProps = {
  url: string;
  userId?: string;
  reloadBalance?: () => {};
};

const View = (props: IProps) => {
  const { userId } = props;

  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { stock } = useQuery();

  const imgQrRef = useRef();
  const walletSelected: any = useSelector(getWalletSelected);
  const walletAddress = walletSelected.address || "N/A";
  const tokenName = String(stock || "N/A").toUpperCase();
  const [amount, setAmount] = useState<number>(null);
  const [openModal, setOpenModal] = useState<boolean>(false);

  useEffect(() => {
    document.getElementById("right").scrollIntoView({
      block: "start",
      behavior: "smooth",
    });
  }, []);

  useEffect(() => {
    if (stock)
      dispatch(
        getWalletByOptionAsync({
          params: {
            userId: userId,
          },
          query: {
            network: "vzonex",
          },
        })
      );
  }, [stock]);

  const handleChange = (e) => {
    setAmount(e);
  };
  const handleCopy = () => {
    copyText(walletAddress);
    toast.success(t("Copied to clipboard!"));
  };

  const handlePreview = () => {
    setOpenModal(true);
  };

  const handleDownload = () => {
    exportComponentAsJPEG(imgQrRef, {
      fileName: `${"Qr-code"}_vzonex_${new Date().getTime()}` || "vzonex",
    });
    setOpenModal(false);
  };

  const renderQr = () => {
    return (
      <Card>
        <div className="container-modal" ref={imgQrRef}>
          <div className="title-modal">
            <img src="/assets/images/logo0.png" alt="" />
          </div>
          <div className="content-modal">
            <VQRCode
              value={`vzxwallet-${tokenName}-${walletAddress}${
                amount >= 0 && `-${amount}`
              }`}
              size={280}
            />
            <p className="mt-3">{`${walletAddress.slice(
              0,
              12
            )}...${walletAddress.slice(-12)}`}</p>

            {amount > 0 && <h3>{`${formatCurrency(amount)} ${tokenName}`}</h3>}
          </div>
          <div className="des-modal">
            <p>
              {t("Send only")}{" "}
              <span>
                {tokenName} ({"vzonex".toUpperCase()})
              </span>{" "}
              {t("to this address.")}
              <br />
              {t("Sending and other coins may result in permanent loss.")}{" "}
            </p>
          </div>
        </div>
      </Card>
    );
  };

  const handleClosed = () => {
    setOpenModal(false);
  };

  return (
    <Card isOpacity={true}>
      <div className="content-right-view">
        <div className="title-action-wallets">
          <p>
            {t("Deposit")} {tokenName}
          </p>
        </div>
        <div className="wallets-info">
          <Network network={"vzonex"} setNetwork={() => {}} />
          <span className="mt-2">
            {tokenName} {t("Address")}
          </span>
          <div className="stock-address">
            <div className="mt-3">
              <VQRCode
                value={`vzxwallet-${tokenName}-${walletAddress}${
                  amount >= 0 && `-${amount}`
                }`}
                size={150}
              />
            </div>
            <p className="mt-3">{walletAddress}</p>
            <div className="copy-view mt-2">
              <Button transparent={true} onClick={handleCopy}>
                {t("Copy")}
              </Button>
              <Button transparent={true} onClick={handlePreview}>
                {t("Preview")}
              </Button>
            </div>
          </div>
          <div className="mt-3">
            <Input
              isCurrency={true}
              transparent={true}
              label={`${t("Amount")} ${tokenName}`}
              value={amount}
              onChange={(e) => handleChange(e.target.value)}
            />
          </div>
        </div>
        <div className="wallets-info">
          {/* <p>
            Deposit fee: <SpanColor color="red100">0.00000000</SpanColor> VZX
          </p> */}
          <div className="text-description-warning">
            <img src="/assets/images/icons/warning.svg" alt="warning" />
            <p>
              {t("You have to deposit at least 0.5")} {tokenName}{" "}
              {t("to be credited. Any deposit")}
              {t("that is less than 0.5")} {tokenName}{" "}
              {t("will not be refunded")}
            </p>
          </div>
          <div className="text-description-warning">
            <img src="/assets/images/icons/warning.svg" alt="warning" />
            <p>
              {t("This deposit address only accepts")} {tokenName}.{" "}
              {t("Do not send other coins to it.")}
            </p>
          </div>
        </div>
      </div>
      <Modal
        isOpen={openModal}
        children={renderQr()}
        onCancel={handleClosed}
        onConfirm={handleDownload}
        confirmLabel={
          <div className="action-modal">
            <img src="/assets/images/icons/download.svg" alt="" />
          </div>
        }
        centered={true}
      />
    </Card>
  );
};

export default View;

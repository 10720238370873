/* eslint-disable indent */
import React from "react";
import { Container, Row, Col, Image } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import VButton from "components/v-button";
import useTranslation from "hooks/useTranslation";
import Card from "components/v-teamplate/Card";

// img

const Error404 = () => {
  const history = useHistory();
  const { t } = useTranslation();
  return (
    <>
      <Container className="p-0">
        <Card className="pb-3">
          <Row className="no-gutters height-self-center">
            <Col sm="12" className="text-center align-self-center">
              <div className="iq-maintenance position-relative mt-5">
                <Image
                  src={"/assets/images/maintenance-404/404.png"}
                  className="img-fluid iq-error-img"
                  alt=""
                />
                <h2 className="mb-0 text-center">
                  {t("Oops! This Page is Not Found.")}
                </h2>
                <p className="text-center">
                  {t("The requested page dose not exist.")}
                </p>
                <VButton
                  className="mt-3 btn btn-primary"
                  onClick={() => history.push("/")}
                >
                  {t("Back to Home")}
                </VButton>
              </div>
            </Col>
          </Row>
        </Card>
      </Container>
    </>
  );
};

export default Error404;

/* eslint-disable jsx-a11y/anchor-is-valid */
import { Col, Row } from "reactstrap";
import { formatNumber, getBase64Image } from "utils/common";

import VTable from "components/v-table";
import { useEffect, useRef, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import DownloadImg from "components/download/View";
import { useAppSelector } from "redux/reducers/hook";
import { getCryptos } from "redux/reducers/cryptos/cryptos.selector";
import APIs from "api";
import useTranslation from "hooks/useTranslation";

const Invoice = () => {
  const cryptos = useAppSelector(getCryptos);
  const { id, type } = useParams();
  const [transaction, setTransaction] = useState(null);
  const [stock, setStock] = useState(null);
  const history = useHistory();
  const imgRef = useRef(null);
  const [logo, setLogo] = useState(null);
  const { t } = useTranslation();

  const columns = [
    {
      key: "Logo",
      title: <span style={{ fontWeight: 700 }}>{t("Hình ảnh")}</span>,
      render: (item: any) => <img className="logo" src={logo} alt="" />,
    },
    {
      key: "From",
      title: <span style={{ fontWeight: 700 }}>{t("Người gửi")}</span>,

      render: (item: any) => `${item ? item["from"] : "N/A"}`,
    },
    {
      key: "To",
      title: <span style={{ fontWeight: 700 }}>{t("Người nhận")}</span>,

      render: (item: any) =>
        `${
          item
            ? item["to"].length > 15
              ? item["to"].slice(0, 15) + "..."
              : item["to"]
            : "N/A"
        }`,
    },
    {
      key: "amount",
      title: <span style={{ fontWeight: 700 }}>{t("Giá tiền")}</span>,
      render: (item: any) =>
        `${
          item
            ? formatNumber(item.amount) +
              "(" +
              String(item["token"]).toUpperCase() +
              ")"
            : "N/A"
        } `,
    },
    {
      key: "status",
      title: <span style={{ fontWeight: 700 }}>{t("Trạng thái")}</span>,
      render: (item: any) => `${item ? item["status"] : "N/A"}`,
    },
  ];

  useEffect(() => {
    if (id && type) {
      APIs.TRANSACTION.getTransactionById({
        params: {
          id,
        },
      })
        .then((res: any) => {
          if (res && res.transaction) {
            setTransaction(res.transaction);
          }
        })
        .catch((err) => console.log(err));
    }
  }, [history, id, type]);

  useEffect(() => {
    const getImages = async () => {
      if (stock) {
        const res = await getBase64Image(stock.logo);
        setLogo(res);
      }
    };
    getImages();
  }, [stock]);

  useEffect(() => {
    if (cryptos && transaction) {
      setStock(cryptos.find((c) => c.symbol === transaction.token));
    }
  }, [cryptos, transaction]);

  return (
    <>
      <div ref={imgRef} className="container-invoice pt-0">
        <div className="thumbnail">
          <img src="/assets/images/invoice-thumbnail.png" alt="" />
        </div>
        <div className="logo-top">
          <img src="/assets/images/logo1.png" alt="logo" />
        </div>
        {transaction && (
          <>
            <div>
              <div className="invoice-title">
                <img src="/assets/images/invoice-header.png" alt="" />
                <span>{t("HÓA ĐƠN")}</span>
              </div>

              <Row>
                <Col xs={4}>
                  <p className="heading-bold">{t("Thông tin")}</p>
                  <div className="row-item">
                    <span className="label">{t("Mã số")}:</span>
                    <span className="value">
                      #{new Date(transaction.createdAt).getTime()}
                    </span>
                  </div>
                  <div className="row-item">
                    <span className="label">{t("Địa chỉ ví")}:</span>

                    <span className="value">
                      {transaction.user_id._id.slice(10)}...
                    </span>
                  </div>

                  <div className="row-item">
                    <span className="label">{t("Cổ phiếu")}:</span>
                    <span className="value">
                      #{new Date(transaction.createdAt).getTime()}
                    </span>
                  </div>

                  <div className="row-item">
                    <span className="label">{t("Ngày")}:</span>
                    <span className="value">
                      {new Date(transaction.createdAt).toLocaleDateString()}
                    </span>
                  </div>
                </Col>

                <Col xs={4}>
                  <p className="heading-normal">{t("Người gửi")}</p>
                  <div className="row-item">
                    <span className="label">support@vzonex.com</span>
                  </div>
                  <div className="row-item">
                    <span className="label">{t("Địa chỉ")}:</span>
                    <span className="value">
                      Số 6-A12, KĐT Lê Trọng Tấn - Gleximco, An Khánh, Hoài Đức,
                      Hà Nội.
                    </span>
                  </div>
                </Col>
                <Col xs={4}>
                  <p className="heading-bold">{t("Người nhận")}</p>
                  <div className="row-item">
                    <span className="label">{transaction.user_id.email}</span>
                  </div>
                </Col>
              </Row>
            </div>

            <div className="content">
              <VTable columns={columns} dataSource={[transaction]}></VTable>
            </div>
            <div className="balance">
              <div className="total-balance">
                <Row>
                  <Col>
                    <h6>Total Balance Before :</h6>
                  </Col>
                  <Col className="p-0">
                    <h5>
                      {(transaction.invoice &&
                        transaction.invoice.balanceBefore[transaction.token]) ||
                        0}{" "}
                      ({String(transaction.token).toUpperCase()})
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <h6>Total Balance After :</h6>
                  </Col>
                  <Col className="p-0">
                    <h5>
                      {transaction.invoice &&
                        transaction.invoice.balanceAfter[
                          transaction.token
                        ]}{" "}
                      ({String(transaction.token).toUpperCase()})
                    </h5>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="transaction-payment-info">
              <div>
                <div>
                  <img src="/assets/images/PaymentBg.png" alt="" />
                  <div>
                    <div>
                      <div className="payment-row-item">
                        <div>
                          <p>SubTotal:</p>
                          <span>{`${formatNumber(transaction.amount)} (${
                            transaction.product_id
                              ? String(transaction.product.token).toUpperCase()
                              : "N/A"
                          })`}</span>
                        </div>
                        <div>
                          <p>Tax: VAT 0%</p>
                          <span>0.00%</span>
                        </div>
                        <div>
                          <p>Total due:</p>
                          <span>{`${formatNumber(transaction.amount)} (${
                            transaction.product_id
                              ? String(transaction.product.token).toUpperCase()
                              : "N/A"
                          })`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="invoice-footer">
              <span>
                {t(
                  "Mọi thắc mắc liên quan đến tài liệu này vui lòng liên hệ với chúng tôi"
                )}
              </span>
              <p>{t("Cảm ơn bạn đã tin tưởng chúng tôi!")}</p>
            </div>
          </>
        )}
      </div>

      {transaction && (
        <DownloadImg
          imgRef={imgRef}
          fileName={`invoice_${type}_${transaction.token}`}
        />
      )}
    </>
  );
};

export default Invoice;

import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/reducers/hook";
import { getUrlImage } from "utils/common";
import { getPageBackgroundsNoAuth } from "api/page-backgrounds";
import Video from "components/video";
import { getUserProfile } from "redux/reducers/users/users.selector";
import SliderCustom from "components/v-slider";

const PageBackgroundsHomeV2 = () => {
  const profile = useAppSelector(getUserProfile);
  const [background, setBackground] = useState<string>(
    "admin/20240208/82c74b18-b024-4358-9cf5-3b6980df8676.jpg"
  );
  console.log(background,'background')

  useEffect(() => {
    getPageBackgroundsNoAuth({ params: { position: "home_v2_bg" } }).then(
      (res: any) => {
        try {
          if (res[0].light.slide[0].src) {
            setBackground(res[0].light.slide[0].src);
          }
        } catch {}
      }
    );
  }, []);

  const userBgSetting = profile?.profile?.update_cover_home_v2?.url;

  return (
    <>
      {(profile?.profile?.update_cover_home_v2?.type === "image" ||
        (profile?.profile?.update_cover_home_v2?.type === "slide" &&
          profile?.profile?.update_cover_home_v2?.slide?.length === 1) || background) && (
        <div>
          <img
            className="bg-cover d-block d-md-none"
            src={
              userBgSetting
                ? getUrlImage(userBgSetting, 700)
                : getUrlImage(background, 700)
            }
            alt="bg-cover"
          />
          <img
            className="bg-cover d-none d-md-block"
            src={
              userBgSetting
                ? getUrlImage(userBgSetting, 1200)
                : getUrlImage(background, 700)
            }
            alt="bg-cover"
          />
        </div>
      )}
      {(profile?.profile?.update_cover_home_v2?.type === "video" ||
        profile?.profile?.update_cover_home_v2?.type === "link") && (
        <div className="bg-cover">
          <Video
            page={"home_v2"}
            isEdit={false}
            link={
              profile?.profile?.update_cover_home_v2?.type === "link"
                ? userBgSetting
                : getUrlImage(userBgSetting)
            }
          />
        </div>
      )}
      {profile?.profile?.update_cover_home_v2?.type === "slide" &&
        profile?.profile?.update_cover_home_v2?.slide?.length > 1 && (
        <div className="bg-cover-slide">
          <SliderCustom
            data={profile?.profile?.update_cover_home_v2?.slide}
            resize_image={1200}
          />
        </div>
      )}
    </>
  );
};

export default PageBackgroundsHomeV2;

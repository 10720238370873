/* eslint-disable indent */
/* eslint-disable jsx-a11y/alt-text */
// import VIcon from "components/v-icon";
import { useState } from "react";
import { Nav } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { setOpenBanner } from "redux/reducers/advertisments/advertisments.reducers";
import { useAppDispatch, useAppSelector } from "redux/reducers/hook";
import { getMenusByKey } from "redux/reducers/settings/settings.selector";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { convertUrl, getUrlImage } from "utils/common";

const Footer = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const mode = urlParams.get("dark");
  const menus = useAppSelector(getMenusByKey("footer_mobile"));
  const userData: any = useAppSelector(getUserProfile);
  const history = useHistory();
  const dispatch = useAppDispatch();

  if (mode !== null) {
    // document.body.classList = "";
    switch (mode) {
      case "true":
        // document.body.classList = "dark";
        break;
      case "false":
        // document.body.classList = "";
        break;
      default:
        // document.body.classList = "";
        break;
    }
  }
  const [hide, setHide] = useState(false);
  window.onscroll = function (e) {
    if (this.oldScroll > this.scrollY) {
      setHide(false);
    } else {
      setHide(true);
    }
    this.oldScroll = this.scrollY;
  };

  const handleClickMenu = (menu) => {
    if (menu?.key === "scan") return history.push(`/${menu.key}`);
    if (menu?.key?.toLowerCase() === "gift") return dispatch(setOpenBanner(true));
    switch (menu.type_view) {
      case "internal":
        history.push(convertUrl(menu.url, userData) || "/");
        break;
      case "link":
        window.open(convertUrl(menu.url), "_blank");
        break;
      case "webview":
        history.push(`/wv/${menu.key}`);
        break;
      default:
        break;
    }
  };

  return (
    <>
      {menus?.length > 0 && (
        <footer
          className={`iq-footer d-lg-none bg-white ${
            hide ? "iq-footer-hide" : ""
          }`}
        >
          <ul className="navbar-nav navbar-list d-flex flex-row justify-content-around w-100 mt-1">
            {/* <Nav.Item as="li">
              <div
                className="btn-banner"
                onClick={() => dispatch(setOpenBanner(true))}
              >
                <VIcon width={30} height={26} name="icon_ads.gif" />
              </div>
            </Nav.Item> */}

            {menus?.map((el) => (
              <Nav.Item as="li">
                <Link
                  // to={`${el?.key === "scan" ? `/${el.key}` : el.url}`}
                  target={el?.type_view === "link" ? "_blank" : ""}
                  className="d-flex align-items-center"
                  onClick={() => handleClickMenu(el)}
                >
                  <img src={getUrlImage(el?.img_icon, 100)} width={30} height={30} />
                </Link>
              </Nav.Item>
            ))}
            {/* <Nav.Item as="li">
            <Link to="/" className="d-flex align-items-center">
              <VButtonCircleIcon icon="menu-list-dot.svg" />
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="/" className="d-flex align-items-center">
              <VButtonCircleIcon icon="search.svg" />
            </Link>
          </Nav.Item>
          <Nav.Item as="li">
            <Link to="#" className="d-flex align-items-center">
              <VButtonCircleIcon icon="message.svg" />
            </Link>
          </Nav.Item> */}
          </ul>
        </footer>
      )}
    </>
  );
};

export default Footer;

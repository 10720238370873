/* eslint-disable indent */
import { request, parseErrorResponse } from "./request";

const getPostDirectoryByUserId = (req) =>
  new Promise((resolve, reject) => {
    request()
      .get(`/post-directory/${req.params.userId}`)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        // else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const createPostDirectory = (req) =>
  new Promise((resolve, reject) => {
    request()
      .post(`/post-directory`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const editPostDirectory = (req) =>
  new Promise((resolve, reject) => {
    request()
      .put(`/post-directory/${req.params.id}`, req.body)
      .then((res) => {
        const { data } = res.data;
        if (data) resolve(data);
        else reject(new Error("Something went error!"));
      })
      .catch(parseErrorResponse)
      .then(reject);
  });

const PostDirectoryService = {
  getPostDirectoryByUserId,
  createPostDirectory,
  editPostDirectory,
};

export default PostDirectoryService;

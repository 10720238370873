import UploadService from "api/uploads";
import { useRef, useState } from "react";
import { Card } from "react-bootstrap";
import { BiPlus } from "react-icons/bi";
import { useAppSelector } from "redux/reducers/hook";
import { getUserProfile } from "redux/reducers/users/users.selector";
import { getUrlImage } from "utils/common";

type IProps = {
  id: string;
  label?: string;
  name?: string;
  format?: string;
  required?: boolean;
  placeholder?: string;
  showTime?: any;
  error?: string;
  field?: any;
  image?: string;
};

const View = ({
  id = "",
  label = "",
  name = "",
  required = false,
  error,
  field,
  image,
}: IProps) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const user = useAppSelector(getUserProfile);
  const [filePath, setFilePath] = useState({
    path: field.value?.path,
    name: field.value?.name,
    type: field.value?.type,
  });

  const handleClick = () => {
    inputRef.current?.click();
  };

  const handleChange = async (e) => {
    const formData = new FormData();
    formData.append("folderPath", `users/${user?._id}`);
    formData.append("files", e.target.files[0]);
    const file: any = await UploadService.uploadFile({
      body: formData,
    });
    const value = {
      path: file.filePath,
      name: e.target.files[0]?.name,
      type: e.target.files[0]?.type,
    };
    field.onChange(value);
    setFilePath(value);
  };

  return (
    <Card border="success" className="mb-4 question_item_card" id={id}>
      {label && (
        <p className="text-sm mb-2">
          <b>{label}</b> {required && <span className="text-danger">*</span>}
        </p>
      )}
      <div
        style={{
          display: "flex",
          gap: "10px",
        }}
      >
        <div
          onClick={() => handleClick()}
          style={{
            height: "150px",
            width: "150px",
            border: "1px",
            borderStyle: "dashed",
            position: "relative",
            borderRadius: "20px",
            cursor: "pointer",
            overflow: "hidden",
          }}
        >
          {filePath?.path ? (
            <img
              style={{ height: "100%", width: "100%", objectFit: "cover" }}
              src={getUrlImage(filePath?.path, 1000)}
              alt="sub"
            />
          ) : (
            <div
              style={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%,-50%)",
              }}
            >
              <BiPlus />
            </div>
          )}
        </div>
        {filePath && <span>{filePath.name}</span>}
      </div>
      <input
        ref={inputRef}
        type="file"
        onChange={handleChange}
        style={{ display: "none" }}
        accept="image/*"
      />
      {error && <p className="_error mb-0">{error}</p>}
    </Card>
  );
};

export default View;

import { createAsyncThunk } from "@reduxjs/toolkit";
import PageService from "api/pages";
import { IRequestData } from "api/request";

export const getPageSuggestsAsync = createAsyncThunk(
  "pages/suggest",
  async (req: IRequestData) => {
    try {
      const response: any = await PageService.getSuggests(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPageFollowingsAsync = createAsyncThunk(
  "pages/followings",
  async (req: IRequestData) => {
    try {
      const response: any = await PageService.getFollowings(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getPageManageAsync = createAsyncThunk(
  "pages/manage",
  async (req: IRequestData) => {
    try {
      const response: any = await PageService.getManage(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

export const getFollowersAsync = createAsyncThunk(
  "pages/followers",
  async (req: IRequestData) => {
    try {
      const response: any = await PageService.getFollowers(req);
      return response;
    } catch (error) {
      return error;
    }
  }
);

import VipsService from "api/vips";
import VButton from "components/v-button";
import VInput from "components/v-input";
import { useState } from "react";
import useTranslation from "hooks/useTranslation";
import { toast } from "react-toastify";
import { useAppDispatch } from "redux/reducers/hook";
import { getUserByIdAsync } from "redux/reducers/users/users.actions";

type IProps = {
  permission: string;
  user: any;
};

const View = ({ permission, user }: IProps) => {
  const [loading, setLoading] = useState(false);
  const [code, setCode] = useState("");

  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const handleClick = async () => {
    try {
      setLoading(true);
      const res: any = await VipsService.activeCode({
        body: {
          code: code,
          permission,
          user_id: user._id,
        },
      });
      if (res) {
        dispatch(
          getUserByIdAsync({
            params: {
              id: user._id,
            },
          })
        );
      }
      setLoading(false);
      toast.success(t(res));
    } catch (error) {
      toast.error(t(error.errors));
      setLoading(false)
    }
  };

  return (
    <div className="v-code-vip-wrapper p-1 text-center">
      <VInput
        label={t("enter_code_to_activate_feature")}
        onChange={(e) => setCode(e.target.value)}
      />
      <VButton
        loading={loading}
        type="button"
        className="btn btn-primary mt-2"
        onClick={handleClick}
      >
        {" "}
        {t("activated")}
      </VButton>
    </div>
  );
};

export default View;

import APIs from "api";
import VBanner from "components/v-banner";
// import VRightView from "components/v-right-view";
import WebModule from "modals/web-modules";
import Utilities from "pages/utilities";
import { useEffect, useState } from "react";
// import { isLiveSite } from "utils/common";
import { getIDFromProfileLink } from "utils/convertIdVideo";

export default function FeedHome({
  expanedLeftRightBarKey,
  setExpanedLeftRightBarKey,
}: any) {
  const isBoth = expanedLeftRightBarKey === "both";
  const isExpanedLeftBar = isBoth || expanedLeftRightBarKey === "left";
  const isExpanedRightBar = isBoth || expanedLeftRightBarKey === "right";

  const [idFeed, setIDFeed] = useState("");
  const [leftPageUrl, setLeftPageUrl] = useState("");

  const specialPage = {
    'utilities': <Utilities />
  }

  const setExpanedLeftBar = (isExpaned: boolean) => {
    setExpanedLeftRightBarKey(isExpaned ? "left" : "");
  };
  const setExpanedRightBar = (isExpaned: boolean) => {
    setExpanedLeftRightBarKey(isExpaned ? "right" : "");
  };

  // did mount
  useEffect(() => {
    APIs.SETTING.getMenusByPosition({
      params: {
        position: "home_v2_left_banner_feed",
      },
    })
      .then((res: any) => {
        const item = res.find(
          (el) => el.position === "home_v2_left_banner_feed"
        );
        
        if (item && getIDFromProfileLink(item.url)) {
          setIDFeed(getIDFromProfileLink(item.url));
        } else {
          setLeftPageUrl(item?.url || "")
        }
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className={`left-and-right-sidebar`}>
      <div className="sidebar-navigator">
        <div
          className={`left-sidebar-navigator ${
            isExpanedLeftBar ? "expaned" : ""
          } ${isExpanedRightBar ? "hide" : ""}`}
          onClick={() => setExpanedLeftBar(!isExpanedLeftBar)}
        >
          <img src="/assets/images/home-v2/menu-2vach.svg" />
        </div>
        <div
          className={`right-sidebar-navigator ${
            isExpanedRightBar ? "expaned" : ""
          } ${isExpanedLeftBar ? "hide" : ""}`}
          onClick={() => setExpanedRightBar(!isExpanedRightBar)}
        >
          <img src="/assets/images/home-v2/menu-2vach.svg" />
        </div>
      </div>

      <div
        className={`left-sidebar-content ${isExpanedLeftBar ? "expaned" : ""}`}
      >
        {/* TODO: add https://vdiarybook.com/profile/advsupport profile feed here */}
        {/* .net: 6429b4e4c93293fdbb93a8f1 */}

        {idFeed && (
          <WebModule
            openWebModuleKey="profile-feed"
            userProfileId={idFeed}
            page={"events"} // force to events because no need to modal
          />
        )}

        {
          leftPageUrl ? (
            leftPageUrl.includes('utilities') ? specialPage['utilities'] : <iframe className="iframe-webview-home" src={leftPageUrl} />
          ) : <></>
        }

      </div>
      <div
        className={`right-sidebar-content ${
          isExpanedRightBar ? "expaned" : ""
        }`}
      >
        {/* <WebModule
          openWebModuleKey="home-feeds"
          page={"events"} // force to events because no need to modal
        /> */}

        <div className="media-height" data-scrollbar="init">
          {/* <VRightView positionBanner={'banner_right_home'} isApp={true} /> */}
          <VBanner position={"banner_right_home"} />
        </div>
      </div>

      {(isExpanedLeftBar || isExpanedRightBar) && !isBoth && (
        <div
          className="home_v2_overlay"
          onClick={() => setExpanedLeftRightBarKey("")}
        >
          {/* overlay */}
        </div>
      )}
    </div>
  );
}

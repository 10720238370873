/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable indent */
import React, {
	useEffect,
	useState,
} from "react";
import Card from "components/v-teamplate/Card";
import useTranslation from "hooks/useTranslation";
import { Col, Row } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import { getUrlProfile } from "utils/common";
import { FRIEND_TYPE } from "config/enums";

import BusinessService from "api/businesses";
import FriendService from "api/friends";
import { isUser, isBusiness, isGroup } from "utils/friends";
import GroupService from "api/groups";
import VNameVerify from "components/v-objects/v-name-verify";
import VAvatar from "components/v-avatar";
import { isMobile } from "react-device-detect";

type IProps = {
	isAuth: boolean;
	dataUser: any;
};

const View = (props: IProps, ref) => {
	const { t } = useTranslation();
	const history = useHistory();

	const { dataUser } = props;
	const _isUser = isUser(dataUser.object_type);
	const _isBusiness = isBusiness(dataUser.object_type);
	const _isGroup = isGroup(dataUser.object_type);

	const [members, setMembers] = useState([]);
	const [lazyParams] = useState({
		limit: 6,
		page: 1,
	});
	const [totalDocs, setTotalDocs] = useState(0);

	const handleAction = (data) => {
		history.push(
			`${getUrlProfile(data.to)}`
		);
	};


	const getData = async () => {
		try {
			let res;
			if (_isUser) {
				res = await FriendService.getFriends({
					params: {
						user_id: dataUser._id,
					},
					query: {
						...lazyParams,
						type: FRIEND_TYPE.FRIEND
					},
				});

			}
			if (_isBusiness) {
				res = await BusinessService.getMemberJoined({
					params: {
						id: dataUser._id,
					},
					query: {
						...lazyParams,
					},
				});

			}

			if (_isGroup) {
				res = await GroupService.getMemberJoined({
					params: {
						id: dataUser._id,
					},
					query: {
						...lazyParams,
					},
				});

			}

			if (res && res.docs) {
				if (res.page === 1) {
					setMembers(res.docs);
				} else {
					setMembers((prev) => prev.concat(res.docs));
				}
				setTotalDocs(res.totalDocs);
			}
		} catch (error) { }
	};

	useEffect(() => {
		getData()
	}, [dataUser])

	return (
		<Card>
			<Card.Header className={'p-2'}>
				<div className="w-100 d-flex justify-content-between">
					<div className="title-left-sidebar">
						{t(_isUser ? "friend" : "Member")}
						<span>({Number(totalDocs)})</span>
					</div>
					<Link to={`${getUrlProfile(dataUser)}?tab=${_isUser ? "friend" : "member"}`}>
						{t("View all")}
					</Link>
				</div>
			</Card.Header>
			<Card.Body className="py-2">
				<Row>
					{
						(members || []).slice(0,3).map((el, index) => (
							<Col
								xs={4}
								key={index}
								className={"p-0 cursor-pointer"}
								onClick={() => handleAction(el)}
							>
								<div className="image-left-sibebar">
									<div className="item-image p-2">
										<VAvatar 
											isClick={true}
											userData={el?.to}
											size={isMobile ? "90px" : "70px"}
											hideName={true}
										/>
										{/* <img src={getUrlImage(el?.to?.avatar, 150) || "/assets/images/VDB-64.png"} alt="" /> */}
									</div>
								</div>
								<p
									className="item-name position-relative mt-1"
								>
									<VNameVerify
										className={"mb-0 d-flex align-items-center justify-content-center"}
										dataUser={el?.to}
										fontWeight={"600"}
										fontSizeIconVerify={10}
									/>
								</p>
							</Col>
						))
					}
				</Row>
			</Card.Body>
		</Card>
	)
}

export default View;
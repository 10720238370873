/* eslint-disable indent */
/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/alt-text */
import { Row, Col, Container } from "react-bootstrap";
import VRightView from "components/v-right-view";

import NewsFeedModule from "../home/newsfeed";
import { POST_PLUGIN } from "config/enums";
import { useAppSelector } from "redux/reducers/hook";
import { getGeneral } from "redux/reducers/settings/settings.selector";

const View = () => {
  // const { reloadNewsFeedNumber } = useAppSelector(getUISettingState);
  const token = localStorage.getItem("auth");
  const general = useAppSelector(getGeneral);
  const show_banner_right_on_container =
    general?.banner_right_layout !== "show_on_right_bar" || null;

  const renderTab = () => {
    return <NewsFeedModule plugin={POST_PLUGIN.EVENT} />;
  };

  return (
    <div className="videos-link-wrapper">
      <Container>
        <Row className="justify-content-center">
          <Col
            lg={token ? (show_banner_right_on_container ? 8 : 12) : 12}
            className={"px-1 newfeed-container"}
          >
            {renderTab()}
          </Col>
          {token && show_banner_right_on_container && (
            <Col lg={4} className={"d-none d-lg-block px-1 "}>
              <VRightView
                className="newfeed-rightview"
                positionBanner={"banner_right_home"}
              />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default View;
